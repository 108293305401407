import { useState } from "react";
import { withRouter } from "react-router-dom";
import { Storage } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import { Form, Modal, Button, Select, Input, Checkbox, Alert } from "antd";
import withSubscriptions from "common/withSubscriptions";
import { HAS_SHEETS } from "common/shared";
import { FILE_TYPES_READABLE, FILE_TYPE_EXTENSIONS, SHEET_NAMES_INVALID_CHARACTERS_PATTERN } from "common/constants";
import { PlusOutlined, MinusOutlined, CloudUploadOutlined } from "@ant-design/icons";

import { deleteFileVersion, getFile } from "graphql/queries_custom";
import { deleteSheet, updateFileVersion, createAuditItem } from "graphql/mutations";
import { getExtensionFromKey } from "common/shared";
import { getSimpleLabel } from "common/labels";

import InfoItem from "InfoItem/InfoItem";

import {
  callGraphQL,
  getUppercaseStatus,
  createFileWithSheets,
  publish,
  createFileVersionInApi,
  pointSheetsToLatestFileVersion,
  isFileOpen,
  showFileIsOpenModal,
  sanitiseSheetName,
} from "common/helpers";

import "./UploadCustomFileModal.scss";

type Props = {
  onClose?: any;
  file: any;
  task: any;
  taskRevision: any;
  apiUser: any;
  visible: boolean;
  organisationDetails: any;
  updateFileRandomNumber: any;
  basePath: string;
  history: any;
};

export function UploadCustomFileModal({
  onClose,
  file,
  task,
  taskRevision,
  apiUser,
  visible,
  organisationDetails,
  updateFileRandomNumber,
  basePath,
  history,
}: Props) {
  const [form] = Form.useForm();
  const [keepSheets, setKeepSheets] = useState<any>(true);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState();
  const [fileToUpload, setFileToUpload] = useState<object>();
  const [sheetCount, setSheetCount] = useState(1);

  async function onFileInputChange(e) {
    const fileToUpload = e.target.files[0];
    if (!fileToUpload.name.includes(".")) {
      Modal.error({
        title: "Invalid file",
        content: "The file you are trying to upload does not have an extension.",
      });
      return;
    }
    const fileExtension = fileToUpload.name.split(".").slice(-1)[0];
    const acceptedExtensions = FILE_TYPE_EXTENSIONS[file.type];

    if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
      Modal.error({
        title: "Wrong file type",
        content: `File extension must be: ${acceptedExtensions.join(
          ", "
        )}. The file you are trying to upload has the extension: ${fileExtension}`,
      });

      return;
    }

    setFileToUpload(fileToUpload);
  }

  async function onSubmit(formFields: any) {
    const fileIsOpen = await isFileOpen({ task, file });
    if (fileIsOpen) {
      showFileIsOpenModal(file);
      return;
    }

    setIsLoading(true);

    let updatedFile;

    await callGraphQL(
      "Could not create audit item",
      graphqlOperation(createAuditItem, {
        input: {
          taskId: task.id,
          projectId: task.projectId,
          clientId: task.clientId,
          fileId: file.id,
          userId: apiUser.id,
          type: "CUSTOM_FILE_UPLOAD",
          organisation: apiUser.organisation,
        },
      })
    );

    if (keepSheets) {
      const oldFileVersion = file.versions.items[file.versions.items.length - 1];
      const newFileVersionNumber = oldFileVersion.versionNumber + 1;
      await createFileVersionInApi({
        file,
        task,
        taskRevision,
        apiUser,
        versionNumber: newFileVersionNumber,
      });

      updatedFile = (
        await callGraphQL(
          "Could not retrieve file",
          graphqlOperation(getFile, {
            id: file.id,
          })
        )
      ).data.getFile;

      await pointSheetsToLatestFileVersion({
        apiUser,
        task,
        file: updatedFile,
        taskRevision,
      });
    } else {
      for (let i = 0; i < file.sheets.items.length; i++) {
        await callGraphQL(
          "Could not delete sheet",
          graphqlOperation(deleteSheet, {
            input: {
              id: file.sheets.items[i].id,
            },
          })
        );
      }

      for (let i = 0; i < file.versions.items.length; i++) {
        await callGraphQL(
          "Could not delete file version",
          graphqlOperation(deleteFileVersion, {
            input: {
              id: file.versions.items[i].id,
            },
          })
        );
      }

      let sheetNames: string[] = [];
      let sheetStatuses: string[] = [];

      if (HAS_SHEETS[file.type]) {
        for (let i = 0; i < sheetCount; i++) {
          sheetNames.push(sanitiseSheetName(formFields[`sheet-${i + 1}-name`]));
          sheetStatuses.push(formFields[`sheet-${i + 1}-status`]);
        }
      } else {
        sheetNames[0] = sanitiseSheetName(file.sheets.items[0].name);
        sheetStatuses[0] = formFields[`sheet-1-status`];
      }

      updatedFile = await createFileWithSheets({
        fileType: file.type,
        extension: FILE_TYPE_EXTENSIONS[file.type],
        apiUser,
        sheetCount,
        taskRevision,
        task,
        taskInitials: task.initials,
        doPublish: false,
        file,
        sheetNames,
        sheetStatuses,
        includeCreateFileStep: false,
        templateId: file.templateId,
      });

      history.replace(`${basePath}?tab=${updatedFile.sheets.items[0].id}`);
    }

    const latestFileVersion = updatedFile.versions.items[updatedFile.versions.items.length - 1];
    const newFileVersionKey = latestFileVersion.key.replace("public/", "");

    const fileToUploadExtension = getExtensionFromKey(`/${(fileToUpload as any).name}`);
    const newFileVersionExtension = getExtensionFromKey(newFileVersionKey);
    const updatedNewFileVersionKey = newFileVersionKey
      .split(`.${newFileVersionExtension}`)
      .join(`.${fileToUploadExtension}`);

    await Storage.put(updatedNewFileVersionKey, fileToUpload, {
      progressCallback(progress) {
        setUploadProgress(progress);
      },
    });

    await callGraphQL(
      "Could not update file version",
      graphqlOperation(updateFileVersion, {
        input: {
          id: latestFileVersion.id,
          key: `public/${updatedNewFileVersionKey}`,
        },
      })
    );
    setUploadProgress(undefined);

    await updateFileRandomNumber();
    await publish({
      file: updatedFile,
      fileVersionId: updatedFile.versions.items[updatedFile.versions.items.length - 1].id,
      taskRevisionId: taskRevision.id,
      task,
    });
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  let sheetFormItems: any[] = [];
  for (let i = 0; i < sheetCount; i++) {
    sheetFormItems.push(
      <div className="sheet-item-container" data-testid={`sheet-${i + 1}`} key={`sheet-${i + 1}`}>
        {HAS_SHEETS[file.type] && (
          <Form.Item
            label={`Sheet ${i + 1} name`}
            name={`sheet-${i + 1}-name`}
            rules={[
              {
                required: true,
                message: "Each sheet needs a name",
              },
              {
                validator: (_, sheetName) => {
                  return new Promise<void>((resolve, reject) => {
                    if (sheetName.match(SHEET_NAMES_INVALID_CHARACTERS_PATTERN)) {
                      return reject("");
                    }

                    return resolve();
                  });
                },
                message: "Sheet names can only contain letters, numbers, spaces, hyphens and underscores",
              },
            ]}
          >
            <Input className="sheet-name" />
          </Form.Item>
        )}
        <Form.Item
          label={HAS_SHEETS[file.type] ? `Sheet ${i + 1} status` : "File status"}
          name={`sheet-${i + 1}-status`}
          rules={[
            {
              required: true,
              message: "Each sheet needs a status",
            },
          ]}
        >
          <Select className="select-status">
            {organisationDetails.fileStatuses.map((status) => {
              return (
                <Select.Option key={status.name} value={getUppercaseStatus(status.name)}>
                  {status.name}
                </Select.Option>
              );
            })}
          </Select>
        </Form.Item>
      </div>
    );
  }

  let submitButtonLabel = "Submit";
  if (isLoading) {
    submitButtonLabel = "Loading...";
    if (uploadProgress) {
      submitButtonLabel = `Uploading... ${
        uploadProgress ? `${Math.floor(((uploadProgress as any).loaded / (uploadProgress as any).total) * 100)}%` : ""
      }`;
    }
  }

  return (
    <Modal
      maskClosable={false}
      title={`Upload ${FILE_TYPES_READABLE[file.type]} file`}
      open={visible}
      onCancel={onClose}
      footer={null}
      className="upload-custom-file-modal"
    >
      <label htmlFor="upload-custom-file" className="upload-button-container">
        <Button
          type="primary"
          onClick={() => document.querySelector("#upload-custom-file")?.click()}
          icon={<CloudUploadOutlined />}
        >
          <span>{fileToUpload ? (fileToUpload as any).name : "Choose file"}</span>
          <input id="upload-custom-file" style={{ display: "none" }} type="file" onChange={onFileInputChange} />
        </Button>
      </label>

      {!organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions && fileToUpload && (
        <InfoItem
          inline
          label={HAS_SHEETS[file.type] ? "Keep sheet names and history" : "Keep file history"}
          value={<Checkbox defaultChecked={true} onChange={(e) => setKeepSheets(e.target.checked)} />}
        />
      )}
      {keepSheets ? null : (
        <Alert
          type="info"
          showIcon
          message={
            HAS_SHEETS[file.type] ? (
              <>
                This will replace your existing {FILE_TYPES_READABLE[file.type]} file and will also erase the sheets and
                sheet revision history for this {getSimpleLabel("task revision")}. <br />
                New sheets will be created based on your input. <br />
              </>
            ) : (
              <>
                This will replace your existing {FILE_TYPES_READABLE[file.type]} file and will also erase the file
                revision history for this {getSimpleLabel("task revision")}. <br />
              </>
            )
          }
        />
      )}
      {fileToUpload && (
        <>
          {HAS_SHEETS[file.type] && !keepSheets && (
            <>
              <Alert
                type="info"
                showIcon
                message={
                  <>
                    You need to list all the sheets in this file so that DraughtHub can include them in the publish
                    process. Any sheets you do not include will be ignored.
                  </>
                }
              />

              <div className="add-remove-sheet-container">
                <Button onClick={() => setSheetCount((sheetCount) => sheetCount + 1)}>
                  <PlusOutlined />
                  Add sheet
                </Button>
                <Button onClick={() => setSheetCount((sheetCount) => sheetCount - 1)} disabled={sheetCount <= 1}>
                  <MinusOutlined /> Remove sheet
                </Button>
              </div>
            </>
          )}
          <Form form={form} onFinish={onSubmit}>
            {!keepSheets && sheetFormItems}

            <div className="submit-container">
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {submitButtonLabel}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: UploadCustomFileModal,
    subscriptions: ["organisationDetails"],
  })
);
