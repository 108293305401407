import React from "react";
import moment from "moment";
import { PlusCircleOutlined, DeleteOutlined, DownOutlined, RightOutlined, EditOutlined } from "@ant-design/icons";
import { message, Typography, Button, Modal, Tabs, Table } from "antd";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";
import * as userActions from "./userActions";
import { isAuthorised } from "common/permissions";

import InviteUserModal from "InviteUserModal/InviteUserModal";
import UserItem from "./UserItem/UserItem";

import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { LexoRank } from "lexorank";
import LazyLoadList from "LazyLoadList/LazyLoadList";
import Card from "Card/Card";
import CreateGroupModal from "Modals/CreateGroupModal/CreateGroupModal";
import CreateTeamModal from "Modals/CreateTeamModal/CreateTeamModal";
import AddPermissionsModal from "Modals/AddPermissionsModal/AddPermissionsModal";
import AddUserToGroupModal from "Modals/AddUserToGroupModal/AddUserToGroupModal";
import AddUserToTeamModal from "Modals/AddUserToTeamModal/AddUserToTeamModal";
import EditGroupNameModal from "Modals/EditGroupNameModal/EditGroupNameModal";
import EditTeamNameModal from "Modals/EditTeamNameModal/EditTeamNameModal";
import Avatar from "Avatar/Avatar";
import UserHierarchy from "./UserHierarchy/UserHierarchy";

import { PERMISSION_GROUPS, findFullPermissionDetails } from "common/permissions";
import { removeUserFromGroup } from "common/permissionOperations";
import { callGraphQL } from "common/helpers";
import { graphqlOperation } from "aws-amplify";
import { updateUser, deleteGroup, updateGroup, updateOrganisation } from "graphql/mutations";

import "./UsersPage.scss";

export class UsersPage extends React.Component {
  state = {
    selectedOrganisation: null,
    isInviteUserModalVisible: false,
    activeTab: "users",
    isCreateGroupModalVisible: false,
    isCreateTeamModalVisible: false,
    isAddPermissionsModalVisible: false,
    isAddUserToGroupModalVisible: false,
    isAddUserToTeamModalVisible: false,
    isEditGroupNameModalVisible: false,
    isEditTeamNameModalVisible: false,
    selectedGroup: null,
    selectedTeam: null,
  };

  componentDidMount() {
    if (!this.props.apiUser) {
      return;
    }
    this.props.setBoxedLayout(false);
    // this.props.setBackground(false);

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "USERS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);
    // this.props.setBackground(true);
  }

  confirmUserDisable = async (userData) => {
    const { apiUser } = this.props;

    Modal.confirm({
      title: "Confirm delete",
      className: "disable-user-modal",
      content: (
        <>
          Are you sure you want to delete{" "}
          <b>
            {userData.firstName} {userData.lastName}
          </b>{" "}
          from <b>{apiUser.organisation}?</b>
          <br />
          <br />
          Once a user is deleted, the work they have done will be preserved, but they will not be able to log in
          anymore.
        </>
      ),
      onOk: () =>
        userActions.disableUser({
          ...userData,
          organisation: apiUser.organisation,
        }),
    });
  };

  confirmUserEnable = async (userData, seatsLeft) => {
    const { apiUser } = this.props;

    if (seatsLeft < 1) {
      Modal.info({
        title: "Not enough seats left",
        className: "no-seats-left-modal",
        content: (
          <>
            Your organisation has used all its seats. If you want to enable this user, please contact us to purchase
            more seats or disable a different user first.
          </>
        ),
      });
      return;
    }

    if (userData.disabledAt) {
      let now = moment();
      let disabledDate = moment(userData.disabledAt);
      let differenceInDays = now.diff(disabledDate, "days");
      let cooldownInDays = 7;

      if (differenceInDays < cooldownInDays) {
        Modal.info({
          title: "User cannot be re-enabled during cool-down period",
          className: "no-reenable-during-cooldown-modal",
          content: (
            <>
              <b>
                {userData.firstName} {userData.lastName}
              </b>{" "}
              was disabled on <b>{disabledDate.format("DD MMMM YYYY")}</b>. <br />A user cannot be re-enabled for{" "}
              {cooldownInDays} days after it has been disabled. <br /> If you still need to re-enable this user before
              the cool-down period is finished, please contact us.
            </>
          ),
        });
        return;
      }
    }

    Modal.confirm({
      title: "Confirm user enable",
      className: "enable-user-modal",
      content: (
        <>
          Are you sure you want to enable{" "}
          <b>
            {userData.firstName} {userData.lastName}
          </b>{" "}
          from <b>{apiUser.organisation}?</b>
          <br />
        </>
      ),
      onOk: () =>
        userActions.enableUser({
          ...userData,
          organisation: apiUser.organisation,
        }),
    });
  };

  removeUserFromTeam = ({ team, user }) => {
    Modal.confirm({
      title: "Confirm team membership removal",
      content: (
        <>
          Are you sure you want to remove{" "}
          <b>
            {user.firstName} {user.lastName}
          </b>{" "}
          from <b>{team.label}?</b>
        </>
      ),
      onOk: async () =>
        await callGraphQL(
          "Could not remove user from team",
          graphqlOperation(updateUser, {
            input: {
              id: user.id,
              teams: user?.teams?.filter((crtTeam) => crtTeam !== team.id),
            },
          })
        ),
    });
  };

  deletePermission = (group, permissionToBeDeleted) => {
    Modal.confirm({
      title: "Confirm permission delete",
      content: (
        <>
          Are you sure you want to remove the permission <b>{permissionToBeDeleted.label}</b> from <b>{group.name}?</b>
        </>
      ),
      onOk: async () =>
        await callGraphQL(
          "Could not create group",
          graphqlOperation(updateGroup, {
            input: {
              id: group.id,
              permissions: group?.permissions?.filter((permissionId) => permissionToBeDeleted.id !== permissionId),
            },
          })
        ),
    });
  };

  displayDetailsPerGroup = (group) => {
    const { users, apiUser } = this.props;

    const groupMembersFullDetails = users.filter((user) => {
      if (!group?.members?.includes(user.id)) {
        return false;
      }

      if (user.isHidden && user.id !== apiUser.id) {
        return false;
      }

      return true;
    });

    return (
      <Tabs>
        <Tabs.TabPane tab={<Typography.Text>Users ({groupMembersFullDetails?.length})</Typography.Text>} key="users">
          <Table
            showHeader={false}
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            expandRowByClick={true}
            columns={[
              {
                key: "user",
                align: "left",
                render: (_, user) => <Avatar user={user} size="normal" showLabel showHoverAnimation={false} />,
              },
              {
                key: "actions",
                width: 50,
                render: (_, user) => {
                  return (
                    <>
                      {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                        <Button onClick={() => removeUserFromGroup(group, user)} icon={<DeleteOutlined />} />
                      )}
                    </>
                  );
                },
              },
            ]}
            dataSource={groupMembersFullDetails}
          />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Permissions" key="permissions">
          <Table
            showHeader={false}
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            expandIconColumnIndex={-1}
            columns={[
              {
                title: "",
                key: "label",
                dataIndex: "label",
                align: "left",
              },
              {
                title: "Permissions",
                align: "right",
                render: (_, row) => {
                  return (
                    <>
                      {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                        <Button onClick={() => this.deletePermission(group, row)} icon={<DeleteOutlined />} />
                      )}
                    </>
                  );
                },
              },
            ]}
            dataSource={group?.permissions?.map((permissionId) => {
              const fullPermissionDetails = findFullPermissionDetails(permissionId, PERMISSION_GROUPS);
              if (!fullPermissionDetails) {
                return {
                  id: permissionId,
                  label: permissionId,
                };
              }
              return fullPermissionDetails;
            })}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  displayDetailsPerTeam = (team) => {
    const { users } = this.props;

    const teamMembers = users.filter((user) => user.teams?.includes(team.id));

    return (
      <Tabs>
        <Tabs.TabPane tab={<Typography.Text>Users ({teamMembers?.length})</Typography.Text>} key="users">
          <Table
            showHeader={false}
            pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
            expandRowByClick={true}
            columns={[
              {
                key: "user",
                align: "left",
                render: (_, user) => <Avatar user={user} size="normal" showLabel showHoverAnimation={false} />,
              },
              {
                key: "actions",
                width: 50,
                render: (_, user) => {
                  return (
                    <>
                      {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                        <Button onClick={() => this.removeUserFromTeam({ team, user })} icon={<DeleteOutlined />} />
                      )}
                    </>
                  );
                },
              },
            ]}
            dataSource={teamMembers}
          />
        </Tabs.TabPane>
      </Tabs>
    );
  };

  deleteGroup = async (group) => {
    Modal.confirm({
      title: "Confirm group deletion",
      className: "delete-group-modal",
      content: (
        <>
          Are you sure you want to delete the group <b>{group.name}</b>?
        </>
      ),
      onOk: async () =>
        await callGraphQL(
          "Could not delete group",
          graphqlOperation(deleteGroup, {
            input: {
              id: group.id,
            },
          })
        ),
    });
  };

  deleteTeam = async (team) => {
    const { organisationDetails, users } = this.props;

    const usersForTeam = users.filter((user) => user.teams?.includes(team.id));

    if (usersForTeam.length > 0) {
      message.error("Cannot delete team with users assigned to it");
      return;
    }

    Modal.confirm({
      title: "Confirm team deletion",
      className: "delete-team-modal",
      content: (
        <>
          Are you sure you want to delete the team <b>{team.label}</b>?
        </>
      ),
      onOk: async () =>
        await callGraphQL(
          "Could not delete team",
          graphqlOperation(updateOrganisation, {
            input: {
              id: organisationDetails.id,
              teams: (organisationDetails.teams || []).filter((x) => x.id !== team.id),
            },
          })
        ),
    });
  };

  displayGroupList = () => {
    const { groups } = this.props;

    let groupColumns = [
      {
        title: "Group name",
        key: "id",
        align: "left",
        render: (_, group) => {
          return (
            <div>
              {group?.name}
              {group.members?.length > 0
                ? ` (${group.members.length} member${group.members.length === 1 ? "" : "s"})`
                : ""}
            </div>
          );
        },
      },
    ];

    if (isAuthorised(["IAM.EDIT_PERMISSIONS"])) {
      groupColumns.push({
        title: "Actions",
        key: "actions",
        fixed: "right",
        width: "45%",
        align: "center",
        render: (_, group) => {
          return (
            <div className="group-actions">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isAddUserToGroupModalVisible: true });
                  this.setState({ selectedGroup: group });
                }}
                className="group-action-button"
                icon={<PlusCircleOutlined />}
              >
                Add user
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isAddPermissionsModalVisible: true });
                  this.setState({ selectedGroup: group });
                }}
                className="group-action-button"
                icon={<PlusCircleOutlined />}
              >
                Add permissions
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isEditGroupNameModalVisible: true });
                  this.setState({ selectedGroup: group });
                }}
                className="group-action-button"
                icon={<EditOutlined />}
              >
                Edit group name
              </Button>
              <Button onClick={() => this.deleteGroup(group)} icon={<DeleteOutlined />}>
                Delete group
              </Button>
            </div>
          );
        },
      });
    }

    return (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={groupColumns}
        expandable={
          isAuthorised(["IAM.VIEW"]) && {
            expandedRowRender: this.displayDetailsPerGroup,
            rowExpandable: (record) => record.name !== "Not Expandable",
            expandIcon: ({ expanded, onExpand, record }) =>
              expanded ? (
                <Button icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
              ) : (
                <Button icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
              ),
          }
        }
        dataSource={groups}
      />
    );
  };

  displayTeamList = () => {
    const { organisationDetails } = this.props;

    let teamColumns = [
      {
        title: "Team name",
        key: "id",
        align: "left",
        render: (_, team) => {
          return <div>{team?.label}</div>;
        },
      },
    ];

    if (isAuthorised(["IAM.EDIT_TEAM_MEMBERSHIP"])) {
      teamColumns.push({
        title: "Actions",
        key: "actions",
        fixed: "right",
        width: "45%",
        align: "center",
        render: (_, team) => {
          return (
            <div className="team-actions">
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isAddUserToTeamModalVisible: true });
                  this.setState({ selectedTeam: team });
                }}
                className="team-action-button"
                icon={<PlusCircleOutlined />}
              >
                Add user
              </Button>
              <Button
                type="primary"
                onClick={() => {
                  this.setState({ isEditTeamNameModalVisible: true });
                  this.setState({ selectedTeam: team });
                }}
                className="team-action-button"
                icon={<EditOutlined />}
              >
                Edit team name
              </Button>
              <Button onClick={() => this.deleteTeam(team)} icon={<DeleteOutlined />}>
                Delete team
              </Button>
            </div>
          );
        },
      });
    }

    return (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 1000 }}
        rowKey="id"
        columns={teamColumns}
        expandable={{
          expandedRowRender: this.displayDetailsPerTeam,
          rowExpandable: (record) => record.name !== "Not Expandable",
          expandIcon: ({ expanded, onExpand, record }) =>
            expanded ? (
              <Button icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
            ) : (
              <Button icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
            ),
        }}
        dataSource={organisationDetails.teams || []}
      />
    );
  };

  displayUserList = (usersToDisplay, includeDragging) => {
    const { organisationDetails, apiUser } = this.props;

    const columns = [
      {
        title: "Name",
        key: "name",
        render: (_, user) => {
          // console.log("user:", user);
          return (
            <>
              <Typography.Text className="name-container">
                <Avatar user={user} size="normal" showLabel showHoverAnimation={false} />
                {/* <Tag /> */}
              </Typography.Text>
            </>
          );
        },
      },
      {
        title: "Job title",
        key: "jobTitle",
      },
      {
        title: "Qualifications",
        key: "qualifications",
      },
      {
        title: "Email",
        key: "email",
      },
    ];

    if (!includeDragging) {
      return (
        <LazyLoadList
          initialChunkSize={30}
          subsequentChunkSize={30}
          overshootSize={15}
          list={usersToDisplay}
          item={(user, index) => {
            return (
              <div className="user-item-container">
                <UserItem
                  organisationDetails={organisationDetails}
                  apiUser={apiUser}
                  userData={user}
                  confirmUserDisable={this.confirmUserDisable}
                  removeUserFromTeam={this.removeUserFromTeam}
                />
              </div>
            );
          }}
        />
      );
    }

    return (
      <>
        <LazyLoadList
          initialChunkSize={30}
          subsequentChunkSize={30}
          overshootSize={15}
          list={usersToDisplay}
          item={(user, index) => {
            return (
              <Draggable key={user.id} draggableId={user.id} index={index} className={`draggable-user ${user.id}`}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className="user-item-container"
                  >
                    <UserItem
                      organisationDetails={organisationDetails}
                      apiUser={apiUser}
                      userData={user}
                      confirmUserDisable={this.confirmUserDisable}
                      removeUserFromTeam={this.removeUserFromTeam}
                    />
                  </div>
                )}
              </Draggable>
            );
          }}
        />
        {/* <Table columns={columns} dataSource={usersToDisplay} /> */}
      </>
    );
  };

  onDragEnd = async (result) => {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const { setProps, context, users, orderedActiveUsers } = this.props;

    let newOrderedActiveUsers = [...orderedActiveUsers];

    const newIndex = result.destination.index;

    let destinationOrder = LexoRank.parse(orderedActiveUsers[newIndex].order);

    let newOrder;
    if (result.destination.index === result.source.index) {
      // no movement needed
      return;
    } else if (newIndex === 0) {
      newOrder = destinationOrder.genPrev();
    } else if (result.destination.index < result.source.index) {
      newOrderedActiveUsers = newOrderedActiveUsers.filter((x) => x.id !== result.draggableId);
      destinationOrder = LexoRank.parse(newOrderedActiveUsers[newIndex].order);
      let beforeItemOrder = LexoRank.parse(newOrderedActiveUsers[newIndex - 1].order);
      newOrder = destinationOrder.between(beforeItemOrder);
    } else if (newIndex === newOrderedActiveUsers.length - 1) {
      newOrder = destinationOrder.genNext();
    } else {
      let afterItemOrder = LexoRank.parse(newOrderedActiveUsers[newIndex + 1].order);
      newOrder = afterItemOrder.between(destinationOrder);
    }

    setProps({
      context: {
        ...context,
        users: users
          .filter((user) => !user.isDisabled)
          .map((user) => {
            if (user.id !== result.draggableId) {
              return user;
            }

            return {
              ...user,
              order: newOrder.value,
            };
          }),
      },
    });

    await callGraphQL(
      "Failed to update job title",
      graphqlOperation(updateUser, {
        input: {
          id: result.draggableId,
          order: newOrder.value,
        },
      })
    );
  };

  render() {
    const {
      isInviteUserModalVisible,
      isCreateGroupModalVisible,
      isCreateTeamModalVisible,
      isAddPermissionsModalVisible,
      isAddUserToGroupModalVisible,
      isAddUserToTeamModalVisible,
      isEditGroupNameModalVisible,
      isEditTeamNameModalVisible,
      selectedGroup,
      selectedTeam,
      activeTab,
    } = this.state;
    const { organisations, organisationDetails, apiUser, users, orderedActiveUsers } = this.props;

    let validUsers = users.filter(
      (user) => user.organisation === organisationDetails.id && !user.isHidden && !user.isDisabled
    );

    let visibleUsers = [...orderedActiveUsers].filter((user) => !user.isHidden && !user.isDisabled);
    let invisibleUsers = validUsers.filter((user) => !visibleUsers.includes(user));

    return (
      <div className="users-page">
        <Tabs
          className="users-page-tabs"
          activeKey={activeTab}
          onTabClick={(tabKey) => {
            this.props.history.push(`/users?tab=${tabKey}`);
            this.setState({ activeTab: tabKey });
          }}
          tabBarExtraContent={{
            right: (
              <div className="main-actions">
                <Typography.Title className="users-page-title">
                  {visibleUsers.length} User
                  {visibleUsers.length === 1 ? "" : "s"}
                </Typography.Title>

                <Button
                  className="invite-user"
                  type="primary"
                  onClick={() => this.setState({ isInviteUserModalVisible: true })}
                  icon={<PlusCircleOutlined />}
                >
                  Invite New
                </Button>
              </div>
            ),
          }}
        >
          <Tabs.TabPane tab="Users" key="users">
            {organisationDetails.settings?.general?.requirePermissionToDisplayUsers && (
              <Typography.Text className="section-title visible-users-title">
                Users visible in lists, filters, etc. ({visibleUsers.length})
              </Typography.Text>
            )}
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div className="main-content" {...provided.droppableProps} ref={provided.innerRef}>
                    {this.displayUserList(visibleUsers, true)}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {organisationDetails.settings?.general?.requirePermissionToDisplayUsers && (
              <>
                <Typography.Text className="section-title">
                  Users not visible in lists, filters, etc. ({invisibleUsers.length})
                </Typography.Text>
                <div className="main-content">{this.displayUserList(invisibleUsers, false)}</div>
              </>
            )}
          </Tabs.TabPane>
          <Tabs.TabPane tab="Groups" key="groups">
            <div className="main-content">
              <Card
                title="Groups"
                withSpace
                actions={
                  <div>
                    {isAuthorised(["IAM.EDIT_PERMISSIONS"]) && (
                      <Button
                        type="primary"
                        onClick={() => this.setState({ isCreateGroupModalVisible: true })}
                        icon={<PlusCircleOutlined />}
                      >
                        Create group
                      </Button>
                    )}
                  </div>
                }
              >
                {this.displayGroupList()}
              </Card>
            </div>
          </Tabs.TabPane>
          {organisationDetails.settings?.general?.usesTeams && (
            <Tabs.TabPane tab="Teams" key="teams">
              <div className="main-content">
                <Card
                  title="Teams"
                  withSpace
                  actions={
                    <div>
                      {isAuthorised(["IAM.EDIT_TEAM_MEMBERSIP"]) && (
                        <Button
                          type="primary"
                          onClick={() => this.setState({ isCreateTeamModalVisible: true })}
                          icon={<PlusCircleOutlined />}
                        >
                          Create team
                        </Button>
                      )}
                    </div>
                  }
                >
                  {this.displayTeamList()}
                </Card>
              </div>
            </Tabs.TabPane>
          )}
          <Tabs.TabPane tab="Hierarchy" key="hierarchy">
            <UserHierarchy users={visibleUsers} />
          </Tabs.TabPane>
        </Tabs>
        {isCreateGroupModalVisible ? (
          <CreateGroupModal
            onClose={() => {
              this.setState({ isCreateGroupModalVisible: false });
            }}
            apiUser={apiUser}
            organisations={organisations}
            organisationDetails={organisationDetails}
          />
        ) : null}
        {isCreateTeamModalVisible ? (
          <CreateTeamModal
            onClose={() => {
              this.setState({ isCreateTeamModalVisible: false });
            }}
          />
        ) : null}
        {isAddPermissionsModalVisible && selectedGroup && (
          <AddPermissionsModal
            onClose={() => {
              this.setState({ isAddPermissionsModalVisible: false });
            }}
            selectedParent={selectedGroup}
            mutation={updateGroup}
            parentType={"group"}
          />
        )}
        {isAddUserToGroupModalVisible && selectedGroup && (
          <AddUserToGroupModal
            onClose={() => {
              this.setState({ isAddUserToGroupModalVisible: false });
            }}
            selectedGroup={selectedGroup}
            orderedActiveUsers={orderedActiveUsers}
          />
        )}
        {isAddUserToTeamModalVisible && selectedTeam && (
          <AddUserToTeamModal
            onClose={() => {
              this.setState({ isAddUserToTeamModalVisible: false });
            }}
            selectedTeam={selectedTeam}
            users={users}
          />
        )}
        {isEditGroupNameModalVisible && selectedGroup && (
          <EditGroupNameModal
            onClose={() => {
              this.setState({ isEditGroupNameModalVisible: false });
            }}
            selectedGroup={selectedGroup}
          />
        )}
        {isEditTeamNameModalVisible && selectedTeam && (
          <EditTeamNameModal
            onClose={() => {
              this.setState({ isEditTeamNameModalVisible: false });
            }}
            selectedTeam={selectedTeam}
          />
        )}
        {isInviteUserModalVisible && (
          <InviteUserModal
            onClose={() => this.setState({ isInviteUserModalVisible: false })}
            apiUser={apiUser}
            users={users}
            organisationDetails={organisationDetails}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: UsersPage,
    subscriptions: ["users", "organisations", "groups", "organisationDetails"],
  })
);
