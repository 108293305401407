import { useState } from "react";
import { PlusCircleOutlined } from "@ant-design/icons";
import { Select, Button, Checkbox, Typography, Radio } from "antd";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";

import { graphqlOperation } from "aws-amplify";
import { callGraphQL, getLabel } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import { getUserReadableCatLevel } from "common/helpers";
import { TASK_SETTINGS } from "common/settings";
import { getSimpleLabel } from "common/labels";

import CreateTaskStatusModal from "Modals/CreateTaskStatusModal/CreateTaskStatusModal";
import CreateFileStatusModal from "Modals/CreateFileStatusModal/CreateFileStatusModal";
import TaskStatusList from "./TaskStatusList/TaskStatusList";
import FileStatusList from "./FileStatusList/FileStatusList";
import CustomFieldList from "./CustomFieldList/CustomFieldList";
import AttachmentTemplateList from "../AttachmentTemplateList/AttachmentTemplateList";
import TaskPrioritiesCard from "./TaskPrioritiesCard/TaskPrioritiesCard";
import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import Card from "Card/Card";

import "./TaskSettingsPage.scss";

export function TaskSettingsPage(props) {
  const { organisationDetails, sprints } = props;
  const [isCreateTaskStatusModalVisible, setIsCreateTaskStatusModalVisible] = useState(false);
  const [isCreateFileStatusModalVisible, setIsCreateFileStatusModalVisible] = useState(false);

  const backlogSprint = sprints[0];

  const checked = TASK_SETTINGS.filter((module) => {
    let checked = false;
    if (!module.SCHEMA_FIELD.includes(".")) {
      checked = organisationDetails[module.SCHEMA_FIELD];
      return checked;
    } else {
      const schemaFieldParts = module.SCHEMA_FIELD.split(".");
      let currentValue = organisationDetails;
      schemaFieldParts.forEach((fieldName) => {
        currentValue = currentValue && currentValue[fieldName];
      });
      return !!currentValue;
    }
  });
  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  const displayTaskStatuses = () => {
    const { setProps, context } = props;

    if (!organisationDetails.taskStatuses || organisationDetails.taskStatuses.length === 0) {
      return null;
    }

    return <TaskStatusList organisationDetails={organisationDetails} setProps={setProps} context={context} />;
  };

  const displayFileStatuses = () => {
    const { organisationDetails, setProps, context } = props;

    if (!organisationDetails.fileStatuses || organisationDetails.fileStatuses.length === 0) {
      return null;
    }

    return <FileStatusList organisationDetails={organisationDetails} setProps={setProps} context={context} />;
  };

  function getInput({ organisationDetails, checked, module }) {
    let input = organisationDetails;
    let schemaFieldParts = module.SCHEMA_FIELD.split(".");
    if (schemaFieldParts.length === 1) {
      input = { [module.SCHEMA_FIELD]: checked };
    } else {
      let currentInputLevel = input;
      schemaFieldParts.forEach((fieldName, levelIndex) => {
        if (levelIndex === schemaFieldParts.length - 1) {
          currentInputLevel[fieldName] = checked;
        } else {
          currentInputLevel[fieldName] = { ...currentInputLevel[fieldName] };
        }
        currentInputLevel = currentInputLevel[fieldName];
      });
    }
    return input;
  }

  async function changeDefaultSprint(defaultSprint) {
    await callGraphQL(
      `Could not update default ${getSimpleLabel("sprint")}}`,
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          settings: {
            ...organisationDetails.settings,
            task: {
              ...organisationDetails.settings?.task,
              defaultSprint,
            },
          },
        },
      })
    );
  }

  async function changeDefaultCatLevel(defaultTaskCatLevel) {
    await callGraphQL(
      `Could not update default ${getSimpleLabel("authority level")}`,
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          settings: {
            ...organisationDetails.settings,
            task: {
              ...organisationDetails.settings?.task,
              defaultTaskCatLevel,
            },
          },
        },
      })
    );
  }

  return (
    <div>
      <Card withSpace title={`General ${getSimpleLabel("task")} settings`}>
        <Checkbox.Group defaultValue={checkedSettings}>
          {TASK_SETTINGS.filter((setting) => setting.INPUT_TYPE !== "text").map((module) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = module;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getInput({
                    organisationDetails: {
                      settings: organisationDetails.settings || {},
                    },
                    checked: e.target.checked,
                    module,
                  });
                  await callGraphQL(
                    "Could not update setting",
                    graphqlOperation(updateOrganisation, {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    })
                  );
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
        <br />
        {TASK_SETTINGS.filter((setting) => setting.INPUT_TYPE === "text").map((module) => {
          const { NAME, DESCRIPTION, SCHEMA_FIELD } = module;

          return (
            <InfoItem
              key={SCHEMA_FIELD}
              label={NAME}
              value={<Typography.Text className="setting-description">{DESCRIPTION}</Typography.Text>}
              extraContent={
                <Input
                  defaultValue={
                    organisationDetails.settings?.task &&
                    organisationDetails.settings?.task[SCHEMA_FIELD.split(".").pop()]
                  }
                  showBorder
                  fullWidth
                  onChange={async (value) => {
                    await callGraphQL(
                      "Could not update setting",
                      graphqlOperation(updateOrganisation, {
                        input: {
                          id: organisationDetails.id,
                          settings: {
                            ...organisationDetails?.settings,
                            task: {
                              ...(organisationDetails.settings?.task || {}),
                              [SCHEMA_FIELD.split(".").pop()]: value,
                            },
                          },
                        },
                      })
                    );
                  }}
                />
              }
            />
          );
        })}

        {organisationDetails.usesSprints && (
          <div className="task-default-sprint">
            <Typography.Text className="task-default-sprint-label">
              Default{" "}
              {getLabel({
                organisationDetails,
                id: "sprint",
                defaultValue: "sprint",
              })}{" "}
              for new {getSimpleLabel("tasks")}:{" "}
            </Typography.Text>
            <div>
              <Radio.Group
                value={organisationDetails.settings?.task?.defaultSprint || "BACKLOG"}
                onChange={(e) => changeDefaultSprint(e.target.value)}
              >
                <Radio key="ACTIVE" value="ACTIVE">
                  Active sprint
                </Radio>
                <Radio key="BACKLOG" value="BACKLOG">
                  {backlogSprint.name}
                </Radio>
              </Radio.Group>
            </div>
          </div>
        )}
        <div className="task-default-cat-level">
          <Typography.Text className="task-default-cat-level-label">
            Default {getSimpleLabel("authority level")} for new {getSimpleLabel("tasks")}
          </Typography.Text>
          <div>
            <Select
              className="cat-level-select"
              allowClear
              onChange={changeDefaultCatLevel}
              value={organisationDetails.settings?.task?.defaultTaskCatLevel}
              style={{ width: 200 }}
              placeholder="Not set"
            >
              <Select.Option key={0} value={0}>
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Select.Option>
              <Select.Option key={1} value={1}>
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Select.Option>
              <Select.Option key={2} value={2}>
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Select.Option>
              <Select.Option key={3} value={3}>
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Select.Option>
            </Select>
          </div>
        </div>
      </Card>
      <CustomFieldList />
      <AttachmentTemplateList organisationDetails={organisationDetails} type="task" />
      <Card
        withSpace
        title={`${getSimpleLabel("Task")} statuses`}
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            data-cy="add-task-status-button"
            onClick={() => setIsCreateTaskStatusModalVisible(true)}
          >
            Add {getSimpleLabel("task")} status
          </Button>
        }
      >
        {displayTaskStatuses()}
        <CreateTaskStatusModal
          visible={isCreateTaskStatusModalVisible}
          onClose={() => setIsCreateTaskStatusModalVisible(false)}
        />
      </Card>
      <Card
        withSpace
        title="File statuses"
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => setIsCreateFileStatusModalVisible(true)}
            data-cy="add-file-status-button"
          >
            Add file status
          </Button>
        }
      >
        {displayFileStatuses()}
        <CreateFileStatusModal
          visible={isCreateFileStatusModalVisible}
          onClose={() => setIsCreateFileStatusModalVisible(false)}
        />
      </Card>
      {organisationDetails.settings?.task?.usesPriority && <TaskPrioritiesCard />}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TaskSettingsPage,
    subscriptions: ["sprints"],
  })
);
