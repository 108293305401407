import { CheckCircleFilled, ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { Modal, Typography, Button } from "antd";
import cookie from "js-cookie";

import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { callGraphQL } from "common/helpers";
import cx from "classnames";

import Card from "Card/Card";

import "./UserComputers.scss";

export default function UserComputers({ user }) {
  const computerId = cookie.get("computer-id");

  async function deleteComputer(computerIdToDelete) {
    try {
      await new Promise<void>((resolve, reject) => {
        Modal.confirm({
          title: "Confirm delete computer",
          maskClosable: true,
          content: (
            <>
              Are you sure you want to delete computer{" "}
              <b>{user.computers.find((computer) => computer.id === computerIdToDelete).name}</b>?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    callGraphQL(
      "Failed to delete computer",
      graphqlOperation(updateUser, {
        input: {
          id: user.id,
          computers: (user.computers || []).filter((computer) => computer.id !== computerIdToDelete),
        },
      })
    );
  }

  function displayComputers() {
    return (user.computers || []).map((computer) => {
      return (
        <div key={computer.id} className="computer-item">
          {computer.id === user.lastActiveComputer ? (
            <div className="active-computer-mark" />
          ) : (
            <div className="inactive-computer-mark" />
          )}
          <Typography.Text className="computer-name">
            {computer.name}{" "}
            {computerId === computer.id ? <span className="this-computer-mark">(this computer)</span> : null}
          </Typography.Text>
          <Typography.Text
            className={cx("logstream-name-and-link-info", {
              "with-logstream": computer.cloudWatchLogStreamName,
            })}
          >
            {computer.cloudWatchLogStreamName && (
              <a
                className="logstream-name"
                href={`https://eu-west-2.console.aws.amazon.com/cloudwatch/home?region=eu-west-2#logsV2:log-groups/log-group/DraughtHub-Link/log-events/${computer.cloudWatchLogStreamName}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Go to AWS CloudWatch
              </a>
            )}

            <span className="link-info">
              {computer.linkIsInstalled ? (
                <span className="link-installed">
                  DraughtHub Link is installed <CheckCircleFilled />
                </span>
              ) : (
                <span className="link-not-installed">
                  DraughtHub Link is not installed <ExclamationCircleOutlined />{" "}
                </span>
              )}
              <br />

              {computer.linkIsRunning ? (
                <span className="link-running">
                  DraughtHub Link is running <CheckCircleFilled />
                </span>
              ) : (
                <span className="link-not-running">
                  DraughtHub Link is not running <ExclamationCircleOutlined />{" "}
                </span>
              )}
            </span>
          </Typography.Text>
          <Button className="delete-computer" icon={<DeleteOutlined />} onClick={() => deleteComputer(computer.id)} />
        </div>
      );
    });
  }
  return (
    <Card title="Computers" className="user-computers">
      {!user.computers || user.computers.length === 0 ? (
        <Typography.Text className="no-configured-computers">No computers configured yet</Typography.Text>
      ) : (
        displayComputers()
      )}
    </Card>
  );
}
