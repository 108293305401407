import { useState } from "react";
import { Form, Modal, Button, Input, Alert } from "antd";

import { updateOrganisation } from "graphql/mutations";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQL } from "common/helpers";
import { graphqlOperation } from "aws-amplify";

import "./EditTeamNameModal.scss";

export function EditTeamNameModal({ onClose, selectedTeam, organisationDetails }) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmit({ label }) {
    setIsLoading(true);
    setError();

    try {
      let updatedTeams = JSON.parse(JSON.stringify(organisationDetails.teams || []));
      updatedTeams.forEach((team) => {
        if (team.id === selectedTeam.id) {
          team.label = label;
        }
      });

      await callGraphQL(
        "Could not edit team name",
        graphqlOperation(updateOrganisation, {
          input: {
            id: organisationDetails.id,
            teams: updatedTeams,
          },
        })
      );
      onClose();
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit team name"
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="edit-team-name-modal"
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form {...layout} form={form} onFinish={onSubmit} initialValues={{ label: selectedTeam.label }}>
        <Form.Item
          label="Team name"
          name="label"
          rules={[
            {
              required: true,
              message: "You must specify a team name",
            },
            {
              validator: !selectedTeam
                ? undefined
                : async (_, label) => {
                    let teamWithSameLabel = (organisationDetails.teams || []).find((team) => team?.label === label);
                    if (teamWithSameLabel) {
                      throw new Error("Team name must be unique");
                    }
                  },
            },
          ]}
        >
          <Input autoComplete="off" className="team-name" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: EditTeamNameModal,
  subscriptions: ["organisationDetails"],
});
