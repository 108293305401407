import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Typography } from "antd";

import { graphqlOperation } from "aws-amplify";
import RichTextEditor from "react-rte";
import _ from "lodash";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQL, useStateRef } from "common/helpers";
import { updateSupplier } from "graphql/mutations";

import Card from "Card/Card";
import Input from "Input/Input";

import "./SupplierSummary.scss";

export function SupplierSummary({ supplier }) {
  const debouncedChangeNotes = useRef(_.debounce(() => changeNotes(), 500)).current;

  const [notes, setNotes, notesRef] = useStateRef(RichTextEditor.createValueFromString(supplier.notes || "", "html"));
  const [isNotesInputOpen, setIsNotesInputOpen] = useStateRef(false);

  useEffect(() => {
    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []); // eslint-disable-line

  function onWindowClick() {
    setIsNotesInputOpen(false);
    changeNotes();
  }

  async function changeNotes() {
    let newNotes = notesRef.current.toString("html");
    if (
      supplier.notes === newNotes ||
      ((supplier.notes === null || supplier.notes === undefined) && newNotes === "<p><br></p>")
    ) {
      return;
    }

    await callGraphQL(
      "Failed to update notes",
      graphqlOperation(updateSupplier, {
        input: {
          id: supplier.id,
          notes: newNotes,
        },
      })
    );
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "bold" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  function setEditorFocus() {
    let editorElement = document.querySelector(".public-DraftEditor-content");
    if (!editorElement || !editorElement.isContentEditable) {
      setTimeout(setEditorFocus, 100);
      return;
    }

    editorElement.focus();
  }

  async function changeSupplierName(name) {
    await callGraphQL(
      "Failed to update name",
      graphqlOperation(updateSupplier, {
        input: {
          id: supplier.id,
          name,
        },
      })
    );
  }

  return (
    <>
      <Card
        data-cy="supplier-details-summary"
        className={cx("supplier-summary")}
        title={
          <>
            <div className="supplier-details-container">
              <div className="supplier-name-container">
                <Input
                  data-cy="supplier-name"
                  defaultValue={supplier.name}
                  className="supplier-name"
                  onChange={changeSupplierName}
                  fullWidth
                  multiLine
                  allowEnter={false}
                />
              </div>
            </div>

            <Typography.Paragraph className="supplier-notes-label">Notes:</Typography.Paragraph>

            <div
              onClick={(e) => {
                setIsNotesInputOpen(true);
                e.stopPropagation();
                setEditorFocus();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
            >
              <RichTextEditor
                value={notes}
                autoFocus={true}
                onChange={(value) => {
                  setNotes(value);
                  debouncedChangeNotes();
                }}
                className="supplier-notes"
                readOnly={!isNotesInputOpen}
                toolbarConfig={toolbarConfig}
              />
            </div>
          </>
        }
      />
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: SupplierSummary,
    subscriptions: ["organisationDetails"],
  })
);
