export function getSimpleLabel(labelId) {
  let organisationDetails;
  if (global.isBrowser) {
    organisationDetails = window.organisationDetails;
  } else {
    organisationDetails = global.organisationDetails;
  }

  let labelDefinition = CUSTOM_LABELS.find((x) => x.id === labelId);
  let value;
  if (labelDefinition) {
    value = labelDefinition.defaultValue;
  }

  if (organisationDetails && organisationDetails.labels && Array.isArray(organisationDetails.labels)) {
    for (let i = 0; i < organisationDetails.labels.length; i++) {
      const label = organisationDetails.labels[i];
      if (label.id === labelId && label.value) {
        value = label.value;
      }
    }
  }

  return value || labelId;
}

const CUSTOM_LABELS = [
  { id: "Timeline", defaultValue: "Timeline" },
  { id: "timeline", defaultValue: "timeline" },
  { id: "Board", defaultValue: "Board" },
  { id: "Library", defaultValue: "Library" },
  { id: "Quotes", defaultValue: "Quotes" },
  { id: "Quote", defaultValue: "Quote" },
  { id: "quote", defaultValue: "quote" },
  { id: "quotes", defaultValue: "quotes" },
  { id: "Clients", defaultValue: "Clients" },
  { id: "Client", defaultValue: "Client" },
  { id: "clients", defaultValue: "clients" },
  { id: "client", defaultValue: "client" },
  { id: "Confirmed", defaultValue: "Confirmed" },
  { id: "Unconfirmed", defaultValue: "Unconfirmed" },
  { id: "confirmed", defaultValue: "confirmed" },
  { id: "unconfirmed", defaultValue: "unconfirmed" },
  { id: "quote-unit-price", defaultValue: "Unit price" },
  { id: "quote-authority-level", defaultValue: "Authority level" },
  { id: "quote-check-price", defaultValue: "Check price" },
  { id: "quote-quantity", defaultValue: "Quantity" },
  { id: "quote-sector", defaultValue: "Sales sector" },
  { id: "quote-hourly", defaultValue: "Time charge" },
  { id: "Sprint", defaultValue: "Sprint" },
  { id: "Sprints", defaultValue: "Sprints" },
  { id: "sprint", defaultValue: "sprint" },
  { id: "sprints", defaultValue: "sprints" },
  { id: "active sprint", defaultValue: "active sprint" },
  { id: "Sprint-Active", defaultValue: "Active" },
  { id: "End sprint", defaultValue: "End sprint" },
  { id: "Start sprint", defaultValue: "Start sprint" },
  { id: "cat-null", defaultValue: "Not set" },
  { id: "cat-zero", defaultValue: "Cat 0" },
  { id: "cat-one", defaultValue: "Cat 1" },
  { id: "cat-two", defaultValue: "Cat 2" },
  { id: "cat-three", defaultValue: "Cat 3" },
  { id: "No Cat Level", defaultValue: "No Cat Level" },
  { id: "phone-1", defaultValue: "Phone 1" },
  { id: "phone-2", defaultValue: "Phone 2" },
  { id: "project", defaultValue: "project" },
  { id: "projects", defaultValue: "projects" },
  { id: "Project", defaultValue: "Project" },
  { id: "Projects", defaultValue: "Projects" },
  { id: "task", defaultValue: "task" },
  { id: "tasks", defaultValue: "tasks" },
  { id: "Task", defaultValue: "Task" },
  { id: "Tasks", defaultValue: "Tasks" },
  { id: "task revision", defaultValue: "task revision" },
  { id: "task revisions", defaultValue: "task revisions" },
  { id: "Task revision", defaultValue: "Task revision" },
  { id: "Task revisions", defaultValue: "Task revisions" },
  { id: "Revisions-task-details-page-tab", defaultValue: "Revisions" },

  { id: "quote-title", defaultValue: "Title" },
  { id: "quote-description", defaultValue: "Description" },
  { id: "financials-tab", defaultValue: "Financials" },
  { id: "Requests", defaultValue: "Requests" },
  { id: "requests", defaultValue: "requests" },
  { id: "Request", defaultValue: "Request" },
  { id: "request", defaultValue: "request" },
  { id: "Task description", defaultValue: "Description" },
  { id: "Stock", defaultValue: "Stock" },
  { id: "stock", defaultValue: "stock" },
  { id: "stock item", defaultValue: "stock item" },
  { id: "stock items", defaultValue: "stock items" },
  { id: "Stock item", defaultValue: "Stock item" },
  { id: "Stock items", defaultValue: "Stock items" },
  { id: "timesheet-fee-level", defaultValue: "fee level" },
  { id: "timesheet-fee-levels", defaultValue: "fee levels" },
  { id: "task-assigned-to-single", defaultValue: "Assigned to" },
  { id: "task-assigned-to-multiple-users", defaultValue: "Assigned to (multiple users)" },
  { id: "request-desired-completion-date", defaultValue: "Desired completion date" },
  { id: "Authority", defaultValue: "Authority" },
  { id: "Authority level", defaultValue: "Authority level" },
  { id: "authority level", defaultValue: "authority level" },
  { id: "sheet-title", defaultValue: "Sheet title" },
  { id: "sheet-title-for-non-sheet-files", defaultValue: "Title" },
  { id: "task-details-attachments-tab", defaultValue: "Attachments" },
  { id: "project-details-attachments-tab", defaultValue: "Attachments" },
  { id: "client-details-attachments-tab", defaultValue: "Attachments" },
];

export default CUSTOM_LABELS;
