import moment from "moment";
import cx from "classnames";
import { useEffect, useRef } from "react";
import { DeleteOutlined } from "@ant-design/icons";
import { Typography, Button, Modal, Tag, message, Dropdown } from "antd";
import { useGetSetState } from "react-use";

import { DragHandleIcon } from "common/icons";
import { TIMESHEET_HOUR_HEIGHT } from "common/constants";
import { taskIdToColor } from "TimelinePage/timelineHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { processIdForDisplay } from "common/helpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";

import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";

import "./TimesheetBlock.scss";

export default function TimesheetBlock({
  context,
  timesheetBlock,
  timesheetBlocks,
  tasks,
  projects,
  onOpen,
  onCopy,
  viewerIsOwner,
  snapCoefficientHours,
  setPropsForPage,
  onResizeStart,
  onResizeEnd,
  onDragStart,
  timesheetTags,
  organisationDetails,
}) {
  const [getState, setState] = useGetSetState({
    dragHandle: undefined,
    dragStartCoordinates: undefined,
    newStartAt: undefined,
    newEndAt: undefined,
    block: timesheetBlock,
    task: undefined,
    isLoadingTask: true,
    taskIdForPreview: undefined,
  });

  const timesheetBlocksRef = useRef(timesheetBlocks);

  useEffect(() => {
    timesheetBlocksRef.current = timesheetBlocks;
  }, [timesheetBlocks]);

  useEffect(() => {
    if (organisationDetails.settings?.timesheet?.canAssignTimesheetBlocksToTaskRevisions) {
      fetchAndSetTask();
    } else {
      setState({
        isLoadingTask: false,
      });
    }
  }, []); // eslint-disable-line

  async function fetchAndSetTask() {
    const taskDetails = (
      await callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task")} details`,
        queryCustom: "getTaskWithRevisions",
        variables: {
          id: timesheetBlock.taskId,
        },
      })
    ).data.getTask;
    setState({
      task: taskDetails,
      isLoadingTask: false,
    });
  }

  const { isLoadingTask, taskIdForPreview, newStartAt, newEndAt, block } = getState();

  let startAt = newStartAt || timesheetBlock.startAt;
  let endAt = newEndAt || timesheetBlock.endAt;
  if (timesheetBlock.isRecording) {
    endAt = moment().toISOString();
  }

  let startHoursNumber = parseFloat(moment(startAt).format("H"));
  let startMinutesNumber = parseFloat(moment(startAt).format("m"));
  startHoursNumber += startMinutesNumber / 60;

  let top = startHoursNumber * TIMESHEET_HOUR_HEIGHT;
  let durationHours = moment(endAt).diff(moment(startAt), "hours", true);
  let height = durationHours * TIMESHEET_HOUR_HEIGHT;

  const backgroundColor = taskIdToColor(timesheetBlock.taskId);

  useEffect(() => {
    setState({
      block: timesheetBlock,
    });
  }, [timesheetBlock]);

  useEffect(() => {
    window.addEventListener("mousemove", onWindowMouseMove);
    window.addEventListener("mouseup", onWindowMouseUp);

    return () => {
      window.removeEventListener("mousemove", onWindowMouseMove);
      window.removeEventListener("mouseup", onWindowMouseUp);
    };
  }, []); // eslint-disable-line

  async function onDelete(e) {
    if (e && e.stopPropagation && typeof e.stopPropagation === "function") {
      e.stopPropagation();
    }

    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      const invoice = (
        await window.callGraphQLSimple({
          message: "Failed to fetch invoice details",
          queryCustom: "getInvoice",
          variables: {
            id: timesheetBlock.invoiceId,
          },
        })
      ).data.getInvoice;

      if (invoice.reviewStatus === "SUCCESS") {
        message.error("This timesheet block is linked to an already-approved invoice, so you cannot delete it.");
        return;
      }
    }

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Are you sure you want to delete this timesheet block?",
          maskClosable: true,
          okButtonProps: {
            "data-cy": "confirm-delete-timesheet-block-modal-ok",
          },
          cancelButtonProps: {
            "data-cy": "confirm-delete-timesheet-block-modal-cancel",
          },
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, user said no
      return;
    }

    await window.callGraphQLSimple({
      message: "Failed to delete timesheet block",
      mutation: "deleteTimesheetBlock",
      variables: {
        input: {
          id: timesheetBlock.id,
        },
      },
    });

    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
    }
  }

  function displayTaskInfo() {
    let taskForDisplay = task || tasks.find((x) => x.id === timesheetBlock.taskId);
    const project = taskForDisplay && projects.find((x) => x.id === taskForDisplay.projectId);
    const taskRevision =
      taskForDisplay && taskForDisplay?.revisions?.items?.find((x) => x.id === timesheetBlock.taskRevisionId);

    return (
      <div
        className={cx("details", {
          "has-no-task": timesheetBlock.taskId === "nothing",
        })}
      >
        <div className="project-and-task">
          {project && <Typography.Text className="project-title">{project.title}</Typography.Text>}
          {timesheetBlock.taskId !== "nothing" && (
            <Typography.Text className="task-title">
              {taskForDisplay?.title || timesheetBlock.taskId}
              {taskRevision ? ` (${taskRevision.name})` : ""}
              {timesheetBlock.invoiceId !== "nothing"
                ? ` (Invoiced: ${processIdForDisplay(timesheetBlock.invoiceId)})`
                : ""}
            </Typography.Text>
          )}
        </div>
        <Typography.Text className="description">{timesheetBlock.description} </Typography.Text>
        <div className="tags">
          {timesheetBlock.billable ? (
            <Tag data-cy="billable-tag" className="billable-tag">
              Billable
            </Tag>
          ) : (
            <Tag data-cy="non-billable-tag" className="billable-tag">
              Non-Billable
            </Tag>
          )}
          {(timesheetBlock.tags || [])
            .filter((tagId) => tagId.length > 0)
            .map((tagId, index) => {
              const tagDefinition = timesheetTags.find((x) => x.id === tagId);
              if (!tagDefinition) {
                return null;
              }
              return (
                <Tag data-cy="timesheet-block-tag" key={index}>
                  {tagDefinition.label}
                </Tag>
              );
            })}
          {timesheetBlock.variation && (
            <Tag data-cy="variation-tag" className="variation-tag">
              Variation
            </Tag>
          )}
          {timesheetBlock.onSite && (
            <Tag data-cy="onsite-tag" className="onsite-tag">
              On Site
            </Tag>
          )}
        </div>
      </div>
    );
  }

  function resetDragState() {
    setState({
      dragStartCoordinates: undefined,
      dragHandle: undefined,
      newStartAt: undefined,
      newEndAt: undefined,
    });
  }

  function onWindowMouseMove(e) {
    const { block, dragStartCoordinates, dragHandle } = getState();

    if (!dragStartCoordinates || !dragHandle || dragHandle === "middle") {
      return;
    }

    const currentY = e.pageY;

    let deltaY = currentY - dragStartCoordinates.y;

    let newStartAt = moment(block.startAt);
    let newEndAt = moment(block.endAt);

    if (dragHandle === "end") {
      let deltaHours = Math.round(pixelsToHours(deltaY) / snapCoefficientHours) * snapCoefficientHours;
      newEndAt.add(deltaHours, "hours");
    } else if (dragHandle === "start") {
      let deltaHours = Math.round(pixelsToHours(deltaY) / snapCoefficientHours) * snapCoefficientHours;
      newStartAt.add(deltaHours, "hours");
    }

    setState({
      newStartAt,
      newEndAt,
    });
  }

  function pixelsToHours(pixels) {
    return pixels / TIMESHEET_HOUR_HEIGHT;
  }

  async function onWindowMouseUp() {
    const { dragStartCoordinates, newStartAt, newEndAt } = getState();

    if (dragStartCoordinates) {
      onResizeEnd();

      setPropsForPage(
        {
          context: {
            ...context,
            timesheetBlocks: timesheetBlocksRef.current.map((crtBlock) => {
              if (crtBlock.id !== timesheetBlock.id) {
                return crtBlock;
              }
              return {
                ...crtBlock,
                startAt: newStartAt || timesheetBlock.startAt,
                endAt: newEndAt || timesheetBlock.endAt,
              };
            }),
          },
        },
        resetDragState
      );

      updateTimesheetBlockOnMove({ newStartAt, newEndAt });
    } else {
      resetDragState();
    }
  }

  async function updateTimesheetBlockOnMove({ newStartAt, newEndAt }) {
    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      const invoice = (
        await window.callGraphQLSimple({
          message: "Failed to fetch invoice details",
          queryCustom: "getInvoice",
          variables: {
            id: timesheetBlock.invoiceId,
          },
        })
      ).data.getInvoice;

      if (invoice.reviewStatus === "SUCCESS") {
        message.error("This timesheet block is linked to an already-approved invoice, so you cannot resize it.");
        return;
      }
    }

    await window.callGraphQLSimple({
      message: "Failed to update timesheet block",
      mutation: "updateTimesheetBlock",
      variables: {
        input: {
          id: timesheetBlock.id,
          startAt: newStartAt || timesheetBlock.startAt,
          endAt: newEndAt || timesheetBlock.endAt,
        },
      },
    });

    if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
      await new Promise((resolve) => setTimeout(resolve, 500));
      await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
      return;
    }
  }

  if (isLoadingTask) {
    return null;
  }

  const { task } = getState();

  let timesheetBlockIsApproved =
    organisationDetails.settings?.timesheet?.usesReview && timesheetBlock.reviewStatus === "APPROVED";

  const dropdownItems = [
    {
      key: "open",
      label: "View details",
      onClick: onOpen,
    },
  ];

  if (task) {
    dropdownItems.push({
      key: "open-task",
      label: `View ${getSimpleLabel("task")}`,
      onClick: () => {
        setState({
          taskIdForPreview: task.id,
        });
      },
    });
  }

  if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
    dropdownItems.push({
      key: "open-invoice",
      label: "View invoice",
      onClick: () => {
        window.open(`/invoices/${timesheetBlock.invoiceId}`, "_blank");
      },
    });
  }

  if (timesheetBlock.quoteId && timesheetBlock.quoteId !== "nothing") {
    dropdownItems.push({
      key: "open-quote",
      label: `View ${getSimpleLabel("quote")}`,
      onClick: () => {
        window.open(`/quotes/${timesheetBlock.quoteId}`, "_blank");
      },
    });
  }

  dropdownItems.push(
    {
      type: "divider",
    },
    {
      key: "copy",
      label: "Copy",
      onClick: () => onCopy(timesheetBlock),
    },
    !timesheetBlockIsApproved && {
      key: "delete",
      label: "Delete",
      onClick: (e) => onDelete(e),
    }
  );

  return (
    <>
      <Dropdown
        menu={{
          items: dropdownItems.filter((x) => x),
        }}
        trigger={["contextMenu", "click"]}
      >
        <div
          className={cx("timesheet-block", {
            compact: durationHours < 2,
            "super-compact": durationHours < 1,
          })}
          style={{
            top: top + 1,
            height: height - 1,
          }}
          // onClick={onClick}
          data-cy="timesheet-block"
          data-task-id={timesheetBlock.taskId}
          data-description={timesheetBlock.description}
        >
          {!timesheetBlockIsApproved && (
            <div
              className="drag-handle"
              draggable
              data-cy="drag-handle"
              data-description-id={block.description}
              onDragStart={(e) => {
                setTimeout(() => {
                  onDragStart();
                }, 100);

                const dragImageElement = e.target.parentNode;
                if (!dragImageElement) {
                  e.preventDefault();
                  e.stopPropagation();
                  return;
                }

                e.dataTransfer.setDragImage(dragImageElement, 0, 0);
                e.dataTransfer.setData("block-id", timesheetBlock.id);
                e.dataTransfer.setData("draggable-type", "timesheet-block");
              }}
              onDragEnd={onWindowMouseUp}
            />
          )}
          <div className="background" style={{ backgroundColor }} />
          {displayTaskInfo()}
          {viewerIsOwner && !timesheetBlockIsApproved && (
            <Button
              icon={<DeleteOutlined />}
              onClick={onDelete}
              className="delete-button"
              data-cy="delete-timesheet-block-button"
            />
          )}
          {!timesheetBlockIsApproved && (
            <div
              className="block-drag-start"
              onMouseDown={(e) => {
                onResizeStart();
                setState({
                  dragHandle: "start",
                  dragStartCoordinates: { x: e.pageX, y: e.pageY },
                });
              }}
              data-cy="resize-handle-start"
            >
              <DragHandleIcon />
            </div>
          )}
          {!timesheetBlockIsApproved && (
            <div
              className="block-drag-end"
              onMouseDown={(e) => {
                onResizeStart();
                setState({
                  dragHandle: "end",
                  dragStartCoordinates: { x: e.pageX, y: e.pageY },
                });
              }}
              data-cy="resize-handle-end"
            >
              <DragHandleIcon />
            </div>
          )}
        </div>
      </Dropdown>
      {taskIdForPreview && (
        <TaskDetailsModal
          taskId={taskIdForPreview}
          onClose={() => {
            setState({
              taskIdForPreview: undefined,
            });
          }}
        />
      )}
    </>
  );
}
