import { useState, useEffect } from "react";
import axios from "axios";
import { message, Modal, Typography, Spin, Button } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { FORM_NESTED_FIELD_LIST_FIELDS_TO_IGNORE } from "common/constants";
import getS3File from "common/getS3File";

import "./ModalManageItemList.scss";

export default function ModalManageItemList({ onClose, templateDetails, fieldName }) {
  const [isLoading, setIsLoading] = useState(false);
  const [itemList, setItemList] = useState();
  const [fieldDetails, setFieldDetails] = useState();
  const [numberForRefresh, setNumberForRefresh] = useState(0);

  useEffect(() => {
    fetchAndSetItemList();
  }, [numberForRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchAndSetItemList() {
    const formFilePublicUrl = await getS3File(templateDetails.key);
    let templateForm = (await axios.get(formFilePublicUrl)).data;
    let fieldDetails = templateForm.fields[fieldName];
    if (!fieldDetails) {
      fieldDetails = {};
    }
    if (!fieldDetails.templateValues) {
      fieldDetails.templateValues = [];
    }
    setItemList(fieldDetails.templateValues);
    setFieldDetails(fieldDetails);
  }

  async function confirmRemoveItemFromList({ item, itemIndex }) {
    Modal.confirm({
      title: "Are you sure you want to remove this item from the list?",
      content: "This action cannot be undone.",
      okText: "Remove",
      okType: "danger",
      onOk: () => removeItemFromList({ item, itemIndex }),
    });
  }

  async function removeItemFromList({ item, itemIndex }) {
    const messageKey = "removeItemFromList";

    message.loading({ content: "Removing item from list...", key: messageKey, duration: 10 });
    try {
      const formFilePublicUrl = await getS3File(templateDetails.key);
      let templateForm = (await axios.get(formFilePublicUrl)).data;

      let templateFormField = templateForm.fields[fieldName];
      if (!templateFormField.templateValues) {
        templateFormField.templateValues = [];
      }

      let itemInTemplateForm = templateFormField.templateValues[itemIndex];

      let itemMatches = true;
      for (let itemKey in item) {
        if (item[itemKey] !== itemInTemplateForm?.item[itemKey]) {
          itemMatches = false;
          break;
        }
      }

      if (!itemMatches) {
        message.error(
          "This item has been edited since it was added to the list. Please refresh the page and try again."
        );
        return;
      }

      templateFormField.templateValues.splice(itemIndex, 1);

      try {
        await Storage.put(templateDetails.key, JSON.stringify(templateForm));
        setItemList(templateFormField.templateValues);
      } catch (e) {
        console.error(e);

        throw e;
      }

      message.success({ content: "Item removed from list", key: messageKey });
    } catch (e) {
      console.error(e);
      message.error({ content: "Failed to remove item from list", key: messageKey });
    }
  }

  function displayItemFields({ item }) {
    let validKeys = Object.keys(item).filter((itemKey) => !FORM_NESTED_FIELD_LIST_FIELDS_TO_IGNORE.includes(itemKey));

    if (fieldDetails.fields) {
      return validKeys.map((itemKey, itemFieldIndex) => {
        let itemFieldValue = fieldDetails.fields[itemKey];

        if (!itemFieldValue) {
          return null;
        }

        return (
          <Typography.Paragraph key={itemFieldIndex}>
            <b>{itemFieldValue.shortLabel || itemFieldValue.label}</b> {item[itemKey]}
          </Typography.Paragraph>
        );
      });
    } else {
      return (
        <Typography.Paragraph>
          <b>Title:</b> {item["title"]}
        </Typography.Paragraph>
      );
    }
  }

  function displayItemActionButtons({ item, itemIndex }) {
    return (
      <>
        <Button
          icon={<DeleteOutlined />}
          className="delete-item"
          onClick={() => confirmRemoveItemFromList({ item, itemIndex })}
        />
      </>
    );
  }

  return (
    <Modal
      maskClosable={false}
      title="Manage presets"
      open={true}
      onCancel={() => {
        onClose();
      }}
      footer={null}
      className="modal-manage-item-list full-screen-on-mobile"
    >
      {!itemList || !fieldDetails ? (
        <Spin />
      ) : (
        <ul className="user-list-options">
          {itemList.map((itemContainer, itemIndex) => {
            return (
              <li className="user-list-option-item" key={itemIndex}>
                <div className="item-content">{displayItemFields({ item: itemContainer.item })}</div>
                <div className="item-action-buttons">
                  {displayItemActionButtons({ item: itemContainer.item, itemIndex })}
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </Modal>
  );
}
