import { Table, Button } from "antd";
import { useGetSetState } from "react-use";
import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { STOCK_ITEM_TYPES } from "common/constants";

import StockItemFilters from "StockItemFilters/StockItemFilters";
import CreateStockItemModal from "Modals/CreateStockItemModal/CreateStockItemModal";

import "./StockItemsPage.scss";

export function StockItemsPage({ stockItems, setBoxedLayout, setRightMargin, history }) {
  const [getState, setState] = useGetSetState({
    selectedStockItem: undefined,
    isCreateStockItemModalOpen: false,
    filter: {},
  });

  useEffect(() => {
    setBoxedLayout(false);
    setRightMargin(false);
    return () => {
      setBoxedLayout(true);
      setRightMargin(true);
    };
  }, []); //eslint-disable-line

  const { filter, isCreateStockItemModalOpen } = getState();

  return (
    <div className="stock-items-page">
      <div className="page-header">
        <StockItemFilters
          onChange={(filter) => setState({ filter })}
          includeCreateQuote
          // export={exportToCSV}
        />

        <Button
          icon={<PlusCircleOutlined />}
          type="primary"
          data-cy="create-stock-item-button"
          className="create-stock-item-button"
          onClick={() => setState({ selectedStockItem: undefined, isCreateStockItemModalOpen: true })}
        >
          Create {getSimpleLabel("stock item")}
        </Button>
      </div>
      <Table
        dataSource={stockItems}
        pagination={{ pageSize: 50, hideOnSinglePage: true }}
        rowKey="id"
        onRow={(stockItem, index) => {
          return {
            onClick: () => {
              history.push(`/stock-items/${stockItem.id}`);
            },
          };
        }}
        columns={[
          {
            title: "Name",
            dataIndex: "name",
            key: "name",
            align: "left",
          },
          {
            title: "Type",
            dataIndex: "type",
            key: "type",
            align: "left",
            render: (type) => STOCK_ITEM_TYPES.find((typeDefinition) => typeDefinition.value === type)?.label || type,
          },
          {
            title: `Display on ${getSimpleLabel("timeline")}`,
            dataIndex: "displayOnTimeline",
            key: "type",
            align: "left",
            render: (displayOnTimeline) => (displayOnTimeline ? "Yes" : "No"),
          },
          {
            title: "Created on",
            dataIndex: "createdAt",
            align: "left",
            render: (_, stockItem) => {
              return moment(stockItem.createdAt).format("DD-MM-YYYY");
            },
          },
        ]}
      />
      {isCreateStockItemModalOpen && (
        <CreateStockItemModal
          onClose={() =>
            setState({
              isCreateStockItemModalOpen: false,
              selectedStockItem: undefined,
            })
          }
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: StockItemsPage,
    subscriptions: ["stockItems"],
  })
);
