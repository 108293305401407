import { Checkbox } from "antd";

import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";

import { FILE_TYPES_READABLE } from "common/constants";

import Card from "Card/Card";

export default function FileTypes(props) {
  const { organisationDetails } = props;

  function displayFileTypes() {
    const lowercaseFileTypes = (organisationDetails.fileTypesUsed || []).map(
      (fileType) => FILE_TYPES_READABLE[fileType]
    );

    return (
      <Checkbox.Group
        options={Object.values(FILE_TYPES_READABLE)}
        defaultValue={lowercaseFileTypes}
        onChange={async (fileTypesUsed) => {
          const allCapsFileTypes = fileTypesUsed.map((fileType) => {
            for (let fileTypeAllCaps in FILE_TYPES_READABLE) {
              if (FILE_TYPES_READABLE[fileTypeAllCaps] === fileType) {
                return fileTypeAllCaps;
              }
            }
            return undefined;
          });
          await callGraphQL(
            "Could not update file types",
            graphqlOperation(updateOrganisation, {
              input: {
                id: organisationDetails.id,
                fileTypesUsed: allCapsFileTypes,
              },
            })
          );
        }}
        className="file-type-checkbox"
      />
    );
  }

  return (
    <Card withSpace title="File types used" data-cy="file-types">
      {displayFileTypes()}
    </Card>
  );
}
