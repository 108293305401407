import { useState, ReactElement } from "react";
import { Button, Tag, Modal, message } from "antd";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";
import { isAuthorised } from "common/permissions";
import { processIdForDisplay } from "common/helpers";
import {
  getLatestMessageForFormFileId,
  getActivityItemsForFormFileId,
  getRequestFormFileIds,
  getTaskIdsAndTaskRevisionIdsForRequestForm,
  haveTaskFilesBeenSentForFormFileId,
} from "common/requestHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { downloadReport } from "ReportPage/Report/reportHelpers";

import { ReportPageWithMinimalSubscriptions } from "ReportPage/ReportPage";
import RequestInformationButton from "pages/RequestDetailsPage/RequestInformationButton/RequestInformationButton";
import SendToEngineerButton from "pages/RequestDetailsPage/SendToEngineerButton/SendToEngineerButton";
// import ReopenFormButton from "pages/RequestDetailsPage/ReopenFormButton/ReopenFormButton";
import Card from "Card/Card";
import MessageForForm from "pages/RequestDetailsPage/MessageForForm/MessageForForm";
import AddRequestFormToTaskRevisionButton from "pages/RequestDetailsPage/AddRequestFormToTaskRevisionButton/AddRequestFormToTaskRevisionButton";
import TaskListModal from "./TaskListModal/TaskListModal";
// import TemplatedForm from "TemplatedForm/TemplatedForm";

import "./RequestFormCard.scss";
import { getSimpleLabel } from "common/labels";

type Props = {
  apiUser: any;
  request: any;
  formFileId: string;
  users: any;
  activityItems: any[];
  isSubmitted: boolean;
  organisationDetails: any;
  formCountTotal: number;
};

export function RequestFormCard({
  formFileId,
  request,
  users,
  apiUser,
  activityItems,
  isSubmitted = false,
  organisationDetails,
  formCountTotal,
}: Props) {
  const [isExpanded, setIsExpanded] = useState<boolean>(isSubmitted ? false : true);
  const [isTaskListModalVisible, setIsTaskListModalVisible] = useState<boolean>(false);
  const [validationErrors, setValidationErrors] = useState<any[]>([]);

  const urlParams = new URLSearchParams(window.location.search);
  const urlFormFileId = urlParams.get("formFileId");
  const formIsSelectedViaUrl = urlFormFileId && urlFormFileId === formFileId;

  const taskIdsAndRevisionIds = getTaskIdsAndTaskRevisionIdsForRequestForm({ activityItems, formFileId });
  const latestMessage = getLatestMessageForFormFileId({ activityItems, formFileId });
  const activityItemsForFormFileId = getActivityItemsForFormFileId({ activityItems, formFileId });
  const formCreationActivityItem = activityItemsForFormFileId.find(
    (activityItem) => activityItem.content?.type === "REQUEST_FORM_CREATED"
  );
  let formName = formCreationActivityItem?.content?.formName || "Information form";
  let formTag: any = null;
  let taskFilesHaveBeenSent = haveTaskFilesBeenSentForFormFileId({ activityItems, formFileId });
  if (taskFilesHaveBeenSent) {
    formTag = <Tag className="dark-tag">Design package issued</Tag>;
  } else if (isSubmitted) {
    formTag = <Tag color="green">Submitted</Tag>;
  } else {
    formTag = <Tag color="blue">Needs information</Tag>;
  }
  let actionButtons: ReactElement[] = [];

  async function generatePdf() {
    const messageKey = "downloading-request-form-pdf";
    try {
      message.loading({ content: "Generating PDF...", key: messageKey, duration: 0 });
      let fileName = `${processIdForDisplay(request.id)} - ${request.title} - ${formName}`;
      await downloadReport({
        fileId: formFileId,
        taskId: null,
        users,
        organisationDetails,
        fileName,
      });
      message.success({ content: "PDF generated", key: messageKey });
    } catch (error) {
      message.error({ content: "Failed to generate PDF", key: messageKey });
    }
  }

  async function onDeleteClick() {
    Modal.confirm({
      title: "Are you sure you want to delete this form?",
      content: "This action cannot be undone.",
      okText: "Delete",
      okType: "danger",
      cancelText: "Cancel",
      onOk: async () => {
        const formFileIds = getRequestFormFileIds({ activityItems });
        const formFileIdsWithoutThisOne = formFileIds.filter((x) => x !== formFileId);
        const latestAddedFormFileId = formFileIdsWithoutThisOne.slice(-1)[0];
        const latestAddedFormActivityItems = getActivityItemsForFormFileId({
          activityItems,
          formFileId: latestAddedFormFileId,
        });
        const latestAddedFormCreationActivityItem = latestAddedFormActivityItems.find(
          (activityItem) => activityItem.content?.type === "REQUEST_FORM_CREATED"
        );

        if (!latestAddedFormCreationActivityItem) {
          message.error("Failed to find at least 1 other form, therefore cannot delete this one");
          return;
        }

        await callGraphQLSimple({
          mutation: "createActivityItem",
          message: "Failed to record activity item",
          variables: {
            input: {
              parentId: request.id,
              author: apiUser.id,
              content: JSON.stringify({
                type: "REQUEST_FORM_DELETED",
                formFileId,
                formName,
              }),
              organisation: organisationDetails.id,
            },
          },
        });

        await callGraphQLSimple({
          mutation: "updateRequest",
          message: `Failed to refresh ${getSimpleLabel("request")}`,
          variables: {
            input: {
              id: request.id,
              itemSubscription: Math.floor(Math.random() * 100000),
              latestFormName: latestAddedFormCreationActivityItem?.content?.formName,
            },
          },
        });
      },
    });
  }

  if (organisationDetails.settings?.request?.allowRequestFormPdfDownload) {
    actionButtons.push(
      <Button key="download-pdf-button" onClick={() => generatePdf()}>
        PDF
      </Button>
    );
  }

  if (isExpanded) {
    actionButtons.push(
      <Button key="close-form-button" onClick={() => setIsExpanded(false)}>
        Collapse form
      </Button>
    );
  } else {
    actionButtons.push(
      <Button key="open-form-button" onClick={() => setIsExpanded(true)}>
        View form
      </Button>
    );
  }

  if (
    isAuthorised(["REQUESTS.DELETE"]) &&
    (!taskIdsAndRevisionIds || taskIdsAndRevisionIds?.length === 0) &&
    formCountTotal > 1
  ) {
    actionButtons.push(
      <Button type="danger" key="delete-form-button" onClick={onDeleteClick}>
        Delete form
      </Button>
    );
  }
  if (isSubmitted) {
    if (isAuthorised(["REQUESTS.EXECUTE"])) {
      if (!taskFilesHaveBeenSent) {
        actionButtons.push(
          <RequestInformationButton
            key="request-information-button"
            request={request}
            users={users}
            apiUser={apiUser}
            formFileId={formFileId}
            formName={formName}
            formCreationActivityItem={formCreationActivityItem}
          />
        );
      }

      if (
        !taskIdsAndRevisionIds ||
        !taskIdsAndRevisionIds?.length ||
        !organisationDetails.settings?.request?.formsCanOnlyBeAddedToOneTask
      ) {
        actionButtons.push(
          <AddRequestFormToTaskRevisionButton
            users={users}
            key="add-form-to-task-revision"
            request={request}
            apiUser={apiUser}
            formFileId={formFileId}
            formName={formName}
            taskIdsAndRevisionIds={taskIdsAndRevisionIds}
            formCreationActivityItem={formCreationActivityItem}
            organisationDetails={organisationDetails}
          />
        );
      }

      if (taskIdsAndRevisionIds && taskIdsAndRevisionIds?.length > 0) {
        actionButtons.push(
          <Button type="dark" onClick={() => setIsTaskListModalVisible(true)}>
            See {taskIdsAndRevisionIds.length === 1 ? getSimpleLabel("task") : getSimpleLabel("tasks")} linked to form
          </Button>
        );
      }
    } else {
      // actionButtons.push(
      //   <ReopenFormButton
      //     key="reopen-form-button"
      //     request={request}
      //     users={users}
      //     apiUser={apiUser}
      //     formFileId={formFileId}
      //     formName={formName}
      //   />
      // );
    }
  } else {
    actionButtons.push(
      <SendToEngineerButton
        request={request}
        apiUser={apiUser}
        formFileId={formFileId}
        formName={formName}
        users={users}
        setValidationErrors={setValidationErrors}
        formCreationActivityItem={formCreationActivityItem}
      />
    );
  }

  return (
    <Card
      stickyHeader={isExpanded}
      title={
        <div onClick={() => setIsExpanded(!isExpanded)} className="card-title">
          {formTag}

          {formName}
        </div>
      }
      className={cx("request-form-card", { collapsed: !isExpanded, highlighted: formIsSelectedViaUrl })}
      withSpace
      actions={actionButtons}
    >
      {latestMessage && latestMessage.content?.message && latestMessage.content.message?.trim() && (
        <MessageForForm users={users} activityItem={latestMessage} />
      )}
      {isExpanded && (
        <div className="inline-report-form">
          <ReportPageWithMinimalSubscriptions
            match={{ params: { fileId: formFileId } }}
            withoutTask
            request={request}
            rootAttachmentPath={`public`}
            readOnly={isSubmitted}
            validationErrors={validationErrors}
          />
          {/* <TemplatedForm
            fileId={formFileId}
            parentId={request.id}
            rootAttachmentPath={`public`}
            apiUser={apiUser}
            organisationDetails={organisationDetails}
          /> */}
        </div>
      )}
      {isTaskListModalVisible && (
        <TaskListModal
          onCancel={() => setIsTaskListModalVisible(false)}
          taskIdsAndRevisionIds={taskIdsAndRevisionIds}
          organisationDetails={organisationDetails}
          requestId={request.id}
          formFileId={formFileId}
          formName={formName}
          apiUser={apiUser}
          activityItems={activityItems}
        />
      )}

      {!isSubmitted && (
        <div className="send-to-engineer-bottom-button-container">
          <SendToEngineerButton
            request={request}
            apiUser={apiUser}
            formFileId={formFileId}
            formName={formName}
            users={users}
            setValidationErrors={setValidationErrors}
            formCreationActivityItem={formCreationActivityItem}
          />
        </div>
      )}
    </Card>
  );
}

export default withSubscriptions({
  Component: RequestFormCard,
  subscriptions: ["organisationDetails", "users"],
});
