const fields = [
  {
    id: "requestFirstRequestId",
    fieldTypes: ["textfield"],
    label: "Request ID",
    value: ({ request }) => {
      return request?.id;
    },
  },
  {
    id: "requestRequesterInitials",
    fieldTypes: ["textfield"],
    label: "Requester initials",
    value: ({ request, users }) => {
      let requesterDetails = users.find((user) => user.id === request?.createdBy);
      if (!requesterDetails) {
        return;
      }

      let initials = [requesterDetails?.firstName?.charAt(0), requesterDetails?.lastName?.charAt(0)];
      return initials.join("");
    },
  },
  {
    id: "requestTitle",
    fieldTypes: ["textfield"],
    label: "Request title",
    value: ({ request }) => {
      return request?.title;
    },
  },
];

export function getFields() {
  return fields;
}

export const label = "Request";
