import TaskItem from "DashboardPage/TaskItem/TaskItem";
import TaskListItem from "TaskListItem/TaskListItem";
import LazyLoadList from "LazyLoadList/LazyLoadList";

import "./TaskList.scss";

export default function TaskList({ tasks, organisationDetails, viewType, windowWidth, onSelectTask }) {
  function displayTasks() {
    if (!tasks || tasks.length === 0) {
      return null;
    }
    return (
      <LazyLoadList
        list={tasks}
        refreshOnChange={[viewType, windowWidth]}
        item={(task, index) => {
          if (viewType === "BOARD") {
            return (
              <TaskItem
                key={task.id}
                task={task}
                index={index}
                organisationDetails={organisationDetails}
                onClick={onSelectTask}
              />
            );
          } else if (viewType === "LIST") {
            return (
              <TaskListItem
                windowWidth={windowWidth}
                key={task.id}
                {...task}
                index={index}
                organisationDetails={organisationDetails}
                card
              />
            );
          }
        }}
      />
    );
  }

  return <div className="task-list">{displayTasks()}</div>;
}
