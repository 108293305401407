import React from "react";
import { Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { graphqlOperation } from "aws-amplify";
import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import { QUOTE_HIDDEN_LINE_ITEM_COLUMN_OPTIONS } from "common/constants";

import "./QuoteHiddenLineItemColumnsList.scss";

function QuoteHiddenLineItemColumnsList({ organisationDetails, setProps, context }) {
  const reorder = (startIndex, endIndex) => {
    const result = organisationDetails.settings?.quote?.hiddenLineItemColumns;
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedHiddenLineItemColumns = reorder(result.source.index, result.destination.index);
    await updateStateAndAPI(reorderedHiddenLineItemColumns);
  }

  async function updateStateAndAPI(newItems) {
    const updatedSettings = {
      ...organisationDetails?.settings,
      quote: {
        ...organisationDetails.settings?.quote,
        hiddenLineItemColumns: newItems,
      },
    };

    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          settings: updatedSettings,
        },
        organisationDetails: {
          ...organisationDetails,
          settings: updatedSettings,
        },
      },
    });
    await callGraphQL(
      "Could not perform operation",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          settings: updatedSettings,
        },
      })
    );
  }

  async function deleteItem(item) {
    const newHiddenLineItemColumns = organisationDetails.settings?.quote.hiddenLineItemColumns.filter(
      (x) => x !== item
    );
    await updateStateAndAPI(newHiddenLineItemColumns);
  }

  return (
    <div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="hidden-line-item-column-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails?.settings?.quote?.hiddenLineItemColumns.map((columnName, index) => {
                return (
                  <Draggable
                    key={columnName}
                    draggableId={columnName}
                    index={index}
                    className={`draggable-hidden-line-item-column`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="hidden-line-item-column-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Typography.Text className="name" data-cy="hidden-line-item-column-name">
                          {QUOTE_HIDDEN_LINE_ITEM_COLUMN_OPTIONS.find((field) => field.value === columnName)?.label ||
                            columnName}
                        </Typography.Text>
                        <div className="actions">
                          <Button
                            key={`hidden-line-item-column-delete-${columnName}`}
                            icon={<DeleteOutlined />}
                            className="delete-hidden-line-item-column"
                            onClick={() => deleteItem(columnName)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: QuoteHiddenLineItemColumnsList,
    subscriptions: ["organisationDetails"],
  })
);
