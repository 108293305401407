import { Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { graphqlOperation } from "aws-amplify";
import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";

import "./TaskPriorityList.scss";

function TaskPriorityList(props) {
  const { organisationDetails, setProps, context } = props;

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(organisationDetails.settings?.task?.priorities);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedPriorities = reorder(result.source.index, result.destination.index);
    let updatedOrganisation = JSON.parse(JSON.stringify(organisationDetails));
    if (!updatedOrganisation.settings) {
      updatedOrganisation.settings = {};
    }

    if (!updatedOrganisation.settings?.task) {
      updatedOrganisation.settings.task = {};
    }
    updatedOrganisation.settings.task.priorities = reorderedPriorities;
    setProps({
      context: {
        ...context,
        organisation: updatedOrganisation,
        organisationDetails: updatedOrganisation,
      },
    });
    await callGraphQL(
      "Could not reorder priority levels",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          settings: updatedOrganisation.settings,
        },
      })
    );
  }

  async function onDeleteClick(priority) {
    let updatedOrganisation = JSON.parse(JSON.stringify(organisationDetails));
    const newPriorities = organisationDetails.settings?.task?.priorities.filter((x) => x.name !== priority.name);
    updatedOrganisation.settings.task.priorities = newPriorities;
    setProps({
      context: {
        ...context,
        organisation: updatedOrganisation,
        organisationDetails: updatedOrganisation,
      },
    });
    await callGraphQL(
      "Could not delete priority level",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          settings: updatedOrganisation.settings,
        },
      })
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="task-priority-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails.settings?.task?.priorities.map((priority, index) => {
                return (
                  <Draggable
                    key={priority.name}
                    draggableId={priority.name}
                    index={index}
                    className={`draggable-task-priority ${priority.name.split(" ").join("")}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="task-priority-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Typography.Text className="name" data-cy="task-priority-name">
                          {priority.name}
                        </Typography.Text>
                        <div className="actions">
                          <Button
                            key={`task-priority-delete-${priority.name}`}
                            icon={<DeleteOutlined />}
                            className="delete-task-priority"
                            onClick={() => onDeleteClick(priority)}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TaskPriorityList,
    subscriptions: ["organisationDetails"],
  })
);
