import { useState } from "react";
import { InputNumber, Typography, Checkbox, Button } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { graphqlOperation } from "aws-amplify";
import _ from "lodash";
import { PUBLIC_S3_BUCKET_URL } from "common/publicS3BucketUrl";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";
import { callGraphQL, getLabel } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import { GENERAL_SETTINGS } from "common/settings";

import InfoItem from "InfoItem/InfoItem";
import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import Input from "Input/Input";
import Explanation from "Explanation/Explanation";
import UploadAvatarModal from "Modals/UploadAvatarModal/UploadAvatarModal";

import "./MainSettings.scss";

export default function MainSettings({ organisationDetails }) {
  const [isLogoModalOpen, setIsLogoModalOpen] = useState(false);
  async function updateOrganisationSetting(fieldName, value) {
    await callGraphQL(
      "Could not update organisation settings",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          [fieldName]: value,
        },
      })
    );
  }

  const checked = GENERAL_SETTINGS.filter((setting) => {
    return _.get(organisationDetails, setting.SCHEMA_FIELD);
  });
  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  return (
    <>
      <Card withSpace title="General settings" className="organisation-main-settings">
        <div className="setting-item">
          <Typography.Text className="setting-name">Organisation logo:</Typography.Text>
          <div>
            {organisationDetails.logoKey && (
              <div className="logo-container">
                <img src={`${PUBLIC_S3_BUCKET_URL}/public/${organisationDetails.logoKey}`} alt="organisation logo" />
              </div>
            )}
            <Button
              style={{ marginTop: "0.5rem" }}
              icon={<UploadOutlined />}
              type="primary"
              onClick={() => {
                setIsLogoModalOpen(true);
              }}
            >
              Upload logo
            </Button>
          </div>
        </div>
        <div className="setting-item">
          <Typography.Text className="setting-name">Slack webhook URL:</Typography.Text>
          <Input
            showBorder
            fullWidth
            defaultValue={organisationDetails.slackWebhookURL}
            placeholder="used to send various notifications to Slack"
            className="slack-notification-url-input"
            onChange={(value) => updateOrganisationSetting("slackWebhookURL", value)}
          />
        </div>
        <div className="setting-item">
          <Typography.Text className="setting-name">Email address to send notifications from:</Typography.Text>
          <Input
            showBorder
            fullWidth
            defaultValue={organisationDetails.settings?.general?.defaultAddressToSendEmailsFrom}
            placeholder="if not specified, the default email address will be used"
            className="default-email-address-input"
            onChange={(value) => {
              callGraphQL(
                "Could not update organisation settings",
                graphqlOperation(updateOrganisation, {
                  input: {
                    id: organisationDetails.id,
                    settings: {
                      ...(organisationDetails.settings || {}),
                      general: {
                        ...(organisationDetails.settings?.general || {}),
                        defaultAddressToSendEmailsFrom: value,
                      },
                    },
                  },
                })
              );
            }}
          />
        </div>
        <div className="setting-item">
          <Typography.Text className="setting-name">
            Base {getLabel({ id: "project", defaultValue: "project" })} number:{" "}
            <Explanation
              title={`The unique number allocated to every ${getLabel({
                id: "project",
                defaultValue: "project",
              })} is calculated by starting from the base ${getLabel({
                id: "project",
                defaultValue: "project",
              })} number and adding the current ${getLabel({
                id: "project",
                defaultValue: "project",
              })} number offset (which increases by 1 with each ${getLabel({
                id: "project",
                defaultValue: "project",
              })}). This process may have additional steps depending on the naming scheme of your organisation.`}
            />
          </Typography.Text>
          <Input
            showBorder
            defaultValue={organisationDetails.projectIdOffset}
            placeholder="0"
            className="project-id-offset-input"
            onChange={(value) => updateOrganisationSetting("projectIdOffset", value ? parseInt(value) : null)}
          />
        </div>
        <div className="setting-item">
          <Typography.Text className="setting-name">
            Current {getLabel({ id: "project", defaultValue: "project" })} number offset:{" "}
          </Typography.Text>
          <Input
            showBorder
            defaultValue={organisationDetails.projectCount}
            className="project-count-input"
            onChange={(value) => updateOrganisationSetting("projectCount", value ? parseInt(value) : null)}
          />
        </div>
        <Checkbox.Group defaultValue={checkedSettings}>
          {GENERAL_SETTINGS.map((setting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: {
                      settings: organisationDetails.settings || {},
                    },
                    checked: e.target.checked,
                    module: setting,
                  });
                  await callGraphQL(
                    "Could not update setting",
                    graphqlOperation(updateOrganisation, {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    })
                  );
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </Card>
      {isLogoModalOpen && (
        <UploadAvatarModal
          onClose={() => {
            setIsLogoModalOpen(false);
          }}
          organisationDetails={organisationDetails}
          parentType="organisation"
        />
      )}
    </>
  );
}
