import { useState } from "react";
import { Form, Modal, Button, Input } from "antd";

import "./ClientContactModal.scss";

type Props = {
  onClose?: any;
  onSubmit?: any;
  contact?: any;
  parent?: any;
};

export default function ClientContactModal({ onClose, onSubmit, contact, parent }: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={contact ? "Edit contact" : "Add contact"}
      open={true}
      onCancel={onClose}
      footer={null}
      className="contact-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          id: contact && contact.id,
          firstName: contact && contact.firstName,
          lastName: contact && contact.lastName,
          email: contact && contact.email,
          phone: contact && contact.phone,
          phone2: contact && contact.phone2,
          position: contact && contact.position,
        }}
        onFinish={(params) => {
          setIsLoading(true);
          if (onSubmit && typeof onSubmit === "function") {
            onSubmit(params);
          }
        }}
      >
        <Form.Item
          label="Label"
          name="id"
          hidden={contact}
          rules={[
            {
              required: true,
              message: "You need to specify a label for the contact",
            },
            {
              validator: contact
                ? undefined
                : async (_, label) => {
                    let contactWithSameId = (parent.contacts || []).find((field) => field.id === label);
                    if (contactWithSameId) {
                      throw new Error("There is already another contact with the same label");
                    }
                  },
            },
          ]}
        >
          <Input data-cy="contact-id-input" />
        </Form.Item>
        <Form.Item label="First Name" name="firstName">
          <Input data-cy="first-name-input" />
        </Form.Item>

        <Form.Item label="Last Name" name="lastName">
          <Input data-cy="last-name-input" />
        </Form.Item>

        <Form.Item label="Email" name="email">
          <Input data-cy="email-input" />
        </Form.Item>

        <Form.Item label="Phone" name="phone">
          <Input data-cy="phone-input" />
        </Form.Item>

        <Form.Item label="Position" name="position">
          <Input data-cy="position-input" />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="contact-submit-modal-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
