import { useEffect, useState } from "react";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import OverallSpinner from "OverallSpinner/OverallSpinner";
import { ReportPageWithMinimalSubscriptions } from "ReportPage/ReportPage";

import "./ReviewRequestForm.scss";

export function ReviewRequestForm({ task, taskRevision, users, organisationDetails, requestFormActivityItem }) {
  const [request, setRequest] = useState();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchAndSetRequest();
  }, []);

  let formFileId = requestFormActivityItem.content.formFileId;
  console.log("requestFormActivityItem =", requestFormActivityItem);
  console.log("formFileId =", formFileId);

  async function fetchAndSetRequest() {
    const request = (
      await callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("request")}`,
        query: "getRequest",
        variables: {
          id: requestFormActivityItem.parentId,
        },
      })
    ).data.getRequest;
    setRequest(request);
    setIsLoading(false);
  }

  if (isLoading) {
    return <OverallSpinner />;
  }

  return (
    <div className="review-request-form inline-report-form">
      {requestFormActivityItem.content.isLinkingReview ? null : (
        <ReportPageWithMinimalSubscriptions
          match={{ params: { fileId: formFileId } }}
          withoutTask
          request={request}
          rootAttachmentPath={`public`}
          readOnly
        />
      )}
    </div>
  );
}

export default withSubscriptions({
  Component: ReviewRequestForm,
  subscriptions: ["organisationDetails", "users"],
});
