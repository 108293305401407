import React from "react";
import { Table, Button, Typography, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";

import { getUserReadableCatLevel, callGraphQL } from "common/helpers";
import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { isAuthorised } from "common/permissions";

import InfoItem from "InfoItem/InfoItem";

export default function UserTeams({ userData, organisationDetails, removeUserFromTeam }) {
  return (
    <>
      <InfoItem
        value={
          <Table
            className="team-memberships-table"
            pagination={{ hideOnSinglePage: true }}
            expandIconColumnIndex={-1}
            showHeader={false}
            columns={[
              {
                key: "team",
                dataIndex: "name",
                align: "left",
                render: (_, row) => {
                  return <Typography.Text>{row.name}</Typography.Text>;
                },
              },
              {
                key: "actions",
                width: 50,
                render: (_, team) => {
                  return (
                    <>
                      {isAuthorised(["TEAMS.EDIT_TEAM_MEMBERSHIP"]) && (
                        <Button
                          onClick={() => removeUserFromTeam({ team, user: userData })}
                          icon={<DeleteOutlined />}
                        />
                      )}
                    </>
                  );
                },
              },
            ]}
            dataSource={(organisationDetails.teams || []).filter((team) => (userData.teams || []).includes(team.id))}
          />
        }
      />
    </>
  );
}
