import { useEffect, useState } from "react";
import { useGetSetState } from "react-use";
import { withRouter } from "react-router-dom";
import { Result } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { fetchActivityItemsForRequest } from "common/shared";
import { callGraphQLSimple } from "common/apiHelpers";

import OverallSpinner from "OverallSpinner/OverallSpinner";
import { ReviewPageContainer } from "ReviewPage/ReviewPageContainer/ReviewPageContainer";

export function RequestReviewPage({
  request,
  location,
  match,
  organisationDetails,
  users,
  hidePreloader,
  showPreloader,
  ...props
}) {
  const [getState, setState] = useGetSetState({
    isLoading: true,
    activityItems: undefined,
    review: undefined,
    project: undefined,
    client: undefined,
  });

  useEffect(() => {
    fetchAndSetActivityItems();
  }, [request]);

  let activityItemId = match?.params?.activityItemId;

  async function fetchAndSetActivityItems() {
    const { activityItems } = await fetchActivityItemsForRequest(request.id);
    const activityItem = activityItems.find((item) => item.id === activityItemId);

    if (!activityItem) {
      setState({ isLoading: false });
      return;
    }

    const [getReviewResponse, getProjectResponse, getClientResponse] = await Promise.all([
      await callGraphQLSimple({
        query: "getReview",
        variables: {
          id: activityItem.content.reviewId,
        },
      }),
      await callGraphQLSimple({
        queryCustom: "getProjectSimple",
        variables: {
          id: request.projectId,
        },
      }),
      await callGraphQLSimple({
        queryCustom: "getClient",
        variables: {
          id: request.clientId,
        },
      }),
    ]);
    const review = getReviewResponse.data.getReview;
    const project = getProjectResponse.data.getProject;
    const client = getClientResponse.data.getClient;
    setState({ review, project, client, activityItems, isLoading: false });
  }

  const { isLoading, activityItems, review, project, client } = getState();

  if (isLoading) {
    return <OverallSpinner />;
  }

  if (!activityItemId) {
    return <Result status="404" title="404" subTitle="Sorry, the page you visited does not exist." />;
  }

  const activityItem = activityItems.find((item) => item.id === activityItemId);

  let fakeTaskRevision = {
    id: activityItem.id,
    name: "Design package",
    description: activityItem.content.taskRevisionName,
    review,
    reviewStatus: activityItem.content.reviewStatus || "IN_PROGRESS",
    reviewId: review.id,
    checkedBy: request.createdBy,
    files: {
      items: activityItem.content.publicUrls.map((publicUrlDetails) => {
        return {
          id: publicUrlDetails.url,
          name: publicUrlDetails.actualFileName,
          isDesignPackageFile: true,
          type: publicUrlDetails.fileType,
          versions: {
            items: [
              {
                id: "nothing",
                updatedAt: "nothing",
                exports: [
                  {
                    key: publicUrlDetails.fileKey,
                  },
                ],
              },
            ],
          },
          sheets: {
            items: [
              {
                id: publicUrlDetails.sheetConstantId,
                name: publicUrlDetails.actualFileName,
                fileId: publicUrlDetails.url,

                revisions: {
                  items: [
                    {
                      id: "nothing",
                      fileVersionId: "nothing",
                      exports: [
                        {
                          key: publicUrlDetails.fileKey,
                        },
                      ],
                    },
                  ],
                },
              },
            ],
          },
        };
      }),
    },
  };

  return (
    <ReviewPageContainer
      activityItem={activityItem}
      isExternalReview={true}
      users={users}
      organisationDetails={organisationDetails}
      taskRevision={fakeTaskRevision}
      request={request}
      task={{
        id: request.id,
        projectId: request.projectId || "nothing",
        clientId: request.clientId || "nothing",
        title: activityItem.content.taskTitle,
        project,
        client,
        revisions: {
          items: [fakeTaskRevision],
        },
      }}
      hidePreloader={hidePreloader}
      showPreloader={showPreloader}
      location={location}
      {...props}
    />
  );
}

export default withRouter(
  withSubscriptions({
    Component: RequestReviewPage,
    subscriptions: ["organisationDetails", "request", "users"],
  })
);
