import { Typography, message } from "antd";
import { graphqlOperation } from "aws-amplify";
import * as mutations from "graphql/mutations";

import { callGraphQL } from "common/helpers";
import { callRest } from "common/apiHelpers";

export async function disableUser({ firstName, lastName, id, cognitoUsername }) {
  try {
    await callRest({
      route: "/disableUser",
      method: "post",
      body: {
        username: cognitoUsername,
      },
    });

    await callGraphQL(
      "Failed to delete user",
      graphqlOperation(mutations.updateUser, {
        input: {
          id,
          isDisabled: true,
          disabledAt: new Date().toISOString(),
        },
      })
    );
  } catch (e) {
    message.error(
      <Typography.Text>
        <b>
          {firstName} {lastName}
        </b>{" "}
        could not be disabled: <b>{e.message}</b>
      </Typography.Text>
    );
  }
}

export async function enableUser({ firstName, lastName, id, cognitoUsername }) {
  try {
    await callRest({
      route: "/enableUser",
      method: "post",
      body: {
        username: cognitoUsername,
      },
    });

    await callGraphQL(
      "Failed to delete user",
      graphqlOperation(mutations.updateUser, {
        input: {
          id,
          isDisabled: false,
        },
      })
    );
  } catch (e) {
    message.error(
      <Typography.Text>
        <b>
          {firstName} {lastName}
        </b>
        could not be enabled: <b>{e.message}</b>
      </Typography.Text>
    );
  }
}
