import { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { updateTask } from "graphql/queries_custom";
import { Form, Modal, Button, Select } from "antd";
import { getUserReadableCatLevel } from "common/helpers";

import { callGraphQL } from "common/helpers";

import "./CatLevelModal.scss";
import { getSimpleLabel } from "common/labels";

type Props = {
  onClose?: any;
  task?: any;
  user?: any;
  authorityType?: any;
  users?: any;
  visible?: any;
  organisationDetails: any;
};

export default function CatLevelModal({
  onClose,
  task,
  user,
  authorityType,
  users,
  visible,
  organisationDetails,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  const authorityTypeUpperCase = authorityType ? `${authorityType[0].toUpperCase()}${authorityType.substring(1)}` : "";

  const entity = task || user;

  async function onSubmit({ catLevel }) {
    if (catLevel === undefined) {
      catLevel = null;
    }
    setIsLoading(true);

    if (user) {
      await callGraphQL(
        `Could not change the ${getSimpleLabel("authority level")}`,
        graphqlOperation(updateUser, {
          input: {
            id: entity.id,
            [`catLevel${authorityTypeUpperCase}`]: catLevel,
          },
        })
      );
    } else if (task) {
      await callGraphQL(
        `Could not change the ${getSimpleLabel("authority level")}`,
        graphqlOperation(updateTask, {
          input: {
            id: entity.id,
            catLevel: catLevel,
          },
        })
      );
    }

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  function checkCatLevel(_, selectedCatLevel) {
    if (!task) {
      return Promise.resolve();
    }

    return new Promise<void>((resolve, reject) => {
      if (!selectedCatLevel) {
        return resolve();
      }
      const targetUser = users.find((x) => x.id === task.assignedTo);

      if (!targetUser) {
        return resolve();
      }
      if (!targetUser.catLevelDesign || targetUser.catLevelDesign < selectedCatLevel) {
        return reject("");
      }

      return resolve();
    });
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title={`Change ${authorityType || ""} authority level`}
      open={visible}
      onCancel={onClose}
      footer={null}
      className="cat-level-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          catLevel: entity[`catLevel${authorityTypeUpperCase}`],
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Authority"
          name="catLevel"
          className="cat-level"
          rules={[
            {
              validator: checkCatLevel,
              message: "The current assignee does not have the required design authority",
            },
          ]}
        >
          <Select className="cat-level-select" allowClear={organisationDetails.settings?.task?.allowUseOfCatZero}>
            <Select.Option key={0} value={0}>
              {getUserReadableCatLevel(organisationDetails, 0)}
            </Select.Option>
            <Select.Option key={1} value={1}>
              {getUserReadableCatLevel(organisationDetails, 1)}
            </Select.Option>
            <Select.Option key={2} value={2}>
              {getUserReadableCatLevel(organisationDetails, 2)}
            </Select.Option>
            <Select.Option key={3} value={3}>
              {getUserReadableCatLevel(organisationDetails, 3)}
            </Select.Option>
          </Select>
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading} data-cy="cat-level-modal-submit-button">
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
