import { useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import cx from "classnames";
import { Typography } from "antd";

import { graphqlOperation } from "aws-amplify";
import RichTextEditor from "react-rte";
import _ from "lodash";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQL, useStateRef } from "common/helpers";
import { updateClient } from "graphql/queries_custom";

import Card from "Card/Card";
import Input from "Input/Input";

import "./ClientSummary.scss";

export function ClientSummary({ client, organisationDetails, withSpace }) {
  const debouncedChangeNotes = useRef(_.debounce(() => changeNotes(), 500)).current;

  const [notes, setNotes, notesRef] = useStateRef(RichTextEditor.createValueFromString(client.notes || "", "html"));
  const [isNotesInputOpen, setIsNotesInputOpen] = useStateRef(false);

  useEffect(() => {
    window.addEventListener("click", onWindowClick);

    return () => {
      window.removeEventListener("click", onWindowClick);
    };
  }, []); //eslint-disable-line

  function onWindowClick() {
    setIsNotesInputOpen(false);
    changeNotes();
  }

  async function changeNotes() {
    let newNotes = notesRef.current.toString("html");
    if (
      client.notes === newNotes ||
      ((client.notes === null || client.notes === undefined) && newNotes === "<p><br></p>")
    ) {
      return;
    }

    await callGraphQL(
      "Failed to update notes",
      graphqlOperation(updateClient, {
        input: {
          id: client.id,
          notes: newNotes,
        },
      })
    );
  }

  const toolbarConfig = {
    // Optionally specify the groups to display (displayed in the order listed).
    display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
    INLINE_STYLE_BUTTONS: [
      { label: "Bold", style: "BOLD", className: "bold" },
      { label: "Italic", style: "ITALIC" },
      { label: "Underline", style: "UNDERLINE" },
    ],
    BLOCK_TYPE_DROPDOWN: [
      { label: "Normal", style: "unstyled" },
      { label: "Heading Large", style: "header-one" },
      { label: "Heading Medium", style: "header-two" },
      { label: "Heading Small", style: "header-three" },
    ],
    BLOCK_TYPE_BUTTONS: [
      { label: "UL", style: "unordered-list-item" },
      { label: "OL", style: "ordered-list-item" },
    ],
  };

  function setEditorFocus() {
    let editorElement = document.querySelector(".public-DraftEditor-content");
    if (!editorElement || !editorElement.isContentEditable) {
      setTimeout(setEditorFocus, 100);
      return;
    }

    editorElement.focus();
  }

  async function changeClientName(name) {
    await callGraphQL(
      "Failed to update name",
      graphqlOperation(updateClient, {
        input: {
          id: client.id,
          name,
        },
      })
    );
  }

  async function changeClientInitials(initials) {
    await callGraphQL(
      "Failed to update initials",
      graphqlOperation(updateClient, {
        input: {
          id: client.id,
          initials,
        },
      })
    );
  }

  return (
    <>
      <Card
        data-cy="client-details-summary"
        className={cx("client-summary")}
        withSpace={withSpace}
        title={
          <>
            <div className="client-details-container">
              <div className="client-initials-and-name">
                {organisationDetails.usesClientInitials && (
                  <Input
                    className="client-initials"
                    defaultValue={client.initials}
                    allowEnter={false}
                    onChange={changeClientInitials}
                    showBorder={!client.initials}
                    placeholder={client.initials ? "" : "Initials"}
                  />
                )}
                <Input
                  data-cy="client-details-name"
                  defaultValue={client.name}
                  className="client-name"
                  onChange={changeClientName}
                  fullWidth
                  multiLine
                  allowEnter={false}
                />
              </div>
            </div>

            <Typography.Paragraph className="client-notes-label">Notes:</Typography.Paragraph>

            <div
              onClick={(e) => {
                setIsNotesInputOpen(true);
                e.stopPropagation();
                setEditorFocus();
              }}
              onMouseDown={(e) => {
                e.stopPropagation();
              }}
              onMouseUp={(e) => {
                e.stopPropagation();
              }}
            >
              <RichTextEditor
                value={notes}
                autoFocus={true}
                onChange={(value) => {
                  setNotes(value);
                  debouncedChangeNotes();
                }}
                className="client-notes"
                readOnly={!isNotesInputOpen}
                toolbarConfig={toolbarConfig}
              />
            </div>
          </>
        }
      />
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ClientSummary,
    subscriptions: ["organisationDetails"],
  })
);
