import { Modal, Typography, Button, Dropdown, message } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import type { MenuProps } from "antd";
import { Storage } from "aws-amplify";
import axios from "axios";

import getS3File from "common/getS3File";
import { isAuthorised } from "common/permissions";

type Props = {
  templateDetails: any;
  form: any;
  formFileKey: string;
  apiUser: any;
};

export default function FormTemplateActions({ templateDetails, form, formFileKey, apiUser }: Props) {
  // it has to be an async function because otherwise the modal doesn't show up for some weird reason
  async function confirmToTemplate() {
    Modal.confirm({
      title: "Are you sure you want to copy this form to the template?",
      content: "This will overwrite the template form.",
      onOk: () => toTemplate(),
    });
  }

  // it has to be an async function because otherwise the modal doesn't show up for some weird reason
  async function confirmFromTemplate() {
    Modal.confirm({
      title: "Are you sure you want to replace this form with the template?",
      content: "This will overwrite this form.",
      onOk: () => fromTemplate(),
    });
  }

  async function toTemplate() {
    try {
      await Storage.put(templateDetails.key, JSON.stringify(form));
      message.success("Template form updated successfully");
    } catch (e) {
      message.error("Template form failed to update");
      console.log(e);
    }
  }

  async function fromTemplate() {
    try {
      const formFilePublicUrl = await getS3File(templateDetails.key);
      let form = (await axios.get(formFilePublicUrl)).data;
      await Storage.put(formFileKey, JSON.stringify(form));
      message.success("Form updated from template");
      window.location.reload();
    } catch (e) {
      message.error("Failed to update form from template");
      console.log(e);
    }
  }

  if (apiUser.isHidden || window.location.hostname === "localhost") {
    // we continue
  } else if (!isAuthorised(["MANAGE_TEMPLATES"])) {
    return null;
  }

  const menuItems: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Typography.Text onClick={confirmToTemplate}>
          Copy the contents of this form to the template (to template)
        </Typography.Text>
      ),
    },
    {
      key: "2",
      label: (
        <Typography.Text onClick={confirmFromTemplate}>
          Replace this form with the template (from template)
        </Typography.Text>
      ),
    },
    {
      key: "3",
      label: (
        <Link to={`/templates/annotation-editor/${templateDetails.type}/${templateDetails.id}`} target="_blank">
          Open template
        </Link>
      ),
    },
  ];

  return (
    <Dropdown menu={{ items: menuItems }} className="form-template-actions" trigger={["click"]}>
      <Button icon={<DownOutlined />}>Template actions</Button>
    </Dropdown>
  );
}
