import moment from "moment";

import { listHolidays } from "../organisation";
import { isUserAvailableInInterval } from "common/shared";

function getFieldValue(requestForm, fieldId) {
  if (!requestForm?.fields) {
    return;
  }

  let targetField = requestForm.fields[fieldId];
  if (!targetField) {
    return;
  }

  return targetField.value;
}

function getInternalDesignerDetails({ form, users }) {
  let twDesignCompanyField = form.fields["twdesigncompany-1723123910757"];
  if (!twDesignCompanyField) {
    return;
  }

  let twDesignCompany = twDesignCompanyField.value;
  if (twDesignCompany && twDesignCompany !== "Byrne Bros Formwork Ltd") {
    return;
  }

  let twDesignContactField = form.fields["byrnebrostwdesigncontact-1724332403072"];

  if (!twDesignContactField) {
    return;
  }

  let twDesignContactId = twDesignContactField.value;
  if (!twDesignContactId) {
    return;
  }

  let twDesignContact = users.find((user) => user.id === twDesignContactId);

  if (!twDesignContact) {
    return;
  }

  return twDesignContact;
}

const fields = [
  {
    id: "BYRNE_tw_scheme_number",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - TW Scheme Number",
    repeatForFieldName: "file",
    value: async ({ task, taskRevision, requestForm }) => {
      let fieldValue = getFieldValue(requestForm, "twschemeno3digit-1723123790668");
      return `DB ${fieldValue || ""}`;
    },
  },
  {
    id: "BYRNE_design_brief_number",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Design Brief number",
    repeatForFieldName: "file",
    value: async ({ task, taskRevision, requestForm }) => {
      return getFieldValue(requestForm, "twschemeno3digit-1723123790668");
    },
  },
  {
    id: "BYRNE_section_location",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Section location",
    repeatForFieldName: "file",
    value: async ({ task, taskRevision, requestForm }) => {
      return getFieldValue(requestForm, "sectionlocation-1723209641866");
    },
  },
  {
    id: "BYRNE_available_designers",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Available designers",
    repeatForFieldName: "user",

    value: async ({ users, form, request, organisationDetails }) => {
      if (!request) {
        return [];
      }

      if (!request.requestedForDate) {
        return [];
      }

      let ifcDesignRequestedDateField = form.fields["ifcdesignreqdate-1720778797047"];

      if (!ifcDesignRequestedDateField || !ifcDesignRequestedDateField.value) {
        return [];
      }

      let ifcDesignRequestedDateMoment = ifcDesignRequestedDateField.value;
      if (typeof ifcDesignRequestedDateMoment === "string") {
        ifcDesignRequestedDateMoment = moment(ifcDesignRequestedDateMoment);
      }

      let holidays = await listHolidays({
        organisation: organisationDetails.id,
        startsAt: moment(request.requestedForDate).subtract(2, "months"),
        endsAt: moment(ifcDesignRequestedDateMoment).add(2, "months"),
      });

      let userOptions = users.map((user) => {
        if (user.isDisabled) {
          return {
            name: `${user.firstName} ${user.lastName} (disabled)`,
            value: user.id,
            disabled: true,
          };
        }
        let { reason } = isUserAvailableInInterval({
          userId: user.id,
          holidays,
          startDate: moment(),
          endDate: ifcDesignRequestedDateMoment,
        });

        return {
          id: user.id,
          name: `${user.firstName} ${user.lastName}${reason ? ` (${reason})` : ""}`,
        };
      });

      return userOptions;

      // return userOptions.filter((option) => !option.disabled);
    },
  },
  {
    id: "files_byrneCheckCertificateForms",
    fieldTypes: ["repeatFor"],
    label: "BYRNE - Design Check Certificate forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      for (let crtTaskRevision of task?.revisions?.items) {
        for (let crtFile of crtTaskRevision?.files?.items) {
          if (crtFile?.name.toLowerCase().startsWith("design check certificate")) {
            return [crtFile];
          }
        }
      }
      return [];
    },
  },
  {
    id: "byrneNameOfTWDesignContact",
    label: "BYRNE - Name of TW Design Contact",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      return `${internalDesignerDetails.firstName} ${internalDesignerDetails.lastName}`;
    },
  },
  {
    id: "byrneEmailOfTWDesignContact",
    label: "BYRNE - Email of TW Design Contact",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      return `${internalDesignerDetails.id}`;
    },
  },
  {
    id: "byrnePhoneOfTWDesignContact",
    label: "BYRNE - Phone of TW Design Contact",
    value: ({ users, form }) => {
      let internalDesignerDetails = getInternalDesignerDetails({ form, users });

      if (!internalDesignerDetails) {
        return;
      }

      let phoneNumbers = [internalDesignerDetails.phone1, internalDesignerDetails.phone2].filter(
        (phoneNumber) => phoneNumber
      );

      return phoneNumbers.join(", ");
    },
  },
];
export function getFields() {
  return fields;
}
