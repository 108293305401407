import { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";

import { Form, Modal, Button } from "antd";

import { callGraphQL } from "common/helpers";

import CookiePolicy from "../../CookiePolicy/CookiePolicy";

import "./CookiePolicyModal.scss";

export default function CookiePolicyModal({ apiUser }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit() {
    setIsLoading(true);

    await callGraphQL(
      "Failed to record cookie policy acceptance",
      graphqlOperation(updateUser, {
        input: {
          id: apiUser.id,
          hasAgreedToCookies: true,
        },
      })
    );
  }

  return (
    <Modal
      maskClosable={false}
      title={
        <>
          <span className="title">Cookie policy</span>{" "}
          <span className="last-updated-date">Last updated: March 29, 2021</span>
        </>
      }
      open={true}
      footer={null}
      className="cookie-policy-modal"
      closeIcon={<></>}
    >
      <>
        <CookiePolicy />
        <Form form={form} initialValues={{}} onFinish={onSubmit}>
          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              I accept the cookie policy
            </Button>
          </div>
        </Form>
      </>
    </Modal>
  );
}
