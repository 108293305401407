import React, { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { createSprint } from "graphql/queries_custom";
import { Form, Modal, Button, Input, Space } from "antd";

import { callGraphQL } from "common/helpers";

import "./CreateSprintModal.scss";
import withSubscriptions from "common/withSubscriptions";

export function CreateSprintModal({ onClose, visible, apiUser }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name }) {
    setIsLoading(true);

    await callGraphQL(
      "Could not create sprint",
      graphqlOperation(createSprint, {
        input: {
          name,
          organisation: apiUser.organisation,
        },
      })
    );
    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Create sprint"
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-sprint-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="name"
            className="sprint-name"
            rules={[
              {
                required: true,
                message: "You must choose a name",
              },
            ]}
          >
            <Input data-cy="create-sprint-modal-sprint-name-input" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({ Component: CreateSprintModal });
