import moment from "moment";
import { Typography } from "antd";

import "./TemplateAlertBanners.scss";

export default function TemplateAlertBanners({
  outputTemplate,
  isViewingLatestVersion,
  isPreviewVisible,
  template,
  targetS3VersionDetails,
  s3Versions,
  apiUser,
}) {
  let oldVersionMessage = null;
  let previewMessage = null;
  let liveTemplateMessage = null;

  if (!isViewingLatestVersion && outputTemplate) {
    const latestS3Version = s3Versions[0];
    const latestVersionWasJustCreated = moment(latestS3Version.LastModified).add(2, "seconds").isAfter(moment());
    // there is a small delay between when the new version is created and when it is available to view,
    // so if we don't check for this, there is a glitch where the alert banner is shown for a split second
    if (!latestVersionWasJustCreated) {
      oldVersionMessage = (
        <Typography.Text className="viewing-old-version-text">
          Showing version
          <b style={{ marginLeft: "0.3rem", marginRight: "0.3rem" }}>
            {targetS3VersionDetails.index} out of {s3Versions.length}
          </b>
          from {moment(targetS3VersionDetails.LastModified).format("DD/MM/YYYY HH:mm:ss")} (read-only)
        </Typography.Text>
      );
    }
  }

  if (isPreviewVisible) {
    previewMessage = <span className="preview-visible-text">Showing preview</span>;
  } else {
    if (template?.isLive) {
      if (apiUser.isHidden || window.location.hostname === "localhost") {
        liveTemplateMessage = <span className="live-template-text">This is a live template, but you can edit it</span>;
      } else {
        liveTemplateMessage = <span className="live-template-text">This is a live template and cannot be edited</span>;
      }
    }
  }

  return (
    <div className="template-alert-banners">
      {oldVersionMessage}
      {previewMessage}
      {liveTemplateMessage}
    </div>
  );
}
