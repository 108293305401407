import React from "react";
import { Result, Button } from "antd";
import { DragDropContext } from "react-beautiful-dnd";
import { PlusCircleOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { graphqlOperation } from "aws-amplify";
import { updateTask } from "graphql/queries_custom";
import { getLabel, callGraphQL } from "common/helpers";
import { getFilteredTasks } from "common/filterHelpers";

import { LexoRank } from "lexorank";

import TaskFilters from "TaskFilters/TaskFilters";
import SprintItem from "./SprintItem/SprintItem";
import CreateSprintModal from "Modals/CreateSprintModal/CreateSprintModal";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";

import "./SprintsPage.scss";

export class SprintsPage extends React.Component {
  _isMounted = false;

  state = {
    isCreateSprintModalVisible: false,
    isCreateTaskModalVisible: false,
    isDragActive: false, // not actualy used at the moment
    selectedSprintId: null,
    filter: {},
    dragStartedOnSprintId: null,
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);
    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "SPRINTS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {}

  onBeforeDragStart = async (result) => {};

  onDragStart = async (result) => {
    this.setState({
      isDragActive: true,
      dragStartedOnSprintId: result.source.droppableId,
    });
  };

  onDragUpdate = async (result) => {};

  onDragEnd = async (result) => {
    const { context } = this.props;
    this.setState({ dragStartedOnSprintId: null });

    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const { tasks, projects, clients } = this.props;
    const draggableId = result.draggableId;

    let sourceSprintId = result.source.droppableId;
    let sourceIndex = result.source.index;
    let destinationSprintId = result.destination.droppableId;
    let destinationIndex = result.destination.index;
    let taskBeforeDestination;
    let taskAfterDestination;

    let filteredTasksInDestinationSprint = getFilteredTasks({
      projects,
      clients,
      tasks,
      filter: { ...this.state.filter, sprintId: undefined },
    })
      .filter((task) => task.id !== draggableId && task.sprintId === destinationSprintId)
      .sort((a, b) => (a.order < b.order ? -1 : 1));

    if (destinationSprintId === sourceSprintId && destinationIndex === sourceIndex) {
      return;
    }

    // debugger;
    let allTasksInDestinationSprint = tasks
      .filter((task) => task.id !== draggableId && task.sprintId === destinationSprintId)
      .sort((a, b) => (a.order < b.order ? -1 : 1));

    if (destinationIndex === 0) {
      taskAfterDestination = allTasksInDestinationSprint[0];
    } else if (destinationIndex === allTasksInDestinationSprint.length) {
      taskBeforeDestination = allTasksInDestinationSprint[allTasksInDestinationSprint.length - 1];
    } else {
      // debugger;
      taskBeforeDestination = filteredTasksInDestinationSprint[destinationIndex - 1];
      taskAfterDestination = allTasksInDestinationSprint.find(
        (_, i) => i > 0 && allTasksInDestinationSprint[i - 1].id === taskBeforeDestination.id
      );
    }

    let newOrder;
    if (!taskBeforeDestination && !taskAfterDestination) {
      newOrder = LexoRank.middle().value;
    } else if (!taskBeforeDestination) {
      newOrder = LexoRank.parse(taskAfterDestination.order).genPrev().value;
    } else if (!taskAfterDestination) {
      newOrder = LexoRank.parse(taskBeforeDestination.order).genNext().value;
    } else {
      newOrder = LexoRank.parse(taskBeforeDestination.order).between(LexoRank.parse(taskAfterDestination.order)).value;
    }

    let input = {
      sprintId: destinationSprintId,
      order: newOrder,
    };

    this.props.setProps({
      context: {
        ...context,
        tasks: tasks
          .map((task) => {
            if (task.id !== draggableId) {
              return task;
            }
            return {
              ...task,
              ...input,
            };
          })
          .sort((a, b) => (a.order < b.order ? -1 : 1)),
      },
    });

    await callGraphQL(
      "Failed to update task",
      graphqlOperation(updateTask, {
        input: {
          id: draggableId,
          ...input,
        },
      })
    );

    this.setState({ isDragActive: false });
  };

  displaySprintList = () => {
    let { sprints, tasks, organisationDetails, projects, clients, windowWidth } = this.props;
    const { dragStartedOnSprintId } = this.state;

    let filteredTasks = getFilteredTasks({
      projects,
      clients,
      tasks,
      filter: { ...this.state.filter, sprintId: undefined },
    });
    const oneSprintIsActive = sprints.some((x) => x.isActive);
    const sortedSprints = [...sprints].sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1));

    return (
      <div className="sprint-list">
        {sortedSprints
          .filter((x) => !x.isFinished)
          .map((sprint, i) => (
            <SprintItem
              key={`${sprint.id}-${i}`}
              {...sprint}
              sprints={sortedSprints}
              oneSprintIsActive={oneSprintIsActive}
              organisationDetails={organisationDetails}
              tasks={tasks.sort((a, b) => (a.order < b.order ? -1 : 1))}
              filteredTasks={filteredTasks}
              openCreateTaskModal={(selectedSprintId) =>
                this.setState({
                  selectedSprintId,
                  isCreateTaskModalVisible: true,
                })
              }
              dragStartedOnThis={dragStartedOnSprintId === sprint.id}
              windowWidth={windowWidth}
            />
          ))}
      </div>
    );
  };

  render() {
    const { organisationDetails, user, apiUser } = this.props;
    const { isCreateSprintModalVisible, isCreateTaskModalVisible, selectedSprintId } = this.state;

    if (!organisationDetails.usesSprints) {
      return (
        <Result
          status="error"
          title="Sprints are not enabled"
          subTitle="Please contact us in order to enable sprints for your organisation"
          extra={[
            <Button type="primary" key="console">
              Go back to Dashboard
            </Button>,
          ]}
        ></Result>
      );
    }

    return (
      <DragDropContext
        onBeforeDragStart={this.onBeforeDragStart}
        onDragStart={this.onDragStart}
        onDragUpdate={this.onDragUpdate}
        onDragEnd={this.onDragEnd}
      >
        <div className="sprints-page">
          <div className="main-content">
            <div className="filter-container">
              <TaskFilters
                onChange={(filter) => this.setState({ filter })}
                includeCreateTask={false}
                previousFilter={this.state.filter}
                defaultFilter={{ sprintId: "all" }}
                cookieName="task-filters-sprints-page"
                avatarListWidthToSubtract={160 + 100 + 220}
              />
              <Button
                type="primary"
                className="create-sprint"
                onClick={() => this.setState({ isCreateSprintModalVisible: true })}
              >
                <PlusCircleOutlined /> Create{" "}
                {getLabel({
                  organisationDetails,
                  id: "sprint",
                  defaultValue: "sprint",
                })}
              </Button>
            </div>
            {this.displaySprintList()}
          </div>
          {isCreateSprintModalVisible && (
            <CreateSprintModal
              visible={true}
              onClose={() => this.setState({ isCreateSprintModalVisible: false })}
              user={this.props.user}
              apiUser={this.props.apiUser}
            />
          )}
          {isCreateTaskModalVisible && (
            <CreateTaskModal
              onClose={() =>
                this.setState({
                  isCreateTaskModalVisible: false,
                  selectedSprintId: null,
                })
              }
              apiUser={apiUser}
              predefinedSprintId={selectedSprintId}
            />
          )}
        </div>
      </DragDropContext>
    );
  }
}

export default withSubscriptions({
  Component: SprintsPage,
  subscriptions: ["organisationDetails", "sprints", "tasks", "users", "projects", "clients"],
});
