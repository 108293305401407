import React from "react";
import {
  LaptopOutlined,
  DownloadOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { Steps, Typography, Button, Input, Space, Form, Result } from "antd";
import cookie from "js-cookie";

import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { callGraphQL } from "common/helpers";

import Card from "Card/Card";
import { DRAUGHTHUB_LINK_URL, PROGRAMS } from "common/constants";
import linkApi from "common/link";

import "./ConfigureComputer.scss";

type Props = {
  organisationDetails: any;
  apiUser: any;
};

export default class ConfigureComputer extends React.Component<Props> {
  _isMounted = false;
  pingTimeout: any = null;

  state = {
    currentStep: 0,
    computerName: "",
    appsFound: null,
    linkSetupComplete: false,
    linkBinaryDownloaded: false,
    isLinkSearching: null,
    linkIsRunning: false,
    isSearching: false,
  };

  componentDidMount() {
    this.attemptToResumeFlow();
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
  }

  startLinkPing = async () => {
    const { apiUser } = this.props;
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }

    try {
      const linkResponse = await linkApi.findBinaries();
      let { isSearching, found } = linkResponse.data;

      if (this._isMounted) {
        this.setState({
          isLinkSearching: isSearching,
          appsFound: found,
          linkIsRunning: true,
        });

        if (isSearching) {
          this.pingTimeout = setTimeout(this.startLinkPing, 1000);
        }
        if (isSearching || found) {
          linkApi.updateCredentials();

          this.setState({
            linkBinaryDownloaded: true,
            linkSetupComplete: true,
            currentStep: 2,
          });

          const computerId = cookie.get("computer-id");

          callGraphQL(
            "Failed to record this computer",
            graphqlOperation(updateUser, {
              input: {
                id: apiUser.id,
                computers: (apiUser.computers || []).map((computer) => {
                  if (computer.id === computerId) {
                    return {
                      ...computer,
                      linkIsInstalled: true,
                      linkIsRunning: true,
                    };
                  }
                  return computer;
                }),
              },
            })
          );

          cookie.set("link-setup-complete", "true", { expires: 99999 });
          cookie.set("link-binary-downloaded", "true", { expires: 99999 });
        }
      }
    } catch (e) {
      this.setState({ linkIsRunning: false });
      this.pingTimeout = setTimeout(this.startLinkPing, 1000);
    }
  };

  attemptToResumeFlow = () => {
    const computerName = cookie.get("computer-name");
    const linkBinaryDownloaded = cookie.get("link-binary-downloaded");
    const linkSetupComplete = cookie.get("link-setup-complete");

    this.setState({
      computerName,
      linkBinaryDownloaded,
      linkSetupComplete,
    });

    if (linkSetupComplete || linkBinaryDownloaded) {
      this.setState({ currentStep: 2 });
      this.startLinkPing();
    } else if (computerName) {
      this.setState({ currentStep: 2 });
    }
  };

  onComputerNameSubmit = async ({ name }) => {
    this.setState({ computerName: name, currentStep: 1 });

    cookie.set("computer-name", name, { expires: 99999 });

    this.startLinkPing();

    window.recordActiveComputer();
  };

  onDownloadLink = () => {
    this.setState({ linkBinaryDownloaded: true, currentStep: 2 });
    cookie.set("link-binary-downloaded", "true", { expires: 99999 });
    this.startLinkPing();
  };

  resetSetup = () => {
    if (this.pingTimeout) {
      clearTimeout(this.pingTimeout);
    }
    cookie.remove("link-setup-complete");
    cookie.remove("link-binary-downloaded");
    cookie.remove("computer-name");

    this.setState({
      computerName: "",
      linkBinaryDownloaded: null,
      linkSetupComplete: null,
      currentStep: 0,
    });
  };

  displayStepDeviceName = () => {
    return (
      <Form initialValues={{}} onFinish={this.onComputerNameSubmit}>
        <Space direction="vertical">
          <Typography.Title level={4}>Choose a name for this computer</Typography.Title>
          <Form.Item
            label="Computer name"
            name="name"
            rules={[
              {
                required: true,
                message: "You must specify a computer name",
              },
            ]}
            className="computer-name-input"
          >
            <Input autoComplete="off" prefix={<LaptopOutlined />} />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
    );
  };

  displayStepDraughtHubLink = () => {
    return (
      <Space direction="vertical">
        <Typography.Title level={4}>Download DraughtHub Link</Typography.Title>
        <div className="instructions">
          <a href={DRAUGHTHUB_LINK_URL} download>
            <Button type="primary" icon={<DownloadOutlined />} onClick={this.onDownloadLink}>
              Download
            </Button>
          </a>
          <br />
          <br />

          <Button onClick={this.onDownloadLink} className="program-already-installed">
            I already have it
          </Button>
          <br />
        </div>
      </Space>
    );
  };

  displayResultComplete = () => {
    const { linkIsRunning } = this.state;

    return (
      <>
        <Result
          status="success"
          title="Setup complete"
          subTitle={
            <>
              {linkIsRunning ? (
                <>DraughtHub Link is working correctly.</>
              ) : (
                <>
                  <b className="danger">DraughtHub Link is not running.</b>
                </>
              )}
              <br />
              <br />
              {linkIsRunning ? this.displayFoundAppList() : null}
            </>
          }
        />
        <div style={{ display: "flex", gap: "0.5rem" }}>
          <Button
            type="primary"
            onClick={() => {
              window.history.back();
            }}
          >
            Get back to what you were doing
          </Button>
          <br />
          <Button key="restart-setup" onClick={this.resetSetup}>
            Restart setup
          </Button>
        </div>
      </>
    );
  };

  displayFoundAppList = () => {
    let { appsFound, isSearching } = this.state;
    const { organisationDetails } = this.props;
    const { fileTypesUsed } = organisationDetails;
    const appsToUse = (fileTypesUsed || []).filter((fileType) => PROGRAMS.includes(fileType));

    if (isSearching) {
      return (
        <p className="still-searching">
          <LoadingOutlined /> Searching for apps
        </p>
      );
    }

    let appsNotFoundElements: React.ReactNode[] | null = null;
    let appsFoundElements: React.ReactNode[] | null = null;

    if (appsFound !== null) {
      appsNotFoundElements = appsToUse
        .filter((app: string) => !(appsFound as any).includes(app.toUpperCase()))
        .map((app: string, i) => {
          return (
            <div className="app-not-found" key={i}>
              <CloseCircleOutlined /> <b key={i}>{app}</b>
              <br />
            </div>
          );
        });
      appsFoundElements = appsToUse
        .filter((app: string) => (appsFound as any).includes(app.toUpperCase()))
        .map((app: string, i) => {
          return (
            <div className="app-found" key={i}>
              <CheckCircleOutlined /> <b key={i}>{app}</b>
              <br />
            </div>
          );
        });
    }

    return (
      <div>
        {!appsFoundElements || appsFoundElements.length === 0 ? null : (
          <>
            <p className="section-title">We have found the following apps:</p>
            {appsFoundElements}
          </>
        )}
        {!appsNotFoundElements || appsNotFoundElements.length === 0 ? null : (
          <>
            <p className="section-title" style={{ marginTop: appsFoundElements?.length ? 20 : 0 }}>
              We could not find:
            </p>
            {appsNotFoundElements}
          </>
        )}
      </div>
    );
  };

  displayResultWaitingForInstall = () => {
    return (
      <Space direction="vertical">
        <Typography.Title level={4}>Download and run DraughtHub Link</Typography.Title>
        <div className="run-instructions">
          <Typography.Paragraph>
            <b>1{")"}</b> Select "keep" in the browser download section
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>2{")"}</b> Run the installer .exe file
          </Typography.Paragraph>
          <Typography.Paragraph>
            <b>3{")"}</b> When the blue pop-up shows up saying "Windows protected your PC", click on "More info" and
            then "Run anyway"
          </Typography.Paragraph>
          <Typography.Paragraph>
            <LoadingOutlined /> Waiting for DraughtHub Link to respond...
          </Typography.Paragraph>
          <br />
          <Button key="restart-setup" onClick={this.resetSetup}>
            Restart setup
          </Button>
        </div>
      </Space>
    );
  };

  displayStepFinish = () => {
    const { linkSetupComplete, currentStep } = this.state;

    if (currentStep !== 2) {
      return null;
    }

    let stepContent: React.ReactNode | null = null;
    if (linkSetupComplete) {
      stepContent = this.displayResultComplete();
    } else {
      stepContent = this.displayResultWaitingForInstall();
    }

    return stepContent;
  };

  displayFinishIcon = () => {
    const { linkSetupComplete, linkBinaryDownloaded } = this.state;

    if (linkSetupComplete) {
      return <CheckCircleOutlined />;
    }

    if (linkBinaryDownloaded) {
      return <LoadingOutlined />;
    } else {
      return null;
    }
  };

  render() {
    const { currentStep } = this.state;

    const steps = [
      {
        title: "Choose a computer name",
        content: this.displayStepDeviceName(),
      },
      {
        title: "Install DraughtHub Link",
        content: this.displayStepDraughtHubLink(),
      },
      {
        title: "Finish",
        content: this.displayStepFinish(),
        icon: this.displayFinishIcon(),
      },
    ];

    return (
      <Card title="Install DraughtHub Link on this computer" className="configure-computer">
        <Steps current={currentStep} size="small">
          {steps.map((item) => (
            <Steps.Step key={item.title} title={item.title} icon={item.icon} />
          ))}
        </Steps>
        <div className="steps-content">{steps[currentStep].content}</div>
      </Card>
    );
  }
}
