function getFormFile({ task, startsWith }) {
  for (let crtTaskRevision of task?.revisions?.items) {
    for (let crtFile of crtTaskRevision?.files?.items) {
      if (crtFile?.name.toLowerCase().startsWith(startsWith)) {
        return [crtFile];
      }
    }
  }
  return [];
}

const fields = [
  {
    id: "files_radixClientDocumentsForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Client documents forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "client documents", task });
    },
  },
  {
    id: "files_radixSurveyOfficeForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Survey (office) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "survey info (office)", task });
    },
  },
  {
    id: "files_radixSurveyOnSiteForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Survey (on site) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "survey info (on site)", task });
    },
  },
  {
    id: "files_radixSurveyOnSiteForms",
    fieldTypes: ["repeatFor"],
    label: "RADIX - Testing information pack (office) forms",
    repeatForFieldName: "file",
    value: ({ task }) => {
      return getFormFile({ startsWith: "testing information pack (office)", task });
    },
  },
];
export function getFields() {
  return fields;
}
