import React from "react";
import moment from "moment";
import cx from "classnames";
import { withRouter, Link } from "react-router-dom";
import { Select, Button, Typography, Modal, message } from "antd";
import { PlusCircleOutlined, CheckCircleOutlined, EditOutlined } from "@ant-design/icons";
import { graphqlOperation } from "aws-amplify";

import { isAuthorised } from "common/permissions";
import { updateProject } from "graphql/queries_custom";
import withSubscriptions from "common/withSubscriptions";
import { callGraphQL } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import Input from "Input/Input";
import Explanation from "Explanation/Explanation";
import Card from "Card/Card";
import Avatar from "Avatar/Avatar";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import UsersFilter from "UsersFilter/UsersFilter";
import ClientLogo from "ClientLogo/ClientLogo";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import CreatePurchaseOrderModal from "Modals/CreatePurchaseOrderModal/CreatePurchaseOrderModal";
import ChangeClientModal from "Modals/ChangeClientModal/ChangeClientModal";

import "./ProjectSidebar.scss";
import { callGraphQLSimple } from "common/apiHelpers";

export class ProjectSidebar extends React.Component {
  state = {
    isCatLevelModalVisible: false,
    isLinkTaskModalVisible: false,
    isRequestReviewModalVisible: false,
    isCreateTaskModalVisible: false,
    isCreatePurchaseOrderModalVisible: false,
    isChangeClientModalVisible: false,
  };

  getExcludedAssigneeList = () => {
    const { task, users } = this.props;

    if (!task.catLevel) {
      return [];
    }
    const result = users.filter((x) => x.catLevelDesign < task.catLevel).map((x) => x.id);

    return result;
  };

  changeAssignedTo = async (newAssignedTo, project, users) => {
    await callGraphQL(
      "Failed to assign project",
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          assignedTo: newAssignedTo,
        },
      })
    );
    const targetUser = users.find((x) => x.id === newAssignedTo);

    if (targetUser) {
      message.success({
        content: (
          <Typography.Text>
            {getSimpleLabel("Project")} has been assigned to{" "}
            <b>
              {targetUser.firstName} {targetUser.lastName}
            </b>
          </Typography.Text>
        ),
        className: "update-cat-level-success",
      });
    } else {
      message.success({
        content: <Typography.Text>Project unassigned</Typography.Text>,
        className: "update-cat-level-success",
      });
    }

    await callGraphQLSimple({
      mutation: "createActivityItem",
      message: "Failed to create activity item",
      variables: {
        input: {
          parentId: project.id,
          content: JSON.stringify({
            oldAssignedTo: project.assignedTo,
            assignedTo: targetUser ? targetUser.id : null,
            type: "ASSIGNEE_CHANGED",
          }),
          type: "LIFECYCLE_EVENT",
          organisation: project.organisation,
          author: window.apiUser.id,
        },
      },
    });
  };

  assignProjectToUser = async ({ project, user }) => {
    await callGraphQL(
      "Failed to change senior engineer on project",
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          assignedTo: user ? user.id : null,
        },
      })
    );

    await callGraphQL(
      "Failed to refresh project details",
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );
  };

  onTeamChange = async (newTeam) => {
    if (!isAuthorised(["PROJECT.CHANGE_TEAM"])) {
      Modal.error({
        title: "Not authorised",
        content: <>You are not authorised to change the team of a {getSimpleLabel("project")}.</>,
      });
      return;
    }
    await window.callGraphQLSimple({
      message: "Failed to change team",
      queryCustom: "updateProject",
      variables: {
        input: {
          id: this.props.project.id,
          team: newTeam,
        },
      },
    });
  };

  confirmFinish = () => {
    const { project, tasks, quotes, history } = this.props;

    let activeTasksInProject = tasks.filter(
      (x) => x.projectId === project.id && !x.isArchived && !x.isFinished && !x.isExternalReference && !x.isHidden
    );

    let activeQuotesInProject = quotes.filter(
      (x) => x.projectId === project.id && !x.isArchived && x.status !== "REJECTED" && x.status !== "ACCEPTED"
    );

    if (activeTasksInProject.length > 0 || activeQuotesInProject.length > 0) {
      let modal = Modal.error({
        title: `Cannot finish ${getSimpleLabel("project")}`,
        className: "cannot-finish-project-modal",
        content: (
          <div className="cannot-finish-project-modal-content">
            {activeTasksInProject.length > 0 ? (
              <div>
                <b>{project.title}</b> currently has active {getSimpleLabel("tasks")} in it and cannot be finished:
                <ul className="projects-which-use-client">
                  {activeTasksInProject.map((task) => (
                    <li
                      key={task.id}
                      onClick={() => {
                        modal.destroy();
                        history.push(`/tasks/${task.id}`);
                      }}
                    >
                      {task.id} - {task.title}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
            {activeQuotesInProject.length > 0 ? (
              <div>
                <b>{project.title}</b> currently has active {getSimpleLabel("quotes")} in it and cannot be finished:
                <ul className="projects-which-use-client">
                  {activeQuotesInProject.map((quote) => (
                    <li
                      key={quote.id}
                      onClick={() => {
                        modal.destroy();
                        history.push(`/quotes/${quote.id}`);
                      }}
                    >
                      {quote.id} - {quote.title}
                    </li>
                  ))}
                </ul>
              </div>
            ) : null}
          </div>
        ),
      });
      return;
    } else {
      Modal.confirm({
        title: `Mark ${getSimpleLabel("project")} as finished`,
        icon: <CheckCircleOutlined />,
        content: (
          <>
            Are you sure you want to finish <b>{project.title}</b>?
          </>
        ),
        okButtonProps: { "data-cy": "finish-project-modal-ok" },
        cancelButtonProps: { "data-cy": "finish-project-modal-cancel" },
        onOk: async () => {
          await callGraphQL(
            "Failed to mark project as finished",
            graphqlOperation(updateProject, {
              input: {
                id: project.id,
                isFinished: true,
                finishedAt: new Date().toISOString(),
              },
            })
          );
        },
      });
    }
  };

  confirmResume = () => {
    const { project } = this.props;
    Modal.confirm({
      title: `Resume ${getSimpleLabel("project")}`,
      icon: <CheckCircleOutlined />,
      okButtonProps: { "data-cy": "resume-project-modal-ok" },
      cancelButtonProps: { "data-cy": "resume-project-modal-cancel" },
      content: (
        <>
          Are you sure you want to resume <b>{project.title}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQL(
          "Failed to resume project",
          graphqlOperation(updateProject, {
            input: {
              id: project.id,
              isFinished: false,
            },
          })
        );
      },
    });
  };

  displayFinishButton = () => {
    const { project } = this.props;

    if (project.isArchived) {
      return null;
    }
    if (project.isFinished) {
      return (
        <Button
          icon={<CheckCircleOutlined />}
          className="mark-as-not-finished"
          onClick={this.confirmResume}
          type="primary"
          data-cy="button-resume-project"
        >
          Resume
        </Button>
      );
    } else {
      return (
        <ButtonWithPermissions
          permissions={["PROJECT.FINISH"]}
          icon={<CheckCircleOutlined />}
          className="mark-as-finished"
          type="dark"
          onClick={this.confirmFinish}
          data-cy="button-finish-project"
        >
          Finish
        </ButtonWithPermissions>
      );
    }
  };

  render() {
    const { splitLayout, project, user, users, match, clients, organisationDetails, apiUser } = this.props;
    const { isCreateTaskModalVisible, isCreatePurchaseOrderModalVisible, isChangeClientModalVisible } = this.state;

    const client = clients.find((client) => client?.id === project.clientId);
    const authorData = users.find((x) => x.id === project.author);

    return (
      <>
        <Card className={cx("project-sidebar", { "split-layout": splitLayout })}>
          <div className="top-buttons">
            {!project.isArchived && !project.isFinished && (
              <ButtonWithPermissions
                type="primary"
                permissions={["CREATE_TASK"]}
                className="create-task"
                onClick={() => this.setState({ isCreateTaskModalVisible: true })}
                icon={<PlusCircleOutlined />}
              >
                Create {getSimpleLabel("task")} in {getSimpleLabel("project")}
              </ButtonWithPermissions>
            )}
            {!project.isArchived &&
              !project.isFinished &&
              organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
                <ButtonWithPermissions
                  type="primary"
                  permissions={["CREATE_PURCHASE_ORDER"]}
                  className="create-purchase-order"
                  onClick={() => this.setState({ isCreatePurchaseOrderModalVisible: true })}
                  icon={<PlusCircleOutlined />}
                >
                  Create purchase order in {getSimpleLabel("project")}
                </ButtonWithPermissions>
              )}
            {this.displayFinishButton()}
          </div>
          {organisationDetails.settings?.general?.usesTeams && (
            <div className="stat team-picker">
              <Typography.Text className="stat-label">Team</Typography.Text>
              <Typography.Text className="stat-value">
                <Select defaultValue={project.team} onChange={this.onTeamChange} className="active-on-hover">
                  {(apiUser.teams || []).map((teamId) => {
                    let teamDetails = organisationDetails.teams?.find((x) => x.id === teamId);
                    return (
                      <Select.Option value={teamDetails.id} key={teamDetails.id}>
                        {teamDetails.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Typography.Text>
            </div>
          )}
          <div className="stat">
            <div className={cx("assigned-to-container")}>
              <Typography.Text className="stat-label">Responsible senior engineer:</Typography.Text>
              {match.params.projectId && !project.isArchived ? (
                <UsersFilter
                  className="assigned-to-picker"
                  activateOnHover={true}
                  value={project.assignedTo}
                  onChange={(newAssignedTo) => this.changeAssignedTo(newAssignedTo, project, users)}
                />
              ) : (
                <Avatar user={users.find((x) => x.id === project.assignedTo)} showLabel />
              )}
            </div>
          </div>

          {client && (
            <Link to={`/clients/${client?.id}`}>
              <div className="stat clickable link ">
                <Typography.Text className="stat-label">{getSimpleLabel("Client")}</Typography.Text>
                <Typography.Text className="stat-value">
                  <ClientLogo client={client} />
                  <br />
                  <Button
                    icon={<EditOutlined />}
                    type="danger"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.setState({ isChangeClientModalVisible: true });
                    }}
                    disabled={!isAuthorised(["PROJECT.CHANGE_CLIENT"])}
                  >
                    Change {getSimpleLabel("client")}
                  </Button>
                </Typography.Text>
              </div>
            </Link>
          )}

          <div className="stat">
            <Typography.Text className="stat-label">PO Number</Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Input
                defaultValue={project.poNumber}
                showBorder
                onChange={async (poNumber) => {
                  await callGraphQL(
                    "Failed to update PO number",
                    graphqlOperation(updateProject, {
                      input: {
                        id: project.id,
                        poNumber,
                      },
                    })
                  );

                  await callGraphQLSimple({
                    mutation: "createActivityItem",
                    message: "Failed to create activity item",
                    variables: {
                      input: {
                        parentId: project.id,
                        content: JSON.stringify({
                          oldPoNumber: project.poNumber,
                          poNumber,
                          type: "PO_NUMBER_CHANGED",
                        }),
                        type: "LIFECYCLE_EVENT",
                        organisation: project.organisation,
                        author: window.apiUser.id,
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Typography.Text>
          </div>

          <div className="stat">
            <Typography.Text className="stat-label">
              <Explanation
                title={`The unique number allocated to every ${getSimpleLabel(
                  "task"
                )} is calculated by starting from the current ${getSimpleLabel(
                  "task"
                )} number. This number is incremented by 1 each time a new ${getSimpleLabel(
                  "task"
                )} gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
              />
              &nbsp; Current {getSimpleLabel("task")} number
            </Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Input
                defaultValue={project.taskCount || "0"}
                showBorder
                onChange={async (taskCount) => {
                  await callGraphQL(
                    `Failed to update current ${getSimpleLabel("task")} number`,
                    graphqlOperation(updateProject, {
                      input: {
                        id: project.id,
                        taskCount: parseInt(taskCount || 0),
                      },
                    })
                  );
                  await callGraphQLSimple({
                    mutation: "createActivityItem",
                    message: "Failed to create activity item",
                    variables: {
                      input: {
                        parentId: project.id,
                        content: JSON.stringify({
                          oldTaskCount: project.taskCount,
                          taskCount: parseInt(taskCount || 0),
                          type: "TASK_COUNT_CHANGED",
                        }),
                        type: "LIFECYCLE_EVENT",
                        organisation: project.organisation,
                        author: window.apiUser.id,
                      },
                    },
                  });
                }}
                fullWidth
              />
            </Typography.Text>
          </div>

          {organisationDetails.settings?.invoice?.usesInvoices && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every invoice is calculated by starting from the current invoice number. This number is incremented by 1 each time a new invoice gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current invoice number
              </Typography.Text>
              <Typography.Text className="stat-value created-by">
                <Input
                  defaultValue={project.invoiceCount || "0"}
                  showBorder
                  onChange={async (invoiceCount) => {
                    await callGraphQL(
                      "Failed to update current invoice number",
                      graphqlOperation(updateProject, {
                        input: {
                          id: project.id,
                          invoiceCount: parseInt(invoiceCount || 0),
                        },
                      })
                    );
                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldInvoiceCount: project.invoiceCount,
                            invoiceCount: parseInt(invoiceCount || 0),
                            type: "INVOICE_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                          author: window.apiUser.id,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}
          {organisationDetails.settings?.quote?.usesQuotes && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every ${getSimpleLabel(
                    "quote"
                  )} is calculated by starting from the current ${getSimpleLabel(
                    "quote"
                  )} number. This number is incremented by 1 each time a new ${getSimpleLabel(
                    "quote"
                  )} gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current {getSimpleLabel("quote")} number
              </Typography.Text>
              <Typography.Text className="stat-value created-by">
                <Input
                  defaultValue={project.quoteCount || "0"}
                  showBorder
                  onChange={async (quoteCount) => {
                    await callGraphQL(
                      `Failed to update current ${getSimpleLabel("quote")} number`,
                      graphqlOperation(updateProject, {
                        input: {
                          id: project.id,
                          quoteCount: parseInt(quoteCount || 0),
                        },
                      })
                    );

                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldQuoteCount: project.quoteCount,
                            quoteCount: parseInt(quoteCount || 0),
                            type: "QUOTE_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}
          {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
            <div className="stat">
              <Typography.Text className="stat-label">
                <Explanation
                  title={`The unique number allocated to every purchase order is calculated by starting from the current purchase order number. This number is incremented by 1 each time a new purchase order gets created. This process may have additional steps depending on the naming scheme of your organisation.`}
                />
                &nbsp; Current purchase order number
              </Typography.Text>
              <Typography.Text className="stat-value">
                <Input
                  defaultValue={project.purchaseOrderCount || "0"}
                  showBorder
                  onChange={async (purchaseOrderCount) => {
                    await callGraphQL(
                      "Failed to update current purchase order number",
                      graphqlOperation(updateProject, {
                        input: {
                          id: project.id,
                          purchaseOrderCount: parseInt(purchaseOrderCount || 0),
                        },
                      })
                    );

                    await callGraphQLSimple({
                      mutation: "createActivityItem",
                      message: "Failed to create activity item",
                      variables: {
                        input: {
                          parentId: project.id,
                          content: JSON.stringify({
                            oldPurchaseOrderCount: project.purchaseOrderCount,
                            purchaseOrderCount: parseInt(purchaseOrderCount || 0),
                            type: "PURCHASE_ORDER_COUNT_CHANGED",
                          }),
                          type: "LIFECYCLE_EVENT",
                          organisation: project.organisation,
                        },
                      },
                    });
                  }}
                  fullWidth
                />
              </Typography.Text>
            </div>
          )}

          <div className="stat created-by-container">
            <Typography.Text className="stat-label">Created by</Typography.Text>
            <Typography.Text className="stat-value created-by">
              <Avatar user={authorData} showLabel={true} maxLabelLength={22} />
            </Typography.Text>
          </div>

          <div className="stat">
            <Typography.Text className="stat-label">Created</Typography.Text>
            <Typography.Text className="stat-value">{moment(project.createdAt).fromNow()}</Typography.Text>
          </div>
          <div className="stat">
            <Typography.Text className="stat-label">Last updated</Typography.Text>
            <Typography.Text className="stat-value">{moment(project.updatedAt).fromNow()}</Typography.Text>
          </div>

          {isCreateTaskModalVisible && (
            <CreateTaskModal
              onClose={() => this.setState({ isCreateTaskModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ projectId: project.id }}
            />
          )}
          {isCreatePurchaseOrderModalVisible && (
            <CreatePurchaseOrderModal
              onClose={() => this.setState({ isCreatePurchaseOrderModalVisible: false })}
              apiUser={window.apiUser}
              predefinedFields={{ projectId: project.id }}
            />
          )}
          {isChangeClientModalVisible ? (
            <ChangeClientModal onClose={() => this.setState({ isChangeClientModalVisible: false })} project={project} />
          ) : null}
        </Card>
      </>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: ProjectSidebar,
    subscriptions: ["organisationDetails", "sprints", "tasks", "users", "clients"],
  })
);
