import React from "react";
import axios from "axios";
import { message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { getFieldDetailsById } from "common/documentEditorDataSources/aggregator";
import { Text, View, Image, Document, Font, Page } from "@react-pdf/renderer";

import {
  initialiseLambdaPdfInserts,
  setReportPageMapping,
  displayPdfAttachment,
  displayPdfTextarea,
  displayDocumentOutputTextarea,
} from "common/documentRenderHelpers";
import { getObjectStyle } from "TemplateEditorPage/renderHelpers";
import {
  replaceImageUrlsWithFreshImages,
  replaceImageUrlsWithFreshImagesInForm,
} from "TemplateEditorPage/browserOnlyRenderHelpers";
import getS3File from "common/getS3File";
import { computeDataFields, replaceDynamicFields, copyRepeatedObjects } from "common/sharedTemplateRenderHelpers";

import MrsSaintDelafield from "ReportPage/Report/MrsSaintDelafield-Regular.ttf";
import ReportPreview from "ReportPage/ReportPreview";

import "./DocumentOutput.scss";

Font.registerHyphenationCallback((word) => [word]);
Font.register({
  family: "MrsSaintDelafield",
  fonts: [
    {
      src: MrsSaintDelafield,
      fontWeight: "normal",
    },
  ],
});

export default class DocumentOutput extends React.Component {
  state = {
    isLoading: true,
    internalNumberForRefresh: 0,
    outputTemplateData: undefined,
    attachmentImages: {},
    registeredFonts: [],
    rawOutputTemplateData: undefined,
  };

  componentDidMount() {
    this.downloadOutputTemplateData();
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // this is only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      const previewData = JSON.parse(JSON.stringify(this.props.previewData));
      replaceImageUrlsWithFreshImagesInForm(previewData);

      try {
        this.setState(
          {
            reportJsonData: this.props.previewData,
            internalNumberForRefresh: this.state.internalNumberForRefresh + 1,
            isLoading: false,
          },
          this.refreshOutputTemplateWithNewData
        );
      } catch (e) {
        console.error(e);
        // nothing to do, we already show an error message in the function which actually failed
      }
    } else if (this.props.numberForRefresh !== prevProps.numberForRefresh) {
      this.refreshOutputTemplateWithNewData();
    }

    // if (!this.props.isDownloadButtonDisabled) {
    //   const userIsCat2Checker = this.isUserCat2Checker();
    //   if (userIsCat2Checker) {
    //     this.props.disableDownloadButton();
    //   }
    // }
  }

  getClient = () => {
    let clientId;
    if (this.props.task) {
      clientId = this.props.task.clientId;
    } else if (this.props.quote) {
      clientId = this.props.quote.clientId;
    } else if (this.props.invoice) {
      clientId = this.props.invoice.clientId;
    }
    const client = this.props.clients.find((client) => client.id === clientId);
    return client;
  };

  downloadOutputTemplateData = async () => {
    const { templateDetails, organisationDetails } = this.props;

    if (!templateDetails?.key) {
      return;
    }

    const jsonOutputTemplateFilePublicUrl = await getS3File(`${templateDetails.key.split(".")[0]}_annotation.json`);
    const jsonOutputTemplateFile = (await axios.get(jsonOutputTemplateFilePublicUrl)).data;

    let fontIdsToRegister = [];
    for (let i = 0; i < organisationDetails.variables.items.length; i++) {
      let variable = organisationDetails.variables.items[i];
      if (variable.type !== "FONT") {
        continue;
      }

      fontIdsToRegister.push(variable.name);

      const publicFontFileUrl = await getS3File(variable.value);

      let publicBoldFontFileUrl;
      if (!variable.name.toLowerCase().includes("bold")) {
        const baseFontName = variable.name
          .toLowerCase()
          .split("regular")
          .join("")
          .split("medium")
          .join("")
          .split("light")
          .join("")
          .trim();
        const boldVariantVariable = organisationDetails.variables.items.find((crtVariable) => {
          if (crtVariable.type !== "FONT") {
            return false;
          }
          if (
            crtVariable.name.toLowerCase().includes(baseFontName) &&
            crtVariable.name !== variable.name &&
            crtVariable.name.toLowerCase().includes("bold")
          ) {
            return true;
          }
          return false;
        });
        if (boldVariantVariable) {
          publicBoldFontFileUrl = await getS3File(boldVariantVariable.value);
        }
      }

      let fonts = [
        {
          src: publicFontFileUrl,
          fontWeight: "normal",
        },
      ];

      if (publicBoldFontFileUrl) {
        fonts.push({
          src: publicBoldFontFileUrl,
          fontWeight: "bold",
        });
      }

      Font.register({
        family: variable.name,
        fonts,
      });
    }

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    this.setState(
      {
        rawOutputTemplateData: jsonOutputTemplateFile,
        registeredFonts: fontIdsToRegister,
      },
      this.refreshOutputTemplateWithNewData
    );
  };

  refreshOutputTemplateWithNewData = async () => {
    try {
      if (!this.state.rawOutputTemplateData) {
        setTimeout(() => {
          this.refreshOutputTemplateWithNewData();
        }, 200);
        return;
      }
      let outputTemplateData = JSON.parse(JSON.stringify(this.state.rawOutputTemplateData));

      const form = JSON.parse(JSON.stringify(this.props.previewData));
      let keyData = {
        ...this.props,
        form,
      };

      // console.log("after repeat outputTemplateData = ", outputTemplateData);

      /*
        Every time we have a data source override (so far this is only an option for the "file" data source), we need to fetch the override file from the server.
        This field is used to cache the override files, so that we don't have to fetch them again if we need them.

        Since the files may change between refreshes, we need to clear this cache every time we refresh the output template.
      */
      window.documentTemplateOverrides = {};

      await computeDataFields({
        parent: outputTemplateData,
        params: keyData,
      });

      await copyRepeatedObjects({
        parent: outputTemplateData,
        params: keyData,
      });

      // console.log("output data after repeat:", JSON.stringify(outputTemplateData, null, 2));

      await replaceDynamicFields({
        parent: outputTemplateData,
        params: keyData,
      });

      await replaceImageUrlsWithFreshImages(outputTemplateData);

      // console.log("outputTemplateData = ", outputTemplateData);

      this.setState({
        outputTemplateData,
        isLoading: false,
        internalNumberForRefresh: this.state.internalNumberForRefresh + 1,
        formFileWithFreshImages: form,
      });
    } catch (err) {
      console.log("err = ", err);
    }
  };

  displayObject = ({ index, parent, object, root, pageSize, pagePadding, parentChain }) => {
    const { registeredFonts } = this.state;
    const { previewData: form } = this.props;

    let style = getObjectStyle({ index, parent, object, root });

    switch (object.custom_type) {
      case "qr-code":
        return (
          <View
            style={{
              ...style,
              height: style.width,
              backgroundColor: "#ddd",
              border: "2px solid black",
              fontSize: "10",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text>QR code</Text>
          </View>
        );
      case "signature":
        let signatureContent = null;
        if (object.src) {
          signatureContent = <Image src={object.src} style={{ objectFit: "scale-down", objectPosition: "0 0" }} />;
        } else if (object.custom_firstName || object.custom_lastName) {
          signatureContent = (
            <Text style={{ fontFamily: "MrsSaintDelafield", fontSize: Math.min(object.height / 1.6, 30) }}>
              {object.custom_firstName} {object.custom_lastName}
            </Text>
          );
        }
        return (
          <View
            key={object.custom_id}
            style={{
              ...style,
              width: object.width,
              height: object.height,
            }}
          >
            {signatureContent}
          </View>
        );

      case "image_container":
        return (
          <View
            key={object.custom_id}
            style={{
              ...style,
              width: object.width,
              height: object.height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {object.src ? <Image src={object.src} /> : null}
          </View>
        );
      case "dynamic_file":
        let files = (object.files || object.custom_fileKeys)?.filter((file) => file) || [];
        return (
          <>
            {files.map((file, i) => {
              let label = `${object.custom_name}`;
              if (object.files?.length > 0) {
                let fileKeyToDisplay = decodeURI(object.files[0]);
                if (fileKeyToDisplay.includes("?X-Amz-Algorithm")) {
                  fileKeyToDisplay = fileKeyToDisplay.split("?X-Amz-Algorithm")[0];
                }
                label += ` - ${fileKeyToDisplay.split("/").slice(-1)[0]}`;
              }

              return (
                <React.Fragment key={i}>
                  {displayPdfAttachment({
                    key: file,
                    label,
                    hasBorders: object.custom_hasPageBorders,
                    pagesToExclude: [],
                    wrapInPage: false,
                    pageSize,
                    pagePadding,
                  })}
                </React.Fragment>
              );
            })}
          </>
        );

      default:
        break;
    }

    switch (object.type) {
      case "rect":
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }
        return <View style={style} key={object.custom_id} />;
      case "ellipse":
        style = {
          ...style,
          width: object.width,
          height: object.height,
          borderRadius: "50%",
        };
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }

        return <View style={style} key={object.custom_id} />;
      case "text":
        let fontFamily = object.fontFamily;
        if (!registeredFonts.includes(fontFamily)) {
          fontFamily = undefined;
        }
        style = {
          ...style,
          borderColor: object.stroke,
          fontFamily,
          fontSize: object.fontSize,
          color: object.fill,
        };

        if (!object.custom_hideBackground && (object.custom_textFill || object.custom_formula_textFill)) {
          style.backgroundColor = object.custom_textFill || object.custom_formula_textFill;
        }

        if (object.strokeWidth && parseInt(object.strokeWidth) && object.stroke) {
          style.border = `${object.strokeWidth}px solid ${object.stroke || "#000000"}`;
        }

        if (object.custom_verticalAlign && object.custom_verticalAlign === "center") {
          style.display = "flex";
          style.justifyContent = "center";
          style.flexDirection = "column";
        }

        if (object.custom_verticalAlign && object.custom_verticalAlign === "bottom") {
          style.display = "flex";
          style.justifyContent = "flex-end";
          style.flexDirection = "column";
        }

        const paramsForGetField = {
          ...this.props,
          form,
          dataSource: object.custom_dynamicInformationDataSource,
          id: object.custom_dynamicInformation,
        };
        let fieldDetails = getFieldDetailsById(paramsForGetField);

        let isValidTextarea = false;
        if (!fieldDetails) {
          try {
            let parsedValue = JSON.parse(object.text);
            if (Array.isArray(parsedValue) || (typeof parsedValue === "object" && parsedValue !== null)) {
              isValidTextarea = true;
            }
          } catch (e) {
            // nothing, it's not a JSON value
          }
        }

        if (
          fieldDetails?.fieldType === "textarea" ||
          (fieldDetails?.type === "section" && fieldDetails?.id?.startsWith("section_")) ||
          isValidTextarea
        ) {
          let nodes;
          try {
            nodes = JSON.parse(object.text);
          } catch (e) {
            // nothing, it's not a JSON value
          }

          let contentToDisplay = null;

          let pageInnerHeight = pageSize.height - pagePadding.top - pagePadding.bottom;

          if (nodes && Array.isArray(nodes)) {
            let nodeContainers = [{ nodes: [], hasPageBreak: false }];
            let crtNodeContainerIndex = 0;
            for (let node of nodes) {
              if (node.type === "page-break") {
                crtNodeContainerIndex++;
                continue;
              }
              if (!nodeContainers[crtNodeContainerIndex]) {
                nodeContainers[crtNodeContainerIndex] = {
                  nodes: [],
                  hasPageBreak: true,
                };
              }
              nodeContainers[crtNodeContainerIndex].nodes.push(node);
            }

            contentToDisplay = nodeContainers.map((nodeContainer, i) => {
              let nodeContainerStyle = {};
              if (nodeContainer.hasPageBreak) {
                nodeContainerStyle.minHeight = pageInnerHeight - 1; // if we don't subtract 1, it tends to lead to extra empty pages
              }

              return (
                <View key={i} style={nodeContainerStyle} break={nodeContainer.hasPageBreak}>
                  {displayDocumentOutputTextarea({
                    props: this.props,
                    numberVariables: this.props.organisationDetails.variables?.items?.filter(
                      (x) => x.type === "NUMBER"
                    ),
                    colorVariables: this.props.organisationDetails.variables?.items?.filter((x) => x.type === "COLOR"),
                    nodes: nodeContainer.nodes,
                    style: {
                      fontFamily,
                      fontSize: object.fontSize,
                      color: object.fill,
                    },
                    pageSize,
                    pagePadding,
                  })}
                </View>
              );
            });
          } else {
            if (!fieldDetails) {
              contentToDisplay = null;
              let parentChainWithObject = [...(parentChain || []), object];
              let parentChainWithObjectWithoutDuplicates = parentChainWithObject.filter(
                (crtObject, index, array) => array.findIndex((o) => o.custom_id === crtObject.custom_id) === index
              );
              message.error(
                `Dynamic field not found for object: ${parentChainWithObjectWithoutDuplicates
                  .map((crtObject) => crtObject.custom_name)
                  .join(" -> ")}`,
                5
              );
            } else {
              try {
                contentToDisplay = displayPdfTextarea({
                  fieldName: fieldDetails.id,
                  targetReportJsonData: form,
                  attachmentImages: {}, //this.state.attachmentImagesCat2Check,
                  styles: {},
                  style: {
                    fontFamily,
                    fontSize: object.fontSize,
                    color: object.fill,
                  },
                  projectFolder: this.props.projectFolder,
                  displayTitle: false,
                  pageSize,
                  pagePadding,
                  props: this.props,
                  isNewTemplate: true,
                });
              } catch (e) {
                console.log("object failing to render", e, object);
              }
            }
          }

          return <View style={style}>{contentToDisplay}</View>;
        }

        return (
          <View
            style={{ ...style, width: object.width }}
            key={object.custom_id}
            wrap={object.custom_allowBreak !== false}
          >
            {!object.text || !object.text.split ? (
              <Text
                wrap={object.custom_allowBreak !== false}
                style={{
                  fontSize: style.fontSize,
                  fontFamily: style.fontFamily,
                  color: style.color,
                }}
              >
                {typeof object.text === "object" ? JSON.stringify(object.text) : object.text}
              </Text>
            ) : (
              object.text.split("\\n").map((line, i) => {
                return (
                  <Text
                    wrap={object.custom_allowBreak !== false}
                    style={{
                      fontSize: style.fontSize,
                      fontFamily: style.fontFamily,
                      color: style.color,
                    }}
                  >
                    {typeof line === "object" ? JSON.stringify(line) : line}
                  </Text>
                );
              })
            )}
          </View>
        );
      case "image":
        if (style.width !== undefined && style.width !== null) {
          style.width = style.width * (object.scaleX || 1);
          let scale = style.width / object.width;
          style.height = object.height * scale;
        }

        return <Image src={object.src} style={style} key={object.custom_id} />;

      default:
        return null;
    }
  };

  displayDocument = () => {
    const { outputTemplateData, isLoading } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};
    window.lambdaPdfPageNumbersToSkipBorders = [];

    window.lambdaPdfAssets = [];

    if (isLoading || !outputTemplateData) {
      return null;
    }

    let pagesAndChapters = outputTemplateData.objects.filter(
      (object) => ["chapter", "page"].includes(object.custom_type) && !object.isHidden && object.visible !== false
    );

    let pages = [];
    // expand chapters into pages, which are direct children of the chapter
    pagesAndChapters.forEach((pageOrChapter) => {
      if (pageOrChapter.custom_chapterLinkToButton) {
        // skip chapters that are linked to a specific form button. These are used to generate entirely separate PDFs,
        // but are still part of the same template
        return;
      }
      if (pageOrChapter.custom_type === "page") {
        pages.push(pageOrChapter);
      } else if (pageOrChapter.custom_type === "chapter") {
        pages.push(
          ...(pageOrChapter.objects || []).filter(
            (object) => object.custom_type === "page" && !object.isHidden && object.visible !== false
          )
        );
      }
    });

    return (
      <Document>
        {pages.map((page, pageIndex) => {
          let pageStyle = getObjectStyle({
            object: page,
            root: outputTemplateData,
          });
          let pageSize = {
            width: page.custom_pageWidth,
            height: page.custom_pageHeight,
          };
          let pagePadding = {
            top: pageStyle.paddingTop,
            right: pageStyle.paddingRight,
            bottom: pageStyle.paddingBottom,
            left: pageStyle.paddingLeft,
          };

          let initialisationElement = null;
          if (pageIndex === 0) {
            initialisationElement = (
              <View
                style={{ position: "absolute", top: 0, left: 0 }}
                render={({ totalPages }) => {
                  initialiseLambdaPdfInserts();
                  return null;
                }}
              />
            );
          }

          return (
            <Page size={pageSize} key={page.custom_id} style={pageStyle}>
              {initialisationElement}
              <Text
                style={{ position: "fixed", top: 0, left: 0, zIndex: 1000 }}
                fixed
                render={({ totalPages, pageNumber }) => {
                  setReportPageMapping({
                    sourcePageGroupName: page.custom_id,
                    sourcePageGroupNumber: pageIndex - (page.custom_repeatCloneIndex || 0),
                    correspondingPreviewPageNumber: pageNumber,
                  });
                  return null;
                }}
              />
              {this.displayParent({
                parent: page,
                root: outputTemplateData,
                pageSize,
                pagePadding,
                page,
              })}
            </Page>
          );
        })}
      </Document>
    );
  };

  displayParent = ({ parent, page, pageSize, pagePadding, root, parentChain }) => {
    return parent.objects
      .filter(
        (object) =>
          !object.isHidden &&
          object.visible !== false &&
          !object.custom_isPageBorder &&
          !object.custom_isPageNumber &&
          object.custom_type !== "page_background"
      )
      .map((object, objectIndex) => {
        if (object.custom_type === "section") {
          return this.displaySection({
            object,
            objectIndex,
            page,
            pageSize,
            pagePadding,
            parent,
            root,
            parentChain: [...(parentChain || []), parent],
          });
        } else {
          return this.displayObject({
            index: objectIndex,
            object: object,
            page,
            parent,
            root,
            pageSize,
            pagePadding,
            parentChain: [...(parentChain || []), parent],
          });
        }
      });
  };

  displaySection = ({ object, objectIndex, page, pageSize, pagePadding, parent, root, parentChain }) => {
    let sectionStyle = {
      ...getObjectStyle({
        object,
        index: objectIndex,
        parent,
        root,
      }),
    };

    return (
      <View key={object.custom_id} style={sectionStyle} wrap={object.custom_allowBreak !== false}>
        {this.displayParent({
          parent: object,
          page,
          pageSize,
          pagePadding,
          root,
          parentChain: [...(parentChain || []), parent],
        })}
      </View>
    );
  };

  render() {
    const { layout = "default", renderMode } = this.props;
    const { isLoading, internalNumberForRefresh } = this.state;

    if (isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    return (
      <div className="report report-dynamic document-output">
        <ReportPreview
          document={this.displayDocument({})}
          layout={layout}
          renderMode={renderMode}
          renderKey={internalNumberForRefresh}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}
