import { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import * as Sentry from "@sentry/react";
import { LexoRank } from "lexorank";
import { Modal, notification, Typography, Button, message } from "antd";
import { RightOutlined, DownOutlined, LoadingOutlined } from "@ant-design/icons";
import cookie from "js-cookie";
import axios from "axios";
import gsap from "gsap";
import moment from "moment";

import { changeTaskStatus } from "common/changeTaskStatus";
import { callGraphQLSimple, fetchCollection, callRest, getRestEndpoint } from "./apiHelpers";
import { getCurrentSession } from "common/authHelpers";

import {
  getProjectId,
  getTaskId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getFrontendFileName,
  changeFileNameAtDownloadTime,
} from "./naming";
import {
  getReview,
  getPurchaseOrder,
  getSupplier,
  listUsersByOrganisation,
  listTimesheetTagsByOrganisation,
  listNotificationsByUser,
  listHolidaysByUser,
  listHolidaysByOrganisation,
  listQuoteLineItems,
  listGroupsByOrganisation,
  listPurchaseOrdersByOrganisation,
  listSuppliersByOrganisation,
  listAnalyticsByOrganisation,
} from "graphql/queries";
import {
  getClient,
  listBoards,
  getBoard,
  listSprints,
  listProjectsSimple,
  getTaskSimple,
  getProjectSimple,
  createTask,
  updateTask,
  deleteTask,
  createProject,
  updateProject,
  createAsyncJob,
  getTaskRevision,
  getFile,
  updateTaskRevision,
  createTaskRevision,
  createFile,
  getQuote,
  getInvoice,
  listClients,
  updateQuote,
  updateClient,
  getOrganisation,
  createClient,
} from "graphql/queries_custom";
import {
  createFileVersion,
  createReview,
  createSheet,
  createSheetRevision,
  createSubtask,
  deleteOrganisation,
  updateSheetRevision,
  updateOrganisation,
  updateSheet,
  deleteSheet,
  updateFile,
  updateFileVersion,
  updateQuoteLineItem,
} from "graphql/mutations";

import awsExports from "aws-exports";
import linkApi from "common/link";
import { createTemplate } from "common/templateHelpers";
import { copyQuoteTemplate } from "QuoteDetailsPage/quoteHelpers";

import {
  DEFAULT_PAGE_LENGTH,
  CONSIDER_PUBLISH_FAILED_THRESHOLD,
  CREATE_RETRY_LIMIT,
  FILE_TYPES_ORDER,
  FILE_TYPES_READABLE,
  IS_NEW_THRESHOLD,
  FILES_TO_PUBLISH_ON_CREATE,
  TASK_RELATIONSHIPS,
  SHEET_NAMES_INVALID_CHARACTERS_PATTERN,
  COOKIE_NAME_SELECTED_TEAMS,
} from "common/constants";
import {
  KEY_TYPES,
  getLatestRevision,
  getLatestFileVersion,
  getFilenameFromKey,
  getExtensionFromKey,
  encodeKey,
  buildFileName,
  HAS_SHEETS,
  getAttachmentTypeFromKey,
} from "common/shared";
import { sendNotificationToTaskAssignee } from "./notificationHelpers";
import { getSimpleLabel } from "./labels";
import getS3File from "common/getS3File";

export function processIdForDisplay(id = "") {
  if (window.organisationDetails?.settings?.general?.hideOrganisationIdInTags) {
    let targetStringToRemove = `${window.organisationDetails?.id}-`;
    if (id.startsWith(targetStringToRemove)) {
      id = id.substring(targetStringToRemove.length);
    }
  }
  return id;
}

export const useEffectOnlyOnce = (func) => useEffect(func, []); // eslint-disable-line
export function useStateRef(initialValue) {
  const [value, setValue] = useState(initialValue);

  const ref = useRef(value);

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return [value, setValue, ref];
}

export function stringToColor({ string, lightness = 50, saturation = 100 }) {
  let hash = 0;
  if (string.length > 0) {
    for (let i = 0; i < string.length; i++) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
      hash = hash & hash; // Convert to 32bit integer
    }
  }

  const shortened = hash % 360;
  return `hsl(${shortened},${saturation}%,${lightness}%)`;
}

export function computePublishStatus(file) {
  let { publishStartAt, publishEndAt, publishError } = { ...file };
  let publishStatus = "IN_PROGRESS";

  if (!publishStartAt) {
    publishStatus = "NOT_STARTED";
  } else if (publishStartAt > publishEndAt || !publishEndAt) {
    const publishDuration = Math.abs(new Date() - new Date(publishStartAt));
    if (publishDuration > CONSIDER_PUBLISH_FAILED_THRESHOLD) {
      publishStatus = "ERROR";
    } else if (publishError) {
      publishStatus = "ERROR";
    }
  } else if (publishEndAt > publishStartAt) {
    publishStatus = "FINISHED";
  }
  return publishStatus;
}

export function getUserReadableCatLevel(organisationDetails, level) {
  let value = "";
  let labelId = "";
  switch (level) {
    case 0:
      value = "Unknown";
      labelId = "cat-zero";
      break;
    case 1:
      value = "Cat 1";
      labelId = "cat-one";
      break;
    case 2:
      value = "Cat 2";
      labelId = "cat-two";
      break;
    case 3:
      value = "Cat 3";
      labelId = "cat-three";
      break;
    case null:
      value = "Not Set";
      labelId = "cat-null";
      break;

    default:
      throw new Error(`Cat level unknown: ${level}`);
  }

  value = getLabel({ organisationDetails, id: labelId, defaultValue: value });
  return value;
}

export function processEntityBeforeSetState({ entityName, entityData }) {
  switch (entityName) {
    case "review":
      entityData.reviewThread.sort((a, b) => (a.createdAt > b.createdAt ? 1 : -1));
      break;
    default:
      break;
  }
  return entityData;
}

export async function isFileOpen({ task, file }) {
  const latestVersionForFile = file.versions.items.slice(-1)[0];
  if (window.isMac || window.Cypress) {
    return false;
  }
  try {
    const linkResponse = await linkApi.isFileOpen({
      executable: file.type,
      fileVersionId: latestVersionForFile.id,
      versionNumber: latestVersionForFile.versionNumber,
      fileId: file.id,
      taskId: task.id,
      organisation: task.organisation,
      taskRevisionId: file.taskRevisionId,
      key: latestVersionForFile.key,
    });
    return linkResponse.data.fileIsOpen;
  } catch (e) {
    return false;
  }
}

export function showFileIsOpenModal(file) {
  return Modal.error({
    closable: true,
    maskClosable: true,
    title: (
      <>
        File is already open in <b>{FILE_TYPES_READABLE[file.type]}</b>
      </>
    ),
    content: `In order to continue, please close the file in ${FILE_TYPES_READABLE[file.type]} and then try again.`,
    className: "file-is-open-modal",
    okButtonProps: { style: { display: "none" } },
  });
}

export async function openFileWithLink({ file, fileVersion, revisionData, task, history, page }) {
  let deviceInitFinished = cookie.get("link-setup-complete");

  if (!window.skipLinkCheck) {
    if (!window.useLink && !deviceInitFinished) {
      showInitialisationModal(history);
      return;
    }

    let linkIsRunning = await linkApi.checkLinkIsRunning();

    if (!linkIsRunning) {
      showLinkIsNotRunningModal();
      return;
    }
  }

  if (!revisionData) {
    revisionData = getLatestRevision(task);
  }

  const latestVersionForFile = file.versions.items.slice(-1)[0];

  const isReadOnly = revisionData.isReadOnly;
  if (isReadOnly) {
    try {
      await new Promise((resolve, reject) => {
        let modal = Modal.confirm({
          title: "Read-only file",
          className: "open-readonly-modal",
          content: (
            <>
              The file you are about to open is read-only. Any changes you make to it will not be uploaded back to the
              cloud.
            </>
          ),
          onOk: () => {
            modal.destroy();
            resolve();
          },
          onCancel: () => {
            modal.destroy();
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, the user clicked "cancel"
      return;
    }
  }

  let modal = Modal.info({
    closable: true,
    maskClosable: true,
    title: (
      <>
        Checking if the file is already open...
        <LoadingOutlined />
      </>
    ),
    className: "check-file-is-open-modal",
    okButtonProps: { style: { display: "none" } },
  });

  try {
    await linkApi.updateCredentials();
  } catch (e) {
    message.error("Failed to update credentials for DraughtHub Link");
  }

  await new Promise((resolve) => setTimeout(resolve, 1000));
  try {
    const fileIsOpen = await isFileOpen({ task, file });

    if (fileIsOpen) {
      modal.destroy();
      modal = Modal.error({
        closable: true,
        maskClosable: true,
        title: (
          <>
            File is already open in <b>{FILE_TYPES_READABLE[file.type]}</b>
          </>
        ),
        content: "If you want to re-open the file from DraughtHub, first close it down on your computer and try again.",
        className: "file-is-open-modal",
        okButtonProps: { style: { display: "none" } },
      });
      return;
    } else {
      modal.update({
        title: (
          <>
            <Typography.Text>Opening the file...</Typography.Text>
            <LoadingOutlined />
          </>
        ),
      });
    }
    await new Promise((resolve) => setTimeout(resolve, 200));

    callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: task.id,
          projectId: task.projectId,
          fileId: file.id,
          clientId: task.clientId,
          page,
          type: "OPEN_FILE_WITH_LINK",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    const linkResponseOpenFile = await linkApi.run({
      executable: file.type,
      fileVersionId: latestVersionForFile.id,
      versionNumber: fileVersion.versionNumber,
      fileId: file.id,
      taskId: task.id,
      isReadOnly: revisionData.isReadOnly,
      organisation: task.organisation,
      taskRevisionId: revisionData.id,
      key: latestVersionForFile.key,
      publishedKey: latestVersionForFile.exports[0].rawKey,
      annotatedKey: latestVersionForFile.exports[0].key,
      customId: latestVersionForFile.customId,
      externalReferences: latestVersionForFile.externalReferences,
      sheets: file.sheets.items.filter((sheet) => sheet.includeInPublish).map((sheet) => sheet.name),
    });

    if (linkResponseOpenFile.data.success) {
      modal.destroy();
      modal = Modal.success({
        closable: true,
        maskClosable: true,
        title: <>File has opened</>,
        content: (
          <>
            You can continue your work in <b>{FILE_TYPES_READABLE[file.type]}</b>
          </>
        ),
        className: "file-has-opened-modal",
        okButtonProps: { style: { display: "none" } },
      });
      setTimeout(modal.destroy, 5000);
    } else {
      modal.destroy();
      modal = Modal.error({
        closable: true,
        maskClosable: true,
        title: <>File failed to open</>,
        content: `Reason: ${linkResponseOpenFile.data.message}`,
        className: "file-failed-to-open-modal",
        okButtonProps: { style: { display: "none" } },
      });
    }
  } catch (e) {
    console.error("File failed to open, error:", e);
    modal.destroy();
    modal = Modal.error({
      closable: true,
      maskClosable: true,
      title: <>File failed to open</>,
      content: `Reason: ${e}`,
      className: "file-failed-to-open-modal",
      okButtonProps: { style: { display: "none" } },
    });
  }
}

export function displayErrorMessage(e, message, reason) {
  let isNetworkError = false;
  if (e?.isAxiosError && e?.response?.data && typeof e.response.data === "string") {
    reason = e.response.data;
  } else if (e.message) {
    reason = e.message;
    // reason = e.errors.map((error, i) => <Typography.Paragraph key={i}>{error.message}</Typography.Paragraph>);
  } else if (e.errors && e.errors[0] && e.errors[0].message) {
    reason = e.errors[0].message;
  } else {
    reason = "GraphQL API error";
  }

  isNetworkError = reason === "Network Error";

  notification.error({
    message: (
      <Typography.Text>
        {message}.
        <br />
        <b>Reason:</b>{" "}
        {isNetworkError ? (
          "Network error (You are probably offline)"
        ) : (
          <>
            <br />
            {reason}
          </>
        )}
        <br />
        {/* {isNetworkError ? null : (
          <b>Our team has been notified of the error, and we will fix it as soon as possible.</b>
        )} */}
      </Typography.Text>
    ),
    duration: window.Cypress ? 5 : 0,
  });
}

export async function captureError({ callback, message, displayError }) {
  try {
    const result = await callback();
    return result;
  } catch (e) {
    let sentryMessage = message;
    let sentryData = {
      extra: {
        error: JSON.stringify(e, null, 2),
      },
      level: "error",
    };
    // console.log("captureError() sentryMessage = ", sentryMessage);
    if (!sentryMessage) {
      sentryMessage = e.message;
    }
    if (!sentryMessage) {
      let dataPart = e.data ? Object.keys(e.data)[0] : "";
      sentryMessage = `GraphQL error: ${dataPart} - ${e.errors[0].errorType || e.errors[0].message}`;
    }
    Sentry.captureMessage(sentryMessage, sentryData);
    if (displayError) {
      displayErrorMessage(e, message || "There has been an error", sentryMessage);
    }
    throw e;
  }
}

export async function callGraphQL(message, operation, displayError = true) {
  return await captureError({
    callback: async () => {
      await getCurrentSession();
      const response = await API.graphql(operation);
      if (response.errors) {
        throw response;
      } else {
        return response;
      }
    },
    message,
    displayError,
  });
}

export async function deleteS3File({ key, versionId }) {
  const params = { Bucket: awsExports.aws_user_files_s3_bucket, Key: key };
  if (versionId) {
    params.VersionId = versionId;
  }

  return new Promise((resolve, reject) => {
    window.s3.deleteObject(params, function (err, data) {
      if (err) {
        console.error("Error deleting S3 file:", err, err.stack);
        reject(err);
      } else {
        resolve();
      }
    });
  });
}

/*
  This function is used to copy the actual S3 file for a file type.
  It's used either to copy within the same task revision (regular up-revving),
  or when we create a new task revision.
  When creating a new task revision, we also pass oldFile and oldTaskRevision as parameters.
*/
export async function copyS3MainFile({
  history,
  task,
  apiUser,
  oldTaskRevision,
  oldFile,
  oldVersionNumber,
  taskRevision,
  file,
  newVersionNumber,
  oldFileVersion,
  newFileVersion,
  openConfirmationModal = true,
  doPublish = false,
}) {
  const requestBody = {
    extension: file.extension,
    organisation: apiUser.organisation,
    oldKey: oldFileVersion.key,
    oldVersionNumber,
    newVersionNumber,
    projectId: task.projectId,
    taskId: task.id,
    clientInitials: task.client?.initials || "",
    projectInitials: task.project?.initials || "",
    taskInitials: task.initials,
    fileId: file.id,
    taskRevisionName: taskRevision.name,
    fileType: file.type,
  };

  if (newFileVersion) {
    requestBody.newKey = newFileVersion.key;
  } else {
    requestBody.newKey = await encodeKey({
      type: KEY_TYPES.FILE_MAIN,
      data: {
        ...requestBody,
        versionNumber: requestBody.newVersionNumber,
      },
    });
  }

  if (oldFile) {
    requestBody.oldFileId = oldFile.id;
  }
  if (oldTaskRevision) {
    requestBody.oldTaskRevisionName = oldTaskRevision.name;
  }

  const copyS3FileResponse = await callRest({
    method: "post",
    route: "/copyS3File",
    body: requestBody,
  });

  // I don't think it's a good idea for this to keep popping up anymore

  // if (!window.Cypress && openConfirmationModal && FILES_TO_OPEN_ON_CREATE.includes(file.type) && history) {
  //   Modal.confirm({
  //     title: "Open new file version",
  //     className: "confirm-open-new-file-version",
  //     content: (
  //       <>A new version of the {FILE_TYPES_READABLE[file.type]} file has been created. Would you like to open it now?</>
  //     ),
  //     cancelText: "Later",
  //     okText: "Open",
  //     onOk: async () => {
  //       await openFileWithLink({
  //         revisionData: taskRevision,
  //         task,
  //         file,
  //         history,
  //         fileVersion: getLatestFileVersion(file),
  //       });
  //     },
  //   });
  // }

  if (doPublish && FILES_TO_PUBLISH_ON_CREATE.includes(file.type)) {
    await publish({
      projectId: task.projectId,
      file,
      fileVersionId: getLatestFileVersion(file).id,
      taskRevisionId: taskRevision.id,
      taskRevisionName: taskRevision.name,
      task,
    });
  }

  return copyS3FileResponse;
}

export function getReadableStatus(status) {
  if (!status) {
    return "";
  }
  if (status.length === 0) {
    return status;
  }
  if (status !== status.toUpperCase()) {
    return status;
  }

  if (!status.includes("_")) {
    return status[0].toUpperCase() + status.substring(1).toLowerCase();
  }

  return status
    .split("_")
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1).toLowerCase();
    })
    .join(" ");
}

export function getUppercaseStatus(status) {
  if (!status) {
    return;
  }
  if (status.length === 0) {
    return status;
  }
  return status.toUpperCase().split(" ").join("_");
}

// API operations
export function fetchAndSetAnalytics({ filter = undefined, organisation, nextToken, limit }) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!limit) {
        this.setState(
          {
            context: {
              ...this.state.context,
              analytics: [],
            },
          },
          () => resolve([])
        );
        return;
      }

      let params = { organisation, sortDirection: "DESC", nextToken, limit };
      if (filter) {
        params.filter = filter;
      }

      const response = await callGraphQL(
        "Failed to list analytics",
        graphqlOperation(listAnalyticsByOrganisation, params)
      );
      const pageOfItems = response.data.listAnalyticsByOrganisation.items;

      this.setState(
        {
          context: {
            ...this.state.context,
            analytics: [...(this.state.context.analytics || []), ...pageOfItems],
            analyticsJobsNextToken: response.data.listAnalyticsByOrganisation.nextToken,
          },
        },
        () => resolve(pageOfItems)
      );
    } catch (err) {
      console.log("error: ", err);
      reject(err);
    }
  });
}

export function fetchAndSetRequests({ limit = DEFAULT_PAGE_LENGTH, filter = {}, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { limit, organisation };
      params.filter = { ...filter };

      if (Object.keys(params.filter).length === 0) {
        params.filter = undefined;
      }
      let items = [];

      while (true) {
        const response = await callGraphQLSimple({
          message: `Failed to list ${getSimpleLabel("requests")}`,
          query: "listRequestsByOrganisation",
          variables: params,
        });
        const pageOfItems = response.data.listRequestsByOrganisation.items;
        params.nextToken = response.data.listRequestsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.requests = items;
        resolve();
      } else {
        this.setState(
          {
            requests: items,
            context: { ...this.state.context, requests: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetStockItems({ limit = DEFAULT_PAGE_LENGTH, filter = {}, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { limit, organisation };
      params.filter = { ...filter };

      if (Object.keys(params.filter).length === 0) {
        params.filter = undefined;
      }
      let items = [];

      while (true) {
        const response = await callGraphQLSimple({
          message: `Failed to list ${getSimpleLabel("stock items")}`,
          query: "listStockItemsByOrganisation",
          variables: params,
        });
        const pageOfItems = response.data.listStockItemsByOrganisation.items;
        params.nextToken = response.data.listStockItemsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.stockItems = items;
        resolve();
      } else {
        this.setState(
          {
            stockItems: items,
            context: { ...this.state.context, stockItems: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetUsers({ limit = DEFAULT_PAGE_LENGTH, filter = {}, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { limit, organisation };
      params.filter = { ...filter };

      if (Object.keys(params.filter).length === 0) {
        params.filter = undefined;
      }
      let items = [];

      while (true) {
        const response = await callGraphQL(
          "Failed to list the users",
          graphqlOperation(listUsersByOrganisation, params)
        );
        const pageOfItems = response.data.listUsersByOrganisation.items;
        params.nextToken = response.data.listUsersByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.users = items;
        resolve();
      } else {
        this.setState(
          {
            users: items,
            context: {
              ...this.state.context,
              users: items,
            },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}
export function fetchAndSetHolidaysByUser({ userId }) {
  return new Promise(async (resolve, reject) => {
    if (!userId) {
      let items = [];
      if (this?.setState) {
        this.setState(
          {
            context: {
              ...this.state.context,
              holidays: items,
            },
          },
          () => resolve(items)
        );
      } else {
        resolve(items);
      }
      return;
    }
    try {
      let params = {
        userId,
        limit: 1000,
      };
      let items = [];

      while (true) {
        const response = await callGraphQL(
          "Failed to list the user's holidays",
          graphqlOperation(listHolidaysByUser, params)
        );
        const pageOfItems = response.data.listHolidaysByUser.items;
        params.nextToken = response.data.listHolidaysByUser.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      this.setState(
        {
          holidays: items,
          context: { ...this.state.context, holidays: items },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetHolidays({ organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = {
        organisation,
        limit: 1000,
      };
      let items = [];

      while (true) {
        const response = await callGraphQL(
          "Failed to list holidays",
          graphqlOperation(listHolidaysByOrganisation, params)
        );
        const pageOfItems = response.data.listHolidaysByOrganisation.items;
        params.nextToken = response.data.listHolidaysByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      this.setState(
        {
          holidays: items,
          context: { ...this.state.context, holidays: items },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetGroups({ organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = {
        organisation,
      };
      let items = [];

      while (true) {
        const response = await callGraphQL(
          "Failed to list the groups",
          graphqlOperation(listGroupsByOrganisation, params)
        );
        const pageOfItems = response.data.listGroupsByOrganisation.items;
        params.nextToken = response.data.listGroupsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.groups = items;
        resolve();
      } else {
        this.setState(
          {
            groups: items,
            context: { ...this.state.context, groups: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetBoards() {
  return new Promise(async (resolve, reject) => {
    try {
      let params = {};
      let items = [];

      while (true) {
        const response = await callGraphQL("Failed to list boards", graphqlOperation(listBoards, params));
        const pageOfItems = response.data.listBoards.items;
        params.nextToken = response.data.listBoards.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      this.setState(
        {
          boards: items,
          context: { ...this.state.context, boards: items },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetProjects({ limit = DEFAULT_PAGE_LENGTH, filter = {}, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { organisation, limit };

      params.filter = { ...filter, ...window.getTeamFilter() };
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      let items = [];

      while (true) {
        const response = await callGraphQL("Failed to list the projects", graphqlOperation(listProjectsSimple, params));
        const pageOfItems = response.data.listProjectsByOrganisation.items;
        params.nextToken = response.data.listProjectsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.projects = items;
        resolve();
      } else {
        this.setState(
          {
            projects: items,
            context: { ...this.state.context, projects: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetOrganisation({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve organisation details",
        graphqlOperation(getOrganisation, {
          id,
        })
      );

      const organisation = response.data.getOrganisation;
      if (!organisation) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: { ...this.state.context, organisation },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetOrganisations(params) {
  const { checkRestoredCachedData } = params || {};
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQLSimple({
        message: "Failed to list organisations",
        queryCustom: "listOrganisations",
      });
      const newOrganisations = response.data.listOrganisations.items;

      if (checkRestoredCachedData && this.hasRestoredCachedData) {
        this.temporaryContext.organisations = newOrganisations;
        resolve(newOrganisations);
      } else {
        this.setState(
          {
            organisations: newOrganisations,
            context: {
              ...this.state.context,
              organisations: newOrganisations,
            },
          },
          () => resolve(newOrganisations)
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetClients({ organisation, limit = DEFAULT_PAGE_LENGTH }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { organisation, limit };
      let items = [];

      while (true) {
        const response = await callGraphQL("Failed to list the clients", graphqlOperation(listClients, params));
        const pageOfItems = response.data.listClientsByOrganisation.items;
        params.nextToken = response.data.listClientsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.clients = items;
        resolve();
      } else {
        this.setState(
          {
            clients: items,
            context: { ...this.state.context, clients: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetSuppliers({ organisation, limit = DEFAULT_PAGE_LENGTH }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { organisation, limit };
      let items = [];

      while (true) {
        const response = await callGraphQL(
          "Failed to list the suppliers",
          graphqlOperation(listSuppliersByOrganisation, params)
        );
        const pageOfItems = response.data.listSuppliersByOrganisation.items;
        params.nextToken = response.data.listSuppliersByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.suppliers = items;
        resolve();
      } else {
        this.setState(
          {
            suppliers: items,
            context: { ...this.state.context, suppliers: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetPurchaseOrders({ organisation, limit = DEFAULT_PAGE_LENGTH, filter = {} }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { organisation, limit };
      let items = [];
      params.filter = { ...filter, ...window.getTeamFilter() };
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      while (true) {
        const response = await callGraphQL(
          "Failed to list the purchase orders",
          graphqlOperation(listPurchaseOrdersByOrganisation, params)
        );
        const pageOfItems = response.data.listPurchaseOrdersByOrganisation.items;
        params.nextToken = response.data.listPurchaseOrdersByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.purchaseOrders = items;
        resolve();
      } else {
        this.setState(
          {
            purchaseOrders: items,
            context: { ...this.state.context, purchaseOrders: items },
          },
          resolve
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetSprints({ filter, limit, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { organisation };
      let items = [];

      while (true) {
        const response = await callGraphQL("Failed to list the sprints", graphqlOperation(listSprints, params));
        const pageOfItems = response.data.listSprintsByOrganisation.items;
        params.nextToken = response.data.listSprintsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.sprints = items;
        resolve();
      } else {
        this.setState(
          {
            sprints: items,
            context: { ...this.state.context, sprints: items },
          },
          resolve
        );
      }
    } catch (e) {
      console.log("Error fetching sprints:", e);
      reject(e);
    }
  });
}

export function fetchAndSetTasks({
  limit = DEFAULT_PAGE_LENGTH,
  organisation,
  filter = {},
  query = "listTasksSimple",
}) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = { limit, organisation };

      params.filter = {
        ...filter,
        ...window.getTeamFilter(),
        // isFinished: {
        //   ne: true,
        // },
      };
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      let tasks = [];
      while (true) {
        const response = await callGraphQLSimple({
          message: `Failed to list ${getSimpleLabel("tasks")}`,
          queryCustom: query,
          variables: params,
        });

        const pageOfTasks = response.data.listTasksByOrganisation.items;
        params.nextToken = response.data.listTasksByOrganisation.nextToken;
        tasks = [...tasks, ...pageOfTasks];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.tasks = tasks;
        resolve(tasks);
      } else {
        this.setState(
          {
            context: {
              ...this.state.context,
              tasks,
            },
          },
          () => resolve(tasks)
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetAsyncJobs({
  filter = undefined,
  organisation,
  fileVersionId,
  sortDirection = "DESC",
  nextToken,
  limit,
  addToList = false,
}) {
  return new Promise(async (resolve, reject) => {
    try {
      if (!limit) {
        this.setState(
          {
            context: {
              ...this.state.context,
              asyncJobs: [],
            },
          },
          () => resolve([])
        );
        return;
      }

      let params = {
        organisation,
        sortDirection,
        fileVersionId,
        nextToken,
        limit,
      };

      if (filter) {
        params.filter = filter;
      }

      let queryName = "listPublishJobsByOrganisation";
      if (fileVersionId) {
        queryName = "listPublishJobsByFile";
      }

      const response = await callGraphQLSimple({
        message: "Failed to list async jobs",
        queryCustom: queryName,
        variables: params,
      });
      const pageOfItems = response.data[queryName].items;

      let newAsyncJobs = pageOfItems;
      if (addToList) {
        newAsyncJobs = [...(this.state.context.asyncJobs || []), ...pageOfItems];
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            asyncJobs: newAsyncJobs,
            asyncJobsNextToken: response.data[queryName].nextToken,
          },
        },
        () => resolve(pageOfItems)
      );
    } catch (err) {
      console.log("error: ", err);
      reject(err);
    }
  });
}

export function fetchAndSetNotifications({ limit = DEFAULT_PAGE_LENGTH, filter = undefined, organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let items = [];
      if (window.apiUser) {
        let params = { limit, organisation, userId: window.apiUser?.id };
        if (filter) {
          params.filter = filter;
        }

        while (true) {
          const response = await callGraphQL(
            "Failed to list notifications",
            graphqlOperation(listNotificationsByUser, params)
          );
          const page = response.data.listNotificationsByUser.items;
          params.nextToken = response.data.listNotificationsByUser.nextToken;
          items = [...items, ...page];

          if (!params.nextToken) {
            break;
          }
        }
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            notifications: items,
          },
        },
        () => resolve(items)
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetQuotes({
  limit = 1000,
  filter = {},
  organisation,
  query = "listQuotesWithLineItemsByOrganisation",
}) {
  // console.log("fetchAndSetinvoices() limit = ", limit, "filter = ", filter);
  return new Promise(async (resolve, reject) => {
    try {
      if (!query) {
        let items = [];
        if (this?.setState) {
          this.setState(
            {
              context: {
                ...this.state.context,
                quotes: items,
              },
            },
            () => resolve(items)
          );
        } else {
          resolve(items);
        }
        return;
      }

      let params = { limit, organisation };
      params.filter = { ...filter, ...window.getTeamFilter() };
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      let items = [];
      while (true) {
        const response = await callGraphQLSimple({
          message: `Failed to list ${getSimpleLabel("quotes")}`,
          queryCustom: query,
          variables: params,
        });

        const page = response.data.listQuotesByOrganisation.items;
        params.nextToken = response.data.listQuotesByOrganisation.nextToken;
        items = [...items, ...page];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.quotes = items;
        resolve(items);
      } else {
        this.setState(
          {
            context: {
              ...this.state.context,
              quotes: items,
            },
          },
          () => resolve(items)
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

// TODO: add a query for listQuoteLineItemsByOrganisation
export function fetchAndSetQuoteLineItems({ limit = DEFAULT_PAGE_LENGTH, filter = undefined, organisation }) {
  // console.log("fetchAndSetQuoteLineItems() limit = ", limit, "filter = ", filter);
  return new Promise(async (resolve, reject) => {
    try {
      let params = {
        limit,
        // organisation,
        // filter: { organisation: { eq: organisation } },
      };
      if (filter) {
        params.filter = { ...(params.filter || {}), ...filter };
      }

      let items = [];
      while (true) {
        const response = await callGraphQL(
          "Failed to list quote line items",
          graphqlOperation(listQuoteLineItems, params)
        );
        const page = response.data.listQuoteLineItems.items;
        params.nextToken = response.data.listQuoteLineItems.nextToken;
        items = [...items, ...page];

        if (!params.nextToken) {
          break;
        }
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            quoteLineItems: items,
          },
        },
        () => resolve(items)
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetInvoices({ limit = DEFAULT_PAGE_LENGTH, filter = {}, organisation }) {
  // console.log("fetchAndSetInvoices() limit = ", limit, "filter = ", filter);
  return new Promise(async (resolve, reject) => {
    try {
      let params = { limit, organisation };
      params.filter = { ...filter, ...window.getTeamFilter() };
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      let items = [];
      while (true) {
        const response = await callGraphQLSimple({
          message: `Failed to list ${getSimpleLabel("quotes")}`,
          queryCustom: "listInvoicesByOrganisation",
          variables: params,
        });
        const page = response.data.listInvoicesByOrganisation.items;
        params.nextToken = response.data.listInvoicesByOrganisation.nextToken;
        items = [...items, ...page];

        if (!params.nextToken) {
          break;
        }
      }

      if (this.hasRestoredCachedData) {
        this.temporaryContext.invoices = items;
        resolve(items);
      } else {
        this.setState(
          {
            context: {
              ...this.state.context,
              invoices: items,
            },
          },
          () => resolve(items)
        );
      }
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetTimelineBlocks({ filter, startDate, endDate, organisation, limit = DEFAULT_PAGE_LENGTH }) {
  return new Promise(async (resolve, reject) => {
    if (!startDate || !endDate) {
      let timelineBlocks = [];
      this.setState(
        {
          context: {
            ...this.state.context,
            timelineBlocks,
          },
        },
        () => resolve(timelineBlocks)
      );
      return;
    }
    try {
      const items = await fetchCollection({
        query: "listTimelineBlocksByOrganisation",
        collectionLabel: "timeline blocks",
        variables: {
          organisation,
          limit,
          filter,
          startDate: {
            between: [startDate, endDate],
          },
        },
      });

      this.setState(
        {
          context: {
            ...this.state.context,
            timelineBlocks: items,
          },
        },
        () => resolve(items)
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetTimesheetTags({ organisation }) {
  return new Promise(async (resolve, reject) => {
    try {
      let params = {
        organisation,
      };

      let items = [];
      while (true) {
        const response = await callGraphQL(
          "Failed to list timesheet tags",
          graphqlOperation(listTimesheetTagsByOrganisation, params)
        );
        const pageOfItems = response.data.listTimesheetTagsByOrganisation.items;
        params.nextToken = response.data.listTimesheetTagsByOrganisation.nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            timesheetTags: items,
          },
        },
        () => resolve(items)
      );
    } catch (err) {
      reject(err);
    }
  });
}

export function fetchAndSetTimesheetBlocks({
  filter,
  startAt,
  endAt,
  organisation,
  userId,
  invoiceId,
  limit = DEFAULT_PAGE_LENGTH,
}) {
  return new Promise(async (resolve, reject) => {
    if (!startAt || !endAt) {
      let items = [];
      if (this?.setState) {
        this.setState(
          {
            context: {
              ...this.state.context,
              timesheetBlocks: items,
            },
          },
          () => resolve(items)
        );
      } else {
        resolve(items);
      }
      return;
    }
    try {
      let params = {
        organisation,
        limit,
        userId,
        invoiceId,
        startAt: {
          between: [startAt, endAt],
        },
        filter: {},
      };
      if (filter) {
        params.filter = { ...params.filter, ...filter };
      }

      if (invoiceId === "nothing") {
        params.invoiceId = {
          eq: "nothing",
        };
      }
      if (Object.keys(params.filter).length === 0) {
        delete params.filter;
      }

      let items = [];

      let queryName;
      if (userId) {
        queryName = "listTimesheetBlocksByUser";
      } else if (invoiceId && invoiceId !== "nothing") {
        queryName = "listTimesheetBlocksByInvoice";
      } else {
        queryName = "listTimesheetBlocksByOrganisation";
      }
      while (true) {
        const response = await callGraphQLSimple({
          message: "Failed to list the timesheet blocks",
          query: queryName,
          variables: params,
        });

        const pageOfItems = response.data[queryName].items;
        params.nextToken = response.data[queryName].nextToken;
        items = [...items, ...pageOfItems];

        if (!params.nextToken) {
          break;
        }
      }

      if (this?.setState) {
        this.setState(
          {
            context: {
              ...this.state.context,
              timesheetBlocks: items,
            },
          },
          () => resolve(items)
        );
      } else {
        resolve(items);
      }
    } catch (err) {
      reject(err);
    }
  });
}

export async function createUserInApiAndCognito({
  email,
  firstName,
  lastName,
  isHidden,
  position,
  qualifications,
  temporaryPassword,
  accountIsActive,
  catLevelDesign,
  catLevelCheck,
  catLevelIssue,
  order,
  organisation,
  workingHours,
  permissions,
}) {
  email = email.toLowerCase().trim();
  firstName = firstName.trim();
  lastName = lastName.trim();
  temporaryPassword = temporaryPassword.trim();

  try {
    await callGraphQLSimple({
      displayError: false,
      mutation: "createUser",
      variables: {
        input: {
          id: email.toLowerCase(),
          organisation,
          position,
          qualifications,
          cognitoUsername: "not-yet-set",
          firstName,
          lastName,
          activated: accountIsActive,
          catLevelDesign,
          catLevelCheck,
          catLevelIssue,
          isHidden,
          order,
          permissions,
          workingHours: workingHours || [
            {
              id: String(Date.now()) + String(Math.floor(Math.random() * 1000)),
              repeatPattern: "every weekday",
              startTime: "0",
              endTime: "0",
            },
          ],
        },
      },
    });
  } catch (e) {
    // check for DynamoDB:ConditionalCheckFailedException
    if (e.errors && e.errors[0] && e.errors[0].errorType === "DynamoDB:ConditionalCheckFailedException") {
      message.error("An account with this email address already exists");
      throw e;
    } else {
      message.error("Failed to create user");
      console.error(e);
      throw e;
    }
  }

  const adminQueryResponse = await callRest({
    method: "post",
    route: "/createUser",
    body: {
      email,
      password: temporaryPassword,
      organisation,
    },
  });

  await callGraphQLSimple({
    message: "Failed to create user",
    mutation: "updateUser",
    variables: {
      input: {
        id: email,
        cognitoUsername: adminQueryResponse.cognitoUsername,
      },
    },
  });
}

export async function createClientInApi({
  id,
  name,
  key,
  initials,
  organisation,
  isPriority,
  fees,
  addresses,
  contacts,
}) {
  const client = (
    await callGraphQL(
      `Failed to create ${getSimpleLabel("client")}`,
      graphqlOperation(createClient, {
        input: {
          id,
          name,
          key,
          initials,
          isPriority,
          organisation,
          fees,
          addresses,
          contacts,
        },
      })
    )
  ).data.createClient;

  return client;
}

export async function createProjectInApi(params) {
  const {
    id,
    title,
    clientId,
    clientDetails,
    initials,
    apiUser,
    extraOffset = 0,
    assignedTo,
    team,
    poNumber,
    address,
    addressCoordinates,
  } = params;
  const organisationDetails = (
    await callGraphQLSimple({
      queryCustom: "getOrganisation",
      variables: { id: apiUser.organisation },
    })
  ).data.getOrganisation;
  const projectId =
    id ||
    (await getProjectId({
      organisation: organisationDetails,
      extraOffset,
      clientDetails,
    }));
  if (!clientId) {
    throw new Error(`${getSimpleLabel("Client")} is required`);
  }

  let createProjectResponse;

  try {
    createProjectResponse = await callGraphQL(
      null,
      graphqlOperation(createProject, {
        input: {
          id: projectId,
          title,
          author: apiUser.id,
          organisation: apiUser.organisation,
          initials,
          clientId,
          assignedTo,
          team,
          poNumber,
          address,
          addressCoordinates,
        },
      }),
      false
    );
    await callGraphQL(
      "Failed to update organisation",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          projectCount: (organisationDetails.projectCount || 0) + extraOffset + 1,
        },
      })
    );
    await callGraphQL(
      "Failed to update client",
      graphqlOperation(updateClient, {
        input: {
          id: clientId,
          randomNumber: Math.floor(Math.random() * 1000000),
        },
      })
    );
    const project = createProjectResponse.data.createProject;

    await createTaskInApi({
      title: "_Admin_Hidden_",
      initials: "ADMIN",
      projectId: project.id,
      taskId: `${project.id}-ADMIN`,
      project,
      clientId: project.id,
      clientDetails,
      apiUser,
      status: getUppercaseStatus(organisationDetails.taskStatuses[0].name) || "",
      isHidden: true,
      team,
    });

    await copyAttachmentTemplate({
      organisationDetails,
      record: project,
      type: "project",
    });

    return createProjectResponse;
  } catch (e) {
    const errorType = e?.errors && e.errors[0]?.errorType;
    if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
      return await createProjectInApi({
        ...params,
        extraOffset: extraOffset + 1,
      });
    } else {
      console.log(e);
      displayErrorMessage(e, "Failed to create project");
    }
  }
}

async function createIndividualTaskInApi(params) {
  let {
    taskId,
    title,
    initials,
    assignedTo,
    assignedToUsers,
    assignedToStockItems,
    subtitle,
    project,
    projectId,
    clientId,
    dueDate,
    startDate,
    endDate,
    catLevel,
    apiUser,
    projects,
    extraOffset = 0,
    status,
    sprintId,
    order = "0|hzzzzz:",
    customState,
    isExternalReference,
    customFields,
    quoteIds,
    quoteLineItemIds,
    estimatedHours,
    budget,
    checkPrice,
    linkedTasks,
    isHidden,
    isConfirmed,
    clientDetails,
    team,
  } = params;
  const projectDetails = project || projects?.find((x) => x.id === project.id);
  taskId =
    taskId ||
    (await getTaskId({
      organisation: apiUser.organisation,
      projectDetails,
      clientDetails,
      extraOffset,
    }));
  try {
    let createTaskResponse = await callGraphQL(
      null,
      graphqlOperation(createTask, {
        input: {
          id: taskId,
          assignedTo,
          assignedToUsers,
          assignedToStockItems,
          organisation: apiUser.organisation,
          title,
          titleLowerCase: title.toLowerCase(),
          initials,
          subtitle,
          projectId: project?.id || projectId,
          clientId: project?.clientId || clientId,
          catLevel,
          dueDate: dueDate ? moment(dueDate).format("YYYY-MM-DD") : null,
          startDate: startDate ? moment(startDate).format("YYYY-MM-DD") : null,
          endDate: endDate ? moment(endDate).format("YYYY-MM-DD") : null,
          author: apiUser.id,
          status,
          sprintId,
          estimatedHours,
          customState,
          order,
          isExternalReference,
          customFields,
          quoteIds,
          quoteLineItemIds,
          budget,
          checkPrice,
          linkedTasks,
          isHidden,
          isConfirmed,
          team: team || project?.team,
          subtaskProgress: 0,
        },
      }),
      false
    );
    return [createTaskResponse, extraOffset];
  } catch (e) {
    // console.log("error here");
    const errorType = e?.errors && e.errors[0]?.errorType;

    if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
      return await createIndividualTaskInApi({
        ...params,
        extraOffset: extraOffset + 1,
      });
    } else {
      console.log("error creating task:", e);
      displayErrorMessage(e, "Failed to create task");
      throw e;
    }
  }
}

export async function linkTasksTogether({ mainTask, linkedTask, relationship }) {
  const relationshipDetails = TASK_RELATIONSHIPS.find((x) => x.value === relationship);

  const linkedTaskData = (
    await callGraphQL(
      `Failed to retrieve ${getLabel({
        id: "task",
        defaultValue: "task",
      })} details`,
      graphqlOperation(getTaskSimple, {
        id: linkedTask,
      })
    )
  ).data.getTask;

  if (!linkedTaskData) {
    message.error("Cannot find details of linked task, aborting");
    return;
  }

  const outgoingRelationshipId = Math.floor(Math.random() * 100000);
  const incomingRelationshipId = Math.floor(Math.random() * 100000);

  const outgoingRelationshipDetails = {
    id: outgoingRelationshipId,
    correspondingId: incomingRelationshipId,
    taskId: linkedTask,
    relationship,
    label: linkedTaskData.title,
  };

  const incomingRelationshipDetails = {
    id: incomingRelationshipId,
    correspondingId: outgoingRelationshipId,
    taskId: mainTask.id,
    relationship: relationshipDetails.corresponding,
    label: mainTask.title,
  };

  await callGraphQL(
    "Failed to record linked task",
    graphqlOperation(updateTask, {
      input: {
        id: mainTask.id,
        linkedTasks: [...(mainTask.linkedTasks || []), outgoingRelationshipDetails],
      },
    })
  );

  await callGraphQL(
    "Failed to record linked task",
    graphqlOperation(updateTask, {
      input: {
        id: linkedTaskData.id,
        linkedTasks: [...(linkedTaskData.linkedTasks || []), incomingRelationshipDetails],
      },
    })
  );
}

export async function createFileVersionInApi({ file, task, taskRevision, apiUser, versionNumber, ...extraFields }) {
  let oldFileVersion;
  let oldFileVersionExtension;
  if (file.versions?.items?.length > 0) {
    oldFileVersion = file.versions.items.slice(-1)[0];
    oldFileVersionExtension = oldFileVersion.key.split(".").slice(-1)[0];
  }

  const paramsForEncodeKey = {
    task,
    file,
    organisation: apiUser.organisation,
    projectId: task.projectId,
    taskId: task.id,
    clientInitials: task.client?.initials || "",
    projectInitials: task.project?.initials || "",
    taskInitials: task.initials,
    versionNumber,
    fileId: file.id,
    taskRevisionName: (taskRevision || getLatestRevision(task)).name,
    fileType: file.type,
  };

  const extension = "pdf";

  let newKey = await encodeKey({
    type: KEY_TYPES.FILE_MAIN,
    data: {
      ...paramsForEncodeKey,
      extension: file.extension,
    },
  });

  const newKeyExtension = newKey.split(".").slice(-1)[0];
  if (oldFileVersionExtension) {
    if (!newKeyExtension !== oldFileVersionExtension) {
      newKey = newKey.split(`.${newKeyExtension}`).join(`.${oldFileVersionExtension}`);
    }
  }

  const fileVersionExports = [
    {
      extension,
      key: await encodeKey({
        type: KEY_TYPES.FILE_MAIN_EXPORT,
        data: {
          ...paramsForEncodeKey,
          extension,
        },
      }),
      rawKey: await encodeKey({
        type: KEY_TYPES.FILE_MAIN_EXPORT_RAW,
        data: {
          ...paramsForEncodeKey,
          extension,
        },
      }),
    },
  ];

  const latestFileVersion = getLatestFileVersion(file);
  let customId = null;
  if (latestFileVersion) {
    customId = latestFileVersion.customId;
  }

  return await callGraphQL(
    "Failed to create file version",
    graphqlOperation(createFileVersion, {
      input: {
        versionNumber,
        fileId: file.id,
        organisation: task.organisation,
        exports: fileVersionExports,
        customId,
        key: newKey,
        ...extraFields,
      },
    })
  );
}

export async function pointSheetsToLatestFileVersion({ apiUser, task, file, taskRevision }) {
  file = {
    ...file,
    sheets: {
      ...file.sheets,
      items: file.sheets.items.map((sheet) => {
        return {
          ...sheet,
          revisions: {
            ...sheet.revisions,
            items: sheet.revisions.items.filter((sheetRevision) => !sheetRevision.isArchived),
          },
        };
      }),
    },
  };

  const latestFileVersion = getLatestFileVersion(file);
  const paramsForEncodeKey = {
    task,
    file,
    organisation: apiUser.organisation,
    projectId: task.projectId,
    taskId: task.id,
    clientInitials: task.client?.initials || "",
    projectInitials: task.project?.initials || "",
    taskInitials: task.initials,
    taskRevisionName: taskRevision.name,
    fileId: file.id,
    fileType: file.type,
    versionNumber: latestFileVersion.versionNumber,
  };

  for (let i = 0; i < file.sheets.items.length; i++) {
    const sheet = file.sheets.items[i];
    const latestSheetRevision = getLatestRevision(sheet);
    const extension = "pdf";
    const encodeKeyData = {
      ...paramsForEncodeKey,
      extension,
      sheetName: sheet.name,
    };
    await callGraphQL(
      "Failed to update sheet revision",
      graphqlOperation(updateSheetRevision, {
        input: {
          id: latestSheetRevision.id,
          fileVersionId: latestFileVersion.id,
          exports: [
            {
              extension,
              key: await encodeKey({
                type: KEY_TYPES.FILE_SHEET_EXPORT,
                data: encodeKeyData,
              }),
              rawKey: await encodeKey({
                type: KEY_TYPES.FILE_SHEET_EXPORT_RAW,
                data: encodeKeyData,
              }),
            },
          ],
        },
      })
    );
  }
}

export async function createSheetRevisionInApi({
  apiUser,
  task,
  sheet,
  file,
  name,
  description,
  fileVersion,
  taskRevision,
  status,
}) {
  const paramsForEncodeKey = {
    task,
    file,
    organisation: apiUser.organisation,
    projectId: task.projectId,
    taskId: task.id,
    clientInitials: task.client?.initials || "",
    projectInitials: task.project?.initials || "",
    taskInitials: task.initials,
    versionNumber: fileVersion.versionNumber,
    fileId: file.id,
    taskRevisionName: taskRevision.name,
    fileType: file.type,
  };

  const extension = "pdf";
  const encodeKeyData = {
    ...paramsForEncodeKey,
    extension,
    sheetName: sheet.name,
  };

  return callGraphQL(
    "Failed to create sheet revision",
    graphqlOperation(createSheetRevision, {
      input: {
        sheetId: sheet.id,
        realCreatedAt: new Date().toISOString(),
        name,
        description,
        status,
        author: taskRevision.author,
        fileVersionId: fileVersion.id,
        exports: [
          {
            extension,
            key: await encodeKey({
              type: KEY_TYPES.FILE_SHEET_EXPORT,
              data: encodeKeyData,
            }),
            rawKey: await encodeKey({
              type: KEY_TYPES.FILE_SHEET_EXPORT_RAW,
              data: encodeKeyData,
            }),
          },
        ],
      },
    })
  );
}

export async function createFileWithSheets(params) {
  let {
    fileType = undefined,
    extension = undefined,
    apiUser,
    sheetCount,
    name,
    templateId = "default",
    templateVersionNumber = 0,
    taskRevision,
    task,
    taskInitials,
    status = undefined,
    doPublish = true,
    isExternalReference = false,
    file = undefined,
    sheetNames = undefined,
    sheetStatuses = undefined,
    includeCreateFileStep = true,
    constantId,
    sourceFile = undefined,
    sourceTask = undefined,
    isHidden = false,
    excludeFromRegister = false,
    sheetRevisionName = undefined,
    sheetRevisionDescription = undefined,
  } = params;

  if (!taskRevision) {
    taskRevision = (
      await callGraphQL(
        "Failed to retrieve file to copy from",
        graphqlOperation(getTaskRevision, {
          id: task.revisions.items.slice(-1)[0].id,
        })
      )
    ).data.getTaskRevision;
  }

  if (!name && !sourceFile) {
    const filesOfType = taskRevision.files.items.filter((x) => x.type === fileType);
    name = String(1000 + filesOfType.length + 1).substring(1);
  }

  if (
    !status &&
    !sheetStatuses &&
    (!window.organisationDetails?.fileStatuses || !window.organisationDetails?.fileStatuses?.length === 0)
  ) {
    message.error(`No file statuses have been defined, cannot create file ${fileType}`);
    return;
  }

  if (sourceFile) {
    sourceFile = (
      await callGraphQL(
        "Failed to retrieve file to copy from",
        graphqlOperation(getFile, {
          id: sourceFile.id,
        })
      )
    ).data.getFile;
    fileType = sourceFile.type;
    extension = sourceFile.extension;
    sheetCount = sourceFile.sheets.items.length;
    sheetNames = sourceFile.sheets.items.map((x) => x.name);
    templateId = sourceFile.templateId;
    templateVersionNumber = sourceFile.templateVersionNumber;
    name = name || sourceFile.name;
    isHidden = sourceFile.isHidden;
  }

  if (!constantId) {
    constantId = `${Date.now()}${Math.floor(Math.random() * 100000)}`;
  }

  if (includeCreateFileStep) {
    file = (
      await callGraphQL(
        "Failed to create file",
        graphqlOperation(createFile, {
          input: {
            name,
            taskRevisionId: taskRevision.id,
            type: fileType,
            organisation: task.organisation,
            extension,
            templateId,
            templateVersionNumber,
            constantId,
            isHidden,
          },
        })
      )
    ).data.createFile;
  }

  const paramsForEncodeKey = {
    task,
    file,
    fileType,
    organisation: task.organisation,
    projectId: task.projectId,
    taskId: task.id,
    clientInitials: task.client?.initials || "",
    projectInitials: task.project?.initials || "",
    taskInitials: taskInitials || task.initials,
    versionNumber: 0,
    fileId: file.id,
    taskRevisionName: taskRevision.name,
  };

  if (includeCreateFileStep && !isExternalReference) {
    try {
      const destinationKey = await encodeKey({
        type: KEY_TYPES.FILE_MAIN,
        data: {
          ...paramsForEncodeKey,
          extension,
          sheetCount,
          templateId,
          templateVersionNumber,
        },
      });

      await callRest({
        route: "/copyTemplate",
        method: "post",
        body: {
          fileType,
          templateId,
          templateVersionNumber,
          extension,
          organisation: task.organisation,
          destinationKey,
        },
      });
    } catch (e) {
      console.log("copy template error:", e);
      callGraphQLSimple({
        message: "Failed to delete newly-created file",
        mutation: "deleteFile",
        variables: {
          input: {
            id: file.id,
          },
        },
      });
      notification.error({
        message: `Failed to create file, operation cancelled. Reason: ${e.message}`,
        duration: 0,
      });
      return;
    }
  }

  const fileVersion = (
    await createFileVersionInApi({
      file,
      task,
      taskRevision,
      apiUser,
      versionNumber: 0,
    })
  ).data.createFileVersion;

  let currentOrder = LexoRank.middle();
  let sheetOrders = [];
  for (let i = 0; i < sheetCount; i++) {
    sheetOrders.push(currentOrder.value);
    currentOrder = currentOrder.genNext();
  }

  let sheetDetailsPromises = [];
  let createSheetPromises = [];

  for (let i = 0; i < sheetCount; i++) {
    sheetDetailsPromises.push(
      new Promise(async (resolve) => {
        let sheetName = sheetNames
          ? sheetNames[i]
          : generateSheetName({
              organisation: task.organisation,
              task,
              file,
              sheetCount: (file.sheets.items.length || 0) + i,
            });
        let autoGeneratedReferenceNumber = await buildFileName(
          { ...paramsForEncodeKey, sheetName },
          KEY_TYPES.SHEET_REFERENCE
        );
        let sheetDescription = await getSheetDescription({
          organisation: task.organisation,
          task,
          taskRevision,
          file,
          sheetCount: (file.sheets.items.length || 0) + i,
        });

        let sheetStatus = status;
        if (sheetStatuses) {
          sheetStatus = sheetStatuses[i];
        }

        if (!sheetStatus) {
          sheetStatus = window.organisationDetails?.fileStatuses[0].name;
        }

        resolve({
          sheetName,
          autoGeneratedReferenceNumber,
          sheetDescription,
          sheetStatus,
        });
      })
    );
  }
  const detailsForAllSheets = await Promise.all(sheetDetailsPromises);

  let areSheetNamesUnique = true;
  let areSheetDescriptionsUnique = true;
  let sheetNamesSet = new Set();
  let sheetDescriptionsSet = new Set();
  detailsForAllSheets.forEach((sheetDetails) => {
    if (sheetNamesSet.has(sheetDetails.sheetName)) {
      areSheetNamesUnique = false;
    }
    sheetNamesSet.add(sheetDetails.sheetName);

    if (sheetDetails.sheetDescription && sheetDescriptionsSet.has(sheetDetails.sheetDescription)) {
      areSheetDescriptionsUnique = false;
    }
    sheetDescriptionsSet.add(sheetDetails.sheetDescription);
  });

  let isAborting = false;
  if (!areSheetNamesUnique) {
    message.error("Sheet names are not unique, aborting");
    isAborting = true;
  }

  if (window.organisationDetails?.settings?.file?.sheetDescriptionsMustBeUnique) {
    if (!areSheetDescriptionsUnique) {
      message.error("Sheet titles are not unique, aborting");
      isAborting = true;
    }
  }

  if (isAborting) {
    // delete what has been created so far
    if (file) {
      await callGraphQLSimple({
        message: "Failed to delete newly-created file",
        queryCustom: "deleteFile",
        variables: {
          input: {
            id: file.id,
          },
        },
      });
    }

    if (fileVersion) {
      await callGraphQLSimple({
        message: "Failed to delete newly-created file version",
        queryCustom: "deleteFileVersion",
        variables: {
          input: {
            id: fileVersion.id,
          },
        },
      });
    }

    return;
  }

  for (let i = 0; i < sheetCount; i++) {
    createSheetPromises.push(
      new Promise(async (resolve) => {
        let { sheetName, autoGeneratedReferenceNumber, sheetDescription, sheetStatus } = detailsForAllSheets[i];
        let sourceSheet = sourceFile?.sheets?.items[i];

        const sheet = (
          await callGraphQL(
            "Failed to create sheet",
            graphqlOperation(createSheet, {
              input: {
                taskId: task.id,
                fileId: file.id,
                fileType,
                name: sheetName,
                description: sheetDescription,
                autoGeneratedReferenceNumber,
                order: sheetOrders[i],
                includeInPublish: sourceSheet ? sourceSheet.includeInPublish : true,
                excludeFromRegister: sourceSheet ? sourceSheet.excludeFromRegister : excludeFromRegister,
                constantId: `${Date.now()}${Math.floor(Math.random() * 100000)}`,
              },
            })
          )
        ).data.createSheet;

        let currentSheetRevisionName =
          sheetRevisionName ||
          (await getSheetRevisionName({
            organisation: task.organisation,
            newStatus: sheetStatus,
            sheet,
            task,
          }));

        await createSheetRevisionInApi({
          apiUser,
          task,
          sheet,
          file,
          fileVersion,
          taskRevision,
          status: sheetStatus,
          name: currentSheetRevisionName,
          description: sheetRevisionDescription || "Initial issue",
        });

        resolve(sheetName);
      })
    );
  }
  await Promise.all(createSheetPromises);

  const updatedFile = (
    await callGraphQL(
      "Failed to retrieve file",
      graphqlOperation(getFile, {
        id: file.id,
      })
    )
  ).data.getFile;

  if (sourceFile) {
    // copy files from S3
    await copyS3MainFile({
      task,
      apiUser,
      taskRevision,
      file,
      newVersionNumber: 0,
      newFileVersion: fileVersion,
      oldFileVersion: sourceFile.versions.items.slice(-1)[0],
      extension: sourceFile.extension,
      openConfirmationModal: false,
    });

    // if it's a report, we need to update the path to all attachments
    // so that they point to the folder that contains the new task's attachments
    if (updatedFile.type === "REPORT" && sourceTask) {
      await updatePathsInReportAndCopyAttachments({
        task,
        sourceTask,
        file: updatedFile,
        taskRevision,
        apiUser,
        fileVersion,
      });
    }
  }

  if (doPublish && FILES_TO_PUBLISH_ON_CREATE.includes(updatedFile.type)) {
    publish({
      projectId: task.projectId,
      file: updatedFile,
      fileVersionId: updatedFile.versions.items[updatedFile.versions.items.length - 1].id,
      taskRevisionId: taskRevision.id,
      taskRevisionName: taskRevision.name,
      task,
    });
  }
  return updatedFile;
}

async function updatePathsInReportAndCopyAttachments({ task, sourceTask, file, taskRevision, apiUser, fileVersion }) {
  const newFileKey = file.versions.items.slice(-1)[0].key.replace("public/", "");

  const filePublicURL = await getS3File(newFileKey);
  const form = (await axios.get(filePublicURL)).data;

  let updatedForm = await extractAttachmentPathsFromReportForm({
    form,
    sourceTaskId: sourceTask.id,
    sourceProjectId: sourceTask.projectId,
    newTaskId: task.id,
    newProjectId: task.projectId,
  });

  await Storage.put(newFileKey, JSON.stringify(updatedForm));
}

async function extractAttachmentPathsFromReportForm({ form, sourceTaskId, sourceProjectId, newTaskId, newProjectId }) {
  try {
    let copyFilePromises = [];
    let updatedForm = JSON.parse(JSON.stringify(form));
    for (let fieldName in updatedForm.fields) {
      let fieldDetails = updatedForm.fields[fieldName];
      if (fieldDetails.type === "textarea") {
        let parsedValue;
        try {
          parsedValue = JSON.parse(fieldDetails.value);
        } catch (error) {
          continue;
        }

        if (parsedValue) {
          for (let element of parsedValue) {
            if (element.type === "attachment") {
              let attachmentDetails = element.attachment;

              let oldKey = attachmentDetails.key;
              let oldLocalKey = attachmentDetails.localKey;

              attachmentDetails.localKey = attachmentDetails.localKey
                .split(sourceTaskId)
                .join(newTaskId)
                .split(sourceProjectId)
                .join(newProjectId);

              attachmentDetails.key = attachmentDetails.key
                .split(sourceTaskId)
                .join(newTaskId)
                .split(sourceProjectId)
                .join(newProjectId);

              copyFilePromises.push(
                callRest({
                  method: "post",
                  route: "/copyS3File",
                  body: {
                    oldKey,
                    newKey: attachmentDetails.key,
                  },
                })
              );
            }
          }
          fieldDetails.value = JSON.stringify(parsedValue);
        }
      }
    }

    await Promise.all(copyFilePromises);

    return updatedForm;
  } catch (error) {
    console.error("Error parsing JSON or extracting paths:", error);
    return null;
  }
}

export async function publish({ file, fileVersionId, task, taskRevisionId }) {
  // for now, we don't want to create async jobs

  await callGraphQL(
    "Failed to create async job",
    graphqlOperation(createAsyncJob, {
      input: {
        fileType: file.type,
        taskId: task.id,
        fileId: file.id,
        type: "PUBLISH",
        fileVersionId,
        organisation: task.organisation,
        userId: window.apiUser.id,
        status: "PENDING",
        restUrl: getRestEndpoint(),
        bucket: awsExports.aws_user_files_s3_bucket,
        region: awsExports.aws_user_files_s3_bucket_region,
        graphQLUrl: awsExports.aws_appsync_graphqlEndpoint,
      },
    })
  );

  // await callRest({
  //   route: "/sendToConsumer",
  //   method: "post",
  //   body: {
  //     operation: "PUBLISH",
  //     key: fileVersion.key,
  //     publishedKey: fileVersion.exports[0].rawKey,
  //     annotatedKey: fileVersion.exports[0].key,
  //     organisation: task.organisation,
  //     clientLogo: task.client?.key || "",
  //     taskTitle: task.title,
  //     taskId: task.id,
  //     projectTitle: task.project.title,
  //     taskRevisionId,
  //     fileId: upToDateFile.id,
  //     executable: upToDateFile.type,
  //     fileVersionId,
  //     versionNumber: fileVersion.versionNumber,
  //     sheets: upToDateFile.sheets.items.filter((sheet) => sheet.includeInPublish).map((x) => x.name),
  //     customId: fileVersion.customId,
  //     externalReferences: fileVersion.externalReferences,
  //   },
  // });
}

async function computeTaskCustomFields(task) {
  let newTaskCustomFields = task.customFields || [];
  let weHaveFormulaFields = false;
  (window.organisationDetails.customFields || []).forEach((fieldDefinition) => {
    if (!fieldDefinition.formula) {
      return;
    }

    weHaveFormulaFields = true;
    let formulaValue = null;
    if (fieldDefinition.formula) {
      eval(`formulaValue = ${fieldDefinition.formula}`); // eslint-disable-line
    }
    newTaskCustomFields.push({
      id: fieldDefinition.id,
      value: formulaValue,
    });
  });

  if (weHaveFormulaFields) {
    await callGraphQL(
      "Could not set custom fields",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          customFields: newTaskCustomFields,
        },
      })
    );
  }
}

export async function createTaskInApi(params) {
  const startTime = Date.now();
  if (params.status) {
    params.status = getUppercaseStatus(params.status);
  }
  let {
    initials,
    projectId,
    apiUser,
    status,
    copyFromTaskId,
    dueDate,
    estimatedHours,
    isTemplate = false,
    isHidden,
    taskId,
  } = params;

  let [createTaskResponse, extraOffset] = await createIndividualTaskInApi(params);
  const newTask = createTaskResponse.data.createTask;

  let copyFromTaskDetails;
  if (copyFromTaskId) {
    copyFromTaskDetails = (
      await callGraphQL(
        `Failed to retrieve ${getSimpleLabel("task")} details to copy from`,
        graphqlOperation(getTaskSimple, {
          id: copyFromTaskId,
        })
      )
    ).data.getTask;
  }

  await window.callGraphQLSimple({
    mutation: "createTaskActivityItem",
    message: `Failed to record ${getSimpleLabel("task")} activity item`,
    variables: {
      input: {
        taskId: newTask.id,
        author: apiUser.id,
        organisation: newTask.organisation,
        type: "CREATED",
      },
    },
  });

  try {
    let newReview;
    if (!isTemplate) {
      await computeTaskCustomFields(newTask);

      if (!window.organisationDetails?.settings?.task?.dontCreateTaskFolders && !isHidden) {
        await createAttachmentFolderForTask({ organisationId: newTask.organisation, taskId: newTask.id, projectId });
      }

      if (!taskId) {
        await callGraphQL(
          "Failed to update project",
          graphqlOperation(updateProject, {
            input: {
              id: projectId,
              taskCount: (newTask.project.taskCount || 0) + extraOffset + 1,
            },
          })
        );
      }
      let createInitialReview = true;
      if (
        isHidden ||
        (window.organisationDetails?.settings?.task?.copyTaskAlsoCopiesAllTaskRevisions && copyFromTaskDetails)
      ) {
        createInitialReview = false;
      }
      if (createInitialReview) {
        newReview = (
          await callGraphQL(
            "Failed to create review",
            graphqlOperation(createReview, {
              input: {
                organisation: apiUser.organisation,
                reviewThread: [],
                approvedItems: [],
              },
            })
          )
        ).data.createReview;
      }
    }

    if (copyFromTaskDetails) {
      const currentSubtasks = copyFromTaskDetails?.subtasks?.items;

      if (currentSubtasks?.length > 0) {
        for (let i = 0; i < currentSubtasks?.length; i++) {
          const currentSubtask = copyFromTaskDetails?.subtasks?.items[i];

          let newSubtask = {
            ...currentSubtask,
            assignedTo: undefined,
            author: apiUser,
            isFinished: false,
            id: `${Date.now()}${Math.floor(Math.random() * 10000)}`,
            parentId: newTask.id,
          };

          await callGraphQL(
            "Could not create subtask",
            graphqlOperation(createSubtask, {
              input: newSubtask,
            })
          );
        }
      }
    }

    if (window.organisationDetails?.settings?.task?.copyTaskAlsoCopiesAllTaskRevisions && copyFromTaskDetails) {
      await copyAllTaskRevisionsFromTask({
        sourceTask: copyFromTaskDetails,
        newTask,
        apiUser,
      });
    } else {
      let newTaskRevisionStatus =
        window.organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions &&
        window.organisationDetails?.fileStatuses
          ? window.organisationDetails?.fileStatuses[0].name.toUpperCase().split(" ").join("_")
          : undefined;

      const taskRevision = (
        await callGraphQL(
          `Failed to create ${getLabel({
            id: "task revision",
            defaultValue: "task revision",
          })} revision`,
          graphqlOperation(createTaskRevision, {
            input: {
              taskId: newTask.id,
              name: await getTaskRevisionName({
                organisation: newTask.organisation,
                task: newTask,
                newStatus: window.organisationDetails?.fileStatuses
                  ? window.organisationDetails?.fileStatuses[0].name
                  : undefined,
              }),
              description: params.taskRevisionDescription || "Initial Issue",
              organisation: apiUser.organisation,
              author: params.assignedTo,
              checkedBy: "",
              reviewId: isTemplate || !newReview ? "nothing" : newReview.id,
              dueDate:
                window.organisationDetails.settings?.task?.useDueDatesOnTaskRevisions && dueDate
                  ? moment(dueDate).format("YYYY-MM-DD")
                  : null,
              estimatedHours: window.organisationDetails.settings?.task?.useTaskRevisionEstimates
                ? estimatedHours
                : null,
              priorityId: params.priorityId,
              requestedDate:
                window.organisationDetails.settings?.task?.usesRequestedDate && params.requestedDate
                  ? moment(params.requestedDate).format("YYYY-MM-DD")
                  : null,
              status: newTaskRevisionStatus,
            },
          })
        )
      ).data.createTaskRevision;

      let createFilePromises = [];

      if (copyFromTaskDetails) {
        const latestTaskRevisionToCopyFrom = copyFromTaskDetails.revisions.items.slice(-1)[0];
        for (let i = 0; i < latestTaskRevisionToCopyFrom.files.items.length; i++) {
          const sourceFile = latestTaskRevisionToCopyFrom.files.items[i];
          if (sourceFile.isArchived) {
            continue;
          }
          try {
            createFilePromises.push(
              createFileWithSheets({
                taskRevision,
                apiUser,
                task: newTask,
                taskInitials: initials,
                status: newTaskRevisionStatus,
                sourceFile,
                sourceTask: copyFromTaskDetails,
              })
            );
          } catch (e) {
            console.error("error creating file:", e);
            throw e;
          }
        }
      }

      await Promise.all(createFilePromises);
    }

    if (!isHidden) {
      await copyAttachmentTemplate({
        organisationDetails: window.organisationDetails,
        record: newTask,
        type: "task",
      });
    }

    await changeTaskStatus({
      taskId: newTask.id,
      status,
      organisationDetails: window.organisationDetails,
      recordActivity: false,
    });

    const endTime = Date.now();
    console.log(`Duration: ${(endTime - startTime) / 1000} seconds`);
    return newTask;
  } catch (e) {
    await deleteTaskInApi(newTask);
    notification.error({
      message: `Failed to create ${getSimpleLabel("task")}. Reason: ${e.message}`,
      duration: 0,
    });
    console.error("error from create task:", e);
    return null;
  }
}

async function copyAllTaskRevisionsFromTask({ sourceTask, newTask, apiUser }) {
  let createTaskRevisionPromises = [];
  for (let i = 0; i < sourceTask.revisions.items.length; i++) {
    createTaskRevisionPromises.push(
      new Promise(async (resolve, reject) => {
        try {
          const sourceTaskRevision = sourceTask.revisions.items[i];

          let newReview = (
            await callGraphQL(
              "Failed to create review",
              graphqlOperation(createReview, {
                input: {
                  organisation: apiUser.organisation,
                  reviewThread: [],
                  approvedItems: [],
                },
              })
            )
          ).data.createReview;

          const taskRevision = (
            await callGraphQL(
              `Failed to create ${getSimpleLabel("task revision")}`,
              graphqlOperation(createTaskRevision, {
                input: {
                  taskId: newTask.id,
                  name: sourceTaskRevision.name,
                  description: sourceTaskRevision.description,
                  organisation: apiUser.organisation,
                  reviewId: newReview.id,
                  estimatedHours: sourceTaskRevision.estimatedHours,
                  priorityId: sourceTaskRevision.priorityId,
                  status: sourceTaskRevision.status,
                },
              })
            )
          ).data.createTaskRevision;

          let createFilePromises = [];

          for (let i = 0; i < sourceTaskRevision.files.items.length; i++) {
            const sourceFile = sourceTaskRevision.files.items[i];
            if (sourceFile.isArchived) {
              continue;
            }
            try {
              createFilePromises.push(
                createFileWithSheets({
                  taskRevision,
                  apiUser,
                  task: newTask,
                  taskInitials: newTask.initials,
                  status: sourceTaskRevision.status,
                  sourceFile,
                  sourceTask,
                })
              );
            } catch (e) {
              console.error("error creating file:", e);
              throw e;
            }
          }

          await Promise.all(createFilePromises);
          resolve();
        } catch (e) {
          reject(e);
        }
      })
    );
    await new Promise((resolve) => setTimeout(resolve, 200));
  }
}

async function createAttachmentFolderForTask({ organisationId, projectId, taskId }) {
  let folderKey = (
    await encodeKey({
      type: KEY_TYPES.PROJECT_ATTACHMENT,
      data: {
        organisation: organisationId,
        projectId,
        attachmentFileName: `${taskId}`,
      },
    })
  )
    .split("//")
    .join("/");

  const createFolderParams = {
    Bucket: awsExports.aws_user_files_s3_bucket,
    Key: `${folderKey}/`,
    Body: "",
  };

  await window.s3.putObject(createFolderParams).promise();
}

async function copyAttachmentTemplate({ organisationDetails, record, type }) {
  let attachmentTemplates = organisationDetails.settings?.[type]?.attachmentTemplates;
  let destinationPrefix;

  let keyType;

  if (record) {
    const encodeKeyParams = {
      data: {
        organisation: record.organisation,
        attachmentFileName: "",
      },
    };

    if (type === "task") {
      keyType = KEY_TYPES.TASK_ATTACHMENT;
      encodeKeyParams.data.taskId = record.id;
      encodeKeyParams.data.projectId = record.projectId;
    }

    if (type === "project") {
      keyType = KEY_TYPES.PROJECT_ATTACHMENT;
      encodeKeyParams.data.projectId = record.id;
    }

    encodeKeyParams.type = keyType;

    destinationPrefix = await encodeKey(encodeKeyParams);
  }

  if (!attachmentTemplates || attachmentTemplates.length === 0) {
    return;
  }

  callRest({
    route: "/copy-prefix",
    method: "post",
    body: {
      sourcePrefix: `public/${record.organisation}/${attachmentTemplates[0].path}`,
      destinationPrefix,
    },
  });
}

export async function bringTaskIntoSprint({ taskId, sprintId, sprints, tasks }) {
  if (!sprintId && sprints) {
    let activeSprint = sprints.find((x) => x.isActive) || sprints[0];
    sprintId = activeSprint.id;
  }

  let tasksInTargetSprint = tasks.filter((x) => x.sprintId === sprintId);
  let lastTaskOrder = tasksInTargetSprint[tasksInTargetSprint.length - 1]?.order || LexoRank.middle().value;

  await callGraphQL(
    `Failed to move ${getLabel({
      id: "task",
      defaultValue: "task",
    })} to sprint`,
    graphqlOperation(updateTask, {
      input: {
        id: taskId,
        sprintId,
        order: LexoRank.parse(lastTaskOrder).genNext().value,
      },
    })
  );
}

export async function deleteTaskInApi(task) {
  if (task.requestIds !== null && task.requestIds.length >= 1) {
    Modal.error({
      title: `This ${getSimpleLabel("task")} is linked to at least one ${getSimpleLabel(
        "request"
      )}, therefore it cannot be deleted`,
      maskClosable: true,
    });
    throw new Error(`${getSimpleLabel("Task")} couldn't be deleted`);
  }

  if (task.linkedTasks) {
    for (let i = 0; i < task.linkedTasks.length; i++) {
      const relationship = task.linkedTasks[i];

      const linkedTaskDetails = (
        await callGraphQL(
          `Failed to retrieve linked ${getLabel({
            id: "task",
            defaultValue: "task",
          })} details`,
          graphqlOperation(getTaskSimple, {
            id: relationship.taskId,
          })
        )
      ).data.getTask;

      await callGraphQL(
        "Failed to remove corresponding link",
        graphqlOperation(updateTask, {
          input: {
            id: relationship.taskId,
            linkedTasks: linkedTaskDetails.linkedTasks.filter((x) => x.id !== relationship.correspondingId),
          },
        })
      );
    }
  }

  if (task.quoteLineItems?.items?.length > 0) {
    for (let i = 0; i < task.quoteLineItems.items.length; i++) {
      const quoteLineItem = task.quoteLineItems.items[i];
      await callGraphQL(
        `Failed to update ${getSimpleLabel("quote")} line item`,
        graphqlOperation(updateQuoteLineItem, {
          input: {
            id: quoteLineItem.id,
            resultingTaskId: "nothing",
          },
        })
      );
      await callGraphQL(
        `Failed to refresh ${getSimpleLabel("quote")} details`,
        graphqlOperation(updateQuote, {
          input: {
            id: quoteLineItem.quoteId,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        })
      );
    }
  }

  await callGraphQL(
    `Failed to delete ${getSimpleLabel("task")}`,
    graphqlOperation(deleteTask, {
      input: {
        id: task.id,
      },
    })
  );

  await callGraphQLSimple({
    mutation: "createTaskActivityItem",
    message: `Failed to record activity item`,
    variables: {
      input: {
        taskId: task.id,
        author: window.apiUser.id,
        organisation: task.organisation,
        type: "DELETED",
      },
    },
  });
}

export async function deleteOrganisationAndContents(id) {
  await callGraphQL(
    "Failed to delete organisation",
    graphqlOperation(deleteOrganisation, {
      input: {
        id,
      },
    })
  );
}

export async function fetchAndSetTask({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        `Failed to retrieve ${getLabel({
          id: "task",
          defaultValue: "task",
        })} details`,
        graphqlOperation(getTaskSimple, {
          id,
        })
      );

      const task = response.data.getTask;
      if (!task) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            task,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetRequest({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQLSimple({
        message: `Failed to retrieve ${getSimpleLabel("request")}`,
        query: "getRequest",
        variables: {
          id,
        },
      });

      const item = response.data.getRequest;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            request: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetStockItem({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQLSimple({
        message: `Failed to retrieve ${getSimpleLabel("stock item")}`,
        query: "getStockItem",
        variables: {
          id,
        },
      });

      const item = response.data.getStockItem;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            stockItem: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetClient({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        `Failed to retrieve ${getSimpleLabel("client")} details`,
        graphqlOperation(getClient, {
          id,
        })
      );

      const client = response.data.getClient;
      if (!client) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            client,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetQuote({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        `Failed to retrieve ${getSimpleLabel("quote")} details`,
        graphqlOperation(getQuote, {
          id,
        })
      );

      const item = response.data.getQuote;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            quote: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetInvoice({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve invoice details",
        graphqlOperation(getInvoice, {
          id,
        })
      );

      const item = response.data.getInvoice;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            invoice: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetPurchaseOrder({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve purchase order details",
        graphqlOperation(getPurchaseOrder, {
          id,
        })
      );

      const item = response.data.getPurchaseOrder;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            purchaseOrder: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}
export async function fetchAndSetSupplier({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve supplier details",
        graphqlOperation(getSupplier, {
          id,
        })
      );

      const item = response.data.getSupplier;
      if (!item) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            supplier: item,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetBoard({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve board details",
        graphqlOperation(getBoard, {
          id,
        })
      );

      const board = response.data.getBoard;
      if (!board) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            board,
          },
        },
        resolve
      );
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetLinkedTasks() {
  return new Promise(async (resolve, reject) => {
    try {
      this.setState({ isLoadingLinkedTasks: true });
      const { task } = this.props;

      let linkedTasksDetails = [];
      if (!task.linkedTasks) {
        this.setState({ isLoadingLinkedTasks: false, linkedTasksDetails: [] }, resolve);
        return;
      }

      for (let i = 0; i < task.linkedTasks.length; i++) {
        const taskId = task.linkedTasks[i].taskId;
        const linkedTask = (
          await callGraphQL(
            "Failed to retrieve task",
            graphqlOperation(getTaskSimple, {
              id: taskId,
            })
          )
        ).data.getTask;
        linkedTasksDetails.push(linkedTask);
      }
      this.setState({ linkedTasksDetails }, resolve);
    } catch (err) {
      this.setState({ linkedTasksDetails: [] }, reject);
      notification.open({
        message: `Error fetching linked ${getSimpleLabel("tasks")}`,
        duration: 0,
      });
    }
  });
}

export async function fetchAndSetTaskRevision({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        `Failed to retrieve ${getLabel({
          id: "task",
          defaultValue: "task",
        })} revision details`,
        graphqlOperation(getTaskRevision, {
          id,
        })
      );

      const taskRevision = response.data.getTaskRevision;
      if (!taskRevision) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState({ context: { ...this.state.context, taskRevision } }, resolve);
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetFile({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve file details",
        graphqlOperation(getFile, {
          id,
        })
      );

      const file = response.data.getFile;
      if (!file) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState({ context: { ...this.state.context, file } }, resolve);
    } catch (err) {
      reject(err);
    }
  });
}

export async function fetchAndSetProject({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        `Failed to retrieve ${getSimpleLabel("project")} details`,
        graphqlOperation(getProjectSimple, {
          id,
        })
      );

      const project = response.data.getProject;
      if (!project) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: { ...this.state.context, project },
        },
        resolve
      );
    } catch (err) {
      notification.open({
        message: `Error fetching ${getSimpleLabel("project")} details`,
        duration: 0,
      });
      reject(err);
    }
  });
}

export async function fetchAndSetReview({ id }) {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await callGraphQL(
        "Failed to retrieve review details",
        graphqlOperation(getReview, {
          id,
        })
      );

      const review = response.data.getReview;
      if (!review) {
        this.setState({ error: 404 });
        resolve();
        return;
      }

      this.setState(
        {
          context: {
            ...this.state.context,
            review: processEntityBeforeSetState({
              entityName: "review",
              entityData: review,
            }),
          },
        },
        resolve
      );
    } catch (err) {
      notification.open({
        message: "Error fetching review details",
        duration: 0,
      });
      reject(err);
    }
  });
}

export function showInitialisationModal(history) {
  Modal.confirm({
    title: "DraughtHub Link is not installed",
    className: "configure-computer-modal",
    content: (
      <>Please install DraughtHub Link on your computer so that you can edit and sync your files with the platform.</>
    ),
    cancelText: "Later",
    okText: "Start installation",
    onOk: () => history.push("/account?tab=draughthub-link"),
  });
}

export function showLinkIsNotRunningModal() {
  Modal.info({
    title: "DraughtHub Link is not running",
    className: "link-not-running-modal",
    content: <>You need to run DraughtHub Link to open this file on your computer.</>,
  });
}

export async function publishAllFilesInTaskRevision({ task, taskRevision }) {
  for (let i = 0; i < taskRevision.files.items.length; i++) {
    const file = taskRevision.files.items[i];
    const latestFileVersion = getLatestFileVersion(file);

    if (FILES_TO_PUBLISH_ON_CREATE.includes(file.type)) {
      await publish({
        projectId: task.projectId,
        file,
        fileVersionId: latestFileVersion.id,
        task,
        taskRevisionId: taskRevision.id,
        taskRevisionName: taskRevision.name,
      });
    }
  }
}

export async function downloadBlob({ blob, fileName }) {
  fileName = fileName.split(":").join(".");
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)
  const blobUrl = URL.createObjectURL(blob);

  // window.open(blobUrl, { target: "_self" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = blobUrl;
  link.download = fileName;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export async function blobToBase64(blob) {
  let result = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
    reader.readAsDataURL(blob);
  });
  result = result.replace(/(\r\n|\n|\r)/gm, "");
  return result;
}

export function downloadBase64({ base64String, fileName }) {
  // Convert your blob into a Blob URL (a special url that points to an object in the browser's memory)

  // window.open(blobUrl, { target: "_self" });

  // Create a link element
  const link = document.createElement("a");

  // Set link's href to point to the Blob URL
  link.href = base64String;
  link.download = fileName;

  // Append link to the body
  document.body.appendChild(link);

  // Dispatch click event on the link
  // This is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(
    new MouseEvent("click", {
      bubbles: true,
      cancelable: true,
      view: window,
    })
  );

  // Remove link from body
  document.body.removeChild(link);
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); //eslint-disable-line no-unused-vars
  return () => setValue((value) => ++value); // update the state to force render
}

export function getExcludedReviewerList({ task, users, allowUseOfCatZero }) {
  if (allowUseOfCatZero && (task.catLevel === null || task.catLevel === undefined)) {
    return users;
  } else if (!allowUseOfCatZero && !task.catLevel) {
    return users;
  }
  const result = users
    .filter(
      (user) => user.catLevelIssue === null || user.catLevelIssue === undefined || user.catLevelIssue < task.catLevel
    )
    .map((user) => user.id);

  return result;
}

export function getFileVersion({ taskRevision, program }) {
  if (!taskRevision || taskRevision.files.items.length === 0) {
    return;
  }

  return taskRevision.files.items.find((x) => x.program === program).version;
}

export function getSortedFiles({ taskRevision }) {
  return taskRevision.files.items
    .filter((file) => !file.isArchived && !file.isHidden)
    .sort((a, b) => FILE_TYPES_ORDER[a.type] - FILE_TYPES_ORDER[b.type]);
}

export async function convertFileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function getImageDimensions(file) {
  return new Promise((resolve) => {
    var url = URL.createObjectURL(file);
    var img = new Image();

    img.onload = function () {
      URL.revokeObjectURL(img.src);
      resolve({ width: img.width, height: img.height });
    };

    img.src = url;
  });
}

export function getCat2Check({ task, linkedTasksDetails }) {
  let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "NEEDS_CAT_2_CHECK");
  if (cat2CheckRelationship) {
    let cat2CheckTask = linkedTasksDetails.find((linkedTask) => linkedTask.id === cat2CheckRelationship.taskId);
    return cat2CheckTask;
  }
}

export function getDesignTaskForCat2Check({ task, linkedTasksDetails }) {
  let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "CAT_2_CHECK_FOR");
  if (cat2CheckRelationship) {
    let cat2CheckTask = linkedTasksDetails.find((linkedTask) => linkedTask.id === cat2CheckRelationship.taskId);
    return cat2CheckTask;
  }
}

export function fileHasAtLeastOneExport(file) {
  if (!file) {
    return false;
  }
  const latestVersion = getLatestFileVersion(file);
  return !!latestVersion.publishEndAt;
}

export async function archiveTask(task) {
  if (task.isArchived || task.isFinished) {
    return;
  }

  const updatedTask = (
    await callGraphQL(
      `Failed to archive ${getSimpleLabel("task")}`,
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          isArchived: true,
          isReadOnly: true,
          archivedAt: new Date().toISOString(),
          finishedAt: null,
        },
      })
    )
  ).data.updateTask;

  await window.callGraphQLSimple({
    mutation: "createTaskActivityItem",
    message: `Failed to record ${getSimpleLabel("task")} activity item`,
    variables: {
      input: {
        taskId: task.id,
        author: window.apiUser.id,
        organisation: window.apiUser.organisation,
        type: "ARCHIVED",
      },
    },
  });

  if (updatedTask.revisions?.items?.length > 0) {
    await callGraphQL(
      `Failed to update old ${getLabel({
        id: "task revision",
        defaultValue: "task revision",
      })} revision`,
      graphqlOperation(updateTaskRevision, {
        input: {
          id: updatedTask.revisions?.items.slice(-1)[0].id,
          isReadOnly: true,
        },
      })
    );
  }

  if (!window.isMac) {
    try {
      await linkApi.cleanup({
        taskId: task.id,
        type: "ARCHIVE",
      });
    } catch (e) {
      console.log("Link cleanup call failed");
    }
  }
}

/**
 * I encode the given S3 object key for use in a url. Amazon S3 keys have some non-
 * standard behavior for encoding - see this Amazon forum thread for more information:
 * https://forums.aws.amazon.com/thread.jspa?threadID=55746
 *
 * @output false
 */

export function isTaskNew(task) {
  const createdAtTimestamp = new Date(task.createdAt).getTime();
  const difference = Date.now() - createdAtTimestamp;
  return difference < IS_NEW_THRESHOLD;
}

export function isTaskOverdue(task) {
  return !task.isFinished && moment(task.dueDate).isBefore(moment().startOf("day"));
}

export function isTaskDueToday(task) {
  return !task.isFinished && task.dueDate && moment(task.dueDate).isSame(moment(), "day");
}

export async function performSheetOperation({
  apiUser,
  task,
  file,
  taskRevision,
  sheet = null,
  sheets = null,
  operation,
  extraParams,
  history,
  performOperationInDraughtHub = true,
  performOperationInApplication = true,
  organisationDetails,
}) {
  const isLinkRunning = window.useLink || (await linkApi.checkLinkIsRunning());
  if (window.apiUser.organisation === "EIS" && !isLinkRunning) {
    message.error("You need to run DraughtHub Link to perform this operation");
    return;
  }

  const fileIsOpen = await isFileOpen({ task, file });
  if (fileIsOpen) {
    showFileIsOpenModal(file);
    return;
  }

  let linkMethod;

  const oldFileVersion = getLatestFileVersion(file);
  const oldVersionNumber = oldFileVersion.versionNumber;
  const newVersionNumber = oldVersionNumber + 1;

  let newFileVersion = (
    await createFileVersionInApi({
      apiUser,
      file,
      task,
      taskRevision,
      versionNumber: newVersionNumber,
      processingStatus: performOperationInApplication && !isLinkRunning ? "IN_QUEUE" : undefined,
    })
  ).data.createFileVersion;

  switch (operation) {
    case "ADD_SHEETS":
      linkMethod = linkApi.addSheets;
      break;
    case "REMOVE_SHEET":
      linkMethod = linkApi.removeSheet;
      break;
    case "RENAME_SHEET":
      linkMethod = linkApi.renameSheet;
      break;
    default:
      break;
  }

  if (performOperationInDraughtHub) {
    switch (operation) {
      case "REMOVE_EXTERNAL_REFERENCES":
        await callGraphQL(
          "Failed to update custom ID",
          graphqlOperation(updateFileVersion, {
            input: {
              id: extraParams.latestFileVersion.id,
              externalReferences: extraParams.latestFileVersion.externalReferences.filter(
                (x) => x.id !== extraParams.externalReferences[0].id
              ),
            },
          })
        );

        break;
      case "ADD_SHEETS":
        linkMethod = linkApi.addSheets;

        const paramsForEncodeKey = {
          task,
          file,
          organisation: apiUser.organisation,
          projectId: task.projectId,
          taskId: task.id,
          clientInitials: task.client?.initials || "",
          projectInitials: task.project?.initials || "",
          taskInitials: task.initials,
          versionNumber: newFileVersion.versionNumber,
          fileId: file.id,
          taskRevisionName: taskRevision.name,
          fileType: file.type,
        };

        for (let i = 0; i < sheets.length; i++) {
          let newSheet = (
            await callGraphQL(
              "Failed to create sheet",
              graphqlOperation(createSheet, {
                input: {
                  ...sheets[i],
                  autoGeneratedReferenceNumber: await buildFileName(
                    { ...paramsForEncodeKey, sheetName: sheets[i].name },
                    KEY_TYPES.SHEET_REFERENCE
                  ),
                  constantId: `${Date.now()}${Math.floor(Math.random() * 100000)}`,
                },
                includeInPublish: true,
              })
            )
          ).data.createSheet;

          let newSheetRevisionName;
          if (organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
            newSheetRevisionName = taskRevision.name;
          } else {
            newSheetRevisionName = await getSheetRevisionName({
              organisation: task.organisation,
              newStatus: extraParams.status,
              sheet: newSheet,
              task,
            });
          }
          await createSheetRevisionInApi({
            apiUser,
            task,
            sheet: newSheet,
            file,
            fileVersion: newFileVersion,
            taskRevision,
            status: extraParams.status,
            name: newSheetRevisionName,
            description: "Initial issue",
          });
        }

        break;
      case "REMOVE_SHEET":
        linkMethod = linkApi.removeSheet;
        await callGraphQL(
          "Failed to delete sheet",
          graphqlOperation(deleteSheet, {
            input: {
              id: sheet.id,
            },
          })
        );
        break;
      case "RENAME_SHEET":
        linkMethod = linkApi.renameSheet;

        await callGraphQL(
          "Failed to update sheet",
          graphqlOperation(updateSheet, {
            input: {
              id: sheet.id,
              name: sheet.name,
            },
          })
        );
        break;
      default:
        break;
    }
  }

  const updatedFileWithContents = (
    await callGraphQL(
      "Failed to retrieve file",
      graphqlOperation(getFile, {
        id: file.id,
      })
    )
  ).data.getFile;

  await pointSheetsToLatestFileVersion({
    apiUser,
    task,
    file: updatedFileWithContents,
    taskRevision,
  });

  await callGraphQL(
    "Failed to update file",
    graphqlOperation(updateFile, {
      input: {
        id: file.id,
        itemSubscription: Math.floor(Math.random() * 1000000),
      },
    })
  );

  await copyS3MainFile({
    history,
    task,
    apiUser,
    taskRevision,
    file,
    oldVersionNumber,
    newVersionNumber,
    oldFileVersion,
    newFileVersion,
    extension: file.extension,
    openConfirmationModal: false,
  });

  if (performOperationInApplication) {
    const commonLinkConsumerParams = {
      key: newFileVersion.key,
      publishedKey: newFileVersion.exports[0].rawKey,
      annotatedKey: newFileVersion.exports[0].key,
      projectId: task.projectId,
      taskId: task.id,
      fileId: file.id,
      organisation: task.organisation,
      taskRevisionId: taskRevision.id,
      fileVersionId: newFileVersion.id,
      versionNumber: newFileVersion.versionNumber,
      executable: file.type,
      taskRevisionName: taskRevision.name,
      isReadOnly: taskRevision.isReadOnly,
      customId: newFileVersion.customId,
      externalReferences: newFileVersion.externalReferences,
    };

    if (isLinkRunning) {
      const linkResponse = await linkMethod({
        ...commonLinkConsumerParams,
        ...extraParams,
      });
      if (linkResponse.data.success) {
        notification["success"]({
          message: "Operation succeeded",
          description: `The new file is now open in ${FILE_TYPES_READABLE[file.type]}`,
        });
      } else {
        notification["error"]({
          message: `Operation failed`,
        });
      }
    } else {
      callRest({
        route: "/sendToConsumer",
        method: "post",
        body: {
          ...commonLinkConsumerParams,
          ...extraParams,
          operation,
        },
      });
      if (!window.Cypress) {
        setTimeout(() => {
          Modal.info({
            title: `We are generating a new ${FILE_TYPES_READABLE[file.type]} file`,
            content: (
              <>
                A new {FILE_TYPES_READABLE[file.type]} file is being created, containing your changes. Please re-open
                this when it is ready.
              </>
            ),
          });
        }, 1000);
      }
    }
  }
}

export async function downloadAttachment(attachment, fileName, versionId) {
  if (!fileName) {
    if (!attachment || !attachment.key) {
      message.error("No attachment specified");
      return;
    }
    fileName = attachment.key.split("/").slice(-1)[0];
  }

  let messageKey = "download-attachment-message";
  message.loading({
    content: "Downloading file to your computer...",
    key: messageKey,
  });

  try {
    const signedUrl = await getS3File(attachment.key.replace("public/", ""), versionId);
    const fileData = await axios.get(signedUrl, {
      responseType: "blob",
      onDownloadProgress: function ({ loaded, total }) {
        let readableTotalSize = calculateReadableSize({ size: total });
        message.loading({
          content: `Downloading file to your computer - ${Math.floor(
            (loaded / total) * 100
          )}% of ${readableTotalSize} done`,
          key: messageKey,
          duration: 0,
        });
        // Do whatever you want with the native progress event
      },
    });
    message.success({
      content: "File successfully downloaded",
      key: messageKey,
      duration: 2,
    });
    downloadBlob({ blob: fileData.data, fileName });
  } catch (e) {
    console.error("error downloading attachment:", e);
    notification.error({
      message: (
        <Typography.Text>
          Could not download attachment <b>{fileName}</b>
        </Typography.Text>
      ),
      duration: 0,
    });
  }

  message.destroy(messageKey);
}

export async function fetchFiles() {
  this.setState({ items: null });
  const { project, task, apiUser } = this.props;
  const { isHome } = this.state;

  this.setState({ isLoading: true });
  let s3ListResponse;
  let items = [];
  let isDocumentLibrary = this.isDocumentLibrary();
  if (isHome) {
    items.push(
      {
        key: `public/${apiUser.organisation}/Document Library`,
        name: "Document Library", // folders' names end in a slash, so we need to go another element back
        type: "FOLDER",
        deleted: false,
      },
      {
        key: "",
        prefixToUse: "",
        name: "Project attachments", // folders' names end in a slash, so we need to go another element back
        type: "FOLDER",
        deleted: false,
      }
    );
  } else {
    if (project || task?.project || isDocumentLibrary) {
      s3ListResponse = await this.listAttachments({
        project: project || task?.project,
        prefix: this.state.rootPrefix,
        isDocumentLibrary,
      });
      s3ListResponse.CommonPrefixes.forEach((item) => {
        let prefixParts = item.Prefix.split("/");
        items.push({
          key: item.Prefix,
          name: prefixParts[prefixParts.length - 2], // folders' names end in a slash, so we need to go another element back
          type: "FOLDER",
          deleted: item.deleted,
        });
      });
      s3ListResponse.Contents.forEach((item) => {
        if (item.Key === s3ListResponse.Prefix) {
          // if we're inside a folder, S3 includes that as part of the contents,
          // even though it's not actually an object, so we want to skip it
          return;
        }

        const type = getAttachmentTypeFromKey(item.Key);

        items.push({
          key: item.Key,
          etag: item.ETag,
          lastModified: item.LastModified,
          storageClass: item.StorageClass,
          name: getFilenameFromKey(item.Key, true),
          type,
          size: item.Size,
          versions: item.Versions,
          deleteMarker: item.DeleteMarker,
        });
      });
    }
  }

  this.setState({ items, isLoading: false });
}

export async function triggerUpdateParent() {
  const { project, task } = this.props;
  fetchFiles.call(this);
  if (project) {
    await callGraphQL(
      "Could not update project",
      graphqlOperation(updateProject, {
        input: {
          id: project.id,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      })
    );
  } else if (task) {
    await callGraphQL(
      "Could not update task",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      })
    );
    await callGraphQL(
      "Could not update project",
      graphqlOperation(updateProject, {
        input: {
          id: task.projectId,
          itemSubscription: Math.floor(Math.random() * 1000000),
        },
      })
    );
  }
}

export function getMonthlyDateRangePresets() {
  let dateRangePresets = {};

  for (let i = 0; i < 12; i++) {
    let startMoment = moment().subtract(i, "month").startOf("month");
    let presetName = startMoment.format("MMM YYYY");
    dateRangePresets[presetName] = [startMoment, moment(startMoment).endOf("month")];
  }

  return {
    YTD: [moment().startOf("year"), moment()],
    ...dateRangePresets,
  };
}

export async function openAttachment(attachment) {
  this.setState({ isHome: false });
  this.setState({ documentViewModalAttachment: attachment });

  if (attachment.type === "FOLDER") {
    let { realPrefix } = this.state;
    let currentPrefix = this.state.rootPrefix;
    this.setState({ isSearchActive: false });
    setTimeout(() => {
      this.setState({ isSearchActive: true });
    }, 500);
    if (this.state.isHome) {
      currentPrefix = "";
    }

    if (currentPrefix === "") {
      currentPrefix = "/";
    }

    let itemPrefix = attachment.key.replace(realPrefix, "");
    if (attachment.prefixToUse !== undefined) {
      itemPrefix = attachment.prefixToUse;
    }
    let newRootPrefix = `${currentPrefix}${itemPrefix}/`;
    if (newRootPrefix[0] === "/") {
      newRootPrefix = newRootPrefix.substring(1);
    }
    this.setState({ rootPrefix: newRootPrefix }, this.updateUrl);

    return;
  }

  let latestVersionKey = attachment.key;
  if (attachment.deleteMarker) {
    latestVersionKey = attachment.versions[1];
  }

  if (attachment.type === "IMAGE") {
    this.setState({
      isFilePreviewVisible: true,
    });
    return;
  }

  if (attachment.type === "VIDEO") {
    try {
      const publicVideoURL = await getS3File(latestVersionKey.replace("public/", ""));
      Modal.confirm({
        className: "video-preview-modal",
        cancelText: "Close",
        content: (
          <video controls>
            <source src={publicVideoURL} type="video/mp4" />
            Your browser does not support HTML video.
          </video>
        ),
        maskClosable: true,
      });
    } catch (e) {
      notification.error({
        message: <Typography.Text>Could not download video</Typography.Text>,
        duration: 0,
      });
    }
    return;
  }

  if (attachment.type === "PDF") {
    this.setState({ isLoadingFile: true });
    try {
      const publicPdfUrl = await getS3File(latestVersionKey.replace("public/", ""));
      const pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;

      const pdfData = await new Response(pdfDataBlob).arrayBuffer();
      this.setState({ pdfPreviewData: pdfData, isLoadingFile: false });
    } catch (e) {
      notification.error({
        message: <Typography.Text>Failed to download PDF file</Typography.Text>,
        duration: 0,
      });
      this.setState({
        isLoadingFile: false,
      });
    }
    return;
  }

  if (attachment.type === "EMAIL") {
    this.setState({ isLoadingFile: true });

    let key = latestVersionKey.replace("public/", "");

    if (latestVersionKey.endsWith(".msg")) {
      key = key.replace(".msg", ".dhubmsg");
    }

    if (latestVersionKey.endsWith(".eml")) {
      key = key.replace(".eml", ".dhubeml");
    }

    const publicEmailUrl = await getS3File(key);
    const emailDataBlob = (await axios.get(publicEmailUrl, { responseType: "blob" })).data;

    const emailData = await new Response(emailDataBlob).arrayBuffer();
    this.setState({ emailPreviewData: emailData, isLoadingFile: false });
    return;
  }

  if (this.props.allowDownload !== false) {
    Modal.confirm({
      className: "download-attachment-modal",
      icon: null,

      content: (
        <div className="download-attachment-modal">
          <Typography.Title level={3}>This file type does not yet support preview</Typography.Title>
          <br />
          <Typography.Text>{attachment.name}</Typography.Text>
        </div>
      ),
      maskClosable: true,
      okText: "Download file",
      cancelText: "Close",
      onOk: async () => {
        const publicEmailUrl = await getS3File(latestVersionKey.replace("public/", ""));
        const fileBlob = (await axios.get(publicEmailUrl, { responseType: "blob" })).data;
        downloadBlob({
          blob: fileBlob,
          fileName: attachment.name,
        });
      },
    });
  } else {
    Modal.info({
      className: "download-attachment-modal",
      icon: null,

      content: (
        <div className="download-attachment-modal">
          <Typography.Title level={3}>This file type does not yet support preview</Typography.Title>
          <br />
          <Typography.Text>{attachment.name}</Typography.Text>
        </div>
      ),
      maskClosable: true,
    });
  }
}

export async function assignTaskToUser({ task, user, organisationDetails }) {
  if (task.assignedTo === user?.id) {
    return true;
  }
  if (task && user && organisationDetails.usesDesignAuthority) {
    let shouldShowModalAboutNotEnoughAuthority = false;
    if (
      (organisationDetails.settings?.task?.allowUseOfCatZero &&
        task.catLevel !== null &&
        (user.catLevelDesign === null || task.catLevel > user.catLevelDesign)) ||
      (!organisationDetails.settings?.task?.allowUseOfCatZero && task.catLevel > user.catLevelDesign)
    ) {
      shouldShowModalAboutNotEnoughAuthority = true;
    }
    if (shouldShowModalAboutNotEnoughAuthority) {
      Modal.error({
        title: "Selected user does not have required authority level",
        className: "not-enough-authority-modal",
        maskClosable: true,
        content: (
          <>
            This{" "}
            {getLabel({
              id: "task",
              defaultValue: "task",
            })}{" "}
            requires {getUserReadableCatLevel(organisationDetails, task.catLevel)} design authority, but the design
            authority level of the selected user is {getUserReadableCatLevel(organisationDetails, user.catLevelDesign)}.
          </>
        ),
      });
      // the task has NOT been assigned successfully
      return false;
    }
  }

  let modal;
  try {
    modal = Modal.info({
      title: "Re-assigning task",
      className: "reassigning-task-modal",
      maskClosable: false,
      content: (
        <>
          We are{" "}
          {user
            ? `re-assigning this ${getLabel({
                id: "task",
                defaultValue: "task",
              })} to ${user.firstName} ${user.lastName}`
            : `marking this ${getLabel({
                id: "task",
                defaultValue: "task",
              })} as unassigned...`}
        </>
      ),
    });
    task = (
      await callGraphQL(
        `Failed to retrieve ${getLabel({
          id: "task",
          defaultValue: "task",
        })} details`,
        graphqlOperation(getTaskSimple, {
          id: task.id,
        })
      )
    ).data.getTask;
    const latestTaskRevision = task.revisions.items.slice(-1)[0];
    if (latestTaskRevision) {
      if (
        !latestTaskRevision.isReadOnly &&
        // this extra condition should not be needed, but for a long time,
        // we weren't marking the latest task revision as read-only when a review got approved
        latestTaskRevision.reviewStatus !== "SUCCESS" &&
        latestTaskRevision.author !== user?.id
      ) {
        await callGraphQL(
          `Failed to assign ${getLabel({
            id: "task revision",
            defaultValue: "task revision",
          })}`,
          graphqlOperation(updateTaskRevision, {
            input: {
              id: latestTaskRevision.id,
              author: user?.id || null,
            },
          })
        );
      }
      for (let i = 0; i < latestTaskRevision.files.items.length; i++) {
        const fileInTask = latestTaskRevision.files.items[i];
        const file = (
          await callGraphQL(
            "Failed to update sheet revision",
            graphqlOperation(getFile, {
              id: fileInTask.id,
            })
          )
        ).data.getFile;
        for (let j = 0; j < file.sheets.items.length; j++) {
          const sheet = file.sheets.items[j];
          const latestSheetRevision = sheet.revisions.items[sheet.revisions.items.length - 1];

          if (latestSheetRevision.reviewAcceptDate) {
            continue;
          }

          if (latestSheetRevision.author !== user?.id) {
            await callGraphQL(
              "Failed to update sheet revision",
              graphqlOperation(updateSheetRevision, {
                input: {
                  id: latestSheetRevision.id,
                  author: user?.id || latestSheetRevision.author,
                },
              })
            );
          }
        }
      }
    }

    await callGraphQL(
      "Failed to assign task",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          assignedTo: user?.id || null,
        },
      })
    );

    await window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId: task.id,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "ASSIGNEE_CHANGED",
          content: JSON.stringify({
            newAssigneeId: user?.id,
          }),
        },
      },
    });
  } catch (e) {
    console.error(e);
    notification.error({
      message: `Failed to re-assign task. Reason: ${e.message}`,
    });
  }
  if (modal) {
    modal.destroy();
  }

  sendNotificationToTaskAssignee({
    taskAssigner: window.apiUser,
    taskAssignee: user,
    clientName: task.client?.name,
    projectTitle: task.project?.title,
    taskTitle: task.title,
    taskId: task.id,
  });

  return true;
}

export function isElementOverflowing(element) {
  return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}

export async function downloadPDF({ fileKey, versionId = null, readableDate = null, file, task }) {
  try {
    const publicUrl = await getS3File(fileKey.replace("public/", ""), versionId);
    await downloadPdfFromPublicUrl({
      publicUrl,
      fileKey,
      readableDate,
      file,
      task,
    });
  } catch (e) {
    console.log("Error downloading PDF:", e);
    notification.error({
      message: "Failed to download PDF",
    });
  }
}

export async function downloadPdfFromPublicUrl({ publicUrl, fileKey, fileName, readableDate, file, task }) {
  const fileBlob = (
    await axios({
      url: publicUrl,
      method: "GET",
      responseType: "blob", // Important
    })
  ).data;
  const latestFileVersion = file.versions.items.slice(-1)[0];
  const exportFileName = `${latestFileVersion.customId || latestFileVersion.exports[0].fileName}.${
    latestFileVersion.exports[0].extension
  }`;

  let changedFileNameWithExtension = exportFileName;
  if (!latestFileVersion.exports[0].fileName && !latestFileVersion.customId) {
    let changedFileName = await changeFileNameAtDownloadTime({
      organisation: file.organisation,
      fileName: fileName || getFilenameFromKey(fileKey),
      file,
      type: KEY_TYPES.FILE_MAIN_EXPORT,
      task,
    });

    if (readableDate) {
      changedFileName += ` on ${readableDate}`;
    }
    let extension = getExtensionFromKey(fileKey);
    changedFileNameWithExtension = `${changedFileName}.${extension}`;
  }

  downloadBlob({
    blob: fileBlob,
    fileName: changedFileNameWithExtension,
  });
}

export function getLabel({ organisationDetails = undefined, id, defaultValue = "" }) {
  if (typeof window !== "undefined") {
    organisationDetails = organisationDetails || window.organisationDetails;
  }
  let value = defaultValue || id;
  if (organisationDetails && organisationDetails.labels && Array.isArray(organisationDetails.labels)) {
    for (let i = 0; i < organisationDetails.labels.length; i++) {
      const label = organisationDetails.labels[i];
      if (label.id === id && label.value) {
        value = label.value;
      }
    }
  }

  return value;
}

export function generateSheetName({ organisation, task, file, sheetCount }) {
  let sheetAlreadyExists = true;
  let newSheetName = "";
  let i = sheetCount + 1;
  while (sheetAlreadyExists) {
    newSheetName = `Sheet${i}`;
    sheetAlreadyExists = file.sheets.items.some((x) => x.name === newSheetName); // eslint-disable-line no-loop-func
    i++;
  }
  return newSheetName;
}

export async function generateFileName({ organisationDetails, taskRevision, fileType, task, templateId }) {
  return await getFrontendFileName({
    organisation: organisationDetails.id,
    organisationDetails,
    task,
    taskRevision,
    templateId,
    fileType,
  });
}

export function truncateString(targetString, maxLength) {
  if (targetString.length < maxLength) {
    return targetString;
  }

  return targetString.substring(0, maxLength) + "…";
}

export function getCleanValue(originalText) {
  return originalText
    .trim()
    .toLowerCase()
    .split(" ")
    .join("")
    .replace(/[^a-zA-Z0-9 -]/, "");
}

export function calculateReadableSize(item) {
  let sizeReadable;
  let GB = Math.pow(1024, 3);
  let MB = Math.pow(1024, 2);
  let KB = 1024;

  if (item.size) {
    if (item.size > GB * 0.9) {
      sizeReadable = `${Math.round((item.size / GB) * 100) / 100}GB`;
    } else if (item.size > MB * 0.9) {
      sizeReadable = `${Math.round((item.size / MB) * 100) / 100}MB`;
    } else {
      sizeReadable = `${Math.floor(item.size / KB)}KB`;
    }
  }
  return sizeReadable;
}

export function isVisibleOnScreen(element) {
  var rect = element.getBoundingClientRect();
  var viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
  return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

export function sanitiseSheetName(sheetName, options) {
  if (options?.trim !== false) {
    sheetName = sheetName.trim();
  }
  return sheetName.replace(SHEET_NAMES_INVALID_CHARACTERS_PATTERN, "");
}

export function sanitiseCSVValue(value) {
  let sanitisedValue = String(value || "").trim();
  sanitisedValue = sanitisedValue.split("\n").join(" ");
  sanitisedValue = sanitisedValue.split('"').join('""');
  sanitisedValue = `"${sanitisedValue}"`;

  return sanitisedValue;
}

export function downloadSheetPDF({
  sheetRevision,
  file,
  task,
  readableDate = undefined,
  customReference = undefined,
  versionId = undefined,
  fileName = undefined,
  sheetRevisionName = undefined,
}) {
  let firstExport;
  if (!HAS_SHEETS[file.type]) {
    let fileVersion =
      file.versions.items.find((x) => x.id === sheetRevision.fileVersionId) || file.versions.items.slice(-1)[-0];
    firstExport = fileVersion.exports[0];
  } else {
    firstExport = sheetRevision.exports[0];
  }
  getS3File(firstExport.key.replace("public/", ""), versionId)
    .then(async (publicUrl) => {
      const fileBlob = (
        await axios({
          url: publicUrl,
          method: "GET",
          responseType: "blob", // Important
        })
      ).data;

      let originalFileName = fileName || firstExport.fileName || getFilenameFromKey(firstExport.key);
      console.log("originalFileName =", originalFileName);
      let changedFileName = customReference;
      if (!changedFileName) {
        changedFileName = await changeFileNameAtDownloadTime({
          organisation: file.organisation,
          fileName: originalFileName,
          sheetRevisionName: sheetRevisionName || sheetRevision.name,
          sheetRevision,
          file,
          type: KEY_TYPES.FILE_SHEET_EXPORT,
          task,
        });
        console.log("changedFileName B = ", changedFileName);
        console.log("sheetRevisionName = ", sheetRevisionName);
        console.log("sheetRevision.name = ", sheetRevision.name);
      }
      console.log("changedFileName = ", changedFileName);

      if (readableDate) {
        changedFileName += ` on ${readableDate}`;
      }

      let extension = getExtensionFromKey(firstExport.key);
      let changedFileNameWithExtension = `${changedFileName}.${extension}`;

      downloadBlob({
        blob: fileBlob,
        fileName: changedFileNameWithExtension,
      });
    })
    .catch((err) => {
      console.error("Error downloading PDF:", err);
      notification.error({
        message: "Could not download PDF",
      });
    });
}

export function getExpandableParamsForTable({ render }) {
  return {
    expandedRowRender: render,
    expandIcon: ({ expanded, onExpand, record }) =>
      expanded ? (
        <Button type="clear" icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} />
      ) : (
        <Button type="clear" icon={<RightOutlined />} onClick={(e) => onExpand(record, e)} />
      ),
    rowExpandable: (record) => record.name !== "Not Expandable",
  };
}

export async function archiveProject({ projectId }) {
  await callGraphQL(
    `Failed to archive ${getLabel({
      id: "project",
      defaultValue: "project",
    })}`,
    graphqlOperation(updateProject, {
      input: {
        id: projectId,
        isArchived: true,
        archivedAt: new Date().toISOString(),
      },
    })
  );

  const tasksInProject = (
    await callGraphQLSimple({
      message: `Failed to fetch ${getSimpleLabel("tasks")} for ${getSimpleLabel("project")}`,
      queryRaw: /* GraphQL */ `
        query ListTasksByProject(
          $projectId: ID
          $createdAt: ModelStringKeyConditionInput
          $sortDirection: ModelSortDirection
          $filter: ModelTaskFilterInput
          $limit: Int
          $nextToken: String
        ) {
          listTasksByProject(
            projectId: $projectId
            createdAt: $createdAt
            sortDirection: $sortDirection
            filter: $filter
            limit: $limit
            nextToken: $nextToken
          ) {
            items {
              id
            }
            nextToken
          }
        }
      `,
      variables: {
        projectId: projectId,
      },
    })
  ).data.listTasksByProject.items;

  tasksInProject.forEach((task) => {
    if (task.isHidden) {
      return;
    }

    archiveTask(task);
  });
}

function getTeamFilter() {
  let filter = {};

  let selectedTeams = window.selectedTeams;

  if (window.apiUser === undefined) {
    throw new Error("window.apiUser is undefined");
  }
  if (window.organisationDetails === undefined) {
    throw new Error("window.organisationDetails is undefined");
  }

  const selectedTeamsInCookie = cookie.get(COOKIE_NAME_SELECTED_TEAMS);
  if (selectedTeamsInCookie) {
    selectedTeams = JSON.parse(selectedTeamsInCookie);
  } else {
    selectedTeams = [...(window.apiUser?.teams || [])];
  }

  // console.log("helpers: selectedTeams =", selectedTeams);
  // console.log("helpers: window.apiUser.teams =", window.apiUser.teams);
  if (window.organisationDetails?.settings?.general?.usesTeams && selectedTeams && selectedTeams.length > 0) {
    filter = { ...filter, or: selectedTeams.map((team) => ({ team: { eq: team } })) };
  }
  // console.log("helper filter = ", filter);
  return filter;
}

export async function checkIfS3FileHasChangedSince({ key, dateTimeToCompareAgainst }) {
  const { Versions: versions } = await callRest({
    method: "GET",
    route: `/s3-list-versions?prefix=${btoa(key)}`,
    includeCredentials: false,
  });

  if (!versions || versions.length === 0) {
    return false;
  }

  const latestVersion = versions[0];

  const lastModified = new Date(latestVersion.LastModified).toISOString();
  return lastModified > dateTimeToCompareAgainst;
}

export function hasParentWithClass(element, classname) {
  if (element && typeof element.className === "string" && element.className.split(" ").includes(classname)) {
    return true;
  }
  return element.parentNode && hasParentWithClass(element.parentNode, classname);
}

export function findScrollableParent(element, scrollType = "vertical") {
  if (element == null) {
    return null;
  }

  let overflow;
  if (scrollType === "vertical") {
    overflow = window.getComputedStyle(element).overflowY;
  } else {
    overflow = window.getComputedStyle(element).overflowX;
  }
  const isScrollable = overflow === "auto" || overflow === "scroll";

  if (isScrollable && element !== document.documentElement) {
    return element;
  }

  return findScrollableParent(element.parentElement);
}

export function isRunningAsPWA() {
  const isPWAiOS = window.navigator.standalone; // For iOS devices
  const isPWAMacOrWindows = window.matchMedia("(display-mode: standalone)").matches; // For other platforms

  return isPWAiOS || isPWAMacOrWindows;
}

window.getTeamFilter = getTeamFilter;
window.gsap = gsap;

// the following functions are exposed on the window for E2E tests
window.createProjectInApi = createProjectInApi;
window.createClientInApi = createClientInApi;
window.createTaskInApi = createTaskInApi;
window.createTemplate = createTemplate;
window.copyQuoteTemplate = copyQuoteTemplate;

window.createUserInApiAndCognito = createUserInApiAndCognito;
window.deleteOrganisationAndContents = deleteOrganisationAndContents;
