import moment from "moment";
import { isAuthorised } from "common/permissions";
import { fetchActivityItemsForRequest } from "common/shared";

import { roundToQuarter } from "common/mathHelpers";
import { getSimpleLabel } from "common/labels";
import isTimesheetBlockASiteVisit from "common/isTimesheetBlockASiteVisit";

export function getFields(params) {
  const fields = [
    {
      id: "users",
      fieldTypes: ["repeatFor"],
      label: "Each user in organisation",
      repeatForFieldName: "user",
      value: ({ users, organisationDetails }) => {
        if (organisationDetails?.settings?.general?.requirePermissionToDisplayUsers) {
          return users
            .filter((crtUser) => {
              return !crtUser.isHidden && !crtUser.isDisabled && isAuthorised(["USERS.MAKE_USER_VISIBLE"], crtUser);
            })
            .sort((a, b) => {
              return a.order < b.order ? -1 : 1;
            });
        } else {
          return users
            .filter((crtUser) => !crtUser.isHidden && !crtUser.isDisabled)
            .sort((a, b) => {
              return a.order < b.order ? -1 : 1;
            });
        }
      },
    },
    {
      id: "tasks",
      fieldTypes: ["repeatFor"],
      label: "Each task in organisation",
      repeatForFieldName: "task",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let tasks = await listTasks({ organisation: organisationDetails.id });
        return tasks;
      },
    },
    {
      id: "projects",
      fieldTypes: ["repeatFor"],
      label: "Each project in organisation",
      repeatForFieldName: "project",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let tasks = await listProjects({ organisation: organisationDetails.id });
        return tasks;
      },
    },
    {
      id: "quotes",
      fieldTypes: ["repeatFor"],
      label: "Each quote in organisation",
      repeatForFieldName: "quote",
      parameters: [
        {
          label: "Sort by total?",
          id: "shouldSortByTotal",
        },
        {
          label: "Sort by total - direction",
          id: "sortByTotalDirection",
        },
        {
          label: "How many quotes to show?",
          id: "limit",
        },
      ],
      value: async ({ organisationDetails, shouldSortByTotal, sortByTotalDirection, limit, quotes }) => {
        if (!organisationDetails) {
          return [];
        }

        quotes = quotes || (await listQuotes({ organisation: organisationDetails.id }));

        if (shouldSortByTotal) {
          quotes = quotes.sort((a, b) => {
            if (sortByTotalDirection === "ascending") {
              return a.total - b.total;
            } else {
              return b.total - a.total;
            }
          });
        }

        if (limit) {
          quotes = quotes.slice(0, limit);
        }

        return quotes;
      },
    },
    {
      id: "clientsWithAmounts",
      fieldTypes: ["repeatFor"],
      label: "Each client in organisation with amounts",
      repeatForFieldName: "client",
      value: async ({ clients, invoices, quotes }) => {
        if (!clients || !invoices || !quotes) {
          return [];
        }

        let clientsWithAmounts = clients.map((client) => {
          let clientInvoices = invoices.filter((invoice) => invoice.clientId === client.id);
          let clientQuotes = quotes.filter((quote) => quote.clientId === client.id);

          let invoicedAmount = clientInvoices.reduce((sum, invoice) => sum + invoice.total, 0);
          let invoicedAmountWithoutTax = clientInvoices.reduce((sum, invoice) => sum + invoice.subtotal, 0);
          let quotedAmount = clientQuotes.reduce((sum, quote) => sum + quote.total, 0);
          let quotedAmountWithoutTax = clientQuotes.reduce((sum, quote) => sum + quote.subtotal, 0);

          return {
            ...client,
            invoicedAmount,
            invoicedAmountWithoutTax,
            quotedAmount,
            quotedAmountWithoutTax,
          };
        });

        return clientsWithAmounts;
      },
    },
    {
      id: "quotesSubtotal",
      label: "Subtotal of quotes",
      value: async ({ quotes }) => {
        if (!quotes) {
          return 0;
        }

        let result = quotes.reduce((sum, quote) => sum + quote.subtotal, 0);
        return global.formatCurrency("GBP", result);
      },
    },
    {
      id: "invoicesSubtotal",
      label: "Subtotal of invoices",
      value: async ({ invoices }) => {
        if (!invoices) {
          return 0;
        }

        let result = invoices.reduce((sum, invoice) => sum + invoice.subtotal, 0);
        return global.formatCurrency("GBP", result);
      },
    },
    {
      id: "invoicesSubtotalNumber",
      label: "Subtotal of invoices - number",
      value: async ({ invoices }) => {
        if (!invoices) {
          return 0;
        }

        let result = invoices.reduce((sum, invoice) => sum + invoice.subtotal, 0);
        return result;
      },
    },

    {
      id: "quotesByMonth",
      fieldTypes: ["repeatFor"],
      label: "Quotes by month",
      parameters: [
        {
          label: "Date range",
          id: "dateRange",
        },
      ],
      value: async ({ organisationDetails, dateRange, quotes }) => {
        if (!organisationDetails) {
          return [];
        }

        let startDate = dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : undefined;
        let endDate = dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : undefined;

        quotes = quotes || (await listQuotes({ organisation: organisationDetails.id, startDate, endDate }));

        let quotesGroupedByMonth = groupQuotesByMonth(quotes);

        let months = [];
        for (let yearMonth in quotesGroupedByMonth) {
          months.push({
            yearMonth,
            quoteCount: quotesGroupedByMonth[yearMonth].length,
            acceptedQuoteCount: quotesGroupedByMonth[yearMonth].filter((quote) => quote.status === "ACCEPTED").length,
            total: quotesGroupedByMonth[yearMonth].reduce((sum, quote) => sum + quote.total, 0),
            acceptedTotal: quotesGroupedByMonth[yearMonth].reduce((sum, quote) => {
              if (quote.status === "ACCEPTED") {
                return sum + quote.total;
              } else {
                return sum;
              }
            }, 0),
            subtotal: quotesGroupedByMonth[yearMonth].reduce((sum, quote) => sum + quote.subtotal, 0),
            acceptedSubtotal: quotesGroupedByMonth[yearMonth].reduce((sum, quote) => {
              if (quote.status === "ACCEPTED") {
                return sum + quote.subtotal;
              } else {
                return sum;
              }
            }, 0),
            rejectedSubtotal: quotesGroupedByMonth[yearMonth].reduce((sum, quote) => {
              if (quote.status === "REJECTED") {
                return sum + quote.subtotal;
              } else {
                return sum;
              }
            }, 0),
          });
        }

        return months;
      },
    },
    {
      id: "invoicesByMonth",
      fieldTypes: ["repeatFor"],
      label: "Invoices by month",
      parameters: [
        {
          label: "Date range",
          id: "dateRange",
        },
      ],
      value: async ({ organisationDetails, dateRange, invoices }) => {
        if (!organisationDetails) {
          return [];
        }

        let startDate = dateRange ? moment(dateRange[0]).format("YYYY-MM-DD") : undefined;
        let endDate = dateRange ? moment(dateRange[1]).format("YYYY-MM-DD") : undefined;

        invoices = invoices || (await listInvoices({ organisation: organisationDetails.id, startDate, endDate }));

        let invoicesGroupedByMonth = groupInvoicesByMonth(invoices);

        let months = [];
        for (let yearMonth in invoicesGroupedByMonth) {
          months.push({
            yearMonth,
            invoiceCount: invoicesGroupedByMonth[yearMonth].length,
            subtotal: invoicesGroupedByMonth[yearMonth].reduce((sum, quote) => sum + quote.subtotal, 0),
          });
        }

        return months;
      },
    },
    {
      id: "invoiceSubtotalByCustomQuoteLineItemField",
      label: "Invoices subtotal by custom quote line item field",
      parameters: [
        {
          label: "Custom field name",
          id: "customFieldName",
        },
      ],
      value: async ({ invoices, quotes, customFieldName }) => {
        if (!invoices || !quotes || !customFieldName) {
          return [];
        }

        let subtotalByCustomField = {};
        for (let invoice of invoices) {
          for (let invoiceLineItem of invoice.lineItems.items) {
            if (invoiceLineItem.quoteLineItem) {
              let quoteLineItem = invoiceLineItem.quoteLineItem;
              let customFieldValue = quoteLineItem?.customFields?.find((x) => x.id === customFieldName)?.value;
              if (customFieldValue) {
                if (!subtotalByCustomField.hasOwnProperty(customFieldValue)) {
                  subtotalByCustomField[customFieldValue] = 0;
                }
                subtotalByCustomField[customFieldValue] += invoiceLineItem.amount;
              }
            }
          }
        }

        // turn the result into an array
        let result = [];
        for (let customFieldValue in subtotalByCustomField) {
          result.push({
            label: customFieldValue,
            value: subtotalByCustomField[customFieldValue],
          });
        }

        return result;
      },
    },
    {
      id: "invoiceSubtotalBySalesSector",
      label: "Invoices subtotal by sales sector",

      value: async ({ invoices, quotes }) => {
        if (!invoices || !quotes) {
          return [];
        }

        let customFieldName = "salessector";

        let subtotalByCustomField = {};
        for (let invoice of invoices) {
          for (let invoiceLineItem of invoice.lineItems.items) {
            if (invoiceLineItem.quoteLineItem) {
              let quoteLineItem = invoiceLineItem.quoteLineItem;
              let customFieldValue = quoteLineItem?.customFields?.find((x) => x.id.startsWith(customFieldName))?.value;
              if (customFieldValue) {
                if (!subtotalByCustomField.hasOwnProperty(customFieldValue)) {
                  subtotalByCustomField[customFieldValue] = 0;
                }
                subtotalByCustomField[customFieldValue] += invoiceLineItem.amount;
              }
            }
          }
        }

        // turn the result into an array
        let result = [];
        for (let customFieldValue in subtotalByCustomField) {
          result.push({
            label: customFieldValue,
            value: subtotalByCustomField[customFieldValue],
          });
        }

        return result;
      },
    },
    {
      id: "invoiceSubtotalByGeographicalLocation",
      label: "Invoices subtotal by geographical location",

      value: async ({ invoices, quotes }) => {
        if (!invoices || !quotes) {
          return [];
        }

        let customFieldName = "geographicallocation";

        let subtotalByCustomField = {};
        for (let invoice of invoices) {
          for (let invoiceLineItem of invoice.lineItems.items) {
            if (invoiceLineItem.quoteLineItem) {
              let quoteLineItem = invoiceLineItem.quoteLineItem;
              let customFieldValue = quoteLineItem?.customFields?.find((x) => x.id.startsWith(customFieldName))?.value;
              if (customFieldValue) {
                if (!subtotalByCustomField.hasOwnProperty(customFieldValue)) {
                  subtotalByCustomField[customFieldValue] = 0;
                }
                subtotalByCustomField[customFieldValue] += invoiceLineItem.amount;
              }
            }
          }
        }

        // turn the result into an array
        let result = [];
        for (let customFieldValue in subtotalByCustomField) {
          result.push({
            label: customFieldValue,
            value: subtotalByCustomField[customFieldValue],
          });
        }

        return result;
      },
    },
    {
      id: "timelineBlocks",
      fieldTypes: ["repeatFor"],
      label: "Each timeline block in organisation",
      repeatForFieldName: "timelineBlock",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
        {
          label: "User",
          id: "userId",
        },
        {
          label: "Include special tasks",
          id: "includeSpecialTasks",
        },
        {
          label: "Include time off",
          id: "includeTimeOff",
        },
      ],
      value: async ({
        organisationDetails,
        clients,
        projects,
        startDate,
        endDate,
        userId,
        includeSpecialTasks,
        includeTimeOff,
        tasks: tasksParam,
      }) => {
        if (!organisationDetails) {
          return [];
        }

        let startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD") : undefined;
        let endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;

        let timeOffPromise;
        if (includeTimeOff) {
          timeOffPromise = listHolidays({
            userId,
            startsAt: startDate ? moment(startDate).subtract(3, "months").format("YYYY-MM-DD") : undefined,
            endsAt: endDate ? moment(endDate).add(3, "months").format("YYYY-MM-DD") : undefined,
          });
        }

        let [tasks, timelineBlocks, timeOff] = await Promise.all([
          tasksParam ? tasksParam : listTasks({ organisation: organisationDetails.id }),
          listTimelineBlocks({
            organisation: organisationDetails.id,
            startDate: startDateFormatted,
            endDate: endDateFormatted,
            userId,
          }),
          timeOffPromise,
        ]);

        for (let timelineBlock of timelineBlocks) {
          timelineBlock.task = tasks.find((task) => task.id === timelineBlock.taskId);
          if (timelineBlock.task) {
            timelineBlock.client = clients.find((client) => client.id === timelineBlock.task.clientId);
            timelineBlock.project = projects.find((project) => project.id === timelineBlock.task.projectId);
          }
        }

        if (includeTimeOff) {
          for (let timeOffItem of timeOff) {
            if (timeOffItem.status !== "APPROVED") {
              continue;
            }
            for (let day of timeOffItem.days) {
              if (
                moment(day.day).isBefore(startDateFormatted, "day") ||
                moment(day.day).isAfter(endDateFormatted, "day")
              ) {
                continue;
              }
              timelineBlocks.push({
                id: timeOffItem.id,
                startDate: day.day,
                endDate: day.day,
                startHours: day.startHours,
                durationHours: day.endHours - day.startHours,
                taskId: timeOffItem.isSick ? "Sick day" : "Holiday",
              });
            }
          }
        }

        if (!includeSpecialTasks) {
          timelineBlocks = timelineBlocks.filter((timelineBlock) => timelineBlock.task);
        }

        timelineBlocks = [...timelineBlocks].sort((a, b) => {
          return a.startDate < b.startDate ? -1 : 1;
        });

        return timelineBlocks;
      },
    },

    {
      id: "invoices",
      fieldTypes: ["repeatFor"],
      label: "Each invoice in organisation",
      repeatForFieldName: "invoice",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate }) => {
        if (!organisationDetails) {
          return [];
        }

        let startDateFormatted = startDate ? moment(startDate).format("YYYY-MM-DD") : undefined;
        let endDateFormatted = endDate ? moment(endDate).format("YYYY-MM-DD") : undefined;

        let [invoices] = await Promise.all([
          listInvoices({
            organisation: organisationDetails.id,
            startDate: startDateFormatted,
            endDate: endDateFormatted,
          }),
        ]);

        let processedInvoices = [...invoices];

        return processedInvoices;
      },
    },

    {
      id: "projectsWithTimesheetsLastMonth",
      fieldTypes: ["repeatFor"],
      label: "Each project with timesheet blocks last month",
      repeatForFieldName: "project",
      value: async ({ organisationDetails, projects }) => {
        if (!organisationDetails) {
          return [];
        }

        let projectsWithTimesheetBlocks = [];
        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let tasks = await listTasks({ organisation: organisationDetails.id });
        let timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });

        if (projects) {
          for (let project of projects) {
            let projectTimesheetBlocks = timesheetBlocks.filter((block) => block.projectId === project.id);
            if (projectTimesheetBlocks.length > 0) {
              projectsWithTimesheetBlocks.push({
                ...project,
                timesheetBlocks: [...projectTimesheetBlocks]
                  .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
                  .map((block) => {
                    return {
                      ...block,
                      task: tasks?.find((task) => task.id === block.taskId),
                      tagsReadable: block.tags?.map((tagId) => {
                        let tag = timesheetTags.find((tag) => tag.id === tagId);
                        return tag?.label;
                      }),
                    };
                  }),
              });
            }
          }
        }

        return projectsWithTimesheetBlocks;
      },
    },
    {
      id: "clientsWithTimesheetsLastMonth",
      fieldTypes: ["repeatFor"],
      label: "Each client with timesheet blocks last month",
      repeatForFieldName: "client",
      value: async ({ organisationDetails, clients, projects }) => {
        if (!organisationDetails) {
          return [];
        }

        let clientsWithTimesheetsLastMonth = [];
        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let tasks = await listTasks({ organisation: organisationDetails.id });
        let timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });

        // iterate through all the clients and find the ones with timesheet blocks last month

        if (clients) {
          for (let client of clients) {
            let clientTimesheetBlocks = timesheetBlocks.filter((block) => block.clientId === client.id);
            let tasksAssociatedWithTimesheetBlocks = clientTimesheetBlocks.map((block) => {
              return tasks?.find((task) => task.id === block.taskId);
            });
            let tasksWithoutDuplicates = [];
            tasksAssociatedWithTimesheetBlocks.forEach((task) => {
              if (!tasksWithoutDuplicates.includes((crtTask) => crtTask.id === task.id)) {
                tasksWithoutDuplicates.push(task);
              }
            });
            if (clientTimesheetBlocks.length > 0) {
              clientsWithTimesheetsLastMonth.push({
                ...client,
                tasks: tasksWithoutDuplicates,
                timesheetBlocks: [...clientTimesheetBlocks]
                  .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
                  .map((block) => {
                    let tagsReadable = block.tags?.map((tagId) => {
                      let tag = timesheetTags.find((tag) => tag.id === tagId);
                      return tag?.label;
                    });

                    return {
                      ...block,
                      tagsReadable,
                      project: projects?.find((project) => project.id === block.projectId),
                      task: tasks?.find((task) => task.id === block.taskId),
                    };
                  }),
              });
            }
          }
        }

        return clientsWithTimesheetsLastMonth;
      },
    },
    {
      id: "timesheetBlocksLastMonth",
      label: "Each timesheet block last month",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "timesheetBlock",
      value: async ({ organisationDetails, clients, projects }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let tasks = await listTasks({ organisation: organisationDetails.id });
        let timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });
        let timesheetBlocksWithAddedInfo = [...timesheetBlocks]
          .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
          .map((block) => {
            return {
              ...block,
              project: projects?.find((project) => project.id === block.projectId),
              task: tasks?.find((task) => task.id === block.taskId),
              client: clients?.find((client) => client.id === block.clientId),
              tagsReadable: block.tags?.map((tagId) => {
                let tag = timesheetTags.find((tag) => tag.id === tagId);
                return tag?.label;
              }),
            };
          });

        return timesheetBlocksWithAddedInfo;
      },
    },
    {
      id: "eachDayInPeriod",
      label: "Each day in period",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "day",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: ({ startDate, endDate }) => {
        const days = [];
        for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, "day")) {
          days.push(date.format("YYYY-MM-DD"));
        }
        return days;
      },
    },
    {
      id: "timesheetBlocksInPeriod",
      label: "Each timesheet block in period",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "timesheetBlock",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
        {
          label: "Only include site visits?",
          id: "onlyIncludeSiteVisits",
        },

        {
          label: "Only for a specific user",
          id: "filterByUser",
        },
      ],
      value: (params) => callbackAllTimesheetBlocksInPeriod(params),
    },
    {
      id: "timesheetsByDay",
      label: "Timesheet stats by day",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "day",

      value: ({ timesheetBlocks }) => {
        if (!timesheetBlocks) {
          return [];
        }

        let sortedTimesheetBlocks = [...timesheetBlocks].sort((a, b) => (a.startAt < b.startAt ? -1 : 1));
        let startDate = moment(sortedTimesheetBlocks[0].startAt).startOf("day").format("YYYY-MM-DD");
        let endDate = moment(sortedTimesheetBlocks[sortedTimesheetBlocks.length - 1].startAt).format("YYYY-MM-DD");

        let days = [];
        for (let date = moment(startDate); date.isSameOrBefore(endDate); date.add(1, "day")) {
          let timesheetBlocksForDate = timesheetBlocks.filter(
            (timesheetBlock) => moment(timesheetBlock.startAt).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")
          );
          let totalDuration = 0;
          let billableDuration = 0;
          let nonBillableDuration = 0;
          let usersWithTimesheetBlocks = new Set();

          for (let timesheetBlock of timesheetBlocksForDate) {
            let endAt = moment(timesheetBlock.endAt);
            if (timesheetBlock.isRecording) {
              endAt = moment();
            }
            let durationHours = roundToQuarter(endAt.diff(moment(timesheetBlock.startAt), "hours", true));
            totalDuration = roundToQuarter(totalDuration + durationHours);
            if (timesheetBlock.billable) {
              billableDuration = roundToQuarter(billableDuration + durationHours);
            } else {
              nonBillableDuration = roundToQuarter(nonBillableDuration + durationHours);
            }

            usersWithTimesheetBlocks.add(timesheetBlock.userId);
          }

          days.push({
            date: date.format("DD-MM-YYYY"),
            totalDuration,
            billableDuration,
            nonBillableDuration,
            usersWithTimeCount: usersWithTimesheetBlocks.size,
            averageDayDuration: roundToQuarter(totalDuration / usersWithTimesheetBlocks.size),
          });
        }

        return days;
      },
    },
    {
      id: "timesheetBlockDurationLastMonth",
      label: "Timesheet block duration last month",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        const totalDuration = timesheetBlocks.reduce((sum, timesheetBlock) => {
          let durationHours = roundToQuarter(
            moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
          );
          return roundToQuarter(sum + durationHours);
        }, 0);

        return totalDuration;
      },
    },
    {
      id: "timesheetBlockSiteVisitCountLastMonth",
      label: "Timesheet block site visit count last month",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);
        const individualSiteVisitsByProjectAndDate = [];
        timesheetBlocksAssociatedWithSiteVisits.forEach((timesheetBlock) => {
          const key = `${timesheetBlock.projectId}_${moment(timesheetBlock.startAt).format("YYYY-MM-DD")}`;
          if (!individualSiteVisitsByProjectAndDate.includes(key)) {
            individualSiteVisitsByProjectAndDate.push(key);
          }
        });
        return individualSiteVisitsByProjectAndDate.length;
      },
    },
    {
      id: "timesheetBlockSiteVisitDurationLastMonth",
      label: "Timesheet block site visit duration last month",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);

        const totalDuration = timesheetBlocksAssociatedWithSiteVisits.reduce((sum, timesheetBlock) => {
          let durationHours = roundToQuarter(
            moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
          );
          return roundToQuarter(sum + durationHours);
        }, 0);

        return totalDuration;
      },
    },
    {
      id: "tasksWithTimesheetsLastMonth",
      label: "Tasks with timesheet blocks last month",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "task",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let tasksWithTimesheetBlocks = [];
        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let tasks = await listTasks({ organisation: organisationDetails.id });

        if (tasks && tasks.length > 0) {
          for (let task of tasks) {
            let taskTimesheetBlocks = timesheetBlocks.filter((block) => block.taskId === task.id);
            if (taskTimesheetBlocks.length > 0) {
              tasksWithTimesheetBlocks.push({
                ...task,
                timesheetBlocks: taskTimesheetBlocks.map((block) => {
                  return {
                    ...block,
                    task: tasks?.find((task) => task.id === block.taskId),
                  };
                }),
              });
            }
          }
        }

        return tasksWithTimesheetBlocks;
      },
    },
    {
      id: "taskCountWithTimesheetsLastMonth",
      label: "Task count with timesheet blocks last month",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let tasks = await listTasks({ organisation: organisationDetails.id });

        let taskCount = 0;
        if (tasks && tasks.length > 0) {
          for (let task of tasks) {
            let taskTimesheetBlocks = timesheetBlocks.filter((block) => block.taskId === task.id);
            if (taskTimesheetBlocks.length > 0) {
              taskCount++;
            }
          }
        }

        return taskCount;
      },
    },
    {
      id: "taskRevisionCountDueLastMonth",
      label: "Number of task revisions due last month",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
        });

        return taskRevisionsDueAndFinishedInMonth.length;
      },
    },
    {
      id: "taskRevisionsDueLastMonth",
      label: "Task revisions due last month",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
        });

        return taskRevisionsDueAndFinishedInMonth;
      },
    },
    {
      id: "taskRevisionCountDueLastMonthWithKPIFailed",
      label: "Number of task revisions due last month with KPI failed",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed.length;
      },
    },
    {
      id: "taskRevisionsDueLastMonthWithKPIFailed",
      label: "Task revisions due last month with KPI failed",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed;
      },
    },

    {
      id: "taskRevisionCountApprovedLastMonth",
      label: "Number of task revisions approved last month",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
        });

        return taskRevisionsDueAndFinishedInMonth.length;
      },
    },
    {
      id: "taskRevisionsApprovedLastMonth",
      label: "Task revisions approved last month",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
        });

        return taskRevisionsDueAndFinishedInMonth;
      },
    },
    {
      id: "taskRevisionCountApprovedLastMonthWithKPIFailed",
      label: "Number of task revisions approved last month with KPI failed",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed.length;
      },
    },
    {
      id: "taskRevisionsApprovedLastMonthWithKPIFailed",
      label: "Task revisions approved last month with KPI failed",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      value: async ({ organisationDetails }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed;
      },
    },
    {
      id: "clients",
      label: "Each client in organisation",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "client",
      parameters: [
        {
          label: "New for year",
          id: "newForYear",
        },
        {
          label: "Invoiced in year",
          id: "invoicedInYear",
        },
        {
          label: "Not invoiced in year",
          id: "notInvoicedInYear",
        },
        {
          label: "Sort by invoiced amount?",
          id: "shouldSortByInvoicedAmount",
        },
      ],
      value: async ({
        clients,
        invoices,
        newForYear,
        invoicedInYear,
        notInvoicedInYear,
        shouldSortByInvoicedAmount,
      }) => {
        let clientsToReturn = [...clients];

        if (newForYear) {
          clientsToReturn = clientsToReturn.filter((client) => {
            return String(moment(client.createdAt).format("YYYY")) === String(newForYear);
          });
        }

        clientsToReturn = clientsToReturn.map((client) => {
          let clientInvoices = invoices.filter((invoice) => invoice.clientId === client.id);
          let invoicedAmount = clientInvoices.reduce((sum, invoice) => sum + invoice.total, 0);
          let invoicedAmountWithoutTax = clientInvoices.reduce((sum, invoice) => sum + invoice.subtotal, 0);
          return {
            ...client,
            invoicedAmount,
            invoicedAmountWithoutTax,
          };
        });

        if (invoicedInYear || notInvoicedInYear) {
          clientsToReturn = clientsToReturn.map((client) => {
            let invoicesByYearForClient = {};
            if (invoices) {
              for (let invoice of invoices) {
                if (invoice.clientId === client.id) {
                  let invoiceYear = moment(invoice.invoiceDate).year();
                  if (!invoicesByYearForClient[invoiceYear]) {
                    invoicesByYearForClient[invoiceYear] = [];
                  }

                  invoicesByYearForClient[invoiceYear].push(invoice);
                }
              }
            }
            return {
              ...client,
              invoicesByYear: invoicesByYearForClient,
            };
          });

          if (invoicedInYear) {
            clientsToReturn = clientsToReturn.filter((client) => {
              return client.invoicesByYear[invoicedInYear];
            });
          }

          if (notInvoicedInYear) {
            clientsToReturn = clientsToReturn.filter((client) => {
              return !client.invoicesByYear[notInvoicedInYear];
            });
          }
        }

        if (shouldSortByInvoicedAmount) {
          clientsToReturn = clientsToReturn.sort((a, b) => {
            return b.invoicedAmount - a.invoicedAmount;
          });
        }

        return clientsToReturn;
      },
    },
    {
      id: "eachTimesheetTag",
      fieldTypes: ["repeatFor"],
      label: "Each timesheet tag in organisation",
      repeatForFieldName: "timesheetTags",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });

        return timesheetTags;
      },
    },
    {
      id: "eachTasksWithRevisions",
      fieldTypes: ["repeatFor"],
      label: "Each task with revisions in organisation",
      repeatForFieldName: "tasks",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let tasks = await listTasks({ organisation: organisationDetails.id, withRevisions: true });

        return tasks;
      },
    },
    {
      id: "activityItemsByRequest",
      label: "Activity items by request",
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let requests = await listRequests({ organisation: organisationDetails.id });
        let requestIds = requests.map((request) => request.id);
        let activityPromises = [];
        for (let requestId of requestIds) {
          activityPromises.push(fetchActivityItemsForRequest(requestId));
          // wait for a bit to avoid rate limiting

          await new Promise((resolve) => setTimeout(resolve, 10));
        }
        const activityDetailsForRequests = await Promise.all(activityPromises);
        let activityItemsByRequest = {};
        for (let activityDetailsForRequest of activityDetailsForRequests) {
          activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
        }

        return activityItemsByRequest;
      },
    },
    {
      id: "allTimesheetBlocksInPeriod",
      label: "All timesheet blocks in period (returns array)",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "timesheetBlocks",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
        {
          label: "Only include site visits?",
          id: "onlyIncludeSiteVisits",
        },

        {
          label: "Only for a specific user",
          id: "filterByUser",
        },
      ],
      value: (params) => {
        return callbackAllTimesheetBlocksInPeriod(params, { returnAsArray: true });
      },
    },
    {
      id: "eachQuoteInOrganisation",
      fieldTypes: ["repeatFor"],
      label: "Each quote in organisation - no parameters",
      repeatForFieldName: "quotes",
      parameters: [],
      value: async ({ organisationDetails }) => {
        if (!organisationDetails) {
          return [];
        }

        let quotes = await listQuotes({ organisation: organisationDetails.id });

        return quotes;
      },
    },

    ///////////////// EIS report with customisable date range
    {
      id: "taskRevisionCountApprovedInRange",
      label: "Number of task revisions approved in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        return taskRevisionsDueAndFinishedInMonth.length;
      },
    },
    {
      id: "taskRevisionsApprovedInRange",
      label: "Task revisions approved in range",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks, ...params }) => {
        // debugger;
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        return taskRevisionsDueAndFinishedInMonth;
      },
    },
    {
      id: "taskRevisionCountDueInRange",
      label: "Number of task revisions due in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        return taskRevisionsDueAndFinishedInMonth.length;
      },
    },
    {
      id: "taskRevisionsDueInRange",
      label: "Task revisions due in range",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsDueInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        return taskRevisionsDueAndFinishedInMonth;
      },
    },

    {
      id: "taskRevisionCountApprovedInRangeWithKPIFailed",
      label: "Number of task revisions approved in range with KPI failed",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed.length;
      },
    },
    {
      id: "taskRevisionsApprovedInRangeWithKPIFailed",
      label: "Task revisions approved in range with KPI failed",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "taskRevision",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        let taskRevisionsDueAndFinishedInMonth = await getTaskRevisionsApprovedInRange({
          organisationDetails,
          startDate,
          endDate,
          tasks,
        });

        let taskRevisionsWithKPIFailed = taskRevisionsDueAndFinishedInMonth.filter((taskRevision) => {
          return moment(taskRevision.reviewAcceptDate).isAfter(taskRevision.dueDate, "day");
        });
        return taskRevisionsWithKPIFailed;
      },
    },
    {
      id: "timesheetBlocksInRange",
      label: "Each timesheet block in range",
      fieldTypes: ["repeatFor"],
      repeatForFieldName: "timesheetBlock",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, clients, projects, startDate, endDate, tasks, timesheetTags }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        tasks = tasks || (await listTasks({ organisation: organisationDetails.id }));
        timesheetTags = timesheetTags || (await listTimesheetTags({ organisation: organisationDetails.id }));
        let timesheetBlocksWithAddedInfo = [...timesheetBlocks]
          .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
          .map((block) => {
            return {
              ...block,
              project: projects?.find((project) => project.id === block.projectId),
              task: tasks?.find((task) => task.id === block.taskId),
              client: clients?.find((client) => client.id === block.clientId),
              tagsReadable: block.tags?.map((tagId) => {
                let tag = timesheetTags.find((tag) => tag.id === tagId);
                return tag?.label;
              }),
            };
          });

        return timesheetBlocksWithAddedInfo;
      },
    },
    {
      id: "timesheetBlockDurationInRange",
      label: "Timesheet block duration in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        const totalDuration = timesheetBlocks.reduce((sum, timesheetBlock) => {
          let durationHours = roundToQuarter(
            moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
          );
          return roundToQuarter(sum + durationHours);
        }, 0);

        return totalDuration;
      },
    },
    {
      id: "timesheetBlockSiteVisitCountInRange",
      label: "Timesheet block site visit count in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);
        const individualSiteVisitsByProjectAndDate = [];
        timesheetBlocksAssociatedWithSiteVisits.forEach((timesheetBlock) => {
          const key = `${timesheetBlock.projectId}_${moment(timesheetBlock.startAt).format("YYYY-MM-DD")}`;
          if (!individualSiteVisitsByProjectAndDate.includes(key)) {
            individualSiteVisitsByProjectAndDate.push(key);
          }
        });
        return individualSiteVisitsByProjectAndDate.length;
      },
    },
    {
      id: "timesheetBlockSiteVisitDurationInRange",
      label: "Timesheet block site visit duration in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        let timesheetBlocksAssociatedWithSiteVisits = timesheetBlocks.filter((timesheetBlock) => timesheetBlock.onSite);

        const totalDuration = timesheetBlocksAssociatedWithSiteVisits.reduce((sum, timesheetBlock) => {
          let durationHours = roundToQuarter(
            moment(timesheetBlock.endAt).diff(moment(timesheetBlock.startAt), "hours", true)
          );
          return roundToQuarter(sum + durationHours);
        }, 0);

        return totalDuration;
      },
    },
    {
      id: "taskCountWithTimesheetsInRange",
      label: "Task count with timesheet blocks in range",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, startDate, endDate, tasks }) => {
        if (!organisationDetails) {
          return [];
        }

        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        tasks = tasks || (await listTasks({ organisation: organisationDetails.id }));

        let taskCount = 0;
        if (tasks && tasks.length > 0) {
          for (let task of tasks) {
            let taskTimesheetBlocks = timesheetBlocks.filter((block) => block.taskId === task.id);
            if (taskTimesheetBlocks.length > 0) {
              taskCount++;
            }
          }
        }

        return taskCount;
      },
    },
    {
      id: "clientsWithTimesheetsInRange",
      fieldTypes: ["repeatFor"],
      label: "Each client with timesheet blocks in range",
      repeatForFieldName: "client",
      parameters: [
        {
          label: "Start date",
          id: "startDate",
        },
        {
          label: "End date",
          id: "endDate",
        },
      ],
      value: async ({ organisationDetails, clients, projects, startDate, endDate, tasks }) => {
        if (!organisationDetails) {
          return [];
        }

        let clientsWithTimesheetsLastMonth = [];
        let startAt = startDate
          ? moment(startDate).startOf("day").toISOString()
          : moment().subtract(1, "month").startOf("month").toISOString();
        let endAt = endDate
          ? moment(endDate).endOf("day").toISOString()
          : moment().subtract(1, "month").endOf("month").toISOString();
        let timesheetBlocks = await listTimesheetBlocks({ organisation: organisationDetails.id, startAt, endAt });
        tasks = tasks || (await listTasks({ organisation: organisationDetails.id }));
        let timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });

        // iterate through all the clients and find the ones with timesheet blocks last month

        if (clients) {
          for (let client of clients) {
            let clientTimesheetBlocks = timesheetBlocks.filter((block) => block.clientId === client.id);
            let tasksAssociatedWithTimesheetBlocks = clientTimesheetBlocks.map((block) => {
              return tasks?.find((task) => task.id === block.taskId);
            });
            let tasksWithoutDuplicates = [];
            tasksAssociatedWithTimesheetBlocks.forEach((task) => {
              if (!tasksWithoutDuplicates.includes((crtTask) => crtTask.id === task.id)) {
                tasksWithoutDuplicates.push(task);
              }
            });
            if (clientTimesheetBlocks.length > 0) {
              clientsWithTimesheetsLastMonth.push({
                ...client,
                tasks: tasksWithoutDuplicates,
                timesheetBlocks: [...clientTimesheetBlocks]
                  .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
                  .map((block) => {
                    let tagsReadable = block.tags?.map((tagId) => {
                      let tag = timesheetTags.find((tag) => tag.id === tagId);
                      return tag?.label;
                    });

                    return {
                      ...block,
                      tagsReadable,
                      project: projects?.find((project) => project.id === block.projectId),
                      task: tasks?.find((task) => task.id === block.taskId),
                    };
                  }),
              });
            }
          }
        }

        return clientsWithTimesheetsLastMonth;
      },
    },
  ];
  if (params.organisationDetails?.groups) {
    for (let group of params.organisationDetails.groups) {
      fields.push({
        id: `repeat_usersInGroup_${group.id}`,
        fieldTypes: ["repeatFor"],
        label: `Each user in group "${group.name}"`,
        repeatForFieldName: "user",
        value: ({ users }) => users.filter((user) => group.members?.includes(user.id)),
      });
    }
  }
  return fields;
}

async function getTaskRevisionsDueInRange({ organisationDetails, startDate, endDate, tasks }) {
  if (!organisationDetails) {
    return [];
  }

  startDate = startDate
    ? moment(startDate).startOf("day").toISOString()
    : moment().subtract(1, "month").startOf("month").toISOString();
  endDate = endDate
    ? moment(endDate).endOf("day").toISOString()
    : moment().subtract(1, "month").endOf("month").toISOString();

  tasks = tasks || (await listTasks({ organisation: organisationDetails.id }));
  let targetTaskRevisions = [];

  for (let task of tasks) {
    for (let taskRevision of task.revisions.items) {
      if (
        taskRevision.dueDate &&
        taskRevision.dueDate >= startDate &&
        taskRevision.dueDate <= endDate
        // taskRevision.reviewAcceptDate >= startDate &&
        // taskRevision.reviewAcceptDate <= endAt
      ) {
        targetTaskRevisions.push({
          ...taskRevision,
          task,
        });

        break;
      }
    }
  }

  return targetTaskRevisions;
}

async function getTaskRevisionsApprovedInRange({ organisationDetails, startDate, endDate, tasks }) {
  if (!organisationDetails) {
    return [];
  }

  startDate = startDate
    ? moment(startDate).startOf("day").toISOString()
    : moment().subtract(1, "month").startOf("month").toISOString();
  endDate = endDate
    ? moment(endDate).endOf("day").toISOString()
    : moment().subtract(1, "month").endOf("month").toISOString();

  tasks = tasks || (await listTasks({ organisation: organisationDetails.id }));
  let targetTaskRevisions = [];

  for (let task of tasks) {
    for (let taskRevision of task.revisions.items) {
      if (taskRevision.reviewAcceptDate >= startDate && taskRevision.reviewAcceptDate <= endDate) {
        targetTaskRevisions.push({
          ...taskRevision,
          task,
        });

        break;
      }
    }
  }

  return targetTaskRevisions;
}

async function listTasks({ organisation, withRevisions }) {
  let tasks = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    let queryName = withRevisions ? "listTasksWithRevisions" : "listTasks";

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task")} list`,
        queryCustom: queryName,
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task")} list`,
        queryName,
        variables: params,
      });
    }

    const pageOfItems = response.data.listTasksByOrganisation.items;
    params.nextToken = response.data.listTasksByOrganisation.nextToken;
    tasks = [...tasks, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  tasks = tasks.filter((task) => !task.isHidden && task.clientId !== "DRAUGHTHUB");

  return tasks;
}

async function listInvoices({ organisation, startDate, endDate }) {
  let invoices = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    let queryName = "listInvoicesByOrganisationForReporting";

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("invoice")} list`,
        queryCustom: queryName,
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("invoice")} list`,
        queryName,
        variables: params,
      });
    }

    const pageOfItems = response.data.listInvoicesByOrganisation.items;
    params.nextToken = response.data.listInvoicesByOrganisation.nextToken;
    invoices = [...invoices, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  if (startDate && endDate) {
    invoices = invoices.filter((invoice) => {
      return invoice.invoiceDate >= startDate && invoice.invoiceDate <= endDate;
    });
  }

  return invoices;
}

async function listRequests({ organisation }) {
  let requests = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    let queryName = "listRequestsByOrganisation";

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("request")} list`,
        query: queryName,
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("request")} list`,
        queryName,
        variables: params,
      });
    }

    const pageOfItems = response.data.listRequestsByOrganisation.items;
    params.nextToken = response.data.listRequestsByOrganisation.nextToken;
    requests = [...requests, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  requests = requests.filter((request) => !request.isHidden && request.clientId !== "DRAUGHTHUB");

  return requests;
}

async function listProjects({ organisation }) {
  let items = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("project")} list`,
        queryCustom: "listProjects",
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to fetch project list",
        queryName: "listProjects",
        variables: params,
      });
    }

    const pageOfItems = response.data.listProjectsByOrganisation.items;
    params.nextToken = response.data.listProjectsByOrganisation.nextToken;
    items = [...items, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  items = items.filter((project) => !project.isArchived && !project.isHidden && project.clientId !== "DRAUGHTHUB");

  return items;
}

async function listQuotes({ organisation, startDate, endDate }) {
  let quotes = [];
  let params = {
    organisation,
    limit: 1000,
  };

  if (startDate && endDate) {
    params.createdAt = {
      between: [startDate, endDate],
    };
  }

  while (true) {
    let response;

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list quotes",
        queryCustom: "listQuotesWithLineItemsByOrganisation",
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list quotes",
        queryName: "listQuotesWithLineItemsByOrganisation",
        variables: params,
      });
    }

    const pageOfItems = response.data.listQuotesByOrganisation.items;
    params.nextToken = response.data.listQuotesByOrganisation.nextToken;
    quotes = [...quotes, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  quotes = quotes.filter((quote) => !quote.isArchived && !quote.isHidden && quote.clientId !== "DRAUGHTHUB");

  return quotes;
}

async function listTimelineBlocks({ organisation, startDate, endDate, userId }) {
  let items = [];
  let params = {
    organisation,
    limit: 1000,
  };

  let filter = {};

  if (!startDate || !endDate) {
    return [];
  }

  params.startDate = {
    between: [startDate, endDate],
  };

  if (userId) {
    filter.userId = {
      eq: userId,
    };
  }

  if (Object.keys(filter).length > 0) {
    params.filter = filter;
  }

  while (true) {
    let response;

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list timeline blocks",
        query: "listTimelineBlocksByOrganisation",
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list timeline blocks",
        queryName: "listTimelineBlocksByOrganisation",
        variables: params,
      });
    }

    const pageOfItems = response.data.listTimelineBlocksByOrganisation.items;
    params.nextToken = response.data.listTimelineBlocksByOrganisation.nextToken;
    items = [...items, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  return items;
}

export async function listHolidays({ organisation, startsAt, endsAt, userId }) {
  let items = [];
  let params = {
    limit: 1000,
  };

  let filter = {};

  if (startsAt) {
    filter.startsAt = {
      ge: startsAt,
    };
  }

  if (endsAt) {
    filter.endsAt = {
      le: endsAt,
    };
  }

  let queryName;

  if (userId) {
    queryName = "listHolidaysByUser";
    params.userId = userId;
  } else {
    queryName = "listHolidaysByOrganisation";
    params.organisation = organisation;
  }

  if (Object.keys(filter).length > 0) {
    params.filter = filter;
  }

  while (true) {
    let response;

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list holidays",
        query: queryName,
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list holidays",
        queryName: queryName,
        variables: params,
      });
    }

    const pageOfItems = response.data[queryName].items;
    params.nextToken = response.data[queryName].nextToken;
    items = [...items, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  return items;
}

async function listTimesheetTags({ organisation }) {
  let tags = [];
  let params = {
    organisation,
    limit: 1000,
  };

  while (true) {
    let response;
    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list timesheet tags",
        query: "listTimesheetTagsByOrganisation",
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list timesheet tags",
        queryName: "listTimesheetTagsByOrganisation",
        variables: params,
      });
    }

    const pageOfItems = response.data.listTimesheetTagsByOrganisation.items;
    params.nextToken = response.data.listTimesheetTagsByOrganisation.nextToken;
    tags = [...tags, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  return tags;
}

async function listTimesheetBlocks({ organisation, startAt, endAt, userId }) {
  let timesheetBlocks = [];
  let params = {
    limit: 1000,
    startAt: {
      between: [startAt, endAt],
    },
  };

  if (startAt > endAt) {
    throw new Error("Start date cannot be after end date");
  }

  let queryName = "listTimesheetBlocksByOrganisation";
  if (userId) {
    queryName = "listTimesheetBlocksByUser";
    params.userId = userId;
  } else {
    params.organisation = organisation;
  }

  while (true) {
    let response;

    if (global.isBrowser) {
      response = await window.callGraphQLSimple({
        message: "Failed to list the timesheet blocks",
        query: queryName,
        variables: params,
      });
    } else {
      response = await global.callGraphQLSimple({
        message: "Failed to list the timesheet blocks",
        queryName,
        variables: params,
      });
    }

    const pageOfItems = response.data[queryName].items;
    params.nextToken = response.data[queryName].nextToken;
    timesheetBlocks = [...timesheetBlocks, ...pageOfItems];

    if (!params.nextToken) {
      break;
    }
  }

  return timesheetBlocks;
}

function groupQuotesByMonth(quotes) {
  const grouped = {};

  quotes.forEach((quote) => {
    // Parse the date and extract year and month
    const date = new Date(quote.createdAt);
    const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

    // Initialize the array for the month if it doesn't exist
    if (!grouped[yearMonth]) {
      grouped[yearMonth] = [];
    }

    // Add the quote to the respective group
    grouped[yearMonth].push(quote);
  });

  return grouped;
}

function groupInvoicesByMonth(invoices) {
  const grouped = {};

  invoices.forEach((invoice) => {
    // Parse the date and extract year and month
    const date = new Date(invoice.invoiceDate);
    const yearMonth = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}`;

    // Initialize the array for the month if it doesn't exist
    if (!grouped[yearMonth]) {
      grouped[yearMonth] = [];
    }

    // Add the quote to the respective group
    grouped[yearMonth].push(invoice);
  });

  return grouped;
}

async function callbackAllTimesheetBlocksInPeriod(
  {
    organisationDetails,
    clients,
    projects,
    startDate,
    endDate,
    onlyIncludeSiteVisits,
    filterByUser,
    tasks,
    timesheetTags,
  },
  extraParams
) {
  if (!organisationDetails) {
    return [];
  }

  if (!startDate || !endDate) {
    return [];
  }

  let startAt = moment(startDate).startOf("day").toISOString();
  let endAt = moment(endDate).endOf("day").toISOString();
  let timesheetBlocks = await listTimesheetBlocks({
    organisation: organisationDetails.id,
    startAt,
    endAt,
    userId: filterByUser,
  });
  if (!tasks) {
    tasks = await listTasks({ organisation: organisationDetails.id });
  }
  // if (!timesheetTags) {
  //   timesheetTags = await listTimesheetTags({ organisation: organisationDetails.id });
  // }

  if (onlyIncludeSiteVisits) {
    timesheetBlocks = timesheetBlocks.filter((timesheetBlock) =>
      isTimesheetBlockASiteVisit({ timesheetBlock, timesheetTags })
    );
  }

  let timesheetBlocksWithAddedInfo = [...timesheetBlocks]
    .sort((a, b) => (a.startAt < b.startAt ? -1 : 1))
    .map((block) => {
      return {
        ...block,
        project: projects?.find((project) => project.id === block.projectId),
        task: tasks?.find((task) => task.id === block.taskId),
        client: clients?.find((client) => client.id === block.clientId),
        tagsReadable: block.tags?.map((tagId) => {
          let tag = timesheetTags?.find((tag) => tag.id === tagId);
          return tag?.label;
        }),
      };
    });

  if (extraParams?.returnAsArray) {
    return [timesheetBlocksWithAddedInfo];
  } else {
    return timesheetBlocksWithAddedInfo;
  }
}
