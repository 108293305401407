import { Checkbox, Typography } from "antd";
import { withRouter } from "react-router-dom";
import { graphqlOperation } from "aws-amplify";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { PROJECT_SETTINGS } from "common/settings";

import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

function ProjectMainSettings(props) {
  const { organisationDetails } = props;

  const checked = PROJECT_SETTINGS.filter(
    (setting) => organisationDetails.settings?.project && organisationDetails.settings?.project[setting.SCHEMA_FIELD]
  );

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  return (
    <Card withSpace title={`${getSimpleLabel("Project")} settings`}>
      <Checkbox.Group defaultValue={checkedSettings}>
        {PROJECT_SETTINGS.filter((setting) => setting.INPUT_TYPE !== "text").map((setting) => {
          const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

          return (
            <Checkbox
              className="setting-checkbox"
              value={SCHEMA_FIELD}
              key={SCHEMA_FIELD}
              onChange={async (e) => {
                await callGraphQL(
                  `Could not update ${getSimpleLabel("project")} setting`,
                  graphqlOperation(updateOrganisation, {
                    input: {
                      id: organisationDetails.id,
                      settings: {
                        ...organisationDetails?.settings,
                        project: {
                          ...(organisationDetails.settings?.project || {}),
                          [SCHEMA_FIELD]: e.target.checked,
                        },
                      },
                    },
                  })
                );
              }}
            >
              <span className="setting-name">{NAME}</span>
              <br />
              <span className="setting-description">{DESCRIPTION}</span>
            </Checkbox>
          );
        })}
      </Checkbox.Group>
      <br />
      {PROJECT_SETTINGS.filter((setting) => setting.INPUT_TYPE === "text").map((setting) => {
        const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;
        return (
          <InfoItem
            key={SCHEMA_FIELD}
            label={NAME}
            value={<Typography.Text className="setting-description">{DESCRIPTION}</Typography.Text>}
            extraContent={
              <Input
                defaultValue={organisationDetails.settings?.project[SCHEMA_FIELD]}
                showBorder
                fullWidth
                onChange={async (value) => {
                  await callGraphQL(
                    `Could not update ${getSimpleLabel("project")} setting`,
                    graphqlOperation(updateOrganisation, {
                      input: {
                        id: organisationDetails.id,
                        settings: {
                          ...organisationDetails?.settings,
                          project: {
                            ...(organisationDetails.settings?.project || {}),
                            [SCHEMA_FIELD]: value,
                          },
                        },
                      },
                    })
                  );
                }}
              />
            }
          />
        );
      })}
    </Card>
  );
}

export default withRouter(
  withSubscriptions({
    Component: ProjectMainSettings,
    subscriptions: ["organisationDetails"],
  })
);
