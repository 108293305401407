import { useState, useEffect } from "react";
import {
  Typography,
  Button,
  Select,
  Popover,
  Input,
  Switch,
  notification,
  Collapse,
  Checkbox,
  message,
  InputNumber,
  Tag,
} from "antd";
import { DeleteOutlined, DownloadOutlined } from "@ant-design/icons";
import { HexColorPicker } from "react-colorful";
import cx from "classnames";
import cookie from "js-cookie";

import { getParentRepeatForField, getRepeatFor, displayNoteAboutParentRepeat } from "../templateEditorVariableHelpers";
import {
  getFieldsForEditorByDataSource,
  getFieldLabelById,
  getFieldDetailsById,
} from "common/documentEditorDataSources/aggregator";
import withSubscriptions from "common/withSubscriptions";
import { TEMPLATE_EDITOR_CONDITIONS, TEMPLATE_APP_PAGE_COMPONENTS } from "common/shared";
import {
  DOCUMENT_EDITOR_DATA_SOURCES,
  DOCUMENT_PAGE_SIZES,
  COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH,
} from "common/constants";
import { CopyLinkIcon } from "common/icons";
import findAllMatchingObjects from "../findAllMatchingObjects";
import { getValueBasedOnFormula, valueExists, getVariables, getPageObjectParent } from "../renderHelpers";

import DraughtHubInput from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import Upload from "Upload/Upload";
import TextVariables from "./TextVariables/TextVariables";
import ConditionalFormatting from "./ConditionalFormatting/ConditionalFormatting";
import Conditions from "./Conditions/Conditions";
import DataSourceOverrideOptions from "./DataSourceOverrideOptions/DataSourceOverrideOptions";
import Explanation from "Explanation/Explanation";

import "./ObjectPanel.scss";

const INITIAL_WIDTH = 400;

export function ObjectPanel({
  organisationDetails,
  template,
  outputTemplate,
  selectedObjects,
  setSelectedObjects,
  fileTypeDetails,
  form,
  recordChange,
  isReadOnly,
  duplicateObject,
  deleteObject,
  changeObjectOrder,
  updateObject: updateObjectFromProps,
}) {
  const [isDraggingWidth, setIsDraggingWidth] = useState(false);
  const [dragStartX, setDragStartX] = useState();
  const [dragStartWidth, setDragStartWidth] = useState(
    parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH)) || INITIAL_WIDTH
  );
  const [panelWidth, setPanelWidth] = useState(
    parseFloat(cookie.get(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH)) || INITIAL_WIDTH
  );
  const [isBackgroundColorPickerOpen, setIsBackgroundColorPickerOpen] = useState(false);
  const [isTextBackgroundColorPickerOpen, setIsTextBackgroundColorPickerOpen] = useState(false);
  const [isBorderColorPickerOpen, setIsBorderColorPickerOpen] = useState(false);
  const [isFontColorPickerOpen, setIsFontColorPickerOpen] = useState(false);

  const isSpreadsheet = template.outputType?.includes("SPREADSHEET");

  const object = selectedObjects[0];

  useEffect(() => {
    window.addEventListener("mousemove", onWindowMouseMove);
    window.addEventListener("mouseup", onWindowMouseUp);

    return () => {
      window.removeEventListener("mousemove", onWindowMouseMove);
      window.removeEventListener("mouseup", onWindowMouseUp);
    };
  }, []);

  function onWindowMouseUp(e) {
    setIsDraggingWidth(false);
  }

  function onWindowMouseMove(e) {
    setIsDraggingWidth((isDraggingWidth) => {
      setDragStartX((dragStartX) => {
        setDragStartWidth((dragStartWidth) => {
          if (isDraggingWidth) {
            let deltaX = e.screenX - dragStartX;
            let newPanelWidth = Math.max(dragStartWidth - deltaX, 10);
            cookie.set(COOKIE_NAME_TEMPLATE_EDITOR_PANEL_WIDTH, newPanelWidth, {
              expires: 99999,
            });

            setPanelWidth(newPanelWidth);
          }
          return dragStartWidth;
        });
        return dragStartX;
      });
      return isDraggingWidth;
    });
  }

  function updateAllTextObjects({ targetObject, ...paramsForUpdateObject }) {
    const textObjects = findAllMatchingObjects(targetObject, (object) => object.type === "text");

    updateObject({
      targetObjects: textObjects,
      ...paramsForUpdateObject,
    });
  }

  function updateObject({ targetObject, targetObjects, fieldName, value, isNumerical = false, fields }) {
    if (!targetObjects) {
      if (!targetObject) {
        targetObject = object;
      }
      targetObjects = [targetObject];
    } else {
      targetObject = targetObjects[0];
    }

    if ((targetObject && targetObject.isLiveCopyOf) || (targetObjects.length === 1 && targetObjects[0].isLiveCopyOf)) {
      // most of the time, we want to update the reference object, meaning the one from which our object has been copied
      // there are however situations where we want to update the target object itself, for example when we are updating the position or name
      let shouldUpdateReferenceObject = true;

      let fieldsExemptFromLiveCopyLogic = ["margin", "position", "custom_name", "absolute"];

      if (fieldName) {
        let fieldNameIsExempt = fieldsExemptFromLiveCopyLogic.some((crtFieldName) =>
          fieldName.toLowerCase().includes(crtFieldName)
        );
        if (fieldNameIsExempt) {
          shouldUpdateReferenceObject = false;
        }
      }

      // debugger;
      if (shouldUpdateReferenceObject) {
        const referencesForLiveCopy = findAllMatchingObjects(
          outputTemplate,
          (object) => object && object.custom_id === targetObject.isLiveCopyOf
        );

        if (referencesForLiveCopy.length) {
          if (targetObjects) {
            targetObjects = referencesForLiveCopy;
          } else {
            targetObject = referencesForLiveCopy[0];
          }
        }
      }
    }

    if (isReadOnly) {
      message.error("You cannot edit a read-only template");
      return;
    }

    const pageObject = getPageObjectParent(targetObject);

    if (!fields) {
      if (isNumerical) {
        let parsedValue = value;
        parsedValue = parseFloat(value);
        if (Number.isNaN(parsedValue)) {
          parsedValue = 0;
        }

        let newProperties = {
          [fieldName]: fieldName.includes("custom_") || fieldName.includes("formula_") ? value : parseFloat(value),
        };

        if (fieldName.includes("formula_")) {
          let nonFormulaFieldName = fieldName.split("formula_").join("");

          const calculatedValue = getValueBasedOnFormula({
            organisationDetails,
            object: targetObject,
            pageObject,
            outputTemplate,
            formulaValue: value,
            fieldName,
            fileTypeDetails,
          });
          newProperties[nonFormulaFieldName] = calculatedValue;

          if (calculatedValue !== "" && calculatedValue !== undefined && isNaN(calculatedValue)) {
            return;
          }
        }

        fields = newProperties;
      } else {
        let newProperties = {
          [fieldName]: value,
        };

        if (fieldName.includes("formula_")) {
          let nonFormulaFieldName = fieldName.split("formula_").join("");
          const calculatedValue = getValueBasedOnFormula({
            organisationDetails,
            object: targetObject,
            pageObject,
            formulaValue: value,
            fieldName,
            fileTypeDetails,
          });
          newProperties[nonFormulaFieldName] = calculatedValue;

          if ((calculatedValue || "").includes("$")) {
            return;
          }
        }
        fields = newProperties;
      }
    }

    if (fields.hasOwnProperty("formula_width")) {
      fields.custom_width = fields.width;
      fields.custom_formula_width = fields.formula_width;
    }

    updateObjectFromProps({
      objectIds: targetObjects.map((object) => object.custom_id),
      fields,
    });

    recordChange();
  }

  function updatePageSize(pageSizeId) {
    if (isReadOnly) {
      message.error("You cannot edit a read-only template");
      return;
    }

    const pageSize = DOCUMENT_PAGE_SIZES[pageSizeId];
    if (!pageSize) {
      return;
    }

    updateObject({
      fields: {
        custom_pageSize: pageSizeId,
        width: pageSize.width,
        height: pageSize.height,
        custom_pageHeight: pageSize.height,
        custom_pageWidth: pageSize.width,
        custom_pageContentHeight: pageSize.height,
        custom_pageContentWidth: pageSize.width,
      },
      isNumerical: true,
    });
  }

  function updateDynamicText({ object, option }) {
    if (isReadOnly) {
      message.error("You cannot edit a read-only template");
      return;
    }

    let fieldLabel = "Dynamic text - empty";
    if (option) {
      fieldLabel = getFieldLabelById({
        dataSource: object.custom_dynamicInformationDataSource,
        id: option?.value,
        form,
        organisationDetails,
      });
    }
    if (!fieldLabel) {
      fieldLabel = option?.label;
    }

    let newFields = {
      custom_dynamicInformation: option?.value,
      custom_name: fieldLabel,
    };

    if (fileTypeDetails.isDocumentTemplate) {
      newFields.text = `[${fieldLabel}]`;

      if (object.group) {
        let childrenInParent = object.group.objects.filter((x) => x.evented && x.selectable).length;
        if (childrenInParent === 1) {
          newFields.custom_name = fieldLabel;
        }
      }
    } else {
      newFields.text = "-";
    }

    updateObject({
      object,
      fields: newFields,
    });

    recordChange();
  }

  async function onDeleteClick() {
    if (isReadOnly) {
      message.error("You cannot edit a read-only template");
      return;
    }

    deleteObject({ object: selectedObjects[0] });
  }

  function displayRotationField(object) {
    return (
      <InfoItem
        label="Rotation (degrees)"
        inline
        value={
          <>
            <InputNumber
              value={object.custom_angle || "0"}
              onChange={(value) => {
                updateObject({
                  fieldName: "angle",
                  value: parseFloat(value),
                  isNumerical: true,
                });
              }}
            />
          </>
        }
      />
    );
  }

  function displayControlsForContent(object) {
    return (
      <Collapse.Panel header="Content" key="content">
        {!object.custom_dynamicInformationDataSource && (
          <InfoItem
            label="Content"
            value={
              object.group?.custom_isPageNumber ? (
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={["currentPage", "pageCount"]}
                  defaultValue={object.text || ""}
                  onChange={(value) => updateObject({ fieldName: "text", value })}
                />
              ) : (
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlurWithDebounce
                  defaultValue={object.text}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "text",
                      value,
                    })
                  }
                />
              )
            }
          />
        )}

        {displayDynamicInfoFields(object)}

        <TextVariables
          object={object}
          updateObject={updateObject}
          fileTypeDetails={fileTypeDetails}
          template={template}
          form={form}
          organisationDetails={organisationDetails}
          displayParametersForObject={displayParametersForObject}
        />
      </Collapse.Panel>
    );
  }

  function displayControlsForText(object) {
    return (
      <>
        <Collapse defaultActiveKey={["position"]}>
          {isSpreadsheet ? displayPositionPanel(object) : displayPositionAndSizePanel(object)}
          {displayConditionalDisplayPanel(object)}
          {displayRepeatForPanel(object)}
          {displayControlsForContent(object)}

          {isSpreadsheet ? null : (
            <Collapse.Panel header="Look & feel" key="look" className="collapse-panel-look-feel">
              {displayRotationField(object)}
              <InfoItem
                label="Font family"
                inline
                value={
                  <Select
                    value={object.fontFamily}
                    onChange={(value) => {
                      updateObject({ fieldName: "fontFamily", value });
                    }}
                  >
                    <Select.Option value="helvetica">Helvetica</Select.Option>

                    {organisationDetails.variables.items
                      .filter((variable) => variable.type === "FONT")
                      .map((variable) => {
                        return (
                          <Select.Option value={variable.name} key={variable.id}>
                            {variable.name}
                          </Select.Option>
                        );
                      })}
                  </Select>
                }
              />
              <InfoItem
                label="Font size"
                inline
                value={
                  <DraughtHubInput
                    fullWidth
                    showBorder
                    fireOnChangeWithoutBlur
                    useAutoComplete
                    autoCompleteOptions={getVariables({
                      organisationDetails,
                    }).map((variableName) => variableName.substring(1))}
                    defaultValue={object.formula_fontSize || ""}
                    onChange={(value) => {
                      updateObject({
                        fieldName: "formula_fontSize",
                        value,
                        isNumerical: true,
                      });
                    }}
                  />
                }
              />
              <InfoItem
                label="Horizontal align"
                inline
                value={
                  <Select
                    value={object.textAlign || "left"}
                    onChange={(value) => updateObject({ fieldName: "textAlign", value })}
                  >
                    <Select.Option value="left">Left</Select.Option>
                    <Select.Option value="center">Center</Select.Option>
                    <Select.Option value="right">Right</Select.Option>
                  </Select>
                }
              />

              <InfoItem
                label="Vertical align"
                inline
                value={
                  <Select
                    placeholder="Top"
                    value={object.custom_verticalAlign}
                    onChange={(value) =>
                      updateObject({
                        object,
                        fieldName: "custom_verticalAlign",
                        value,
                      })
                    }
                  >
                    <Select.Option value="top">Top</Select.Option>
                    <Select.Option value="center">Center</Select.Option>
                    <Select.Option value="bottom">Bottom</Select.Option>
                  </Select>
                }
              />

              <InfoItem
                label="Font color"
                inline
                value={
                  <ColorPicker
                    organisationDetails={organisationDetails}
                    fieldName="formula_fill"
                    defaultValue="#000000"
                    value={object.fill}
                    visible={isFontColorPickerOpen}
                    onVisibleChange={setIsFontColorPickerOpen}
                    updateObject={(params) => updateObject({ ...params })}
                    object={object}
                  />
                }
              />
              {displayOpacityField(object)}

              {fileTypeDetails.isDocumentTemplate && (
                <InfoItem
                  label="Background color"
                  inline
                  value={
                    <ColorPicker
                      organisationDetails={organisationDetails}
                      fieldName="custom_formula_textFill"
                      value={object.custom_textFill}
                      defaultValue="#ffffff"
                      visible={isTextBackgroundColorPickerOpen}
                      onVisibleChange={setIsTextBackgroundColorPickerOpen}
                      updateObject={(params) => updateObject({ ...params })}
                      object={object}
                    />
                  }
                />
              )}
              {fileTypeDetails.isDocumentTemplate && (
                <InfoItem
                  label="Hide background"
                  inline
                  value={
                    <Checkbox
                      checked={object.custom_hideBackground}
                      onChange={(e) => {
                        updateObject({
                          object,
                          fieldName: "custom_hideBackground",
                          value: e.target.checked,
                        });
                      }}
                    />
                  }
                />
              )}

              {fileTypeDetails.isDocumentTemplate && (
                <InfoItem
                  label="Border color"
                  inline
                  value={
                    <ColorPicker
                      organisationDetails={organisationDetails}
                      fieldName="formula_stroke"
                      value={object.stroke}
                      defaultValue="#000000"
                      visible={isBorderColorPickerOpen}
                      onVisibleChange={setIsBorderColorPickerOpen}
                      updateObject={(params) =>
                        updateObject({
                          ...params,
                        })
                      }
                      object={object}
                    />
                  }
                />
              )}
              {fileTypeDetails.isDocumentTemplate && (
                <InfoItem
                  label="Border thickness"
                  inline
                  value={
                    <DraughtHubInput
                      fullWidth
                      showBorder
                      fireOnChangeWithoutBlur
                      useAutoComplete
                      autoCompleteOptions={getVariables({
                        organisationDetails,
                      }).map((variableName) => variableName.substring(1))}
                      placeholder="1"
                      defaultValue={object.formula_strokeWidth || ""}
                      onChange={(value) => {
                        if (value < 0) {
                          value = 0;
                        }
                        value = String(value);
                        updateObject({
                          object,
                          fieldName: "formula_strokeWidth",
                          value,
                          isNumerical: true,
                        });
                      }}
                    />
                  }
                />
              )}
              <ConditionalFormatting
                object={object}
                updateObject={updateObject}
                fileTypeDetails={fileTypeDetails}
                template={template}
                form={form}
                organisationDetails={organisationDetails}
              />
            </Collapse.Panel>
          )}
          {displayDuplicateAndDeletePanel(object)}
          {isSpreadsheet ? null : displayDebugInformation(object)}
        </Collapse>
      </>
    );
  }

  function displayOpacityField(object) {
    return (
      <InfoItem
        label="Opacity"
        inline
        value={
          <DraughtHubInput
            fullWidth
            showBorder
            fireOnChangeWithoutBlur
            placeholder="100"
            defaultValue={object.custom_opacity || ""}
            onChange={(value) => {
              if (!value) {
                value = "100";
              }
              let numericalValue = parseFloat(value || "0") || 0;
              if (numericalValue < 0) {
                numericalValue = 0;
              }

              updateObject({
                object,
                fields: {
                  opacity: numericalValue / 100,
                  custom_opacity: numericalValue,
                },
              });
            }}
          />
        }
      />
    );
  }

  function displayControlsForWidthAndHeight(object) {
    let elementHasHeight = true;
    if (object.custom_type === "qr-code") {
      elementHasHeight = false;
    } else if (fileTypeDetails.isDocumentTemplate) {
      if (object.type === "text") {
        elementHasHeight = false;
      }
    }

    let defaultValueWidth = "";
    if (object.formula_width !== undefined && object.formula_width !== null) {
      defaultValueWidth = object.formula_width;
    } else if (object.width !== undefined && object.width !== null) {
      defaultValueWidth = object.width;
    }

    let defaultValueHeight = "";
    if (object.formula_height !== undefined && object.formula_height !== null) {
      defaultValueHeight = object.formula_height;
    } else if (object.height !== undefined && object.height !== null) {
      defaultValueHeight = object.height;
    }

    return (
      <>
        <InfoItem
          label="Width"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={defaultValueWidth}
              onChange={(value) => {
                updateObject({
                  fieldName: "formula_width",
                  value,
                  isNumerical: true,
                });
              }}
            />
          }
        />

        {fileTypeDetails.isDocumentTemplate && (
          <InfoItem
            label="Calculate width formula at display time"
            inline
            value={
              <Checkbox
                checked={object.custom_calculateWidthFormulaAtDisplayTime}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_calculateWidthFormulaAtDisplayTime",
                    value: e.target.checked,
                  });
                }}
              />
            }
          />
        )}

        {elementHasHeight && (
          <>
            <InfoItem
              label="Height"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                    variableName.substring(1)
                  )}
                  defaultValue={defaultValueHeight}
                  onChange={(value) => {
                    updateObject({
                      fieldName: "formula_height",
                      value,
                      isNumerical: true,
                    });
                  }}
                />
              }
            />

            {fileTypeDetails.isDocumentTemplate && (
              <InfoItem
                label="Calculate height formula at display time"
                inline
                value={
                  <Checkbox
                    checked={object.custom_calculateHeightFormulaAtDisplayTime}
                    onChange={(e) => {
                      updateObject({
                        fieldName: "custom_calculateHeightFormulaAtDisplayTime",
                        value: e.target.checked,
                      });
                    }}
                  />
                }
              />
            )}
          </>
        )}
      </>
    );
  }

  function displayPositionAndSizePanel(object) {
    return (
      <Collapse.Panel header="Position & size" key="position" className="collapse-panel-position">
        {displayBringToFrontSendToBack(object)}
        {displayControlsForWidthAndHeight(object)}
        <br />
        {displayControlsForObjectPosition(object)}
        <br />
        {displayControlsForObjectPading(object)}
      </Collapse.Panel>
    );
  }

  function displayPositionPanel(object) {
    return (
      <Collapse.Panel header="Position" key="position" className="collapse-panel-position">
        {displayBringToFrontSendToBack(object)}
      </Collapse.Panel>
    );
  }

  function displayControlsForObjectPading(object) {
    return (
      <>
        <InfoItem
          label="Padding top"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_paddingTop || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_paddingTop",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding bottom"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_paddingBottom || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_paddingBottom",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding left"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_paddingLeft || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_paddingLeft",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding right"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_paddingRight || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_paddingRight",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
      </>
    );
  }

  function displaySectionPositionAndSizePanel(object) {
    return (
      <Collapse.Panel header="Position & size" key="position" className="collapse-panel-position">
        {displayBringToFrontSendToBack(object)}

        <InfoItem
          label="Min width"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_sectionMinWidth || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_sectionMinWidth",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Min height"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_sectionMinHeight || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_sectionMinHeight",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <br />

        {displayControlsForObjectPosition(object)}

        <>
          <br />
          {displayControlsForObjectPading(object)}
        </>
      </Collapse.Panel>
    );
  }

  function displayDuplicateAndDeletePanel(object, extraParams = {}) {
    const { allowDuplicate = true } = extraParams;
    return (
      <Collapse.Panel header="Duplicate & delete" key="duplicate-delete" className="collapse-panel-duplicate-delete">
        <InfoItem
          value={
            <Button
              icon={<CopyLinkIcon />}
              onClick={() => {
                duplicateObject({ object, isLiveCopy: true });
              }}
              disabled={!allowDuplicate}
            >
              Create live copy
            </Button>
          }
        />
        <InfoItem
          value={
            <Button
              icon={<CopyLinkIcon />}
              onClick={() => {
                duplicateObject({ object });
              }}
              disabled={!allowDuplicate}
            >
              Create static copy
            </Button>
          }
        />
        <InfoItem
          value={
            <Button icon={<DeleteOutlined />} onClick={onDeleteClick}>
              Delete element
            </Button>
          }
        />
      </Collapse.Panel>
    );
  }

  function displayDebugInformation(object) {
    let parentType = "";
    if (object.group) {
      if (object.group.custom_type === "page") {
        parentType = "Page";
      } else if (object.group.custom_type === "section") {
        parentType = "Section";
      } else if (object.group.custom_type) {
        parentType = object.group.custom_type;
      } else {
        parentType = object.group.type;
      }
    }
    return (
      <Collapse.Panel header="Debug information" key="debug-information" className="collapse-panel-debug-information">
        <InfoItem label="Calculated width" inline value={object.width} />
        <InfoItem label="Calculated height" inline value={object.height} />
        <InfoItem label="Parent name" inline value={object.group ? object.group.custom_name : ""} />
        <InfoItem label="Parent type" inline value={parentType} />
      </Collapse.Panel>
    );
  }

  function displayConditionalDisplayPanel(object) {
    return (
      <Collapse.Panel
        header="Conditional display"
        key="conditional-display"
        className="collapse-panel-conditional-display"
      >
        <InfoItem
          label="Use conditional display"
          inline
          value={
            <Checkbox
              checked={object.custom_usesConditionalDisplay}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_usesConditionalDisplay",
                  value: e.target.checked,
                });
              }}
            />
          }
        />
        <InfoItem
          label="Add multiple conditions"
          inline
          value={
            <Checkbox
              checked={object.custom_usesMultipleConditions}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_usesMultipleConditions",
                  value: e.target.checked,
                });
              }}
            />
          }
        />
        {object.custom_usesConditionalDisplay && !object.custom_usesMultipleConditions && (
          <>
            <InfoItem
              label="Target data source"
              value={
                <Select
                  allowClear
                  value={object.custom_conditionalDisplayDataSource}
                  placeholder="No option selected"
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                  onChange={(_, option) => {
                    updateObject({
                      fields: {
                        custom_conditionalDisplayDataSource: option?.value,
                        custom_conditionalDisplayDataSourceField: undefined,
                        custom_conditionalDisplayDataSourceOverride: undefined,
                      },
                    });
                  }}
                >
                  {fileTypeDetails.dataSources.map((dataSource) => {
                    return (
                      <Select.Option
                        key={dataSource}
                        value={dataSource}
                        label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      >
                        {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
            />
            <DataSourceOverrideOptions
              object={object}
              dataSourceFieldName="custom_conditionalDisplayDataSource"
              dataSourceOverrideFieldName="custom_conditionalDisplayDataSourceOverride"
              fileTypeDetails={fileTypeDetails}
              form={form}
              organisationDetails={organisationDetails}
              onChange={({ dataSourceOverrideFieldName, value }) => {
                updateObject({
                  fields: {
                    [dataSourceOverrideFieldName]: value,
                  },
                });
              }}
            />

            {object.custom_conditionalDisplayDataSource && (
              <InfoItem
                label="Target field"
                value={
                  <Select
                    allowClear
                    value={object.custom_conditionalDisplayDataSourceField}
                    placeholder="No option selected"
                    showSearch
                    filterOption={(searchValue, { label }) => {
                      return label?.toLowerCase().includes(searchValue?.toLowerCase());
                    }}
                    onChange={(_, option) => {
                      updateObject({
                        fields: {
                          custom_conditionalDisplayDataSourceField: option?.value,
                        },
                      });
                    }}
                  >
                    {getFieldsForEditorByDataSource({
                      repeatFor: getRepeatFor(object),
                      dataSource: object.custom_conditionalDisplayDataSource,
                      form,
                      organisationDetails,
                    }).map((option) => {
                      return (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
            )}
            {object.custom_conditionalDisplayDataSourceField && (
              <InfoItem
                label="Condition"
                value={
                  <Select
                    allowClear
                    value={object.custom_conditionalDisplayCondition}
                    placeholder="No option selected"
                    showSearch
                    filterOption={(searchValue, { label }) => {
                      return label?.toLowerCase().includes(searchValue?.toLowerCase());
                    }}
                    onChange={(_, option) => {
                      updateObject({
                        fields: {
                          custom_conditionalDisplayCondition: option?.value,
                        },
                      });
                    }}
                  >
                    {TEMPLATE_EDITOR_CONDITIONS.map((option) => {
                      return (
                        <Select.Option key={option.value} value={option.value} label={option.label}>
                          {option.label}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
            )}
            {object.custom_conditionalDisplayCondition &&
              TEMPLATE_EDITOR_CONDITIONS.find(
                (condition) => condition.value === object.custom_conditionalDisplayCondition
              )?.hasTarget && (
                <InfoItem
                  label="Target value / values"
                  value={
                    <DraughtHubInput
                      fullWidth
                      showBorder
                      fireOnChangeWithoutBlur
                      useAutoComplete
                      autoCompleteOptions={getVariables({
                        organisationDetails,
                      }).map((variableName) => variableName.substring(1))}
                      defaultValue={object.custom_conditionalDisplayTarget}
                      onChange={(value) =>
                        updateObject({
                          object,
                          fieldName: "custom_conditionalDisplayTarget",
                          value,
                        })
                      }
                    />
                  }
                />
              )}
          </>
        )}

        {object.custom_usesConditionalDisplay && object.custom_usesMultipleConditions && (
          <Conditions
            object={object}
            updateObject={updateObject}
            fileTypeDetails={fileTypeDetails}
            template={template}
            form={form}
            organisationDetails={organisationDetails}
          />
        )}
      </Collapse.Panel>
    );
  }

  function displayDynamicInfoFields(object) {
    let fieldType;
    switch (object.custom_type) {
      case "image_container":
        fieldType = "image";
        break;
      case "signature":
        fieldType = "signature";
        break;
      case "dynamic_file":
        fieldType = "file";
        break;
      default:
        switch (object.type) {
          case "text":
            fieldType = "textfield";
            break;
          default:
            break;
        }
        break;
    }

    let dynamicInfoFieldDetails;
    let parentRepeatForField;

    if (object.custom_dynamicInformationDataSource) {
      parentRepeatForField = getParentRepeatForField({ object, form, organisationDetails });
    }

    if (object.custom_dynamicInformation && object.custom_dynamicInformationDataSource) {
      const paramsForGetFieldDetails = {
        parentRepeat: parentRepeatForField,
        dataSource: object.custom_dynamicInformationDataSource,
        id: parentRepeatForField ? `repeat_${object.custom_dynamicInformation}` : object.custom_dynamicInformation,
        fieldId: object.custom_dynamicInformation,
        form,
        organisationDetails,
      };
      dynamicInfoFieldDetails = getFieldDetailsById(paramsForGetFieldDetails);
    }

    let repeatFor = getRepeatFor(object);

    return (
      <>
        {displayNoteAboutParentRepeat({ object, form, organisationDetails })}
        <InfoItem
          label="Dynamic content - data source"
          value={
            <Select
              allowClear
              value={object.custom_dynamicInformationDataSource}
              placeholder="No option selected"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
              onChange={(_, option) => {
                updateObject({
                  fields: {
                    custom_dynamicInformationDataSource: option?.value,
                    custom_dynamicInformation: undefined,
                    custom_dynamicInformationDataSourceOverride: undefined,
                  },
                });
              }}
            >
              {fileTypeDetails.dataSources.map((dataSource, index) => {
                return (
                  <Select.Option key={index} value={dataSource} label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}>
                    {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />

        <DataSourceOverrideOptions
          object={object}
          dataSourceFieldName="custom_dynamicInformationDataSource"
          dataSourceOverrideFieldName="custom_dynamicInformationDataSourceOverride"
          fileTypeDetails={fileTypeDetails}
          form={form}
          organisationDetails={organisationDetails}
          onChange={({ dataSourceOverrideFieldName, value }) => {
            updateObject({
              fields: {
                [dataSourceOverrideFieldName]: value,
              },
            });
          }}
        />

        {object.custom_dynamicInformationDataSource && (
          <>
            <InfoItem
              label="Field to display"
              value={
                <Select
                  allowClear
                  value={object.custom_dynamicInformation}
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                  placeholder="No option selected"
                  onChange={(_, option) => updateDynamicText({ object, option })}
                >
                  {getFieldsForEditorByDataSource({
                    parentRepeat: parentRepeatForField,
                    dataSource: object.custom_dynamicInformationDataSource,
                    repeatFor,
                    form,
                    fieldType,
                    organisationDetails,
                  }).map((option, i) => {
                    return (
                      <Select.Option key={i} value={option.value} label={option.label}>
                        {option.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
            />
            {displayParametersForObject({
              fieldName: "custom_dynamicInformationParameters",
              object,
              parameters: dynamicInfoFieldDetails?.parameters,
            })}
          </>
        )}

        <InfoItem
          label="Custom field name"
          inline
          value={
            <Input
              defaultValue={object.custom_customFieldName}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_customFieldName",
                  value: e.target.value,
                });
              }}
            />
          }
        />

        <InfoItem
          label="Trim whitespace"
          inline
          value={
            <Checkbox
              defaultChecked={object.custom_trimWhitespace}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_trimWhitespace",
                  value: e.target.checked,
                });
              }}
            />
          }
        />

        {object.custom_type === "dynamic_file" && (
          <InfoItem
            label="Has page borders"
            inline
            value={
              <>
                <Checkbox
                  checked={object.custom_hasPageBorders}
                  onChange={(e) => {
                    updateObject({
                      fieldName: "custom_hasPageBorders",
                      value: e.target.checked,
                    });
                  }}
                />
              </>
            }
          />
        )}

        <InfoItem
          label="Date format"
          inline
          value={
            <Input
              placeholder="DD-MM-YYYY"
              defaultValue={object.custom_dateFormat}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_dateFormat",
                  value: e.target.value,
                });
              }}
            />
          }
        />

        <InfoItem
          label="Add number of days"
          inline
          value={
            <Input
              placeholder="0"
              defaultValue={object.custom_dateAddDays}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_dateAddDays",
                  value: e.target.value,
                });
              }}
            />
          }
        />

        {dynamicInfoFieldDetails?.isIndex && (
          <InfoItem
            label="Number prefix"
            inline
            value={
              <Input
                placeholder="0"
                defaultValue={object.custom_numberPrefix || ""}
                onChange={(e) =>
                  updateObject({
                    fieldName: "custom_numberPrefix",
                    value: e.target.value,
                    isNumerical: true,
                  })
                }
              />
            }
          />
        )}
        {dynamicInfoFieldDetails?.isIndex && (
          <InfoItem
            label="Number of prefix digits to remove"
            inline
            value={
              <DraughtHubInput
                fullWidth
                showBorder
                placeholder="0"
                fireOnChangeWithoutBlur
                defaultValue={object.custom_numberPrefixDigitsToRemove || ""}
                onChange={(value) =>
                  updateObject({
                    fieldName: "custom_numberPrefixDigitsToRemove",
                    value,
                    isNumerical: true,
                  })
                }
              />
            }
          />
        )}
        {dynamicInfoFieldDetails?.isSpreadsheet && (
          <InfoItem
            label="Desired cell"
            inline
            value={
              <Input
                placeholder="e.g. A1"
                defaultValue={object.custom_targetSpreadsheetCell}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_targetSpreadsheetCell",
                    value: e.target.value,
                  });
                }}
              />
            }
          />
        )}

        {dynamicInfoFieldDetails?.fieldTypes?.includes("repeatFor") || dynamicInfoFieldDetails?.supportsSeparator ? (
          <InfoItem
            label="Static separator between elements"
            inline
            value={
              <Input
                placeholder=""
                defaultValue={object.custom_staticSeparator}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_staticSeparator",
                    value: e.target.value,
                  });
                }}
              />
            }
          />
        ) : (
          <InfoItem
            label="Split value by (separator)"
            inline
            value={
              <Input
                placeholder=""
                defaultValue={object.custom_splitBySeparator}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_splitBySeparator",
                    value: e.target.value,
                  });
                }}
              />
            }
          />
        )}

        {object.custom_splitBySeparator && (
          <InfoItem
            label="Use value at index after split"
            inline
            value={
              <Input
                placeholder=""
                defaultValue={object.custom_splitUseValueAtIndex}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_splitUseValueAtIndex",
                    value: e.target.value,
                  });
                }}
              />
            }
          />
        )}
        <InfoItem
          label="Skip first X characters"
          inline
          value={
            <Input
              placeholder="0"
              defaultValue={object.custom_skipFirstXCharacters}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_skipFirstXCharacters",
                  value: e.target.value,
                  isNumber: true,
                });
              }}
            />
          }
        />
        <InfoItem
          label="Static prefix"
          inline
          value={
            <Input
              placeholder=""
              defaultValue={object.custom_staticPrefix}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_staticPrefix",
                  value: e.target.value,
                });
              }}
            />
          }
        />

        <InfoItem
          label="Static suffix"
          inline
          value={
            <Input
              placeholder=""
              defaultValue={object.custom_staticSuffix}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_staticSuffix",
                  value: e.target.value,
                });
              }}
            />
          }
        />

        <InfoItem
          label="Transform text"
          inline
          value={
            <Select
              allowClear
              value={object.custom_textTransform}
              placeholder="None"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
              onChange={(_, option) => {
                updateObject({
                  fieldName: "custom_textTransform",
                  value: option?.value,
                });
              }}
            >
              <Select.Option value="uppercase">Uppercase</Select.Option>
              <Select.Option value="lowercase">Lowercase</Select.Option>
              <Select.Option value="capitalize">Capitalize</Select.Option>
            </Select>
          }
        />
      </>
    );
  }

  function displayControlsForDynamicImage(object) {
    return (
      <>
        <Collapse defaultActiveKey={["content"]}>
          {displayConditionalDisplayPanel(object)}
          <Collapse.Panel header="Position & size" key="position" className="collapse-panel-position">
            {displayBringToFrontSendToBack(object)}
            {displayControlsForWidthAndHeight(object)}
            {displayControlsForObjectPosition(object)}
          </Collapse.Panel>
          <Collapse.Panel header="Content" key="content" className="collapse-panel-content">
            <InfoItem
              label={
                <>
                  <Explanation title="You can give the exact path to an attachment to be loaded here, instead of taking the value from a dynamic field" />{" "}
                  Static attachment path
                </>
              }
              value={
                <Input
                  defaultValue={object.custom_staticAttachmentKey}
                  onChange={(e) => {
                    updateObject({
                      fieldName: "custom_staticAttachmentKey",
                      value: e.target.value,
                    });
                  }}
                />
              }
            />
            {displayDynamicInfoFields(object)}
          </Collapse.Panel>
          {displayDuplicateAndDeletePanel(object)}
          {displayDebugInformation(object)}
        </Collapse>
      </>
    );
  }

  function displayControlsForStaticImage(object) {
    return (
      <>
        <Collapse defaultActiveKey={["content"]}>
          {displayConditionalDisplayPanel(object)}
          <Collapse.Panel header="Position & scale" key="position" className="collapse-panel-position">
            {displayBringToFrontSendToBack(object)}
            <InfoItem
              label="Width"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={(object.width || 0) * (object.scaleX || 1) || 0}
                  onChange={(value) => {
                    if (value === "") {
                      value = 1;
                    }
                    const originalWidth = 100;

                    const newScale = value / originalWidth;
                    updateObject({
                      fields: { scaleX: newScale },
                      isNumerical: true,
                    });
                  }}
                />
              }
            />

            {displayControlsForObjectPosition(object)}
          </Collapse.Panel>

          <Collapse.Panel header="Content" key="content" className="collapse-panel-content">
            <InfoItem value={<Upload size="normal" onChange={(_, file) => onStaticImageUpload(object, file)} />} />
            <Button icon={<DownloadOutlined />} onClick={() => downloadImage(object)}>
              Download image
            </Button>
          </Collapse.Panel>
          {displayDuplicateAndDeletePanel(object, { allowDuplicate: false })}
          {displayDebugInformation(object)}
        </Collapse>
      </>
    );
  }

  async function downloadImage(object) {
    window.open(object.src, "_blank");
  }

  async function onStaticImageUpload(object, file) {
    let messageKey = "uploading-image";
    if (!file) {
      // it means the user cancelled the upload
      return;
    }
    message.loading({
      content: "Uploading image...",
      key: messageKey,
      duration: 0,
    });

    let fileKey = `${organisationDetails.id}/templates/uploaded_images/${template.name}_${Date.now()}.${
      file.name.split(".").slice(-1)[0]
    }`;
    try {
      await Storage.put(fileKey, file);
      const publicUrl = await Storage.get(fileKey);

      let objectWidth = 100;

      const { width, height } = await new Promise((resolve, reject) => {
        const image = new Image();
        image.src = publicUrl;
        image.onload = () => {
          resolve({ width: image.width, height: image.height });
        };
      });

      const initialScale = objectWidth / width;

      updateObject({
        object,
        fields: {
          custom_imageKey: fileKey,
          custom_originalImageWidth: width,
          height: height * initialScale,
          width: objectWidth,
          custom_width: objectWidth,
          src: publicUrl,
        },
      });
      message.success({
        content: "Image uploaded",
        key: messageKey,
        duration: 2,
      });
    } catch (e) {
      message.destroy();
      notification.error({
        message: (
          <Typography.Text>
            Could not upload image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  function displayControlsForChapter(object) {
    return (
      <>
        <Collapse defaultActiveKey={["conditional-display"]}>
          <Collapse.Panel header="Position" key="position">
            {displayBringToFrontSendToBack(object)}
          </Collapse.Panel>
          {displayConditionalDisplayPanel(object)}
          {displayChapterLinkToButton(object)}
          {/* {displayRepeatForPanel(object)} */}

          {displayDuplicateAndDeletePanel(object)}
        </Collapse>
      </>
    );
  }

  function displayChapterLinkToButton(object) {
    return (
      <Collapse.Panel header="Link to button" key="link-to-button">
        <InfoItem
          label="Target form button ID"
          value={
            <Input
              showBorder
              fullWidth
              defaultValue={object.custom_chapterLinkToButton}
              onChange={(e) => {
                updateObject({
                  fieldName: "custom_chapterLinkToButton",
                  value: e.target.value,
                });
              }}
            />
          }
        />
      </Collapse.Panel>
    );
  }

  function displayControlsForPage(object) {
    return (
      <>
        <Collapse defaultActiveKey={["padding"]}>
          {isSpreadsheet ? null : (
            <>
              <Collapse.Panel header="Size and position" key="size">
                <InfoItem
                  label="Page size"
                  inline
                  value={
                    <Select
                      defaultValue="A4"
                      value={object.custom_pageSize}
                      onChange={(_, option) => {
                        updatePageSize(option?.value);
                      }}
                    >
                      {Object.keys(DOCUMENT_PAGE_SIZES).map((pageSize) => {
                        return (
                          <Select.Option key={pageSize} value={pageSize}>
                            {DOCUMENT_PAGE_SIZES[pageSize].label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
                {displayBringToFrontSendToBack(object)}
              </Collapse.Panel>

              <Collapse.Panel header="Padding" key="padding">
                <InfoItem
                  label="Override document page padding"
                  inline
                  value={
                    <Switch
                      checked={object.custom_hasCustomPadding}
                      onChange={(value) => {
                        updateObject({
                          fieldName: "custom_hasCustomPadding",
                          value,
                        });
                      }}
                    />
                  }
                />
                {object.custom_hasCustomPadding && displayControlsForObjectPading(object)}
              </Collapse.Panel>
              {displayParentTextObjectReset(object)}
            </>
          )}
          {displayConditionalDisplayPanel(object)}
          {displayRepeatForPanel(object)}

          {displayDuplicateAndDeletePanel(object)}
          {isSpreadsheet ? null : displayDebugInformation(object)}
        </Collapse>
      </>
    );
  }

  function displayControlsForSection(object) {
    return (
      <Collapse defaultActiveKey={["position"]}>
        {isSpreadsheet ? displayPositionPanel(object) : displaySectionPositionAndSizePanel(object)}
        {isSpreadsheet ? null : displayInternalLayoutPanel(object)}
        {displayConditionalDisplayPanel(object)}
        <Collapse.Panel header="Data used" key="data-used">
          <TextVariables
            object={object}
            updateObject={updateObject}
            fileTypeDetails={fileTypeDetails}
            template={template}
            form={form}
            organisationDetails={organisationDetails}
            isDataSection
            displayParametersForObject={displayParametersForObject}
          />
        </Collapse.Panel>
        {displayRepeatForPanel(object)}
        {isSpreadsheet ? null : displaySectionLookAndFeelPanel(object)}
        {isSpreadsheet ? null : displayParentTextObjectReset(object)}
        {displayDuplicateAndDeletePanel(object)}
        {isSpreadsheet ? null : displayDebugInformation(object)}
      </Collapse>
    );
  }

  function displayControlsForComponent(object) {
    return (
      <Collapse defaultActiveKey={["component-details"]}>
        {isSpreadsheet ? displayPositionPanel(object) : displayPositionAndSizePanel(object)}
        {displayConditionalDisplayPanel(object)}
        {displayRepeatForPanel(object)}
        {displayComponentDetailsPanel(object)}
        {displayDuplicateAndDeletePanel(object)}
        {isSpreadsheet ? null : displayDebugInformation(object)}
      </Collapse>
    );
  }

  function displayComponentDetailsPanel(object) {
    let componentTypeDetails;
    if (object.custom_componentType) {
      componentTypeDetails = TEMPLATE_APP_PAGE_COMPONENTS.find(
        (componentTypeDetails) => componentTypeDetails.id === object.custom_componentType
      );
    }

    return (
      <Collapse.Panel header="Component details" key="component-details" className="collapse-panel-component-details">
        <InfoItem
          label="Component type"
          value={
            <Select
              defaultValue={object.custom_componentType}
              placeholder="No component selected"
              onChange={(_, option) => {
                updateObject({
                  fields: {
                    custom_componentType: option?.value,
                    custom_componentParameters: {},
                  },
                });
              }}
            >
              {TEMPLATE_APP_PAGE_COMPONENTS.map((componentTypeDetails) => {
                return (
                  <Select.Option key={componentTypeDetails.id} value={componentTypeDetails.id}>
                    {componentTypeDetails.label}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />
        {displayParametersForObject({
          fieldName: "custom_componentParameters",
          object,
          parameters: componentTypeDetails?.parameters,
        })}
      </Collapse.Panel>
    );
  }

  function displayInternalLayoutPanel(object) {
    return (
      <Collapse.Panel header="Internal layout" key="internal-layout" className="collapse-panel-internal-layout">
        <InfoItem
          label="Arrange children as"
          inline
          value={
            <Select
              defaultValue="column"
              value={object.custom_flexDirection}
              onChange={(_, option) => {
                updateObject({
                  fieldName: "custom_flexDirection",
                  value: option?.value,
                });
              }}
            >
              <Select.Option key="row" value="row">
                Row
              </Select.Option>
              <Select.Option key="column" value="column">
                Column
              </Select.Option>
            </Select>
          }
        />
        <InfoItem
          label="Distance between children"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={object.custom_formula_gap || ""}
              onChange={(value) =>
                updateObject({
                  fieldName: "custom_formula_gap",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
      </Collapse.Panel>
    );
  }

  function displaySectionLookAndFeelPanel(object) {
    return (
      <Collapse.Panel header="Look & feel" key="look" className="collapse-panel-look-feel">
        <InfoItem
          label="Background color"
          inline
          value={
            <ColorPicker
              organisationDetails={organisationDetails}
              fieldName="custom_formula_sectionFill"
              value={object.custom_sectionFill}
              defaultValue="#ffffff"
              visible={isBackgroundColorPickerOpen}
              onVisibleChange={setIsBackgroundColorPickerOpen}
              updateObject={(params) =>
                updateObject({
                  ...params,
                })
              }
              object={object}
            />
          }
        />
        <InfoItem
          label="Background opacity %"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              defaultValue={
                object.custom_sectionBackgroundOpacity === undefined ? "100" : object.custom_sectionBackgroundOpacity
              }
              step={10}
              onChange={(value) => {
                if (value < 0) {
                  value = 0;
                } else if (value > 100) {
                  value = 100;
                }
                updateObject({
                  fieldName: "custom_sectionBackgroundOpacity",
                  value,
                  isNumerical: true,
                });
              }}
            />
          }
        />
        <InfoItem
          label="Border color"
          inline
          value={
            <ColorPicker
              organisationDetails={organisationDetails}
              fieldName="custom_formula_sectionStroke"
              value={object.custom_sectionStroke}
              defaultValue="#000000"
              visible={isBorderColorPickerOpen}
              onVisibleChange={setIsBorderColorPickerOpen}
              updateObject={(params) => {
                updateObject({
                  ...params,
                  isNumerical: false,
                });
              }}
              object={object}
            />
          }
        />
        <InfoItem
          label="Border thickness"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              placeholder="0"
              defaultValue={object.custom_formula_sectionStrokeWidth || ""}
              onChange={(value) => {
                if (value < 0) {
                  value = 0;
                }
                updateObject({
                  fieldName: "custom_formula_sectionStrokeWidth",
                  value,
                  isNumerical: true,
                });
              }}
            />
          }
        />
        <ConditionalFormatting
          object={object}
          updateObject={updateObject}
          fileTypeDetails={fileTypeDetails}
          template={template}
          form={form}
          organisationDetails={organisationDetails}
        />
      </Collapse.Panel>
    );
  }

  function displayParentTextObjectReset(object) {
    return (
      <Collapse.Panel
        header="Text objects inside"
        key="text-objects-inside"
        className="collapse-panel-text-objects-inside"
      >
        <Typography.Text className="parent-repeat-note">
          Note: setting any of the values below will set them on all the text objects inside
        </Typography.Text>
        <InfoItem
          label="Font family"
          inline
          value={
            <Select
              onChange={(value) => {
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "fontFamily",
                  value,
                });
              }}
            >
              <Select.Option value="helvetica">Helvetica</Select.Option>

              {organisationDetails.variables.items
                .filter((variable) => variable.type === "FONT")
                .map((variable) => {
                  return (
                    <Select.Option value={variable.name} key={variable.id}>
                      {variable.name}
                    </Select.Option>
                  );
                })}
            </Select>
          }
        />
        <InfoItem
          label="Font size"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              onChange={(value) => {
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "formula_fontSize",
                  value,
                  isNumerical: true,
                });
              }}
            />
          }
        />

        <InfoItem
          label="Padding top"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_paddingTop",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding bottom"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_paddingBottom",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding left"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_paddingLeft",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Padding right"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                variableName.substring(1)
              )}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_paddingRight",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />

        <InfoItem
          label="Space above"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_marginTop",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Space below"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_marginBottom",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Space to the left"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_marginLeft",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />
        <InfoItem
          label="Space to the right"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              useAutoComplete
              autoCompleteOptions={getVariables({
                organisationDetails,
              }).map((variableName) => variableName.substring(1))}
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_formula_marginRight",
                  value,
                  isNumerical: true,
                })
              }
            />
          }
        />

        <InfoItem
          label="Horizontal align"
          inline
          value={
            <Select
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "textAlign",
                  value,
                })
              }
            >
              <Select.Option value="left">Left</Select.Option>
              <Select.Option value="center">Center</Select.Option>
              <Select.Option value="right">Right</Select.Option>
            </Select>
          }
        />

        <InfoItem
          label="Vertical align"
          inline
          value={
            <Select
              placeholder="Top"
              onChange={(value) =>
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_verticalAlign",
                  value,
                })
              }
            >
              <Select.Option value="top">Top</Select.Option>
              <Select.Option value="center">Center</Select.Option>
              <Select.Option value="bottom">Bottom</Select.Option>
            </Select>
          }
        />

        <InfoItem
          label="Font color"
          inline
          value={
            <ColorPicker
              organisationDetails={organisationDetails}
              fieldName="formula_fill"
              defaultValue="#000000"
              visible={isFontColorPickerOpen}
              onVisibleChange={setIsFontColorPickerOpen}
              updateObject={(params) =>
                updateAllTextObjects({
                  ...params,
                  targetObject: object,
                })
              }
              object={object}
            />
          }
        />
        <InfoItem
          label="Opacity"
          inline
          value={
            <DraughtHubInput
              fullWidth
              showBorder
              fireOnChangeWithoutBlur
              placeholder="100"
              onChange={(value) => {
                if (value < 0) {
                  value = 0;
                }
                value = String(value);
                updateAllTextObjects({
                  targetObject: object,
                  fieldName: "custom_opacity",
                  value,
                  isNumerical: true,
                });
              }}
            />
          }
        />
        {fileTypeDetails.isDocumentTemplate && (
          <InfoItem
            label="Background color"
            inline
            value={
              <ColorPicker
                organisationDetails={organisationDetails}
                fieldName="custom_formula_textFill"
                value={object.custom_textFill}
                visible={isTextBackgroundColorPickerOpen}
                onVisibleChange={setIsTextBackgroundColorPickerOpen}
                updateObject={(params) =>
                  updateAllTextObjects({
                    ...params,
                    targetObject: object,
                  })
                }
                object={object}
              />
            }
          />
        )}
        {fileTypeDetails.isDocumentTemplate && (
          <InfoItem
            label="Border color"
            inline
            value={
              <ColorPicker
                organisationDetails={organisationDetails}
                fieldName="formula_stroke"
                value={object.stroke}
                visible={isBorderColorPickerOpen}
                onVisibleChange={setIsBorderColorPickerOpen}
                updateObject={(params) =>
                  updateAllTextObjects({
                    ...params,
                    targetObject: object,
                  })
                }
                object={object}
              />
            }
          />
        )}
        {fileTypeDetails.isDocumentTemplate && (
          <InfoItem
            label="Border thickness"
            inline
            value={
              <DraughtHubInput
                fullWidth
                showBorder
                fireOnChangeWithoutBlur
                useAutoComplete
                autoCompleteOptions={getVariables({
                  organisationDetails,
                }).map((variableName) => variableName.substring(1))}
                placeholder="1"
                onChange={(value) => {
                  if (value < 0) {
                    value = 0;
                  }
                  value = String(value);
                  updateAllTextObjects({
                    targetObject: object,
                    fieldName: "formula_strokeWidth",
                    value,
                    isNumerical: true,
                  });
                }}
              />
            }
          />
        )}
      </Collapse.Panel>
    );
  }

  function displayControlsForObjectPosition(object) {
    if (!fileTypeDetails.isDocumentTemplate) {
      let defaultValueTop = object.top !== undefined && object.top !== null ? object.top : "";
      return (
        <>
          <InfoItem
            label="Top"
            inline
            value={
              <>
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  defaultValue={defaultValueTop}
                  onChange={(value) => {
                    updateObject({
                      fieldName: "top",
                      value,
                      isNumerical: true,
                    });
                  }}
                />
              </>
            }
          />
          <InfoItem
            label="Left"
            inline
            value={
              <>
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  defaultValue={object.left !== undefined && object.left !== null ? object.left : ""}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "left",
                      value,
                      isNumerical: true,
                    })
                  }
                />
              </>
            }
          />
        </>
      );
    }
    return (
      <>
        <InfoItem
          label="Positioning"
          inline
          value={
            <Select
              defaultValue="static"
              value={object.custom_cssPosition}
              onChange={(_, option) => {
                updateObject({
                  fieldName: "custom_cssPosition",
                  value: option?.value,
                });
              }}
            >
              <Select.Option key="static" value="static">
                Relative
              </Select.Option>
              <Select.Option key="absolute" value="absolute">
                Absolute
              </Select.Option>
            </Select>
          }
        />
        {object.custom_cssPosition === "absolute" ? (
          <>
            {object.group?.custom_type === "page" && (
              <>
                <InfoItem
                  label="Is part of page borders"
                  inline
                  value={
                    <>
                      <Checkbox
                        checked={object.custom_isPageBorder}
                        onChange={(e) => {
                          updateObject({
                            fieldName: "custom_isPageBorder",
                            value: e.target.checked,
                          });
                        }}
                      />
                    </>
                  }
                />
                <InfoItem
                  label="Is part of page numbers"
                  inline
                  value={
                    <>
                      <Checkbox
                        checked={object.custom_isPageNumber}
                        onChange={(e) => {
                          updateObject({
                            fieldName: "custom_isPageNumber",
                            value: e.target.checked,
                          });
                        }}
                      />
                    </>
                  }
                />
              </>
            )}

            <InfoItem
              label="Top"
              className={cx({
                "with-error": object.custom_absoluteBottom && object.custom_absoluteTop,
              })}
              inline
              value={
                <>
                  <DraughtHubInput
                    fullWidth
                    showBorder
                    fireOnChangeWithoutBlur
                    useAutoComplete
                    autoCompleteOptions={getVariables({
                      organisationDetails,
                    }).map((variableName) => variableName.substring(1))}
                    defaultValue={object.custom_formula_absoluteTop || ""}
                    onChange={(value) =>
                      updateObject({
                        fieldName: "custom_formula_absoluteTop",
                        value,
                        isNumerical: true,
                      })
                    }
                  />
                </>
              }
            />
            <InfoItem
              label="Left"
              inline
              className={cx({
                "with-error": object.custom_absoluteLeft !== undefined && object.custom_absoluteRight !== undefined,
              })}
              value={
                <>
                  <DraughtHubInput
                    fullWidth
                    showBorder
                    fireOnChangeWithoutBlur
                    useAutoComplete
                    autoCompleteOptions={getVariables({
                      organisationDetails,
                    }).map((variableName) => variableName.substring(1))}
                    defaultValue={object.custom_formula_absoluteLeft || ""}
                    onChange={(value) =>
                      updateObject({
                        fieldName: "custom_formula_absoluteLeft",
                        value,
                        isNumerical: true,
                      })
                    }
                  />

                  {valueExists(object.custom_absoluteLeft) && valueExists(object.custom_absoluteRight) ? (
                    <Typography.Text className="validation-message">
                      "Left" is ignored. "Right" takes priority.
                    </Typography.Text>
                  ) : null}
                </>
              }
            />
          </>
        ) : (
          <>
            <InfoItem
              label="Allow content to be split between pages"
              inline
              value={
                <Checkbox
                  checked={object.custom_allowBreak !== false}
                  onChange={(e) => {
                    updateObject({
                      fieldName: "custom_allowBreak",
                      value: e.target.checked,
                    });
                  }}
                />
              }
            />
            <InfoItem
              label="Space above"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={object.custom_formula_marginTop || ""}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "custom_formula_marginTop",
                      value,
                      isNumerical: true,
                    })
                  }
                />
              }
            />
            <InfoItem
              label="Space below"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={object.custom_formula_marginBottom || ""}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "custom_formula_marginBottom",
                      value,
                      isNumerical: true,
                    })
                  }
                />
              }
            />
            <InfoItem
              label="Space to the left"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={object.custom_formula_marginLeft || ""}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "custom_formula_marginLeft",
                      value,
                      isNumerical: true,
                    })
                  }
                />
              }
            />
            <InfoItem
              label="Space to the right"
              inline
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={object.custom_formula_marginRight || ""}
                  onChange={(value) =>
                    updateObject({
                      fieldName: "custom_formula_marginRight",
                      value,
                      isNumerical: true,
                    })
                  }
                />
              }
            />
          </>
        )}
      </>
    );
  }

  function displayControlsForRectangle(object) {
    return (
      <Collapse defaultActiveKey={["position"]}>
        {displayPositionAndSizePanel(object)}
        {displayConditionalDisplayPanel(object)}

        <Collapse.Panel header="Look & feel" key="look" className="collapse-panel-look-feel">
          {displayRotationField(object)}
          <InfoItem
            label="Corner radius (bottom left)"
            inline
            value={
              <DraughtHubInput
                fullWidth
                showBorder
                fireOnChangeWithoutBlur
                placeholder="0"
                defaultValue={object.rx || ""}
                onChange={(value) => {
                  if (value < 0) {
                    value = 0;
                  }
                  value = String(value);
                  updateObject({
                    fields: {
                      rx: value,
                      ry: value,
                    },
                  });
                }}
              />
            }
          />
          {displayOpacityField(object)}
          <InfoItem
            label="Background color"
            inline
            value={
              <ColorPicker
                organisationDetails={organisationDetails}
                fieldName="formula_fill"
                value={object.fill}
                defaultValue="#ffffff"
                visible={isBackgroundColorPickerOpen}
                onVisibleChange={setIsBackgroundColorPickerOpen}
                updateObject={(params) => updateObject({ ...params })}
                object={object}
              />
            }
          />
          <InfoItem
            label="Border color"
            inline
            value={
              <ColorPicker
                organisationDetails={organisationDetails}
                fieldName="formula_stroke"
                value={object.stroke}
                defaultValue="#000000"
                visible={isBorderColorPickerOpen}
                onVisibleChange={setIsBorderColorPickerOpen}
                updateObject={(params) => updateObject({ ...params })}
                object={object}
              />
            }
          />
          <InfoItem
            label="Border thickness"
            inline
            value={
              <DraughtHubInput
                fullWidth
                showBorder
                fireOnChangeWithoutBlur
                useAutoComplete
                autoCompleteOptions={getVariables({ organisationDetails }).map((variableName) =>
                  variableName.substring(1)
                )}
                placeholder="1"
                defaultValue={object.formula_strokeWidth || ""}
                onChange={(value) => {
                  if (value < 0) {
                    value = 0;
                  }
                  value = String(value);
                  updateObject({
                    fieldName: "formula_strokeWidth",
                    value,
                    isNumerical: true,
                  });
                }}
              />
            }
          />
          <ConditionalFormatting
            object={object}
            updateObject={updateObject}
            fileTypeDetails={fileTypeDetails}
            template={template}
            form={form}
            organisationDetails={organisationDetails}
          />
        </Collapse.Panel>
        {displayDuplicateAndDeletePanel(object)}
        {displayDebugInformation(object)}
      </Collapse>
    );
  }

  function displayControlsForQRCode(object) {
    return (
      <Collapse defaultActiveKey={["position"]}>
        {displayPositionAndSizePanel(object)}
        {displayConditionalDisplayPanel(object)}

        {displayDuplicateAndDeletePanel(object)}
        {displayDebugInformation(object)}
      </Collapse>
    );
  }

  function calculateRepeatOffset({ object, axis, spacing = 0, newFields = {} }) {
    let width = object.width || 0;
    let height = object.height || 0;
    spacing = spacing || 0;

    if (axis === "UP") {
      newFields.custom_repeatOffsetX = 0;
      newFields.custom_repeatOffsetY = -height - spacing;
    } else if (axis === "DOWN") {
      newFields.custom_repeatOffsetX = 0;
      newFields.custom_repeatOffsetY = height + spacing;
    } else if (axis === "LEFT") {
      newFields.custom_repeatOffsetY = 0;
      newFields.custom_repeatOffsetX = -width - spacing;
    } else if (axis === "RIGHT") {
      newFields.custom_repeatOffsetY = 0;
      newFields.custom_repeatOffsetX = width + spacing;
    }

    updateObject({
      fields: newFields,
    });
  }

  function displayControlsForGroup(object) {
    return (
      <Collapse defaultActiveKey={["repeat"]}>
        <Collapse.Panel header="Migration checkbox" key="migration-checkbox">
          <InfoItem
            label="Is using old system"
            inline
            value={
              <Checkbox
                checked={
                  object.custom_isUsingOldSystem === undefined ||
                  object.custom_isUsingOldSystem === null ||
                  object.custom_isUsingOldSystem
                }
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_isUsingOldSystem",
                    value: e.target.checked,
                  });
                }}
              />
            }
          />
        </Collapse.Panel>
        <Collapse.Panel header="Position" key="position">
          {displayControlsForObjectPosition(object)}
        </Collapse.Panel>
        {displayConditionalDisplayPanel(object)}
        {displayRepeatForPanel(object)}
        <Collapse.Panel header="Ungroup & delete" key="ungroup-delete" className="collapse-panel-duplicate-delete">
          {/* <InfoItem
            value={
              <Button
                type="primary"
                disabled
                onClick={() => {
                  // canvas.getActiveObject().toActiveSelection();
                  // canvas.getObjects().forEach((object) => {
                  //   if (object.evented && object.type !== "group") {
                  //     object.set("hasControls", true);
                  //   }
                  // });
                  // canvas.requestRenderAll();
                  
                }}
              >
                Ungroup
              </Button>
            }
          /> */}
          <InfoItem
            value={
              <Button icon={<DeleteOutlined />} onClick={onDeleteClick}>
                Delete group
              </Button>
            }
          />
        </Collapse.Panel>
      </Collapse>
    );
  }

  function onChangeParameterValue({
    parameterId,
    value,
    parameterFieldName,
    fieldName,
    oldParameterValues,
    updateFunction,
  }) {
    if (!oldParameterValues) {
      oldParameterValues = {};
    }

    let newParameterValues = JSON.parse(JSON.stringify(oldParameterValues));
    if (!newParameterValues[parameterId]) {
      newParameterValues[parameterId] = {};
    }
    newParameterValues[parameterId][parameterFieldName] = value;
    if (updateFunction) {
      updateFunction({
        fieldName,
        value: JSON.stringify(newParameterValues),
      });
    } else {
      updateObject({
        fieldName,
        value: JSON.stringify(newParameterValues),
      });
    }
  }

  function displayParametersForObject({ fieldName, object, parameters, updateFunction }) {
    if (!parameters) {
      return null;
    }

    let parameterValues;
    try {
      parameterValues = object[fieldName] ? JSON.parse(object[fieldName]) : {};
    } catch (e) {
      parameterValues = {};
    }

    return (
      <div className="object-parameter-list">
        {parameters.map((parameter, index) => {
          let parameterValue = parameterValues[parameter.id];

          let fieldOptions;

          if (parameterValue?.dataSource) {
            fieldOptions = getFieldsForEditorByDataSource({
              dataSource: parameterValue?.dataSource,
              form,
              repeatFor: getRepeatFor(object),
              organisationDetails,
            });
          }

          return (
            <div className="parameter-item" key={index}>
              <InfoItem
                label={`${parameter.label} - data source`}
                value={
                  <Select
                    allowClear
                    value={parameterValue?.dataSource}
                    placeholder="No option selected"
                    showSearch
                    filterOption={(searchValue, { label }) => {
                      return label?.toLowerCase().includes(searchValue?.toLowerCase());
                    }}
                    onChange={(_, option) => {
                      onChangeParameterValue({
                        parameterId: parameter.id,
                        value: option?.value,
                        parameterFieldName: "dataSource",
                        fieldName,
                        oldParameterValues: parameterValues,
                        updateFunction,
                      });
                    }}
                  >
                    {fileTypeDetails.dataSources.map((dataSource) => {
                      return (
                        <Select.Option
                          key={dataSource}
                          value={dataSource}
                          label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                        >
                          {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                        </Select.Option>
                      );
                    })}
                  </Select>
                }
              />
              {parameterValue?.dataSource && (
                <InfoItem
                  label={`${parameter.label} - field`}
                  value={
                    <Select
                      allowClear
                      value={parameterValue?.field}
                      placeholder="No option selected"
                      showSearch
                      filterOption={(searchValue, { label }) => {
                        return label?.toLowerCase().includes(searchValue?.toLowerCase());
                      }}
                      onChange={(_, option) => {
                        onChangeParameterValue({
                          parameterId: parameter.id,
                          value: option?.value,
                          parameterFieldName: "field",
                          fieldName,
                          oldParameterValues: parameterValues,
                          updateFunction,
                        });
                      }}
                    >
                      {fieldOptions.map((option) => {
                        return (
                          <Select.Option key={option.value} value={option.value} label={option.label}>
                            {option.label}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  }
                />
              )}

              {parameterValue?.dataSource === "general" && parameterValue.field === "manualInput" && (
                <InfoItem
                  label="Value"
                  value={
                    <Input
                      defaultValue={parameterValue.custom_manualInput}
                      onChange={(e) => {
                        onChangeParameterValue({
                          parameterId: parameter.id,
                          value: e.target.value,
                          parameterFieldName: "custom_manualInput",
                          fieldName,
                          oldParameterValues: parameterValues,
                        });
                      }}
                    />
                  }
                />
              )}
            </div>
          );
        })}
      </div>
    );
  }

  function displayRepeatForPanel(object) {
    const parentRepeatForField = getParentRepeatForField({ object, form, organisationDetails });

    let fieldOptionsForRepeatFor;
    let repeatForFieldDetails;

    if (object.custom_repeatForDataSource) {
      fieldOptionsForRepeatFor = getFieldsForEditorByDataSource({
        dataSource: object.custom_repeatForDataSource,
        form,
        parentRepeat: parentRepeatForField,
        fieldType: "repeatFor",
        organisationDetails,
      });

      repeatForFieldDetails = fieldOptionsForRepeatFor.find((option) => option.value === object.custom_repeatFor);
    }

    return (
      <Collapse.Panel header="Repeat" key="repeat">
        {displayNoteAboutParentRepeat({ object, form, organisationDetails })}
        <InfoItem
          label="Repeat for - data source"
          value={
            <Select
              allowClear
              value={object.custom_repeatForDataSource}
              placeholder="No option selected"
              showSearch
              filterOption={(searchValue, { label }) => {
                return label?.toLowerCase().includes(searchValue?.toLowerCase());
              }}
              onChange={(_, option) => {
                updateObject({
                  fields: {
                    custom_repeatForDataSource: option?.value,
                    custom_repeatFor: undefined,
                    custom_repeatForDataSourceOverride: undefined,
                  },
                });
              }}
            >
              {fileTypeDetails.dataSources.map((dataSource) => {
                return (
                  <Select.Option key={dataSource} value={dataSource} label={DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}>
                    {DOCUMENT_EDITOR_DATA_SOURCES[dataSource]}
                  </Select.Option>
                );
              })}
            </Select>
          }
        />

        <DataSourceOverrideOptions
          object={object}
          dataSourceFieldName="custom_repeatForDataSource"
          dataSourceOverrideFieldName="custom_repeatForDataSourceOverride"
          fileTypeDetails={fileTypeDetails}
          form={form}
          organisationDetails={organisationDetails}
          onChange={({ dataSourceOverrideFieldName, value }) => {
            updateObject({
              fields: {
                [dataSourceOverrideFieldName]: value,
              },
            });
          }}
        />

        {object.custom_repeatForDataSource && (
          <>
            <InfoItem
              label="Repeat for - field"
              value={
                <Select
                  allowClear
                  value={object.custom_repeatFor}
                  placeholder="No option selected"
                  showSearch
                  filterOption={(searchValue, { label }) => {
                    return label?.toLowerCase().includes(searchValue?.toLowerCase());
                  }}
                  onChange={(_, option) => {
                    updateObject({
                      fieldName: "custom_repeatFor",
                      value: option?.value,
                    });
                  }}
                >
                  {fieldOptionsForRepeatFor.map((option) => {
                    return (
                      <Select.Option key={option.value} value={option.value} label={option.label}>
                        {option.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              }
            />
            {displayParametersForObject({
              fieldName: "custom_repeatForParameters",
              object,
              parameters: repeatForFieldDetails?.parameters,
            })}
          </>
        )}

        {!fileTypeDetails.isDocumentTemplate && object.custom_repeatFor && (
          <>
            <InfoItem
              label="Repeat direction"
              value={
                <Select
                  value={object.custom_repeatAxis}
                  onChange={(_, option) => {
                    calculateRepeatOffset({
                      object,
                      axis: option.value,
                      spacing: object.custom_repeatSpacing || 0,
                      newFields: {
                        custom_repeatAxis: option.value,
                      },
                    });
                  }}
                >
                  <Select.Option key="UP" value="UP">
                    Up
                  </Select.Option>
                  <Select.Option key="DOWN" value="DOWN">
                    Down
                  </Select.Option>
                  <Select.Option key="LEFT" value="LEFT">
                    Left
                  </Select.Option>
                  <Select.Option key="RIGHT" value="RIGHT">
                    Right
                  </Select.Option>
                </Select>
              }
            />
          </>
        )}
        {!fileTypeDetails.isDocumentTemplate && object.custom_repeatFor && (
          <>
            <InfoItem
              label="Repeat spacing"
              value={
                <DraughtHubInput
                  fullWidth
                  showBorder
                  fireOnChangeWithoutBlur
                  useAutoComplete
                  autoCompleteOptions={getVariables({
                    organisationDetails,
                  }).map((variableName) => variableName.substring(1))}
                  defaultValue={object.custom_repeatSpacing || 0}
                  onChange={(value) => {
                    calculateRepeatOffset({
                      object,
                      axis: object.custom_repeatAxis,
                      spacing: parseFloat(value) || 0,
                      newFields: {
                        custom_repeatSpacing: value,
                      },
                    });
                  }}
                />
              }
            />
          </>
        )}
      </Collapse.Panel>
    );
  }

  function displaySingleObjectControls(object) {
    let readableObjectTypes = {
      rect: "Rectangle",
      text: "Text",
    };
    let title = readableObjectTypes[object.type] || object.type;

    switch (object.custom_type) {
      case "chapter":
        title = "Chapter";
        break;
      case "page":
        title = "Page";
        break;
      case "section":
        if (fileTypeDetails.isDocumentTemplate) {
          title = "Page section";
        } else {
          title = "Group";
        }
        break;

      case "image_container":
        title = "Dynamic Image";
        break;
      case "signature":
        title = "Signature";
        break;
      case "dynamic_file":
        title = "Dynamic file";
        break;
      case "image":
        title = "Static Image";
        break;

      case "component":
        title = "Component";
        break;
      default:
        break;
    }

    let content = null;

    switch (object.custom_type) {
      case "image":
        content = displayControlsForStaticImage(object);
        break;
      case "section":
        if (fileTypeDetails.isDocumentTemplate) {
          content = displayControlsForSection(object);
        } else {
          content = displayControlsForGroup(object);
        }
        break;
      case "chapter":
        content = displayControlsForChapter(object);
        break;
      case "page":
        content = displayControlsForPage(object);
        break;
      case "image_container":
      case "signature":
      case "dynamic_file":
        content = displayControlsForDynamicImage(object);
        break;
      case "component":
        content = displayControlsForComponent(object);
        break;
      case "qr-code":
        content = displayControlsForQRCode(object);
        break;
      default:
        switch (object.type) {
          case "text":
            content = displayControlsForText(object);
            break;
          case "group":
            content = displayControlsForGroup(object);
            break;
          default:
            content = displayControlsForRectangle(object);
            break;
        }
    }

    return (
      <div className="section">
        <Typography.Text className="title">
          {title}
          {object.isLiveCopyOf ? (
            <>
              <br />
              <Tag color="green">Live copy</Tag>
            </>
          ) : null}
        </Typography.Text>
        <InfoItem
          label="Name"
          inline
          value={
            <Input
              defaultValue={object.custom_name}
              onChange={(e) =>
                updateObject({
                  fieldName: "custom_name",
                  value: e.target.value,
                })
              }
            />
          }
        />
        {isSpreadsheet && object.custom_type === "section" && (
          <InfoItem
            label="Is spreadsheet row"
            inline
            value={
              <Checkbox
                checked={object.custom_isSpreadsheetRow}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_isSpreadsheetRow",
                    value: e.target.checked,
                  });
                }}
              />
            }
          />
        )}
        {isSpreadsheet && object.type === "text" && (
          <InfoItem
            label="Is spreadsheet name"
            inline
            value={
              <Checkbox
                checked={object.custom_isSpreadsheetName}
                onChange={(e) => {
                  updateObject({
                    fieldName: "custom_isSpreadsheetName",
                    value: e.target.checked,
                  });
                }}
              />
            }
          />
        )}

        {content}
      </div>
    );
  }

  function displayBringToFrontSendToBack(object) {
    return (
      <>
        <InfoItem
          className="bring-to-front-send-to-back"
          value={
            <>
              <Button
                onClick={async () => {
                  if (isReadOnly) {
                    message.error("You cannot edit a read-only template");
                    return;
                  }

                  changeObjectOrder({
                    childId: object.custom_id,
                    newRelativePosition: 1,
                  });
                }}
              >
                Bring forward
              </Button>
              <Button
                onClick={async () => {
                  if (isReadOnly) {
                    message.error("You cannot edit a read-only template");
                    return;
                  }

                  changeObjectOrder({
                    childId: object.custom_id,
                    newRelativePosition: -1,
                  });
                }}
              >
                Push backward
              </Button>
            </>
          }
        />
      </>
    );
  }

  function displayMultipleObjectControls() {
    return (
      <div className="section">
        <Typography.Text className="title">{selectedObjects.length} objects selected</Typography.Text>
        <InfoItem
          value={
            <Button
              type="primary"
              onClick={() => {
                // let newGroup = canvas.getActiveObject().toGroup();
                // let counterName = "custom_groupCount";
                // let counter = (canvas[counterName] || 0) + 1;
                // canvas.set(counterName, counter);
                // newGroup.set({
                //   custom_name: `Group${counter}`,
                //   custom_index: counter,
                //   custom_id: `${Date.now()}-${Math.random()}`,
                //   hasControls: false,
                //   originX: "left",
                //   originY: "top",
                // });
                // canvas.requestRenderAll();
              }}
            >
              Create group
            </Button>
          }
        />
      </div>
    );
  }

  return (
    <div className="object-panel panel" style={{ width: panelWidth }}>
      <div className="panel-contents">
        {selectedObjects.length === 1
          ? displaySingleObjectControls(selectedObjects[0])
          : displayMultipleObjectControls()}
      </div>
      <div
        className="border-drag"
        onMouseDown={(e) => {
          setIsDraggingWidth(true);
          setDragStartX(e.screenX);
          setPanelWidth((panelWidth) => {
            setDragStartWidth(() => {
              return panelWidth;
            });
            return panelWidth;
          });
        }}
      />
    </div>
  );
}

function ColorPicker({
  organisationDetails,
  value,
  defaultValue,
  fieldName,
  object,
  updateObject,
  visible,
  onVisibleChange,
}) {
  let parsedValue = value || defaultValue;
  let formulaValue = object[fieldName] || defaultValue;

  if (formulaValue === "null") {
    formulaValue = "";
  }

  return (
    <div className="color-input-container">
      <Popover
        content={
          <div className="color-picker-overlay">
            <HexColorPicker color={parsedValue} onChange={(newColor) => updateObject({ fieldName, value: newColor })} />
          </div>
        }
        placement="bottomLeft"
        trigger="click"
        open={visible}
        onOpenChange={onVisibleChange}
      >
        <DraughtHubInput
          prefix={
            <div
              className="color-preview"
              style={{ backgroundColor: parsedValue }}
              onClick={(e) => onVisibleChange(!visible)}
            />
          }
          fullWidth
          showBorder
          fireOnChangeWithoutBlur
          useAutoComplete
          autoCompleteOptions={getVariables({
            organisationDetails,
            type: "COLOR",
          }).map((variableName) => variableName.substring(1))}
          defaultValue={formulaValue}
          onChange={(inputValue) => {
            updateObject({ fieldName, value: inputValue });
          }}
        />
      </Popover>
    </div>
  );
}

export default withSubscriptions({
  Component: ObjectPanel,
});
