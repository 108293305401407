import { useState } from "react";
import { Tooltip, Mentions, Button, Tag } from "antd";
import cx from "classnames";
import { UpOutlined, QuestionCircleFilled } from "@ant-design/icons";

import { sendNotificationsToMentionedPeople } from "common/mentionHelpers";
import { processIdForDisplay } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import "./ReviewCommentBox.scss";

export default function ReviewCommentBox({ onSubmitComment, taskRevision, isReply, users, apiUser, task }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [commentValue, setCommentValue] = useState("");
  async function onSubmit(commentBody) {
    setCommentValue("");
    sendNotificationsToMentionedPeople({
      textToSearch: commentBody,
      mentionOptions: users.map((x) => x.id),
      users,
      notificationText: `${apiUser.firstName} ${apiUser.lastName} mentioned you in a review for ${getSimpleLabel(
        "task"
      )} ${task.project?.title} ${task.title} (${processIdForDisplay(task.id)})`,
      link: `${window.location.pathname}${window.location.search}`,
      apiUser,
    });
    onSubmitComment(commentBody);
  }

  function onKeyUp(e) {
    if (
      (e.key === "Enter" && e.shiftKey === true) ||
      (e.key === "Enter" && ((window.isMac && e.metaKey) || (!window.isMac && e.ctrlKey)))
    ) {
      onSubmit(commentValue);
    }
  }

  return (
    <div className={cx("review-comment-box", { "reply-box": isReply, "is-expanded": isExpanded })} onKeyUp={onKeyUp}>
      {!isReply && (
        <Button
          type="clear"
          className="expand-collapse-button"
          icon={<UpOutlined />}
          onClick={() => setIsExpanded((oldIsExpanded) => !oldIsExpanded)}
        />
      )}

      <Mentions
        onChange={(value) => setCommentValue(value)}
        value={commentValue}
        placeholder={isReply ? "Type your reply" : "Leave a comment"}
      >
        {users.map((user, index) => (
          <Mentions.Option value={user.id} key={`${user.id}-${index}`}>
            {user.firstName} {user.lastName}
          </Mentions.Option>
        ))}
      </Mentions>

      <div className="buttons-container">
        <Tooltip
          overlayClassName="review-comment-box-send-tooltip"
          title={
            <>
              <Tag color="white" style={{ marginBottom: "0.3rem" }}>
                <b>Enter</b>
              </Tag>
              adds a new line. <br />
              <Tag color="white">
                <b>Shift</b> + <b>Enter</b>
              </Tag>
              or
              <Tag color="white" style={{ marginLeft: "0.5rem" }}>
                <b>{window.isMac ? "Cmd" : "Ctrl"}</b> + <b>Enter</b>
              </Tag>
              sends the message.
              <br />
              <br />
              The "Send" button is disabled when the message is empty.
            </>
          }
          placement="leftBottom"
        >
          <QuestionCircleFilled className="tooltip-icon" />
        </Tooltip>
        <Button
          type="primary"
          className={cx("comment", {
            disabled: taskRevision.reviewStatus === "CLOSED",
          })}
          onClick={() => {
            onSubmit(commentValue);
          }}
          disabled={taskRevision.reviewStatus === "CLOSED" || commentValue.length === 0}
        >
          {isReply ? "Send" : "Send"}
        </Button>
      </div>
    </div>
  );
}
