import { useEffect, useState } from "react";
import {
  Alert,
  Form,
  Modal,
  Button,
  Input,
  Checkbox,
  InputNumber,
  message,
  AutoComplete,
  Select,
  Typography,
  Spin,
  Tag,
} from "antd";
import moment from "moment";
import { Link } from "react-router-dom";
import cx from "classnames";
import { EyeOutlined, CloseOutlined } from "@ant-design/icons";

import { TIMELINE_PSEUDO_TASKS } from "common/constants";
import { processIdForDisplay, useForceUpdate } from "common/helpers";
import { getFeesForClient } from "common/feeHelpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";
import { getSimpleLabel } from "common/labels";
import { CloseIcon } from "common/icons";
import { Task } from "common/types";

// import Explanation from "Explanation/Explanation";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import TaskPicker from "TaskPicker/TaskPicker";
import TimePicker from "TimePicker/TimePicker";

import "./TimesheetBlockModal.scss";

type Props = {
  onClose?: any;
  onCreate?: any;
  onEdit?: any;
  timesheetBlock?: any;
  isNew?: boolean;
  tasks: any;
  viewerIsOwner?: boolean;
  targetDate: any;
  organisationDetails: any;
  user: any;
  quotes: any;
  timesheetTags: any;
  clients: any;
  isUsingClockInClockOut: boolean;
};

export default function TimesheetBlockModal({
  onClose,
  onCreate,
  onEdit,
  timesheetBlock,
  isNew,
  tasks,
  viewerIsOwner,
  targetDate,
  organisationDetails,
  user,
  timesheetTags,
  clients,
  isUsingClockInClockOut,
  quotes,
}: Props) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [taskIdForPreview, setTaskIdForPreview] = useState();
  const [quoteLineItems, setQuoteLineItems] = useState();
  const [realTaskDetails, setRealTaskDetails] = useState<Task | undefined>(undefined);
  const [invoiceDetails, setInvoiceDetails] = useState<any>(undefined);
  const [isLoadingTaskDetails, setIsLoadingTaskDetails] = useState(true);
  const [isTaskPickerSelected, setIsTaskPickerSelected] = useState(false);
  const forceUpdate = useForceUpdate();

  let timesheetBlockIsApproved =
    organisationDetails.settings?.timesheet?.usesReview && timesheetBlock?.reviewStatus === "APPROVED";

  let quoteLineItemId = form.getFieldValue("quoteLineItemId");
  let quote = quoteLineItemId ? quotes.find((x) => x.id === quoteLineItemId) : undefined;
  let client = realTaskDetails ? clients.find((x) => x.id === (realTaskDetails as any).clientId) : undefined;

  let feesData = getFeesForClient({
    organisationDetails,
    quote,
    clientDetails: client,
    currency: "GBP",
  });

  const isLinkedToAnApprovedInvoice = invoiceDetails?.reviewStatus === "SUCCESS";

  async function onRemoveFromInvoiceClick() {
    Modal.confirm({
      title: "Are you sure you want to remove this timesheet block from the invoice?",
      content: "This will not delete the timesheet block, it will just remove it from the invoice.",
      onOk: async () => {
        if (onEdit && typeof onEdit === "function") {
          await onEdit({ ...timesheetBlock, invoiceId: "nothing", invoicingStatus: null });
          await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
        }
      },
    });
  }

  async function getTaskDetails(taskId) {
    setIsLoadingTaskDetails(false);
    if (!taskId || taskId === "nothing") {
      setQuoteLineItems(undefined);
      setRealTaskDetails(undefined);
      return;
    }

    setIsLoadingTaskDetails(true);
    const taskDetails = (
      await window.callGraphQLSimple({
        message: `Failed to fetch ${getSimpleLabel("task")} details`,
        queryCustom: "getTaskWithFilesAndQuoteLineItems",
        variables: {
          id: taskId,
        },
      })
    ).data.getTask;
    if (taskDetails && timesheetBlock.extraDetails && timesheetBlock.extraDetails.fileId) {
      for (let taskRevision of taskDetails.revisions.items) {
        for (let file of taskRevision.files.items) {
          if (file.id === timesheetBlock.extraDetails.fileId) {
            form.setFieldsValue({
              taskRevisionId: taskRevision.id,
            });
            break;
          }
        }
      }
    }

    setIsLoadingTaskDetails(false);

    setRealTaskDetails(taskDetails);

    let quoteLineItems;
    if (taskDetails) {
      quoteLineItems = [...taskDetails.quoteLineItems.items].sort((a, b) => {
        return a.createdAt > b.createdAt ? -1 : 1;
      });

      if (quoteLineItems.length === 0) {
        const cat2CheckSubjectLink = taskDetails.linkedTasks?.find((x) => x.relationship === "CAT_2_CHECK_FOR");
        const cat2CheckSubjectTaskId = cat2CheckSubjectLink && cat2CheckSubjectLink.taskId;
        if (cat2CheckSubjectTaskId) {
          const cat2CheckSubject = (
            await window.callGraphQLSimple({
              message: `Failed to fetch Cat 2 check ${getSimpleLabel("task")} details`,
              queryCustom: "getTaskSimple",
              variables: {
                id: cat2CheckSubjectTaskId,
              },
            })
          ).data.getTask;
          quoteLineItems = cat2CheckSubject.quoteLineItems.items;
        }
      }
      setQuoteLineItems(quoteLineItems);

      let matchingQuoteLineItemInTask = quoteLineItems.find((x) => x.id === form.getFieldValue("quoteLineItemId"));
      if (!matchingQuoteLineItemInTask) {
        let autoSelectedQuoteLineItemId = quoteLineItems[0]?.id;

        let newBillable = form.getFieldValue("billable");

        if (autoSelectedQuoteLineItemId) {
          let quoteLineItem = quoteLineItems.find((x) => x.id === autoSelectedQuoteLineItemId);

          if (quoteLineItem) {
            if (quoteLineItem.isRejected || (quote?.status === "REJECTED" && newBillable)) {
              newBillable = false;
              message.warning(
                `The selected ${getSimpleLabel(
                  "quote"
                )} line item is rejected, so the timesheet block has been marked as non-billable.`,
                7
              );
            }
          }
        }

        form.setFieldsValue({
          quoteLineItemId: autoSelectedQuoteLineItemId,
          billable: newBillable,
        });
      }
    } else {
      setQuoteLineItems(undefined);
      form.setFieldsValue({
        quoteLineItemId: undefined,
      });
    }

    forceUpdate();
  }
  useEffect(() => {
    setTimeout(async () => {
      forceUpdate();

      getTaskDetails(timesheetBlock?.taskId);

      if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
        const invoice = (
          await window.callGraphQLSimple({
            message: "Failed to fetch invoice details",
            queryCustom: "getInvoice",
            variables: {
              id: timesheetBlock.invoiceId,
            },
          })
        ).data.getInvoice;

        setInvoiceDetails(invoice);
      }
    }, 100);
  }, []); // eslint-disable-line

  let taskId = form.getFieldValue("taskId");
  let task = taskId ? tasks.find((x) => x.id === taskId) : undefined;

  let initialTask = tasks.find((x) => x.id === timesheetBlock.taskId);

  let title: any = "Activity details";
  if (viewerIsOwner) {
    if (isNew) {
      title = "Record activity";
    } else {
      title = "Edit activity";
    }
  }
  if (task) {
    title += ` - ${task.title}`;
  }

  if (realTaskDetails) {
    title = (
      <>
        {title}{" "}
        <Button
          type="primary"
          icon={<EyeOutlined />}
          onClick={() => setTaskIdForPreview((realTaskDetails as any).id)}
          className="see-task-details-button"
        >
          See task details
        </Button>
      </>
    );
  }

  async function onSubmit(params) {
    let { years, months, date } = targetDate.toObject();

    if (isUsingClockInClockOut) {
      params.billable = false;
      params.isRecording = true;
      params.startAt = moment();
    }

    if (
      organisationDetails.settings?.timesheet?.canAssignTimesheetBlocksToTaskRevisions &&
      realTaskDetails &&
      !params.taskRevisionId
    ) {
      message.error(
        `If you choose a real ${getSimpleLabel("task")}, you must also choose a ${getSimpleLabel("task revision")}`
      );
      return;
    }

    if (!isUsingClockInClockOut && !realTaskDetails && !params.pseudoTaskId) {
      message.error(`You must choose either a real ${getSimpleLabel("task")} or a special ${getSimpleLabel("task")}`);
      return;
    }

    if (!isUsingClockInClockOut) {
      params.startAt.set({ years, months, date });
      params.endAt.set({ years, months, date });
    }

    if (params.endAt.isBefore(params.startAt)) {
      let placeholder = params.endAt;
      params.endAt = params.startAt;
      params.startAt = placeholder;
    }

    if (params.startAt.format("DD-MM-YYYY HH:mm") === params.endAt.format("DD-MM-YYYY HH:mm")) {
      message.error("Start and end times cannot be the same");
      return;
    }

    if (realTaskDetails) {
      params.projectId = (realTaskDetails as any).projectId;
      params.clientId = (realTaskDetails as any).clientId;
      if (params.quoteLineItemId) {
        let quoteLineItem = (quoteLineItems as any)?.find((x) => x.id === params.quoteLineItemId);
        if (quoteLineItem) {
          params.quoteId = quoteLineItem.quoteId;
        } else {
          params.quoteId = undefined;
        }
      }
    } else {
      params.projectId = "nothing";
      params.clientId = "nothing";
      if (params.billable && organisationDetails.settings?.invoice?.usesInvoices) {
        message.error(`Billable work must be assigned to a real ${getSimpleLabel("task")}`);
        return;
      }
    }

    if (params.billable) {
      const requiresQuoteLineItemOrVariation: boolean =
        organisationDetails.settings?.timesheet?.requireQuoteOrVariationForBillableTimesheetBlocks;
      const meetsRequirements: boolean = !!params.quoteLineItemId || !!params.variation;

      if (requiresQuoteLineItemOrVariation && !meetsRequirements) {
        message.error(
          `Billable work must have an associated ${getSimpleLabel("quote")} line item or be marked as a variation.`
        );
        return;
      }
    }

    if (params.pseudoTaskId) {
      params.taskId = params.pseudoTaskId;
    }

    delete params.pseudoTaskId;

    if (params.startAt.isAfter(params.endAt)) {
      message.error("Start time cannot be later than end time");
      return;
    }
    setIsLoading(true);

    if (isNew) {
      if (onCreate && typeof onCreate === "function") {
        await onCreate({ ...timesheetBlock, ...params });
      }
    } else {
      if (onEdit && typeof onEdit === "function") {
        await onEdit({ ...timesheetBlock, ...params });
      }
    }
    setIsLoading(false);
  }

  return (
    <Modal
      maskClosable={true}
      closeIcon={<CloseIcon />}
      title={title}
      open={true}
      onCancel={onClose}
      footer={null}
      className={cx("timesheet-block-modal", "full-screen-on-mobile", {
        "no-scroll": isTaskPickerSelected,
      })}
    >
      {isLinkedToAnApprovedInvoice ? (
        <Alert
          type="info"
          message={"This timesheet block is linked to an already-approved invoice, so you cannot edit it."}
          style={{ marginBottom: "1rem", marginTop: "-0.5rem" }}
        />
      ) : null}
      {timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing" && !invoiceDetails ? (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "3rem",
            marginBottom: "3rem",
          }}
        >
          <Spin />
        </div>
      ) : (
        <Form
          form={form}
          layout="vertical"
          disabled={isLinkedToAnApprovedInvoice}
          initialValues={{
            ...timesheetBlock,
            tags: timesheetBlock.tags || undefined,
            taskId: timesheetBlock.taskId !== "nothing" && initialTask ? timesheetBlock.taskId : "",
            pseudoTaskId: timesheetBlock.taskId !== "nothing" && !initialTask ? timesheetBlock.taskId : "",
            billable: isNew ? true : timesheetBlock.billable,
            variation: isNew ? false : timesheetBlock.variation,
            onSite: isNew ? false : timesheetBlock.onSite,
            feeRole: isNew ? user?.feeRoles && user?.feeRoles[0] : timesheetBlock.feeRole,
          }}
          onFinish={!viewerIsOwner ? undefined : onSubmit}
        >
          {timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing" && (
            <Form.Item label="Invoice" name="invoiceId">
              <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                <Link to={`/invoices/${timesheetBlock.invoiceId}`}>
                  <Tag className="dark-tag">{processIdForDisplay(timesheetBlock.invoiceId)}</Tag>
                </Link>
                <Button icon={<CloseOutlined />} onClick={() => onRemoveFromInvoiceClick()}>
                  Remove from invoice
                </Button>
              </div>
            </Form.Item>
          )}
          <Form.Item
            label="Task"
            name="taskId"
            dependencies={["pseudoTaskId"]}
            hidden={isUsingClockInClockOut}
            rules={[
              {
                validator: async (_, taskId) => {
                  if (!taskId) {
                    return;
                  }
                  if (!form.getFieldValue("taskId")) {
                    return;
                  }

                  if (form.getFieldValue("taskId") && form.getFieldValue("pseudoTaskId")) {
                    throw new Error(
                      `You cannot choose both a real ${getSimpleLabel("task")} and a special ${getSimpleLabel(
                        "task"
                      )} at the same time`
                    );
                  }
                },
              },
            ]}
          >
            <TaskPicker
              includeProjectName={true}
              includeTaskId={true}
              value={form.getFieldValue("taskId")}
              onSearch={() => {
                form.setFieldsValue({ taskId: "" });
                forceUpdate();
              }}
              allowClear={true}
              onChange={(taskId) => {
                form.setFieldsValue({ taskId });
                forceUpdate();
                getTaskDetails(taskId);
              }}
              disabled={!viewerIsOwner}
              onDropdownVisibleChange={(isOpen) => {
                setIsTaskPickerSelected(isOpen);
              }}
              listHeight={window.innerHeight > 600 ? 400 : 250}
            />
          </Form.Item>
          {organisationDetails.settings?.timesheet?.canAssignTimesheetBlocksToTaskRevisions && (
            <Form.Item label={`Choose a ${getSimpleLabel("task revision")}: `} name="taskRevisionId">
              <Select disabled={!realTaskDetails} allowClear>
                {realTaskDetails?.revisions?.items?.map((revision) => {
                  return (
                    <Select.Option key={revision?.id} value={revision?.id}>
                      {revision?.name}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}
          {organisationDetails.settings?.quote?.usesQuotes && (
            <Form.Item
              label={`Choose a ${getSimpleLabel("quote")} line item: `}
              name="quoteLineItemId"
              hidden={isUsingClockInClockOut}
            >
              <Select className="quote-line-item-picker-dropdown" disabled={!viewerIsOwner} allowClear>
                {(quoteLineItems as any)?.map((quoteLineItem, index) => {
                  return (
                    <Select.Option value={quoteLineItem.id} key={index}>
                      <div>
                        {processIdForDisplay(quoteLineItem.quoteId)} - {quoteLineItem.title}
                        {(quote?.status === "REJECTED" || quoteLineItem?.isRejected) && (
                          <Tag color="#ff4d4f" style={{ marginLeft: "0.5rem" }}>
                            Rejected
                          </Tag>
                        )}
                      </div>
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
          )}

          <Form.Item
            label={<>Special {getSimpleLabel("task")}</>}
            name="pseudoTaskId"
            dependencies={["taskId"]}
            hidden={isUsingClockInClockOut}
            rules={[
              {
                validator: async (_, pseudoTaskId) => {
                  if (!pseudoTaskId) {
                    return;
                  }
                  if (!form.getFieldValue("pseudoTaskId")) {
                    return;
                  }

                  if (form.getFieldValue("pseudoTaskId") && form.getFieldValue("taskId")) {
                    throw new Error(
                      `You cannot choose a ${getSimpleLabel("task")} and a special ${getSimpleLabel(
                        "task"
                      )} at the same time`
                    );
                  }
                },
              },
            ]}
          >
            <AutoComplete
              options={TIMELINE_PSEUDO_TASKS.map((pseudoTask) => {
                return {
                  label: pseudoTask,
                  value: pseudoTask,
                };
              })}
              value={form.getFieldValue("pseudoTaskId")}
            />
          </Form.Item>

          <Form.Item
            label="Description"
            name="description"
            hidden={isUsingClockInClockOut}
            rules={[
              {
                required: organisationDetails.settings?.timesheet?.isDescriptionMandatory,
                message: "You must provide a description",
              },
            ]}
          >
            <Input.TextArea data-cy="description-input" disabled={!viewerIsOwner} />
          </Form.Item>

          <Form.Item label="Time start" name="startAt" hidden={isUsingClockInClockOut}>
            <TimePicker format="HH:mm" showNow minuteStep={30} disabled={!viewerIsOwner} allowClear={false} />
          </Form.Item>

          <Form.Item label="Time end" name="endAt" hidden={isUsingClockInClockOut}>
            <TimePicker format="HH:mm" showNow minuteStep={30} disabled={!viewerIsOwner} allowClear={false} />
          </Form.Item>

          <Form.Item label="Billable" name="billable" valuePropName="checked" hidden={isUsingClockInClockOut}>
            <Checkbox
              disabled={!viewerIsOwner}
              onChange={(e) => {
                form.setFieldsValue({ billable: e.target.checked });
                forceUpdate();
              }}
            />
          </Form.Item>

          <Form.Item
            label={`Choose a ${getSimpleLabel("timesheet-fee-level")}: `}
            name="feeRole"
            rules={[
              {
                required: form.getFieldValue("billable"),
                message: `Billable time must have a ${getSimpleLabel("timesheet-fee-level")} associated with it`,
              },
            ]}
          >
            <Select
              disabled={!form.getFieldValue("billable") || !viewerIsOwner}
              placeholder={`This user has no ${getSimpleLabel(
                "timesheet-fee-levels"
              )} defined. You need to add one from the users page.`}
              allowClear
            >
              {user?.feeRoles?.length > 0
                ? user?.feeRoles?.map((feeRoleId) => {
                    let defaultFeeDetails = organisationDetails.defaultFees?.find((x) => x.id === feeRoleId);
                    let clientFeeDetails = (feesData || []).find((x) => x.id === feeRoleId);
                    let feeValueToDisplay = clientFeeDetails?.valueWithCurrency;
                    if (organisationDetails.settings?.general?.hideFinancials) {
                      feeValueToDisplay = `${clientFeeDetails.value}`;
                    }

                    return (
                      <Select.Option value={feeRoleId} key={feeRoleId}>
                        <Typography.Text>
                          <b>{defaultFeeDetails?.label}:</b> <span style={{ marginLeft: 5 }}>{feeValueToDisplay}</span>
                        </Typography.Text>
                      </Select.Option>
                    );
                  })
                : null}
            </Select>
          </Form.Item>
          {!isUsingClockInClockOut && (
            <>
              <Form.Item label="Variation" name="variation" valuePropName="checked">
                <Checkbox disabled={!viewerIsOwner} />
              </Form.Item>
              <Form.Item label="On Site" name="onSite" valuePropName="checked">
                <Checkbox disabled={!viewerIsOwner} />
              </Form.Item>

              <Form.Item label="Miles travelled" name="mileage">
                <InputNumber placeholder="0" disabled={!viewerIsOwner} />
              </Form.Item>

              <Form.Item name="invoiceId" hidden>
                <Input />
              </Form.Item>

              <Form.Item
                label="Tags"
                name="tags"
                rules={[
                  {
                    required: organisationDetails.settings?.timesheet?.areTagsMandatory,
                    message: "You must choose at least one tag",
                  },
                ]}
              >
                <Select
                  mode="multiple"
                  allowClear
                  style={{ width: "100%" }}
                  placeholder="Select one or more tags"
                  disabled={!viewerIsOwner}
                  filterOption={(input, option) => {
                    return (option as any)?.label.toLowerCase().includes(input.toLowerCase());
                  }}
                >
                  {timesheetTags?.map((tag) => {
                    return (
                      <Select.Option value={tag.id} key={tag.id} label={tag.label}>
                        {tag.label}
                      </Select.Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </>
          )}
          {viewerIsOwner && !timesheetBlockIsApproved && (
            <div className="submit-container">
              <Button type="primary" htmlType="submit" loading={isLoading} data-cy="timesheet-modal-submit-button">
                Submit
              </Button>
            </div>
          )}
        </Form>
      )}

      <br />
      {taskIdForPreview && (
        <TaskDetailsModal
          taskId={taskIdForPreview}
          onClose={() => {
            setTaskIdForPreview(undefined);
          }}
        />
      )}
    </Modal>
  );
}
