import { Progress } from "antd";
import moment from "moment";
import { Treemap, Pie } from "@ant-design/plots";
import { Column } from "@ant-design/charts";

import { taskIdToColor } from "TimelinePage/timelineHelpers";
import { COLORS } from "common/constants";

import SimpleStat from "CustomApplicationPage/SimpleStat/SimpleStat";

export default function PageComponent({ object, style }) {
  // console.log("PageComponent() object = ", object);
  const parameterValues = object?.parameterValues || {};
  // console.log("PageComponent() parameterValues = ", parameterValues);

  function formatXAxis(tickItem) {
    if (parameterValues.xAxisDateFormat) {
      let isValidDate = moment(tickItem, parameterValues.xAxisDateFormat).isValid();

      if (isValidDate) {
        return moment(tickItem).format(parameterValues.xAxisDateFormat);
      }
    }
    return tickItem;
  }

  function formatYAxis(tickItem) {
    if (parameterValues.yAxisFormatAsCurrency) {
      return window.formatCurrency("GBP", parseFloat(tickItem));
    }
    return tickItem;
  }

  let component = null;
  let processedData;
  switch (object.custom_componentType) {
    case "ring-chart":
      let ringChart;
      if (parameterValues.data?.length) {
        processedData = parameterValues.data?.map((item) => {
          return {
            value: item[parameterValues.dataKey],
            name: item[parameterValues.nameKey],
          };
        });

        const ringChartConfig = {
          data: processedData,
          angleField: "value",
          colorField: "name",
          innerRadius: 0,
          legend: false,
          label: {
            type: "inner",
            offset: "-50%",
            content: "{name}",
            style: {
              textAlign: "center",
              fontSize: 10,
              color: "#000000",
              fontWeight: "bold",
            },
          },
        };
        ringChart = <Pie {...ringChartConfig} data={processedData} />;
      }
      component = (
        <SimpleStat
          style={{ width: object.width, height: object.height }}
          label={object.custom_name}
          value={<div style={{ marginLeft: "1rem" }}>{ringChart}</div>}
        />
      );
      break;

    case "bar-chart":
      let barChart = null;
      if (parameterValues.data?.length) {
        processedData = parameterValues.data
          ?.map((item) => {
            return {
              xAxis: item[parameterValues.xAxis],
              yAxis: item[parameterValues.yAxis],
            };
          })
          .sort((a, b) => (a.xAxis > b.xAxis ? 1 : -1));

        const chartConfig = {
          xField: "xAxis",
          yField: "yAxis",
          width: object.width,
          height: object.height - 40,
          legend: false,
          color: parameterValues.color || [COLORS[2]],
          animation: false,
          tooltip: {
            customContent: (title, items) => {
              let formattedValue = items[0] && formatYAxis(items[0].value);
              return (
                <div className="chart-simple-tooltip">
                  <span>{title} </span>
                  {items[0] && <span>{formattedValue}</span>}
                </div>
              );
            },
          },
          yAxis: {
            label: {
              formatter: (value) => formatYAxis(value),
            },
          },
        };

        barChart = <Column {...chartConfig} data={processedData} className="chart" />;
      }
      component = (
        <SimpleStat
          style={{ width: object.width, height: object.height }}
          label={object.custom_name}
          value={<div style={{ marginLeft: "1rem" }}>{barChart}</div>}
        />
      );
      break;
    case "treemap":
      processedData = parameterValues.data?.map((item) => {
        return {
          value: item[parameterValues.dataKey],
          name: item[parameterValues.nameKey],
        };
      });
      if (parameterValues.onlyShowTopXItems) {
        let sortedData = [...(processedData || [])].sort((a, b) =>
          a[parameterValues.dataKey] < b[parameterValues.dataKey] ? 1 : -1
        );
        if (parameterValues.groupTheRest) {
          let topXData = sortedData.slice(0, parameterValues.onlyShowTopXItems);
          let restData = sortedData.slice(parameterValues.onlyShowTopXItems);
          let restTotal = restData.reduce((acc, curr) => acc + curr.value, 0);
          let restItem = {
            name: "Others",
            value: restTotal,
          };
          processedData = topXData.concat(restItem);
        } else {
          processedData = sortedData.slice(0, parameterValues.onlyShowTopXItems);
        }
      }

      const config = {
        data: {
          name: "root",
          children: processedData,
        },
        colorField: "name",
        valueField: "value",

        color: ({ name }) => {
          return taskIdToColor(name, [
            "#BE8FB8",
            "#733B3D",
            "#C76F68",
            "#F2A09F",
            "#1E2044",
            "#353567",
            "#495194",
            "#2E3971",
            "#3F1D22",
            "#FDA081",
          ]);
        },
        tooltip: {
          formatter: (datum) => {
            return { name: formatXAxis(datum.name), value: formatYAxis(datum.value) };
          },
        },
        legend: false,
      };
      component = (
        <SimpleStat
          style={{ width: object.width, height: object.height }}
          label={object.custom_name}
          value={
            <div style={{ width: object.width, height: object.height }}>
              <Treemap {...config} />
            </div>
          }
        />
      );
      break;
    case "simple-stat":
      let valueFormatted = parameterValues.value;
      if (parameterValues.dateFormat) {
        valueFormatted = moment(parameterValues.value).format(parameterValues.dateFormat);
      }
      if (parameterValues.formatAsCurrency) {
        valueFormatted = window.formatCurrency("GBP", parseFloat(valueFormatted));
      }
      component = <SimpleStat label={object.custom_name} value={valueFormatted} />;
      break;
    case "progress":
      let progressPercent = Math.round((parameterValues.currentValue / parameterValues.maxValue) * 100);
      component = <SimpleStat label={object.custom_name} value={<Progress percent={progressPercent} />} />;
      break;
    default:
      break;
  }

  return <div style={{ ...style, flexShrink: 0 }}>{component}</div>;
}
