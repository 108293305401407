import { useState } from "react";
import { Form, Modal, Button, Space, Input } from "antd";
import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";
import awsExports from "aws-exports";

import { updateTask, updateProject } from "graphql/queries_custom";
import { updateOrganisation } from "graphql/mutations";
import { KEY_TYPES, encodeKey } from "common/shared";

import "./CreateFolderModal.scss";

export default function CreateFolderModal({
  onClose,
  task,
  project,
  request,
  prefix,
  isDocumentLibrary,
  organisationDetails,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name }) {
    setIsLoading(true);

    const fileKey = `${await getKey(name)}/`;

    const params = {
      Bucket: awsExports.aws_user_files_s3_bucket,
      Key: fileKey,
      Body: "",
    };

    // this command actually creates the folder, the rest of the calls are just to trigger a refresh
    await window.s3.putObject(params).promise();

    if (project) {
      await callGraphQL(
        "Could not update project",
        graphqlOperation(updateProject, {
          input: {
            id: project.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        })
      );
    } else if (task) {
      await callGraphQL(
        "Could not update task",
        graphqlOperation(updateTask, {
          input: {
            id: task.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        })
      );
    } else {
      await callGraphQL(
        "Could not update organisation",
        graphqlOperation(updateOrganisation, {
          input: {
            id: organisationDetails.id,
            itemSubscription: Math.floor(Math.random() * 1000000),
          },
        })
      );
    }

    onClose();
  }

  async function getKey(localKey) {
    let fileKey;
    if (isDocumentLibrary) {
      fileKey = `public/${organisationDetails.id}/${prefix}/${localKey}`;
    } else if (request) {
      fileKey = await encodeKey({
        type: KEY_TYPES.REQUEST_ATTACHMENT,
        data: {
          organisation: organisationDetails.id,
          requestId: request.id,
          attachmentFileName: `${prefix}${localKey}`,
        },
      });
    } else {
      fileKey = await encodeKey({
        type: KEY_TYPES.PROJECT_ATTACHMENT,
        data: {
          organisation: organisationDetails.id,
          projectId: task ? task.projectId : project.id,
          attachmentFileName: `${prefix}${localKey}`,
        },
      });
    }
    return fileKey.split("//").join("/");
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Create folder"
      visible={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-folder-modal"
    >
      <Form {...layout} form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            name="name"
            label="Folder name"
            className="folder-name-form-item"
            rules={[
              {
                required: true,
                message: "You must specify a folder name",
              },
            ]}
          >
            <Input data-cy="folder-name-input" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="create-folder-submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}
