import { useState, useEffect } from "react";
import axios from "axios";
import { Form, Modal, Button, Select, Spin, Space } from "antd";

import getS3File from "common/getS3File";

import "./ModalAddItemFromList.scss";

export default function ModalAddItemFromList({ onSubmit, onClose, templateDetails, fieldName }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [itemList, setItemList] = useState();
  const [fieldDetails, setFieldDetails] = useState();
  const [defaultKeyName, setDefaultKeyName] = useState();

  useEffect(() => {
    fetchAndSetItemList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchAndSetItemList() {
    const formFilePublicUrl = await getS3File(templateDetails.key);
    let templateForm = (await axios.get(formFilePublicUrl)).data;
    let fieldDetails = templateForm.fields[fieldName];
    if (!fieldDetails) {
      fieldDetails = {};
    }
    if (!fieldDetails.templateValues) {
      fieldDetails.templateValues = [];
    }
    setItemList(fieldDetails.templateValues);
    setFieldDetails(fieldDetails);
    if (fieldDetails.defaultKeyName) {
      setDefaultKeyName(fieldDetails.defaultKeyName);
    } else {
      setDefaultKeyName("title");
    }
  }

  function onFormSubmit({ selectedItemIndex }) {
    let item = itemList[selectedItemIndex].item;
    onSubmit(item);
    form.resetFields();
  }

  return (
    <Modal
      maskClosable={false}
      title="Add preset item"
      open={true}
      onOk={onFormSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className="modal-add-item-from-list full-screen-on-mobile"
    >
      {!itemList || !fieldDetails ? (
        <Spin />
      ) : (
        <Form layout="vertical" form={form} initialValues={{}} onFinish={onFormSubmit}>
          <Space direction="vertical" style={{ width: "100%" }}>
            <Form.Item
              name="selectedItemIndex"
              rules={[
                {
                  required: true,
                  message: "Please select an item",
                },
              ]}
            >
              <Select
                style={{ width: "100%" }}
                data-cy="select-item-dropdown"
                showSearch
                placeholder="Select an item or search for one"
                filterOption={(searchValue, optionDetails) => {
                  let optionMatches = false;
                  for (let key in optionDetails) {
                    if (!key.startsWith("item-attribute-")) {
                      continue;
                    }
                    let keyDetails = optionDetails[key];
                    if (!keyDetails) {
                      continue;
                    }
                    if (typeof keyDetails === "object") {
                      keyDetails = JSON.stringify(keyDetails);
                    }
                    if (typeof keyDetails !== "string") {
                      continue;
                    }
                    if (keyDetails?.toLowerCase()?.includes(searchValue?.toLowerCase())) {
                      optionMatches = true;
                    }
                  }
                  return optionMatches;
                }}
                disabled={isLoading}
              >
                {(itemList || []).map((item, itemIndex) => {
                  let optionAttributes = {};
                  let itemFields = item.item;
                  let label = "";
                  for (let key in itemFields) {
                    optionAttributes[`item-attribute-${key}`] = itemFields[key];
                  }
                  if (defaultKeyName) {
                    let itemValueForDefaultKey = itemFields[defaultKeyName];
                    if (itemValueForDefaultKey) {
                      label = itemValueForDefaultKey;
                    }
                  }

                  return (
                    <Select.Option key={itemIndex} value={itemIndex} {...optionAttributes}>
                      {label}
                    </Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <div className="submit-container">
              <Button type="primary" htmlType="submit" loading={isLoading} className="submit-create-client-modal">
                {isLoading ? "Creating" : "Submit"}
              </Button>
            </div>
          </Space>
        </Form>
      )}
    </Modal>
  );
}
