import AEC from "./organisationFunctions/AEC";
import PBG from "./organisationFunctions/PBG";
import AWD from "./organisationFunctions/AWD";
import EIS from "./organisationFunctions/EIS";
import APOLLO from "./organisationFunctions/APOLLO";
import PSD from "./organisationFunctions/PSD";
import RDX from "./organisationFunctions/RDX";
import CNFM from "./organisationFunctions/CNFM";
import BYRNE from "./organisationFunctions/BYRNE";
import DefaultNaming from "./organisationFunctions/DefaultNaming";

const ORGANISATIONS = {
  AEC,
  PBG,
  AWD,
  EIS,
  CR: AEC,
  AWD2: AWD,
  AEC2: AEC,
  DC: DefaultNaming,
  APOLLO,
  PSD,
  DefaultNaming,
  BYRNE,
  RDX,
  CNFM,
};

export function getNamingFunction(organisation, functionName) {
  let organisationFunctions = ORGANISATIONS[organisation?.id || organisation];

  if (organisationFunctions && organisationFunctions[functionName]) {
    return organisationFunctions[functionName];
  }
}

export function hasCustomFunction(functionName, organisation) {
  return !!getNamingFunction(organisation, functionName);
}

export async function getCustomFunction(functionName, params) {
  let namingFunction =
    getNamingFunction(params.organisation, functionName) || ORGANISATIONS.DefaultNaming[functionName];

  if (!namingFunction) {
    return undefined;
  }

  return await namingFunction(params);
}

export async function getProjectId(params) {
  return await getCustomFunction("getProjectId", params);
}

export async function getTaskId(params) {
  return await getCustomFunction("getTaskId", params);
}

export async function getQuoteId(params) {
  return await getCustomFunction("getQuoteId", params);
}

export async function getRequestId(params) {
  return await getCustomFunction("getRequestId", params);
}

export async function getInvoiceId(params) {
  return await getCustomFunction("getInvoiceId", params);
}

export async function getPurchaseOrderId(params) {
  return await getCustomFunction("getPurchaseOrderId", params);
}

export async function getSheetDescription(params) {
  return await getCustomFunction("getSheetDescription", params);
}

export async function getTaskRevisionName(params) {
  return await getCustomFunction("getTaskRevisionName", params);
}

export async function getSheetRevisionName(params) {
  return await getCustomFunction("getSheetRevisionName", params);
}

export async function getQuoteRevisionName(params) {
  return await getCustomFunction("getQuoteRevisionName", params);
}

export async function getFrontendFileName(params) {
  return await getCustomFunction("getFrontendFileName", params);
}

export async function getTimelineBlockColor(params) {
  return await getCustomFunction("getTimelineBlockColor", params);
}

export async function getPredefinedTaskFieldsFromRequestForm(params) {
  return await getCustomFunction("getPredefinedTaskFieldsFromRequestForm", params);
}

export async function getPredefinedProjectFieldsFromRequestForm(params) {
  return await getCustomFunction("getPredefinedProjectFieldsFromRequestForm", params);
}

////////////////////////// START ASYNC VERSIONS OF THE FUNCTIONS - NEW //////////////////////////

export async function getSheetReferenceNumberAsync({ sheet, file, includeRevisionName = false, sheetRevisionName }) {
  let customReferenceNumber = getCustomSheetReferenceNumber({
    sheet,
    file,
  });

  if (customReferenceNumber) {
    return customReferenceNumber;
  }

  let autoGeneratedReferenceNumber = await getAutoGeneratedSheetReferenceNumberAsync({
    sheet,
    file,
    sheetRevisionName,
  });

  if (includeRevisionName) {
    return `${autoGeneratedReferenceNumber} ${sheet?.revisions?.items?.slice(-1)[0]?.name}`;
  } else {
    return autoGeneratedReferenceNumber;
  }
}

export async function getAutoGeneratedSheetReferenceNumberAsync({ sheet, file, sheetRevisionName }) {
  let referenceNumber = sheet.autoGeneratedReferenceNumber;

  let organisationFunctions = ORGANISATIONS[file.organisation];

  if (organisationFunctions && organisationFunctions.changeSheetReferenceAsync) {
    referenceNumber = await organisationFunctions.changeSheetReferenceAsync({
      sheet,
      file,
      referenceNumber,
      sheetRevisionName: sheetRevisionName || sheet?.revisions?.items?.slice(-1)[0]?.name,
    });
  } else if (organisationFunctions && organisationFunctions.changeSheetReference) {
    referenceNumber = organisationFunctions.changeSheetReference({
      sheet,
      file,
      referenceNumber,
      sheetRevisionName: sheetRevisionName || sheet?.revisions?.items?.slice(-1)[0]?.name,
    });
  }

  return referenceNumber;
}

////////////////////////// END ASYNC VERSIONS OF THE FUNCTIONS - NEW //////////////////////////

////////////////////////// START SYNC VERSIONS OF THE FUNCTIONS - OLD //////////////////////////
export function getSheetReferenceNumber({ sheet, file, includeRevisionName = false, sheetRevisionName }) {
  let customReferenceNumber = getCustomSheetReferenceNumber({
    sheet,
    file,
  });

  if (customReferenceNumber) {
    return customReferenceNumber;
  }

  let autoGeneratedReferenceNumber = getAutoGeneratedSheetReferenceNumber({
    sheet,
    file,
    sheetRevisionName,
  });

  if (includeRevisionName) {
    return `${autoGeneratedReferenceNumber} ${sheet?.revisions?.items?.slice(-1)[0]?.name}`;
  } else {
    return autoGeneratedReferenceNumber;
  }
}

export function getAutoGeneratedSheetReferenceNumber({ sheet, file, sheetRevisionName }) {
  let referenceNumber = sheet.autoGeneratedReferenceNumber;

  let organisationFunctions = ORGANISATIONS[file.organisation];

  if (organisationFunctions && organisationFunctions.changeSheetReference) {
    referenceNumber = organisationFunctions.changeSheetReference({
      sheet,
      file,
      referenceNumber,
      sheetRevisionName: sheetRevisionName || sheet?.revisions?.items?.slice(-1)[0]?.name,
    });
  }

  return referenceNumber;
}

////////////////////////// END SYNC VERSIONS OF THE FUNCTIONS - OLD //////////////////////////

export function getCustomSheetReferenceNumber({ sheet, file }) {
  return sheet.customReferenceNumber || file?.versions.items.slice(-1)[0].customId;
}

export async function changeFileNameAtDownloadTime(data) {
  const { organisation } = data;

  let result = "";

  let organisationFunctions = ORGANISATIONS[organisation];
  if (organisationFunctions && organisationFunctions.changeFileNameAtDownloadTime) {
    result = await organisationFunctions.changeFileNameAtDownloadTime(data);
  } else {
    result = data.fileName;
  }

  let stringToReplace = "_annotated";
  result = result.replace(stringToReplace, "");

  return result;
}
