import moment from "moment";
import { FILE_TYPES_DETAILS, fetchActivityItemsForRequest, processIdForDisplay } from "common/shared";
import { getSheetReferenceNumberAsync } from "common/naming";
import { FILE_TYPES_ORDER } from "common/constants";
import { getDetailsForFormAndTaskRevision } from "common/sharedRequestHelpers";

const fields = [
  {
    id: "taskRevisionName",
    fieldTypes: ["textfield"],
    label: "Name",
    value: ({ taskRevision }) => taskRevision.name,
  },
  {
    id: "taskRevisionDescription",
    fieldTypes: ["textfield"],
    label: "Description",
    value: ({ taskRevision }) => taskRevision.description,
  },

  {
    id: "assigneeSignature",
    fieldTypes: ["signature"],
    label: "Assignee signature",
    value: ({ taskRevision, users }) => {
      const author = users.find((user) => user.id === taskRevision.author);
      if (!author) {
        return undefined;
      }

      return {
        image: author.signature,
        firstName: author.firstName,
        lastName: author.lastName,
      };
    },
  },
  {
    id: "reviewerSignature",
    fieldTypes: ["signature"],
    label: "Reviewer signature",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return undefined;
      }

      return {
        image: reviewer.signature,
        firstName: reviewer.firstName,
        lastName: reviewer.lastName,
      };
    },
  },

  {
    id: "reviewerInitials",
    fieldTypes: ["textfield"],
    label: "Reviewer initials",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return undefined;
      }
      return `${reviewer.firstName[0]}${reviewer.lastName[0]}`;
    },
  },
  {
    id: "reviewerFullName",
    fieldTypes: ["textfield"],
    label: "Reviewer full name",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return undefined;
      }
      return `${reviewer.firstName} ${reviewer.lastName}`;
    },
  },
  {
    id: "reviewerPosition",
    fieldTypes: ["textfield"],
    label: "Reviewer position",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return "";
      }
      return `${reviewer.position || ""}`;
    },
  },
  {
    id: "reviewerPosition",
    fieldTypes: ["textfield"],
    label: "Reviewer telephone",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return "";
      }

      let phone1 = reviewer.phone1 || "";
      let phone2 = reviewer.phone2 || "";
      let phones = [phone1, phone2].filter((x) => x);

      return phones.join(", ");
    },
  },
  {
    id: "reviewerQualifications",
    fieldTypes: ["textfield"],
    label: "Reviewer qualifications",
    value: ({ task, taskRevision, users }) => {
      const reviewer = users.find((user) => user.id === taskRevision.checkedBy);
      if (!reviewer) {
        return "";
      }
      return `${reviewer.qualifications || ""}`;
    },
  },
  {
    id: "assigneeInitials",
    fieldTypes: ["textfield"],
    label: "Assignee initials",
    value: ({ task, taskRevision, users }) => {
      const assignee = users.find((user) => user.id === taskRevision.author);
      if (!assignee) {
        return "";
      }
      return `${assignee.firstName[0]}${assignee.lastName[0]}`;
    },
  },
  {
    id: "assigneeFullName",
    fieldTypes: ["textfield"],
    label: "Assignee full name",
    value: ({ task, taskRevision, users }) => {
      const assignee = users.find((user) => user.id === taskRevision.author);
      if (!assignee) {
        return "";
      }
      return `${assignee.firstName} ${assignee.lastName}`;
    },
  },
  {
    id: "assigneePosition",
    fieldTypes: ["textfield"],
    label: "Assignee position",
    value: ({ task, taskRevision, users }) => {
      const assignee = users.find((user) => user.id === taskRevision.author);
      if (!assignee) {
        return "";
      }
      return `${assignee.position || ""}`;
    },
  },
  {
    id: "assigneeTelephone",
    fieldTypes: ["textfield"],
    label: "Assignee telephone",
    value: ({ task, taskRevision, users }) => {
      const assignee = users.find((user) => user.id === taskRevision.author);
      if (!assignee) {
        return "";
      }
      let phone1 = assignee.phone1 || "";
      let phone2 = assignee.phone2 || "";
      let phones = [phone1, phone2].filter((x) => x);

      return phones.join(", ");
    },
  },
  {
    id: "assigneeQualifications",
    fieldTypes: ["textfield"],
    label: "Assignee qualifications",
    value: ({ task, taskRevision, users }) => {
      const assignee = users.find((user) => user.id === taskRevision.author);
      if (!assignee) {
        return "";
      }
      return `${assignee.qualifications}`;
    },
  },
  {
    id: "taskRevisionApprovedAt",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Approved at",
    value: ({ taskRevision, dateFormat, addDays }) => {
      if (!taskRevision) {
        return "";
      } else {
        if (!taskRevision.reviewAcceptDate) {
          return "";
        } else {
          return moment(taskRevision.reviewAcceptDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "taskRevisionPriority",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Priority",
    value: ({ taskRevision, organisationDetails }) => {
      if (!taskRevision.priorityId) {
        return "";
      } else {
        if (!organisationDetails) {
          return "";
        } else {
          const priority = organisationDetails.settings?.task?.priorities.find((x) => x.id === taskRevision.priorityId);
          if (!priority) {
            return "";
          }
          return priority.name;
        }
      }
    },
  },
  {
    id: "taskRevisionDueDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Due date",
    value: ({ taskRevision, dateFormat, addDays }) => {
      if (!taskRevision) {
        return "";
      } else {
        if (!taskRevision.dueDate) {
          return "";
        } else {
          return moment(taskRevision.dueDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "taskRevisionRequestedDate",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Requested date",
    value: ({ taskRevision, dateFormat, addDays }) => {
      if (!taskRevision) {
        return "";
      } else {
        if (!taskRevision.requestedDate) {
          return "";
        } else {
          return moment(taskRevision.requestedDate).add(addDays, "day").format(dateFormat);
        }
      }
    },
  },
  {
    id: "taskRevisionDueDateMet",
    fieldTypes: ["textfield"],
    isDate: true,
    label: "Due date met",
    value: ({ taskRevision, dateFormat, addDays }) => {
      if (!taskRevision.dueDate) {
        return false;
      } else {
        if (!taskRevision.reviewAcceptDate) {
          return false;
        } else {
          return moment(taskRevision.reviewAcceptDate).isSameOrBefore(taskRevision.dueDate, "day");
        }
      }
    },
  },
  {
    id: "files",
    label: "Each file in task revision",
    fieldTypes: ["repeatFor"],
    repeatForFieldName: "file",
    value: ({ taskRevision }) => {
      if (!taskRevision) {
        return [];
      }
      return taskRevision.files.items
        .filter((file) => !file.isHidden)
        .sort((a, b) => FILE_TYPES_ORDER[a.type] - FILE_TYPES_ORDER[b.type]);
    },
  },
  {
    id: "filesWithPrintableSheets",
    label: "Each file with printable sheets in task revision",
    fieldTypes: ["repeatFor"],
    repeatForFieldName: "file",
    value: ({ taskRevision }) => {
      if (!taskRevision) {
        return [];
      }

      let result = taskRevision.files.items
        .filter((file) => !file.isHidden && file.sheets.items.some((sheet) => !sheet.excludeFromRegister))
        .sort((a, b) => FILE_TYPES_ORDER[a.type] - FILE_TYPES_ORDER[b.type]);

      return result;
    },
  },
  {
    id: "riskAssessmentReference",
    label: "Risk Assessment Reference",
    value: async ({ organisationDetails, taskRevision }) => {
      if (!organisationDetails) {
        return "";
      }
      const targetTemplate = organisationDetails.templates.items.find((x) => x.name === "Risk Assessment");
      if (!targetTemplate) {
        return "";
      }
      if (!taskRevision) {
        return [];
      }
      const firstRiskAssessmentFile = taskRevision.files.items.find((x) => x.templateId === targetTemplate.id);
      if (!firstRiskAssessmentFile) {
        return "";
      }
      const firstSheetInFile = firstRiskAssessmentFile.sheets.items[0];
      if (!firstSheetInFile) {
        return "";
      }
      return await getSheetReferenceNumberAsync({
        file: firstRiskAssessmentFile,
        sheet: firstSheetInFile,
      });
    },
  },
  {
    id: "transmittalDate",
    fieldTypes: ["repeatFor"],
    label: "Each date in document transmittal",
    repeatForFieldName: "transmittalDate",
    value: ({ taskRevision }) => {
      if (!taskRevision) {
        return [];
      }

      return getDatesForDocumentTransmittal(taskRevision);
    },
  },
  {
    id: "repeat_transmittalCurrentDate",
    fieldTypes: ["textfield"],
    label: "Current date in document transmittal",
    isDate: true,
    value: ({ transmittalDate, dateFormat }) => moment(transmittalDate).format(dateFormat),
  },
  {
    id: "taskRevisionTaskId",
    fieldTypes: ["textfield"],
    label: "Task ID",
    value: ({ taskRevision }) => taskRevision.taskId,
  },
  {
    id: "firstRequestID",
    fieldTypes: ["textfield"],
    label: "First request ID",
    value: async ({ taskRevision, task, activityItemsByRequest }) => {
      let requestIds;
      if (task?.requestIds && task?.requestIds.length > 0) {
        requestIds = task.requestIds;
      } else if (taskRevision?.task?.requestIds && taskRevision?.task?.requestIds.length > 0) {
        requestIds = taskRevision.task.requestIds;
      } else {
        return "";
      }

      const activityDetailsForRequests = await Promise.all(requestIds.map(fetchActivityItemsForRequest));
      activityItemsByRequest = activityItemsByRequest || {};
      for (let activityDetailsForRequest of activityDetailsForRequests) {
        activityItemsByRequest[activityDetailsForRequest.requestId] = activityDetailsForRequest.activityItems;
      }

      let requestFormActivityItem = getDetailsForFormAndTaskRevision({
        activityItemsByRequest: activityItemsByRequest,
        taskRevisionId: taskRevision.id,
      });

      if (!requestFormActivityItem || !requestFormActivityItem.content) {
        return "";
      }

      return processIdForDisplay(requestFormActivityItem.parentId);
    },
  },
];

export function getDatesForDocumentTransmittal(taskRevision) {
  let sheetsInDocumentRegister = [];
  if (!taskRevision) {
    return [];
  }
  for (let file of taskRevision.files.items) {
    for (let sheet of file.sheets.items) {
      if (!sheet.excludeFromRegister) {
        sheetsInDocumentRegister.push(sheet);
      }
    }
  }
  let datesWithDuplicates = [];
  for (let sheet of sheetsInDocumentRegister) {
    for (let sheetRevision of sheet.revisions.items) {
      if (sheetRevision.reviewAcceptDate) {
        datesWithDuplicates.push(sheetRevision.reviewAcceptDate);
      }
    }
  }
  const datesWithoutDuplicates = [...new Set(datesWithDuplicates)];
  return datesWithoutDuplicates;
}

async function getDesignTaskForCat2Check(task) {
  if (!task.linkedTasks) {
    return undefined;
  }

  let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "CAT_2_CHECK_FOR");
  if (!cat2CheckRelationship) {
    return undefined;
  }

  if (!global.isBrowser) {
    return undefined;
  }

  const designTask = (
    await window.callGraphQLSimple({
      message: `Failed to fetch design task details`,
      queryCustom: "getTaskWithFiles",
      variables: {
        id: cat2CheckRelationship.taskId,
      },
    })
  ).data.getTask;

  return designTask;
}

async function getCat2CheckTask(task) {
  if (!task.linkedTasks) {
    return undefined;
  }

  let cat2CheckRelationship = (task.linkedTasks || []).find((x) => x.relationship === "NEEDS_CAT_2_CHECK");
  if (!cat2CheckRelationship) {
    return undefined;
  }

  if (!global.isBrowser) {
    return undefined;
  }

  const cat2CheckTask = (
    await window.callGraphQLSimple({
      message: "Failed to fetch Cat 2 check task details",
      queryCustom: "getTaskWithFiles",
      variables: {
        id: cat2CheckRelationship.taskId,
      },
    })
  ).data.getTask;

  return cat2CheckTask;
}

async function getLatestTaskRevisionInDesignTaskForCat2Check(task) {
  if (!task) {
    return undefined;
  }

  let designTask = await getDesignTaskForCat2Check(task);

  if (!designTask) {
    return undefined;
  }

  return designTask.revisions.items.slice(-1)[0];
}

async function getLatestTaskRevisionInCat2CheckTask(task) {
  if (!task) {
    return undefined;
  }

  let cat2Task = await getCat2CheckTask(task);

  if (!cat2Task) {
    return undefined;
  }

  return cat2Task.revisions.items.slice(-1)[0];
}

export function getFields({ organisationDetails }) {
  let extraFields = [];
  if (organisationDetails?.customFields && organisationDetails.customFields.length > 0) {
    organisationDetails.customFields.forEach((fieldDefinition) => {
      extraFields.push({
        id: `taskRevisionCustomTaskField_${fieldDefinition.id}`,
        fieldTypes: ["textfield"],
        label: `Task - ${fieldDefinition.label}`,
        value: ({ taskRevision }) => {
          if (!taskRevision || !taskRevision.task) {
            return "";
          }
          let task = taskRevision.task;
          if (!task || !task.customFields) {
            return "";
          }
          let targetField = task?.customFields?.find((field) => field.id === fieldDefinition.id);
          if (!targetField) {
            return "";
          }
          return targetField.value;
        },
      });
    });
  }
  for (let fileTypeName in FILE_TYPES_DETAILS) {
    let fileTypeDetails = FILE_TYPES_DETAILS[fileTypeName];
    if (fileTypeDetails.isPartOfATask) {
      extraFields.push({
        id: `files_${fileTypeName}`,
        fieldTypes: ["repeatFor"],
        label: `Each ${fileTypeDetails.label} file in task revision`,
        repeatForFieldName: "file",
        value: ({ taskRevision }) => {
          if (!taskRevision) {
            return [];
          }
          let options = taskRevision?.files?.items?.filter((file) => file.type === fileTypeName && !file.isHidden);

          return options;
        },
      });
      extraFields.push({
        id: `files_printable${fileTypeName}`,
        fieldTypes: ["repeatFor"],
        label: `Each printable ${fileTypeDetails.label} file in task revision`,
        repeatForFieldName: "file",
        value: ({ taskRevision }) => {
          if (!taskRevision) {
            return [];
          }
          let options = taskRevision?.files?.items?.filter(
            (file) =>
              file.type === fileTypeName &&
              !file.isHidden &&
              file.sheets.items.some((sheet) => !sheet.excludeFromRegister)
          );

          return options;
        },
      });
      extraFields.push({
        id: `files_${fileTypeName}_DESIGN_CAT2`,
        fieldTypes: ["repeatFor"],
        label: `Each ${fileTypeDetails.label} file in design task (for Cat 2 check flow)`,
        repeatForFieldName: "file",
        value: async ({ task }) => {
          const taskRevision = await getLatestTaskRevisionInDesignTaskForCat2Check(task);
          if (!taskRevision) {
            return [];
          }
          return taskRevision?.files?.items?.filter((file) => file.type === fileTypeName && !file.isHidden);
        },
      });
      extraFields.push({
        id: `files_${fileTypeName}_CAT2`,
        fieldTypes: ["repeatFor"],
        label: `Each ${fileTypeDetails.label} file in Cat 2 check task`,
        repeatForFieldName: "file",
        value: async ({ task }) => {
          const taskRevision = await getLatestTaskRevisionInCat2CheckTask(task);
          if (!taskRevision) {
            return [];
          }
          return taskRevision?.files?.items?.filter((file) => file.type === fileTypeName && !file.isHidden);
        },
      });
    }
    if (fileTypeDetails.isPartOfATask && fileTypeDetails.hasSheets) {
      extraFields.push({
        id: `sheets_${fileTypeName}`,
        fieldTypes: ["repeatFor"],
        label: `Each printable sheet reference in each ${fileTypeDetails.label} file in task revision`,
        repeatForFieldName: "sheet",
        value: ({ taskRevision }) => {
          let items = [];
          if (!taskRevision) {
            return items;
          }
          if (taskRevision?.files?.items) {
            for (let file of taskRevision?.files?.items) {
              if (file.type !== fileTypeName || file.isHidden) {
                continue;
              }
              if (file?.sheets?.items) {
                for (let sheet of file?.sheets?.items) {
                  if (sheet.excludeFromRegister) {
                    continue;
                  }
                  items.push({ ...sheet, file });
                }
              }
            }
          }
          return items;
        },
      });
      extraFields.push({
        id: `sheet_ids_${fileTypeName}`,
        fieldTypes: ["repeatFor"],
        label: `Each printable sheet ID in each ${fileTypeDetails.label} file in task revision`,
        repeatForFieldName: "sheet",
        value: ({ taskRevision }) => {
          let items = [];
          if (!taskRevision) {
            return items;
          }
          for (let file of taskRevision?.files?.items) {
            if (file.type !== fileTypeName || file.isHidden) {
              continue;
            }
            for (let sheet of file?.sheets?.items) {
              if (sheet.excludeFromRegister) {
                continue;
              }
              items.push({ ...sheet, file });
            }
          }
          return items;
        },
      });
      extraFields.push({
        id: `sheets_${fileTypeName}_DESIGN_CAT2`,
        fieldTypes: ["repeatFor"],
        label: `Each printable sheet in each ${fileTypeDetails.label} file in design task (for Cat 2 check flow)`,
        repeatForFieldName: "sheet",
        value: async ({ task }) => {
          const taskRevision = await getLatestTaskRevisionInDesignTaskForCat2Check(task);
          let items = [];
          if (!taskRevision) {
            return items;
          }
          for (let file of taskRevision?.files?.items) {
            if (file.type !== fileTypeName || file.isHidden) {
              continue;
            }
            for (let sheet of file?.sheets?.items) {
              if (sheet.excludeFromRegister) {
                continue;
              }
              items.push({ ...sheet, file });
            }
          }
          return items;
        },
      });
      extraFields.push({
        id: `sheets_${fileTypeName}_CAT2`,
        fieldTypes: ["repeatFor"],
        label: `Each printable sheet in each ${fileTypeDetails.label} file in Cat 2 check task`,
        repeatForFieldName: "sheet",
        value: async ({ task }) => {
          const taskRevision = await getLatestTaskRevisionInCat2CheckTask(task);
          let items = [];
          if (!taskRevision) {
            return items;
          }
          for (let file of taskRevision?.files?.items) {
            if (file.type !== fileTypeName || file.isHidden) {
              continue;
            }
            for (let sheet of file?.sheets?.items) {
              if (sheet.excludeFromRegister) {
                continue;
              }
              items.push({ ...sheet, file });
            }
          }
          return items;
        },
      });
    }
  }
  return [...fields, ...extraFields];
}
