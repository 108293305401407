import { useEffect } from "react";
import { message, Result, Typography, Button } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import moment from "moment";
import axios from "axios";

import { callGraphQLSimple } from "common/apiHelpers";
import { downloadBlob } from "common/helpers";
import { assembleArchive } from "common/downloadSelectedSheetsInTask";

import Card from "Card/Card";

import "./PublicIssuedTaskFilesModal.scss";

type Props = {
  data: any;
};

export default function PublicIssuedTaskFilesModal({ data }: Props) {
  useEffect(() => {
    recordActivityItemOnLoad();
  }, []);

  async function recordActivityItemOnLoad() {
    await callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: "Failed to record activity item",
      variables: {
        input: {
          taskId: data.content.taskId,
          content: JSON.stringify({
            type: "PUBLIC_UPLOAD_URL_VIEWED",
          }),
          organisation: data.organisation,
          author: window.apiUser?.id || "PUBLIC",
          type: "LIFECYCLE_EVENT",
        },
      },
    });
  }

  async function downloadIndividualFile({ url, fileName }: { url: string; fileName: string }) {
    const messageKey = `downloading-${url}`;
    message.loading({ content: `Downloading ${fileName}`, key: messageKey, duration: 0 });
    try {
      const fileBlob = (await axios.get(url, { responseType: "blob" })).data;
      await downloadBlob({
        blob: fileBlob,
        fileName,
      });
      await callGraphQLSimple({
        mutation: "createTaskActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            taskId: data.content.taskId,
            content: JSON.stringify({
              type: `TASK_FILES_DOWNLOADED_INDIVIDUAL`,
              fileName,
            }),
            author: window.apiUser?.id || "PUBLIC",
            organisation: data.organisation,
            type: "LIFECYCLE_EVENT",
          },
        },
      });
      message.destroy(messageKey);
    } catch (e) {
      message.error({ content: "Failed to download file", key: messageKey });
    }
  }

  function displayFileList() {
    return (
      <div style={{ marginBottom: "1rem" }} key="extra-files">
        <Typography.Text className="files-included-title">Files included in this package:</Typography.Text>
        <div>
          {data.content.publicUrls.map((publicUrlDetails) => {
            return (
              <div key={publicUrlDetails.url} style={{ marginTop: "0.5rem" }}>
                <Typography.Text>{publicUrlDetails.actualFileName}.pdf</Typography.Text>{" "}
                <Button
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() =>
                    downloadIndividualFile({
                      fileName: `${publicUrlDetails.actualFileName}.pdf`,
                      url: publicUrlDetails.url,
                    })
                  }
                />
              </div>
            );
          })}
          {data.content.attachmentsWithPublicUrls?.map((attachment) => {
            return (
              <div key={attachment.key} style={{ marginTop: "0.5rem" }}>
                <Typography.Text>{attachment.key.split("/").pop()}</Typography.Text>
                <Button
                  icon={<DownloadOutlined />}
                  style={{ marginLeft: "0.5rem" }}
                  onClick={() =>
                    downloadIndividualFile({
                      url: attachment.url,
                      fileName: attachment.key.split("/").pop(),
                    })
                  }
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }

  let linkIsExpired = false;

  if (
    data?.content?.sharing &&
    data.content.sharing.expiresAt &&
    moment(data.content.sharing?.expiresAt).isBefore(moment())
  ) {
    linkIsExpired = true;
  }

  async function onDownloadAllFilesClick() {
    let assembledPublicUrlsIncludingAttachments = [];

    assembledPublicUrlsIncludingAttachments = data.content.publicUrls.map((publicUrlDetails) => {
      return {
        url: publicUrlDetails.url,
        fileName: publicUrlDetails.actualFileName,
      };
    });

    if (data.content.attachmentsWithPublicUrls) {
      assembledPublicUrlsIncludingAttachments = assembledPublicUrlsIncludingAttachments.concat(
        data.content.attachmentsWithPublicUrls.map((attachment) => {
          return {
            url: attachment.url,
            fileName: attachment.key.split("/").pop(),
          };
        })
      );
    }

    await assembleArchive({
      taskId: data.content.taskId,
      taskTitle: data.content.taskTitle,
      projectTitle: data.content.projectTitle,
      messageKey: "download-all-files",
      publicUrls: assembledPublicUrlsIncludingAttachments,
    });
    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createTaskActivityItem",
      variables: {
        input: {
          taskId: data.content.taskId,
          content: JSON.stringify({
            type: "TASK_FILES_DOWNLOADED_ALL",
          }),
          organisation: data.organisation,
          author: window.apiUser?.id || "PUBLIC",
          type: "LIFECYCLE_EVENT",
        },
      },
    });
  }
  return (
    <div style={{ display: "flex", justifyContent: "center" }} className="public-issued-task-files-modal">
      {linkIsExpired ? (
        <Card>
          <Result status="error" title="This link has expired" />
        </Card>
      ) : (
        <Card>
          <Result
            status={"success"}
            title={
              <>
                Design package issued:
                <br />
                {data.content.projectTitle} - {data.content.taskTitle}
              </>
            }
            subTitle={
              <>
                <Typography.Text>
                  Issued by {data.author} on {moment(data.createdAt).format("DD-MM-YYYY")}.
                </Typography.Text>
              </>
            }
            extra={[
              displayFileList(),
              <Button type="primary" icon={<DownloadOutlined />} onClick={onDownloadAllFilesClick} key="download-all">
                Download all files
              </Button>,
            ]}
          />
        </Card>
      )}
    </div>
  );
}
