import { useEffect, useState } from "react";
import { Spin, Select, Form, Modal, Button, notification, Typography, Tag } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { useForceUpdate } from "common/helpers";

import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import TaskPicker from "TaskPicker/TaskPicker";
import ReviewPrimaryStatus from "ReviewPage/ReviewPrimaryStatus/ReviewPrimaryStatus";
import ReviewSecondaryStatus from "ReviewPage/ReviewSecondaryStatus/ReviewSecondaryStatus";
import CreateTaskRevisionModal from "CreateTaskRevisionModal/CreateTaskRevisionModal";
import FormTextButton from "reusableComponents/FormTextButton/FormTextButton";

import "./AddToTaskModal.scss";
import { getSimpleLabel } from "common/labels";

export function AddToTaskModal({
  onClose,
  onSubmit,
  apiUser,
  visible,
  tasks,
  organisationDetails,
  projectId,
  predefinedCreateTaskFields,
  predefinedCreateTaskRevisionFields,
  predefinedCreateProjectFields,
  entityName,
  includeTaskRevision = false,
  includeAddNewTaskRevision = false,
  alreadyAddedToTaskIdsAndRevisionIds = undefined,
  isLinkingReview = false,
  predefinedTaskId,
}) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [isCreateTaskModalVisible, setIsCreateTaskModalVisible] = useState(false);
  const [isCreateTaskRevisionModalVisible, setIsCreateTaskRevisionModalVisible] = useState(false);
  const [taskDetails, setTaskDetails] = useState(undefined);
  const [numberForRefresh, setNumberForRefresh] = useState(0);
  const forceUpdate = useForceUpdate();

  const taskId = form.getFieldValue("taskId");

  useEffect(() => {
    if (predefinedTaskId) {
      setTimeout(() => {
        form.setFieldsValue({ taskId: predefinedTaskId });
        forceUpdate();
      }, 100);
    }
  }, [predefinedTaskId]);

  useEffect(() => {
    if (!visible || !includeTaskRevision) {
      return;
    }

    setTaskDetails(undefined);

    if (!taskId) {
      return;
    }

    async function fetchTaskDetails() {
      const taskDetails = (
        await callGraphQLSimple({
          queryCustom: "getTaskSimple",
          message: `Failed to fetch ${getSimpleLabel("task")} details`,
          variables: {
            id: taskId,
          },
        })
      ).data.getTask;
      setTaskDetails(taskDetails);
      let latestTaskRevision = taskDetails.revisions.items.slice(-1)[0];
      if (latestTaskRevision?.isReadOnly) {
        if (includeAddNewTaskRevision) {
          form.setFieldsValue({ taskRevisionId: "ADD_NEW" });
        }
      } else {
        const alreadyAddedToRevision = alreadyAddedToTaskIdsAndRevisionIds?.some((crtElement) => {
          return crtElement.taskId === taskDetails.id && crtElement.taskRevisionId === latestTaskRevision.id;
        });
        if (!alreadyAddedToRevision) {
          form.setFieldsValue({ taskRevisionId: latestTaskRevision?.id });
        }
      }
      forceUpdate();
    }

    fetchTaskDetails();
  }, [visible, taskId, includeTaskRevision, includeAddNewTaskRevision, numberForRefresh]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onSubmitInner({ taskId, taskRevisionId }) {
    const taskRevision = taskDetails?.revisions?.items?.find((x) => x.id === taskRevisionId);

    try {
      setIsLoading(true);
      await onSubmit({
        taskId,
        taskRevisionId,
        taskRevisionName: taskRevision?.name,
        taskTitle: taskDetails?.title,
        projectTitle: taskDetails?.project?.title,
        isLinkingReview,
      });
      setIsLoading(false);
    } catch (e) {
      console.error(e);
      notification.error({
        message: (
          <>
            <Typography.Text>
              Could not add {entityName} to {getSimpleLabel("task")}
            </Typography.Text>
            <br />
            <Typography.Text>Reason: {e.message || JSON.stringify(e)}</Typography.Text>
          </>
        ),
        duration: 0,
      });
      setIsLoading(false);
    }
  }

  function checkTask(_, taskId) {
    return new Promise((resolve, reject) => {
      if (!taskId) {
        return reject("");
      }
      const parts = taskId.split("-");
      if (parts.length < 3) {
        return reject("");
      }

      return resolve();
    });
  }

  const layout = {
    labelCol: {
      span: 5,
    },
    wrapperCol: {
      span: 19,
    },
  };

  let tasksToDisplay = [];
  if (projectId) {
    tasksToDisplay = tasks.filter(
      (x) => !x.isExternalReference && x.projectId === projectId && !x.isArchived && !x.isHidden
    );
    if (!predefinedCreateTaskFields) {
      predefinedCreateTaskFields = {};
    }
    predefinedCreateTaskFields.projectId = projectId;
  } else {
    tasksToDisplay = tasks.filter((x) => !x.isExternalReference && !x.isArchived && !x.isHidden);
  }

  let submitLabel = "Submit";
  if (isLoading) {
    submitLabel = "Submitting...";
  }

  return (
    <>
      <Modal
        maskClosable={false}
        title={`Add ${getSimpleLabel(entityName)} to ${getSimpleLabel("task")}`}
        open={visible}
        onOk={onSubmitInner}
        onCancel={() => {
          form.resetFields();
          onClose();
        }}
        footer={null}
        className="add-to-task-modal"
      >
        <Form
          {...layout}
          form={form}
          initialValues={{
            taskId: predefinedTaskId,
          }}
          onFinish={onSubmitInner}
        >
          <Form.Item
            label={`Choose ${getSimpleLabel("task")}`}
            className="choose-task-form-item"
            name="taskId"
            rules={[
              {
                required: true,
                message: `You must specify a ${getSimpleLabel("task")}`,
                validator: checkTask,
              },
            ]}
          >
            <>
              <TaskPicker
                includeProjectName={false}
                includeTaskId={false}
                tasks={tasksToDisplay}
                value={form.getFieldValue("taskId")}
                onSearch={() => {
                  form.setFieldsValue({ taskId: "" });
                  forceUpdate();
                }}
                disabled={!!predefinedTaskId}
                onChange={(taskId) => {
                  form.setFieldsValue({ taskId, taskRevisionId: undefined });
                  forceUpdate();
                }}
              />

              <Button
                type="primary"
                className="create-task"
                onClick={() => setIsCreateTaskModalVisible(true)}
                data-cy="add-to-task-modal-create-new-task-button"
              >
                <PlusCircleOutlined /> Create new
              </Button>
            </>
          </Form.Item>
          {includeTaskRevision && taskId && (
            <Form.Item
              label={`Choose ${getSimpleLabel("task revision")}`}
              className="choose-task-revision-form-item"
              name="taskRevisionId"
              rules={[
                {
                  required: true,
                  message: `You must specify a ${getSimpleLabel("task revision")}`,
                },
              ]}
            >
              {taskDetails ? (
                <>
                  <Select
                    value={form.getFieldValue("taskRevisionId")}
                    disabled={!taskDetails.revisions?.items || !taskDetails.revisions.items?.length === 0}
                    loading={!taskDetails}
                    onChange={(taskRevisionId) => {
                      form.setFieldsValue({ taskRevisionId });
                      forceUpdate();
                    }}
                  >
                    {includeAddNewTaskRevision && (
                      <Select.Option key="ADD_NEW" value="ADD_NEW">
                        Add new {getSimpleLabel("task revision")}
                      </Select.Option>
                    )}
                    {[...taskDetails?.revisions?.items]
                      .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                      .map((taskRevision) => {
                        const alreadyAddedToRevision = alreadyAddedToTaskIdsAndRevisionIds?.some((crtElement) => {
                          return crtElement.taskId === taskDetails.id && crtElement.taskRevisionId === taskRevision.id;
                        });

                        return (
                          <Select.Option
                            key={taskRevision.id}
                            value={taskRevision.id}
                            disabled={alreadyAddedToRevision}
                          >
                            <div style={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                              {taskRevision.name}
                              <ReviewPrimaryStatus taskRevision={taskRevision} />
                              <ReviewSecondaryStatus taskRevision={taskRevision} />
                              {alreadyAddedToRevision && <Tag color="orange">Already added</Tag>}
                            </div>
                          </Select.Option>
                        );
                      })}
                  </Select>
                  <FormTextButton
                    onClick={(e) => {
                      setIsCreateTaskRevisionModalVisible(true);
                    }}
                  >
                    Create new {getSimpleLabel("task revision")}
                  </FormTextButton>
                </>
              ) : (
                <Spin />
              )}
            </Form.Item>
          )}

          <div className="submit-container">
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoading}
              data-cy="add-to-task-modal-submit-button"
              disabled={includeTaskRevision && !taskDetails}
            >
              {submitLabel}
            </Button>
          </div>
        </Form>
        <br />
        {isCreateTaskModalVisible && (
          <CreateTaskModal
            predefinedFields={predefinedCreateTaskFields}
            predefinedCreateProjectFields={predefinedCreateProjectFields}
            onClose={() => setIsCreateTaskModalVisible(false)}
            apiUser={apiUser}
            onSave={(task) => {
              form.setFieldsValue({ taskId: task.id });
              forceUpdate();
            }}
          />
        )}
      </Modal>
      {isCreateTaskRevisionModalVisible && (
        <CreateTaskRevisionModal
          apiUser={apiUser}
          task={taskDetails}
          organisationDetails={organisationDetails}
          predefinedFields={predefinedCreateTaskRevisionFields}
          onClose={() => {
            setNumberForRefresh((numberForRefresh) => numberForRefresh + 1);
            setIsCreateTaskRevisionModalVisible(false);
          }}
        />
      )}
    </>
  );
}

export default withSubscriptions({
  Component: AddToTaskModal,
  subscriptions: ["tasks", "projects", "sprints", "clients", "users", "organisationDetails"],
});
