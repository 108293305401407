import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import withSubscriptions from "common/withSubscriptions";

// import { downloadBase64, sanitiseCSVValue } from "common/helpers";
import Tabs from "reusableComponents/Tabs/Tabs";
import TimeOffForAllUsers from "../TimeOffForAllUsers/TimeOffForAllUsers";

import "./TimeOffPage.scss";

export function TimeOffPage({ orderedActiveUsers, apiUser, history, holidays }) {
  const [activeTab, setActiveTab] = useState("holidays"); // eslint-disable-line
  const [defaultExpandedRow, setDefaultExpandedRow] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab) {
      setActiveTab(tab);
    }

    const defaultExpandedUserId = urlParams.get("defaultExpandedRowUserId");

    if (defaultExpandedUserId) {
      setDefaultExpandedRow(defaultExpandedUserId);
    }
  }, []);

  // async function exportToCsv() {
  // const columns = [...tableColumns];
  // let csvContent = columns.map((column) => column.title).join(",") + "\n";
  // let enhancedTimesheetBlocks = timesheetBlocks.map((block) => {
  //   let task = tasks.find((task) => task.id === block.taskId);
  //   let project = projects.find((project) => project.id === task?.projectId);
  //   let client = clients.find((client) => client.id === project?.clientId);
  //   let user = users.find((user) => user.id === block.userId);
  //   return {
  //     ...block,
  //     client,
  //     task,
  //     project,
  //     user,
  //   };
  // });
  // enhancedTimesheetBlocks.forEach((timesheetBlock) => {
  //   csvContent +=
  //     columns
  //       .map((column) => {
  //         if (column.fieldFunction) {
  //           return sanitiseCSVValue(column.fieldFunction(timesheetBlock));
  //         } else {
  //           return sanitiseCSVValue(timesheetBlock[column.dataIndex]);
  //         }
  //       })
  //       .join(",") + "\n";
  // });
  // let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
  // await downloadBase64({
  //   base64String: base64CSV,
  //   fileName: `timesheet ${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}.csv`,
  // });
  // }

  return (
    <div className="time-off-page">
      <Tabs
        className="time-off-tabs"
        activeKey={activeTab}
        onTabClick={(tabKey) => {
          history.push(`/timeoff?tab=${tabKey}`);
          setActiveTab(tabKey);
        }}
        items={[
          {
            id: "holidays",
            title: "Holidays",
            content: (
              <TimeOffForAllUsers
                apiUser={apiUser}
                holidays={holidays.filter((holiday) => !holiday.isSick)}
                users={orderedActiveUsers}
                isSick={false}
                key="holidays"
                defaultExpandedRow={defaultExpandedRow}
              />
            ),
          },
          {
            id: "sickDays",
            title: "Sick days",
            content: (
              <TimeOffForAllUsers
                apiUser={apiUser}
                holidays={holidays.filter((holiday) => holiday.isSick)}
                users={orderedActiveUsers}
                isSick={true}
                key="sick"
                defaultExpandedRow={defaultExpandedRow}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimeOffPage,
    subscriptions: ["users", "tasks", "projects", "clients", "organisationDetails", "holidays"],
  })
);
