import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Button, Typography, Tabs, Tag } from "antd";
import moment from "moment";

import withSubscriptions from "common/withSubscriptions";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";
import { downloadBase64, getExpandableParamsForTable, sanitiseCSVValue, getLabel } from "common/helpers";
import { roundToQuarter } from "common/mathHelpers";
import { Table, Select } from "antd";
import DatePicker from "DatePicker/DatePicker";
import TaskIdTag from "TaskIdTag/TaskIdTag";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import { tableColumns } from "./tableColumns";
import { isAuthorised } from "common/permissions";
import { getWorkingAndNonWorkingDaysForUser } from "common/timeOffHelpers";
import getEndDateForTimelineBlock from "common/getEndDateForTimelineBlock";
import getTimelineBlocksOnDay from "common/getTimelineBlocksOnDay";
import getNumberOfHoursForTimelineBlockOnDay from "common/getNumberOfHoursForTimelineBlockOnDay";

import InfoItem from "InfoItem/InfoItem";
import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import TaskPicker from "TaskPicker/TaskPicker";
import TimesheetBlocksTable from "TimesheetBlocksTable/TimesheetBlocksTable";
import RecordingSymbol from "reusableComponents/RecordingSymbol/RecordingSymbol";

import "./TimesheetsPage.scss";

export function TimesheetsPage({
  users,
  apiUser,
  timesheetBlocks,
  timelineBlocks,
  tasks,
  projects,
  clients,
  defaultStartDate,
  defaultEndDate,
  onlyShowUsersWithTime = false,
  defaultFilterClientId,
  defaultFilterProjectId,
  defaultFilterTaskId,
  timesheetTags,
  history,
  fetchAndSetTimesheetBlocks,
  fetchAndSetTimelineBlocks,
  organisationDetails,
  windowWidth,
}) {
  const [activeTab, setActiveTab] = useState("Users");
  const [startDate, setStartDate] = useState(defaultStartDate ? moment(defaultStartDate) : moment().startOf("isoWeek"));
  const [endDate, setEndDate] = useState(defaultEndDate ? moment(defaultEndDate) : moment().endOf("isoWeek"));
  const [isTaskDetailsModalVisible, setIsTaskDetailsModalVisible] = useState(false);
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [filterClientId, setFilterClientId] = useState(defaultFilterClientId);
  const [filterProjectId, setFilterProjectId] = useState(defaultFilterProjectId);
  const [filterTaskId, setFilterTaskId] = useState(defaultFilterTaskId);
  const [isLoading, setIsLoading] = useState(true);

  let displayAllColumns = false;
  if (endDate.diff(startDate, "months") < 1) {
    displayAllColumns = true;
  }

  let timesheetBlocksWithDuration = timesheetBlocks.map((timesheetBlock) => {
    let endAt = timesheetBlock.endAt;
    if (timesheetBlock.isRecording) {
      endAt = moment().toISOString();
    }
    let durationHours = moment(endAt).diff(moment(timesheetBlock.startAt), "hours", true);
    // round to 1 decimal place
    durationHours = roundToQuarter(durationHours);
    return {
      ...timesheetBlock,
      durationHours,
    };
  });

  useEffect(() => {
    fetchData({ startDate, endDate, filterClientId, filterProjectId, filterTaskId }).then(() => {
      setIsLoading(false);
    });
  }, [startDate, endDate, filterClientId, filterProjectId, filterTaskId]); // eslint-disable-line

  async function fetchData({ startDate, endDate, filterClientId, filterProjectId, filterTaskId }) {
    let paramsForTimesheetBlocksFetch = {
      organisation: apiUser.organisation,
      startAt: startDate.startOf("day").toISOString(),
      endAt: endDate.endOf("day").toISOString(),
    };
    let filter = {};
    if (filterClientId) {
      filter = {
        ...filter,
        clientId: {
          eq: filterClientId,
        },
      };
    }
    if (filterProjectId) {
      filter = {
        ...filter,
        projectId: {
          eq: filterProjectId,
        },
      };
    }
    if (filterTaskId) {
      filter = {
        ...filter,
        taskId: {
          eq: filterTaskId,
        },
      };
    }
    if (Object.keys(filter).length > 0) {
      paramsForTimesheetBlocksFetch.filter = filter;
    }

    await Promise.all([
      fetchAndSetTimesheetBlocks(paramsForTimesheetBlocksFetch),

      fetchAndSetTimelineBlocks({
        organisation: apiUser.organisation,
        startDate: moment(startDate).startOf("day").subtract(1, "week").toISOString(),
        endDate: endDate.endOf("day").toISOString(),
      }),
    ]);
  }

  function calculateTotalHoursWithinInterval({ startDate, endDate, timelineBlocks }) {
    let totalHours = 0;
    let currentDate = moment(startDate);

    while (currentDate.isSameOrBefore(endDate)) {
      const blocksOnDay = getTimelineBlocksOnDay({ targetDate: currentDate, timelineBlocks });
      for (let block of blocksOnDay) {
        totalHours += getNumberOfHoursForTimelineBlockOnDay({ timelineBlock: block, targetDate: currentDate });
      }
      currentDate.add(1, "days");
    }

    return totalHours;
  }

  function getDataForUser(user) {
    let hoursLogged;

    let timesheetBlocksForUser = timesheetBlocksWithDuration.filter((block) => block.userId === user.id);
    let timelineBlocksForUser = timelineBlocks
      .filter((block) => block.userId === user.id)
      .map((timelineBlock) => {
        return {
          ...timelineBlock,
          endDate: getEndDateForTimelineBlock({ timelineBlock }),
        };
      });

    let hoursPlanned = 0;

    if (displayAllColumns) {
      hoursPlanned = calculateTotalHoursWithinInterval({
        startDate,
        endDate,
        timelineBlocks: timelineBlocksForUser,
      });
    }

    hoursLogged = timesheetBlocksForUser.reduce((sum, block) => {
      return sum + block.durationHours;
    }, 0);

    let timeOffBlocks = [];
    if (organisationDetails.settings?.general?.usesTimeOff) {
      timeOffBlocks = timesheetBlocksForUser.filter((block) => {
        if (block.taskId.toLowerCase().includes("holiday") || block.taskId.toLowerCase().includes("sick day")) {
          return true;
        }
        return false;
      });
    }

    const hoursTimeOff = timeOffBlocks.reduce((sum, block) => {
      return sum + block.durationHours;
    }, 0);

    const { billableHours, nonBillableHours } = timesheetBlocksForUser
      .filter((block) => {
        return !timeOffBlocks.some((timeOffBlock) => timeOffBlock.id === block.id);
      })
      .reduce(
        (result, block) => {
          if (block.billable) {
            result.billableHours += block.durationHours;
          } else {
            result.nonBillableHours += block.durationHours;
          }
          return result;
        },
        {
          billableHours: 0,
          nonBillableHours: 0,
        }
      );

    const { workingDays } = getWorkingAndNonWorkingDaysForUser({
      user: users.find((x) => x.id === user.id),
      startDate: moment(startDate),
      endDate: moment(endDate),
    });

    let workingHoursInPeriod = 0;

    let utilisationPercentageAsLogged;
    let utilisationPercentageAsPlanned;
    if (workingDays) {
      if (!hoursLogged) {
        utilisationPercentageAsLogged = 0;
      } else {
        workingHoursInPeriod = workingDays.length * TIMELINE_DEFAULT_HOURS_IN_A_DAY;
        if (!workingHoursInPeriod) {
          utilisationPercentageAsLogged = "No working hours in period";
        } else {
          utilisationPercentageAsLogged = (hoursLogged || 0) / workingHoursInPeriod;
        }
      }
    } else {
      utilisationPercentageAsLogged = "No working hours in period";
    }

    if (workingDays) {
      if (!hoursPlanned) {
        utilisationPercentageAsPlanned = 0;
      } else {
        workingHoursInPeriod = workingDays.length * TIMELINE_DEFAULT_HOURS_IN_A_DAY;
        if (!workingHoursInPeriod) {
          utilisationPercentageAsPlanned = "No working hours in period";
        } else {
          utilisationPercentageAsPlanned = (hoursPlanned || 0) / workingHoursInPeriod;
        }
      }
    } else {
      utilisationPercentageAsPlanned = "No working hours in period";
    }

    return {
      ...user,
      key: user.id,
      order: users.find((x) => x.id === user.id).order,
      hoursLogged,
      hoursPlanned,
      billablePercentage: billableHours / (billableHours + nonBillableHours),
      hoursTimeOff,
      utilisationPercentageAsLogged,
      utilisationPercentageAsPlanned,
    };
  }

  function displayDetailsForUser(user) {
    let userTimesheetBlocks = timesheetBlocksWithDuration.filter((timesheetBlock) => timesheetBlock.userId === user.id);

    let taskIds = [];
    for (let i = 0; i < userTimesheetBlocks.length; i++) {
      if (!taskIds.includes(userTimesheetBlocks[i].taskId)) {
        taskIds.push(userTimesheetBlocks[i].taskId);
      }
    }

    if (filterTaskId) {
      return <TimesheetBlocksTable blocks={userTimesheetBlocks} timesheetTags={timesheetTags} includeActions />;
    }

    return (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        expandRowByClick={true}
        expandable={getExpandableParamsForTable({
          render: (record) => <TimesheetBlocksTable blocks={record.timesheetBlocks} includeActions />,
        })}
        columns={[
          {
            title: "Task",
            align: "left",
            render: (_, row) => {
              let tag = null;
              if (row.task) {
                tag = <TaskIdTag task={row.task} includeTitle={false} />;
              }
              return (
                <>
                  {tag}
                  {row.label}
                </>
              );
            },
          },
          {
            title: "Hours logged",
            dataIndex: "hoursLogged",
          },
        ]}
        onRow={(record) => {
          return {
            onClick: () => {
              if (record.task) {
                setIsTaskDetailsModalVisible(true);
                setSelectedTaskId(record.taskId);
              }
            },
          };
        }}
        dataSource={taskIds.map((taskId) => {
          let task = tasks.find((task) => task.id === taskId);
          let label = task?.title || taskId;
          if (label === "nothing") {
            label = "Time not allocated to any task";
          }

          let timesheetBlocks = userTimesheetBlocks.filter((block) => block.taskId === taskId);

          let hoursLogged = timesheetBlocks.reduce((hoursLogged, block) => {
            return hoursLogged + block.durationHours;
          }, 0);

          return {
            key: taskId,
            taskId,
            task,
            label,
            hoursLogged,
            timesheetBlocks,
          };
        })}
      />
    );
  }

  async function exportToCsv() {
    const columns = [...tableColumns];

    let csvContent = columns.map((column) => column.title).join(",") + "\n";

    let enhancedTimesheetBlocks = timesheetBlocksWithDuration.map((block) => {
      let task = tasks.find((task) => task.id === block.taskId);
      let project = projects.find((project) => project.id === task?.projectId);
      let client = clients.find((client) => client.id === project?.clientId);
      let user = users.find((user) => user.id === block.userId);
      return {
        ...block,
        client,
        task,
        project,
        user,
        tags: block.tags?.map((tagId) => timesheetTags?.find((x) => x.id === tagId)?.label),
      };
    });

    enhancedTimesheetBlocks.forEach((timesheetBlock) => {
      csvContent +=
        columns
          .map((column) => {
            if (column.fieldFunction) {
              return sanitiseCSVValue(column.fieldFunction(timesheetBlock));
            } else {
              return sanitiseCSVValue(timesheetBlock[column.dataIndex]);
            }
          })
          .join(",") + "\n";
    });

    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `timesheet ${startDate.format("DD-MM-YYYY")} - ${endDate.format("DD-MM-YYYY")}.csv`,
    });
  }

  function displayProjectPicker() {
    let projectsToChooseFrom = projects;
    if (filterClientId) {
      projectsToChooseFrom = projectsToChooseFrom.filter((project) => project.clientId === filterClientId);
    }
    projectsToChooseFrom = projectsToChooseFrom.filter((project) => !project.id.includes("TEMPLATES"));

    return (
      <InfoItem
        label={`Filter by ${getLabel({
          id: "project",
          defaultValue: "project",
        })}`}
        value={
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder={`Select ${getLabel({
              id: "project",
              defaultValue: "project",
            })}`}
            optionFilterProp="children"
            onChange={(value) => {
              setFilterProjectId(value);
            }}
            allowClear
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={filterProjectId}
          >
            {projectsToChooseFrom.map((project) => {
              return (
                <Select.Option key={project.id} value={project.id}>
                  {project.title}
                </Select.Option>
              );
            })}
          </Select>
        }
      />
    );
  }

  function displayClientPicker() {
    return (
      <InfoItem
        label="Filter by client"
        value={
          <Select
            showSearch
            style={{ width: 200 }}
            placeholder="Select client"
            optionFilterProp="children"
            onChange={(value) => {
              setFilterClientId(value);
            }}
            allowClear
            filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
            value={filterClientId}
          >
            {clients.map((client) => {
              return (
                <Select.Option key={client.id} value={client.id}>
                  {client.name}
                </Select.Option>
              );
            })}
          </Select>
        }
      />
    );
  }

  function displayTaskPicker() {
    let excludeList = [];
    if (filterProjectId) {
      excludeList = tasks.filter((task) => task.projectId !== filterProjectId).map((task) => task.id);
    }
    return (
      <InfoItem
        className="task-picker-container"
        label={`Filter by ${getLabel({
          id: "task",
          defaultValue: "task",
        })}`}
        value={
          <TaskPicker
            allowClear
            includeProjectName={false}
            includeTaskId={true}
            onChange={(value) => {
              setFilterTaskId(value);
            }}
            value={filterTaskId}
            excludeList={excludeList}
          />
        }
      />
    );
  }

  function displayDatePicker() {
    return (
      <InfoItem
        label="Date range"
        value={
          <DatePicker.RangePicker
            format="DD-MM-YYYY"
            allowClear={false}
            dropdownClassName="timeline-window-date-range-picker"
            onChange={([startDate, endDate]) => {
              setStartDate(startDate);
              setEndDate(endDate);
            }}
            value={[startDate, endDate]}
            ranges={{
              "This week": [moment().startOf("isoWeek"), moment().endOf("isoWeek")],
              "Last week": [
                moment().startOf("isoWeek").subtract(1, "weeks"),
                moment().subtract(1, "week").endOf("isoWeek"),
              ],
              "2 weeks ago": [
                moment().startOf("isoWeek").subtract(2, "weeks"),
                moment().endOf("isoWeek").subtract(2, "weeks"),
              ],
              "3 weeks ago": [
                moment().startOf("isoWeek").subtract(3, "weeks"),
                moment().endOf("isoWeek").subtract(3, "weeks"),
              ],
              "4 weeks ago": [
                moment().startOf("isoWeek").subtract(4, "weeks"),
                moment().endOf("isoWeek").subtract(4, "weeks"),
              ],
              [`This month (${moment().format("MMMM")})`]: [moment().startOf("month"), moment()],
              [`Last month (${moment().subtract(1, "month").format("MMMM")})`]: [
                moment().startOf("month").subtract(1, "month"),
                moment().endOf("month").subtract(1, "month").endOf("month"),
              ],
              YTD: [moment().startOf("year"), moment()],
              "Previous year": [
                moment().startOf("year").subtract(1, "year"),
                moment().endOf("year").subtract(1, "year").endOf("year"),
              ],
            }}
          />
        }
      />
    );
  }

  let tableRows = users
    .filter((user) => {
      if (user.isHidden) {
        return false;
      }

      if (!isAuthorised(["TIMESHEETS.VIEW"]) && user.id !== apiUser.id) {
        return false;
      }

      return true;
    })
    .map((user) => {
      return getDataForUser(user);
    })
    .filter((row) => {
      if (row.isDisabled && row.hoursLogged === 0) {
        return false;
      }
      return true;
    });

  if (onlyShowUsersWithTime) {
    tableRows = tableRows.filter((row) => row.hoursLogged > 0);
  }

  let atLeastOneUserUsesClockInClockOut = users.some((user) => {
    let targetUserIsUsingClockInClockOut = isAuthorised(["USER_TIMESHEET.USE_CLOCK_IN_OUT"], user, true);
    return targetUserIsUsingClockInClockOut;
  });

  return (
    <div className="timesheets-page">
      <Tabs
        className="tabs"
        activeKey={activeTab}
        onTabClick={(tabKey) => {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set("timesheetTab", tabKey);

          history.push(`timesheets?${urlParams.toString()}`);
          setActiveTab(tabKey);
        }}
        tabBarExtraContent={{
          right: <Button onClick={exportToCsv}>Export to CSV </Button>,
        }}
      >
        <Tabs.TabPane tab={<Typography.Text data-cy="users-tab">Users</Typography.Text>} key="Users">
          <Card
            withSpace
            actions={
              <div className="timesheets-filters">
                {displayClientPicker()}
                {displayProjectPicker()}
                {displayTaskPicker()}
                {displayDatePicker()}
              </div>
            }
          >
            <Table
              pagination={{ hideOnSinglePage: true, pageSize: 50 }}
              loading={isLoading}
              scroll={{ x: 800 }}
              sticky={{
                offsetHeader: -10,
              }}
              columns={[
                {
                  title: "User",
                  key: "user",
                  dataIndex: "id",
                  align: "left",
                  sorter: (a, b) => (a.order < b.order ? -1 : 1),
                  defaultSortOrder: "ascend",
                  width: atLeastOneUserUsesClockInClockOut ? 300 : 200,

                  render: (_, user) => {
                    let clockedInTag = null;

                    let targetUserIsUsingClockInClockOut = isAuthorised(
                      ["USER_TIMESHEET.USE_CLOCK_IN_OUT"],
                      user,
                      true
                    );
                    if (targetUserIsUsingClockInClockOut) {
                      let isClockedIn = timesheetBlocks.find((block) => block.userId === user.id && block.isRecording);
                      if (isClockedIn) {
                        clockedInTag = (
                          <Tag className="accent-tag" style={{ marginLeft: "0.3rem" }}>
                            <RecordingSymbol white />
                            Clocked in
                          </Tag>
                        );
                      } else {
                        clockedInTag = (
                          <Tag color="#002f44" style={{ marginLeft: "0.3rem" }}>
                            Clocked out
                          </Tag>
                        );
                      }
                    }

                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Avatar user={users.find((x) => x.id === user.id)} showLabel />
                        {clockedInTag}
                      </div>
                    );
                  },
                },
                {
                  title: "Total hours logged",
                  dataIndex: "hoursLogged",
                  key: "hoursLogged",
                  sorter: (a, b) => (a.hoursLogged < b.hoursLogged ? -1 : 1),
                },
                organisationDetails.settings?.general?.usesTimeOff && {
                  title: "Time off hours logged",
                  dataIndex: "hoursTimeOff",
                  key: "hoursTimeOff",
                  sorter: (a, b) => (a.hoursTimeOff < b.hoursTimeOff ? -1 : 1),
                  render: (value) => {
                    return <Typography.Text>{isNaN(value) ? "0" : value}</Typography.Text>;
                  },
                },
                {
                  title: (
                    <>
                      Billable {windowWidth > 1000 ? "percentage logged" : ""} <br />
                      {organisationDetails.settings?.general?.usesTimeOff ? "(excluding time off)" : ""}
                    </>
                  ),
                  dataIndex: "billablePercentage",
                  key: "billablePercentage",
                  sorter: (a, b) => (a.billablePercentage < b.billablePercentage ? -1 : 1),
                  render: (value) => {
                    return <Typography.Text>{isNaN(value) ? "0%" : `${Math.floor(value * 100)}%`}</Typography.Text>;
                  },
                },
                {
                  title: "Utilisation as logged",
                  dataIndex: "utilisationPercentageAsLogged",
                  key: "utilisationPercentageAsLogged",
                  sorter: (a, b) => (a.utilisationPercentageAsLogged < b.utilisationPercentageAsLogged ? -1 : 1),
                  render: (value) => {
                    return <Typography.Text>{isNaN(value) ? value : `${Math.floor(value * 100)}%`}</Typography.Text>;
                  },
                },
                displayAllColumns
                  ? {
                      title: "Utilisation as planned",
                      dataIndex: "utilisationPercentageAsPlanned",
                      key: "utilisationPercentageAsPlanned",
                      sorter: (a, b) => (a.utilisationPercentageAsPlanned < b.utilisationPercentageAsPlanned ? -1 : 1),
                      render: (value) => {
                        return (
                          <Typography.Text>{isNaN(value) ? value : `${Math.floor(value * 100)}%`}</Typography.Text>
                        );
                      },
                    }
                  : undefined,
              ].filter((x) => x)}
              expandable={getExpandableParamsForTable({
                render: displayDetailsForUser,
              })}
              dataSource={tableRows}
            />
          </Card>
        </Tabs.TabPane>

        <Tabs.TabPane
          tab={
            <Typography.Text data-cy="tasks-tab">
              {getLabel({
                id: "Tasks",
                defaultValue: "Tasks",
              })}
            </Typography.Text>
          }
          key="Tasks"
          disabled
        >
          <span>
            {getLabel({
              id: "Tasks",
              defaultValue: "Tasks",
            })}{" "}
            tab
          </span>
        </Tabs.TabPane>
      </Tabs>
      {isTaskDetailsModalVisible && selectedTaskId && (
        <TaskDetailsModal
          taskId={selectedTaskId}
          onClose={() => {
            setIsTaskDetailsModalVisible(false);
            setSelectedTaskId();
          }}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimesheetsPage,
    subscriptions: [
      "users",
      "tasks",
      "projects",
      "clients",
      "organisationDetails",
      "timesheetBlocks",
      "timesheetTags",
      "timelineBlocks",
    ],
  })
);
