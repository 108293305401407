import moment from "moment";

import { KEY_TYPES } from "../shared";
import log from "../log";
import { getFullDetailsForNaming } from "./namingHelpers";

export async function getFileName({ data, type }) {
  // debugger;
  // console.log("PSD:getFileName() type =", type, "data = ", data);
  // if (type === "SHEET_REFERENCE") {
  //   debugger;
  // }
  // await log({
  //   level: "DEBUG",
  //   message: "START",
  //   method: "PSD:getFileName",
  //   details: {
  //     data,
  //     type,
  //   },
  // });

  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  let { fileTypeCount, sameTemplateCount, taskIdWithoutOrg, task, file, organisationDetails } =
    await getFullDetailsForNaming(data, type);

  let sheet;
  let fileNumber;

  if (type === KEY_TYPES.FILE_SHEET_EXPORT || type === KEY_TYPES.FILE_SHEET_EXPORT_RAW) {
    sheet = file.sheets.items.find((x) => x.name === data.sheetName);
  }

  let result = `${organisationDetails.id}-${taskIdWithoutOrg}`;

  // console.log("DefaultNaming::getFileName() data =", JSON.stringify(data, null, 2));
  // console.log("DefaultNaming::getFileName() type =", type);

  switch (data.fileType.toUpperCase()) {
    case "AUTOCAD":
    case "REVIT":
    case "BRICSCAD":
      result += "-DRG";
      if (sheet) {
        result += `-${sheet.description}`;
      } else {
        if (!type.toUpperCase().includes("SHEET")) {
          fileNumber = String(1000 + fileTypeCount).substring(1);
          result += `-${fileNumber}`;
        }
      }
      break;
    case "EXCEL":
      result += "-CAL";
      fileNumber = String(10 + fileTypeCount).substring(1);
      result += `-${fileNumber}`;
      if (type?.includes("SHEET") && data.sheetName) {
        result += ` ${data.sheetName}`;
      }
      break;

    default:
      let templateId = data.templateId || data.file?.templateId;
      const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
      if (templateDetails) {
        if (templateDetails?.name?.toUpperCase().includes("EDC")) {
          result += "-EDC";
        } else if (templateDetails?.name?.toUpperCase().includes("SIC")) {
          result += "-SIC";
        } else if (templateDetails?.name?.toUpperCase().includes("DESIGN CERTIFICATE")) {
          result += "-DC";
        } else if (templateDetails?.name?.toUpperCase().includes("CHECK CERTIFICATE")) {
          result += "-CC";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT2") ||
          templateDetails?.name?.toUpperCase().includes("CAT 2")
        ) {
          result += "-CAT2";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT1") ||
          templateDetails?.name?.toUpperCase().includes("CAT 1")
        ) {
          result += "-CAT1";
        } else if (
          templateDetails?.name?.toUpperCase().includes("DRA") ||
          templateDetails?.name?.toUpperCase().includes("RISK ASSESSMENT")
        ) {
          result += "-DRA";
        } else if (
          templateDetails?.name?.toUpperCase().includes("CAT3") ||
          templateDetails?.name?.toUpperCase().includes("CAT 3")
        ) {
          result += "-CAT3";
        }
      }

      if (templateDetails?.name?.toUpperCase().includes("DOCUMENT REGISTER")) {
        result = result.replace("-ADMIN", "-REG");
      } else {
        fileNumber = String(sameTemplateCount + 1);
        result += `-${fileNumber}`;
      }
      break;
  }

  // await log({
  //   level: "DEBUG",
  //   message: "END",
  //   method: "DefaultNaming::getFileName",
  //   details: {
  //     result,
  //   },
  // });
  return result;
}

async function changeFileNameAtDownloadTime(data) {
  // console.log('changeFileNameAtDownloadTime() data =', data);
  let { fileName, sheetRevisionName, file, type, task, invoice, projects, clients, sheetRevision } = data;

  let sheet;
  if (sheetRevision?.sheetId) {
    sheet = file.sheets.items.find((x) => x.id === sheetRevision.sheetId);
  }

  if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.name} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }

  if (file?.type === "AUTOCAD") {
    if (type?.includes("SHEET")) {
      let fileVersion = file.versions.items.find((x) => x.id === sheetRevision.fileVersionId);
      if (sheet?.customReferenceNumber && sheet.customReferenceNumber !== "undefined") {
        return sheet.customReferenceNumber;
      } else if (fileVersion?.customId && fileVersion.customId !== "undefined") {
        return fileVersion.customId;
      }
      let fileNameParts = fileName.split(" ");
      fileNameParts.splice(1, 1, `${sheetRevisionName}`);
      fileName = fileNameParts.join(" ");
    }
  } else {
    let latestFileVersion = file?.versions?.items?.slice(-1)[0];
    if (latestFileVersion?.customId && latestFileVersion?.customId !== "undefined") {
      fileName = latestFileVersion.customId;
    } else {
      if (!sheetRevisionName && file && file.sheets && file.sheets.items && file.sheets.items[0]) {
        let firstSheet = file.sheets.items[0];
        sheetRevisionName = firstSheet.revisions.items.slice(-1)[0].name;
      }
      let fileNameParts = fileName.split(" ");
      if (!task?.isHidden) {
        fileNameParts.splice(1, 1, `${sheetRevisionName}`);
      }
      fileName = fileNameParts.join(" ");
    }
  }

  return fileName;
}

function changeSheetReference({ sheet, file, referenceNumber }) {
  if (["AUTOCAD", "REVIT", "BRICSCAD"].includes(file?.type.toUpperCase())) {
    return `${referenceNumber}-${sheet.description}`;
  } else {
    return referenceNumber;
  }
}

function getProjectId({ organisation, extraOffset }) {
  const projectNumber =
    parseInt(organisation.projectIdOffset || 0) + parseInt(organisation.projectCount || 0) + 1 + parseInt(extraOffset);
  return `${organisation.id}-${projectNumber}`;
}

async function getTaskId({ organisation, projectDetails, extraOffset }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  let taskNumberAsString = `${taskNumber}`;

  return `${projectDetails.id}-${taskNumberAsString}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  let quoteNumberAsString = quoteNumber >= 10 ? `${quoteNumber}` : `0${quoteNumber}`;
  return `${projectDetails.id}-Q${quoteNumberAsString}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  let invoiceNumberAsString = invoiceNumber >= 10 ? `${invoiceNumber}` : `0${invoiceNumber}`;
  return `${projectDetails.id}-INV${invoiceNumberAsString}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ task, file, taskRevision, sheetCount }) {
  const fileType = file.type;
  let sheetCountForFileType = sheetCount;
  const latestTaskRevision = taskRevision || task.revisions.items[task.revisions.items.length - 1];

  latestTaskRevision.files.items.forEach((crtFile) => {
    if (crtFile.type !== fileType || crtFile.isArchived) {
      return;
    }

    sheetCountForFileType += crtFile.sheets.items.filter((x) => x.includeInPublish).length;
  });

  if (file.type === "EXCEL") {
    return String(1001 + sheetCountForFileType).substring(1);
  }
  return String(1 + sheetCountForFileType);
}

function getRequestId({ organisationDetails, extraOffset }) {
  const requestNumber = parseInt(organisationDetails.requestCount || 0) + 1 + parseInt(extraOffset);
  return `${organisationDetails.id}-REQ${requestNumber}`;
}

function getTaskRevisionName({ task }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let nameIndexOfLatestTaskRevision = possibleNames.findIndex((x) => x === latestTaskRevision.name);
  if (nameIndexOfLatestTaskRevision === -1) {
    return null;
  }

  let nextName = null;
  while (!nextName && nameIndexOfLatestTaskRevision < possibleNames.length - 1) {
    nextName = possibleNames[nameIndexOfLatestTaskRevision + 1];
    // eslint-disable-next-line
    if (nonArchivedRevisions.find((x) => x.name === nextName)) {
      nextName = null;
      nameIndexOfLatestTaskRevision++;
    }
  }

  return nextName;
}

function getSheetRevisionName({ sheet }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return possibleNames[0];
  }
  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === latestSheetRevision.name);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

function getQuoteRevisionName({ quote }) {
  const possibleNames = [
    "A",
    "B",
    "C",
    "D",
    "E",
    "F",
    "G",
    "H",
    "J",
    "K",
    "L",
    "M",
    "N",
    "P",
    "Q",
    "R",
    "S",
    "T",
    "U",
    "V",
    "W",
    "X",
    "Y",
    "Z",
  ];
  if (!quote || !quote.currentRevisionName) {
    return possibleNames[0];
  }

  const nameIndexOfLatestSheetRevision = possibleNames.findIndex((x) => x === quote.currentRevisionName);
  if (nameIndexOfLatestSheetRevision === -1) {
    return null;
  }
  return possibleNames[nameIndexOfLatestSheetRevision + 1];
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  if (!templateId) {
    return;
  }
  const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);

  if (templateDetails?.name === "Information") {
    return taskRevision.name;
  } else if (templateDetails?.name === "Minutes") {
    return `Meeting minutes - ${moment().format("DD-MM-YYYY")}`;
  }

  const filesWithSameTemplate = taskRevision.files.items.filter((file) => file.templateId === templateDetails?.id);
  let filesWithSameTemplateCount = filesWithSameTemplate.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    filesWithSameTemplateCount += deletedFilesByType[fileType];
  }

  const result = `${templateDetails?.name} - ${String(1000 + filesWithSameTemplateCount + 1).substring(1)}`;
  return result;
}

async function getTimelineBlockColor({ task, timelineBlock }) {
  let hasTaskRevision =
    timelineBlock.taskRevisionId &&
    task?.revisions &&
    window.organisationDetails.settings?.timeline?.planTaskRevisionsInsteadOfTasks;

  let taskRevisionName;

  if (timelineBlock.taskRevisionId) {
    if (hasTaskRevision) {
      let revision = task.revisions.items.find((x) => x.id === timelineBlock.taskRevisionId);
      if (revision) {
        taskRevisionName = revision.name;
      }
    }
  }

  if (!task || !taskRevisionName) {
    return;
  }

  let taskRevisionNameUpperCase = taskRevisionName.toUpperCase().split(" ").join("_");

  let color = "#002f44";

  switch (taskRevisionNameUpperCase) {
    case "CLIENT_DOCUMENTS":
      color = "#325D95";
      break;
    case "SURVEY_INFO_(OFFICE)":
      color = "#9FD67F";
      break;
    case "SURVEY_INFO_(ON_SITE)":
      color = "#D19AD0";
      break;
    case "TESTING_INFORMATION_PACK_(OFFICE)":
      color = "#A1D5EE";
      break;
    case "T1_TESTING_RECORDING_DOCUMENT_(ON_SITE)":
      color = "#F5C242";
      break;
    case "SAFETY_DOCUMENTS_T1_TESTING":
      color = "#FCFC53";
      break;
    // case "":
    //   color = "";
    //   break;
    default:
      break;
  }

  return color;
}

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getQuoteRevisionName,
  getFrontendFileName,
  getRequestId,
  getTimelineBlockColor,
};

export default functions;
