import { useState } from "react";
import { Checkbox, Button, Table, Modal, Typography } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import { graphqlOperation } from "aws-amplify";
import { withRouter } from "react-router-dom";
import _ from "lodash";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import withSubscriptions from "common/withSubscriptions";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";
import { TIMESHEET_SETTINGS } from "common/settings";

import Input from "Input/Input";
import InfoItem from "InfoItem/InfoItem";
import Card from "Card/Card";
import CreateTagModal from "Modals/CreateTagModal/CreateTagModal";

import "./TimesheetSettingsPage.scss";

function TimesheetSettingsPage({ organisationDetails, timesheetTags }) {
  const [isAddNewTagModalVisible, setIsAddNewTagModalVisible] = useState(false);

  const checked = TIMESHEET_SETTINGS.filter((timesheetSetting) => {
    if (timesheetSetting.INPUT_TYPE === "text") {
      return false;
    }
    return _.get(organisationDetails, timesheetSetting.SCHEMA_FIELD);
  });

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);
  const tagColumns = [
    {
      title: "",
      dataIndex: "label",
      key: "label",
    },
    {
      title: "",
      key: "actions",
      render: (_, tag) => {
        return (
          <Button
            icon={<DeleteOutlined />}
            type="clear"
            onClick={async () => {
              Modal.confirm({
                title: "Confirm delete tag",
                maskClosable: true,
                content: (
                  <>
                    Are you sure you want to delete <b>{tag.label}</b>?
                  </>
                ),
                onOk: () => {
                  window.callGraphQLSimple({
                    message: "Failed to delete timesheet tag",
                    mutation: "deleteTimesheetTag",
                    variables: {
                      input: {
                        id: tag.id,
                      },
                    },
                  });
                },
              });
            }}
          />
        );
      },
    },
  ];

  return (
    <div className="timesheet-settings-page">
      <Card withSpace title="Timesheet settings">
        <Checkbox.Group defaultValue={checkedSettings}>
          {TIMESHEET_SETTINGS.filter((timesheetSetting) => {
            if (timesheetSetting.INPUT_TYPE === "text") {
              return false;
            }

            return true;
          }).map((timesheetSetting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = timesheetSetting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: { settings: organisationDetails.settings || {} },
                    checked: e.target.checked,
                    module: timesheetSetting,
                  });
                  await callGraphQL(
                    "Could not update setting",
                    graphqlOperation(updateOrganisation, {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    })
                  );
                }}
              >
                <span className="setting-name">{NAME}</span>
                <br />
                <span className="setting-description">{DESCRIPTION}</span>
              </Checkbox>
            );
          })}
        </Checkbox.Group>

        <br />

        {TIMESHEET_SETTINGS.filter((setting) => setting.INPUT_TYPE === "text").map((module) => {
          const { NAME, DESCRIPTION, SCHEMA_FIELD } = module;

          return (
            <InfoItem
              key={SCHEMA_FIELD}
              label={NAME}
              value={<Typography.Text className="setting-description">{DESCRIPTION}</Typography.Text>}
              extraContent={
                <Input
                  defaultValue={
                    organisationDetails.settings?.timesheet &&
                    organisationDetails.settings?.timesheet[SCHEMA_FIELD.split(".").pop()]
                  }
                  showBorder
                  fullWidth
                  onChange={async (value) => {
                    await callGraphQL(
                      "Could not update setting",
                      graphqlOperation(updateOrganisation, {
                        input: {
                          id: organisationDetails.id,
                          settings: {
                            ...organisationDetails?.settings,
                            timesheet: {
                              ...(organisationDetails.settings?.timesheet || {}),
                              [SCHEMA_FIELD.split(".").pop()]: value || null,
                            },
                          },
                        },
                      })
                    );
                  }}
                />
              }
            />
          );
        })}
      </Card>
      <Card
        withSpace
        title="Timesheet tags"
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            data-cy="add-new-tag-button"
            onClick={() => setIsAddNewTagModalVisible(true)}
          >
            Add new tag
          </Button>
        }
      >
        <Table
          dataSource={timesheetTags}
          columns={tagColumns}
          pagination={{ hideOnSinglePage: true, pageSize: 50 }}
          showHeader={false}
        />
      </Card>
      <CreateTagModal
        visible={isAddNewTagModalVisible}
        onClose={() => setIsAddNewTagModalVisible(false)}
        timesheetTags={timesheetTags}
      />
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimesheetSettingsPage,
    subscriptions: ["organisationDetails", "timesheetTags"],
  })
);
