import { useEffect } from "react";
import { useGetSetState } from "react-use";
import cx from "classnames";
import { useCallback } from "react";

import "./TemplateObjectResizeHandler.scss";

const KEYBOARD_DEFAULT_STEP = 1;
const KEYBOARD_SHIFT_STEP = 10;

export default function TemplateObjectResizeHandler({ object, scale, onResize, onDrag }) {
  const [getState, setState] = useGetSetState({
    isResizing: false,
    isDragging: false,
    startX: 0,
    startY: 0,
    deltaX: 0,
    deltaY: 0,
    objectTop: object.top,
    objectLeft: object.left,
    objectWidth: object.width,
    objectHeight: object.height,
    resizeDirection: undefined,

    newObjectTop: object.top,
    newObjectLeft: object.left,
    newObjectWidth: object.width,
    newObjectHeight: object.height,
  });

  let allowHeightResize = false;
  let allowWidthResize = false;

  useEffect(() => {
    document.addEventListener("mousemove", onMouseMove);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("mousemove", onMouseMove);
      document.removeEventListener("keydown", onKeyDown);
    };
  });

  useEffect(() => {
    setState({
      objectTop: object.top,
      objectLeft: object.left,
      objectWidth: object.width,
      objectHeight: object.height,

      newObjectTop: object.top,
      newObjectLeft: object.left,
      newObjectWidth: object.width,
      newObjectHeight: object.height,
    });
  }, [object]);

  useEffect(() => {
    window.addEventListener("mouseup", onMouseUp);

    return () => {
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, []);

  switch (object.custom_type) {
    case "qr-code":
      allowWidthResize = true;
      break;

    case "signature":
    case "image_container":
      allowWidthResize = true;
      allowHeightResize = true;
      break;

    default:
      if (object.custom_type && object.custom_type.length > 0) {
        break;
      }
      switch (object.type) {
        case "rect":
        case "image":
        case "ellipse":
          allowHeightResize = true;
          allowWidthResize = true;

        case "text":
          allowWidthResize = true;
          break;

        default:
          break;
      }
  }

  const onMouseDownDrag = (e) => {
    e.stopPropagation();
    setState({
      isDragging: true,
      startX: e.clientX,
      startY: e.clientY,
    });
  };

  const onMouseDown = (direction) => (e) => {
    e.stopPropagation();
    setState({
      isResizing: true,
      startX: e.clientX,
      startY: e.clientY,
      resizeDirection: direction,
    });
  };

  const onMouseMove = useCallback(
    (e) => {
      const {
        isResizing,
        isDragging,
        startX,
        startY,
        resizeDirection,
        objectTop,
        objectLeft,
        objectWidth,
        objectHeight,
      } = getState();
      if (!isResizing && !isDragging) {
        return;
      }

      let deltaX = (e.clientX - startX) / scale;
      let deltaY = (e.clientY - startY) / scale;

      if (isResizing) {
        if (resizeDirection === "left" || resizeDirection === "right") {
          deltaY = 0;
        } else if (resizeDirection === "top" || resizeDirection === "bottom") {
          deltaX = 0;
        }
      }

      let newObjectProps = {};

      switch (resizeDirection) {
        case "left":
          newObjectProps = {
            newObjectWidth: objectWidth - deltaX,
            newObjectLeft: objectLeft + deltaX,
          };
          break;
        case "right":
          newObjectProps = {
            newObjectWidth: objectWidth + deltaX,
          };
          break;
        case "top":
          newObjectProps = {
            newObjectHeight: objectHeight - deltaY,
            newObjectTop: objectTop + deltaY,
          };
          break;
        case "bottom":
          newObjectProps = {
            newObjectHeight: objectHeight + deltaY,
          };
          break;
        case "top-left":
          newObjectProps = {
            newObjectWidth: objectWidth - deltaX,
            newObjectHeight: objectHeight - deltaY,
            newObjectTop: objectTop + deltaY,
            newObjectLeft: objectLeft + deltaX,
          };
          break;
        case "top-right":
          newObjectProps = {
            newObjectWidth: objectWidth + deltaX,
            newObjectHeight: objectHeight - deltaY,
            newObjectTop: objectTop + deltaY,
          };
          break;
        case "bottom-left":
          newObjectProps = {
            newObjectWidth: objectWidth - deltaX,
            newObjectHeight: objectHeight + deltaY,
            newObjectLeft: objectLeft + deltaX,
          };
          break;
        case "bottom-right":
          newObjectProps = {
            newObjectWidth: objectWidth + deltaX,
            newObjectHeight: objectHeight + deltaY,
          };
          break;
      }

      setState({ deltaX, deltaY, ...newObjectProps });
    },
    [getState, setState, scale]
  );

  function onKeyDown(e) {
    const { objectTop, objectLeft } = getState();
    let onDragParams = {};
    let step = KEYBOARD_DEFAULT_STEP;

    if (e.shiftKey) {
      step = KEYBOARD_SHIFT_STEP;
    }

    if (e.key === "ArrowRight") {
      onDragParams = {
        top: objectTop,
        left: Math.round(objectLeft + step),
      };
    } else if (e.key === "ArrowLeft") {
      onDragParams = {
        top: objectTop,
        left: Math.round(objectLeft - step),
      };
    } else if (e.key === "ArrowUp") {
      onDragParams = {
        top: Math.round(objectTop - step),
        left: objectLeft,
      };
    } else if (e.key === "ArrowDown") {
      onDragParams = {
        top: Math.round(objectTop + step),
        left: objectLeft,
      };
    } else {
      return;
    }

    onDrag(onDragParams);
  }

  const onMouseUp = useCallback(
    (e) => {
      const {
        isResizing,
        isDragging,
        deltaX,
        deltaY,
        objectWidth,
        objectHeight,
        objectLeft,
        objectTop,
        newObjectTop,
        newObjectLeft,
        newObjectWidth,
        newObjectHeight,
      } = getState();
      e.stopPropagation();
      e.preventDefault();

      if (isDragging) {
        if (onDrag && typeof onDrag === "function") {
          onDrag({
            top: (objectTop || 0) + (deltaY || 0),
            left: (objectLeft || 0) + (deltaX || 0),
          });
        }
        setTimeout(() => {
          setState({
            objectTop: objectTop + deltaY,
            objectLeft: objectLeft + deltaX,
            objectWidth,
            objectHeight,
          });
        }, 300);
      } else if (isResizing) {
        if (onResize && typeof onResize === "function") {
          let onResizeParams = {
            top: newObjectTop,
            left: newObjectLeft,
            width: newObjectWidth,
            height: newObjectHeight,
            object,
          };

          onResize(onResizeParams);
        }
        setTimeout(() => {
          setState({
            objectTop: newObjectTop,
            objectLeft: newObjectLeft,
            objectWidth: newObjectWidth,
            objectHeight: newObjectHeight,
          });
        }, 300);
      }

      setState({
        isResizing: false,
        isDragging: false,
        deltaX: 0,
        deltaY: 0,
        startX: undefined,
        startY: undefined,
      });
    },
    [getState, setState, onResize, object]
  );

  const { isResizing, isDragging, deltaX, deltaY, resizeDirection, newObjectWidth, newObjectHeight } = getState();

  let style = {};

  if (isResizing) {
    switch (resizeDirection) {
      case "left":
        style = {
          width: `calc(200% + 4px + ${-deltaX * 2 || 0}px`,
          left: `calc(-1px + ${deltaX || 0}px`,
        };
        break;
      case "right":
        style = {
          width: `calc(200% + 4px + ${deltaX * 2 || 0}px`,
        };
        break;
      case "top":
        style = {
          height: `calc(200% + 4px + ${-deltaY * 2 || 0}px`,
          top: `calc(-1px + ${deltaY || 0}px`,
        };
        break;
      case "bottom":
        style = {
          height: `calc(200% + 4px + ${deltaY * 2 || 0}px`,
        };
        break;
      case "top-left":
        style = {
          width: `calc(200% + 4px + ${-deltaX * 2 || 0}px`,
          height: `calc(200% + 4px + ${-deltaY * 2 || 0}px`,
          left: `calc(-1px + ${deltaX || 0}px`,
          top: `calc(-1px + ${deltaY || 0}px`,
        };
        break;
      case "top-right":
        style = {
          width: `calc(200% + 4px + ${deltaX * 2 || 0}px`,
          height: `calc(200% + 4px + ${-deltaY * 2 || 0}px`,
          top: `calc(-1px + ${deltaY || 0}px`,
        };
        break;
      case "bottom-left":
        style = {
          width: `calc(200% + 4px + ${-deltaX * 2 || 0}px`,
          height: `calc(200% + 4px + ${deltaY * 2 || 0}px`,
          left: `calc(-1px + ${deltaX || 0}px`,
        };
        break;
      case "bottom-right":
        style = {
          width: `calc(200% + 4px + ${deltaX * 2 || 0}px`,
          height: `calc(200% + 4px + ${deltaY * 2 || 0}px`,
        };
        break;
    }
  } else if (isDragging) {
    style = {
      left: `calc(-1px + ${deltaX || 0}px`,
      top: `calc(-1px + ${deltaY || 0}px`,
    };
  }

  let scaleForResizeHandlers = Math.max(1 / scale, 0.1);

  return (
    <div
      className={cx("template-object-resize-handler", {
        "no-events": !allowWidthResize && !allowHeightResize,
      })}
      style={style}
      onMouseDown={onMouseDownDrag}
    >
      {allowWidthResize && (
        <div
          className={cx("template-object-resize-handler template-object-resize-handler-left")}
          style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
          onMouseDown={onMouseDown("left")}
        />
      )}
      {allowWidthResize && (
        <div
          className={cx("template-object-resize-handler template-object-resize-handler-right")}
          style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
          onMouseDown={onMouseDown("right")}
        />
      )}
      {allowHeightResize && (
        <div
          className={cx("template-object-resize-handler template-object-resize-handler-top")}
          style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
          onMouseDown={onMouseDown("top")}
        />
      )}
      {allowHeightResize && (
        <div
          className={cx("template-object-resize-handler template-object-resize-handler-bottom")}
          style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
          onMouseDown={onMouseDown("bottom")}
        />
      )}
      {allowHeightResize && allowWidthResize && (
        <>
          <div
            className={cx("template-object-resize-handler template-object-resize-handler-top-left")}
            style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
            onMouseDown={onMouseDown("top-left")}
          />
          <div
            className={cx("template-object-resize-handler template-object-resize-handler-top-right")}
            style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
            onMouseDown={onMouseDown("top-right")}
          />
          <div
            className={cx("template-object-resize-handler template-object-resize-handler-bottom-left")}
            style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
            onMouseDown={onMouseDown("bottom-left")}
          />
          <div
            className={cx("template-object-resize-handler template-object-resize-handler-bottom-right")}
            style={{ transform: `scale(${scaleForResizeHandlers}) translate(-50%, -50%)` }}
            onMouseDown={onMouseDown("bottom-right")}
          />
        </>
      )}
    </div>
  );
}
