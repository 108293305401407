import { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { message, Table, Tag, Button, Modal, Typography, Dropdown, Menu, Checkbox } from "antd";
import { DownOutlined } from "@ant-design/icons";
import cx from "classnames";
import moment from "moment";

import withSubscriptions from "common/withSubscriptions";
import { getSimpleLabel } from "common/labels";
import { stringToColor, getLabel, processIdForDisplay } from "common/helpers";
import { getFeesForClient } from "common/feeHelpers";
import { roundToQuarter } from "common/mathHelpers";
import { getInvoicingStatusTag } from "common/invoiceHelpers/invoiceHelpers";
import { enhanceQuoteWithInvoicing } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { recalculateInvoiceAmounts } from "common/invoiceHelpers";
import { callGraphQLSimple } from "common/apiHelpers";

import Avatar from "Avatar/Avatar";
import RecordingSymbol from "reusableComponents/RecordingSymbol/RecordingSymbol";

import "./TimesheetBlocksTable.scss";

export function TimesheetBlocksTable({
  organisationDetails,
  quotes,
  clients,
  timesheetTags,
  blocks,
  users,
  triggerRefresh,
  includeInvoicingStatus = true,
  includeQuoteId = false,
  includeOnHold = false,
  includeActions = false,
  isLoading = false,
  customActions = null,
  includeCheckboxes = false,
  selectedRowIds = {},
  onSelectRow = () => {},
  onDeselectRow = () => {},
}) {
  const [tasksWithRevisions, setTasksWithRevisions] = useState([]);

  useEffect(() => {
    if (organisationDetails.settings?.timesheet?.canAssignTimesheetBlocksToTaskRevisions === false) {
      return;
    }
    async function fetchTasksWithRevisions() {
      const tasksWithRevisions = (
        await callGraphQLSimple({
          message: `Failed to fetch ${getSimpleLabel("tasks")}`,
          queryRaw: `
          query ListTasksByOrganisation(
            $organisation: ID
            $createdAt: ModelStringKeyConditionInput
            $sortDirection: ModelSortDirection
            $filter: ModelTaskFilterInput
            $limit: Int
            $nextToken: String
          ) {
            listTasksByOrganisation(
              organisation: $organisation
              createdAt: $createdAt
              sortDirection: $sortDirection
              filter: $filter
              limit: $limit
              nextToken: $nextToken
            ) {
              items {
                id
                title
                revisions {
                  items {
                    id
                    name
                    description
                  }
                }
              }
              nextToken
            }
          }
        `,
          variables: {
            organisation: organisationDetails.id,
          },
        })
      ).data.listTasksByOrganisation.items;

      setTasksWithRevisions(tasksWithRevisions);
    }

    fetchTasksWithRevisions();
  }, []);
  async function confirmRemoveFromInvoice(timesheetBlockRow) {
    const invoiceDetails = (
      await window.callGraphQLSimple({
        message: "Failed to fetch invoice details",
        queryCustom: "getInvoice",
        variables: {
          id: timesheetBlockRow.timesheetBlock.invoiceId,
        },
      })
    ).data.getInvoice;

    if (invoiceDetails?.reviewStatus === "SUCCESS") {
      message.error("The invoice has already been approved and cannot be edited.");
      return;
    }

    await new Promise((resolve) => {
      let userDetails = users.find((x) => x.id === timesheetBlockRow.userId);
      Modal.confirm({
        title: "Confirm removal from invoice",
        content: (
          <>
            Are you sure you want to remove{" "}
            <b>
              {timesheetBlockRow.durationHours} hour
              {timesheetBlockRow.durationHours === 1 ? "" : "s"}
            </b>{" "}
            for{" "}
            <b>
              {userDetails.firstName} {userDetails.lastName}
            </b>{" "}
            on <b>{moment(timesheetBlockRow.startAt).format("DD-MM-YYYY")}</b> from invoice{" "}
            <b>{timesheetBlockRow.timesheetBlock.invoiceId}</b>?
          </>
        ),
        onOk: async () => {
          const { user, ...timesheetBlock } = timesheetBlockRow.timesheetBlock;
          let updatedBlock = JSON.parse(JSON.stringify(timesheetBlock));
          updatedBlock.invoicingStatus = null;
          updatedBlock.invoiceId = "nothing";

          await window.callGraphQLSimple({
            message: "Failed to remove timesheet block from invoice",
            mutation: "updateTimesheetBlock",
            variables: {
              input: {
                id: timesheetBlock.id,
                invoicingStatus: null,
                invoiceId: "nothing",
              },
            },
          });

          if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
            await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
          }

          if (triggerRefresh) {
            triggerRefresh(updatedBlock);
          }
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  async function confirmWriteOffTimesheetBlock(timesheetBlockRow) {
    await new Promise((resolve) => {
      let userDetails = users.find((x) => x.id === timesheetBlockRow.userId);
      Modal.confirm({
        title: "Confirm write off",
        content: (
          <>
            Are you sure you want to write off{" "}
            <b>
              {timesheetBlockRow.durationHours} hour
              {timesheetBlockRow.durationHours === 1 ? "" : "s"}
            </b>{" "}
            for{" "}
            <b>
              {userDetails.firstName} {userDetails.lastName}
            </b>{" "}
            on <b>{moment(timesheetBlockRow.startAt).format("DD-MM-YYYY")}</b> ?
          </>
        ),
        onOk: async () => {
          const { user, ...timesheetBlock } = timesheetBlockRow.timesheetBlock;
          let updatedBlock = JSON.parse(JSON.stringify(timesheetBlock));
          updatedBlock.invoicingStatus = "WRITE_OFF";
          updatedBlock.invoiceId = "nothing";

          await window.callGraphQLSimple({
            message: "Failed to write off timesheet block",
            mutation: "updateTimesheetBlock",
            variables: {
              input: {
                id: timesheetBlock.id,
                invoicingStatus: "WRITE_OFF",
                invoiceId: "nothing",
              },
            },
          });

          if (timesheetBlock.invoiceId && timesheetBlock.invoiceId !== "nothing") {
            await recalculateInvoiceAmounts({ invoiceId: timesheetBlock.invoiceId });
          }

          if (triggerRefresh) {
            triggerRefresh(updatedBlock);
          }
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  async function confirmMarkTimesheetBlockAsInvoiced(timesheetBlockRow) {
    await new Promise((resolve) => {
      let userDetails = users.find((x) => x.id === timesheetBlockRow.userId);
      Modal.confirm({
        title: "Confirm action",
        content: (
          <>
            Are you sure you want to mark{" "}
            <b>
              {timesheetBlockRow.durationHours} hour
              {timesheetBlockRow.durationHours === 1 ? "" : "s"}
            </b>{" "}
            for{" "}
            <b>
              {userDetails.firstName} {userDetails.lastName}
            </b>{" "}
            as invoiced on <b>{moment(timesheetBlockRow.startAt).format("DD-MM-YYYY")}</b> ?
          </>
        ),
        onOk: async () => {
          let updatedBlock = JSON.parse(JSON.stringify(timesheetBlockRow.timesheetBlock));
          updatedBlock.invoicingStatus = "INVOICED";
          updatedBlock.invoiceId = "nothing";

          await window.callGraphQLSimple({
            message: "Failed to mark timesheet block as invoiced",
            mutation: "updateTimesheetBlock",
            variables: {
              input: {
                id: timesheetBlockRow.timesheetBlock.id,
                invoicingStatus: "INVOICED",
                invoiceId: "nothing",
              },
            },
          });

          if (triggerRefresh) {
            triggerRefresh(updatedBlock);
          }
          resolve();
        },
        onCancel: () => {
          resolve();
        },
      });
    });
  }

  async function markTimesheetBlockAsOnHold(timesheetBlockRow) {
    let updatedBlock = JSON.parse(JSON.stringify(timesheetBlockRow.timesheetBlock));
    updatedBlock.invoicingStatus = "ON_HOLD";
    updatedBlock.invoiceId = "nothing";

    await window.callGraphQLSimple({
      message: "Failed to mark timesheet block as on hold",
      mutation: "updateTimesheetBlock",
      variables: {
        input: {
          id: timesheetBlockRow.timesheetBlock.id,
          invoicingStatus: "ON_HOLD",
          invoiceId: "nothing",
        },
      },
    });

    if (triggerRefresh) {
      triggerRefresh(updatedBlock);
    }
  }

  async function markTimesheetBlockAsNotOnHold(timesheetBlockRow) {
    let updatedBlock = JSON.parse(JSON.stringify(timesheetBlockRow.timesheetBlock));
    updatedBlock.invoicingStatus = null;
    updatedBlock.invoiceId = "nothing";

    await window.callGraphQLSimple({
      message: "Failed to mark timesheet block as not on hold",
      mutation: "updateTimesheetBlock",
      variables: {
        input: {
          id: timesheetBlockRow.timesheetBlock.id,
          invoicingStatus: null,
          invoiceId: "nothing",
        },
      },
    });

    if (triggerRefresh) {
      triggerRefresh(updatedBlock);
    }
  }

  let columns = [
    {
      title: "Date",
      align: "left",
      width: 90,
      render: (_, row) => moment(row.date).format("DD-MM-YYYY"),
    },
    {
      title: "User",
      align: "left",
      dataIndex: "avatar",
      width: 50,
    },
    {
      title: "Start time",
      align: "center",
      dataIndex: "startTime",
      width: 60,
    },
    {
      title: "End time",
      align: "center",
      dataIndex: "endTime",
      width: 60,
    },
    {
      title: "Hours",
      width: 50,
      align: "center",
      render: (_, row) => {
        let result = roundToQuarter(row.durationHours);

        return result;
      },
    },
    {
      title: "Fee level",
      dataIndex: "feeRoleToDisplay",
      key: "feeRoleToDisplay",
      align: "left",
      width: 150,
    },
    !organisationDetails.settings?.general?.hideFinancials && {
      title: "Rate",
      dataIndex: "rate",
      key: "rate",
      width: 50,
      align: "center",
      render: (_, row, index) => {
        return (
          <span data-cy="timesheet-block-rate" data-row-index={index}>
            {row.rate}
          </span>
        );
      },
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      align: "left",
      width: 150,
    },
  ].filter((x) => x);
  if (includeQuoteId) {
    columns.push({
      title: `${getSimpleLabel("Quote")} ID`,
      align: "center",
      width: 120,
      render: (_, row) => {
        if (!row.quoteId || row.quoteId === "nothing") {
          return null;
        }

        let quote = quotes.find((x) => x.id === row.quoteId);
        const enhancedQuote = enhanceQuoteWithInvoicing(quote);

        return (
          <div>
            <Link to={`/quotes/${row.quoteId}`} target="_blank" onClick={(e) => e.stopPropagation()}>
              <Tag className="dark-tag">{processIdForDisplay(row.quoteId)}</Tag>
            </Link>
            <div style={{ marginTop: "0.2rem" }}>
              {quote?.isArchived && <Tag color="#003a54">Archived</Tag>}
              {getInvoicingStatusTag({
                invoicingStatus: enhancedQuote?.invoicingStatus,
                invoicedAmount: enhancedQuote?.invoicedAmount,
              })}
              {quote?.status === "REJECTED" && <Tag color="#ff4d4f">{getSimpleLabel("Quote")} rejected</Tag>}
            </div>
          </div>
        );
      },
    });
    columns.push({
      title: `${getSimpleLabel("Quote")} line item`,
      align: "center",
      width: 70,
      render: (_, row) => {
        if (!row.quoteId || row.quoteId === "nothing") {
          return null;
        }

        const enhancedQuote = enhanceQuoteWithInvoicing(quotes.find((x) => x.id === row.quoteId));
        let quoteLineItem = enhancedQuote?.lineItems.items.find((x) => x.id === row.quoteLineItemId);

        return (
          <div>
            {quoteLineItem && (
              <>
                <Typography.Text>{quoteLineItem.title}</Typography.Text>
                <div style={{ marginTop: "0.2rem", display: "flex", flexDirection: "column", gap: "0.3rem" }}>
                  {getInvoicingStatusTag({
                    invoicingStatus: quoteLineItem.invoicingStatus,
                    invoicedAmount: quoteLineItem.invoicedAmount,
                  })}
                  {quoteLineItem.isRejected && <Tag color="#ff4d4f">{getSimpleLabel("Quote")} line item rejected</Tag>}
                </div>
              </>
            )}
          </div>
        );
      },
    });
  }
  columns.push({
    title: `${getLabel({
      id: "Task",
      defaultValue: "Task",
    })} ID`,
    key: "taskId",
    align: "center",
    width: 120,
    render: (_, row) => {
      if (!row.taskId || row.taskId === "nothing") {
        return null;
      }
      return (
        <Link to={`/tasks/${row.taskId}`} target="_blank" onClick={(e) => e.stopPropagation()}>
          <Tag className="dark-tag">{processIdForDisplay(row.taskId)}</Tag>
        </Link>
      );
    },
  });

  if (organisationDetails.settings?.timesheet?.canAssignTimesheetBlocksToTaskRevisions) {
    columns.push({
      title: `${getSimpleLabel("Task revision")}`,
      align: "center",
      width: 120,
      render: (_, row) => {
        if (!row.taskRevisionId || row.taskRevisionId === "nothing") {
          return null;
        }
        let targetTask = tasksWithRevisions.find((x) => x.id === row.taskId);
        if (!targetTask) {
          return null;
        }

        let taskRevisionDetails = targetTask.revisions.items.find((x) => x.id === row.taskRevisionId);

        if (!taskRevisionDetails) {
          return null;
        }

        return (
          <>
            <Typography.Text>
              <b>{taskRevisionDetails.name}</b> <br /> ({taskRevisionDetails.description})
            </Typography.Text>
          </>
        );
      },
    });
  }

  columns.push({ title: "", dataIndex: "tags", key: "tags", align: "left", width: 100 });
  if (includeCheckboxes) {
    columns.push({
      title: "Select for actions",
      key: "select",
      align: "center",
      width: 60,
      render: (_, row) => {
        let isChecked = selectedRowIds?.has(row.id);

        return (
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <Checkbox
              checked={isChecked}
              onChange={() => {
                if (isChecked) {
                  onDeselectRow(row);
                } else {
                  onSelectRow(row);
                }
              }}
            />
          </div>
        );
      },
    });
  }

  if (customActions) {
    columns.push({
      title: "",
      width: 60,
      align: "center",
      render: (_, row, index) => {
        return customActions(row, index);
      },
    });
  } else if (organisationDetails.settings?.invoice?.usesInvoices && includeActions) {
    columns.push({
      title: "",
      width: 60,
      align: "center",
      render: (_, row) => {
        return (
          <Dropdown
            trigger="click"
            overlay={
              <Menu>
                <Menu.Item
                  key="open-timesheet"
                  onClick={(e) => {
                    if (e.stopPropagation) {
                      e.stopPropagation();
                    }
                    // debugger;
                    let newWindow = window.open(`/user-timesheet/${row.userId}/${row.date}`, "_blank");
                    if (newWindow) {
                      newWindow.focus();
                    }
                  }}
                >
                  Open timesheet on this date
                </Menu.Item>
                <Menu.Divider />
                {row.timesheetBlock.invoiceId && row.timesheetBlock.invoiceId !== "nothing" && (
                  <>
                    <Menu.Item
                      key="remove-from-invoice"
                      onClick={(e) => {
                        confirmRemoveFromInvoice(row);
                      }}
                    >
                      Remove from invoice ({row.timesheetBlock.invoiceId})
                    </Menu.Item>
                    <Menu.Divider />
                  </>
                )}

                <Menu.Item
                  key="write-off"
                  onClick={(e) => {
                    confirmWriteOffTimesheetBlock(row);
                  }}
                >
                  Write off
                </Menu.Item>
                <Menu.Divider />
                <Menu.Item
                  key="mark-as-invoiced"
                  onClick={(e) => {
                    confirmMarkTimesheetBlockAsInvoiced(row);
                  }}
                >
                  Mark as previously invoiced
                </Menu.Item>

                {includeOnHold && (
                  <>
                    <Menu.Divider />
                    {row.invoicingStatus === "ON_HOLD" ? (
                      <Menu.Item
                        key="not-on-hold"
                        onClick={(e) => {
                          markTimesheetBlockAsNotOnHold(row);
                        }}
                      >
                        Mark as not on hold
                      </Menu.Item>
                    ) : (
                      <Menu.Item
                        key="put-on-hold"
                        onClick={(e) => {
                          markTimesheetBlockAsOnHold(row);
                        }}
                      >
                        Put on hold
                      </Menu.Item>
                    )}
                  </>
                )}
              </Menu>
            }
          >
            <Button icon={<DownOutlined />} onClick={(e) => e.stopPropagation()}>
              Actions
            </Button>
          </Dropdown>
        );
      },
    });
  }

  return (
    <Table
      className="timesheet-blocks-table"
      pagination={{ hideOnSinglePage: true, pageSize: 500 }}
      scroll={{ x: 1200 }}
      expandRowByClick={true}
      loading={isLoading}
      onRow={(blockRow) => {
        return {
          onClick: (e) => {
            if (e.target?.className?.includes("ant-dropdown-menu")) {
              return;
            }
            let newWindow = window.open(`/user-timesheet/${blockRow.userId}/${blockRow.date}`, "_blank");
            if (newWindow) {
              newWindow.focus();
            }
          },
        };
      }}
      columns={columns}
      rowClassName={(row) => {
        let classNames = [];
        if (row.variation) {
          classNames.push("variation-row");
        }
        return cx(classNames);
      }}
      dataSource={[...blocks]
        .sort((a, b) => {
          if (a.startAt < b.startAt) {
            return -1;
          }
          if (a.startAt > b.startAt) {
            return 1;
          }

          if (a.taskId < b.taskId) {
            return -1;
          }
          if (a.taskId > b.taskId) {
            return 1;
          }

          if (a.quoteId < b.quoteId) {
            return -1;
          }
          if (a.quoteId > b.quoteId) {
            return 1;
          }

          if ((a.description || "") < (b.description || "")) {
            return -1;
          }
          if ((a.description || "") > (b.description || "")) {
            return 1;
          }

          return 0;
        })
        .map((timesheetBlock) => {
          let endAt = timesheetBlock.endAt;
          if (timesheetBlock.isRecording) {
            endAt = moment().toISOString();
          }
          const durationHours = moment(endAt).diff(timesheetBlock.startAt, "hours", true);

          const quote = quotes.find((x) => x.id === timesheetBlock.quoteId) || {};

          let client = clients.find((x) => x.id === (timesheetBlock.clientId || quote?.clientId));

          let feeRoleToDisplay = "";
          let rateToDisplay = "";

          // if (!client) {
          //   feeRoleToDisplay = `${getSimpleLabel("Client")} not found`;
          //   rateToDisplay = `${getSimpleLabel("Client")} not found`;
          // } else {
          let feesData = getFeesForClient({
            organisationDetails,
            quote,
            clientDetails: client,
            currency: "GBP",
          });

          let feeRoleForTimesheet = feesData.find((x) => x.id === timesheetBlock.feeRole);
          feeRoleToDisplay = feeRoleForTimesheet?.label;

          if (feeRoleForTimesheet) {
            if (organisationDetails.settings?.general?.hideFinancials) {
              rateToDisplay = feeRoleForTimesheet.value;
            } else {
              rateToDisplay = window.formatCurrency("GBP", feeRoleForTimesheet.value);
            }
          } else {
            rateToDisplay = "";
            feeRoleToDisplay = "";
          }
          // }

          let { invoicingStatus } = timesheetBlock;
          let invoicingStatusTag = null;
          if (invoicingStatus === "INVOICED") {
            invoicingStatusTag = <Tag color="#19aae8">Invoiced</Tag>;
          } else if (invoicingStatus === "WRITE_OFF") {
            invoicingStatusTag = <Tag color="#ff4d4f">Write-off</Tag>;
          } else {
            invoicingStatusTag = <Tag color="#b8d1db">Not invoiced</Tag>;
          }

          return {
            ...timesheetBlock,
            timesheetBlock,
            key: timesheetBlock.id,
            durationHours,
            date: moment(timesheetBlock.startAt).format("DD MMMM YYYY"),
            startTime: moment(timesheetBlock.startAt).format("HH:mm"),
            endTime: timesheetBlock.isRecording ? (
              <>
                <Tag className="accent-tag">
                  <RecordingSymbol white />
                  Clocked in
                </Tag>
              </>
            ) : (
              moment(timesheetBlock.endAt).format("HH:mm")
            ),
            invoicingStatusTag,
            feeRoleToDisplay: feeRoleToDisplay,
            avatar: <Avatar user={users.find((x) => x.id === timesheetBlock.userId)} disableUserCard />,
            rate: rateToDisplay,
            tags: (
              <div className="timesheet-block-tags">
                {organisationDetails.settings?.invoice?.usesInvoices && includeInvoicingStatus && invoicingStatusTag}
                {timesheetBlock.variation && <Tag color="#ff4d4f">Variation</Tag>}
                {includeOnHold && timesheetBlock.invoicingStatus === "ON_HOLD" && <Tag color="#ffbc0d">On hold</Tag>}
                {timesheetBlock.tags?.map((tagId, i) => {
                  const tagDefinition = timesheetTags.find((x) => x.id === tagId);
                  if (!tagDefinition) {
                    return null;
                  }
                  return (
                    <Tag
                      key={i}
                      color={stringToColor({
                        string: tagDefinition.label,
                        saturation: 70,
                        lightness: 45,
                      })}
                    >
                      {tagDefinition.label}
                    </Tag>
                  );
                })}
              </div>
            ),
          };
        })}
    />
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimesheetBlocksTable,
    subscriptions: ["users", "tasks", "projects", "clients", "quotes", "organisationDetails", "timesheetTags"],
  })
);
