import moment from "moment";
import { Typography, notification, Tag } from "antd";
import { RRule } from "rrule";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY } from "common/constants";

export function getUserTimeOffDataForDisplay({ users, holidays, interval, isSick }) {
  let validHolidays = [...holidays].sort((a, b) => (a.startsAt < b.startsAt ? -1 : 1));

  if (isSick) {
    validHolidays = validHolidays.filter((holiday) => holiday.isSick);
  } else {
    validHolidays = validHolidays.filter((holiday) => !holiday.isSick);
  }

  if (interval) {
    validHolidays = validHolidays.filter((holiday) => {
      return moment(holiday.startsAt).isBetween(interval.startDate, interval.endDate);
    });
  }

  let tableData = validHolidays.map((holiday) => {
    let temporalStatus = "";
    if (moment(holiday.startsAt).isBefore(moment(), "day")) {
      if (moment(holiday.endsAt).isAfter(moment(), "day")) {
        temporalStatus = "In progress";
      } else {
        temporalStatus = "Past";
      }
    } else {
      temporalStatus = "Future";
    }

    let approvalStatusTag = null;
    switch (holiday.status) {
      case "PENDING":
        approvalStatusTag = <Tag color="orange">Pending</Tag>;
        break;
      case "APPROVED":
        const approvedByUser = users.find((x) => x.id === holiday.approvedBy);
        approvalStatusTag = (
          <Tag color="green">
            Approved by {approvedByUser?.firstName} {approvedByUser?.lastName}
          </Tag>
        );
        break;
      case "REJECTED":
        const rejectedByUser = users.find((x) => x.id === holiday.rejectedBy);
        approvalStatusTag = (
          <Tag color="red">
            Rejected by {rejectedByUser?.firstName} {rejectedByUser?.lastName}
          </Tag>
        );
        break;
      default:
        break;
    }

    let numberOfDays = 0;

    holiday.days.forEach((day) => {
      if (day.endHours) {
        const numberOfHours = day.endHours - day.startHours;

        numberOfDays += numberOfHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      } else {
        numberOfDays += 1;
      }
    });

    return {
      ...holiday,
      key: holiday.userId,
      numberOfDays,
      dayList: holiday.days,
      approvalStatus: approvalStatusTag,
      temporalStatus,
      originalHoliday: holiday,
    };
  });

  return tableData;
}

export function getWorkingAndNonWorkingDaysForUser({ user, startDate, endDate }) {
  let nonWorkingDays = [];
  let workingDays = [];
  (user.workingHours || [])
    .filter((rule) => rule.repeatPattern?.length > 0)
    .forEach((rule) => {
      try {
        const rruleOptions = RRule.parseText(rule.repeatPattern);
        const startDateUTC = moment(startDate).add(moment().utcOffset(), "minutes").utc();
        const endDateUTC = moment(endDate).add(moment().utcOffset(), "minutes").utc();

        rruleOptions.dtstart = startDateUTC.toDate();
        rruleOptions.until = endDateUTC.toDate();
        const rrule = new RRule(rruleOptions);

        const occurences = rrule.all();
        workingDays.push(...occurences.map((dateObj) => moment(dateObj).format("YYYY-MM-DD")));
      } catch (e) {
        notification.error({
          message: (
            <Typography.Text>
              Failed to parse working hours rule:
              <br />
              <b>{rule.repeatPattern}</b>
            </Typography.Text>
          ),
        });
      }
    });
  return {
    workingDays,
    nonWorkingDays,
  };
}
