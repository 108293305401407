import { Checkbox, Button } from "antd";
import DatePicker from "DatePicker/DatePicker";
import moment from "moment";
import cx from "classnames";
import { LexoRank } from "lexorank";
import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import { graphqlOperation } from "aws-amplify";
import { deleteSubtask, updateSubtask, createSubtask } from "graphql/mutations";
import { getTaskSimple, updateTask } from "graphql/queries_custom";
import { callGraphQL } from "common/helpers";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import Input from "Input/Input";

import "./Subtasks.scss";

export function Subtasks({ apiUser, task, setProps, context }) {
  async function onCreateClick(e) {
    let newOrder = LexoRank.middle().genNext();
    if (task.subtasks.items.length > 0) {
      newOrder = LexoRank.parse(task.subtasks.items.slice(-1)[0].order).genNext();
    }

    let newSubtask = {
      id: `${Date.now()}${Math.floor(Math.random() * 10000)}`,
      title: "",
      author: apiUser.id,
      organisation: apiUser.organisation,
      parentId: task.id,
      order: newOrder.value,
    };

    setProps({
      context: {
        ...context,
        task: {
          ...task,
          subtasks: {
            items: [...task.subtasks.items, newSubtask].sort((a, b) => (a.order < b.order ? -1 : 1)),
          },
        },
      },
    });

    await callGraphQL(
      "Could not create subtask",
      graphqlOperation(createSubtask, {
        input: newSubtask,
      })
    );

    setTimeout(() => focusSubtaskAtIndex(task.subtasks.items.length), 200);
    updateProgress();
  }

  function focusSubtaskAtIndex(subtaskIndex) {
    let selector = `.subtask-item[data-index='${subtaskIndex}'] .input.item-title input`;
    console.log("selector = ", selector);
    let subtaskElement = document.querySelector(selector);
    if (!subtaskElement) {
      setTimeout(() => focusSubtaskAtIndex(subtaskIndex), 100);
      return;
    }
    subtaskElement.focus();
  }

  async function onDeleteClick(e, subtask) {
    setProps({
      context: {
        ...context,
        task: {
          ...task,
          subtasks: {
            items: [...task.subtasks.items].filter((crtSubtask) => {
              return crtSubtask.id !== subtask.id;
            }),
          },
        },
      },
    });

    await callGraphQL(
      "Could not delete subtask",
      graphqlOperation(deleteSubtask, {
        input: {
          id: subtask.id,
        },
      })
    );

    updateProgress();
  }

  async function updateProgress() {
    const updatedTask = (
      await callGraphQL(
        `Could not retrieve ${getSimpleLabel("task")} details`,
        graphqlOperation(getTaskSimple, {
          id: task.id,
        })
      )
    ).data.getTask;
    const doneSubtaskCount = updatedTask.subtasks.items.filter((x) => x.isFinished).length;
    const progress = Math.round((doneSubtaskCount / updatedTask.subtasks.items.length) * 100);
    await callGraphQL(
      `Could not update progress on ${getSimpleLabel("task")}`,
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          subtaskProgress: progress,
        },
      })
    );
  }

  async function onCheck(e, subtask) {
    setProps({
      context: {
        ...context,
        task: {
          ...task,
          subtasks: {
            items: [...task.subtasks.items]
              .map((crtSubtask) => {
                if (crtSubtask.id !== subtask.id) {
                  return crtSubtask;
                }
                return {
                  ...subtask,
                  isFinished: e.target.checked,
                };
              })
              .sort((a, b) => (a.order < b.order ? -1 : 1)),
          },
        },
      },
    });

    await callGraphQL(
      "Could not update subtask",
      graphqlOperation(updateSubtask, {
        input: {
          id: subtask.id,
          isFinished: e.target.checked,
        },
      })
    );

    updateProgress();
  }
  async function changeDueDate(momentDate, stringDate, subtask) {
    let formattedDate;
    if (stringDate.length === 0) {
      formattedDate = null;
    } else {
      formattedDate = momentDate.format("YYYY-MM-DD");
    }

    await callGraphQL(
      "Failed to update the due date",
      graphqlOperation(updateSubtask, {
        input: {
          id: subtask.id,
          dueDate: formattedDate,
        },
      })
    );
  }

  async function onChangeTitle(title, subtask) {
    await callGraphQL(
      "Could not update subtask",
      graphqlOperation(updateSubtask, {
        input: {
          id: subtask.id,
          title,
        },
      })
    );

    await callGraphQL(
      "Could not create subtask",
      graphqlOperation(updateTask, {
        input: {
          id: task.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );
  }

  return (
    <Card className="subtasks" title="Subtasks">
      <Button onClick={onCreateClick} type="primary">
        <PlusCircleOutlined /> Create subtask
      </Button>
      {task.subtasks.items?.length > 0 ? (
        <div className="subtasks-header">
          <div className="subtasks-title">Title</div>
          <div className="subtasks-due-date">Due date</div>
        </div>
      ) : null}
      <div className="subtask-list">
        {task.subtasks.items.map((subtask, i) => {
          return (
            <div
              data-index={i}
              className={cx("subtask-item", { "is-finished": subtask.isFinished })}
              key={subtask.id}
              data-cy="subtask-item"
            >
              <Checkbox
                onChange={(e) => {
                  onCheck(e, subtask);
                }}
                checked={subtask.isFinished}
                data-cy="check-subtask"
              />
              <Input
                defaultValue={subtask.title}
                className={"item-title"}
                data-cy="subtask-title"
                fullWidth
                placeholder={'type here to set the title, then press "Enter" to move to the next subtask'}
                onChange={(e) => onChangeTitle(e, subtask)}
                onEnterCreateSubtask={onCreateClick}
              />
              <DatePicker
                format="DD-MM-YYYY"
                className="active-on-hover item-due-date"
                defaultValue={subtask.dueDate ? moment(subtask.dueDate) : undefined}
                onChange={(e) => changeDueDate(e, "test", subtask)}
                placeholder="Not Set"
                data-cy="subtask-due-date"
                suffixIcon={null}
              />
              <Button
                onClick={(e) => onDeleteClick(e, subtask)}
                className="delete-item-button"
                data-cy="subtask-delete-button"
              >
                <DeleteOutlined />
              </Button>
            </div>
          );
        })}
      </div>
    </Card>
  );
}

export default withRouter(Subtasks);
