import { Modal, message } from "antd";
import { graphqlOperation } from "aws-amplify";
import awsExports from "aws-exports";
import { notification } from "antd";

import {
  callGraphQL,
  isFileOpen,
  showFileIsOpenModal,
  createFileVersionInApi,
  pointSheetsToLatestFileVersion,
  createSheetRevisionInApi,
  copyS3MainFile,
} from "common/helpers";
import { callGraphQLSimple, getRestEndpoint } from "common/apiHelpers";
import { getSheetRevisionName } from "./naming";
import linkApi from "common/link";
import { getFile } from "graphql/queries_custom";
import { createSheet, updateSheet, deleteSheet, updateFile } from "graphql/mutations";
import { KEY_TYPES, getLatestFileVersion, buildFileName } from "common/shared";
import { FILE_TYPES_READABLE } from "common/constants";

export default async function performSheetOperations({
  apiUser,
  task,
  file,
  taskRevision,
  operations,
  history,
  organisationDetails,
  messageKey,
}) {
  if (
    !organisationDetails.fileStatuses ||
    !organisationDetails.fileStatuses[0] ||
    !organisationDetails.fileStatuses[0].name
  ) {
    message.error("No file statuses have been defined, cannot continue");
    return;
  }

  const isLinkRunning = window.useLink || (await linkApi.checkLinkIsRunning());

  let defaultSheetStatus = organisationDetails.fileStatuses[0].name;

  const fileIsOpen = await isFileOpen({ task, file });
  if (fileIsOpen) {
    showFileIsOpenModal(file);
    return;
  }

  const oldFileVersion = getLatestFileVersion(file);
  const oldVersionNumber = oldFileVersion.versionNumber;
  let newVersionNumber = oldVersionNumber;

  let newFileVersion = oldFileVersion;

  newVersionNumber = oldVersionNumber + 1;
  newFileVersion = (
    await createFileVersionInApi({
      apiUser,
      file,
      task,
      taskRevision,
      versionNumber: newVersionNumber,
      processingStatus: "SUCCESS",
    })
  ).data.createFileVersion;

  for (let i = 0; i < operations.length; i++) {
    const operation = operations[i];
    const percentComplete = Math.round((i / operations.length) * 100);
    if (messageKey) {
      message.loading({ content: `Submitting: ${percentComplete}% done...`, key: messageKey, duration: 0 });
    }
    let updatedFile = (
      await callGraphQLSimple({
        message: "Failed to retrieve file",
        queryCustom: "getFile",
        variables: {
          id: file.id,
        },
      })
    ).data.getFile;
    let sheet;
    if (operation.sheetName) {
      sheet = updatedFile.sheets.items.find((sheet) => sheet.name === operation.sheetName);
    }
    switch (operation.type) {
      case "ADD_SHEET":
        const paramsForEncodeKey = {
          task,
          file: updatedFile,
          organisation: apiUser.organisation,
          projectId: task.projectId,
          taskId: task.id,
          clientInitials: task.client?.initials || "",
          projectInitials: task.project?.initials || "",
          taskInitials: task.initials,
          versionNumber: newFileVersion.versionNumber,
          fileId: updatedFile.id,
          taskRevisionName: taskRevision.name,
          fileType: updatedFile.type,
        };

        let newSheet = (
          await callGraphQL(
            "Failed to create sheet",
            graphqlOperation(createSheet, {
              input: {
                taskId: task.id,
                fileId: updatedFile.id,
                name: operation.newSheetName,
                description: operation.description,
                autoGeneratedReferenceNumber: await buildFileName(
                  {
                    ...paramsForEncodeKey,
                    sheetName: operation.newSheetName,
                  },
                  KEY_TYPES.SHEET_REFERENCE
                ),
                constantId: `${Date.now()}${Math.floor(Math.random() * 100000)}`,
                fileType: file.type,
                includeInPublish: true,
                order: operation.order,
              },
            })
          )
        ).data.createSheet;

        let newSheetRevisionName;
        if (organisationDetails.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
          newSheetRevisionName = taskRevision.name;
        } else {
          newSheetRevisionName = await getSheetRevisionName({
            organisation: task.organisation,
            newStatus: defaultSheetStatus,
            sheet: newSheet,
            task,
          });
        }
        await createSheetRevisionInApi({
          apiUser,
          task,
          sheet: newSheet,
          file: updatedFile,
          fileVersion: newFileVersion,
          taskRevision,
          status: defaultSheetStatus,
          name: newSheetRevisionName,
          description: "Initial issue",
        });

        break;
      case "REMOVE_SHEET":
        await callGraphQL(
          "Failed to delete sheet",
          graphqlOperation(deleteSheet, {
            input: {
              id: sheet.id,
            },
          })
        );
        break;
      case "RENAME_SHEET":
        sheet = updatedFile.sheets.items.find((sheet) => sheet.name === operation.oldSheetName);
        await callGraphQL(
          "Failed to update sheet",
          graphqlOperation(updateSheet, {
            input: {
              id: sheet.id,
              name: operation.newSheetName,
              description: operation.newSheetDescription,
            },
          })
        );
        break;
      default:
        break;
    }
  }

  let updatedFileWithContents = (
    await callGraphQL(
      "Failed to retrieve file",
      graphqlOperation(getFile, {
        id: file.id,
      })
    )
  ).data.getFile;

  await pointSheetsToLatestFileVersion({
    apiUser,
    task,
    file: updatedFileWithContents,
    taskRevision,
  });

  await new Promise((resolve) => setTimeout(resolve, 3000));
  console.log("updating itemSubscription");

  await callGraphQL(
    "Failed to update file",
    graphqlOperation(updateFile, {
      input: {
        id: file.id,
        itemSubscription: Math.floor(Math.random() * 1000000),
      },
    })
  );

  await copyS3MainFile({
    history,
    task,
    apiUser,
    taskRevision,
    file,
    oldVersionNumber,
    newVersionNumber,
    oldFileVersion,
    newFileVersion,
    extension: file.extension,
    openConfirmationModal: false,
  });

  let operationsReadyForAPI = operations.map((operation) => {
    return {
      type: operation.type,
      sheetName: operation.sheetName,
      copySheetName: operation.copySheetName,
      oldSheetName: operation.oldSheetName,
      newSheetName: operation.newSheetName,
    };
  });

  console.log("operationsReadyForAPI = ", operationsReadyForAPI);

  if (isLinkRunning) {
    const linkResponse = await linkApi.batchEdit({
      key: newFileVersion.key,
      publishedKey: newFileVersion.exports[0].rawKey,
      annotatedKey: newFileVersion.exports[0].key,
      projectId: task.projectId,
      taskId: task.id,
      fileId: file.id,
      organisation: task.organisation,
      taskRevisionId: taskRevision.id,
      fileVersionId: newFileVersion.id,
      versionNumber: newFileVersion.versionNumber,
      executable: file.type,
      taskRevisionName: taskRevision.name,
      isReadOnly: taskRevision.isReadOnly,
      customId: newFileVersion.customId,
      externalReferences: newFileVersion.externalReferences,
      operations: operationsReadyForAPI,
    });
    if (linkResponse.data.success) {
      notification["success"]({
        message: "Operation succeeded",
        description: `The new file is now open in ${FILE_TYPES_READABLE[file.type]}`,
      });
    } else {
      notification["error"]({
        message: `Operation failed`,
      });
    }
  } else {
    await callGraphQLSimple({
      message: "Failed to add sheet operation to the queue",
      queryCustom: "createAsyncJob",
      variables: {
        input: {
          fileType: file.type,
          taskId: task.id,
          fileId: file.id,
          type: "MANIPULATE_DWG",
          fileVersionId: newFileVersion.id,
          organisation: task.organisation,
          userId: window.apiUser.id,
          status: "PENDING",
          restUrl: getRestEndpoint(),
          bucket: awsExports.aws_user_files_s3_bucket,
          region: awsExports.aws_user_files_s3_bucket_region,
          graphQLUrl: awsExports.aws_appsync_graphqlEndpoint,
          operations: operationsReadyForAPI,
        },
      },
    });
    if (!window.Cypress) {
      setTimeout(() => {
        Modal.info({
          title: `We are generating a new ${FILE_TYPES_READABLE[file.type]} file`,
          content: (
            <>
              A new {FILE_TYPES_READABLE[file.type]} file is being created, containing your changes. Please re-open this
              when it is ready.
            </>
          ),
        });
      }, 1000);
    }
  }
}
