import { Modal, message } from "antd";
import { callRest } from "common/apiHelpers";

export async function createInvoice({
  organisationId,
  clientId,
  projectId,
  taskIds,
  quoteIds,
  quoteLineItemIds,
  timesheetBlockIds,
  templateId,
}: {
  organisationId: string;
  clientId: string;
  projectId: string;
  taskIds: string[] | undefined;
  quoteIds: string[] | undefined;
  quoteLineItemIds: string[] | undefined;
  timesheetBlockIds: string[] | undefined;
  templateId: string;
}) {
  if (!organisationId) throw new Error("organisationId is required");
  if (!projectId) throw new Error("projectId is required");
  if (!clientId) throw new Error("clientId is required");
  if (!templateId) throw new Error("templateId is required");

  if (taskIds) {
    taskIds = taskIds.filter((value, index, self) => self.indexOf(value) === index);
  }
  if (quoteIds) {
    quoteIds = quoteIds.filter((value, index, self) => self.indexOf(value) === index);
  }
  if (quoteLineItemIds) {
    quoteLineItemIds = quoteLineItemIds.filter((value, index, self) => self.indexOf(value) === index);
  }
  if (timesheetBlockIds) {
    timesheetBlockIds = timesheetBlockIds.filter((value, index, self) => self.indexOf(value) === index);
  }

  return await callRest({
    route: "/invoices/create",
    method: "post",
    // @ts-ignore
    body: {
      organisationId,
      quoteIds,
      quoteLineItemIds,
      projectId,
      taskIds,
      timesheetBlockIds,
      templateId,
      clientId,
    },
    includeCredentials: false,
  });
}

export async function recalculateInvoiceAmounts({ invoiceId }) {
  await callRest({
    message: "Failed to recalculate invoice amounts",
    route: "/invoices/recalculate-amounts",
    method: "POST",
    // @ts-ignore
    body: {
      invoiceId,
    },
    includeCredentials: false,
  });
}

export async function confirmDeleteInvoice({ invoiceId, organisationId, history }) {
  try {
    await new Promise<void>((resolve, reject) => {
      Modal.confirm({
        title: "Confirm invoice delete",
        maskClosable: true,
        content: (
          <>
            Are you sure you want to delete invoice <b>{invoiceId}</b>?
          </>
        ),
        okText: "Yes, delete",
        onOk: () => {
          resolve();
        },
        onCancel: () => {
          reject();
        },
      });
    });
  } catch (e) {
    // nothing, it just means the user selected "cancel"
    return;
  }

  await deleteInvoice({ invoiceId, history, organisationId });
}

export async function deleteInvoice({ invoiceId, organisationId, history, skipMessages = false }) {
  let messageKey = "deleting-invoice";
  if (!skipMessages) {
    message.loading({
      content: "Deleting invoice",
      key: messageKey,
      duration: 0,
    });
  }
  try {
    await callRest({
      route: "/invoices/delete",
      method: "post",
      // @ts-ignore
      body: {
        invoiceId,
        organisationId,
      },
      includeCredentials: false,
    });

    if (!skipMessages) {
      message.success({ content: "Invoice deleted", key: messageKey, duration: window.Cypress ? 0.5 : 2 });
    }
    if (window.refreshInvoices) {
      window.refreshInvoices();
    }
    if (history) {
      history.push(`/invoices`);
    }
  } catch (e) {
    console.error("error when deleting invoice:", e);
    message.error({
      content: "Failed to completely delete invoice",
      key: messageKey,
      duration: 5,
    });
  }
}

export async function deleteInvoiceLineItem({ invoiceId, lineItemId }) {
  await callRest({
    route: "/invoices/delete-line-item",
    method: "post",
    // @ts-ignore
    body: {
      invoiceId,
      lineItemId,
    },
    includeCredentials: false,
  });
}
