import { message, Input, Modal, Button } from "antd";
import { useGetSetState } from "react-use";

import { callGraphQLSimple } from "common/apiHelpers";
import {
  sendRequestInformationSubmittedNotification,
  sendRequestFormReceivedNotification,
} from "common/notificationHelpers";
import { getSimpleLabel } from "common/labels";
import { isFormValid } from "common/formValidators";

type Props = {
  request: any;
  apiUser: any;
  formFileId: string;
  users: any;
  formName: string;
  setValidationErrors: (validationErrors) => void;
  formCreationActivityItem: any;
};

export default function SendToEngineerButton({
  apiUser,
  request,
  formFileId,
  formName,
  users,
  setValidationErrors,
  formCreationActivityItem,
}: Props) {
  const [getState, setState] = useGetSetState({
    isSendMessageModalOpen: false,
    moreInformationMessageContent: "",
  });

  async function onClick() {
    const messageKey = "validating-request-form";
    message.loading({ content: `Validating ${getSimpleLabel("request")} form...`, key: messageKey, duration: 10 });

    let validationResult;
    try {
      validationResult = await isFormValid({ fileId: formFileId });
      if (validationResult?.isValid) {
        setValidationErrors([]);
        message.destroy(messageKey);
      } else {
        setValidationErrors(validationResult.validationErrors);
        message.error({
          content: "There are validation errors in the form",
          key: messageKey,
        });

        return;
      }
    } catch (e) {
      message.error({
        content: `Encountered an error while trying to to validate ${getSimpleLabel("request")} form`,
        key: messageKey,
      });
    }

    setState({ isSendMessageModalOpen: true });
  }

  async function submit() {
    const { moreInformationMessageContent } = getState();
    if (!moreInformationMessageContent) {
      message.error("Please include a message");
      return;
    }
    const messageKey = "send-information";
    message.loading({ content: "Notifying engineer...", key: messageKey, duration: 0 });

    try {
      setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
      await callGraphQLSimple({
        mutation: "updateRequest",
        message: `Failed to update status`,
        variables: {
          input: {
            id: request.id,
            status: "INFORMATION_SUBMITTED",
          },
        },
      });
    } catch (e) {
      message.error({
        content: "Failed to notify engineer",
        key: messageKey,
      });
      return;
    }

    try {
      if (request.assignedTo && request.assignedTo !== "nothing") {
        await sendRequestInformationSubmittedNotification({
          users,
          request,
          apiUser,
          formName,
          messageContent: moreInformationMessageContent,
          formFileId,
        });
      }

      await sendRequestFormReceivedNotification({
        users,
        request,
        apiUser,
        formName,
        formFileId,
        receiverId: formCreationActivityItem.author,
      });

      await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: request.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: "INFORMATION_SUBMITTED",
              message: moreInformationMessageContent,
              formFileId,
              formName,
            }),
            organisation: request.organisation,
          },
        },
      });
      message.success({ content: "Successfully notified engineer", key: messageKey });
    } catch (e) {
      message.error({
        content: "Failed to notify engineer",
        key: messageKey,
      });
      try {
        await callGraphQLSimple({
          mutation: "updateRequest",
          displayError: false,
          variables: {
            input: {
              id: request.id,
              status: request.status,
            },
          },
        });
      } catch (e) {
        // nothing we can do about it
      }
    }

    try {
      await callGraphQLSimple({
        mutation: "updateRequest",
        displayError: false,
        variables: {
          input: {
            id: request.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      });
    } catch (e) {
      // nothing we can do about it
    }
    setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
  }

  const state = getState();

  return (
    <>
      <Button type="primary" onClick={onClick}>
        Submit information
      </Button>
      <Modal
        title="Include a message for the engineer"
        open={state.isSendMessageModalOpen}
        onCancel={() => {
          setState({ isSendMessageModalOpen: false, moreInformationMessageContent: "" });
        }}
        onOk={submit}
      >
        <Input.TextArea
          value={state.moreInformationMessageContent}
          onChange={(e) => setState({ moreInformationMessageContent: e.target.value })}
        />
      </Modal>
    </>
  );
}
