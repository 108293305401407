import { useState } from "react";
import { Pagination } from "antd";
import { REVIEW_ACTIVITY_ITEMS_PER_PAGE } from "common/constants";
import ReviewThreadItem from "../ReviewThreadItem/ReviewThreadItem";

import "./ReviewActivityList.scss";

interface Props {
  items: any[];
  includeResolved: boolean;
  task: any;
  apiUser: any;
  users: any;
  review: any;
  externalReview: any;
  taskRevision: any;
  reviewIsActive: boolean;
  extraItemProps?: any;
}

export default function ReviewActivityList({
  items,
  includeResolved,
  task,
  apiUser,
  users,
  review,
  externalReview,
  taskRevision,
  reviewIsActive,
  extraItemProps = {},
}: Props) {
  const [currentPageIndex, setCurrentPageIndex] = useState<number | undefined>(undefined);

  let pageCount = Math.ceil(items.length / REVIEW_ACTIVITY_ITEMS_PER_PAGE);

  let pageIndexToUse = currentPageIndex;
  if (pageIndexToUse === undefined || pageIndexToUse > pageCount) {
    pageIndexToUse = pageCount;
  }

  return (
    <div className="review-activity-list">
      <div className="items">
        {items
          .slice((pageIndexToUse - 1) * REVIEW_ACTIVITY_ITEMS_PER_PAGE, pageIndexToUse * REVIEW_ACTIVITY_ITEMS_PER_PAGE)
          .map((item, i, list) => {
            return (
              <ReviewThreadItem
                {...item}
                key={i}
                task={task}
                includeResolved={includeResolved}
                apiUser={apiUser}
                users={users}
                review={review}
                taskRevision={taskRevision}
                revisionAuthor={taskRevision.author}
                revisionReviewer={taskRevision.checkedBy}
                reviewIsActive={reviewIsActive}
                {...extraItemProps}
              />
            );
          })}
      </div>
      <Pagination
        current={pageIndexToUse}
        total={items.length}
        onChange={(pageIndex) => {
          setCurrentPageIndex(pageIndex);
        }}
        pageSize={REVIEW_ACTIVITY_ITEMS_PER_PAGE}
        showSizeChanger={false}
        hideOnSinglePage
      />
    </div>
  );
}
