import { withRouter } from "react-router-dom";
import { Checkbox, message } from "antd";
import _ from "lodash";

import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";
import { getSimpleLabel } from "common/labels";
import { REQUEST_SETTINGS } from "common/settings";

import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./RequestSettingsPage.scss";

function RequestSettingsPage({ organisationDetails }) {
  const checked = REQUEST_SETTINGS.filter((setting) => {
    return _.get(organisationDetails, setting.SCHEMA_FIELD);
  });

  const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

  return (
    <div className="request-settings-page">
      <Card withSpace title={`${getSimpleLabel("Request")} settings`}>
        <InfoItem
          label="Emails to be notified on new requests (space-separated)"
          value={
            <Input
              showBorder
              fullWidth
              defaultValue={
                organisationDetails.settings?.request?.emailsToNotifyOnNewRequest
                  ? organisationDetails.settings?.request?.emailsToNotifyOnNewRequest.join(" ")
                  : ""
              }
              onChange={async (newValue) => {
                try {
                  await callGraphQLSimple({
                    displayError: false,
                    message: "Failed to update default emails to notify on new request",
                    mutation: "updateOrganisation",
                    variables: {
                      input: {
                        id: organisationDetails.id,
                        settings: {
                          ...(organisationDetails.settings || {}),
                          request: {
                            ...(organisationDetails.settings?.request || {}),
                            emailsToNotifyOnNewRequest: newValue.split(" "),
                          },
                        },
                      },
                    },
                  });
                  message.success("Updated default emails to notify on new request");
                } catch (e) {
                  message.error("Failed to update default emails to notify on new request");
                }
              }}
            />
          }
        />
        <Checkbox.Group defaultValue={checkedSettings}>
          {REQUEST_SETTINGS.map((setting) => {
            const { NAME, DESCRIPTION, SCHEMA_FIELD } = setting;

            return (
              <Checkbox
                className="setting-checkbox"
                value={SCHEMA_FIELD}
                key={SCHEMA_FIELD}
                onChange={async (e) => {
                  const input = getGraphQLInput({
                    organisationDetails: {
                      settings: organisationDetails.settings || {},
                    },
                    checked: e.target.checked,
                    module: setting,
                  });
                  await callGraphQL(
                    "Could not update setting",
                    graphqlOperation(updateOrganisation, {
                      input: {
                        id: organisationDetails.id,
                        ...input,
                      },
                    })
                  );
                }}
              >
                <span className="setting-name">{NAME}</span>
                {DESCRIPTION && (
                  <>
                    <br />
                    <span className="setting-description">{DESCRIPTION}</span>
                  </>
                )}
              </Checkbox>
            );
          })}
        </Checkbox.Group>
      </Card>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: RequestSettingsPage,
    subscriptions: ["organisationDetails"],
  })
);
