import { useCallback, useEffect } from "react";
import { Typography } from "antd";
import { useGetSetState } from "react-use";
import cx from "classnames";
import { debounce, throttle } from "lodash";

import { getObjectStyle } from "TemplateEditorPage/renderHelpers";
import { useForceUpdate } from "common/helpers";
import findAllMatchingObjects from "../findAllMatchingObjects";

import TemplatePageHeader from "./TemplatePageHeader/TemplatePageHeader";
import ZoomableNew from "ZoomableNew/ZoomableNew";
import ObjectPlaceholder from "./ObjectPlaceholder/ObjectPlaceholder";
import PagePaddingLimit from "./PagePaddingLimit/PagePaddingLimit";
import PdfRenderer from "ReportPage/PdfRenderer";
import TemplateAlertBanners from "../TemplateAlertBanners/TemplateAlertBanners";
import TemplateObjectResizeHandler from "./TemplateObjectResizeHandler/TemplateObjectResizeHandler";

import "./TemplateEditorCanvas.scss";

const THRESHOLD_FOR_PANNING = 5;

export default function TemplateEditorCanvas({
  fileTypeDetails,
  fileType,
  outputTemplate,
  zoomableRefreshKey,
  selectedObjects,
  setSelectedObjects,
  selectedObjectForEditingChildren,
  setSelectedObjectForEditingChildren,
  templatePdf,
  pdfPreviewData,
  isViewingLatestVersion,
  isPreviewVisible,
  template,
  targetS3VersionDetails,
  s3Versions,
  apiUser,
  updateObject,
  defaultScale,
  defaultPosition,
  visible,
}) {
  // console.log("selectedObjects", selectedObjects);

  const [getState, setState] = useGetSetState({
    position: undefined,
    scale: undefined,
    mouseDownX: undefined,
    mouseDownY: undefined,
  });

  const forceUpdate = useForceUpdate();

  useEffect(() => {
    // we want to trigger an update every time the output template changes because we want to re-render the canvas,
    // especially for the selection highlight
    forceUpdate();
  }, [outputTemplate]);

  const debouncedOnZoomableChange = useCallback(debounce(onZoomableChange, 300), []);
  const throttleOnZoomableChange = useCallback(throttle(onZoomableChange, 1000), []);

  function isObjectSelected(object) {
    return (selectedObjects || []).some((selectedObject) => selectedObject.custom_id === object.custom_id);
  }

  function isObjectSelectable(object) {
    let isChildOfRoot = object.group.custom_id === "root";

    if (isChildOfRoot) {
      if (!selectedObjectForEditingChildren) {
        return true;
      }

      if (
        selectedObjectForEditingChildren.group?.custom_id === "root" &&
        selectedObjectForEditingChildren?.custom_id !== object.custom_id
      ) {
        return true;
      }

      return false;
    }

    return selectedObjectForEditingChildren?.objects?.some((childObject) => childObject.custom_id === object.custom_id);
  }

  function onPagesContainerClick(e) {
    const { mouseDownX, mouseDownY } = getState();

    let currentMouseX = e.clientX;
    let currentMouseY = e.clientY;

    if (
      Math.abs(mouseDownX - currentMouseX) > THRESHOLD_FOR_PANNING ||
      Math.abs(mouseDownY - currentMouseY) > THRESHOLD_FOR_PANNING
    ) {
      return;
    }
    setSelectedObjects([]);
    setSelectedObjectForEditingChildren(undefined);
  }

  function onPagesContainerMouseDown(e) {
    setState({
      mouseDownX: e.clientX,
      mouseDownY: e.clientY,
    });
  }

  function onSelectableObjectClick(e, object) {
    e.stopPropagation();
    setSelectedObjects([object]);

    if (selectedObjectForEditingChildren) {
      let objectIsChildOfselectedObjectForEditingChildren = selectedObjectForEditingChildren?.objects?.some(
        (childObject) => childObject.custom_id === object.custom_id
      );
      if (
        !objectIsChildOfselectedObjectForEditingChildren &&
        object.custom_id !== selectedObjectForEditingChildren.custom_id
      ) {
        setSelectedObjectForEditingChildren(undefined);
      }
    }
  }

  function getCommonObjectProps(object) {
    let objectIsSelected = false;
    let objectIsSelectable = false;
    let objectIsSelectedForEditingChildren = selectedObjectForEditingChildren?.custom_id === object.custom_id;

    if (!objectIsSelectedForEditingChildren) {
      objectIsSelected = isObjectSelected(object);
      objectIsSelectable = isObjectSelectable(object);
    }

    let commonElementProps = {
      className: cx("template-object", {
        "page-container": object.custom_type === "page",
        "page-row": object.custom_type === "chapter",
        selected: objectIsSelected,
        selectable: objectIsSelectable,
        "selected-for-editing-children": objectIsSelectedForEditingChildren,
      }),
      id: object.custom_id,
      key: object.custom_id,
      "data-custom-id": object.custom_id,
      "data-custom-type": object.custom_type,
      "data-custom-name": object.custom_name,
    };

    if (objectIsSelectable) {
      commonElementProps.onClick = (e) => {
        onSelectableObjectClick(e, object);
        setSelectedObjectForEditingChildren(object);
      };
    }

    return commonElementProps;
  }

  function onObjectResize({ object, width, height, top, left }) {
    let updateObjectFields = {};
    if (width !== undefined) {
      updateObjectFields.width = width;
      updateObjectFields.formula_width = width;
    }

    if (height !== undefined) {
      updateObjectFields.height = height;
      updateObjectFields.formula_height = height;
    }

    if (top !== undefined) {
      updateObjectFields.top = top;
    }

    if (left !== undefined) {
      updateObjectFields.left = left;
    }

    updateObject({
      objectId: object.custom_id,
      fields: updateObjectFields,
    });
  }

  function onObjectDrag({ object, dragParams }) {
    updateObject({
      objectId: object.custom_id,
      fields: dragParams,
    });
  }

  function shouldDisplayResizeHandler({ commonObjectProps }) {
    if (fileTypeDetails.isDocumentTemplate || !commonObjectProps.className?.includes("selected")) {
      return false;
    }

    return true;
  }

  function displayObject({ index, parent, object, root, pageSize, pagePadding }) {
    const { scale } = getState();

    let registeredFonts = [];

    let style = getObjectStyle({ index, parent, object, root, isDocumentTemplate: fileTypeDetails.isDocumentTemplate });

    const commonObjectProps = getCommonObjectProps(object);

    switch (object.custom_type) {
      case "qr-code":
        return (
          <div {...commonObjectProps} style={{ ...style, height: style.width }}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            <ObjectPlaceholder type="qr-code" object={object} parentStyle={{ ...style, height: style.width }} />
          </div>
        );
      case "signature":
        return (
          <div {...commonObjectProps} style={style}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            <ObjectPlaceholder type="signature" object={object} parentStyle={style} />
          </div>
        );

      case "image_container":
        return (
          <div {...commonObjectProps} style={style}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            <ObjectPlaceholder type="image_container" object={object} parentStyle={style} />
          </div>
        );
      case "dynamic_file":
        return (
          <div style={style} {...commonObjectProps}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            <ObjectPlaceholder type="dynamic_file" object={object} parentStyle={style} />
          </div>
        );
      case "component":
        return (
          <div style={style} {...commonObjectProps}>
            <ObjectPlaceholder type="component" object={object} parentStyle={style} />
          </div>
        );
      default:
        break;
    }

    switch (object.type) {
      case "rect":
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }
        return (
          <div style={style} {...commonObjectProps}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
          </div>
        );
      case "ellipse":
        style = {
          ...style,
          borderRadius: "50%",
          width: object.rx * 2,
          height: object.ry * 2,
        };
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }

        return (
          <div style={style} {...commonObjectProps}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
          </div>
        );
      case "text":
        let fontFamily = object.fontFamily;
        if (!registeredFonts.includes(fontFamily)) {
          fontFamily = undefined;
        }
        style = {
          ...style,
          borderColor: object.stroke,
          fontFamily,
          fontSize: object.fontSize,
          color: object.fill,
        };

        if (!object.custom_hideBackground && (object.custom_textFill || object.custom_formula_textFill)) {
          style.backgroundColor = object.custom_textFill || object.custom_formula_textFill;
        }

        if (object.strokeWidth && parseInt(object.strokeWidth) && object.stroke) {
          style.border = `${object.strokeWidth}px solid ${object.stroke || "#000000"}`;
        }

        return (
          <div style={{ ...style, width: object.width }} {...commonObjectProps}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            <Typography.Text
              style={{
                fontSize: style.fontSize,
                fontFamily: style.fontFamily,
                color: style.color,
                textWrap: "nowrap",
                overflowX: "hidden",
              }}
            >
              {object.text}
            </Typography.Text>
          </div>
        );
      case "image":
        style = {
          ...style,
          width: style.width * (object.scaleX || 1),
          height: style.height * (object.scaleY || 1),
        };

        return (
          <div style={style} {...commonObjectProps}>
            {shouldDisplayResizeHandler({ commonObjectProps }) && (
              <TemplateObjectResizeHandler
                object={object}
                onResize={onObjectResize}
                onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
                scale={scale}
              />
            )}
            {object.src ? (
              <img src={object.src} style={{ width: "100%" }} />
            ) : (
              <ObjectPlaceholder type="image" object={object} parentStyle={style} />
            )}
          </div>
        );

      default:
        return null;
    }
  }

  function displaySection({ object, objectIndex, page, pageSize, pagePadding, parent, root }) {
    const { scale } = getState();
    let style = getObjectStyle({
      object,
      index: objectIndex,
      parent,
      root,
      isDocumentTemplate: fileTypeDetails.isDocumentTemplate,
    });

    let objectIsSelectedForEditingChildren = selectedObjectForEditingChildren?.custom_id === object.custom_id;

    const commonObjectProps = getCommonObjectProps(object);

    let objectProps = {
      ...commonObjectProps,
      style,
    };

    if (objectIsSelectedForEditingChildren) {
      objectProps.className = cx(objectProps.className, "selected-for-editing-children");
    }

    return (
      <div {...objectProps}>
        {shouldDisplayResizeHandler({ commonObjectProps }) && (
          <TemplateObjectResizeHandler
            object={object}
            onResize={onObjectResize}
            onDrag={(dragParams) => onObjectDrag({ object, dragParams })}
            scale={scale}
          />
        )}
        {displayParent({
          parent: object,
          page,
          pageSize,
          pagePadding,
          root,
        })}
      </div>
    );
  }

  function displayParent({ parent, page, pageSize, pagePadding, root }) {
    return parent.objects
      .filter((object) => {
        return object.visible !== false;
      })
      .map((object, objectIndex) => {
        if (object.custom_type === "section" || object.type === "group") {
          return displaySection({
            object,
            objectIndex,
            page,
            pageSize,
            pagePadding,
            parent,
            root,
          });
        } else {
          return displayObject({
            index: objectIndex,
            object: object,
            page,
            parent,
            root,
            pageSize,
            pagePadding,
          });
        }
      });
  }

  function onZoomableChange({ position, scale }) {
    setState({
      position,
      scale,
    });
  }

  function displayDocumentTemplate() {
    let pagesAndChapters = outputTemplate.objects.filter(
      (object) => ["chapter", "page"].includes(object.custom_type) && !object.isHidden && object.visible !== false
    );

    let pages = [];
    // expand chapters into pages, which are direct children of the chapter
    pagesAndChapters.forEach((pageOrChapter) => {
      if (pageOrChapter.custom_type === "page") {
        pages.push(pageOrChapter);
      } else if (pageOrChapter.custom_type === "chapter") {
        pages.push(...(pageOrChapter.objects || []).filter((object) => object.custom_type === "page"));
      }
    });

    let pageRows = [];
    pages.forEach((page) => {
      if (pageRows.length === 0) {
        pageRows.push([]);
      }
      let latestRow = pageRows[pageRows.length - 1];

      if (latestRow.length > 0) {
        let latestRowGroupId = latestRow[0].group.custom_id;
        let pageGroupId = page.group.custom_id;
        if (latestRowGroupId !== pageGroupId) {
          pageRows.push([]);
          latestRow = pageRows[pageRows.length - 1];
        }
      }

      latestRow.push(page);
    });

    return pageRows.map((pageRow, rowIndex) => {
      let firstPage = pageRow[0];

      if (!firstPage) {
        return null;
      }

      let groupObject = firstPage.group;

      let commonObjectProps = {};
      if (groupObject?.custom_type === "chapter") {
        commonObjectProps = getCommonObjectProps(groupObject);
      } else {
        commonObjectProps = {
          className: cx("page-row"),
        };
      }

      return (
        <div key={rowIndex} {...commonObjectProps}>
          <span className="page-row-name">{firstPage.group.custom_name}</span>
          {pageRow.map((page) => {
            return displayPage({ page });
          })}
        </div>
      );
    });
  }

  function displayPage({ page }) {
    let { height, ...style } = getObjectStyle({
      object: page,
      root: outputTemplate,
    });
    let pageSize = {
      width: page.custom_pageWidth,
      height: page.custom_pageHeight,
    };
    let pagePadding = {
      top: style.paddingTop,
      right: style.paddingRight,
      bottom: style.paddingBottom,
      left: style.paddingLeft,
    };

    let objectIsSelectedForEditingChildren = selectedObjectForEditingChildren?.custom_id === page.custom_id;

    let objectProps = {
      ...getCommonObjectProps(page),
      style: { ...style, width: pageSize.width, minHeight: pageSize.height || 0 },
    };

    if (objectIsSelectedForEditingChildren) {
      objectProps.className = cx(objectProps.className, "selected-for-editing-children");
    }

    return (
      <div {...objectProps}>
        <TemplatePageHeader page={page} />
        <PagePaddingLimit pagePadding={pagePadding} />
        {displayParent({
          parent: page,
          root: outputTemplate,
          pageSize,
          pagePadding,
          page,
        })}
      </div>
    );
  }

  function displayNonDocumentTemplate() {
    if (!templatePdf) {
      return null;
    }

    let objects = displayParent({
      parent: outputTemplate,
      root: outputTemplate,
    });

    let renderMode = "svg";
    let pdfRendererScale = undefined;

    if (fileType === "EXCEL") {
      renderMode = "canvas";
      pdfRendererScale = 6;
    }

    if (isPreviewVisible) {
      return (
        <PdfRenderer
          fileData={pdfPreviewData}
          includePagination={false}
          renderMode={renderMode}
          includePreloader={false}
          scale={pdfRendererScale}
        />
      );
    }

    return (
      <>
        <PdfRenderer
          fileData={templatePdf}
          includePagination={false}
          renderMode={renderMode}
          includePreloader={false}
          scale={pdfRendererScale}
        />
        {objects}
      </>
    );
  }

  const scale = getState().scale || 0;

  let scaleClassName = "";
  if (scale > 6) {
    scaleClassName = `scale-6`;
  } else if (scale > 3) {
    scaleClassName = `scale-3`;
  } else if (scale > 2) {
    scaleClassName = `scale-2`;
  } else if (scale > 1) {
    scaleClassName = `scale-1`;
  } else {
    scaleClassName = `scale-0`;
  }

  let chaptersExist = outputTemplate.objects.some((object) => object.custom_type === "chapter");

  let zoomableContent = fileTypeDetails.isDocumentTemplate ? displayDocumentTemplate() : displayNonDocumentTemplate();

  return (
    <div
      className={cx("template-editor-canvas", scaleClassName, { invisible: !visible, "chapters-exist": chaptersExist })}
      onClick={onPagesContainerClick}
      onMouseDown={onPagesContainerMouseDown}
    >
      <ZoomableNew
        className="canvas-zoomable-container"
        refreshKey={zoomableRefreshKey}
        onChange={(params) => {
          throttleOnZoomableChange(params);
          debouncedOnZoomableChange(params);
        }}
        defaultScale={defaultScale}
        defaultPosition={defaultPosition}
      >
        <div className="zoomable-content">{zoomableContent}</div>
      </ZoomableNew>

      <TemplateAlertBanners
        outputTemplate={outputTemplate}
        isViewingLatestVersion={isViewingLatestVersion}
        isPreviewVisible={isPreviewVisible}
        template={template}
        targetS3VersionDetails={targetS3VersionDetails}
        s3Versions={s3Versions}
        apiUser={apiUser}
      />
    </div>
  );
}
