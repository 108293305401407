import React from "react";
import moment from "moment";
import { DragDropContext } from "react-beautiful-dnd";
import { LexoRank } from "lexorank";
import { Helmet } from "react-helmet";
import { Empty, Button, Modal } from "antd";
import { Redirect, Link } from "react-router-dom";

import { getLabel } from "common/helpers";
import withAuth from "common/withAuth";
import withSubscriptions from "common/withSubscriptions";
import { getUppercaseStatus, downloadBase64, sanitiseCSVValue } from "common/helpers";
import { getFilteredTasks } from "common/filterHelpers";
import { isAuthorised, getGroupNamesForUser } from "common/permissions";
import { getUpdatedTaskDetailsAfterChangingStatus, changeTaskStatus } from "common/changeTaskStatus";
import { getTableColumns } from "DashboardPage/tableColumns";

import TaskFilters from "TaskFilters/TaskFilters";
import TableView from "./TableView/TableView";
import Lane from "Lane/Lane";
import LaneContainer from "LaneContainer/LaneContainer";
import TaskList from "./TaskList/TaskList";
import TaskDetailsModal from "Modals/TaskDetailsModal/TaskDetailsModal";
import DashboardViewTypes from "./DashboardViewTypes/DashboardViewTypes";
import UnauthorisedPage from "UnauthorisedPage/UnauthorisedPage";

import "./DashboardPage.scss";

window.LexoRank = LexoRank;

export class DashboardPage extends React.Component {
  state = {
    filter: {},
    tasks: null,
    viewType: "BOARD",
    isTaskDetailsModalVisible: false,
    selectedTaskId: null,
  };

  componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.setNoScroll(true);
    this.props.setBackground(false);

    this.checkEISDomain();
    if (window.dashboardViewType) {
      this.setState({ viewType: window.dashboardViewType });
    }

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: "nothing",
          fileId: "nothing",
          clientId: "nothing",
          page: "DASHBOARD",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });
  }

  componentWillUnmount() {
    this.props.setBackground(true);
    this.props.setBoxedLayout(true);
    this.props.setNoScroll(false);
    window.dashboardViewType = this.state.viewType;
  }

  componentDidUpdate(prevProps, prevState) {
    if (!this.state.tasks || prevProps.tasks !== this.props.tasks) {
      this.setState({ tasks: this.props.tasks });
    }
  }

  checkEISDomain = () => {
    if (
      this.props.apiUser.organisation === "EIS" &&
      ["draughthub.com", "www.draughthub.com"].includes(window.location.hostname)
    ) {
      // redirect to eis-draughthub.com
      Modal.confirm({
        icon: null,
        title: (
          <>
            Your organisation has been moved to <br /> eis-draughthub.com.
          </>
        ),
        okText: "Ok, take me there",
        onOk: () => {
          window.location.href = "https://eis-draughthub.com";
        },
        cancelText: "Remind me in 30 seconds",
        onCancel: () => {
          setTimeout(() => {
            this.checkEISDomain();
          }, 10000);
        },
      });
    }
  };

  onDragEnd = async (result) => {
    const { organisationDetails, projects, clients } = this.props;

    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const { tasks } = this.state;
    const draggableId = result.draggableId;
    let tasksInSprint = tasks
      .filter(
        (task) =>
          !task.isHidden &&
          !task.id.includes("TEMPLATES") &&
          task.id !== draggableId &&
          (!this.state.filter.sprintId || task.sprintId === this.state.filter.sprintId.eq)
      )
      .sort((a, b) => (a.order < b.order ? -1 : 1));

    let filteredTasks = getFilteredTasks({
      projects,
      clients,
      tasks: tasksInSprint,
      filter: this.state.filter,
    }).filter((task) => task.id !== draggableId);
    let sourceStatus = result.source.droppableId;
    let sourceIndex = result.source.index;
    let destinationStatus = result.destination.droppableId;
    let destinationIndex = result.destination.index;
    let taskBeforeDestination;
    let taskAfterDestination;
    if (destinationStatus === sourceStatus && destinationIndex === sourceIndex) {
      return;
    }
    let destinationStatusUpperCase = destinationStatus.toUpperCase().split(" ").join("_");
    let tasksWithStatus = filteredTasks.filter(
      (task) => task.status.toUpperCase().split(" ").join("_") === destinationStatusUpperCase
    );

    if (destinationIndex === 0) {
      taskAfterDestination = tasksWithStatus[0];
      if (taskAfterDestination) {
        taskBeforeDestination = tasksInSprint.find(
          (_, i) => tasksInSprint[i + 1] && tasksInSprint[i + 1].id === taskAfterDestination.id
        );
      }
    } else if (destinationIndex === tasksWithStatus.length) {
      taskBeforeDestination = tasksWithStatus[tasksWithStatus.length - 1];
      taskAfterDestination = tasksInSprint.find(
        (_, i) => tasksInSprint[i - 1] && tasksInSprint[i - 1].id === taskBeforeDestination.id
      );
    } else {
      taskBeforeDestination = tasksWithStatus[destinationIndex - 1];
      taskAfterDestination = tasksWithStatus[destinationIndex];
    }

    let newOrder;
    if (tasksInSprint.length === 0) {
      newOrder = LexoRank.middle().value;
    } else {
      if (!taskBeforeDestination && !taskAfterDestination) {
        newOrder = LexoRank.parse(tasksInSprint[tasksInSprint.length - 1].order).genNext().value;
      } else if (!taskBeforeDestination) {
        newOrder = LexoRank.parse(taskAfterDestination.order).genPrev().value;
      } else if (!taskAfterDestination) {
        newOrder = LexoRank.parse(taskBeforeDestination.order).genNext().value;
      } else {
        if (taskBeforeDestination.order === taskAfterDestination.order) {
          newOrder = LexoRank.parse(taskBeforeDestination.order).genNext().value;
        } else {
          newOrder = LexoRank.parse(taskBeforeDestination.order).between(
            LexoRank.parse(taskAfterDestination.order)
          ).value;
        }
      }
    }

    const input = getUpdatedTaskDetailsAfterChangingStatus({
      taskId: draggableId,
      status: destinationStatus,
      organisationDetails,
    });
    input.order = newOrder;

    this.setState({
      tasks: this.state.tasks
        .map((task) => {
          if (task.id !== draggableId) {
            return task;
          }
          return {
            ...task,
            ...input,
          };
        })
        .sort((a, b) => (a.order < b.order ? -1 : 1)),
    });

    await changeTaskStatus({
      taskId: draggableId,
      status: destinationStatus,
      organisationDetails,
      order: newOrder,
    });
  };

  displayCurrentView = () => {
    const { organisationDetails, projects, clients } = this.props;
    const { tasks, viewType } = this.state;

    let filteredTasks = getFilteredTasks({
      projects,
      clients,
      tasks,
      filter: this.state.filter,
    });

    if (!filteredTasks) {
      return null;
    }

    const lanes = (organisationDetails.taskStatuses || []).map((status) => {
      let laneStatusUpperCase = getUppercaseStatus(status.name);
      const filteredTasksInLane = filteredTasks.filter(
        (task) => getUppercaseStatus(task.status) === laneStatusUpperCase
      );
      const allTasksInLane = tasks.filter(
        (task) => !task.isHidden && getUppercaseStatus(task.status) === laneStatusUpperCase
      );

      let suffix;
      if (organisationDetails.settings?.task?.usesTaskEstimates) {
        const estimatedHours = filteredTasksInLane
          .map((task) => task.estimatedHours)
          .reduce((a, b) => (a || 0) + (b || 0), 0);
        suffix = (
          <>
            {" "}
            - {estimatedHours} estimated hour{estimatedHours === 1 ? "" : "s"}
          </>
        );
      }

      return {
        label: status.name,
        tasks: filteredTasksInLane,
        status: getUppercaseStatus(status.name),
        itemCount: filteredTasksInLane.length,
        allItemCount: allTasksInLane.length,
        suffix,
      };
    });

    const laneElements = lanes.map((lane) => {
      return (
        <Lane lane={lane} key={lane.label} viewType={viewType}>
          <TaskList
            organisationDetails={organisationDetails}
            tasks={[...lane.tasks].sort((a, b) => (a.order < b.order ? -1 : 1))}
            viewType={viewType}
            windowWidth={this.props.windowWidth}
            onSelectTask={(taskId) =>
              this.setState({
                isTaskDetailsModalVisible: true,
                selectedTaskId: taskId,
              })
            }
          />
        </Lane>
      );
    });

    if (viewType === "BOARD" || viewType === "LIST") {
      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          {lanes.length === 0 ? (
            <div>
              <Empty
                description={
                  <div className="no-task-statuses-message">
                    No{" "}
                    {getLabel({
                      id: "task",
                      defaultValue: "task",
                    })}{" "}
                    statuses defined
                    <Link to={`organisations/${organisationDetails.id}/settings?tab=Settings&secondaryTab=Tasks`}>
                      <Button type="primary">Go to organisation settings</Button>
                    </Link>
                  </div>
                }
              />
            </div>
          ) : (
            <LaneContainer viewType={viewType}>{laneElements}</LaneContainer>
          )}
        </DragDropContext>
      );
    } else if (viewType === "TABLE") {
      return (
        <TableView
          {...this.state}
          onSelectTask={(taskId) => {
            this.setState({
              isTaskDetailsModalVisible: true,
              selectedTaskId: taskId,
            });
          }}
        />
      );
    } else {
      return null;
    }
  };

  changeViewType = (viewType) => {
    this.setState({ viewType });
  };

  export = async () => {
    const { tasks, organisationDetails, projects, clients, apiUser, groups } = this.props;
    const { filter } = this.state;

    const columns = [...getTableColumns()];

    const groupNamesForUser = getGroupNamesForUser(apiUser.id, groups);

    (organisationDetails.customFields || [])
      .filter((field) => {
        if (!field.groupsThatCanSee || field.groupsThatCanSee.length === 0) {
          return true;
        }

        return field.groupsThatCanSee.some((group) => groupNamesForUser.includes(group));
      })
      .forEach((fieldDefinition) => {
        columns.push({
          title: fieldDefinition.label,
          dataIndex: fieldDefinition.id,
          isCustomField: true,
          options: fieldDefinition.options,
          type: fieldDefinition.type,
        });
      });

    let csvContent = columns.map((column) => column.title).join(",") + "\n";
    let filteredTasks = getFilteredTasks({
      projects,
      clients,
      tasks,
      filter,
    }).sort((a, b) => (a.createdAt < b.createdAt ? -1 : 1));
    csvContent += filteredTasks
      .map((task, i) => {
        return columns
          .map((column) => {
            if (column.isCustomField) {
              let customTaskField = (task.customFields || []).find((field) => field.id === column.dataIndex);
              if (customTaskField) {
                let readableFieldValue = customTaskField.value;
                if (column.options && column.type === "RADIO_LIST") {
                  readableFieldValue = column.options.find((option) => option.value === customTaskField.value)?.label;
                } else if (column.options && column.type === "CHECKBOX_LIST") {
                  const parsedCustomTaskFieldValue = JSON.parse(customTaskField.value);
                  const filteredCheckboxListOptions = column.options.filter((option) =>
                    parsedCustomTaskFieldValue.includes(option.value)
                  );
                  readableFieldValue = filteredCheckboxListOptions.map((option) => option.label);
                }
                return sanitiseCSVValue(readableFieldValue);
              } else {
                return "";
              }
            } else if (column.fieldFunction) {
              return sanitiseCSVValue(column.fieldFunction({ task }));
            } else {
              return sanitiseCSVValue(task[column.dataIndex]);
            }
          })
          .join(",");
      })
      .join("\n");
    let base64CSV = `data:text/csv;base64,${btoa(unescape(encodeURIComponent(csvContent)))}`;
    await downloadBase64({
      base64String: base64CSV,
      fileName: `tasks ${moment().format("DD-MM-YYYY")}.csv`,
    });
  };

  render() {
    if (!this.state.tasks) {
      return null;
    }
    const { windowWidth, organisationDetails } = this.props;
    const { isTaskDetailsModalVisible, selectedTaskId, viewType } = this.state;

    if (!isAuthorised(["DASHBOARD.VIEW"])) {
      if (isAuthorised(["REQUESTS.VIEW"])) {
        return <Redirect to="/requests" />;
      }
      return <UnauthorisedPage />;
    }

    return (
      <div className="dashboard-page">
        <Helmet>
          <meta
            name="description"
            content="Automate the admin. Focus on the engineering. Saving Civil Engineers one day a week."
          />
          <title>Dashboard</title>
        </Helmet>
        <TaskFilters
          onChange={(filter) => {
            this.setState({ filter });
          }}
          previousFilter={this.state.filter}
          includeExportButton
          export={this.export}
          windowWidth={windowWidth}
          cookieName="task-filters-tasks-page"
          avatarListWidthToSubtract={160 + 100 + 120 + 140 + 120}
        />
        {windowWidth > 800 && organisationDetails.settings?.dashboard?.displayViewTypePicker && (
          <DashboardViewTypes viewType={viewType} changeViewType={this.changeViewType} />
        )}

        {this.displayCurrentView()}

        {isTaskDetailsModalVisible && selectedTaskId && (
          <TaskDetailsModal
            taskId={selectedTaskId}
            onClose={() => {
              this.setState({
                isTaskDetailsModalVisible: false,
                selectedTaskId: null,
              });
            }}
          />
        )}
      </div>
    );
  }
}

export default withSubscriptions({
  Component: withAuth(DashboardPage),
  subscriptions: ["tasks", "apiUser", "sprints", "organisationDetails", "users", "clients", "projects", "groups"],
});
