import React from "react";
import { Button, Typography, Modal } from "antd";
import { DeleteOutlined, PlusCircleOutlined, EditOutlined } from "@ant-design/icons";
import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";

import Card from "Card/Card";
import AddressModal from "Modals/AddressModal/AddressModal";

import "./AddressList.scss";

export class AddressList extends React.Component {
  state = {
    isAddressModalVisible: false,
    selectedAddress: null,
  };

  confirmDeleteAddress = (address) => {
    Modal.confirm({
      title: "Confirm address delete",
      className: "delete-address-modal",
      content: (
        <>
          Are you sure you want to delete address <b>{address.label}</b>?
        </>
      ),
      onOk: async () => {
        const { parent, mutation } = this.props;
        await callGraphQL(
          "Could not delete address",
          graphqlOperation(mutation, {
            input: {
              id: parent.id,
              addresses: (parent.addresses || []).filter((x) => x.id !== address.id),
            },
          })
        );
        this.setState({ isAddressModalVisible: false });
      },
    });
  };

  onAddressModalSubmit = async ({ addressDetails }) => {
    const { selectedAddress } = this.state;
    const { parent, mutation } = this.props;

    if (!selectedAddress) {
      await callGraphQL(
        "Could not add address",
        graphqlOperation(mutation, {
          input: {
            id: parent.id,
            addresses: [...(parent.addresses || []), addressDetails],
          },
        })
      );
    } else {
      await callGraphQL(
        "Could not update address",
        graphqlOperation(mutation, {
          input: {
            id: parent.id,
            addresses: [
              ...(parent.addresses || []).map((address) => {
                if (address.id === addressDetails.id) {
                  return addressDetails;
                }

                return address;
              }),
            ],
          },
        })
      );
    }

    this.setState({ isAddressModalVisible: false, selectedAddress: null });
  };

  render() {
    const { isAddressModalVisible, selectedAddress } = this.state;
    const { parent } = this.props;

    return (
      <Card
        title="Addresses"
        className={cx("address-list", "with-border")}
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => this.setState({ isAddressModalVisible: true })}
            data-cy="add-address-button"
          >
            Add address
          </Button>
        }
      >
        {parent.addresses?.map((address) => {
          return (
            <div className="address" key={address.id} data-cy="address-item">
              <Typography.Text className="address-id" data-cy="address-id">
                {address.id}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="street-number">
                <Typography.Text className="address-detail-item-label">Street Number:</Typography.Text>
                {address.streetNumber}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="house-name">
                <Typography.Text className="address-detail-item-label">House Name:</Typography.Text>
                {address.houseName}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="street-name">
                <Typography.Text className="address-detail-item-label">Street Name:</Typography.Text>
                {address.streetName}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="city">
                <Typography.Text className="address-detail-item-label">City:</Typography.Text>
                {address.city}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="county">
                <Typography.Text className="address-detail-item-label">County:</Typography.Text>
                {address.county}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="postcode">
                <Typography.Text className="address-detail-item-label">Postcode:</Typography.Text>
                {address.postcode}
              </Typography.Text>
              <Typography.Text className="address-detail-item" data-cy="country">
                <Typography.Text className="address-detail-item-label">Country:</Typography.Text>
                {address.country}
              </Typography.Text>
              <Button
                icon={<DeleteOutlined />}
                className="delete-address"
                data-cy="delete-address-button"
                onClick={() => this.confirmDeleteAddress(address)}
              />
              <Button
                icon={<EditOutlined />}
                className="edit-address"
                onClick={() => this.setState({ selectedAddress: address, isAddressModalVisible: true })}
                data-cy="edit-address-button"
              />
            </div>
          );
        })}
        {isAddressModalVisible && (
          <AddressModal
            onClose={() => this.setState({ isAddressModalVisible: false, selectedAddress: null })}
            onSubmit={this.onAddressModalSubmit}
            address={selectedAddress}
          />
        )}
      </Card>
    );
  }
}

export default withSubscriptions({
  Component: AddressList,
  subscriptions: ["organisationDetails"],
});
