export default function getLengthFields(fields) {
  let result = [];

  for (let field of fields) {
    let updatedLabel = "Length of ";

    if (!field.label) {
      continue;
    }
    if (field.label === field.label.toUpperCase()) {
      updatedLabel += field.label;
    } else {
      updatedLabel += field.label[0].toLowerCase() + field.label.slice(1);
    }

    result.push({
      ...field,
      id: field.id + "Length",
      label: updatedLabel,
      value: async (params) => {
        // debugger;
        let fieldValue;
        if (typeof field.value === "function") {
          fieldValue = await field.value(params);
        } else {
          fieldValue = field.value;
        }
        if (typeof fieldValue === "string" || fieldValue instanceof String || Array.isArray(fieldValue)) {
          return fieldValue.length;
        } else {
          return 0;
        }
      },
    });
  }
  return result;
}
