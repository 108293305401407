import { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { updateOrganisation } from "graphql/mutations";
import { Form, Modal, Button, Input, Space } from "antd";

import { callGraphQL } from "common/helpers";

import "./CreateTeamModal.scss";
import withSubscriptions from "common/withSubscriptions";

// import uniqid from "uniqid";

export function CreateTeamModal({ onClose, apiUser, organisationDetails }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ label }) {
    setIsLoading(true);

    const uniqueID = window.randomUUID();

    await callGraphQL(
      "Could not create team",
      graphqlOperation(updateOrganisation, {
        input: {
          id: apiUser.organisation,
          teams: [...(organisationDetails?.teams || []), { id: uniqueID, label }],
        },
      })
    );

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Create team"
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-team-modal"
    >
      <Form form={form} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="label"
            className="team-label"
            rules={[
              {
                required: true,
                message: "You must choose a name for the team",
              },
            ]}
          >
            <Input data-cy="team-label-name-input" />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} data-cy="add-team-submit-button">
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: CreateTeamModal,
  subscriptions: ["organisationDetails"],
});
