import { Modal, Button } from "antd";
import { Link } from "react-router-dom";
import { CheckCircleOutlined, CloseCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import { getSimpleLabel } from "common/labels";

import HolidayConflicts from "common/HolidayConflicts/HolidayConflicts";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";

import "./HolidayConflictsModal.scss";

export default function HolidayConflictsModal({
  onClose,
  holidays,
  users,
  targetHoliday,

  approveHoliday,
  confirmDeleteHoliday,
  rejectHoliday,
}) {
  console.log(`/timeline?endDate=${targetHoliday.endsAt}&startDate=${targetHoliday.startsAt}`);
  return (
    <Modal
      className="holiday-conflicts-modal"
      onCancel={onClose}
      open={true}
      footer={null}
      title={"Conflicting requests"}
    >
      <HolidayConflicts
        holidays={holidays}
        users={users}
        targetHoliday={targetHoliday}
        firstTableTitle={"Pending requests"}
        secondTableTitle={"Approved requests"}
      />

      <div className="time-off-actions">
        <>
          <ButtonWithPermissions
            permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
            onClick={() => rejectHoliday(targetHoliday)}
            icon={<CloseCircleOutlined />}
            data-cy="reject-time-off-button"
          >
            Reject
          </ButtonWithPermissions>
          <ButtonWithPermissions
            type="primary"
            permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
            onClick={() => approveHoliday(targetHoliday)}
            icon={<CheckCircleOutlined />}
            data-cy="approve-time-off-button"
          >
            Approve
          </ButtonWithPermissions>
        </>
        <ButtonWithPermissions
          permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
          type="dark"
          onClick={() => confirmDeleteHoliday(targetHoliday)}
          icon={<DeleteOutlined />}
          data-cy="delete-time-off-button"
        >
          Delete
        </ButtonWithPermissions>
        <Link to={`/timeline?endDate=${targetHoliday.endsAt}&startDate=${targetHoliday.startsAt}`}>
          <Button>View on {getSimpleLabel("timeline")}</Button>
        </Link>
      </div>
    </Modal>
  );
}
