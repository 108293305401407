import { useState } from "react";
import { Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { graphqlOperation } from "aws-amplify";
import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";

import EditFileStatusModal from "Modals/EditFileStatusModal/EditFileStatusModal";

import "./FileStatusList.scss";

function FileStatusList(props) {
  const { organisationDetails, setProps, context } = props;
  const [selectedStatus, setSelectedStatus] = useState(); // eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState(); // eslint-disable-line
  const [isEditFileStatusModalVisible, setIsEditFileStatusModalVisible] = useState(false);

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(organisationDetails.fileStatuses);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedFileStatuses = reorder(result.source.index, result.destination.index);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          fileStatuses: reorderedFileStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          fileStatuses: reorderedFileStatuses,
        },
      },
    });
    await callGraphQL(
      "Could not reorder file statuses",
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          fileStatuses: reorderedFileStatuses,
        },
      })
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="file-status-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails.fileStatuses.map((fileStatus, index) => {
                return (
                  <Draggable
                    key={fileStatus.name}
                    draggableId={fileStatus.name}
                    index={index}
                    className={`draggable-file-status ${fileStatus.name.split(" ").join("")}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="file-status-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Typography.Text className="name" data-cy="file-status-name">
                          {fileStatus.name}
                        </Typography.Text>
                        <div className="actions">
                          {/* <Button
                            key={`file-status-edit-${fileStatus.name}`}
                            icon={<EditOutlined />}
                            className="edit-file-status"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedStatus(fileStatus);
                              setIsEditFileStatusModalVisible(true);
                            }}
                          /> */}
                          <Button
                            key={`file-status-delete-${fileStatus.name}`}
                            icon={<DeleteOutlined />}
                            className="delete-file-status"
                            onClick={async () => {
                              const newFileStatuses = organisationDetails.fileStatuses.filter(
                                (x) => x.name !== fileStatus.name
                              );
                              setProps({
                                context: {
                                  ...context,
                                  organisation: {
                                    ...organisationDetails,
                                    fileStatuses: newFileStatuses,
                                  },
                                  organisationDetails: {
                                    ...organisationDetails,
                                    fileStatuses: newFileStatuses,
                                  },
                                },
                              });
                              await callGraphQL(
                                "Could not delete file status",
                                graphqlOperation(updateOrganisation, {
                                  input: {
                                    id: organisationDetails.id,
                                    fileStatuses: newFileStatuses,
                                  },
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isEditFileStatusModalVisible && (
        <EditFileStatusModal
          visible={true}
          statusDetails={selectedStatus}
          index={selectedIndex}
          onClose={() => setIsEditFileStatusModalVisible(false)}
        />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: FileStatusList,
    subscriptions: ["organisationDetails"],
  })
);
