import { useState } from "react";
import { Button, Typography } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { graphqlOperation } from "aws-amplify";
import withSubscriptions from "common/withSubscriptions";
import { withRouter } from "react-router-dom";

import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";
import { TASK_STATUS_TYPES_READABLE } from "common/constants";
import { getSimpleLabel } from "common/labels";

import EditTaskStatusModal from "Modals/EditTaskStatusModal/EditTaskStatusModal";

import "./TaskStatusList.scss";

function TaskStatusList({ organisationDetails, setProps, context }) {
  const [selectedStatus, setSelectedStatus] = useState(); // eslint-disable-line
  const [selectedIndex, setSelectedIndex] = useState(); // eslint-disable-line
  const [isEditTaskStatusModalVisible, setIsEditTaskStatusModalVisible] = useState(false);

  const reorder = (startIndex, endIndex) => {
    const result = Array.from(organisationDetails.taskStatuses);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  async function onDragEnd(result) {
    // item dropped outside the list
    if (!result.destination) {
      return;
    }

    const reorderedTaskStatuses = reorder(result.source.index, result.destination.index);
    setProps({
      context: {
        ...context,
        organisation: {
          ...organisationDetails,
          taskStatuses: reorderedTaskStatuses,
        },
        organisationDetails: {
          ...organisationDetails,
          taskStatuses: reorderedTaskStatuses,
        },
      },
    });
    await callGraphQL(
      `Could not reorder ${getSimpleLabel("task")} statuses`,
      graphqlOperation(updateOrganisation, {
        input: {
          id: organisationDetails.id,
          taskStatuses: reorderedTaskStatuses,
        },
      })
    );
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div className="task-status-list" {...provided.droppableProps} ref={provided.innerRef}>
              {organisationDetails.taskStatuses?.map((taskStatus, index) => {
                return (
                  <Draggable
                    key={taskStatus.name}
                    draggableId={taskStatus.name}
                    index={index}
                    data-cy={`taskStatus-${taskStatus}`}
                    className={`draggable-task-status ${taskStatus.name.split(" ").join("")}`}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="task-status-item"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Typography.Text className="type" data-cy="task-status-type">
                          {TASK_STATUS_TYPES_READABLE[taskStatus.type]}
                        </Typography.Text>
                        <Typography.Text className="name" data-cy="task-status-name">
                          {taskStatus.name}
                        </Typography.Text>
                        <div className="actions">
                          {/* <Button
                            key={`task-status-edit-${taskStatus.name}`}
                            icon={<EditOutlined />}
                            className="edit-task-status"
                            onClick={() => {
                              setSelectedIndex(index);
                              setSelectedStatus(taskStatus);
                              setIsEditTaskStatusModalVisible(true);
                            }}
                          /> */}
                          <Button
                            key={`task-status-delete-${taskStatus.name}`}
                            icon={<DeleteOutlined />}
                            className="delete-task-status"
                            onClick={async () => {
                              const newTaskStatuses = organisationDetails.taskStatuses?.filter(
                                (x) => x.name !== taskStatus.name
                              );
                              setProps({
                                context: {
                                  ...context,
                                  organisation: {
                                    ...organisationDetails,
                                    taskStatuses: newTaskStatuses,
                                  },
                                  organisationDetails: {
                                    ...organisationDetails,
                                    taskStatuses: newTaskStatuses,
                                  },
                                },
                              });
                              await callGraphQL(
                                `Could not delete ${getSimpleLabel("task")} status`,
                                graphqlOperation(updateOrganisation, {
                                  input: {
                                    id: organisationDetails.id,
                                    taskStatuses: newTaskStatuses,
                                  },
                                })
                              );
                            }}
                          />
                        </div>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {isEditTaskStatusModalVisible && (
        <EditTaskStatusModal
          visible={true}
          statusDetails={selectedStatus}
          index={selectedIndex}
          onClose={() => setIsEditTaskStatusModalVisible(false)}
        />
      )}
    </>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TaskStatusList,
    subscriptions: ["organisationDetails"],
  })
);
