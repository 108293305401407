import { useState } from "react";
import { Typography, Select, InputNumber } from "antd";
import { StarFilled, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import moment from "moment";
import cx from "classnames";

import { INVOICE_STATUSES, INVOICE_DEFAULT_EXPECT_PAYMENT_AFTER_DAYS } from "common/constants";
import { getInvoicePaymentTerms } from "common/invoiceHelpers/sharedInvoiceHelpers";
import { processIdForDisplay, getLabel } from "common/helpers";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";

import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import Input from "Input/Input";
import DatePicker from "DatePicker/DatePicker";
import ClientLogo from "ClientLogo/ClientLogo";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import ClientContactModal from "Modals/ClientContactModal/ClientContactModal";
import AddressModal from "Modals/AddressModal/AddressModal";
import UsersFilter from "UsersFilter/UsersFilter";

import "./InvoiceMetadata.scss";

export default function InvoiceMetadata(props) {
  const {
    invoice,
    clientDetails,
    changeAttribute,
    debouncedChangeAttribute,
    users,
    clients,
    isDisabled,
    sortedClientContacts,
    organisationDetails,
  } = props;

  const client = clients.find((client) => client.id === invoice.clientId);
  const [isClientContactModalVisible, setIsClientContactModalVisible] = useState();
  const [isClientAddressModalVisible, setIsClientAddressModalVisible] = useState();

  function getExcludedAssigneeList() {
    return [];
  }

  async function onClientContactModalSubmit(contactDetails) {
    await callGraphQLSimple({
      message: `Failed to add ${getSimpleLabel("client")} contact`,
      queryCustom: "updateClient",
      variables: {
        input: {
          id: client.id,
          contacts: [...(client.contacts || []), contactDetails],
        },
      },
    });

    setIsClientContactModalVisible(false);

    changeAttribute({
      fieldName: "clientContact",
      value: contactDetails.id,
    });
  }

  async function onClientAddressModalSubmit({ addressDetails }) {
    await callGraphQLSimple({
      message: "Failed to create address",
      queryCustom: "updateClient",
      variables: {
        input: {
          id: client.id,
          addresses: [...(client.addresses || []), addressDetails],
        },
      },
    });

    setIsClientAddressModalVisible(false);

    changeAttribute({
      fieldName: "clientAddress",
      value: addressDetails.id,
    });
  }

  let thereIsAnAccountancyIntegration = organisationDetails.integrations?.some(
    (integration) => integration?.id === "XERO" || integration?.id === "QUICKBOOKS"
  );

  return (
    <>
      <Card className={cx("metadata-card")}>
        <div className="metadata-container">
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Invoice ID:</Typography.Paragraph>
            <Typography.Paragraph className="item-value item-value-static">
              {clientDetails.isPriority ? <StarFilled className="priority-marker" /> : null}
              {processIdForDisplay(invoice.id)}
            </Typography.Paragraph>
          </div>
          <Link className="metadata-item clickable" to={`/projects/${invoice.projectId}`}>
            <Typography.Paragraph className="item-label">Project:</Typography.Paragraph>
            <Typography.Paragraph className="item-value">{invoice.project.title}</Typography.Paragraph>
          </Link>
          <Link className="metadata-item clickable" to={`/clients/${clientDetails.id}`}>
            <Typography.Paragraph className="item-label">
              {getLabel({
                id: "Client",
                defaultValue: "Client",
              })}
              :
            </Typography.Paragraph>
            <ClientLogo client={invoice.client} size="small" />
          </Link>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Assigned to:</Typography.Paragraph>
            <Typography.Paragraph className="item-value">
              {invoice.isArchived || invoice.status === "ACCEPTED" ? (
                <Avatar user={users.find((x) => x.id === invoice.assignedTo)} showLabel={true} />
              ) : (
                <UsersFilter
                  className="assigned-to-picker"
                  activateOnHover={true}
                  value={invoice.assignedTo}
                  onChange={(value) => changeAttribute({ fieldName: "assignedTo", value })}
                  excludeList={getExcludedAssigneeList()}
                  suffixIcon={null}
                  maxLabelLength={22}
                  data-cy="assigned-to-picker"
                />
              )}
            </Typography.Paragraph>
          </div>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Status:</Typography.Paragraph>
            <Select
              disabled={invoice.isArchived}
              onChange={(value) => changeAttribute({ fieldName: "status", value })}
              value={invoice.status}
              data-cy="invoice-status-picker"
            >
              {INVOICE_STATUSES.map((status) => {
                const clientContactDetails = clientDetails?.contacts?.find((x) => x?.id === invoice?.clientContact);
                let isDisabled = false;
                let label = status.label;

                if (status.value === "SENT" && !clientContactDetails) {
                  isDisabled = true;
                  label = `Sent - needs ${getSimpleLabel("client")} contact`;
                }

                return (
                  <Select.Option
                    key={status.value}
                    value={status.value}
                    disabled={isDisabled}
                    style={isDisabled ? { opacity: "0.5" } : { opacity: 1 }}
                  >
                    {label}
                  </Select.Option>
                );
              })}
            </Select>
          </div>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Amount paid:</Typography.Paragraph>
            <ReviewTarget name="amountPaid" {...props} visible={invoice.isUnderReview}>
              {thereIsAnAccountancyIntegration ? (
                <Typography.Text>{window.formatCurrency("GBP", invoice.amountPaid)}</Typography.Text>
              ) : (
                <InputNumber
                  formatter={(value) => {
                    if (!value) {
                      return null;
                    }

                    return `£ ${value}`;
                  }}
                  min={0}
                  disabled={thereIsAnAccountancyIntegration}
                  defaultValue={invoice.amountPaid}
                  onChange={(newValue) =>
                    debouncedChangeAttribute({
                      fieldName: "amountPaid",
                      value: newValue,
                      includeRecalculation: true,
                    })
                  }
                />
              )}
            </ReviewTarget>
          </div>
          {/* {organisationDetails.settings?.invoice?.isValidFromHidden !== true && (
            <div className="metadata-item">
              <Typography.Paragraph className="item-label">Valid from:</Typography.Paragraph>
              <DatePicker
                format="DD MMM YYYY"
                data-cy="valid-from"
                defaultValue={invoice.validFrom && moment(invoice.validFrom)}
                disabled={isDisabled}
                onChange={(value) =>
                  changeAttribute({
                    fieldName: "validFrom",
                    value: moment(value).startOf("day"),
                  })
                }
              />
            </div>
          )}
          {organisationDetails.settings?.invoice?.isValidUntilHidden !== true && (
            <div className="metadata-item">
              <Typography.Paragraph className="item-label">Valid until:</Typography.Paragraph>
              <DatePicker
                format="DD MMM YYYY"
                data-cy="valid-until"
                defaultValue={invoice.validUntil && moment(invoice.validUntil)}
                disabled={isDisabled}
                onChange={(value) =>
                  changeAttribute({
                    fieldName: "validUntil",
                    value: moment(value).endOf("day"),
                  })
                }
              />
            </div>
          )} */}

          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Client address:</Typography.Paragraph>
            <ReviewTarget name="clientAddress" {...props} visible={invoice.isUnderReview}>
              <Select
                value={invoice.clientAddress}
                disabled={isDisabled}
                data-cy="client-address-dropdown"
                onChange={(value) => {
                  if (value === "ADD_NEW") {
                    setIsClientAddressModalVisible(true);
                    return;
                  }
                  changeAttribute({
                    fieldName: "clientAddress",
                    value,
                  });
                }}
              >
                <Select.Option key="add-new" value="ADD_NEW">
                  <Typography.Text>
                    <b>
                      <PlusOutlined /> Add new client address
                    </b>
                  </Typography.Text>
                </Select.Option>
                {(clientDetails.addresses || []).map((address) => {
                  return (
                    <Select.Option key={address.id} value={address.id}>
                      {address.id}
                    </Select.Option>
                  );
                })}
              </Select>
            </ReviewTarget>
          </div>

          <div className="metadata-item">
            <Typography.Paragraph className="item-label">{getSimpleLabel("Client")} contact:</Typography.Paragraph>
            <ReviewTarget name="client-contact" {...props} visible={invoice.isUnderReview}>
              <Select
                data-cy="client-contact-dropdown"
                popupClassName="client-contact-dropdown"
                value={invoice.clientContact}
                disabled={isDisabled}
                onChange={(value) => {
                  if (value === "ADD_NEW") {
                    setIsClientContactModalVisible(true);
                    return;
                  }
                  changeAttribute({
                    fieldName: "clientContact",
                    value,
                  });
                }}
              >
                <Select.Option key="add-new" value="ADD_NEW">
                  <Typography.Text>
                    <b>
                      <PlusOutlined /> Add new {getSimpleLabel("client")} contact
                    </b>
                  </Typography.Text>
                </Select.Option>
                {sortedClientContacts?.map((contact, i) => {
                  return (
                    <Select.Option key={i} value={contact.id}>
                      {contact.firstName} {contact.lastName} ({contact.id})
                    </Select.Option>
                  );
                })}
              </Select>
            </ReviewTarget>
          </div>

          {/* <div className="metadata-item">
            <Typography.Paragraph className="item-label">Reference:</Typography.Paragraph>
            <ReviewTarget name="invoice-reference" {...props} visible={invoice.isUnderReview}>
              <Input
                className="item-value"
                defaultValue={invoice.reference}
                disabled={isDisabled}
                onChange={(value) => changeAttribute({ fieldName: "reference", value })}
                showBorder
                fullWidth
              />
            </ReviewTarget>
          </div> */}
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Invoice date:</Typography.Paragraph>
            <ReviewTarget name="invoice-date" {...props} visible={invoice.isUnderReview}>
              <DatePicker
                className="item-value"
                format="DD-MM-YYYY"
                defaultValue={invoice.invoiceDate ? moment(invoice.invoiceDate) : undefined}
                disabled={isDisabled}
                onChange={(value) =>
                  changeAttribute({
                    fieldName: "invoiceDate",
                    value: value ? value.format("YYYY-MM-DD") : null,
                  })
                }
                showBorder
                fullWidth
              />
            </ReviewTarget>
          </div>

          {/* <div className="metadata-item">
          <Typography.Paragraph className="item-label">Currency:</Typography.Paragraph>
          <Select
            data-cy="currency-picker"
            value={invoice.currency}
            disabled={invoice.status === "ACCEPTED"  || invoice.isArchived}
            onChange={(value) => changeAttribute({ fieldName: "currency", value })}
          >
            {CURRENCIES.map((currency) => {
              return (
                <Select.Option key={currency.value} value={currency.value}>
                  {currency.value}
                </Select.Option>
              );
            })}
          </Select>
        </div> */}
          {/* <div className="metadata-item">
            <Typography.Paragraph className="item-label">Amounts are:</Typography.Paragraph>
            <ReviewTarget name="taxInclusive" {...props} visible={invoice.isUnderReview}>
              <Select
                disabled={isDisabled}
                value={invoice.taxInclusive}
                data-cy="tax-dropdown"
                onChange={(value) => {
                  changeAttribute({
                    fieldName: "taxInclusive",
                    value,
                  });
                }}
              >
                <Select.Option key="true" value={true}>
                  Tax Inclusive
                </Select.Option>
                <Select.Option key="false" value={false}>
                  Tax Exclusive
                </Select.Option>
              </Select>
            </ReviewTarget>
          </div> */}
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Tax rate:</Typography.Paragraph>
            <ReviewTarget name="taxRate" {...props} visible={invoice.isUnderReview}>
              <InputNumber
                min={0}
                disabled={isDisabled}
                defaultValue={invoice.taxRate}
                data-cy="invoice-tax-rate-input"
                onChange={(newValue) =>
                  debouncedChangeAttribute({
                    fieldName: "taxRate",
                    value: newValue,
                    includeRecalculation: true,
                  })
                }
              />
            </ReviewTarget>
          </div>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">PO number:</Typography.Paragraph>
            <ReviewTarget name="po-number" {...props} visible={invoice.isUnderReview}>
              <Input
                className="item-value"
                defaultValue={invoice.poNumber}
                disabled={isDisabled}
                onChange={(value) => changeAttribute({ fieldName: "poNumber", value })}
                showBorder
                fireOnChangeWithoutBlurWithDebounce
                fullWidth
              />
            </ReviewTarget>
          </div>
          <div className="metadata-item">
            <Typography.Paragraph className="item-label">Payment terms (number of days):</Typography.Paragraph>
            <ReviewTarget name="payment-terms" {...props} visible={invoice.isUnderReview}>
              <Input
                className="item-value"
                numerical
                defaultValue={invoice.expectPaymentAfterDays}
                placeholder={getInvoicePaymentTerms({ client, organisationDetails })}
                disabled={isDisabled}
                onChange={(value) => changeAttribute({ fieldName: "expectPaymentAfterDays", value: value || null })}
                fireOnChangeWithoutBlurWithDebounce
                showBorder
                fullWidth
              />
            </ReviewTarget>
          </div>
        </div>
      </Card>
      {isClientContactModalVisible && (
        <ClientContactModal
          onClose={() => setIsClientContactModalVisible(false)}
          onSubmit={onClientContactModalSubmit}
          parent={client}
        />
      )}
      {isClientAddressModalVisible && (
        <AddressModal onClose={() => setIsClientAddressModalVisible(false)} onSubmit={onClientAddressModalSubmit} />
      )}
    </>
  );
}
