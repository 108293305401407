import { callGraphQL, getReadableStatus } from "common/helpers";
import { callRest } from "common/apiHelpers";
import { graphqlOperation } from "aws-amplify";
import { updateQuote } from "graphql/queries_custom";
import { createQuoteActivityItem } from "graphql/mutations";
import { REVIEW_STATUS_READABLE } from "common/constants";
import { getLabel } from "common/helpers";
import { KEY_TYPES, encodeKey } from "common/shared";

export async function copyQuoteTemplate({ organisationDetails, templateId, newQuote, apiUser, users, project }) {
  let fileType = "QUOTE";
  const templatesForFileType = organisationDetails.templates.items.filter(
    // (template) => (template.isLive || !template.key) && !template.isDeprecated
    // TODO: re-add the restriction for template to be live
    (template) => template.type === fileType && !template.isDeprecated
  );

  if (!templateId) {
    templateId = templatesForFileType[0].id;
  }
  const templateDetails = templatesForFileType && templatesForFileType.find((template) => template.id === templateId);

  const extension = "json";

  try {
    await recordActivityItem({
      quote: newQuote,
      type: "CREATED",
      author: apiUser.id,
      users,
    });
  } catch (e) {
    console.error(e);
  }

  const fileKey = await encodeKey({
    type: KEY_TYPES.QUOTE,
    data: {
      organisation: organisationDetails.id,
      projectId: project.id,
      quoteId: newQuote.id,
      extension,
      fileType,
    },
  });

  await callRest({
    route: "/copyTemplate",
    method: "post",
    body: {
      fileType,
      templateId,
      templateVersionNumber: templateDetails?.currentVersionNumber || 0,
      extension,
      organisation: organisationDetails.id,
      destinationKey: fileKey,
    },
  });

  await window.callGraphQLSimple({
    queryCustom: "updateQuote",
    variables: {
      input: {
        id: newQuote.id,
        fileKey,
        templateId: templateDetails?.id,
        templateVersionNumber: templateDetails?.currentVersionNumber || 0,
      },
    },
  });
}

export async function recordActivityItem({ quote, type, author, users, content, includeRefresh = true }) {
  const reviewerDetails = users?.find((user) => user.id === quote.checkedBy);

  if (!content) {
    switch (type) {
      case "CREATED":
        content = `${getLabel({ id: "Quote", defaultValue: "Quote" })} created`;
        break;
      case "STATUS_CHANGED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} status changed to ${getReadableStatus(quote.status)}`;
        break;
      case "TOTAL_CHANGED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} total changed`;
        break;
      case "SENT":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} status manually changed to "Sent"`;
        break;
      case "ACCEPTED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} approved`;
        break;
      case "REJECTED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} rejected`;
        break;
      case "VIEWED":
        content = `${getLabel({ id: "Quote", defaultValue: "Quote" })} viewed`;
        break;
      case "REVIEW_ACCEPTED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} review approved`;
        break;
      case "REVIEW_REJECTED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} review rejected - ${REVIEW_STATUS_READABLE[quote.reviewStatus].label}`;
        break;
      case "REVIEW_CLOSED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} review closed`;
        break;
      case "REVIEW_OPENED":
        content = `${getLabel({
          id: "Quote",
          defaultValue: "Quote",
        })} review opened. Reviewer is ${reviewerDetails?.firstName} ${reviewerDetails?.lastName}`;
        break;
      default:
        break;
    }
    content = content || type;
  }

  await callGraphQL(
    "Failed to record activity item",
    graphqlOperation(createQuoteActivityItem, {
      input: {
        quoteId: quote.id,
        total: quote.total || 0,
        type,
        organisation: quote.organisation,
        author,
        content,
      },
    })
  );

  if (includeRefresh) {
    await callGraphQL(
      "Failed to refresh quote",
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );
  }
}
