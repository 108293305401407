import moment from "moment";
import cx from "classnames";
import axios from "axios";
import * as Sentry from "@sentry/react";
import {
  Tooltip,
  Modal,
  Typography,
  Input as AntInput,
  Checkbox,
  Button,
  Radio,
  Space,
  Select,
  InputNumber,
  Tag,
  notification,
  message,
} from "antd";
import {
  PlusCircleOutlined,
  CopyOutlined,
  EditOutlined,
  UpOutlined,
  DownOutlined,
  RightOutlined,
  DeleteOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";

import getS3File from "common/getS3File";
import { fetchAndSetTask, calculateReadableSize, downloadBlob } from "common/helpers";
import { callGraphQLSimple, callRest } from "common/apiHelpers";
import { getFilenameFromKey, getAttachmentTypeFromKey, HAS_SHEETS, KEY_TYPES } from "common/shared";
import { readAndCompressImage } from "browser-image-resizer";
import { isConditionMet, isObjectHidden } from "common/sharedTemplateRenderHelpers";
import { PaperclipIcon, PageBreakIcon } from "common/icons";
import { getAttachmentsFromString } from "common/documentRenderHelpers";
import { replaceDynamicFormFields } from "common/sharedTemplateRenderHelpers";
import {
  BULLET_POINT_CHARACTER,
  MAX_ATTACHMENT_SIZE_BYTES,
  THRESHOLD_FOR_FULL_SIZE_VERSION,
  ATTACHMENTS_MAX_IMAGE_WIDTH,
  ATTACHMENTS_MAX_IMAGE_HEIGHT,
  DOCUMENT_FORM_SPREADSHEET_DEFAULT_ROWS,
  DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS,
} from "common/constants";
import { changeFileNameAtDownloadTime } from "common/naming";
import { getSimpleLabel } from "common/labels";

import InsertAttachmentModal from "Modals/InsertAttachmentModal/InsertAttachmentModal";
import ReportUserListModal from "Modals/ReportUserListModal/ReportUserListModal";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import Attachments from "Attachments/Attachments";
import DatePicker from "DatePicker/DatePicker";
import Spreadsheet from "Spreadsheet/Spreadsheet";
import Input from "Input/Input";
import Textarea from "DocumentForm/Textarea/Textarea";
import InfoItem from "InfoItem/InfoItem";
import ErrorBoundary from "ErrorBoundary/ErrorBoundary";
import FilePreview from "FilePreview/FilePreview";
import FieldLabel from "Form/FieldLabel/FieldLabel";
import NestedFieldListWithModal from "Form/NestedFieldListWithModal/NestedFieldListWithModal";
import DynamicSectionList from "Form/DynamicSectionList/DynamicSectionList";
import Explanation from "Explanation/Explanation";
import TaskPicker from "TaskPicker/TaskPicker";
import ProjectPickerForm from "Form/ProjectPickerForm/ProjectPickerForm";

let latestKeyPressed;

export function getImagesFromReport(report) {
  let imageKeys = [];
  for (let fieldName in report.fields) {
    const field = report.fields[fieldName];
    let imagesInField = [];
    if (typeof field.value === "string") {
      imagesInField = getAttachmentsFromString(field.value).filter((x) => x.type === "IMAGE");
    } else if (field.type === "sectionList") {
      field.value.forEach((section) =>
        imagesInField.push(...getAttachmentsFromString(section.text).filter((x) => x.type === "IMAGE"))
      );
    } else if (field.type === "attachmentPicker" && field.renderedInReport !== false) {
      imagesInField = field.value.filter(keyIsLoadable).map((key) => ({ localKey: key })) || [];
    } else if (field.type === "attachmentPickerSections" && field.renderedInReport !== false) {
      field.value.forEach((section) => {
        imagesInField.push(...(section.value.filter(keyIsLoadable).map((key) => ({ localKey: key })) || []));
      });
    }
    imageKeys.push(...imagesInField);
  }
  return imageKeys;
}

function keyIsLoadable(key) {
  const VALID_EXTENSIONS = ["png", "jpg", "jpeg", "pdf"];
  return VALID_EXTENSIONS.some((extension) => key.toLowerCase().endsWith(extension));
}

export function getSelectedFileFromTask({ fieldData, taskRevision }) {
  let selectedFileConstantId = fieldData.value;
  if (!selectedFileConstantId) {
    const eligibleFiles = taskRevision.files.items.filter((x) => !x.isArchived && x.type === fieldData.fileType);
    if (eligibleFiles.length === 0) {
      return null;
    }
    selectedFileConstantId = eligibleFiles[0].constantId;
  }
  return selectedFileConstantId;
}

// props is actually used in the eval function, even if eslint says it's unused. Do not delete it.

export function getProcessedTextareaContent(e, latestKeyPressed) {
  const bulletPointStartSequence = `${BULLET_POINT_CHARACTER} `;
  let cursorPosition = e.target.selectionStart;
  let rawContent = e.target.value;
  let processedContent = rawContent
    .split("\n- ")
    .join(`\n${bulletPointStartSequence}`)
    .split("\n* ")
    .join(`\n${bulletPointStartSequence}`);
  if (processedContent.startsWith("- ") || processedContent.startsWith("* ")) {
    processedContent = bulletPointStartSequence + processedContent.substring(2);
  }

  if (latestKeyPressed === "Enter") {
    let linesInText = processedContent.split("\n");
    let currentLineIndex = processedContent.substring(0, cursorPosition).split(/\r\n|\r|\n/).length - 1;
    let previousLine = linesInText[currentLineIndex - 1];
    let currentLine = linesInText[currentLineIndex];

    if (
      previousLine &&
      previousLine.startsWith(BULLET_POINT_CHARACTER) &&
      !currentLine.startsWith(BULLET_POINT_CHARACTER)
    ) {
      if (previousLine === bulletPointStartSequence) {
        linesInText.splice(currentLineIndex - 1, 1);
      } else {
        linesInText[currentLineIndex] += bulletPointStartSequence;
        cursorPosition += bulletPointStartSequence.length;
      }
    }

    processedContent = linesInText.join("\n");
  }

  return processedContent;
}

export function getOrderedFieldKeys(fieldContainer) {
  return Object.keys(fieldContainer).sort((a, b) => {
    const fieldA = fieldContainer[a];
    const fieldB = fieldContainer[a];
    return fieldA.adminOrder < fieldB.adminOrder ? -1 : 1;
  });
}

export async function onAttachmentPickerSubmit(attachments, dates, sizes) {
  if (this.props.task) {
    await fetchAndSetTask.call(this, { id: this.props.match.params.taskId });
  }
  const { form, fieldUnderEditName, subFieldUnderEditIndex, subFieldUnderEditName } = this.state;
  const newFields = JSON.parse(JSON.stringify(form.fields));
  let fieldData;
  if (subFieldUnderEditIndex !== null) {
    if (subFieldUnderEditName) {
      fieldData = newFields[fieldUnderEditName].fields[subFieldUnderEditName];
    } else {
      fieldData = newFields[fieldUnderEditName].value[subFieldUnderEditIndex];
    }
  } else {
    fieldData = newFields[fieldUnderEditName];
  }

  if (fieldData.renderedInReport !== false) {
    attachments = await checkForLargeAttachments.call(this, attachments, sizes);
  }

  if (subFieldUnderEditIndex !== null) {
    if (subFieldUnderEditName) {
      if (!newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName]) {
        newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName] = {};
      }
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].value = attachments;
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].dates = dates;
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex][subFieldUnderEditName].sizes = sizes;
    } else {
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex].value = attachments;
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex].dates = dates;
      newFields[fieldUnderEditName].value[subFieldUnderEditIndex].sizes = sizes;
    }
  } else {
    newFields[fieldUnderEditName].value = attachments;
    newFields[fieldUnderEditName].dates = dates;
    newFields[fieldUnderEditName].sizes = sizes;
  }

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
      subFieldUnderEditIndex: null,
      fieldUnderEditName: null,
      isAttachmentPickerOpen: false,
    },
    this.saveUserFields
  );
}

export async function checkForLargeAttachments(attachments, sizes) {
  let oversizedAttachments = [];
  for (let i = 0; i < attachments.length; i++) {
    const key = attachments[i];
    if (sizes && sizes[key] > MAX_ATTACHMENT_SIZE_BYTES) {
      let attachmentName = key.split("/").slice(-1)[0];
      oversizedAttachments = [...oversizedAttachments, { name: attachmentName, size: sizes[key] }];
    }
  }

  if (oversizedAttachments && oversizedAttachments.length > 0) {
    let readableSize = calculateReadableSize({
      size: MAX_ATTACHMENT_SIZE_BYTES,
    });
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: "Confirm attachment",
          className: "confirm-attachment",
          maskClosable: true,
          content: (
            <>
              These attachments are over {readableSize}:
              <ul className="oversized-attachments">
                {oversizedAttachments.map((attachment) => {
                  return (
                    <li>
                      {attachment.name}: {calculateReadableSize(attachment)}
                    </li>
                  );
                })}
              </ul>
              Are you sure you want to attach these large files?
            </>
          ),
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // it means the user selected "cancel", so we remove the items which are too large
      attachments = attachments.filter((key) => {
        return sizes[key] <= MAX_ATTACHMENT_SIZE_BYTES;
      });
    }
  }
  return attachments;
}

export async function onAttachmentSubmit(attachments, dates, sizes) {
  const { fieldUnderEditSelectionStart, fieldUnderEditName, form } = this.state;
  const { task } = this.props;

  if (task) {
    await fetchAndSetTask.call(this, { id: this.props.match.params.taskId });
  }

  let textToInsert = [];

  let fieldNameToChange = fieldUnderEditName.includes("_") ? fieldUnderEditName.split("_")[0] : fieldUnderEditName;

  let fieldData = form.fields[fieldNameToChange];

  attachments = await checkForLargeAttachments.call(this, attachments, sizes);

  for (let i = 0; i < attachments.length; i++) {
    const key = attachments[i];
    const type = getAttachmentTypeFromKey(key);
    const attachmentsFolder = `${this.state.projectFolder}/attachments/`;

    const localKey = key.split(attachmentsFolder).join("");
    let attributes = "";
    if (type === "IMAGE") {
      attributes = " size=50% align=center";
    }
    textToInsert.push(`[${localKey}${attributes}]`);
  }
  textToInsert = textToInsert.join("\n");

  insertTextIntoField.call(this, {
    fieldData,
    fieldUnderEditName,
    fieldUnderEditSelectionStart,
    fieldNameToChange,
    textToInsert,
  });
}

function insertTextIntoField({
  fieldData,
  fieldUnderEditName,
  fieldUnderEditSelectionStart,
  fieldNameToChange,
  textToInsert,
}) {
  let sectionUnderEditId = "";
  let oldTextValue = fieldData.value;
  if (fieldUnderEditName.includes("_")) {
    sectionUnderEditId = fieldUnderEditName.split("_")[1];
    oldTextValue = fieldData.value.find((crtSection) => crtSection.id === sectionUnderEditId).text;
  }

  let textBeforeCursorPosition = oldTextValue.substring(0, fieldUnderEditSelectionStart);
  let textAfterCursorPosition = oldTextValue.substring(fieldUnderEditSelectionStart, oldTextValue.length);

  let newValue = textBeforeCursorPosition + "\n" + textToInsert + "\n" + textAfterCursorPosition;

  let newFieldData;
  if (fieldUnderEditName.includes("_")) {
    newFieldData = {
      ...fieldData,
      value: fieldData.value.map((crtSection) => {
        if (crtSection.id === sectionUnderEditId) {
          return {
            ...crtSection,
            text: newValue,
          };
        }
        return crtSection;
      }),
    };
  } else {
    newFieldData = {
      ...fieldData,
      value: newValue,
    };
  }

  this.setState(
    {
      form: {
        ...this.state.form,
        fields: {
          ...this.state.form.fields,
          [fieldNameToChange]: newFieldData,
        },
      },
    },
    this.saveUserFields
  );
}

export function displayInsertAttachmentPickerModal() {
  const { isAttachmentPickerOpen, subFieldUnderEditIndex, form, formPreview, fieldUnderEditName } = this.state;
  const { apiUser, task, quote, invoice, request } = this.props;

  let project = quote?.project || invoice?.project;

  if (!isAttachmentPickerOpen) {
    return null;
  }

  const jsonData = formPreview || form;

  let editTarget = jsonData.fields[fieldUnderEditName];
  if (subFieldUnderEditIndex !== null && subFieldUnderEditIndex !== undefined) {
    editTarget = editTarget.value[subFieldUnderEditIndex];
  }

  return (
    <InsertAttachmentModal
      task={task}
      project={project}
      request={request}
      apiUser={apiUser}
      defaultPath={editTarget.defaultPath}
      defaultRelativePath={editTarget.defaultRelativePath}
      onSubmit={(attachments, dates, sizes) => onAttachmentPickerSubmit.call(this, attachments, dates, sizes)}
      onClose={() => this.setState({ isAttachmentPickerOpen: false })}
      selectedItems={
        subFieldUnderEditIndex === null
          ? jsonData.fields[fieldUnderEditName].value
          : jsonData.fields[fieldUnderEditName].value[subFieldUnderEditIndex].value
      }
      allowedFileTypes={!jsonData.fields[fieldUnderEditName].renderedInReport ? undefined : ["IMAGE", "PDF"]}
    />
  );
}

export function displayInsertAttachmentModal() {
  const { isInsertAttachmentsModalOpen } = this.state;
  const { apiUser, task, quote, invoice } = this.props;

  let project = quote?.project || invoice?.project;

  if (!isInsertAttachmentsModalOpen) {
    return null;
  }

  return (
    <InsertAttachmentModal
      task={task}
      project={project}
      apiUser={apiUser}
      onSubmit={(attachments, dates, sizes) => onAttachmentSubmit.call(this, attachments, dates, sizes)}
      onClose={() => this.setState({ isInsertAttachmentsModalOpen: false })}
      allowedFileTypes={["IMAGE", "PDF"]}
    />
  );
}

export function displayModalForNestedField(params) {
  const { onFieldEdit, onFieldDelete, changeFieldOrder } = params || {};
  const { isNestedFieldModalOpen, isFormEditor, selectedNestedContainerField } = this.state;

  if (!isNestedFieldModalOpen || !selectedNestedContainerField) {
    return null;
  }

  let modalTitle;

  if (isFormEditor) {
    modalTitle = (
      <>
        <Typography.Text className="modal-with-fields-inner-title" style={{ marginRight: "1rem" }}>
          {selectedNestedContainerField.label}
        </Typography.Text>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            this.setState({
              selectedFormField: undefined,
              isAddingFormField: true,
            });
          }}
        >
          Add field
        </Button>
      </>
    );
  } else {
    modalTitle = <>Add {selectedNestedContainerField.label} item</>;
  }

  return (
    <Modal
      open={true}
      footer={null}
      title={modalTitle}
      className="report-modal-with-fields"
      onCancel={() => {
        this.setState({
          isNestedFieldModalOpen: false,
          // isModalWithFieldsVisible: false,
          // modalWithFieldsName: undefined,
          selectedModalContainerField: undefined,
        });
      }}
    >
      {displayFields.call(this, {
        showHiddenByModal: false,
        showNestedFieldsInModal: true,
        onFieldEdit,
        onFieldDelete,
        changeFieldOrder,
      })}
    </Modal>
  );
}

export function displayReportUserListModal() {
  const {
    formPreview,
    form,
    fieldUnderEditName,
    fieldUnderEditValue,
    subFieldUnderEditIndex,
    isReportUserListModalOpen,
  } = this.state;

  if (!isReportUserListModalOpen) {
    return null;
  }

  const jsonData = formPreview || form;

  let selectedField = jsonData.fields[fieldUnderEditName];

  return (
    <ReportUserListModal
      {...this.props}
      field={selectedField}
      onSubmit={(itemDetails) => onReportUserListSubmit.call(this, itemDetails)}
      initialValues={
        fieldUnderEditValue && subFieldUnderEditIndex !== null ? fieldUnderEditValue[subFieldUnderEditIndex] : null
      }
      onClose={() =>
        this.setState({
          isReportUserListModalOpen: false,
          fieldUnderEditValue: null,
          fieldUnderEditName: null,
          subFieldUnderEditIndex: null,
        })
      }
    />
  );
}

export function displayModalContainingFields(params) {
  const { onFieldEdit, onFieldDelete, changeFieldOrder } = params || {};
  const { isModalWithFieldsVisible, modalWithFieldsName, form, isFormEditor } = this.state;

  if (!isModalWithFieldsVisible || !modalWithFieldsName) {
    return null;
  }

  let modalTitle;

  if (isFormEditor) {
    modalTitle = (
      <>
        <Typography.Text className="modal-with-fields-inner-title" style={{ marginRight: "1rem" }}>
          {form.fields[modalWithFieldsName].label}
        </Typography.Text>
        <Button
          type="primary"
          icon={<PlusCircleOutlined />}
          onClick={() => {
            this.setState({
              selectedFormField: undefined,
              isAddingFormField: true,
            });
          }}
        >
          Add field
        </Button>
      </>
    );
  } else {
    modalTitle = <>{form.fields[modalWithFieldsName].label}</>;
  }

  return (
    <Modal
      open={true}
      footer={null}
      title={modalTitle}
      className="report-modal-with-fields"
      onCancel={() => {
        this.setState({
          isModalWithFieldsVisible: false,
          modalWithFieldsName: undefined,
          selectedModalContainerField: undefined,
        });
      }}
    >
      {displayFields.call(this, {
        showHiddenByModal: true,
        onFieldEdit,
        onFieldDelete,
        changeFieldOrder,
      })}
    </Modal>
  );
}

export function displayModalContainingSectionFields(params) {
  const { onFieldEdit, onFieldDelete, changeFieldOrder } = params || {};
  const { isModalWithFieldsVisible, modalWithSectionFieldsName, selectedSectionContainerField, form, isFormEditor } =
    this.state;

  if (!isModalWithFieldsVisible || !modalWithSectionFieldsName) {
    return null;
  }

  let modalTitle = null;
  let addFieldButton = null;

  if (isFormEditor) {
    addFieldButton = (
      <Button
        type="primary"
        icon={<PlusCircleOutlined />}
        onClick={() => {
          this.setState({
            selectedFormField: undefined,
            isAddingFormField: true,
          });
        }}
      >
        Add field
      </Button>
    );
    modalTitle = (
      <>
        <Typography.Text className="modal-with-fields-inner-title" style={{ marginRight: "1rem" }}>
          {selectedSectionContainerField.label}
        </Typography.Text>
        {addFieldButton}
      </>
    );
  } else {
    modalTitle = <>{selectedSectionContainerField.label}</>;
  }

  return (
    <Modal
      open={true}
      footer={null}
      title={modalTitle}
      className={cx("report-modal-with-fields", {
        "compact-fields": selectedSectionContainerField.compactFields,
      })}
      onCancel={() => {
        this.setState({
          isModalWithFieldsVisible: false,
          modalWithSectionFieldsName: undefined,
          selectedSectionContainerField: undefined,
        });
      }}
    >
      {displayFields.call(this, {
        showHiddenByModal: false,
        sectionNameToDisplayFieldsFor: modalWithSectionFieldsName,
        onFieldEdit,
        onFieldDelete,
        changeFieldOrder,
      })}
      {addFieldButton}
    </Modal>
  );
}

export function displayFields({
  showHiddenByModal,
  showNestedFieldsInModal,
  sectionNameToDisplayFieldsFor,
  onFieldEdit,
  onFieldDelete,
  changeFieldOrder,
  rootField,
  rootFieldName,
  childField,
  childIndex,
}) {
  let { taskRevision, form, isFormEditor, modalWithFieldsName, selectedNestedContainerField, subFieldUnderEditIndex } =
    this.state;

  const { request, quote, invoice, purchaseOrder, task } = this.props;

  taskRevision = taskRevision || {};

  let fieldsToDisplay;
  if (selectedNestedContainerField && showNestedFieldsInModal) {
    fieldsToDisplay = JSON.parse(JSON.stringify(selectedNestedContainerField.fields));
    if (subFieldUnderEditIndex !== undefined) {
      let subfieldValues = selectedNestedContainerField.value[subFieldUnderEditIndex];
      for (let fieldName in fieldsToDisplay) {
        if (subfieldValues.hasOwnProperty(fieldName)) {
          fieldsToDisplay[fieldName].value = subfieldValues[fieldName];
        }
      }
    }
  } else if (childField) {
    fieldsToDisplay = JSON.parse(JSON.stringify(rootField.fields)) || {};
    for (let fieldName in fieldsToDisplay) {
      if (Array.isArray(childField[fieldName]) || typeof childField[fieldName] === "string") {
        fieldsToDisplay[fieldName].value = childField[fieldName];
      } else {
        for (let childFieldName in childField[fieldName]) {
          fieldsToDisplay[fieldName][childFieldName] = childField[fieldName][childFieldName];
        }
      }
    }
  } else {
    if (!form) {
      Sentry.captureMessage("Form is missing when trying to display fields", {
        extra: {
          taskId: task?.id,
          taskRevisionId: taskRevision?.id,
          requestId: request?.id,
          quoteId: quote?.id,
          invoiceId: invoice?.id,
          purchaseOrderId: purchaseOrder?.id,
        },
        level: "error",
      });
      message.error(
        "Form could not be loaded. Our team has been notified of this problem. If you need assistance, please contact us."
      );
      return;
    } else if (!form.fields) {
      console.log("form = ", form);
      console.log("form.fields = ", form.fields);
      Sentry.captureMessage("Form fields are missing when trying to display fields", {
        extra: {
          taskId: task?.id,
          taskRevisionId: taskRevision?.id,
          requestId: request?.id,
          quoteId: quote?.id,
          invoiceId: invoice?.id,
          purchaseOrderId: purchaseOrder?.id,
        },
        level: "error",
      });
      message.error(
        "Form fields could not be loaded. Our team has been notified of this problem. If you need assistance, please contact us."
      );
      return;
    }

    fieldsToDisplay = JSON.parse(JSON.stringify(form.fields));
  }

  let visibleFieldNames = Object.keys(fieldsToDisplay).filter((fieldName) => {
    if (fieldName === "lineItems") {
      // this is a reserved field name for storing preset line items in quotes, invoices, and purchase orders
      return false;
    }
    let fieldDetails = fieldsToDisplay[fieldName];
    if (sectionNameToDisplayFieldsFor) {
      return fieldDetails.parentSection === sectionNameToDisplayFieldsFor;
    } else {
      if (fieldDetails.parentSection) {
        return false;
      } else if (fieldDetails.isForLineItems && !isFormEditor) {
        return false;
      } else if (!showHiddenByModal) {
        let isVisible = !fieldDetails.hiddenInModalBy;
        return isVisible;
      } else if (showHiddenByModal && modalWithFieldsName) {
        return fieldDetails.hiddenInModalBy === modalWithFieldsName;
      }
    }
    return false;
  });

  const sortedFieldNames = visibleFieldNames.sort(
    (a, b) => fieldsToDisplay[a].adminOrder - fieldsToDisplay[b].adminOrder
  );

  return (
    <div
      className={cx("form", {
        "read-only": taskRevision.isReadOnly || this.isReviewed || this.state.userIsCat2Checker,
      })}
    >
      {sortedFieldNames.map((fieldName, i) => {
        const fieldData = fieldsToDisplay[fieldName];
        // if (!onFieldEdit && !shouldFieldBeDisplayed({ fieldName, form })) {
        if (!onFieldEdit && this.state.hiddenFormFields && this.state.hiddenFormFields[fieldName]) {
          return null;
        }

        if (!this.state.isFormEditor && fieldData.isHidden) {
          return null;
        }

        let fieldParams = {
          fieldData,
          fieldName,
          taskRevision,
          editorParams: {
            onFieldEdit,
            onFieldDelete,
            changeFieldOrder,
          },
          nestedFieldParams: {
            rootField,
            rootFieldName,
            childField,
            childIndex,
          },
        };
        let field;
        switch (fieldData.type) {
          //
          //
          //
          // -------------- START old nested fields --------------------------------
          // do not use anymore and do not remove because they are used in existing reports
          case "attachmentPickerSections":
            field = displayAttachmentPickerSectionsField.call(this, fieldParams);
            break;
          case "userInputList":
            field = displayUserInputListField.call(this, fieldParams);
            break;
          case "sectionList":
            field = displaySectionList.call(this, fieldParams);
            break;
          // -------------- END old nested fields ----------------------------------
          //
          //
          //

          //
          //
          //
          // -------------- START new nested fields ---------------------
          // use these
          case "nestedFieldListWithModal":
            field = displayNestedFieldListWithModal.call(this, fieldParams);
            break;
          case "nestedFieldListNoModal":
            field = displayNestedFieldListNoModal.call(this, fieldParams);
            break;
          case "dynamicSectionList":
            field = displayDynamicSectionList.call(this, fieldParams);
            break;
          // -------------- END new nested fields, use these ---------------------
          //
          //
          //

          case "section-heading":
            field = displaySectionHeading.call(this, fieldParams);
            break;
          case "section":
            field = displaySection.call(this, fieldParams);
            break;
          case "separator":
            field = displaySeparator.call(this, fieldParams);
            break;
          case "textarea":
            field = displayTextarea.call(this, fieldParams);
            break;
          case "textfield":
            field = displayTextfield.call(this, fieldParams);
            break;
          case "number":
            field = displayNumericalInput.call(this, fieldParams);
            break;
          case "date-picker":
            field = displayDatePicker.call(this, fieldParams);
            break;
          case "date-range-picker":
            field = displayDateRangePicker.call(this, fieldParams);
            break;
          case "dropdown":
            field = displayDropdownField.call(this, fieldParams);
            break;
          case "checkbox-list":
            field = displayCheckboxList.call(this, fieldParams);
            break;
          case "checkbox":
            field = displayCheckbox.call(this, fieldParams);
            break;
          case "radio-list":
            field = displayRadioList.call(this, fieldParams);
            break;
          case "attachmentPicker":
            field = displayAttachmentPickerField.call(this, fieldParams);
            break;
          case "attachmentUploader":
            field = displayAttachmentUploaderField.call(this, fieldParams);
            break;
          case "taskFilePicker":
            field = displayTaskFilePicker.call(this, fieldParams);
            break;
          case "modalWithFields":
            field = displayModalWithFieldsInput.call(this, fieldParams);
            break;
          case "spreadsheet":
            field = displaySpreadsheet.call(this, fieldParams);
            break;
          case "button":
            field = displayButton.call(this, fieldParams);
            break;
          case "projectPicker":
            field = displayProjectPicker.call(this, fieldParams);
            break;
          default:
            console.error("unknown field type:", fieldData.type, fieldParams);
            field = null;
            break;
        }

        return (
          <div className={cx("report-field-container", `field-type-${fieldData.type}`)} key={fieldName}>
            {this.state.isFormEditor && (
              <div className="form-field-edit-overlay">
                <div className="overlay-buttons-container">
                  {[
                    "nestedFieldListWithModal",
                    "nestedFieldListNoModal",
                    "modalWithFields",
                    "section",
                    "dynamicSectionList",
                  ].includes(fieldData.type) && (
                    <Button
                      type="dark"
                      icon={<EditOutlined />}
                      onClick={() => {
                        let newState = {
                          isModalWithFieldsVisible: true,
                        };

                        if (fieldData.type === "modalWithFields") {
                          newState = {
                            ...newState,
                            modalWithFieldsName: fieldName,
                            selectedModalContainerField: fieldData,
                          };
                        } else if (["nestedFieldListWithModal", "nestedFieldListNoModal"].includes(fieldData.type)) {
                          newState = {
                            ...newState,
                            isNestedFieldModalOpen: true,
                            selectedNestedContainerField: fieldData,
                          };
                        } else if (fieldData.type === "section") {
                          newState = {
                            ...newState,
                            modalWithSectionFieldsName: fieldName,
                            selectedSectionContainerField: fieldData,
                          };
                        } else if (fieldData.type === "dynamicSectionList") {
                          let placeholderSection;
                          for (let crtFieldName in fieldsToDisplay) {
                            let crtFieldDetails = fieldsToDisplay[crtFieldName];
                            if (crtFieldDetails.isPlaceholderForDynamicSectionList === fieldName) {
                              placeholderSection = crtFieldDetails;
                              break;
                            }
                          }
                          newState = {
                            ...newState,
                            modalWithSectionFieldsName: placeholderSection.id,
                            selectedSectionContainerField: placeholderSection,
                          };
                        }

                        this.setState(newState);
                      }}
                    >
                      Edit fields
                    </Button>
                  )}

                  {!fieldData.isPlaceholderForDynamicSectionList && (
                    <Button
                      type="primary"
                      icon={<EditOutlined />}
                      onClick={() => {
                        onFieldEdit({ fieldData });
                      }}
                    ></Button>
                  )}
                  {!fieldData.isPlaceholderForDynamicSectionList && (
                    <Button
                      icon={<CopyOutlined />}
                      onClick={() => {
                        onFieldEdit({ fieldData, duplicate: true });
                      }}
                    ></Button>
                  )}

                  {!(selectedNestedContainerField && showNestedFieldsInModal) && (
                    <>
                      <Button
                        disabled={i === 0}
                        icon={<UpOutlined />}
                        onClick={() => {
                          let delta = -1;
                          let skipFieldsWithParent = true;
                          if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                            skipFieldsWithParent = false;
                            const previousFieldName = sortedFieldNames[i + delta];
                            if (previousFieldName) {
                              const previousField = form.fields[previousFieldName];
                              if (previousField) {
                                delta = previousField.adminOrder - fieldData.adminOrder;
                              }
                            }
                          }

                          changeFieldOrder({
                            fieldData,
                            delta,
                            skipFieldsWithParent,
                          });
                        }}
                      >
                        1
                      </Button>
                      <Button
                        disabled={i === 0}
                        icon={<UpOutlined />}
                        onClick={() => {
                          let delta = -5;
                          let skipFieldsWithParent = true;
                          if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                            skipFieldsWithParent = false;
                            const previousFieldName = sortedFieldNames[i + delta];
                            if (previousFieldName) {
                              const previousField = form.fields[previousFieldName];
                              if (previousField) {
                                delta = previousField.adminOrder - fieldData.adminOrder;
                              }
                            }
                          }

                          changeFieldOrder({
                            fieldData,
                            delta,
                            skipFieldsWithParent,
                          });
                        }}
                      >
                        5
                      </Button>

                      <Button
                        disabled={i >= sortedFieldNames.length - 1}
                        icon={<DownOutlined />}
                        onClick={() => {
                          let delta = 1;
                          let skipFieldsWithParent = true;
                          if (modalWithFieldsName || sectionNameToDisplayFieldsFor) {
                            skipFieldsWithParent = false;
                            const nextFieldName = sortedFieldNames[i + delta];
                            if (nextFieldName) {
                              const nextField = form.fields[nextFieldName];
                              if (nextField) {
                                delta = nextField.adminOrder - fieldData.adminOrder;
                              }
                            }
                          }

                          changeFieldOrder({
                            fieldData,
                            delta,
                            skipFieldsWithParent,
                          });
                        }}
                      ></Button>
                    </>
                  )}

                  {!fieldData.isPlaceholderForDynamicSectionList && (
                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        onFieldDelete({ fieldData });
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            {field}
          </div>
        );
      })}
    </div>
  );
}

export function displayTextfield({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}

      <Input
        placeholder={fieldData.placeholder}
        disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
        defaultValue={fieldData.value || ""}
        // setting autoComplete to "off" doesn't always work, so ChatGPT suggested I use a non-standard value, which seems to work
        autoComplete="nope"
        showBorder
        fullWidth
        fireOnChangeWithoutBlurWithDebounce
        debounceDelay={500}
        onChange={(value) => {
          updateFieldValue.call(this, {
            fieldName,
            value,
            nestedFieldParams,
          });
        }}
      />
    </div>
  );
}

export function displayProjectPicker({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}
      <ProjectPickerForm
        apiUser={this.props.apiUser}
        fieldName={fieldName}
        fieldData={fieldData}
        nestedFieldParams={nestedFieldParams}
        disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
        updateFieldValue={(params) => {
          updateFieldValue.call(this, params);
        }}
        windowWidth={this.props.windowWidth}
      />
    </div>
  );
}

export function displayTaskPicker({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}
      <TaskPicker
        value={fieldData.value}
        disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
        onChange={(value) => {
          updateFieldValue.call(this, {
            fieldName,
            value,
            nestedFieldParams,
          });
        }}
      />
    </div>
  );
}

export function displayInputLabel({ fieldData, fieldName, extraContent = null, onlyDisplayExplanation = false }) {
  let inlineDescriptionElement = null;
  let tooltipDescriptionElement = null;

  if (fieldData.hasInlineDescription && fieldData.inlineDescription) {
    let parsedInlineDescription;
    try {
      parsedInlineDescription = JSON.parse(fieldData.inlineDescription);
    } catch (e) {
      // nothing;
    }
    if (parsedInlineDescription) {
      inlineDescriptionElement = (
        <div className="field-inline-description">
          <Textarea
            memoize
            defaultValue={parsedInlineDescription}
            disabled
            documentProps={{
              ...this.props,
              ...this.state,
            }}
            extraToolbarButtons={null}
            basicFormattingOnly
          />
        </div>
      );
    }
  }

  if (fieldData.hasTooltipDescription && fieldData.tooltipDescription) {
    let parsedTooltipDescription;
    try {
      parsedTooltipDescription = JSON.parse(fieldData.tooltipDescription);
    } catch (e) {
      // nothing;
    }
    if (parsedTooltipDescription) {
      tooltipDescriptionElement = (
        <span className="field-tooltip-description">
          <Explanation
            overlayClassName="field-tooltip-description-overlay"
            title={
              <Textarea
                memoize
                defaultValue={parsedTooltipDescription}
                disabled
                documentProps={{
                  ...this.props,
                  ...this.state,
                }}
                extraToolbarButtons={null}
                basicFormattingOnly
              />
            }
          />
        </span>
      );
    }
  }

  return (
    <Typography.Paragraph
      className={cx("field-label", {
        "only-display-explanation": onlyDisplayExplanation,
      })}
    >
      {onlyDisplayExplanation ? (
        inlineDescriptionElement
      ) : (
        <>
          <Typography.Text className="field-name">
            {displayLabel.call(this, { fieldData, fieldName })}
            {tooltipDescriptionElement ? <> {tooltipDescriptionElement}</> : null}
            {extraContent ? extraContent : null}
          </Typography.Text>
          {inlineDescriptionElement}
        </>
      )}
    </Typography.Paragraph>
  );
}

export function displayDropdownField({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  let style = {
    width: "100%",
  };
  if (fieldData.hasOwnProperty("width")) {
    style.width = fieldData.width;
  }

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}

      <Select
        key={`${fieldName}-select-${this.state.numberForPreviewRefresh}`}
        style={style}
        defaultValue={fieldData.value ? fieldData.value : undefined}
        placeholder={fieldData.placeholder}
        disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
        onChange={(value) => {
          updateFieldValue.call(this, { fieldName, value, nestedFieldParams });
        }}
        allowClear={fieldData.allowClear}
      >
        {fieldData.options.map((option, index) => (
          <Select.Option key={index} value={option.value} disabled={option.disabled}>
            {option.label}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export function displayCheckbox({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;

  return (
    <InfoItem
      inline
      label={displayLabel.call(this, { fieldData, fieldName })}
      value={
        <Checkbox
          disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          onChange={(e) => {
            updateFieldValue.call(this, {
              fieldName,
              value: e.target.checked,
              nestedFieldParams,
            });
          }}
          checked={fieldData.value}
        />
      }
    />
  );
}

export function displayNumericalInput({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  return (
    <InfoItem
      label={displayLabel.call(this, { fieldData, fieldName })}
      value={
        <InputNumber
          placeholder={fieldData.placeholder}
          disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          onChange={(value) => {
            updateFieldValue.call(this, {
              fieldName,
              value,
              nestedFieldParams,
            });
          }}
          defaultValue={fieldData.value}
        />
      }
    />
  );
}

export function displayDatePicker({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;

  return (
    <InfoItem
      inline
      label={displayLabel.call(this, { fieldData, fieldName })}
      value={
        <DatePicker
          format={"DD-MM-YYYY"}
          placeholder={fieldData.placeholder}
          disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          allowClear={fieldData.allowClear}
          onChange={(value) => {
            updateFieldValue.call(this, {
              fieldName,
              value: value ? value.format("YYYY-MM-DD") : null,
              nestedFieldParams,
            });
          }}
          defaultValue={fieldData.value ? moment(fieldData.value) : undefined}
        />
      }
    />
  );
}

export function displayDateRangePicker({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;

  return (
    <InfoItem
      inline
      label={displayLabel.call(this, { fieldData, fieldName })}
      value={
        <DatePicker.RangePicker
          placeholder={fieldData.placeholder}
          disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          allowClear={fieldData.allowClear}
          onChange={(value) => {
            updateFieldValue.call(this, {
              fieldName,
              value,
              nestedFieldParams,
            });
          }}
          defaultValue={fieldData.value ? [moment(fieldData.value[0]), moment(fieldData.value[1])] : undefined}
        />
      }
    />
  );
}

export function displayCheckboxList({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const fieldIsUnderEdit = this.state.fieldUnderEditName === fieldName;
  const { userIsCat2Checker } = this.state;

  let editOptionsButton = null;
  let fieldContent = null;

  if (fieldData.useDynamicOptions && this.state.isFormEditor) {
    fieldContent = (
      <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>
        This checkbox list uses dynamic options. The options will only appear when you create a document from this
        template
      </Typography.Text>
    );
  } else {
    if (
      !fieldData.useDynamicOptions &&
      !taskRevision.isReadOnly &&
      !userIsCat2Checker &&
      fieldData.editable !== false &&
      !this.isReviewed
    ) {
      editOptionsButton = (
        <Button
          icon={fieldIsUnderEdit ? <CheckCircleOutlined /> : <EditOutlined />}
          type="clear"
          onClick={() => {
            this.setState({
              fieldUnderEditName: fieldIsUnderEdit ? null : fieldName,
            });
          }}
        >
          {fieldIsUnderEdit ? "Done" : "Edit options"}
        </Button>
      );
    }

    if (fieldIsUnderEdit) {
      fieldContent = (
        <>
          {Array.isArray(fieldData.options) &&
            fieldData.options.map((option, i) => (
              <div className="editable-option-item" key={i}>
                <Button
                  icon={<DeleteOutlined />}
                  disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
                  onClick={() => {
                    removeCheckboxOption.call(this, { optionIndex: i });
                  }}
                />
                <Typography.Text>{option.label}</Typography.Text>
              </div>
            ))}

          <Button
            style={{ display: "inline-block", marginTop: 10 }}
            icon={<PlusCircleOutlined />}
            disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
            type="primary"
            onClick={() => {
              openCheckboxOptionAddModal.call(this);
            }}
          >
            Add option
          </Button>
        </>
      );
    } else {
      if (fieldData.options && fieldData.options.length > 0) {
        fieldContent = (
          <Checkbox.Group
            disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
            onChange={(value) => {
              updateFieldValue.call(this, {
                fieldName,
                value,
                nestedFieldParams,
              });
            }}
            options={fieldData.options.filter((option) => Object.keys(option).length !== 0)}
            value={fieldData.value}
          />
        );
      } else {
        fieldContent = (
          <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>No options available</Typography.Text>
        );
      }
    }
  }

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName, extraContent: editOptionsButton })}

      {fieldContent}
    </div>
  );
}

export function removeCheckboxOption({ optionIndex }) {
  const { form, fieldUnderEditName } = this.state;

  const newFields = { ...form.fields };
  newFields[fieldUnderEditName].options.splice(optionIndex, 1);

  // to update

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
    },
    this.saveUserFields
  );
}

export function shouldFieldBeDisplayed({ form, fieldName }) {
  let fieldData = form.fields[fieldName];

  // this is for the old-style report forms, before the template editor
  if (fieldData.conditionTarget) {
    const targetField = form.fields[fieldData.conditionTarget];
    let targetFieldValue = targetField.value;
    if (typeof targetFieldValue === "string") {
      if (fieldData.conditionValuesIncluded && !fieldData.conditionValuesIncluded.includes(targetFieldValue)) {
        return false;
      }
      if (fieldData.conditionValuesExcluded && fieldData.conditionValuesExcluded.includes(targetFieldValue)) {
        return false;
      }
    } else if (Array.isArray(targetFieldValue)) {
      let isValidForIncludedValues =
        !fieldData.conditionValuesIncluded ||
        targetFieldValue.some((value) => fieldData.conditionValuesIncluded.includes(value));
      let isValidForExcludedValues =
        !fieldData.conditionValuesExcluded ||
        targetFieldValue.every((value) => !fieldData.conditionValuesExcluded.includes(value));
      if (!isValidForIncludedValues || !isValidForExcludedValues) {
        return false;
      }
    }

    // this for the new-style conditional display logic provided by the template editor
  } else if (fieldData.usesConditionalDisplay) {
    const expectedValue = fieldData.conditionalDisplayTarget;

    let targetFieldForComparisonData = form.fields[fieldData.conditionalDisplayDataSourceField];
    if (targetFieldForComparisonData) {
      let targetFieldForComparisonIsVisible = shouldFieldBeDisplayed({
        form,
        fieldName: fieldData.conditionalDisplayDataSourceField,
      });
      if (!targetFieldForComparisonIsVisible) {
        return false;
      }
    }

    if (!targetFieldForComparisonData) {
      message.error(
        `The field "${fieldData.label}" has conditional display active, but the configuration is incomplete`
      );
      return true;
    }

    const realValue = targetFieldForComparisonData.value;
    const elementShouldBeDisplayed = isConditionMet({
      expectedValue,
      realValue,
      condition: fieldData.conditionalDisplayCondition,
    });

    return elementShouldBeDisplayed;
  }
  return true;
}

export function displayRadioList({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  let fieldContent = null;
  if (fieldData.useDynamicOptions && this.state.isFormEditor) {
    fieldContent = (
      <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>
        This radio button list uses dynamic options. The options will only appear when you create a document from this
        template
      </Typography.Text>
    );
  } else {
    let value = fieldData.value;

    if (value) {
      let valueIsAmongOptions = fieldData.options.some((option) => option.value === value);
      if (!valueIsAmongOptions) {
        if (fieldData.autoSelectFirstOption && fieldData.options.length > 0) {
          value = fieldData.options[0].value;
        } else {
          value = undefined;
        }

        updateFieldValue.call(this, {
          fieldName,
          value,
          nestedFieldParams,
        });
      }
    }

    if (fieldData.options && fieldData.options.length > 0) {
      fieldContent = (
        <Radio.Group
          disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          onChange={(e) => {
            updateFieldValue.call(this, {
              fieldName,
              value: e.target.value,
              nestedFieldParams,
            });
          }}
          options={fieldData.options}
          value={value}
        />
      );
    } else {
      fieldContent = (
        <Typography.Text style={{ opacity: 0.7, fontStyle: "italic" }}>No options available</Typography.Text>
      );
    }
  }

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, {
        fieldData,
        fieldName,
        extraContent: (
          <>
            {fieldData.value &&
              !taskRevision.isReadOnly &&
              !userIsCat2Checker &&
              fieldData.editable !== false &&
              !this.isReviewed && (
                <>
                  {" "}
                  <Button
                    type="clear"
                    onClick={() => {
                      updateFieldValue.call(this, {
                        fieldName,
                        value: undefined,
                        nestedFieldParams,
                      });
                    }}
                  >
                    Clear selected option
                  </Button>
                </>
              )}
          </>
        ),
      })}

      {fieldContent}
    </div>
  );
}

function removeAttachmentItem({ fieldName, key }) {
  const { form } = this.state;

  const newFields = JSON.parse(JSON.stringify(form.fields));
  newFields[fieldName].value = newFields[fieldName].value.filter((x) => x !== key);

  // to update

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
    },
    this.saveUserFields
  );
}

export function displayAttachmentPickerField({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker, selectedAttachmentKeyForPreview } = this.state;

  let attachmentsForPreview = [];
  let selectedAttachmentForPreview = undefined;
  if (Array.isArray(fieldData.value)) {
    attachmentsForPreview = fieldData.value.map((key) => {
      return {
        key,
        name: key.replace(`public/${window.apiUser.organisation}/`, ""),
        type: getAttachmentTypeFromKey(key),
      };
    });
  }
  if (selectedAttachmentKeyForPreview) {
    selectedAttachmentForPreview = attachmentsForPreview.find(
      (attachment) => attachment.key === selectedAttachmentKeyForPreview
    );
  }

  return (
    <>
      {fieldData.allowPreview && selectedAttachmentKeyForPreview && (
        <FilePreview
          attachments={attachmentsForPreview}
          initiallySelectedAttachment={selectedAttachmentForPreview}
          onClose={() => this.setState({ selectedAttachmentKeyForPreview: undefined })}
          organisationId={this.props.organisationDetails.id}
          allowedTypes={["IMAGE"]}
        />
      )}
      <div className="input-group" key={fieldName}>
        {displayInputLabel.call(this, {
          fieldData,
          fieldName,
          extraContent: (
            <>
              {" "}
              {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && !fieldData.readOnly && (
                <Button
                  type="clear"
                  icon={<EditOutlined />}
                  onClick={() => {
                    if (nestedFieldParams?.rootFieldName) {
                      this.setState({
                        fieldUnderEditName: nestedFieldParams.rootFieldName,
                        subFieldUnderEditIndex: nestedFieldParams.childIndex,
                        subFieldUnderEditName: fieldName,
                        isAttachmentPickerOpen: true,
                      });
                    } else {
                      this.setState({
                        fieldUnderEditName: fieldName,
                        isAttachmentPickerOpen: true,
                      });
                    }
                  }}
                >
                  Choose attachments
                </Button>
              )}
            </>
          ),
        })}

        <ul className="attachment-items">
          {Array.isArray(fieldData.value) &&
            fieldData.value.map((key, i) => {
              let type = getAttachmentTypeFromKey(key);
              return (
                <li key={i} style={{ marginBottom: fieldData.fieldsPerItem && 10 }}>
                  {!(taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed) && !fieldData.readOnly && (
                    <Button
                      type="clear"
                      className="remove-attachment-button"
                      data-cy="remove-attachment-button"
                      icon={<CloseCircleOutlined />}
                      onClick={() => removeAttachmentItem.call(this, { key, fieldName })}
                    />
                  )}
                  {fieldData.withLabels && (
                    <Input
                      fullWidth
                      className="attachment-label-input"
                      fireOnChangeWithoutBlur
                      defaultValue={(fieldData.labels || {})[key]}
                      placeholder="Add a label"
                      onChange={(label) => {
                        let labels = JSON.parse(JSON.stringify(fieldData)).labels || {};
                        labels[key] = label;

                        updateFieldValue.call(this, {
                          fieldName,
                          keyName: "labels",
                          value: labels,
                          nestedFieldParams,
                        });
                      }}
                    />
                  )}
                  {fieldData.fieldsPerItem &&
                    fieldData.fieldsPerItem
                      .filter((extraField) => !extraField.hidden)
                      .map((extraField) => {
                        return (
                          <Input
                            key={extraField.name}
                            className="attachment-extra-field-input"
                            fireOnChangeWithoutBlur
                            defaultValue={(fieldData[`${extraField.name}s`] || {})[key] || ""}
                            placeholder={extraField.label}
                            showBorder
                            onChange={(value) => {
                              let newFieldData = JSON.parse(JSON.stringify(fieldData));
                              let extraFieldPluralName = newFieldData[`${extraField.name}s`] || {};
                              extraFieldPluralName[key] = value;

                              updateFieldValue.call(this, {
                                fieldName,
                                keyName: [`${extraField.name}s`],
                                value: extraFieldPluralName,
                                nestedFieldParams,
                              });
                            }}
                          />
                        );
                      })}
                  <Typography.Text
                    className={cx("attachment-name", {
                      "clickable-attachment-name": fieldData.allowPreview && ["IMAGE", "PDF"].includes(type),
                    })}
                    onClick={() =>
                      this.setState({
                        selectedAttachmentKeyForPreview: key,
                      })
                    }
                  >
                    {key.replace(`public/${window.apiUser.organisation}/`, "")}
                  </Typography.Text>{" "}
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
}

function displayAttachmentUploaderField({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  // const { isAttachmentPickerOpen, subFieldUnderEditIndex, form, formPreview, fieldUnderEditName } = this.state;
  // const { apiUser, task, quote, invoice, request } = this.props;

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}

      <Attachments
        {...this.props}
        defaultPath={fieldData.defaultPath ? fieldData.defaultPath : undefined}
        defaultRelativePath={fieldData.defaultRelativePath || fieldData.label}
        presentationOnly={fieldData.presentationOnly}
        isInModal={false}
        includeHomeButton={false}
        allowDownload={fieldData.allowDownload !== undefined ? fieldData.allowDownload : true}
        onUpload={({ result }) => {
          const { form } = this.state;

          const newFields = JSON.parse(JSON.stringify(form.fields));

          let newFieldValue = [...(newFields[fieldName].value || [])];
          for (let resultItem of result) {
            if (resultItem.status === "fulfilled" && !newFieldValue.includes(resultItem.value)) {
              newFieldValue.push(resultItem.value);
            }
          }

          updateFieldValue.call(this, {
            fieldName,
            value: newFieldValue,
            nestedFieldParams,
          });
        }}
      />
    </div>
  );
}

function removeSectionAttachment({ key, attachmentSection, fieldName }) {
  const { form } = this.state;

  const newFields = JSON.parse(JSON.stringify(form.fields));
  const targetField = newFields[fieldName].value?.find((field) => field.id === attachmentSection.id);
  targetField.value = targetField.value.filter((attachmentKey) => attachmentKey !== key);

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
    },
    this.saveUserFields
  );
}

export function displayAttachmentPickerSectionsField({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;
  return (
    <div key={fieldName}>
      <div className="input-group" style={{ marginBottom: 0 }}>
        {displayInputLabel.call(this, {
          fieldData,
          fieldName,
          extraContent: (
            <>
              {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
                <Button
                  icon={<PlusCircleOutlined />}
                  type="clear"
                  onClick={() => {
                    const { form } = this.state;

                    const newFields = JSON.parse(JSON.stringify(form.fields));
                    newFields[fieldName].value = [
                      ...newFields[fieldName].value,
                      {
                        id: String(Date.now()),
                        title: "Section title",
                        value: [],
                      },
                    ];

                    this.setState(
                      {
                        form: {
                          ...form,
                          fields: newFields,
                        },
                      },
                      this.saveUserFields
                    );
                  }}
                >
                  Add section
                </Button>
              )}
            </>
          ),
        })}
      </div>
      {fieldData.value.map((attachmentSection, sectionIndex) => {
        return (
          <div className="input-group attachment-picker-section-field" key={`${fieldName}-${attachmentSection.id}`}>
            <Typography.Paragraph className="field-label">
              <div className="attachment-section-title-and-description">
                <div className="attachment-section-title-container">
                  <Typography.Text className="attachment-section-label">Title</Typography.Text>
                  <Input
                    className="field-name"
                    defaultValue={attachmentSection.title || ""}
                    showBorder
                    fullWidth
                    onChange={(title) => {
                      const { form } = this.state;

                      const newFields = JSON.parse(JSON.stringify(form.fields));
                      newFields[fieldName].value[sectionIndex].title = title;

                      this.setState(
                        {
                          form: {
                            ...form,
                            fields: newFields,
                          },
                        },
                        this.saveUserFields
                      );
                    }}
                  />
                </div>
                <div className="attachment-section-title-container">
                  <Typography.Text className="attachment-section-label">Description</Typography.Text>
                  <Input
                    className="field-name"
                    multiLine
                    fullWidth
                    defaultValue={attachmentSection.description || ""}
                    showBorder
                    style={{ minHeight: "100%" }}
                    onChange={(description) => {
                      const { form } = this.state;

                      const newFields = JSON.parse(JSON.stringify(form.fields));
                      newFields[fieldName].value[sectionIndex].description = description;

                      this.setState(
                        {
                          form: {
                            ...form,
                            fields: newFields,
                          },
                        },
                        this.saveUserFields
                      );
                    }}
                  />
                </div>
              </div>

              {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
                <Button
                  icon={<EditOutlined />}
                  type="clear"
                  onClick={() => {
                    this.setState({
                      fieldUnderEditName: fieldName,
                      subFieldUnderEditIndex: sectionIndex,
                      isAttachmentPickerOpen: true,
                    });
                  }}
                >
                  Choose attachments
                </Button>
              )}
              {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
                <Button
                  icon={<DeleteOutlined />}
                  type="clear"
                  onClick={async () => {
                    try {
                      await new Promise((resolve, reject) => {
                        Modal.confirm({
                          title: "Confirm delete section",
                          maskClosable: true,
                          content: (
                            <>
                              Are you sure you want to delete the section <b>{attachmentSection.title}</b>?
                            </>
                          ),
                          onOk: () => {
                            resolve();
                          },
                          onCancel: () => {
                            reject();
                          },
                        });
                      });
                    } catch (e) {
                      // nothing, it means the user said "no"
                      return;
                    }

                    const { form } = this.state;

                    const newFields = JSON.parse(JSON.stringify(form.fields));
                    newFields[fieldName].value.splice(sectionIndex, 1);

                    this.setState(
                      {
                        form: {
                          ...form,
                          fields: newFields,
                        },
                      },
                      this.saveUserFields
                    );
                  }}
                >
                  Delete section
                </Button>
              )}
            </Typography.Paragraph>

            <Typography.Text className="attachment-list-title">Attachments selected</Typography.Text>
            <ul>
              {Array.isArray(attachmentSection.value) &&
                attachmentSection.value.map((key, i) => (
                  <li key={i} style={{ listStyle: "none" }}>
                    <Button
                      icon={<CloseCircleOutlined />}
                      onClick={() =>
                        removeSectionAttachment.call(this, {
                          key,
                          attachmentSection,
                          fieldName: "appendix",
                        })
                      }
                      style={{ backgroundColor: "#e0eef4", border: "unset" }}
                    />
                    <Typography.Text>{getFilenameFromKey(key, true)}</Typography.Text>{" "}
                  </li>
                ))}
            </ul>
          </div>
        );
      })}
    </div>
  );
}

export function displayUserInputListField({ fieldData, fieldName, taskRevision }) {
  const { userIsCat2Checker } = this.state;
  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, {
        fieldData,
        fieldName,
        extraContent: (
          <>
            {" "}
            {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
              <Button
                icon={<PlusCircleOutlined />}
                type="clear"
                onClick={() => {
                  this.setState({
                    fieldUnderEditName: fieldName,
                    isReportUserListModalOpen: true,
                  });
                }}
              >
                Add item
              </Button>
            )}
          </>
        ),
      })}

      <ul className="user-list-options">
        {Array.isArray(fieldData.value) &&
          fieldData.value
            .filter((item) => item)
            .map((item, i) => (
              <li className="user-list-option-item" key={i}>
                <div className="item-content">
                  {Object.keys(item).map((itemKey, j) => {
                    return (
                      <Typography.Paragraph key={j}>
                        <b>{fieldData.fields[itemKey].shortLabel || fieldData.fields[itemKey].label}</b> {item[itemKey]}
                      </Typography.Paragraph>
                    );
                  })}
                </div>
                {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
                  <Button
                    icon={<DeleteOutlined />}
                    className="delete-item"
                    onClick={() =>
                      removeUserListItem.call(this, {
                        itemIndex: i,
                        fieldName,
                      })
                    }
                  />
                )}
                {!taskRevision.isReadOnly && !userIsCat2Checker && !this.isReviewed && (
                  <Button
                    icon={<EditOutlined />}
                    className="edit-item"
                    onClick={() =>
                      this.setState({
                        isReportUserListModalOpen: true,
                        fieldUnderEditName: fieldName,
                        fieldUnderEditValue: fieldData.value,
                        subFieldUnderEditIndex: i,
                      })
                    }
                  />
                )}
              </li>
            ))}
      </ul>
    </div>
  );
}

export function displayNestedFieldListWithModal({ fieldData, fieldName, taskRevision }) {
  const { userIsCat2Checker, isFormEditor, templateDetails, form } = this.state;
  return (
    <NestedFieldListWithModal
      fieldData={fieldData}
      fieldName={fieldName}
      taskRevision={taskRevision}
      userIsCat2Checker={userIsCat2Checker}
      isFormEditor={isFormEditor}
      isReviewed={this.isReviewed}
      removeUserListItem={(params) => removeUserListItem.call(this, params)}
      setState={(params, callback) => this.setState(params, callback)}
      saveUserFields={() => this.saveUserFields()}
      templateDetails={templateDetails}
      onReportUserListSubmit={(params) => onReportUserListSubmit.call(this, params)}
      form={form}
      windowWidth={this.props.windowWidth}
    />
  );
}

export function displayDynamicSectionList({ fieldData, fieldName, taskRevision }) {
  const { userIsCat2Checker, isFormEditor, templateDetails, form } = this.state;
  return (
    <DynamicSectionList
      fieldData={fieldData}
      fieldName={fieldName}
      taskRevision={taskRevision}
      userIsCat2Checker={userIsCat2Checker}
      isFormEditor={isFormEditor}
      isReviewed={this.isReviewed}
      removeUserListItem={(params) => removeUserListItem.call(this, params)}
      setState={(params, callback) => this.setState(params, callback)}
      saveUserFields={() => this.saveUserFields()}
      templateDetails={templateDetails}
      onReportUserListSubmit={(params) => onReportUserListSubmit.call(this, params)}
      form={form}
      windowWidth={this.props.windowWidth}
    />
  );
}

export function displayLabel({ fieldData, fieldName }) {
  const { isFormEditor } = this.state;
  const { validationErrors } = this.props;

  return (
    <FieldLabel
      fieldData={fieldData}
      fieldName={fieldName}
      isFormEditor={isFormEditor}
      validationErrors={validationErrors}
    />
  );
}

export function displayNestedFieldListNoModal({ fieldData, fieldName, taskRevision, formEditorParams }) {
  const { userIsCat2Checker, form } = this.state;
  return (
    <div className="input-group" key={fieldName}>
      <InfoItem
        includeColon={false}
        label={displayLabel.call(this, { fieldData, fieldName })}
        inline
        value={
          !taskRevision.isReadOnly &&
          !userIsCat2Checker &&
          !this.isReviewed && (
            <Button
              icon={<PlusCircleOutlined />}
              type="clear"
              onClick={() => {
                const newForm = JSON.parse(JSON.stringify(form));
                if (!newForm.fields[fieldName].value) {
                  newForm.fields[fieldName].value = [];
                }
                newForm.fields[fieldName].value.push({});

                this.setState(
                  {
                    form: newForm,
                    isReportUserListModalOpen: false,
                    fieldUnderEditName: null,
                    fieldUnderEditValue: null,
                    subFieldUnderEditIndex: null,
                  },
                  this.saveUserFields
                );
              }}
            >
              Add item
            </Button>
          )
        }
        extraContent={fieldData.value?.map((nestedField, index) => {
          return (
            <div className="nested-field-item" key={index}>
              {displayFields.call(this, {
                showHiddenByModal: false,
                showNestedFieldsInModal: true,
                rootField: fieldData,
                rootFieldName: fieldName,
                childField: nestedField,
                childIndex: index,
                ...formEditorParams,
              })}
            </div>
          );
        })}
      />
    </div>
  );
}

export function addCheckboxOption({ option }) {
  const { form, fieldUnderEditName } = this.state;

  const newFields = { ...form.fields };
  newFields[fieldUnderEditName].options.push(option);

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
    },
    this.saveUserFields
  );
}

export function removeUserListItem({ itemIndex, fieldName }) {
  const { form } = this.state;

  const newFields = { ...form.fields };
  newFields[fieldName].value.splice(itemIndex, 1);

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
    },
    this.saveUserFields
  );
}

export function openCheckboxOptionAddModal() {
  const modal = Modal.info({
    title: "Option value:",
    icon: null,
    className: "add-report-field-option-modal",
    maskClosable: true,
    content: (
      <AntInput
        onChange={(e) => {
          this.setState({ fieldUnderEditValue: e.target.value });
        }}
      />
    ),
    onOk: () => {
      addCheckboxOption.call(this, {
        option: {
          label: this.state.fieldUnderEditValue,
          value: this.state.fieldUnderEditValue,
        },
      });
      modal.destroy();
    },
  });
}

export function onReportUserListSubmit(itemDetails) {
  console.log("onReportUserListSubmit() itemDetails = ", itemDetails);
  const { form, fieldUnderEditName, subFieldUnderEditIndex } = this.state;

  const newFields = JSON.parse(JSON.stringify(form.fields));

  if (!Array.isArray(newFields[fieldUnderEditName].value)) {
    newFields[fieldUnderEditName].value = [];
  }

  if (subFieldUnderEditIndex === null) {
    newFields[fieldUnderEditName].value.push(itemDetails);
  } else {
    newFields[fieldUnderEditName].value[subFieldUnderEditIndex] = itemDetails;
  }

  this.setState(
    {
      form: {
        ...form,
        fields: newFields,
      },
      isReportUserListModalOpen: false,
      fieldUnderEditName: null,
      fieldUnderEditValue: null,
      subFieldUnderEditIndex: null,
    },
    async () => {
      await this.saveUserFields();
      message.success("Item saved");
    }
  );
}

export function getPastedFiles(data) {
  const acceptedFormats = ["application/pdf", "image/png", "image/jpg", "image/jpeg"];

  let files = [];
  if (!data.items || data.items.length === 0) {
    return files;
  }
  // NOTE: This needs to be a for loop because it's a list-like object
  for (let i = 0; i < data.items.length; i++) {
    const item = data.items[i];
    if (item.kind === "file" && acceptedFormats.includes(item.type)) {
      files.push(item.getAsFile());
    }
  }
  return files;
}

export async function onTextareaPaste({ e, fieldData, fieldName, isNewEditor }) {
  const { apiUser, task, quote, invoice } = this.props;
  const { projectFolder } = this.state;
  const files = getPastedFiles.call(this, e.clipboardData);

  this.setState({ fieldUnderEditName: fieldName });

  if (!files || files.length === 0) {
    return;
  }

  const loadingModal = Modal.info({
    icon: <LoadingOutlined />,
    footer: null,
    closable: false,
    className: "modal-no-buttons",
    title: <>Uploading files...</>,
  });

  e.preventDefault();
  this.setState({
    selectedField: fieldName,
    fieldUnderEditselectionStart: e.target.selectionStart,
  });

  let keys = [];
  let uploadPromises = [];

  for (let i = 0; i < files.length; i++) {
    const file = files[i];

    let extraSuffix = "";
    if (files.length > 1) {
      extraSuffix = `_${i + 1}`;
    }

    let fileName;

    if (file.name && file.name !== "image.png" && file.name !== "image.jpg") {
      fileName = file.name;
    } else {
      fileName = `${moment().format("DD-MM-YYYY_HH-mm-ss")}${extraSuffix}_${apiUser.firstName || ""}_${
        apiUser.lastName || ""
      }.${file.type.split("/")[1]}`;
    }

    let localKey = `${task?.id || quote?.id || invoice?.id}/${fileName}`;
    const key = `${projectFolder}/attachments/${localKey}`.replace("public/", "");
    const keyFullSize = `${projectFolder}/attachments/pasted/full_size_versions/${fileName}`.replace("public/", "");
    keys.push(localKey);

    if (file.type?.includes("image") && file.size >= THRESHOLD_FOR_FULL_SIZE_VERSION) {
      let resizedFile = await readAndCompressImage(file, {
        quality: 0.6,
        maxWidth: ATTACHMENTS_MAX_IMAGE_WIDTH,
        maxHeight: ATTACHMENTS_MAX_IMAGE_HEIGHT,
        mimeType: file.type,
      });

      uploadPromises.push(
        Storage.put(keyFullSize, file, {
          contentType: file.type,
        })
      );

      uploadPromises.push(
        Storage.put(key, resizedFile, {
          contentType: file.type,
        })
      );
    } else {
      uploadPromises.push(
        Storage.put(key, file, {
          contentType: file.type,
        })
      );
    }
  }

  if (uploadPromises.length > 0) {
    await Promise.all(uploadPromises);
    onAttachmentSubmit.call(this, keys);
  }

  loadingModal.destroy();
}

function displaySeparator({ fieldData, fieldName }) {
  return (
    <>
      <div className={cx("form-separator")} />
      {fieldData.hasTooltipDescription || fieldData.hasInlineDescription
        ? displayInputLabel.call(this, { fieldData, fieldName, onlyDisplayExplanation: true })
        : null}
    </>
  );
}

function displayTextAreaToolbox({
  taskRevision,
  fieldData,
  fieldName,
  sectionFieldName,
  isUnderReview,
  userIsCat2Checker,
}) {
  let { fieldUnderEditName, fieldUnderEditSelectionStart } = this.state;

  let isDisabled = true;

  if (fieldUnderEditName && (fieldUnderEditName === fieldName || fieldUnderEditName === sectionFieldName)) {
    isDisabled = false;
  }

  return (
    <div
      className={cx("editor-toolbox", {
        "is-under-review": isUnderReview,
        disabled: isDisabled,
      })}
    >
      <Tooltip title="Insert page break">
        {taskRevision?.isReadOnly || userIsCat2Checker || this.isReviewed ? null : (
          <Button
            icon={<PageBreakIcon />}
            className="textarea-insert-page-break"
            onClick={() => {
              let selectionStart = fieldUnderEditSelectionStart;
              let fieldNameOrSectionFieldName = fieldName;
              if (sectionFieldName) {
                fieldNameOrSectionFieldName = sectionFieldName;
              }

              if (fieldUnderEditName !== fieldNameOrSectionFieldName) {
                selectionStart = 0;
              }

              insertTextIntoField.call(this, {
                fieldData,
                fieldUnderEditName: fieldUnderEditName,
                fieldUnderEditSelectionStart: selectionStart,
                fieldNameToChange: fieldName,
                textToInsert: "[page break]",
              });
            }}
          />
        )}
      </Tooltip>
      <Tooltip title="Insert attachment" mouseEnterDelay={0.7}>
        {taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed ? null : (
          <Button
            icon={<PaperclipIcon />}
            data-cy="insert-attachment-button"
            className="textarea-insert-attachment"
            onClick={() => {
              let selectionStart = fieldUnderEditSelectionStart;
              let fieldNameOrSectionFieldName = fieldName;
              if (sectionFieldName) {
                fieldNameOrSectionFieldName = sectionFieldName;
              }

              if (fieldUnderEditName !== fieldNameOrSectionFieldName) {
                selectionStart = 0;
              }
              this.setState({
                isInsertAttachmentsModalOpen: true,
                fieldUnderEditSelectionStart: selectionStart,
                fieldUnderEditName: fieldNameOrSectionFieldName,
              });
            }}
          />
        )}
      </Tooltip>
    </div>
  );
}

export function displaySectionList({ fieldData, fieldName, taskRevision }) {
  const { userIsCat2Checker, isUnderReview } = this.state;
  return (
    <div className="input-group" key={fieldName} data-cy="section-list" data-name={fieldName}>
      {fieldData.label && fieldData.label !== "" && displayInputLabel.call(this, { fieldData, fieldName })}

      {fieldData.value.map((section, i) => {
        const sectionFieldName = `${fieldName}_${section.id}`;
        return (
          <div className="text-section-item" key={i + section.id}>
            <div className="field-label">
              <Typography.Text className="field-name">Section title</Typography.Text>
              <Input
                className="field-name"
                fullWidth
                showBorder
                data-cy="field-name-input"
                placeholder="Section title"
                defaultValue={section.title || ""}
                disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
                onChange={(title) => {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        fields: {
                          ...this.state.form.fields,
                          [fieldName]: {
                            ...fieldData,
                            value: fieldData.value.map((crtSection) => {
                              if (crtSection.id === section.id) {
                                return { ...section, title };
                              } else {
                                return crtSection;
                              }
                            }),
                          },
                        },
                      },
                    },
                    this.debouncedSaveUserFields
                  );
                }}
              />
            </div>

            <div className="field-label">
              <Typography.Text className="field-name">Section body</Typography.Text>
            </div>
            <div className="section-textarea-container">
              <AntInput.TextArea
                onPaste={(e) =>
                  onTextareaPaste.call(this, { e, fieldData, fieldName: sectionFieldName, isNewEditor: false })
                }
                autoSize={{ minRows: 3 }}
                disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
                value={section.text || ""}
                data-cy={`${fieldName}-textarea`}
                onClick={(e) => {
                  this.setState({
                    fieldUnderEditName: sectionFieldName,
                    fieldUnderEditSelectionStart: e.target.selectionStart,
                  });
                }}
                onKeyUp={(e) => {
                  if (this.state.fieldUnderEditName !== sectionFieldName) {
                    this.setState({
                      fieldUnderEditName: sectionFieldName,
                    });
                  }
                  this.setState({
                    fieldUnderEditSelectionStart: e.target.selectionStart,
                  });
                }}
                onChange={(e) => {
                  this.setState(
                    {
                      form: {
                        ...this.state.form,
                        fields: {
                          ...this.state.form.fields,
                          [fieldName]: {
                            ...fieldData,
                            value: fieldData.value.map((crtSection) => {
                              if (crtSection.id === section.id) {
                                return {
                                  ...section,
                                  text: getProcessedTextareaContent(e, latestKeyPressed),
                                };
                              } else {
                                return crtSection;
                              }
                            }),
                          },
                        },
                      },
                    },
                    () => {
                      e.target.selectionEnd = e.target.selectionStart;
                      this.debouncedSaveUserFields();
                    }
                  );
                }}
              />
              {fieldData.textOnly
                ? null
                : displayTextAreaToolbox.call(this, {
                    taskRevision,
                    fieldData,
                    fieldName,
                    sectionFieldName,
                    isUnderReview,
                    userIsCat2Checker,
                    basicFormattingOnly: fieldData.basicFormattingOnly,
                  })}
            </div>
            <Tooltip title="Delete section">
              {taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed ? null : (
                <Button
                  icon={<DeleteOutlined />}
                  className="delete-section"
                  data-cy="delete-section-button"
                  onClick={() => deleteSection.call(this, { fieldData, fieldName, section })}
                />
              )}
            </Tooltip>
          </div>
        );
      })}
      <Button
        type="primary"
        onClick={() => {
          this.setState(
            {
              form: {
                ...this.state.form,
                fields: {
                  ...this.state.form.fields,
                  [fieldName]: {
                    ...fieldData,
                    value: [
                      ...fieldData.value,
                      {
                        title: "New section",
                        text: "",
                        id: String(Date.now()),
                      },
                    ],
                  },
                },
              },
            },
            this.saveUserFields
          );
        }}
      >
        <PlusCircleOutlined /> <span>Add section</span>
      </Button>
    </div>
  );
}

export function displayTaskFilePicker({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker } = this.state;

  let selectedFile = getSelectedFileFromTask({ fieldData, taskRevision });

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}
      <Radio.Group
        onChange={async (e) => {
          await updateFieldValue.call(this, {
            fieldName,
            value: e.target.value,
            nestedFieldParams,
          });

          setTimeout(window.reloadData, 500);
        }}
        value={selectedFile}
        disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
      >
        <Space direction="vertical">
          {taskRevision.files.items
            .filter((x) => !x.isArchived && x.type === fieldData.fileType)
            .map((crtFile) => {
              return (
                <Radio key={crtFile.id} value={crtFile.constantId}>
                  {crtFile.name}
                </Radio>
              );
            })}
        </Space>
      </Radio.Group>
    </div>
  );
}

export function displayModalWithFieldsInput({ fieldData, fieldName, taskRevision }) {
  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}
      <Button
        type="primary"
        onClick={() => {
          this.setState({
            isModalWithFieldsVisible: true,
            modalWithFieldsName: fieldName,
          });
        }}
      >
        {fieldData.buttonText || "Edit"}
      </Button>
    </div>
  );
}

function displaySectionHeading({ fieldData }) {
  return (
    <div className={cx("form-section-heading")}>
      <Typography.Title level={2}>{fieldData.label}</Typography.Title>
    </div>
  );
}

export function displaySection({ fieldData, fieldName, taskRevision }) {
  const stateKey = `section-isOpen-${fieldName}`;
  const { form, isFormEditor } = this.state;

  if (fieldData.isPlaceholderForDynamicSectionList) {
    // we don't display this field, it's just meant to be kept in the data structure, so we can reference it
    return null;
  }

  let parentDynamicSectionListId = fieldData.parentDynamicSectionList;
  let parentDynamicSectionListField;
  let realItemIndexInDynamicSectionList;
  let itemCountInDynamicSectionList;
  if (parentDynamicSectionListId) {
    parentDynamicSectionListField = form.fields[parentDynamicSectionListId];

    let sectionFields = [];
    for (let crtFieldName in form.fields) {
      let crtField = form.fields[crtFieldName];
      if (crtField.parentDynamicSectionList === fieldData.parentDynamicSectionList) {
        sectionFields.push(crtField);
      }
    }
    itemCountInDynamicSectionList = sectionFields.length;

    sectionFields.sort((a, b) => a.adminOrder - b.adminOrder);
    realItemIndexInDynamicSectionList = sectionFields.findIndex((x) => x.id === fieldData.id);
  }
  let isAlwaysExpanded =
    !isFormEditor && (fieldData.isAlwaysExpanded || parentDynamicSectionListField?.sectionItemsAreAlwaysExpanded);

  let sectionIsOpen = isAlwaysExpanded || this.state[stateKey];

  const validationErrors = this.props.validationErrors || [];
  const fieldNamesWithValidationErrors = validationErrors.map((x) => x.fieldKey);

  let fieldsNamesInThisSection = [];
  for (let currentFieldName in this.state.form.fields) {
    let currentFieldDetails = this.state.form.fields[currentFieldName];
    if (currentFieldDetails.parentSection === fieldName) {
      fieldsNamesInThisSection.push(currentFieldName);
    }
  }

  let anyFieldsInThisSectionAreInvalid = false;
  for (let currentFieldName of fieldsNamesInThisSection) {
    if (fieldNamesWithValidationErrors.includes(currentFieldName)) {
      anyFieldsInThisSectionAreInvalid = true;
      break;
    }
  }

  if (anyFieldsInThisSectionAreInvalid) {
    sectionIsOpen = true;
  }

  let fieldLabel = fieldData.label;
  if (fieldData.isPlaceholderForDynamicSectionList) {
    let dynamicSectionListField = form.fields[fieldData.isPlaceholderForDynamicSectionList];
    if (dynamicSectionListField) {
      fieldLabel = `Fields for "${dynamicSectionListField.label}"`;
    }
  }

  return (
    <div
      className={cx("form-section", {
        open: sectionIsOpen,
        "has-invalid-fields": anyFieldsInThisSectionAreInvalid,
        "placeholder-section": fieldData.isPlaceholderForDynamicSectionList,
        "compact-fields": fieldData.compactFields,
        "always-expanded": isAlwaysExpanded,
      })}
    >
      {parentDynamicSectionListField && (
        <div className="section-item-buttons">
          {realItemIndexInDynamicSectionList > 0 && (
            <Button
              icon={<UpOutlined />}
              onClick={() => {
                let newFields = JSON.parse(JSON.stringify(form.fields));
                let fieldDetailsInNewFields = newFields[fieldName];
                let sectionFields = [];
                for (let crtFieldName in newFields) {
                  let crtField = newFields[crtFieldName];
                  if (crtField.parentDynamicSectionList === fieldDetailsInNewFields.parentDynamicSectionList) {
                    sectionFields.push(crtField);
                  }
                }

                sectionFields.sort((a, b) => a.adminOrder - b.adminOrder);
                let crtFieldIndex = sectionFields.findIndex((x) => x.id === fieldDetailsInNewFields.id);

                let temp = sectionFields[crtFieldIndex];
                sectionFields[crtFieldIndex] = sectionFields[crtFieldIndex - 1];
                sectionFields[crtFieldIndex - 1] = temp;

                for (let i = 0; i < sectionFields.length; i++) {
                  sectionFields[i].adminOrder = i / 100;
                }

                message.success("Section moved up");

                this.setState(
                  {
                    form: {
                      ...form,
                      fields: newFields,
                    },
                  },
                  this.saveUserFields
                );
              }}
            />
          )}
          {realItemIndexInDynamicSectionList < itemCountInDynamicSectionList - 1 && (
            <Button
              icon={<DownOutlined />}
              onClick={() => {
                let newFields = JSON.parse(JSON.stringify(form.fields));
                let fieldDetailsInNewFields = newFields[fieldName];
                let sectionFields = [];
                for (let crtFieldName in newFields) {
                  let crtField = newFields[crtFieldName];
                  if (crtField.parentDynamicSectionList === fieldDetailsInNewFields.parentDynamicSectionList) {
                    sectionFields.push(crtField);
                  }
                }

                sectionFields.sort((a, b) => a.adminOrder - b.adminOrder);
                let crtFieldIndex = sectionFields.findIndex((x) => x.id === fieldDetailsInNewFields.id);

                let temp = sectionFields[crtFieldIndex];
                sectionFields[crtFieldIndex] = sectionFields[crtFieldIndex + 1];
                sectionFields[crtFieldIndex + 1] = temp;

                for (let i = 0; i < sectionFields.length; i++) {
                  sectionFields[i].adminOrder = i / 100;
                }

                message.success("Section moved down");

                this.setState(
                  {
                    form: {
                      ...form,
                      fields: newFields,
                    },
                  },
                  this.saveUserFields
                );
              }}
            />
          )}
          <Tooltip title="Delete section">
            <Button
              icon={<DeleteOutlined />}
              onClick={() => {
                Modal.confirm({
                  title: "Delete section",
                  content: "Are you sure you want to delete this section?",
                  onOk: () => {
                    let newFields = JSON.parse(JSON.stringify(form.fields));
                    for (let crtFieldName in newFields) {
                      let crtField = newFields[crtFieldName];
                      if (crtField.parentSection === fieldName) {
                        delete newFields[crtFieldName];
                      }
                    }
                    delete newFields[fieldName];

                    this.setState(
                      {
                        form: {
                          ...form,
                          fields: newFields,
                        },
                      },
                      this.saveUserFields
                    );
                  },
                });
              }}
            />
          </Tooltip>
        </div>
      )}
      <div
        className={cx("form-section-heading", "clickable")}
        data-name={fieldName}
        onClick={() => {
          this.setState(
            {
              [stateKey]: !sectionIsOpen,
            },
            () => {
              // scroll to the top of the section
              setTimeout(() => {
                if (sectionIsOpen) {
                  return;
                }
                const sectionHeadingElement = document.querySelector(`.form-section-heading[data-name="${fieldName}"]`);
                if (sectionHeadingElement) {
                  sectionHeadingElement.scrollIntoView({ behavior: "smooth" });
                }
              }, 0);
            }
          );
        }}
      >
        <RightOutlined className={cx("open-close-section-icon", { rotated: sectionIsOpen })} />
        <Typography.Title level={2}>{fieldLabel}</Typography.Title>
      </div>
      <div className="form-section-content">
        {sectionIsOpen &&
          displayFields.call(this, {
            showHiddenByModal: false,
            sectionNameToDisplayFieldsFor: fieldName,
          })}
      </div>
    </div>
  );
}

export function displayButton({ fieldData, fieldName, nestedFieldParams, taskRevision }) {
  return (
    <div className="input-group" key={fieldName}>
      <Button
        type="primary"
        onClick={async () => {
          if (!this.props.quote) {
            return;
          }
          let fileKey = this.props.quote.fileKey.replace(".json", ".pdf");

          const buildDocumentResponse = await callRest({
            route: "/build-document",
            method: "POST",
            body: {
              organisationId: this.props.quote.organisation,
              quoteId: this.props.quote.id,
              projectId: this.props.quote.projectId,
              clientId: this.props.quote.clientId,
              targetFormButtonId: fieldData.id,
            },
            includeCredentials: false,
          });

          if (buildDocumentResponse.errorMessage) {
            throw new Error(`This error originated on the server: ${buildDocumentResponse.errorMessage}`);
          }

          const publicUrl = buildDocumentResponse.signedUrl;

          const fileBlob = (
            await axios({
              url: publicUrl,
              method: "GET",
              responseType: "blob", // Important
            })
          ).data;

          let fileName = fileKey.split("/").slice(-1)[0];

          if (this.props.quote.currentRevisionName) {
            fileName = fileName.replace(".pdf", ` ${this.props.quote.currentRevisionName}.pdf`);
          }

          if (fieldData.resultingFileNameSuffix) {
            fileName = fileName.replace(".pdf", `${fieldData.resultingFileNameSuffix}.pdf`);
          }

          await downloadBlob({
            blob: fileBlob,
            fileName,
          });
        }}
      >
        {fieldData.label}
      </Button>
    </div>
  );
}

export function displaySpreadsheet({ fieldData, fieldName, nestedFieldParams, taskRevision }) {
  const { userIsCat2Checker, isUnderReview } = this.state;

  const isReadOnly = taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed;

  let spreadsheetData;
  if (fieldData.value) {
    spreadsheetData = JSON.parse(fieldData.value);
  } else {
    spreadsheetData = {
      columnCount: DOCUMENT_FORM_SPREADSHEET_DEFAULT_COLUMNS,
      rowCount: DOCUMENT_FORM_SPREADSHEET_DEFAULT_ROWS,
      rows: [],
    };
  }

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}

      <ReviewTarget name={`spreadsheet-${fieldName}`} {...this} {...this.props} visible={isUnderReview}>
        <ErrorBoundary>
          <div className="spreadsheet-container">
            <div className="spreadsheet-inner-container">
              <Spreadsheet
                data={spreadsheetData}
                onChange={(value) => {
                  updateFieldValue.call(this, {
                    fieldName,
                    value,
                    nestedFieldParams,
                  });
                }}
                isReadOnly={isReadOnly}
              />
              {/* <Spreadsheet
                data={spreadsheetData}
                onChange={(newSpreadsheetData) => {
                  console.log("onChange()");
                  const stringifiedSpreadsheet = getSerialisedSpreadsheet(newSpreadsheetData);
                  if (fieldData.value !== stringifiedSpreadsheet) {
                    updateFieldValue.call(this, {
                      fieldName,
                      value: stringifiedSpreadsheet,
                      nestedFieldParams,
                    });
                  }
                }}
                mode={mode}
              /> */}
              <div className="after-spreadsheet">
                <Button
                  icon={<PlusCircleOutlined />}
                  onClick={() => {
                    updateFieldValue.call(this, {
                      fieldName,
                      value: JSON.stringify({
                        ...spreadsheetData,
                        rowCount: spreadsheetData.rowCount + 1,
                      }),
                      nestedFieldParams,
                    });
                  }}
                >
                  Add row
                </Button>
              </div>
            </div>
          </div>
        </ErrorBoundary>
      </ReviewTarget>
    </div>
  );
}

export function displayTextarea({ fieldData, fieldName, taskRevision, nestedFieldParams }) {
  const { userIsCat2Checker, isUnderReview, isHardcodedTemplate } = this.state;
  let parsedFieldValue;
  const isEmailText = fieldName === "emailText";
  let useNewEditor = true;

  if (isHardcodedTemplate || isEmailText) {
    useNewEditor = false;
  } else {
    try {
      if (fieldData.value && fieldData.value !== "") {
        parsedFieldValue = JSON.parse(fieldData.value);
      }
    } catch (e) {
      useNewEditor = false;
    }
  }

  if (useNewEditor && parsedFieldValue && !Array.isArray(parsedFieldValue)) {
    parsedFieldValue = [];
  }

  return (
    <div className="input-group" key={fieldName}>
      {displayInputLabel.call(this, { fieldData, fieldName })}

      <ReviewTarget name={`dynamic-field-${fieldName}`} {...this} {...this.props} visible={isUnderReview}>
        {useNewEditor ? (
          <Textarea
            memoize
            defaultValue={parsedFieldValue}
            debouncedOnChange={(newValue) => {
              updateFieldValue.call(this, {
                fieldName,
                value: newValue,
                nestedFieldParams,
              });
            }}
            documentProps={{
              ...this.props,
              ...this.state,
            }}
            extraToolbarButtons={fieldData.textOnly ? undefined : ["page-break", "attachment"]}
            basicFormattingOnly={fieldData.basicFormattingOnly}
            disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
          />
        ) : (
          <AntInput.TextArea
            data-cy={`${fieldName}-textarea`}
            onPaste={(e) => onTextareaPaste.call(this, { e, fieldData, fieldName, isNewEditor: true })}
            autoSize={{ minRows: 3 }}
            disabled={taskRevision.isReadOnly || userIsCat2Checker || this.isReviewed}
            value={fieldData.value || ""}
            onClick={(e) => {
              this.setState({
                fieldUnderEditName: fieldName,
                fieldUnderEditSelectionStart: e.target.selectionStart,
              });
            }}
            onKeyDown={(e) => {
              latestKeyPressed = e.key;
            }}
            onKeyUp={(e) => {
              if (this.state.fieldUnderEditName !== fieldName) {
                this.setState({
                  fieldUnderEditName: fieldName,
                });
              }
              this.setState({
                fieldUnderEditSelectionStart: e.target.selectionStart,
              });
            }}
            onChange={(e) => {
              updateFieldValue.call(this, {
                fieldName,
                value: getProcessedTextareaContent(e, latestKeyPressed),
                nestedFieldParams,
              });
            }}
          />
        )}
      </ReviewTarget>
      {!useNewEditor &&
        !fieldData.textOnly &&
        displayTextAreaToolbox.call(this, {
          taskRevision,
          fieldData,
          fieldName,
          isUnderReview,
          userIsCat2Checker,
        })}
    </div>
  );
}

export async function deleteSection({ fieldData, fieldName, section }) {
  try {
    await new Promise((resolve, reject) => {
      Modal.confirm({
        title: "Confirm delete section",
        maskClosable: true,
        content: <>Are you sure you want to delete the selected section?</>,
        onOk: () => {
          resolve();
        },
        onCancel: () => {
          reject();
        },
      });
    });
  } catch (e) {
    // nothing, it means the user said "no"
    return;
  }

  this.setState(
    {
      form: {
        ...this.state.form,
        fields: {
          ...this.state.form.fields,
          [fieldName]: {
            ...fieldData,
            value: fieldData.value.filter((crtSection) => crtSection.id !== section.id),
          },
        },
      },
    },
    this.saveUserFields
  );
}

async function updateFieldValue({ fieldName, value, keyName = "value", nestedFieldParams }) {
  const { form } = this.state;
  if (!this.debouncedSaveUserFields) {
    return;
  }

  let { rootFieldName, childIndex } = nestedFieldParams || {};

  let newForm = JSON.parse(JSON.stringify(form));
  let targetField = newForm.fields[rootFieldName];
  if (childIndex !== undefined) {
    if (!Array.isArray(targetField.value)) {
      targetField.value = [{}];
    }
    targetField = targetField.value[childIndex];
    keyName = fieldName;
  } else {
    rootFieldName = fieldName;
  }

  if (!targetField) {
    targetField = newForm.fields[rootFieldName];
  }

  targetField[keyName] = value;

  // console.log({
  //   fieldName,
  //   value,
  //   keyName,
  //   nestedFieldParams,
  //   newForm,
  //   oldform,
  // });
  // console.log("");
  // console.log("");
  // console.log("");

  return new Promise((resolve) => {
    this.setState(
      {
        form: newForm,
      },
      async () => {
        await this.debouncedSaveUserFields();

        if (targetField?.triggersFormUpdate) {
          if (this.recalculateDynamicFormFields) {
            await this.recalculateDynamicFormFields();
          }
        }

        resolve();
      }
    );
  });
}

export async function buildReport({ fileId, taskId, users, organisationDetails, includeFileName = true }) {
  const [fileResponse, taskResponse] = await Promise.all([
    callGraphQLSimple({
      message: "Failed to retrieve file details",
      queryCustom: "getFile",
      variables: {
        id: fileId,
      },
    }),
    taskId
      ? callGraphQLSimple({
          message: `Failed to retrieve ${getSimpleLabel("task")} details`,
          queryCustom: "getTaskSimple",
          variables: {
            id: taskId,
          },
        })
      : undefined,
  ]);

  let file = fileResponse.data.getFile;
  const task = taskResponse?.data.getTask;

  const formFileKey = file.versions.items.slice(-1)[0].key.replace("public/", "");
  const formFilePublicUrl = await getS3File(formFileKey);
  let form = (await axios.get(formFilePublicUrl)).data;

  let formBeforeReplacingDynamicFields = JSON.parse(JSON.stringify(form));

  await replaceDynamicFormFields({
    form,
    params: {
      file,
      task,
      taskRevision: task?.revisions.items.find((x) => x.id === file.taskRevisionId),
      project: task?.project,
      users,
      organisationDetails,
    },
  });

  let weNeedToReUploadTheForm = false;

  for (let field in form.fields) {
    if (field.autoSelectFirstOption && field.options && field.options.length > 0) {
      if (!field.value) {
        field.value = field.options[0].value;
        weNeedToReUploadTheForm = true;
      } else {
        let valueIsAmongOptions = field.options.some((option) => option.value === field.value);
        if (!valueIsAmongOptions) {
          field.value = field.options[0].value;
          weNeedToReUploadTheForm = true;
        }
      }
    }
  }

  if (JSON.stringify(form) !== JSON.stringify(formBeforeReplacingDynamicFields)) {
    weNeedToReUploadTheForm = true;
  }

  if (weNeedToReUploadTheForm) {
    await Storage.put(formFileKey, JSON.stringify(form));
  }

  // TODO: re-implement checking for deleted attachments;
  // const attachments = file.reportMetadata?.inserts;
  // const hasAttachments = attachments && attachments.length > 0;

  try {
    // if (hasAttachments) {
    //   const status = await this.checkAttachmentsStatus(attachments);

    //   if (status !== "VALID") {
    //     this.setState({ isDownloading: false });
    //     return;
    //   }
    // }

    let formFieldFileToTakeNameFrom;

    for (let fieldName in form.fields) {
      let fieldData = form.fields[fieldName];
      if (fieldData.takeFileNameFromSelectedOption) {
        if (fieldData.value) {
          formFieldFileToTakeNameFrom = fieldData;
        }
      }
    }

    const buildDocumentResponse = await callRest({
      route: "/build-document",
      method: "POST",
      body: {
        organisationId: file.organisation,
        fileId: file.id,
        taskId: task?.id,
        projectId: task?.projectId,
        clientId: task?.clientId,
      },
      includeCredentials: false,
    });

    if (buildDocumentResponse.errorMessage) {
      throw new Error(`This error originated on the server: ${buildDocumentResponse.errorMessage}`);
    }

    let firstExport;

    let sheet = file.sheets.items[0];
    let sheetRevision = sheet.revisions.items.slice(-1)[0];

    let fileNameOverride;
    let sheetRevisionNameOverride = sheetRevision.name;

    let fileNameIncludingAnyOverrides;

    if (includeFileName) {
      if (formFieldFileToTakeNameFrom) {
        // console.log("formFieldFileToTakeNameFrom = ", formFieldFileToTakeNameFrom);
        if (formFieldFileToTakeNameFrom.value.length === 0) {
          notification.error({
            message: `No file selected: ${formFieldFileToTakeNameFrom.label}`,
          });
          return;
        }

        let targetFileDetails = (
          await window.callGraphQLSimple({
            message: `Failed to retrieve details for ${formFieldFileToTakeNameFrom.label}`,
            queryCustom: "getFile",
            variables: {
              id: formFieldFileToTakeNameFrom.value,
            },
          })
        ).data.getFile;
        fileNameOverride = targetFileDetails.versions.items
          .slice(-1)[0]
          .exports[0].key.split("/")
          .slice(-1)[0]
          .split(".pdf")[0];
        sheetRevisionNameOverride = targetFileDetails.sheets.items[0].revisions.items.slice(-1)[0].name;
        sheet = targetFileDetails.sheets.items[0];
        sheetRevision = sheet.revisions.items.slice(-1)[0];
        file = targetFileDetails;
      }

      if (!HAS_SHEETS[file.type]) {
        let fileVersion =
          file.versions.items.find((x) => x.id === sheetRevision.fileVersionId) || file.versions.items.slice(-1)[-0];
        firstExport = fileVersion.exports[0];
      } else {
        firstExport = sheetRevision.exports[0];
      }

      let latestFileVersion = file.versions.items.slice(-1)[0];

      let customReference = form?.fields?.customTaskId?.value || latestFileVersion?.customId;

      let changedFileName = customReference;
      if (!changedFileName) {
        let originalFileName = fileNameOverride || firstExport.fileName || getFilenameFromKey(firstExport.key);
        changedFileName = await changeFileNameAtDownloadTime({
          organisation: file.organisation,
          fileName: originalFileName,
          sheetRevisionName: sheetRevisionNameOverride,
          sheetRevision,
          file,
          type: KEY_TYPES.FILE_SHEET_EXPORT,
          task,
        });
      }
      fileNameIncludingAnyOverrides = changedFileName;
    }

    return {
      signedUrl: buildDocumentResponse.signedUrl,
      fileName: fileNameIncludingAnyOverrides,
      file,
      task,
      key: firstExport?.key,
    };
  } catch (err) {
    console.log(err);
    notification.error({
      message: (
        <Typography.Text>
          Could not download report:
          <br />
          {err.message}
        </Typography.Text>
      ),
      duration: 0,
    });
    throw err;
  }
}

export async function downloadReport({ fileId, taskId, users, organisationDetails, fileName }) {
  let buildReportResponse = {};
  try {
    buildReportResponse = await buildReport({ fileId, taskId, users, organisationDetails });
  } catch (e) {}
  const { signedUrl, fileName: fileNameFromBuildReportResponse, file, task } = buildReportResponse || {};

  fileName = fileName || fileNameFromBuildReportResponse;

  if (!signedUrl) {
    message.error("Could not download report");
    return;
  }

  const fileBlob = (
    await axios({
      url: signedUrl,
      method: "GET",
      responseType: "blob", // Important
    })
  ).data;

  let extension = file.versions.items.slice(-1)[0].exports[0].key.split(".").slice(-1)[0];
  let fileNameWithExtension = `${fileName}.${extension}`;

  await downloadBlob({
    blob: fileBlob,
    fileName: fileNameWithExtension,
  });
}

export async function computeHiddenFormFields() {
  const { form } = this.state;

  let newHiddenFormFields = {};

  if (!form) {
    return;
  }

  let isObjectHiddenPromises = [];

  for (let fieldName in form.fields) {
    let fieldDetails = form.fields[fieldName];
    if (!fieldDetails.usesConditionalDisplay) {
      continue;
    }

    let fieldDetailsWithParamsForConditionalDisplay = { ...fieldDetails };
    for (let keyName of Object.keys(fieldDetailsWithParamsForConditionalDisplay)) {
      // add "custom" to each key that starts with "conditionalDisplay"
      if (keyName.startsWith("conditionalDisplay")) {
        fieldDetailsWithParamsForConditionalDisplay[`custom_${keyName}`] =
          fieldDetailsWithParamsForConditionalDisplay[keyName];
        delete fieldDetailsWithParamsForConditionalDisplay[keyName];
      }
    }

    isObjectHiddenPromises.push(
      new Promise(async (resolve) => {
        let fieldIsHidden = await isObjectHidden({
          object: fieldDetailsWithParamsForConditionalDisplay,
          params: {
            ...this.props,
            ...this.state,
          },
        });
        resolve({
          fieldDetails,
          fieldIsHidden,
        });
      })
    );
  }

  const results = await Promise.all(isObjectHiddenPromises);

  for (let result of results) {
    let { fieldDetails, fieldIsHidden } = result;
    if (fieldIsHidden) {
      newHiddenFormFields[fieldDetails.id] = true;
    }
  }

  this.setState({
    hiddenFormFields: newHiddenFormFields,
  });
}
