import {
  ImageIcon,
  ImageIconStatic,
  SignatureIcon,
  CircleIcon,
  RectangleIcon,
  TextIcon,
  PageIcon,
  UploadIcon,
  PaperclipIcon,
  PagesIcon,
  ListIcon2,
} from "common/icons";
import cx from "classnames";
import { FolderOpenOutlined, StarOutlined, QrcodeOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

import "./Toolbar.scss";

export const TOOLS = {
  TEXT: "TEXT",
  IMAGE: "IMAGE",
  IMAGE_STATIC: "IMAGE_STATIC",
  SIGNATURE: "SIGNATURE",
  CONTAINER: "CONTAINER",
  DYNAMIC_FILE: "DYNAMIC_FILE",
  ELLIPSE: "ELLIPSE",
  RECTANGLE: "RECTANGLE",
  LINE: "LINE",
  PAGE: "PAGE",
  SECTION: "SECTION",
  COMPONENT: "COMPONENT",
  QR_CODE: "QR_CODE",
  UPLOAD: "UPLOAD",
  CHAPTER: "CHAPTER",
};

const tools = [
  {
    icon: <PagesIcon />,
    name: TOOLS.CHAPTER,
    tooltip: "Insert chapter",
  },

  {
    icon: <PageIcon />,
    name: TOOLS.PAGE,
    tooltip: "Insert page",
  },

  {
    icon: <ListIcon2 />,
    name: TOOLS.SECTION,
    tooltip: "Insert page section",
  },
  {
    separator: true,
  },
  {
    icon: <TextIcon />,
    name: TOOLS.TEXT,
    tooltip: "Insert text box",
  },
  {
    icon: <ImageIcon />,
    name: TOOLS.IMAGE,
    tooltip: "Insert dynamic image",
  },
  {
    icon: <ImageIconStatic />,
    name: TOOLS.IMAGE_STATIC,
    tooltip: "Insert static image",
  },
  {
    icon: <PaperclipIcon />,
    name: TOOLS.DYNAMIC_FILE,
    tooltip: "Insert dynamic file",
  },
  {
    icon: <SignatureIcon />,
    name: TOOLS.SIGNATURE,
    tooltip: "Insert signature",
  },
  {
    icon: <CircleIcon />,
    name: TOOLS.ELLIPSE,
    tooltip: "Insert ellipse",
  },
  {
    icon: <RectangleIcon />,
    name: TOOLS.RECTANGLE,
    tooltip: "Insert rectangle",
  },

  {
    icon: <StarOutlined />,
    name: TOOLS.COMPONENT,
    tooltip: "Insert component",
  },
  {
    icon: <QrcodeOutlined />,
    name: TOOLS.QR_CODE,
    tooltip: "Insert QR code",
  },
  {
    separator: true,
  },
  {
    icon: <UploadIcon />,
    name: TOOLS.UPLOAD,
    tooltip: "Save to the cloud",
  },
];

export default function Toolbar({ activeTool, isPreviewVisible, disabledTools = [], onClick, thereAreUnsavedChanges }) {
  function onToolClick(e, tool) {
    e.preventDefault();

    onClick(tool.name);
  }

  return (
    <div className={cx("toolbar", { "is-preview-visible": isPreviewVisible })}>
      {tools
        .filter((tool) => !disabledTools.includes(tool.name))
        .map((tool) => {
          if (tool.separator) {
            return <div className="toolbar-separator" />;
          }
          return (
            <Tooltip key={tool.name} title={tool.tooltip} placement="right">
              <div
                className={cx("item", `item-${tool.name}`, {
                  active: activeTool === tool.name,
                })}
                onClick={(e) => onToolClick(e, tool)}
              >
                {tool.name === TOOLS.UPLOAD && (
                  <div
                    className={cx("unsaved-changes-indicator", {
                      visible: thereAreUnsavedChanges,
                    })}
                  />
                )}
                {tool.icon}
              </div>
            </Tooltip>
          );
        })}
    </div>
  );
}
