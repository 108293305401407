import { useEffect } from "react";
import { useGetSetState } from "react-use";
import { Table, Input, Form, Modal, Button, Typography, notification, message } from "antd";
import { DeleteOutlined, EditOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { LexoRank } from "lexorank";

import performSheetOperations from "common/performSheetOperations";

import CreateSheetModal from "CreateSheetModal/CreateSheetModalNew";

import "./EditSheetsModal.scss";

type Props = {
  file: any;
  onClose: () => void;
  task: any;
  taskRevision: any;
  apiUser: any;
  user: any;
  organisationDetails: any;
  history: any;
};

interface SheetOperationGraphQL {
  type:
    | "ADD_SHEET"
    | "ADD_SHEETS"
    | "BATCH_EDIT"
    | "REMOVE_SHEET"
    | "REMOVE_SHEETS"
    | "RENAME_SHEET"
    | "ATTACH_EXTERNAL_REFERENCES"
    | "REMOVE_EXTERNAL_REFERENCES";
  sheetName?: string;
  copySheetName?: string;
  oldSheetName?: string;
  newSheetName?: string;
  newSheetDescription?: string;
}

interface SheetOperationLocal extends SheetOperationGraphQL {
  hasBeenApplied?: boolean;
  description?: string;
  id: string;
  order?: string;
}

type State = {
  selectedSheet: any | undefined;
  addSheetModalNewSheetName: string | undefined;
  isCreateSheetModalOpen: boolean;
  isEditSheetNameModalOpen: boolean;
  operations: SheetOperationLocal[];
  updatedSheetList: any[];
  sheetNamesToUseAsABase: string[];
  isOpen: boolean;
};

function generateId() {
  return `${Date.now()}-${Math.floor(Math.random() * 100000)}`;
}

export default function EditSheetsModal({
  file,
  onClose,
  task,
  taskRevision,
  apiUser,
  user,
  organisationDetails,
  history,
}: Props) {
  const [getState, setState] = useGetSetState<State>({
    isOpen: true,
    selectedSheet: undefined,
    addSheetModalNewSheetName: undefined,
    isCreateSheetModalOpen: false,
    isEditSheetNameModalOpen: false,
    operations: [],
    updatedSheetList: [...file.sheets.items],
    sheetNamesToUseAsABase: file.sheetsInApplication
      ? file.sheetsInApplication.map((sheetInApplication) => sheetInApplication.name)
      : [],
  });

  const state = getState();

  let updatedFile = {
    ...file,
    sheets: {
      items: state.updatedSheetList,
    },
  };

  let updatedTask = JSON.parse(JSON.stringify(task));
  let updatedTaskRevision = updatedTask.revisions.items.find((x) => x.id === taskRevision.id);
  if (!updatedTaskRevision) {
    throw new Error("Could not find task revision");
  }

  updatedTaskRevision.files.items = updatedTaskRevision.files.items.map((x) => {
    if (x.id === updatedFile.id) {
      return updatedFile;
    }
    return x;
  });

  // console.log("sheetNamesToUseAsABase = ", sheetNamesToUseAsABase);
  // console.log("updatedSheetList = ", updatedSheetList);

  useEffect(() => {
    const { operations } = getState();
    const latestOperation = operations[operations.length - 1];

    if (!latestOperation) {
      return;
    }

    if (latestOperation.hasBeenApplied) {
      return;
    }

    switch (latestOperation.type) {
      case "REMOVE_SHEET":
        setState({
          updatedSheetList: state.updatedSheetList.filter((sheet) => sheet.name !== latestOperation.sheetName),
          sheetNamesToUseAsABase: state.sheetNamesToUseAsABase.filter(
            (sheetName) => sheetName !== latestOperation.sheetName
          ),
        });

        break;
      case "ADD_SHEET":
        setState({
          updatedSheetList: [
            ...state.updatedSheetList,
            {
              id: generateId(),
              name: latestOperation.newSheetName,
              description: latestOperation.description,
              includeInPublish: true,
              order: latestOperation.order,
            },
          ],
          sheetNamesToUseAsABase: [...state.sheetNamesToUseAsABase, latestOperation.newSheetName as string],
        });

        break;
      case "RENAME_SHEET":
        setState({
          updatedSheetList: state.updatedSheetList.map((sheet) => {
            if (sheet.name === latestOperation.oldSheetName) {
              return {
                ...sheet,
                name: latestOperation.newSheetName,
                description: latestOperation.newSheetDescription,
              };
            }

            return sheet;
          }),
          sheetNamesToUseAsABase: state.sheetNamesToUseAsABase.map((sheetName) => {
            if (sheetName === latestOperation.oldSheetName) {
              return latestOperation.newSheetName as string;
            }

            return sheetName;
          }),
        });

        break;
      default:
        throw new Error(`Unknown operation type: ${latestOperation.type}`);
    }

    setState({
      operations: state.operations.map((operation) => {
        if (operation.id === latestOperation.id) {
          return {
            ...operation,
            hasBeenApplied: true,
          };
        }

        return operation;
      }),
    });
  }, [state.operations]); // eslint-disable-line react-hooks/exhaustive-deps

  async function onSubmit() {
    const { operations } = getState();

    if (!operations?.length) {
      message.info("No changes made");
      onClose();
      return;
    }

    let sheetNameIsReused = false;
    let sheetNames = new Set();
    let sheetDescriptionIsReused = false;
    let sheetDescriptions = new Set();

    for (let sheet of state.updatedSheetList) {
      if (sheetNames.has(sheet.name)) {
        sheetNameIsReused = true;
        break;
      }

      sheetNames.add(sheet.name);
    }

    for (let sheet of state.updatedSheetList) {
      if (sheetDescriptions.has(sheet.description)) {
        sheetDescriptionIsReused = true;
        break;
      }

      sheetDescriptions.add(sheet.description);
    }

    if (sheetNameIsReused) {
      message.error("At least two sheets have the same name. Please make sure each sheet has a unique name.");
      return;
    }

    if (organisationDetails?.settings?.file?.sheetDescriptionsMustBeUnique) {
      if (sheetDescriptionIsReused) {
        message.error("At least two sheets have the same title. Please make sure each sheet has a unique description.");
        return;
      }
    }

    // console.log(JSON.stringify(operations, null, 2));
    const messageKey = "edit-sheets-modal-submit";
    message.loading({
      content: "Submitting...",
      key: messageKey,
      duration: 0,
    });
    setState({
      isOpen: false,
    });

    try {
      await performSheetOperations({
        apiUser,
        task,
        file,
        taskRevision,
        operations,
        history,
        organisationDetails,
        messageKey,
      });
      message.destroy(messageKey);
      setState({
        operations: [],
        updatedSheetList: [...file.sheets.items],
      });

      onClose();
    } catch (e) {
      console.error(e);
      message.error({
        content: "Failed to submit",
        key: messageKey,
      });
    }
  }

  async function onDeleteClick(sheet) {
    Modal.confirm({
      title: `Are you sure to delete sheet ${sheet.name}?`,
      onOk: async () => {
        setState({
          operations: [
            ...state.operations,
            {
              id: generateId(),
              type: "REMOVE_SHEET",
              sheetName: sheet.name,
            },
          ],
        });
      },
    });
  }

  function onEditClick(sheet) {
    setState({
      selectedSheet: sheet,
      isEditSheetNameModalOpen: true,
    });
  }

  async function onEditSheetNameModalSubmit({ name, description }) {
    const { selectedSheet, operations } = getState();
    setState({
      operations: [
        ...operations,
        {
          id: generateId(),
          type: "RENAME_SHEET",
          oldSheetName: selectedSheet.name,
          newSheetName: name.trim(),
          newSheetDescription: description,
        },
      ],
      isEditSheetNameModalOpen: false,
    });
  }

  async function onCreateSheetModalSubmit({ sheetName, copySheetName, description, status }) {
    try {
      const sheets = [...updatedFile.sheets.items].sort((a, b) => (a.order < b.order ? -1 : 1));
      const orderedSheets = sheets;
      let lastOrder = orderedSheets[orderedSheets.length - 1].order;

      const newOrder = lastOrder ? LexoRank.parse(lastOrder).genNext().toString() : LexoRank.middle().toString();

      setState({
        operations: [
          ...state.operations,
          {
            id: generateId(),
            type: "ADD_SHEET",
            copySheetName,
            newSheetName: sheetName.trim(),
            description,
            order: newOrder,
          },
        ],
      });
    } catch (err) {
      notification.error({
        message: "Failed to add sheet",
      });
      console.error("Error adding sheet: ", err);
    }

    setState({
      isCreateSheetModalOpen: false,
    });
  }

  let columns = [
    {
      title: "Sheet name",
      dataIndex: "name",
      key: "name",
    },
    !organisationDetails.settings?.file?.hideSheetTitleInput && {
      title: "Sheet title",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "0.5rem", justifyContent: "center" }}>
          <Button icon={<DeleteOutlined />} onClick={() => onDeleteClick(record)} />
          <Button icon={<EditOutlined />} onClick={() => onEditClick(record)} />
        </div>
      ),
    },
  ].filter((x) => x);

  return (
    <>
      <Modal
        title="Edit sheets"
        open={state.isOpen}
        onOk={onSubmit}
        onCancel={onClose}
        className="edit-sheets-modal"
        maskClosable={false}
      >
        <div style={{ width: "100%", display: "flex", justifyContent: "flex-end" }}></div>
        <div className="inner-modal-content">
          <div className="sheet-list">
            <div className="sheet-list-header">
              <Typography.Text className="section-list-title">Sheets</Typography.Text>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() =>
                  setState({
                    isCreateSheetModalOpen: true,
                    addSheetModalNewSheetName: undefined,
                  })
                }
              >
                Add sheet
              </Button>
            </div>
            <Table
              dataSource={state.updatedSheetList}
              columns={columns as any}
              pagination={{ pageSize: 50, hideOnSinglePage: true }}
            />
            {/* {state.updatedSheetList.map((sheet) => {
              return (
                <div key={sheet.id} className="sheet-item">
                  <Typography.Text className="sheet-name">{sheet.name}</Typography.Text>
                  <Button icon={<DeleteOutlined />} onClick={() => onDeleteClick(sheet)} />
                  <Button icon={<EditOutlined />} onClick={() => onEditClick(sheet)} />
                </div>
              );
            })} */}
          </div>
          {/* <div className="operation-list">
            <Typography.Text className="section-list-title">Operations</Typography.Text>
            {operations.map((operation) => (
              <div key={operation.id} className="operation-item">
                <Typography.Text className="operation-type">{operation.type}</Typography.Text>
                {operation.sheetName && (
                  <Typography.Text className="operation-target">{operation.sheetName}</Typography.Text>
                )}
              </div>
            ))}
          </div> */}
        </div>
      </Modal>
      {state.isCreateSheetModalOpen ? (
        <CreateSheetModal
          onClose={() => {
            setState({
              isCreateSheetModalOpen: false,
            });
          }}
          sheetNamesToUseAsABase={state.sheetNamesToUseAsABase}
          updatedSheetList={state.updatedSheetList}
          defaultNewSheetName={state.addSheetModalNewSheetName}
          // addSheetToApplicationOnly={addSheetToApplicationOnly}
          apiUser={apiUser}
          user={user}
          task={updatedTask}
          file={updatedFile}
          taskRevision={updatedTaskRevision}
          onSubmit={onCreateSheetModalSubmit}
        />
      ) : null}
      {state.isEditSheetNameModalOpen && (
        <Modal
          title="Edit sheet details"
          open
          footer={null}
          maskClosable={false}
          onCancel={() => {
            setState({
              isEditSheetNameModalOpen: false,
              selectedSheet: undefined,
            });
          }}
        >
          <Form
            initialValues={{
              name: state.selectedSheet?.name,
              description: state.selectedSheet?.description,
            }}
            onFinish={onEditSheetNameModalSubmit}
          >
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "You need to specify a sheet name",
                },
                {
                  validator: async (_, value) => {
                    if (value === state.selectedSheet?.name) {
                      return;
                    }

                    if (state.updatedSheetList.some((sheet) => sheet.name === value)) {
                      return Promise.reject("Sheet name is already in use");
                    }
                  },
                },
              ]}
            >
              <Input autoComplete="gibberish" />
            </Form.Item>
            {!organisationDetails.settings?.file?.hideSheetTitleInput && (
              <Form.Item
                label="Title"
                name="description"
                rules={[
                  {
                    validator: async (_, value) => {
                      if (value === state.selectedSheet?.description) {
                        return;
                      }

                      if (organisationDetails?.settings?.file?.sheetDescriptionsMustBeUnique) {
                        if (state.updatedSheetList.some((sheet) => sheet.description === value)) {
                          return Promise.reject("Sheet title is already in use");
                        }
                      }
                    },
                  },
                ]}
              >
                <Input autoComplete="gibberish" />
              </Form.Item>
            )}
            <Form.Item wrapperCol={{ offset: 10, span: 16 }}>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </>
  );
}
