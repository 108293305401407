import React from "react";
import { Button, Typography, Modal } from "antd";
import { EditOutlined, DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";

import withSubscriptions from "common/withSubscriptions";
import { updateOrganisation } from "graphql/mutations";
import { CUSTOM_FIELD_TYPES } from "common/constants";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";
import CreateCustomFieldModal from "Modals/CreateCustomFieldModal/CreateCustomFieldModal";

import "./CustomFieldList.scss";

export class CustomFieldList extends React.Component {
  state = {
    isCustomFieldModalVisible: false,
    selectedCustomField: null,
  };

  confirmDeleteCustomTaskState = (field) => {
    const { organisationDetails } = this.props;

    Modal.confirm({
      title: "Confirm custom field delete",
      className: "delete-custom-field-modal",
      content: (
        <>
          Are you sure you want to delete custom field <b>{field.label}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQL(
          "Could not delete custom field",
          graphqlOperation(updateOrganisation, {
            input: {
              id: organisationDetails.id,
              customFields: (organisationDetails.customFields || []).filter((crtField) => crtField.id !== field.id),
            },
          })
        );
      },
    });
  };

  render() {
    const { isCustomFieldModalVisible, selectedCustomField } = this.state;
    const { organisationDetails } = this.props;

    return (
      <Card
        withSpace
        title={`Custom ${getSimpleLabel("task")} fields`}
        className="custom-field-list"
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            data-cy="create-custom-field-button"
            onClick={() => this.setState({ isCustomFieldModalVisible: true })}
          >
            Add custom field
          </Button>
        }
      >
        {(organisationDetails.customFields || [])
          .filter((x) => !x.isStatic)
          .map((field) => {
            return (
              <div className="custom-field" key={field.id} data-cy="custom-field-item">
                <Typography.Text className="type" data-cy="custom-field-type">
                  {CUSTOM_FIELD_TYPES.find((x) => x.value === field.type).label}
                </Typography.Text>
                <Typography.Text className="name" data-cy="custom-field-name">
                  {field.label}
                </Typography.Text>
                <div className="custom-field-actions-wrapper">
                  <Button
                    icon={<EditOutlined />}
                    className="edit-custom-field"
                    data-cy="edit-custom-field-button"
                    onClick={() =>
                      this.setState({
                        selectedCustomField: field,
                        isCustomFieldModalVisible: true,
                      })
                    }
                  />
                  <Button
                    icon={<DeleteOutlined />}
                    className="delete-custom-field"
                    data-cy="delete-custom-field-button"
                    onClick={() => this.confirmDeleteCustomTaskState(field)}
                  />
                </div>
              </div>
            );
          })}
        {isCustomFieldModalVisible && (
          <CreateCustomFieldModal
            organisationDetails={organisationDetails}
            target="TASK"
            onClose={() =>
              this.setState({
                isCustomFieldModalVisible: false,
                selectedCustomField: null,
              })
            }
            customField={selectedCustomField}
          />
        )}
      </Card>
    );
  }
}

export default withSubscriptions({
  Component: CustomFieldList,
  subscriptions: ["organisationDetails"],
});
