/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateOrganisation = /* GraphQL */ `
  subscription OnCreateOrganisation($id: String) {
    onCreateOrganisation(id: $id) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateOrganisation = /* GraphQL */ `
  subscription OnUpdateOrganisation($id: String) {
    onUpdateOrganisation(id: $id) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteOrganisation = /* GraphQL */ `
  subscription OnDeleteOrganisation($id: String) {
    onDeleteOrganisation(id: $id) {
      id
      logoKey
      name
      description
      projectCount
      requestCount
      projectIdOffset
      hasAutoPublish
      slackWebhookURL
      usesFiles
      usesAuthority
      usesDesignAuthority
      usesCheckAuthority
      usesReviewAuthority
      usesInitials
      usesClientInitials
      usesProjectInitials
      usesTaskInitials
      usesRevisions
      usesReviews
      usesSprints
      usesDelete
      billingDay
      pricePerSeat
      taxRate
      accountBalance
      monthlyCost
      discountPerSeat
      lastBillCreatedAt
      seats
      deploymentAlertNumber
      itemSubscription
      settings {
        review {
          excludedFileTypes
          defaultCommentFontSize
        }
        quote {
          isValidFromHidden
          isValidUntilHidden
          hiddenLineItemColumns
          hiddenLineItemColumnsByTemplate {
            templateId
            hiddenLineItemColumns
          }
          shouldArchiveTasksWhenClosingQuote
          shouldOpenCreateTaskModal
          defaultQuoteBccEmails
          usesQuotes
          usesDelete
          tasksCreatedFromQuoteDefaultToQuoteTitle
          tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
          defaultUnitPrice
          disablePreview
          fieldsToCopyFromFirstQuoteLineItemByTemplate {
            templateId
            fieldsToCopyFromFirstQuoteLineItem
          }
          emailAddressToSendQuotesFrom
          quotesInHours
        }
        task {
          createsCat2Check
          defaultSprint
          attachmentTemplates {
            id
            name
            path
          }
          taskRevisionsAreSyncedWithSheetRevisions
          allowUseOfCatZero
          usesTaskEstimates
          useTaskRevisionEstimates
          useDueDatesOnTaskRevisions
          usesPriority
          usesRequestedDate
          displayTaskEstimatesAsTag
          createAttachmentFolderWithTask
          dontCreateTaskFolders
          defaultTaskCatLevel
          isTaskAssigneeMandatory
          allowUnassignedAsMandatoryException
          isTaskDueDateMandatory
          priorities {
            id
            name
            color
          }
          maxTaskRevisionNameLength
          maxTaskRevisionDescriptionLength
          needsRequestForTaskRevisionCreation
          needsRequestForTaskRevisionReview
          automaticallyCreateProject
          hideCustomTaskNumberAtCreationTime
          hideCopyTaskAtCreationTime
          hideTaskDueDates
          taskIdToCopyByDefault
          cannotCreateNewTaskRevisions
          allowMultipleLiveTaskRevisions
          copyTaskAlsoCopiesAllTaskRevisions
          usesStartAndEndDates
          allowMultipleUsersToBeAssignedToATask
          allowMultipleStockItemsToBeAssignedToATask
          hideRawCloudStorageTab
        }
        file {
          hideIncludeInPublishSwitch
          hideSheetTitleInput
          hideCustomReference
          hideIncludeInDocumentRegister
          hideAnnotateSwitch
          hideXref
          maxSheetRevisionDescriptionLength
          maxSheetRevisionNameLength
          sheetDescriptionsMustBeUnique
        }
        general {
          usesDocumentRegister
          usesTaskConfirmation
          usesTeams
          usesTimeOff
          usesUploadFromGoogleDrive
          usesUploadFromOneDrive
          usesUploadFromDropbox
          sectors {
            id
            label
          }
          geographicalLocations {
            id
            label
          }
          hideFinancials
          hideOrganisationIdInTags
          requirePermissionToDisplayUsers
          defaultAddressToSendEmailsFrom
          scheduledNotifications {
            id
            name
            title
            body
            targetUserGroups
            schedule
          }
        }
        timesheet {
          usesTimesheets
          areTagsMandatory
          isDescriptionMandatory
          requireQuoteOrVariationForBillableTimesheetBlocks
          canAssignTimesheetBlocksToTaskRevisions
          usesReview
          recordLatLongWhenClockingInOut
          recordLatLongEveryXMinutes
          usesPartDayTimeOff
        }
        timeline {
          usesTimeline
          usesPhysicalBlockInteraction
          usesGapFilling
          usesColoredBlocks
          usesCustomColorLogic
          defaultRoundToHours
          defaultTaskLengthHours
          shouldAssignTimelineTaskToUser
          shouldDisplayOwnUserAtTheTop
          planProjectsInsteadOfTasks
          planTaskRevisionsInsteadOfTasks
          defaultTaskLengthToEstimatedHours
          pseudoTaskOptions {
            id
            defaultColor
          }
        }
        calendar {
          usesCalendar
        }
        documentLibrary {
          usesDocumentLibrary
        }
        queue {
          usesQueue
        }
        dashboard {
          displayViewTypePicker
        }
        invoice {
          usesInvoices
          usesDoubleInvoiceReview
          usesPercentagesInsteadOfQuantity
          invoiceDate
          useCancelledStatus
          displayPaidInvoicesInSeparateColumns
          emailAddressToSendInvoicesFrom
          defaultExpectPaymentAfterDays
        }
        purchaseOrder {
          usesPurchaseOrders
          emailAddressToSendPurchaseOrdersFrom
        }
        supplier {
          usesSuppliers
        }
        project {
          attachmentTemplates {
            id
            name
            path
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          hideCustomProjectNumberAtCreationTime
          hideAssignedToAtCreationTime
          hideClientAtCreationTime
          customNumberCheckboxOnByDefault
        }
        client {
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
        }
        request {
          usesRequests
          emailsToNotifyOnNewRequest
          usesClientReview
          displayRequestFormInTaskReview
          allowRequestFormPdfDownload
          allowExternalReviews
        }
        holiday {
          onlyAllowsFullDays
        }
        stock {
          usesStock
        }
      }
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      vatNumber
      fileTypesUsed
      financialControllers
      addons {
        pricePerSeat
        name
        discountPercent
      }
      labels {
        id
        value
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      teams {
        id
        label
      }
      fileStatuses {
        name
      }
      taskStatuses {
        name
        type
      }
      customFields {
        id
        label
        target
        type
        options {
          value
          label
          color
          showAsTag
          tagLabel
        }
        defaultValue
        showAsTag
        displayTagIfChecked
        displayTagIfEmpty
        onlyVisibleForCertainUserGroups
        groupsThatCanSee
        tagLabel
        color
        formula
        isStatic
        mandatory
        nonAdminsCanRead
        nonAdminsCanWrite
      }
      integrations {
        id
        authorisedAt
      }
      accountancyOrganisationId
      includeInBilling
      financialsLastRecalculatedAt
      financialsLastStartedRecalculatingAt
      financialsRecalculationProgress
      financialsRecalculationStatus
      createdAt
      updatedAt
      variables {
        items {
          id
          organisation
          name
          type
          value
          createdAt
          updatedAt
        }
        nextToken
      }
      templates {
        items {
          id
          organisation
          name
          type
          createdAt
          currentVersionNumber
          previewTaskId
          previewFileId
          previewTaskRevisionId
          isLive
          isDeprecated
          isDefault
          key
          reviewCommentFontSize
          excludeFromRegisterByDefault
          isCat2Restricted
          outputType
          systemVersion
          parentType
          updatedAt
        }
        nextToken
      }
      bills {
        items {
          id
          customId
          organisation
          createdAt
          isPaid
          subtotal
          totalWithTax
          taxAmount
          lineItems {
            id
            name
            quantity
            pricePerUnit
            amount
            amountWithTax
            discountPercent
            discountAmount
          }
          s3Key
          itemSubscription
          accountancyInvoiceId
          updatedAt
        }
        nextToken
      }
      timesheetTags {
        items {
          id
          organisation
          label
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateVariable = /* GraphQL */ `
  subscription OnCreateVariable($organisation: String) {
    onCreateVariable(organisation: $organisation) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVariable = /* GraphQL */ `
  subscription OnUpdateVariable($organisation: String) {
    onUpdateVariable(organisation: $organisation) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVariable = /* GraphQL */ `
  subscription OnDeleteVariable($organisation: String) {
    onDeleteVariable(organisation: $organisation) {
      id
      organisation
      name
      type
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateGroup = /* GraphQL */ `
  subscription OnCreateGroup($organisation: String) {
    onCreateGroup(organisation: $organisation) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateGroup = /* GraphQL */ `
  subscription OnUpdateGroup($organisation: String) {
    onUpdateGroup(organisation: $organisation) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteGroup = /* GraphQL */ `
  subscription OnDeleteGroup($organisation: String) {
    onDeleteGroup(organisation: $organisation) {
      id
      name
      permissions
      members
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemplate = /* GraphQL */ `
  subscription OnCreateTemplate($organisation: String) {
    onCreateTemplate(organisation: $organisation) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const onUpdateTemplate = /* GraphQL */ `
  subscription OnUpdateTemplate($organisation: String) {
    onUpdateTemplate(organisation: $organisation) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const onDeleteTemplate = /* GraphQL */ `
  subscription OnDeleteTemplate($organisation: String) {
    onDeleteTemplate(organisation: $organisation) {
      id
      organisation
      name
      type
      createdAt
      currentVersionNumber
      previewTaskId
      previewFileId
      previewTaskRevisionId
      isLive
      isDeprecated
      isDefault
      key
      reviewCommentFontSize
      excludeFromRegisterByDefault
      isCat2Restricted
      outputType
      systemVersion
      parentType
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($organisation: String) {
    onCreateUser(organisation: $organisation) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($organisation: String) {
    onUpdateUser(organisation: $organisation) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($organisation: String) {
    onDeleteUser(organisation: $organisation) {
      id
      isAdmin
      isDisabled
      isLoginDisabled
      disabledAt
      cognitoUsername
      organisation
      isHidden
      activated
      firstName
      lastName
      avatarKey
      catLevelDesign
      catLevelCheck
      catLevelIssue
      quoteCreationLimit
      quoteReviewLimit
      invoiceCreationLimit
      invoiceReviewLimit
      purchaseOrderCreationLimit
      purchaseOrderReviewLimit
      position
      qualifications
      signature
      signatureWidth
      signatureHeight
      createdAt
      hasAgreedToCookies
      hasAgreedToTerms
      notificationSettings {
        webSystemNotifications
        webSystemReviewsRequest
        webSystemReviewsResult
        webAppReviewsRequest
        webAppReviewsResult
        webAppTaskAssigned
        webAppPublicUpload
        webAppMention
        webAppRequestActivity
        webAppQuoteAcceptedRejected
        webAppTimeOffRequest
        webAppStockMaintenance
        emailReviewsRequest
        emailReviewsResult
        emailTaskAssigned
        emailPublicUpload
        emailMention
        emailRequestActivity
        emailQuoteAcceptedRejected
        emailTimeOffRequest
        emailStockMaintenance
        mobileAppReviewsRequest
        mobileAppReviewsResult
        mobileMention
      }
      order
      computers {
        id
        name
        cloudWatchLogStreamName
        linkIsInstalled
        linkIsRunning
      }
      lastActiveComputer
      lastLoginAt
      feeRoles
      defaultFeeRole
      phone1
      phone2
      office
      team
      teams
      department
      featureFlags {
        autoBackup
        serverPoweredReportPublish
        serverPoweredQuotePublish
        serverPoweredInvoicePublish
        batchEditSheets
      }
      workingHours {
        id
        repeatPattern
        startTime
        endTime
        intervalId
      }
      permissions
      allowanceHolidayDays
      allowances {
        year
        allowance
        type
        intervalId
      }
      lastOnlineAt
      hasPrioritySignature
      managerIds
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification($userId: String) {
    onCreateNotification(userId: $userId) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification($userId: String) {
    onUpdateNotification(userId: $userId) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification($userId: String) {
    onDeleteNotification(userId: $userId) {
      id
      userId
      readAt
      createdAt
      displayedAt
      message
      type
      link
      author
      target
      organisation
      updatedAt
    }
  }
`;
export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($organisation: String) {
    onCreateClient(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($organisation: String) {
    onUpdateClient(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($organisation: String) {
    onDeleteClient(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      isPriority
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      fees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      customFields {
        id
        value
      }
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      defaultExpectPaymentAfterDays
      sharedWithGroups
      sharedWithUsers
      updatedAt
      projects {
        items {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateSupplier = /* GraphQL */ `
  subscription OnCreateSupplier($organisation: String) {
    onCreateSupplier(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const onUpdateSupplier = /* GraphQL */ `
  subscription OnUpdateSupplier($organisation: String) {
    onUpdateSupplier(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const onDeleteSupplier = /* GraphQL */ `
  subscription OnDeleteSupplier($organisation: String) {
    onDeleteSupplier(organisation: $organisation) {
      id
      key
      name
      organisation
      notes
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      addresses {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      contacts {
        id
        firstName
        lastName
        email
        phone
        phone2
        position
        notes
      }
      defaultAddressId
      defaultContactId
      companyNumber
      vatNumber
      updatedAt
    }
  }
`;
export const onCreateSprint = /* GraphQL */ `
  subscription OnCreateSprint($organisation: String) {
    onCreateSprint(organisation: $organisation) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSprint = /* GraphQL */ `
  subscription OnUpdateSprint($organisation: String) {
    onUpdateSprint(organisation: $organisation) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSprint = /* GraphQL */ `
  subscription OnDeleteSprint($organisation: String) {
    onDeleteSprint(organisation: $organisation) {
      id
      name
      goal
      startDate
      endDate
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      lastTaskOrder
      isActive
      isFinished
      createdAt
      startedAt
      finishedAt
      updatedAt
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateBill = /* GraphQL */ `
  subscription OnCreateBill($organisation: String) {
    onCreateBill(organisation: $organisation) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const onUpdateBill = /* GraphQL */ `
  subscription OnUpdateBill($organisation: String) {
    onUpdateBill(organisation: $organisation) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const onDeleteBill = /* GraphQL */ `
  subscription OnDeleteBill($organisation: String) {
    onDeleteBill(organisation: $organisation) {
      id
      customId
      organisation
      createdAt
      isPaid
      subtotal
      totalWithTax
      taxAmount
      lineItems {
        id
        name
        quantity
        pricePerUnit
        amount
        amountWithTax
        discountPercent
        discountAmount
      }
      s3Key
      itemSubscription
      accountancyInvoiceId
      updatedAt
    }
  }
`;
export const onCreateTimesheetTag = /* GraphQL */ `
  subscription OnCreateTimesheetTag($organisation: String) {
    onCreateTimesheetTag(organisation: $organisation) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTimesheetTag = /* GraphQL */ `
  subscription OnUpdateTimesheetTag($organisation: String) {
    onUpdateTimesheetTag(organisation: $organisation) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTimesheetTag = /* GraphQL */ `
  subscription OnDeleteTimesheetTag($organisation: String) {
    onDeleteTimesheetTag(organisation: $organisation) {
      id
      organisation
      label
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTimelineBlock = /* GraphQL */ `
  subscription OnCreateTimelineBlock($organisation: String) {
    onCreateTimelineBlock(organisation: $organisation) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const onUpdateTimelineBlock = /* GraphQL */ `
  subscription OnUpdateTimelineBlock($organisation: String) {
    onUpdateTimelineBlock(organisation: $organisation) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const onDeleteTimelineBlock = /* GraphQL */ `
  subscription OnDeleteTimelineBlock($organisation: String) {
    onDeleteTimelineBlock(organisation: $organisation) {
      id
      startDate
      endDate
      startHours
      durationHours
      taskId
      taskRevisionId
      userId
      organisation
      isPseudoTask
      createdAt
      repeatPattern
      userChosenColor
      isFixed
      updatedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($organisation: String) {
    onCreateProject(organisation: $organisation) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($organisation: String) {
    onUpdateProject(organisation: $organisation) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($organisation: String) {
    onDeleteProject(organisation: $organisation) {
      id
      organisation
      randomNumber
      itemSubscription
      collectionSubscription
      author
      title
      description
      clientId
      assignedTo
      initials
      taskCount
      quoteCount
      invoiceCount
      purchaseOrderCount
      createdAt
      isArchived
      isFinished
      archivedAt
      finishedAt
      adminFiles {
        id
        name
        taskId
      }
      clientContacts
      address {
        id
        streetName
        streetNumber
        houseName
        postcode
        county
        city
        country
      }
      addressCoordinates {
        lat
        lng
      }
      poNumber
      customFields {
        id
        value
      }
      team
      amountBudget
      amountSpent
      amountInvoiced
      amountReceived
      amountOutstanding
      hoursBudget
      hoursSpent
      sharedWithGroups
      sharedWithUsers
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      tasks {
        items {
          id
          isReadOnly
          isHidden
          isConfirmed
          linkedTasks {
            id
            correspondingId
            taskId
            relationship
            label
          }
          quoteIds
          quoteLineItemIds
          initials
          randomNumber
          itemSubscription
          collectionSubscription
          catLevel
          organisation
          projectId
          sprintId
          clientId
          author
          title
          titleLowerCase
          assignedTo
          assignedToUsers
          assignedToStockItems
          subtitle
          dueDate
          startDate
          endDate
          estimatedHours
          budget
          checkPrice
          isArchived
          isFinished
          finishedAt
          archivedAt
          isUnderReview
          reviewStatus
          reviewSecondaryStatus
          status
          isExternalReference
          customFields {
            id
            value
          }
          order
          createdAt
          subtaskProgress
          checkedBy
          amountBudget
          amountSpent
          hoursBudget
          hoursSpent
          clientContact
          clientContacts
          clientAddress
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          team
          requestIds
          sharedWithGroups
          sharedWithUsers
          updatedAt
          organisationDetails {
            id
            logoKey
            name
            description
            projectCount
            requestCount
            projectIdOffset
            hasAutoPublish
            slackWebhookURL
            usesFiles
            usesAuthority
            usesDesignAuthority
            usesCheckAuthority
            usesReviewAuthority
            usesInitials
            usesClientInitials
            usesProjectInitials
            usesTaskInitials
            usesRevisions
            usesReviews
            usesSprints
            usesDelete
            billingDay
            pricePerSeat
            taxRate
            accountBalance
            monthlyCost
            discountPerSeat
            lastBillCreatedAt
            seats
            deploymentAlertNumber
            itemSubscription
            vatNumber
            fileTypesUsed
            financialControllers
            accountancyOrganisationId
            includeInBilling
            financialsLastRecalculatedAt
            financialsLastStartedRecalculatingAt
            financialsRecalculationProgress
            financialsRecalculationStatus
            createdAt
            updatedAt
          }
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          subtasks {
            nextToken
          }
          activity {
            nextToken
          }
          quoteLineItems {
            nextToken
          }
          revisions {
            nextToken
          }
          auditItems {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          parentId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quotes {
        items {
          id
          organisation
          currentRevisionName
          isArchived
          assignedTo
          author
          clientId
          projectId
          fileKey
          validFrom
          validUntil
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          rejectionDetails {
            type
            notes
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          isTenderWin
          manuallyInvoicedAmount
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          requestIds
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      invoices {
        items {
          id
          organisation
          isArchived
          dueDate
          assignedTo
          invoiceDate
          author
          clientId
          projectId
          fileKey
          reference
          title
          description
          currency
          taxInclusive
          taxRate
          subtotal
          totalTax
          total
          createdAt
          termsText
          termsAttachment
          status
          randomNumber
          itemSubscription
          collectionSubscription
          clientContact
          clientContacts
          clientAddress
          templateId
          templateVersionNumber
          savedAt
          metadata {
            pageNumbersToSkipBorders
          }
          poNumber
          checkedBy
          isUnderReview
          reviewApprovedAt
          secondReviewApprovedAt
          reviewStatus
          reviewSecondaryStatus
          contactIdsToIncludeInCC
          contactIdsToIncludeInBCC
          extraEmailsToIncludeInCC {
            id
            email
          }
          extraEmailsToIncludeInBCC {
            id
            email
          }
          lastUpdateAuthorId
          accountancyInvoiceId
          amountPaid
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          customFields {
            id
            value
          }
          team
          expectPaymentAfterDays
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          project {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          activity {
            nextToken
          }
          lineItems {
            nextToken
          }
          reviews {
            nextToken
          }
          timesheetBlocks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateBoard = /* GraphQL */ `
  subscription OnCreateBoard($organisation: String) {
    onCreateBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateBoard = /* GraphQL */ `
  subscription OnUpdateBoard($organisation: String) {
    onUpdateBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteBoard = /* GraphQL */ `
  subscription OnDeleteBoard($organisation: String) {
    onDeleteBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          id
          taskId
          boardId
          order
          organisation
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateBoardTask = /* GraphQL */ `
  subscription OnCreateBoardTask($organisation: String) {
    onCreateBoardTask(organisation: $organisation) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateBoardTask = /* GraphQL */ `
  subscription OnUpdateBoardTask($organisation: String) {
    onUpdateBoardTask(organisation: $organisation) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteBoardTask = /* GraphQL */ `
  subscription OnDeleteBoardTask($organisation: String) {
    onDeleteBoardTask(organisation: $organisation) {
      id
      taskId
      boardId
      order
      organisation
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($organisation: String) {
    onCreateTask(organisation: $organisation) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($organisation: String) {
    onUpdateTask(organisation: $organisation) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($organisation: String) {
    onDeleteTask(organisation: $organisation) {
      id
      isReadOnly
      isHidden
      isConfirmed
      linkedTasks {
        id
        correspondingId
        taskId
        relationship
        label
      }
      quoteIds
      quoteLineItemIds
      initials
      randomNumber
      itemSubscription
      collectionSubscription
      catLevel
      organisation
      projectId
      sprintId
      clientId
      author
      title
      titleLowerCase
      assignedTo
      assignedToUsers
      assignedToStockItems
      subtitle
      dueDate
      startDate
      endDate
      estimatedHours
      budget
      checkPrice
      isArchived
      isFinished
      finishedAt
      archivedAt
      isUnderReview
      reviewStatus
      reviewSecondaryStatus
      status
      isExternalReference
      customFields {
        id
        value
      }
      order
      createdAt
      subtaskProgress
      checkedBy
      amountBudget
      amountSpent
      hoursBudget
      hoursSpent
      clientContact
      clientContacts
      clientAddress
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      team
      requestIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
      organisationDetails {
        id
        logoKey
        name
        description
        projectCount
        requestCount
        projectIdOffset
        hasAutoPublish
        slackWebhookURL
        usesFiles
        usesAuthority
        usesDesignAuthority
        usesCheckAuthority
        usesReviewAuthority
        usesInitials
        usesClientInitials
        usesProjectInitials
        usesTaskInitials
        usesRevisions
        usesReviews
        usesSprints
        usesDelete
        billingDay
        pricePerSeat
        taxRate
        accountBalance
        monthlyCost
        discountPerSeat
        lastBillCreatedAt
        seats
        deploymentAlertNumber
        itemSubscription
        settings {
          review {
            excludedFileTypes
            defaultCommentFontSize
          }
          quote {
            isValidFromHidden
            isValidUntilHidden
            hiddenLineItemColumns
            shouldArchiveTasksWhenClosingQuote
            shouldOpenCreateTaskModal
            defaultQuoteBccEmails
            usesQuotes
            usesDelete
            tasksCreatedFromQuoteDefaultToQuoteTitle
            tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
            defaultUnitPrice
            disablePreview
            emailAddressToSendQuotesFrom
            quotesInHours
          }
          task {
            createsCat2Check
            defaultSprint
            taskRevisionsAreSyncedWithSheetRevisions
            allowUseOfCatZero
            usesTaskEstimates
            useTaskRevisionEstimates
            useDueDatesOnTaskRevisions
            usesPriority
            usesRequestedDate
            displayTaskEstimatesAsTag
            createAttachmentFolderWithTask
            dontCreateTaskFolders
            defaultTaskCatLevel
            isTaskAssigneeMandatory
            allowUnassignedAsMandatoryException
            isTaskDueDateMandatory
            maxTaskRevisionNameLength
            maxTaskRevisionDescriptionLength
            needsRequestForTaskRevisionCreation
            needsRequestForTaskRevisionReview
            automaticallyCreateProject
            hideCustomTaskNumberAtCreationTime
            hideCopyTaskAtCreationTime
            hideTaskDueDates
            taskIdToCopyByDefault
            cannotCreateNewTaskRevisions
            allowMultipleLiveTaskRevisions
            copyTaskAlsoCopiesAllTaskRevisions
            usesStartAndEndDates
            allowMultipleUsersToBeAssignedToATask
            allowMultipleStockItemsToBeAssignedToATask
            hideRawCloudStorageTab
          }
          file {
            hideIncludeInPublishSwitch
            hideSheetTitleInput
            hideCustomReference
            hideIncludeInDocumentRegister
            hideAnnotateSwitch
            hideXref
            maxSheetRevisionDescriptionLength
            maxSheetRevisionNameLength
            sheetDescriptionsMustBeUnique
          }
          general {
            usesDocumentRegister
            usesTaskConfirmation
            usesTeams
            usesTimeOff
            usesUploadFromGoogleDrive
            usesUploadFromOneDrive
            usesUploadFromDropbox
            hideFinancials
            hideOrganisationIdInTags
            requirePermissionToDisplayUsers
            defaultAddressToSendEmailsFrom
          }
          timesheet {
            usesTimesheets
            areTagsMandatory
            isDescriptionMandatory
            requireQuoteOrVariationForBillableTimesheetBlocks
            canAssignTimesheetBlocksToTaskRevisions
            usesReview
            recordLatLongWhenClockingInOut
            recordLatLongEveryXMinutes
            usesPartDayTimeOff
          }
          timeline {
            usesTimeline
            usesPhysicalBlockInteraction
            usesGapFilling
            usesColoredBlocks
            usesCustomColorLogic
            defaultRoundToHours
            defaultTaskLengthHours
            shouldAssignTimelineTaskToUser
            shouldDisplayOwnUserAtTheTop
            planProjectsInsteadOfTasks
            planTaskRevisionsInsteadOfTasks
            defaultTaskLengthToEstimatedHours
          }
          calendar {
            usesCalendar
          }
          documentLibrary {
            usesDocumentLibrary
          }
          queue {
            usesQueue
          }
          dashboard {
            displayViewTypePicker
          }
          invoice {
            usesInvoices
            usesDoubleInvoiceReview
            usesPercentagesInsteadOfQuantity
            invoiceDate
            useCancelledStatus
            displayPaidInvoicesInSeparateColumns
            emailAddressToSendInvoicesFrom
            defaultExpectPaymentAfterDays
          }
          purchaseOrder {
            usesPurchaseOrders
            emailAddressToSendPurchaseOrdersFrom
          }
          supplier {
            usesSuppliers
          }
          project {
            hideCustomProjectNumberAtCreationTime
            hideAssignedToAtCreationTime
            hideClientAtCreationTime
            customNumberCheckboxOnByDefault
          }
          client {
          }
          request {
            usesRequests
            emailsToNotifyOnNewRequest
            usesClientReview
            displayRequestFormInTaskReview
            allowRequestFormPdfDownload
            allowExternalReviews
          }
          holiday {
            onlyAllowsFullDays
          }
          stock {
            usesStock
          }
        }
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        vatNumber
        fileTypesUsed
        financialControllers
        addons {
          pricePerSeat
          name
          discountPercent
        }
        labels {
          id
          value
        }
        defaultFees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        teams {
          id
          label
        }
        fileStatuses {
          name
        }
        taskStatuses {
          name
          type
        }
        customFields {
          id
          label
          target
          type
          options {
            value
            label
            color
            showAsTag
            tagLabel
          }
          defaultValue
          showAsTag
          displayTagIfChecked
          displayTagIfEmpty
          onlyVisibleForCertainUserGroups
          groupsThatCanSee
          tagLabel
          color
          formula
          isStatic
          mandatory
          nonAdminsCanRead
          nonAdminsCanWrite
        }
        integrations {
          id
          authorisedAt
        }
        accountancyOrganisationId
        includeInBilling
        financialsLastRecalculatedAt
        financialsLastStartedRecalculatingAt
        financialsRecalculationProgress
        financialsRecalculationStatus
        createdAt
        updatedAt
        variables {
          items {
            id
            organisation
            name
            type
            value
            createdAt
            updatedAt
          }
          nextToken
        }
        templates {
          items {
            id
            organisation
            name
            type
            createdAt
            currentVersionNumber
            previewTaskId
            previewFileId
            previewTaskRevisionId
            isLive
            isDeprecated
            isDefault
            key
            reviewCommentFontSize
            excludeFromRegisterByDefault
            isCat2Restricted
            outputType
            systemVersion
            parentType
            updatedAt
          }
          nextToken
        }
        bills {
          items {
            id
            customId
            organisation
            createdAt
            isPaid
            subtotal
            totalWithTax
            taxAmount
            s3Key
            itemSubscription
            accountancyInvoiceId
            updatedAt
          }
          nextToken
        }
        timesheetTags {
          items {
            id
            organisation
            label
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
      activity {
        items {
          id
          taskId
          author
          content
          type
          organisation
          createdAt
          updatedAt
        }
        nextToken
      }
      quoteLineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      revisions {
        items {
          id
          taskId
          name
          isReadOnly
          organisation
          isArchived
          status
          dueDate
          requestedDate
          estimatedHours
          priorityId
          requestIds
          base
          randomNumber
          itemSubscription
          collectionSubscription
          description
          author
          checkedBy
          reviewId
          reviewAcceptDate
          reviewStatus
          reviewSecondaryStatus
          deletedFilesByType
          deletedFilesByTemplate
          readAccessGroups
          writeAccessGroups
          createdAt
          updatedAt
          task {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          files {
            nextToken
          }
          review {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            parentId
            createdAt
            updatedAt
          }
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateSubtask = /* GraphQL */ `
  subscription OnCreateSubtask($organisation: String) {
    onCreateSubtask(organisation: $organisation) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSubtask = /* GraphQL */ `
  subscription OnUpdateSubtask($organisation: String) {
    onUpdateSubtask(organisation: $organisation) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSubtask = /* GraphQL */ `
  subscription OnDeleteSubtask($organisation: String) {
    onDeleteSubtask(organisation: $organisation) {
      id
      parentId
      order
      assignedTo
      title
      description
      isFinished
      isArchived
      author
      dueDate
      organisation
      createdAt
      updatedAt
      subtasks {
        items {
          id
          parentId
          order
          assignedTo
          title
          description
          isFinished
          isArchived
          author
          dueDate
          organisation
          createdAt
          updatedAt
          subtasks {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateTaskActivityItem = /* GraphQL */ `
  subscription OnCreateTaskActivityItem($organisation: String) {
    onCreateTaskActivityItem(organisation: $organisation) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTaskActivityItem = /* GraphQL */ `
  subscription OnUpdateTaskActivityItem($organisation: String) {
    onUpdateTaskActivityItem(organisation: $organisation) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTaskActivityItem = /* GraphQL */ `
  subscription OnDeleteTaskActivityItem($organisation: String) {
    onDeleteTaskActivityItem(organisation: $organisation) {
      id
      taskId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onCreateActivityItem = /* GraphQL */ `
  subscription OnCreateActivityItem($organisation: String) {
    onCreateActivityItem(organisation: $organisation) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateActivityItem = /* GraphQL */ `
  subscription OnUpdateActivityItem($organisation: String) {
    onUpdateActivityItem(organisation: $organisation) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteActivityItem = /* GraphQL */ `
  subscription OnDeleteActivityItem($organisation: String) {
    onDeleteActivityItem(organisation: $organisation) {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onCreatePurchaseOrder = /* GraphQL */ `
  subscription OnCreatePurchaseOrder($organisation: String) {
    onCreatePurchaseOrder(organisation: $organisation) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdatePurchaseOrder = /* GraphQL */ `
  subscription OnUpdatePurchaseOrder($organisation: String) {
    onUpdatePurchaseOrder(organisation: $organisation) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeletePurchaseOrder = /* GraphQL */ `
  subscription OnDeletePurchaseOrder($organisation: String) {
    onDeletePurchaseOrder(organisation: $organisation) {
      id
      organisation
      isArchived
      assignedTo
      author
      supplierId
      projectId
      clientId
      quoteId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      status
      randomNumber
      itemSubscription
      collectionSubscription
      supplierContact
      supplierContacts
      supplierAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      updatedAt
      activity {
        items {
          id
          documentId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          purchaseOrderId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          isHourly
          lastUpdateAuthorId
          supplierQuoteId
          customFields {
            id
            value
          }
          order
          updatedAt
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuote = /* GraphQL */ `
  subscription OnCreateQuote($organisation: String) {
    onCreateQuote(organisation: $organisation) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuote = /* GraphQL */ `
  subscription OnUpdateQuote($organisation: String) {
    onUpdateQuote(organisation: $organisation) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuote = /* GraphQL */ `
  subscription OnDeleteQuote($organisation: String) {
    onDeleteQuote(organisation: $organisation) {
      id
      organisation
      currentRevisionName
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      rejectionDetails {
        type
        notes
      }
      defaultFees {
        id
        label
        value
        type
        defaultPercentage
        defaultPlus
        overrideFeeId
      }
      isTenderWin
      manuallyInvoicedAmount
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      requestIds
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          quoteId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          quoteId
          organisation
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          authorityLevel
          checkPrice
          resultingTaskId
          resultingPurchaseOrderId
          isHourly
          isRejected
          sector
          geographicalLocation
          isHourlyFullyInvoiced
          lastUpdateAuthorId
          manuallyInvoicedAmount
          isManuallyFullyInvoiced
          progressPercent
          customFields {
            id
            value
          }
          requestIds
          order
          updatedAt
          invoiceLineItems {
            nextToken
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateQuoteActivityItem = /* GraphQL */ `
  subscription OnCreateQuoteActivityItem($organisation: String) {
    onCreateQuoteActivityItem(organisation: $organisation) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onUpdateQuoteActivityItem = /* GraphQL */ `
  subscription OnUpdateQuoteActivityItem($organisation: String) {
    onUpdateQuoteActivityItem(organisation: $organisation) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onDeleteQuoteActivityItem = /* GraphQL */ `
  subscription OnDeleteQuoteActivityItem($organisation: String) {
    onDeleteQuoteActivityItem(organisation: $organisation) {
      id
      quoteId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onCreateInvoiceActivityItem = /* GraphQL */ `
  subscription OnCreateInvoiceActivityItem($organisation: String) {
    onCreateInvoiceActivityItem(organisation: $organisation) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onUpdateInvoiceActivityItem = /* GraphQL */ `
  subscription OnUpdateInvoiceActivityItem($organisation: String) {
    onUpdateInvoiceActivityItem(organisation: $organisation) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onDeleteInvoiceActivityItem = /* GraphQL */ `
  subscription OnDeleteInvoiceActivityItem($organisation: String) {
    onDeleteInvoiceActivityItem(organisation: $organisation) {
      id
      invoiceId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onCreateDocumentActivityItem = /* GraphQL */ `
  subscription OnCreateDocumentActivityItem($organisation: String) {
    onCreateDocumentActivityItem(organisation: $organisation) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onUpdateDocumentActivityItem = /* GraphQL */ `
  subscription OnUpdateDocumentActivityItem($organisation: String) {
    onUpdateDocumentActivityItem(organisation: $organisation) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onDeleteDocumentActivityItem = /* GraphQL */ `
  subscription OnDeleteDocumentActivityItem($organisation: String) {
    onDeleteDocumentActivityItem(organisation: $organisation) {
      id
      documentId
      total
      type
      organisation
      createdAt
      author
      content
      updatedAt
    }
  }
`;
export const onCreateQuoteLineItem = /* GraphQL */ `
  subscription OnCreateQuoteLineItem($organisation: String) {
    onCreateQuoteLineItem(organisation: $organisation) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateQuoteLineItem = /* GraphQL */ `
  subscription OnUpdateQuoteLineItem($organisation: String) {
    onUpdateQuoteLineItem(organisation: $organisation) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteQuoteLineItem = /* GraphQL */ `
  subscription OnDeleteQuoteLineItem($organisation: String) {
    onDeleteQuoteLineItem(organisation: $organisation) {
      id
      quoteId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      isRejected
      sector
      geographicalLocation
      isHourlyFullyInvoiced
      lastUpdateAuthorId
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      progressPercent
      customFields {
        id
        value
      }
      requestIds
      order
      updatedAt
      invoiceLineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreatePurchaseOrderLineItem = /* GraphQL */ `
  subscription OnCreatePurchaseOrderLineItem($organisation: String) {
    onCreatePurchaseOrderLineItem(organisation: $organisation) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const onUpdatePurchaseOrderLineItem = /* GraphQL */ `
  subscription OnUpdatePurchaseOrderLineItem($organisation: String) {
    onUpdatePurchaseOrderLineItem(organisation: $organisation) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const onDeletePurchaseOrderLineItem = /* GraphQL */ `
  subscription OnDeletePurchaseOrderLineItem($organisation: String) {
    onDeletePurchaseOrderLineItem(organisation: $organisation) {
      id
      purchaseOrderId
      organisation
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      isHourly
      lastUpdateAuthorId
      supplierQuoteId
      customFields {
        id
        value
      }
      order
      updatedAt
    }
  }
`;
export const onCreateInvoiceLineItem = /* GraphQL */ `
  subscription OnCreateInvoiceLineItem($organisation: String) {
    onCreateInvoiceLineItem(organisation: $organisation) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onUpdateInvoiceLineItem = /* GraphQL */ `
  subscription OnUpdateInvoiceLineItem($organisation: String) {
    onUpdateInvoiceLineItem(organisation: $organisation) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onDeleteInvoiceLineItem = /* GraphQL */ `
  subscription OnDeleteInvoiceLineItem($organisation: String) {
    onDeleteInvoiceLineItem(organisation: $organisation) {
      id
      invoiceId
      organisation
      quoteLineItemId
      taskId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      createdAt
      taxAmount
      amount
      customFields {
        id
        value
      }
      order
      updatedAt
      quoteLineItem {
        id
        quoteId
        organisation
        title
        description
        quantity
        unitPrice
        discountPercent
        taxRate
        createdAt
        taxAmount
        amount
        authorityLevel
        checkPrice
        resultingTaskId
        resultingPurchaseOrderId
        isHourly
        isRejected
        sector
        geographicalLocation
        isHourlyFullyInvoiced
        lastUpdateAuthorId
        manuallyInvoicedAmount
        isManuallyFullyInvoiced
        progressPercent
        customFields {
          id
          value
        }
        requestIds
        order
        updatedAt
        invoiceLineItems {
          items {
            id
            invoiceId
            organisation
            quoteLineItemId
            taskId
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            order
            updatedAt
          }
          nextToken
        }
      }
    }
  }
`;
export const onCreateTaskRevision = /* GraphQL */ `
  subscription OnCreateTaskRevision($organisation: String) {
    onCreateTaskRevision(organisation: $organisation) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateTaskRevision = /* GraphQL */ `
  subscription OnUpdateTaskRevision($organisation: String) {
    onUpdateTaskRevision(organisation: $organisation) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteTaskRevision = /* GraphQL */ `
  subscription OnDeleteTaskRevision($organisation: String) {
    onDeleteTaskRevision(organisation: $organisation) {
      id
      taskId
      name
      isReadOnly
      organisation
      isArchived
      status
      dueDate
      requestedDate
      estimatedHours
      priorityId
      requestIds
      base
      randomNumber
      itemSubscription
      collectionSubscription
      description
      author
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      deletedFilesByType
      deletedFilesByTemplate
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      files {
        items {
          id
          taskRevisionId
          name
          organisation
          isArchived
          isHidden
          type
          extension
          sheetsInApplication {
            name
            exportKey
          }
          customFields {
            id
            value
          }
          reportMetadata {
            pageNumbersToSkipBorders
          }
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          skipAnnotation
          hasCustomFile
          autoPublishDisabled
          templateId
          templateVersionNumber
          isCorrupted
          isStartingFile
          startingFileName
          lastNonCorruptedS3VersionId
          constantId
          updatedAt
          sheets {
            nextToken
          }
          versions {
            nextToken
          }
          auditItems {
            nextToken
          }
        }
        nextToken
      }
      review {
        id
        organisation
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        approvedItems {
          id
          approvedAt
        }
        randomNumber
        itemSubscription
        collectionSubscription
        parentId
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile($organisation: String) {
    onCreateFile(organisation: $organisation) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile($organisation: String) {
    onUpdateFile(organisation: $organisation) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile($organisation: String) {
    onDeleteFile(organisation: $organisation) {
      id
      taskRevisionId
      name
      organisation
      isArchived
      isHidden
      type
      extension
      sheetsInApplication {
        name
        exportKey
      }
      customFields {
        id
        value
      }
      reportMetadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      randomNumber
      itemSubscription
      collectionSubscription
      createdAt
      skipAnnotation
      hasCustomFile
      autoPublishDisabled
      templateId
      templateVersionNumber
      isCorrupted
      isStartingFile
      startingFileName
      lastNonCorruptedS3VersionId
      constantId
      updatedAt
      sheets {
        items {
          id
          taskId
          fileId
          name
          description
          fileType
          autoGeneratedReferenceNumber
          customReferenceNumber
          createdAt
          order
          includeInPublish
          constantId
          excludeFromRegister
          updatedAt
          revisions {
            nextToken
          }
        }
        nextToken
      }
      versions {
        items {
          id
          versionNumber
          customId
          fileId
          key
          organisation
          pageCount
          processingSource
          processingDestination
          processingStatus
          processingOperation
          publishStartAt
          publishEndAt
          publishError
          savedAt
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          externalReferences {
            id
            fileVersionId
            taskId
            taskRevisionId
            taskRevisionName
            fileId
            versionNumber
            key
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      auditItems {
        items {
          id
          taskId
          projectId
          fileId
          userId
          clientId
          content
          page
          type
          organisation
          fileType
          fileName
          createdAt
          fileVersionId
          s3VersionId
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateSheet = /* GraphQL */ `
  subscription OnCreateSheet($organisation: String) {
    onCreateSheet(organisation: $organisation) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateSheet = /* GraphQL */ `
  subscription OnUpdateSheet($organisation: String) {
    onUpdateSheet(organisation: $organisation) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteSheet = /* GraphQL */ `
  subscription OnDeleteSheet($organisation: String) {
    onDeleteSheet(organisation: $organisation) {
      id
      taskId
      fileId
      name
      description
      fileType
      autoGeneratedReferenceNumber
      customReferenceNumber
      createdAt
      order
      includeInPublish
      constantId
      excludeFromRegister
      updatedAt
      revisions {
        items {
          id
          sheetId
          name
          description
          status
          author
          checkedBy
          reviewAcceptDate
          fileVersionId
          exports {
            extension
            key
            rawKey
            fileName
            latestS3VersionId
          }
          isArchived
          realCreatedAt
          createdAt
          updatedAt
          fileVersion {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreateSheetRevision = /* GraphQL */ `
  subscription OnCreateSheetRevision($organisation: String) {
    onCreateSheetRevision(organisation: $organisation) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateSheetRevision = /* GraphQL */ `
  subscription OnUpdateSheetRevision($organisation: String) {
    onUpdateSheetRevision(organisation: $organisation) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteSheetRevision = /* GraphQL */ `
  subscription OnDeleteSheetRevision($organisation: String) {
    onDeleteSheetRevision(organisation: $organisation) {
      id
      sheetId
      name
      description
      status
      author
      checkedBy
      reviewAcceptDate
      fileVersionId
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      isArchived
      realCreatedAt
      createdAt
      updatedAt
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateFileVersion = /* GraphQL */ `
  subscription OnCreateFileVersion($organisation: String) {
    onCreateFileVersion(organisation: $organisation) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFileVersion = /* GraphQL */ `
  subscription OnUpdateFileVersion($organisation: String) {
    onUpdateFileVersion(organisation: $organisation) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFileVersion = /* GraphQL */ `
  subscription OnDeleteFileVersion($organisation: String) {
    onDeleteFileVersion(organisation: $organisation) {
      id
      versionNumber
      customId
      fileId
      key
      organisation
      pageCount
      processingSource
      processingDestination
      processingStatus
      processingOperation
      publishStartAt
      publishEndAt
      publishError
      savedAt
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      externalReferences {
        id
        fileVersionId
        taskId
        taskRevisionId
        taskRevisionName
        fileId
        versionNumber
        key
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReview = /* GraphQL */ `
  subscription OnCreateReview($organisation: String) {
    onCreateReview(organisation: $organisation) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateReview = /* GraphQL */ `
  subscription OnUpdateReview($organisation: String) {
    onUpdateReview(organisation: $organisation) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteReview = /* GraphQL */ `
  subscription OnDeleteReview($organisation: String) {
    onDeleteReview(organisation: $organisation) {
      id
      organisation
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        itemId
        content
        author
        reviewThread {
          id
          type
          createdAt
          sheetId
          sheetLabel
          itemId
          content
          author
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          resolved
          edited
          resolvedBy
          resolvedAt
        }
        resolved
        edited
        resolvedBy
        resolvedAt
      }
      approvedItems {
        id
        approvedAt
      }
      randomNumber
      itemSubscription
      collectionSubscription
      parentId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAsyncJob = /* GraphQL */ `
  subscription OnCreateAsyncJob($organisation: String) {
    onCreateAsyncJob(organisation: $organisation) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onUpdateAsyncJob = /* GraphQL */ `
  subscription OnUpdateAsyncJob($organisation: String) {
    onUpdateAsyncJob(organisation: $organisation) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onDeleteAsyncJob = /* GraphQL */ `
  subscription OnDeleteAsyncJob($organisation: String) {
    onDeleteAsyncJob(organisation: $organisation) {
      id
      retriedTimes
      retriedFromAsyncJobId
      retryReason
      fileType
      taskId
      fileId
      type
      fileVersionId
      organisation
      userId
      status
      createdAt
      progressPercent
      cancelRequested
      error
      startedAt
      finishedAt
      durationSeconds
      inputS3VersionId
      outputS3VersionId
      outputS3Key
      auditItemId
      consumerId
      logStreamName
      athenaExecutionId
      resultKey
      executionId
      restUrl
      bucket
      region
      graphQLUrl
      operations {
        type
        sheetName
        copySheetName
        oldSheetName
        newSheetName
        sheetNames
        newSheetNames
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
      }
      updatedAt
      task {
        id
        isReadOnly
        isHidden
        isConfirmed
        linkedTasks {
          id
          correspondingId
          taskId
          relationship
          label
        }
        quoteIds
        quoteLineItemIds
        initials
        randomNumber
        itemSubscription
        collectionSubscription
        catLevel
        organisation
        projectId
        sprintId
        clientId
        author
        title
        titleLowerCase
        assignedTo
        assignedToUsers
        assignedToStockItems
        subtitle
        dueDate
        startDate
        endDate
        estimatedHours
        budget
        checkPrice
        isArchived
        isFinished
        finishedAt
        archivedAt
        isUnderReview
        reviewStatus
        reviewSecondaryStatus
        status
        isExternalReference
        customFields {
          id
          value
        }
        order
        createdAt
        subtaskProgress
        checkedBy
        amountBudget
        amountSpent
        hoursBudget
        hoursSpent
        clientContact
        clientContacts
        clientAddress
        contactIdsToIncludeInCC
        contactIdsToIncludeInBCC
        extraEmailsToIncludeInCC {
          id
          email
        }
        extraEmailsToIncludeInBCC {
          id
          email
        }
        team
        requestIds
        sharedWithGroups
        sharedWithUsers
        updatedAt
        organisationDetails {
          id
          logoKey
          name
          description
          projectCount
          requestCount
          projectIdOffset
          hasAutoPublish
          slackWebhookURL
          usesFiles
          usesAuthority
          usesDesignAuthority
          usesCheckAuthority
          usesReviewAuthority
          usesInitials
          usesClientInitials
          usesProjectInitials
          usesTaskInitials
          usesRevisions
          usesReviews
          usesSprints
          usesDelete
          billingDay
          pricePerSeat
          taxRate
          accountBalance
          monthlyCost
          discountPerSeat
          lastBillCreatedAt
          seats
          deploymentAlertNumber
          itemSubscription
          settings {
          }
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          vatNumber
          fileTypesUsed
          financialControllers
          addons {
            pricePerSeat
            name
            discountPercent
          }
          labels {
            id
            value
          }
          defaultFees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          teams {
            id
            label
          }
          fileStatuses {
            name
          }
          taskStatuses {
            name
            type
          }
          customFields {
            id
            label
            target
            type
            defaultValue
            showAsTag
            displayTagIfChecked
            displayTagIfEmpty
            onlyVisibleForCertainUserGroups
            groupsThatCanSee
            tagLabel
            color
            formula
            isStatic
            mandatory
            nonAdminsCanRead
            nonAdminsCanWrite
          }
          integrations {
            id
            authorisedAt
          }
          accountancyOrganisationId
          includeInBilling
          financialsLastRecalculatedAt
          financialsLastStartedRecalculatingAt
          financialsRecalculationProgress
          financialsRecalculationStatus
          createdAt
          updatedAt
          variables {
            nextToken
          }
          templates {
            nextToken
          }
          bills {
            nextToken
          }
          timesheetTags {
            nextToken
          }
        }
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        project {
          id
          organisation
          randomNumber
          itemSubscription
          collectionSubscription
          author
          title
          description
          clientId
          assignedTo
          initials
          taskCount
          quoteCount
          invoiceCount
          purchaseOrderCount
          createdAt
          isArchived
          isFinished
          archivedAt
          finishedAt
          adminFiles {
            id
            name
            taskId
          }
          clientContacts
          address {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          addressCoordinates {
            lat
            lng
          }
          poNumber
          customFields {
            id
            value
          }
          team
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          sharedWithGroups
          sharedWithUsers
          updatedAt
          client {
            id
            key
            name
            organisation
            notes
            initials
            isPriority
            randomNumber
            itemSubscription
            collectionSubscription
            createdAt
            defaultAddressId
            defaultContactId
            companyNumber
            vatNumber
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            defaultExpectPaymentAfterDays
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          tasks {
            nextToken
          }
          activity {
            nextToken
          }
          quotes {
            nextToken
          }
          auditItems {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        subtasks {
          items {
            id
            parentId
            order
            assignedTo
            title
            description
            isFinished
            isArchived
            author
            dueDate
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            taskId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quoteLineItems {
          items {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
          nextToken
        }
        revisions {
          items {
            id
            taskId
            name
            isReadOnly
            organisation
            isArchived
            status
            dueDate
            requestedDate
            estimatedHours
            priorityId
            requestIds
            base
            randomNumber
            itemSubscription
            collectionSubscription
            description
            author
            checkedBy
            reviewId
            reviewAcceptDate
            reviewStatus
            reviewSecondaryStatus
            deletedFilesByType
            deletedFilesByTemplate
            readAccessGroups
            writeAccessGroups
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        timesheetBlocks {
          items {
            id
            startAt
            endAt
            invoiceId
            taskId
            taskRevisionId
            projectId
            clientId
            description
            billable
            tags
            mileage
            userId
            organisation
            quoteLineItemId
            quoteId
            variation
            onSite
            invoicingStatus
            feeRole
            reviewStatus
            startLatLong
            endLatLong
            isRecording
            latLongStart
            latLongEnd
            createdAt
            updatedAt
          }
          nextToken
        }
      }
      file {
        id
        taskRevisionId
        name
        organisation
        isArchived
        isHidden
        type
        extension
        sheetsInApplication {
          name
          exportKey
        }
        customFields {
          id
          value
        }
        reportMetadata {
          inserts {
            key
            bucket
            pageNumber
            hasBorders
            pagesToExclude
            pagesToInclude
            deletePage
          }
          assets {
            name
            bucket
            key
            width
            height
            scale
          }
          pageMapping {
            sourcePageGroupNumber
            sourcePageGroupName
            correspondingPreviewPageNumbers
          }
          pageNumbersToSkipBorders
        }
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        skipAnnotation
        hasCustomFile
        autoPublishDisabled
        templateId
        templateVersionNumber
        isCorrupted
        isStartingFile
        startingFileName
        lastNonCorruptedS3VersionId
        constantId
        updatedAt
        sheets {
          items {
            id
            taskId
            fileId
            name
            description
            fileType
            autoGeneratedReferenceNumber
            customReferenceNumber
            createdAt
            order
            includeInPublish
            constantId
            excludeFromRegister
            updatedAt
          }
          nextToken
        }
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            pageCount
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            createdAt
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
      }
      fileVersion {
        id
        versionNumber
        customId
        fileId
        key
        organisation
        pageCount
        processingSource
        processingDestination
        processingStatus
        processingOperation
        publishStartAt
        publishEndAt
        publishError
        savedAt
        exports {
          extension
          key
          rawKey
          fileName
          latestS3VersionId
        }
        externalReferences {
          id
          fileVersionId
          taskId
          taskRevisionId
          taskRevisionName
          fileId
          versionNumber
          key
        }
        createdAt
        updatedAt
      }
    }
  }
`;
export const onCreateAuditItem = /* GraphQL */ `
  subscription OnCreateAuditItem($organisation: String) {
    onCreateAuditItem(organisation: $organisation) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const onUpdateAuditItem = /* GraphQL */ `
  subscription OnUpdateAuditItem($organisation: String) {
    onUpdateAuditItem(organisation: $organisation) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const onDeleteAuditItem = /* GraphQL */ `
  subscription OnDeleteAuditItem($organisation: String) {
    onDeleteAuditItem(organisation: $organisation) {
      id
      taskId
      projectId
      fileId
      userId
      clientId
      content
      page
      type
      organisation
      fileType
      fileName
      createdAt
      fileVersionId
      s3VersionId
      updatedAt
    }
  }
`;
export const onCreateAnalytics = /* GraphQL */ `
  subscription OnCreateAnalytics($organisation: String) {
    onCreateAnalytics(organisation: $organisation) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAnalytics = /* GraphQL */ `
  subscription OnUpdateAnalytics($organisation: String) {
    onUpdateAnalytics(organisation: $organisation) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAnalytics = /* GraphQL */ `
  subscription OnDeleteAnalytics($organisation: String) {
    onDeleteAnalytics(organisation: $organisation) {
      id
      organisation
      parentId
      userId
      parentType
      eventType
      data
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHistoricData = /* GraphQL */ `
  subscription OnCreateHistoricData($organisation: String) {
    onCreateHistoricData(organisation: $organisation) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHistoricData = /* GraphQL */ `
  subscription OnUpdateHistoricData($organisation: String) {
    onUpdateHistoricData(organisation: $organisation) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHistoricData = /* GraphQL */ `
  subscription OnDeleteHistoricData($organisation: String) {
    onDeleteHistoricData(organisation: $organisation) {
      id
      date
      tasksTotal
      tasksFinished
      tasksArchived
      tasksOpen
      projectsTotal
      projectsArchived
      usersTotal
      clientsTotal
      organisation
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHistory = /* GraphQL */ `
  subscription OnCreateHistory($organisation: String) {
    onCreateHistory(organisation: $organisation) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHistory = /* GraphQL */ `
  subscription OnUpdateHistory($organisation: String) {
    onUpdateHistory(organisation: $organisation) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHistory = /* GraphQL */ `
  subscription OnDeleteHistory($organisation: String) {
    onDeleteHistory(organisation: $organisation) {
      id
      organisation
      parentId
      startDate
      endDate
      type
      parentType
      interval
      content
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTimesheetBlock = /* GraphQL */ `
  subscription OnCreateTimesheetBlock($organisation: String) {
    onCreateTimesheetBlock(organisation: $organisation) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onUpdateTimesheetBlock = /* GraphQL */ `
  subscription OnUpdateTimesheetBlock($organisation: String) {
    onUpdateTimesheetBlock(organisation: $organisation) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onDeleteTimesheetBlock = /* GraphQL */ `
  subscription OnDeleteTimesheetBlock($organisation: String) {
    onDeleteTimesheetBlock(organisation: $organisation) {
      id
      startAt
      endAt
      invoiceId
      taskId
      taskRevisionId
      projectId
      clientId
      description
      billable
      tags
      mileage
      userId
      organisation
      quoteLineItemId
      quoteId
      variation
      onSite
      invoicingStatus
      feeRole
      reviewStatus
      startLatLong
      endLatLong
      isRecording
      latLongStart
      latLongEnd
      createdAt
      updatedAt
      expenses {
        items {
          id
          taskId
          projectId
          clientId
          amount
          description
          receiptPhotos
          timesheetBlockId
          invoiceId
          userId
          organisation
          incurredAt
          createdAt
          updatedAt
        }
        nextToken
      }
    }
  }
`;
export const onCreateExpense = /* GraphQL */ `
  subscription OnCreateExpense($organisation: String) {
    onCreateExpense(organisation: $organisation) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateExpense = /* GraphQL */ `
  subscription OnUpdateExpense($organisation: String) {
    onUpdateExpense(organisation: $organisation) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteExpense = /* GraphQL */ `
  subscription OnDeleteExpense($organisation: String) {
    onDeleteExpense(organisation: $organisation) {
      id
      taskId
      projectId
      clientId
      amount
      description
      receiptPhotos
      timesheetBlockId
      invoiceId
      userId
      organisation
      incurredAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHoliday = /* GraphQL */ `
  subscription OnCreateHoliday($organisation: String) {
    onCreateHoliday(organisation: $organisation) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const onUpdateHoliday = /* GraphQL */ `
  subscription OnUpdateHoliday($organisation: String) {
    onUpdateHoliday(organisation: $organisation) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const onDeleteHoliday = /* GraphQL */ `
  subscription OnDeleteHoliday($organisation: String) {
    onDeleteHoliday(organisation: $organisation) {
      id
      organisation
      userId
      createdAt
      startsAt
      endsAt
      days {
        day
        startHours
        endHours
      }
      repeatPattern
      status
      approvedAt
      approvedBy
      rejectedAt
      rejectedBy
      isSick
      message
      updatedAt
    }
  }
`;
export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice($organisation: String) {
    onCreateInvoice(organisation: $organisation) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice($organisation: String) {
    onUpdateInvoice(organisation: $organisation) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice($organisation: String) {
    onDeleteInvoice(organisation: $organisation) {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      invoiceDate
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      taxRate
      subtotal
      totalTax
      total
      createdAt
      termsText
      termsAttachment
      status
      randomNumber
      itemSubscription
      collectionSubscription
      clientContact
      clientContacts
      clientAddress
      templateId
      templateVersionNumber
      savedAt
      metadata {
        inserts {
          key
          bucket
          pageNumber
          hasBorders
          pagesToExclude
          pagesToInclude
          deletePage
        }
        assets {
          name
          bucket
          key
          width
          height
          scale
        }
        pageMapping {
          sourcePageGroupNumber
          sourcePageGroupName
          correspondingPreviewPageNumbers
        }
        pageNumbersToSkipBorders
      }
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      secondReviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      contactIdsToIncludeInCC
      contactIdsToIncludeInBCC
      extraEmailsToIncludeInCC {
        id
        email
      }
      extraEmailsToIncludeInBCC {
        id
        email
      }
      lastUpdateAuthorId
      accountancyInvoiceId
      amountPaid
      exports {
        extension
        key
        rawKey
        fileName
        latestS3VersionId
      }
      customFields {
        id
        value
      }
      team
      expectPaymentAfterDays
      updatedAt
      client {
        id
        key
        name
        organisation
        notes
        initials
        isPriority
        randomNumber
        itemSubscription
        collectionSubscription
        createdAt
        fees {
          id
          label
          value
          type
          defaultPercentage
          defaultPlus
          overrideFeeId
        }
        addresses {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        contacts {
          id
          firstName
          lastName
          email
          phone
          phone2
          position
          notes
        }
        defaultAddressId
        defaultContactId
        companyNumber
        vatNumber
        customFields {
          id
          value
        }
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        defaultExpectPaymentAfterDays
        sharedWithGroups
        sharedWithUsers
        updatedAt
        projects {
          items {
            id
            organisation
            randomNumber
            itemSubscription
            collectionSubscription
            author
            title
            description
            clientId
            assignedTo
            initials
            taskCount
            quoteCount
            invoiceCount
            purchaseOrderCount
            createdAt
            isArchived
            isFinished
            archivedAt
            finishedAt
            clientContacts
            poNumber
            team
            amountBudget
            amountSpent
            amountInvoiced
            amountReceived
            amountOutstanding
            hoursBudget
            hoursSpent
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      project {
        id
        organisation
        randomNumber
        itemSubscription
        collectionSubscription
        author
        title
        description
        clientId
        assignedTo
        initials
        taskCount
        quoteCount
        invoiceCount
        purchaseOrderCount
        createdAt
        isArchived
        isFinished
        archivedAt
        finishedAt
        adminFiles {
          id
          name
          taskId
        }
        clientContacts
        address {
          id
          streetName
          streetNumber
          houseName
          postcode
          county
          city
          country
        }
        addressCoordinates {
          lat
          lng
        }
        poNumber
        customFields {
          id
          value
        }
        team
        amountBudget
        amountSpent
        amountInvoiced
        amountReceived
        amountOutstanding
        hoursBudget
        hoursSpent
        sharedWithGroups
        sharedWithUsers
        updatedAt
        client {
          id
          key
          name
          organisation
          notes
          initials
          isPriority
          randomNumber
          itemSubscription
          collectionSubscription
          createdAt
          fees {
            id
            label
            value
            type
            defaultPercentage
            defaultPlus
            overrideFeeId
          }
          addresses {
            id
            streetName
            streetNumber
            houseName
            postcode
            county
            city
            country
          }
          contacts {
            id
            firstName
            lastName
            email
            phone
            phone2
            position
            notes
          }
          defaultAddressId
          defaultContactId
          companyNumber
          vatNumber
          customFields {
            id
            value
          }
          amountBudget
          amountSpent
          amountInvoiced
          amountReceived
          amountOutstanding
          hoursBudget
          hoursSpent
          defaultExpectPaymentAfterDays
          sharedWithGroups
          sharedWithUsers
          updatedAt
          projects {
            nextToken
          }
          quotes {
            nextToken
          }
          invoices {
            nextToken
          }
        }
        tasks {
          items {
            id
            isReadOnly
            isHidden
            isConfirmed
            quoteIds
            quoteLineItemIds
            initials
            randomNumber
            itemSubscription
            collectionSubscription
            catLevel
            organisation
            projectId
            sprintId
            clientId
            author
            title
            titleLowerCase
            assignedTo
            assignedToUsers
            assignedToStockItems
            subtitle
            dueDate
            startDate
            endDate
            estimatedHours
            budget
            checkPrice
            isArchived
            isFinished
            finishedAt
            archivedAt
            isUnderReview
            reviewStatus
            reviewSecondaryStatus
            status
            isExternalReference
            order
            createdAt
            subtaskProgress
            checkedBy
            amountBudget
            amountSpent
            hoursBudget
            hoursSpent
            clientContact
            clientContacts
            clientAddress
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            team
            requestIds
            sharedWithGroups
            sharedWithUsers
            updatedAt
          }
          nextToken
        }
        activity {
          items {
            id
            parentId
            author
            content
            type
            organisation
            createdAt
            updatedAt
          }
          nextToken
        }
        quotes {
          items {
            id
            organisation
            currentRevisionName
            isArchived
            assignedTo
            author
            clientId
            projectId
            fileKey
            validFrom
            validUntil
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            isTenderWin
            manuallyInvoicedAmount
            team
            requestIds
            updatedAt
          }
          nextToken
        }
        auditItems {
          items {
            id
            taskId
            projectId
            fileId
            userId
            clientId
            content
            page
            type
            organisation
            fileType
            fileName
            createdAt
            fileVersionId
            s3VersionId
            updatedAt
          }
          nextToken
        }
        invoices {
          items {
            id
            organisation
            isArchived
            dueDate
            assignedTo
            invoiceDate
            author
            clientId
            projectId
            fileKey
            reference
            title
            description
            currency
            taxInclusive
            taxRate
            subtotal
            totalTax
            total
            createdAt
            termsText
            termsAttachment
            status
            randomNumber
            itemSubscription
            collectionSubscription
            clientContact
            clientContacts
            clientAddress
            templateId
            templateVersionNumber
            savedAt
            poNumber
            checkedBy
            isUnderReview
            reviewApprovedAt
            secondReviewApprovedAt
            reviewStatus
            reviewSecondaryStatus
            contactIdsToIncludeInCC
            contactIdsToIncludeInBCC
            lastUpdateAuthorId
            accountancyInvoiceId
            amountPaid
            team
            expectPaymentAfterDays
            updatedAt
          }
          nextToken
        }
      }
      activity {
        items {
          id
          invoiceId
          total
          type
          organisation
          createdAt
          author
          content
          updatedAt
        }
        nextToken
      }
      lineItems {
        items {
          id
          invoiceId
          organisation
          quoteLineItemId
          taskId
          title
          description
          quantity
          unitPrice
          discountPercent
          taxRate
          createdAt
          taxAmount
          amount
          customFields {
            id
            value
          }
          order
          updatedAt
          quoteLineItem {
            id
            quoteId
            organisation
            title
            description
            quantity
            unitPrice
            discountPercent
            taxRate
            createdAt
            taxAmount
            amount
            authorityLevel
            checkPrice
            resultingTaskId
            resultingPurchaseOrderId
            isHourly
            isRejected
            sector
            geographicalLocation
            isHourlyFullyInvoiced
            lastUpdateAuthorId
            manuallyInvoicedAmount
            isManuallyFullyInvoiced
            progressPercent
            requestIds
            order
            updatedAt
          }
        }
        nextToken
      }
      reviews {
        items {
          id
          organisation
          reviewThread {
            id
            type
            createdAt
            sheetId
            sheetLabel
            itemId
            content
            author
            resolved
            edited
            resolvedBy
            resolvedAt
          }
          approvedItems {
            id
            approvedAt
          }
          randomNumber
          itemSubscription
          collectionSubscription
          parentId
          createdAt
          updatedAt
        }
        nextToken
      }
      timesheetBlocks {
        items {
          id
          startAt
          endAt
          invoiceId
          taskId
          taskRevisionId
          projectId
          clientId
          description
          billable
          tags
          mileage
          userId
          organisation
          quoteLineItemId
          quoteId
          variation
          onSite
          invoicingStatus
          feeRole
          reviewStatus
          startLatLong
          endLatLong
          isRecording
          latLongStart
          latLongEnd
          createdAt
          updatedAt
          expenses {
            nextToken
          }
        }
        nextToken
      }
    }
  }
`;
export const onCreatePublicArticle = /* GraphQL */ `
  subscription OnCreatePublicArticle($organisation: String) {
    onCreatePublicArticle(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const onUpdatePublicArticle = /* GraphQL */ `
  subscription OnUpdatePublicArticle($organisation: String) {
    onUpdatePublicArticle(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const onDeletePublicArticle = /* GraphQL */ `
  subscription OnDeletePublicArticle($organisation: String) {
    onDeletePublicArticle(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      slug
      title
      content
      tags
      parentId
      updatedAt
    }
  }
`;
export const onCreateRequest = /* GraphQL */ `
  subscription OnCreateRequest($organisation: String) {
    onCreateRequest(organisation: $organisation) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const onUpdateRequest = /* GraphQL */ `
  subscription OnUpdateRequest($organisation: String) {
    onUpdateRequest(organisation: $organisation) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const onDeleteRequest = /* GraphQL */ `
  subscription OnDeleteRequest($organisation: String) {
    onDeleteRequest(organisation: $organisation) {
      id
      organisation
      itemSubscription
      assignedTo
      createdAt
      createdBy
      status
      requestedAt
      requestedForDate
      requestedBy
      title
      priorityId
      clientId
      projectId
      latestFormName
      resultingTaskIds
      resultingQuoteIds
      sharedWithGroups
      sharedWithUsers
      updatedAt
    }
  }
`;
export const onCreateAttachment = /* GraphQL */ `
  subscription OnCreateAttachment($organisation: String) {
    onCreateAttachment(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const onUpdateAttachment = /* GraphQL */ `
  subscription OnUpdateAttachment($organisation: String) {
    onUpdateAttachment(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const onDeleteAttachment = /* GraphQL */ `
  subscription OnDeleteAttachment($organisation: String) {
    onDeleteAttachment(organisation: $organisation) {
      id
      organisation
      createdAt
      createdBy
      s3CreatedAt
      s3UpdatedAt
      nameWithoutExtension
      extension
      name
      path
      size
      type
      sharedWith {
        id
        targetType
        targetId
        access
      }
      updatedAt
    }
  }
`;
export const onCreateInterval = /* GraphQL */ `
  subscription OnCreateInterval($organisation: String) {
    onCreateInterval(organisation: $organisation) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const onUpdateInterval = /* GraphQL */ `
  subscription OnUpdateInterval($organisation: String) {
    onUpdateInterval(organisation: $organisation) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const onDeleteInterval = /* GraphQL */ `
  subscription OnDeleteInterval($organisation: String) {
    onDeleteInterval(organisation: $organisation) {
      id
      organisation
      createdAt
      startDate
      endDate
      name
      content
      type
      updatedAt
    }
  }
`;
export const onCreateStockItem = /* GraphQL */ `
  subscription OnCreateStockItem($organisation: String) {
    onCreateStockItem(organisation: $organisation) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
export const onUpdateStockItem = /* GraphQL */ `
  subscription OnUpdateStockItem($organisation: String) {
    onUpdateStockItem(organisation: $organisation) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
export const onDeleteStockItem = /* GraphQL */ `
  subscription OnDeleteStockItem($organisation: String) {
    onDeleteStockItem(organisation: $organisation) {
      id
      organisation
      createdAt
      name
      description
      type
      customFields {
        id
        value
      }
      displayOnTimeline
      maintenanceIntervals {
        id
        content
        startDate
        endDate
        startTime
        endTime
        status
        completedAt
        notes
        reminders {
          id
          offsetType
          offsetAmount
        }
      }
      updatedAt
    }
  }
`;
