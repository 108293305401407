import React from "react";
import awsExports from "aws-exports";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { message, Typography, Button, Modal, notification, Alert } from "antd";
import cookie from "js-cookie";
import { graphqlOperation } from "aws-amplify";
import RichTextEditor from "react-rte";
import _ from "lodash";
import axios from "axios";
import { EditOutlined, PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import cx from "classnames";

import { getSimpleLabel } from "common/labels";
import withSubscriptions from "common/withSubscriptions";
import {
  COOKIE_NAME_QUOTE_PREVIEW,
  COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE,
  MIN_DOCUMENT_FORM_WIDTH,
} from "common/constants";
import { KEY_TYPES, encodeKey, getTemplateFromOrganisation } from "common/shared";
import getS3File from "common/getS3File";
import { isAuthorised } from "common/permissions";
import { callGraphQLSimple, callRest } from "common/apiHelpers";
import { callGraphQL, downloadBlob, getLabel } from "common/helpers";
import {
  displayInsertAttachmentModal,
  displayInsertAttachmentPickerModal,
  displayReportUserListModal,
  displayFields,
  displayModalContainingFields,
  computeHiddenFormFields,
} from "ReportPage/Report/reportHelpers";
import { processLambdaPdfInserts, processReportPageMapping } from "common/documentRenderHelpers";
import { updateTask, updateQuote, getQuote } from "graphql/queries_custom";
import { createQuoteLineItem, updateQuoteLineItem, updatePurchaseOrder, deleteQuote } from "graphql/mutations";
import { calculateTaskFinancials } from "common/financialHelpers";
import { recordActivityItem } from "./quoteHelpers";
import { changeLineItemCustomField } from "./customLineItemFields";

import QuoteDocument from "./QuoteDocument/QuoteDocument";
import Input from "Input/Input";
import Card from "Card/Card";
import CreateTaskModal from "CreateTaskModal/CreateTaskModal";
import RequestQuoteReviewModal from "Modals/RequestQuoteReviewModal/RequestQuoteReviewModal";
import SendQuoteModal from "Modals/SendDocumentModal/SendQuoteModal";
import QuoteActivity from "./QuoteActivity/QuoteActivity";
import QuoteLineItemsTable from "./QuoteLineItemsTable/QuoteLineItemsTable";
import QuoteMetadata from "./QuoteMetadata/QuoteMetadata";
import QuoteActions from "./QuoteActions/QuoteActions";
import QuoteReviewSummary from "./QuoteReviewSummary/QuoteReviewSummary";
import AddToTaskModal from "Modals/AddToTaskModal/AddToTaskModal";
import AddQuoteLineItemToPurchaseOrderModal from "Modals/AddQuoteLineItemToPurchaseOrderModal/AddQuoteLineItemToPurchaseOrderModal";
import ReviewTarget from "ReviewTarget/ReviewTarget";
import DocumentReview from "DocumentReview/DocumentReview";
import QuoteRejectionDetails from "./QuoteRejectionDetails/QuoteRejectionDetails";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import ModalAddItemFromList from "Form/ModalAddItemFromList/ModalAddItemFromList";
import ModalManageItemList from "Form/ModalManageItemList/ModalManageItemList";

import "./QuoteDetailsPage.scss";

const toolbarConfig = {
  // Optionally specify the groups to display (displayed in the order listed).
  display: ["INLINE_STYLE_BUTTONS", "BLOCK_TYPE_BUTTONS", "LINK_BUTTONS", "BLOCK_TYPE_DROPDOWN", "HISTORY_BUTTONS"],
  INLINE_STYLE_BUTTONS: [
    { label: "Bold", style: "BOLD", className: "bold" },
    { label: "Italic", style: "ITALIC" },
    { label: "Underline", style: "UNDERLINE" },
  ],
  BLOCK_TYPE_DROPDOWN: [
    { label: "Normal", style: "unstyled" },
    { label: "Heading Large", style: "header-one" },
    { label: "Heading Medium", style: "header-two" },
    { label: "Heading Small", style: "header-three" },
  ],
  BLOCK_TYPE_BUTTONS: [
    { label: "UL", style: "unordered-list-item" },
    { label: "OL", style: "ordered-list-item" },
  ],
};

export class QuoteDetailsPage extends React.Component {
  queueCheckerIsRunning = false;
  state = {
    description: "",
    dataUri: null,
    form: null,
    formPreview: null,
    isSaving: false,
    savedAt: moment(),
    fieldUnderEditName: null,
    fieldUnderEditSelectionStart: null,
    subFieldUnderEditIndex: null,
    fieldUnderEditValue: "",
    isAttachmentPickerOpen: false,
    isReportUserListModalOpen: false,
    isCreatingTaskFromLineItemId: null,
    isCreateTaskModalVisible: false,
    isSendingQuote: false,
    isDownloadingQuote: false,
    isInsertAttachmentsModalOpen: false,
    sendStatus: null,
    selectedLineItemId: null,
    isPreviewEnabled: false,
    isAddToTaskModalVisible: false,
    isAddQuoteLineItemToPurchaseOrderModalVisible: false,
    isRequestReviewModalVisible: false,
    hasNewComment: false,
    newCommentFieldName: null,
    newCommentLineItemIndex: null,
    areCommentsVisible: true,
    isUnderReview: false,
    isSendQuoteModalVisible: false,
    isApprovedPdfVisible: false,
    approvedPdfData: null,
    hasApprovedPdf: null,
    numberForPreviewRefresh: 0,
    isHardcodedTemplate: false, // if the template is hardcoded, it means it wasn't created with the template editor
    isWaitingForFirstDocumentAfterReviewApproval: false,
    hasUploadedPreview: false,
    isCorrupted: false,
    corruptedReason: null,
    dateTimeToCheckFormAgainst: moment().toISOString(),
    lineItemChangeQueue: [],
    hiddenFormFields: {},
    isAddItemFromListModalOpen: false,
    templateDetails: null,
    isManageItemListModalOpen: false,
  };

  constructor(props) {
    super(props);
    this.debouncedChangeDescription = _.debounce(() => this.changeDescription(), 500);
    this.debouncedInnerSaveUserFields = _.debounce(this.saveUserFields, 1000);

    this.debouncedSaveUserFields = () => {
      computeHiddenFormFields.call(this);
      this.debouncedInnerSaveUserFields();
    };
    this.debouncedChangeAttribute = _.debounce(this.changeAttribute, 200);
    this.debouncedChangeLineItemCustomField = _.debounce(changeLineItemCustomField, 500);
  }

  async componentDidMount() {
    this.props.setBoxedLayout(false);
    this.props.showPreloader();
    this.checkQueryParamsForAutoScroll();
    this.setState({ isUnderReview: this.props.quote.isUnderReview });
    this.intervalCheckFormWidth = setInterval(this.checkFormWidth, 3000);
    setTimeout(this.checkFormWidth, 1000);

    const isPreviewEnabled = cookie.get(COOKIE_NAME_QUOTE_PREVIEW);
    if (!this.props.organisationDetails.settings?.quote?.disablePreview || isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      if (isPreviewEnabled === "true") {
        this.setState({ isPreviewEnabled: true });
      } else if (isPreviewEnabled === "false") {
        this.setState({ isPreviewEnabled: false });
      }
    }

    // const areCommentsVisible = cookie.get(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE);
    // if (areCommentsVisible === "true") {
    //   this.setState({ areCommentsVisible: true });
    // } else if (areCommentsVisible === "false") {
    //   this.setState({ areCommentsVisible: false });
    // }

    const { quote } = this.props;

    const projectFolder = await encodeKey({
      type: KEY_TYPES.PROJECT_FOLDER,
      data: {
        organisation: quote.organisation,
        projectId: quote.projectId,
      },
    });

    if (!quote.project) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("project")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    if (!quote.client) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("client")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    this.loadFile();
    const templateDetails = getTemplateFromOrganisation({
      organisationDetails: this.props.organisationDetails,
      templateId: this.props.quote.templateId,
      fileType: "QUOTE",
    });

    this.setState({
      projectFolder,
      isHardcodedTemplate: !templateDetails?.key,
      description: RichTextEditor.createValueFromString(quote.description || "", "html"),
      templateDetails,
    });

    window.callGraphQLSimple({
      displayError: false,
      mutation: "createAuditItem",
      variables: {
        input: {
          taskId: "nothing",
          projectId: quote.projectId,
          fileId: quote.id,
          clientId: quote.client.id,
          page: "QUOTE_DETAILS",
          type: "PAGE_VIEW",
          userId: window.apiUser.id,
          organisation: window.apiUser.organisation,
        },
      },
    });

    this.fetchApprovedVersion();
  }

  addLineItemChangeToQueue = (params) => {
    this.setState(
      {
        lineItemChangeQueue: [
          ...this.state.lineItemChangeQueue,
          {
            id: window.randomUUID(),
            payload: params,
          },
        ],
      },
      this.checkLineItemChangeQueue
    );
  };

  checkLineItemChangeQueue = async () => {
    if (this.queueCheckerIsRunning) {
      // only one instance of the queue checker should run at a time;
      return;
    }
    this.queueCheckerIsRunning = true;
    const { lineItemChangeQueue } = this.state;
    if (lineItemChangeQueue.length === 0) {
      return;
    }
    const firstItemInQueue = lineItemChangeQueue[0];
    const secondItemInQueue = lineItemChangeQueue[1];

    let shouldWaitBeforeProceeding = true;

    let messageKey = "quote-recalculating-amounts";

    if (
      secondItemInQueue &&
      firstItemInQueue.payload.id === secondItemInQueue.payload.id &&
      firstItemInQueue.payload.fieldName === secondItemInQueue.payload.fieldName
    ) {
      shouldWaitBeforeProceeding = false;
      // if the second item in the queue is the same as the first, remove the first
    } else {
      message.loading({
        content: "Recalculating amounts...",
        key: messageKey,
        duration: 0,
      });
      await this.changeLineItem(firstItemInQueue.payload);
    }

    this.setState(
      {
        lineItemChangeQueue: this.state.lineItemChangeQueue.filter((item) => item.id !== firstItemInQueue.id),
      },
      async () => {
        this.queueCheckerIsRunning = false;
        if (shouldWaitBeforeProceeding) {
          await new Promise((resolve) => setTimeout(resolve, 100));
        }
        if (this.state.lineItemChangeQueue.length > 0) {
          this.checkLineItemChangeQueue();
        } else {
          message.success({
            content: "Amounts recalculated",
            key: messageKey,
          });
        }
      }
    );
  };

  componentDidUpdate(prevProps) {
    const { quote } = this.props;
    if (prevProps.quote.isUnderReview !== quote.isUnderReview) {
      this.setState({ isUnderReview: quote.isUnderReview });
    }

    const {
      savedAt: savedAtOld,
      updatedAt: updatedAtOld,
      activity: activityOld,
      ...oldQuoteForChecking
    } = prevProps.quote;
    const { savedAt: savedAtNew, updatedAt: updatedAtNew, activity: activityNew, ...newQuoteForChecking } = quote;

    let strOldQuoteForChecking = JSON.stringify(oldQuoteForChecking, null, 2);
    let strNewQuoteForChecking = JSON.stringify(newQuoteForChecking, null, 2);

    if (strOldQuoteForChecking !== strNewQuoteForChecking) {
      this.refreshQuotePreview();
    }
    this.isReviewed = quote.reviewStatus === "SUCCESS";

    if (this.state.hasApprovedPdf && !quote.reviewApprovedAt) {
      this.setState({
        approvedPdfData: undefined,
        hasApprovedPdf: undefined,
        isApprovedPdfVisible: false,
      });
    }

    if (quote.reviewApprovedAt && !prevProps.quote.reviewApprovedAt) {
      this.setState({ isWaitingForFirstDocumentAfterReviewApproval: true });
    }
  }

  componentWillUnmount() {
    this.props.setBoxedLayout(true);

    if (this.intervalCheckFormWidth) {
      clearInterval(this.intervalCheckFormWidth);
    }
  }

  checkFormWidth = () => {
    const formElement = document.querySelector(".user-input-container");
    if (!formElement) {
      return;
    }

    // if the width of the form is below the threshold

    if (formElement.offsetWidth < MIN_DOCUMENT_FORM_WIDTH && this.state.isPreviewEnabled) {
      this.setState({ isPreviewEnabled: false });
    }
  };

  fetchApprovedVersion = async () => {
    const { quote } = this.props;
    // debugger;

    if (quote.reviewApprovedAt && quote.exports && quote.exports.length > 0) {
      const publicPdfUrl = await getS3File(
        quote.exports[0].key.replace("public/", ""),
        quote.exports[0].latestS3VersionId
      );
      const pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;
      const pdfData = await new Response(pdfDataBlob).arrayBuffer();

      this.setState({ approvedPdfData: pdfData, hasApprovedPdf: true });
    } else {
      this.setState({ hasApprovedPdf: false });
    }
  };

  refreshQuotePreview = () => {
    this.setState({
      numberForPreviewRefresh: this.state.numberForPreviewRefresh + 1,
    });
  };

  getPotentialReviewers = () => {
    const { users, quote, apiUser } = this.props;

    let potentialReviewers = users.filter(
      (user) => !user.isDisabled && user.quoteReviewLimit && user.quoteReviewLimit > quote.total
    );
    if (!potentialReviewers.some((user) => user.id === apiUser.id)) {
      if (quote.author === apiUser.id && apiUser.quoteCreationLimit && apiUser.quoteCreationLimit >= quote.total) {
        potentialReviewers.unshift(apiUser);
      }
    }
    return potentialReviewers;
  };

  checkQueryParamsForAutoScroll = () => {
    const { history, location } = this.props;
    const urlParams = new URLSearchParams(window.location.search);

    if (urlParams.has("lineItem")) {
      const lineItemsCard = document.querySelector(".line-items-card");
      if (!lineItemsCard) {
        setTimeout(this.checkQueryParamsForAutoScroll, 100);
        return;
      }
      setTimeout(() => {
        lineItemsCard.scrollIntoView({ behavior: "smooth", block: "center" });

        urlParams.delete("lineItem");

        setTimeout(() => {
          history.replace(`${location.pathname}?${urlParams.toString()}`);
        }, 5000);
      }, 500);
    }
  };

  loadFile = async () => {
    const { quote } = this.props;

    if (!quote.fileKey) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("quote")} file found.`,
      });
      this.props.hidePreloader();
      return;
    }

    const filePublicURL = await getS3File(quote.fileKey.replace("public/", ""));
    const quoteFileData = (await axios.get(filePublicURL)).data;

    this.setState(
      {
        form: quoteFileData,
        formPreview: quoteFileData,
      },
      async () => {
        await computeHiddenFormFields.call(this);
        this.props.hidePreloader();
      }
    );
  };

  saveUserFields = async () => {
    const { form } = this.state;
    const { quote } = this.props;
    this.setState({
      isSaving: true,
      isSaveError: false,
      userHasChangedSomething: true,
    });
    try {
      await Storage.put(quote.fileKey.replace("public/", ""), JSON.stringify(form));

      callGraphQL(
        `Failed to update ${getLabel({
          id: "quote",
          defaultValue: "quote",
        })} "saved at" date`,
        graphqlOperation(updateQuote, {
          input: {
            id: quote.id,
            savedAt: new Date().toISOString(),
          },
        })
      );

      // this.loadEntirePdfVersions();
      this.setState({
        formPreview: form,
        savedAt: moment(),
        isSaving: false,
        dateTimeToCheckFormAgainst: moment().add(3, "seconds").toISOString(),
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to save {getSimpleLabel("quote")}:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      this.setState({ isSaving: false, isSaveError: true });
    }
  };

  isDisabled = (quote) => {
    if (
      quote.status === "REJECTED" ||
      quote.status === "ACCEPTED" ||
      quote.reviewStatus === "SUCCESS" ||
      quote.isArchived
    ) {
      return true;
    }

    return false;
  };

  uploadQuotePDF = async (dataUri, force = false) => {
    const { quote } = this.props;
    const { hasApprovedPdf, isWaitingForFirstDocumentAfterReviewApproval } = this.state;

    if (!force) {
      if (hasApprovedPdf || moment(quote.reviewApprovedAt).diff(moment(), "seconds") > 20) {
        this.setState({ hasUploadedPreview: true });
        return;
      }
    }

    if (
      !window.lambdaPdfInserts ||
      !window.lambdaPdfInserts[window.reportRenderCycle] ||
      Object.keys(window.lambdaPdfInserts[window.reportRenderCycle]).length === 0
    ) {
      setTimeout(() => this.uploadQuotePDF(dataUri), 500);
      return;
    }

    this.setState({ isUploadingPreview: true });

    let oldMetadata = JSON.parse(JSON.stringify(quote.metadata));
    oldMetadata?.inserts?.forEach((insert) => {
      for (let property in insert) {
        if (insert[property] === null) {
          delete insert[property];
        }
      }
    });

    let newMetadata = {
      inserts: processLambdaPdfInserts(window.lambdaPdfInserts[window.reportRenderCycle]),
      assets: window.lambdaPdfAssets,
      pageMapping: processReportPageMapping(window.reportPageMapping[window.reportRenderCycle]),
      pageNumbersToSkipBorders: window.lambdaPdfPageNumbersToSkipBorders,
    };

    if (!_.isEqual(oldMetadata, newMetadata)) {
      await callGraphQL(
        "Failed to save pdf attachment metadata",
        graphqlOperation(updateQuote, {
          input: {
            id: quote.id,
            metadata: newMetadata,
          },
        })
      );
    }

    const newBlob = await (await fetch(dataUri)).blob();

    this.setState({ isWaitingForFirstDocumentAfterReviewApproval: false });

    const quotePdfFile = new File([newBlob], "");
    const uploadParams = {
      Bucket: awsExports.aws_user_files_s3_bucket,
      Key: quote.fileKey.replace(".json", "_raw.pdf"),
      Body: quotePdfFile,
    };

    await window.s3.upload(uploadParams).promise();

    if (isWaitingForFirstDocumentAfterReviewApproval) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
      await callRest({
        route: "/annotate",
        method: "POST",
        body: {
          quoteId: quote.id,
          eventId: quote.id,
          organisation: quote.organisation,
          fileType: "QUOTE",
        },
        includeCredentials: false,
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      this.fetchApprovedVersion();
    }

    this.setState({ isUploadingPreview: false, hasUploadedPreview: true });
  };

  deleteQuote = async () => {
    const { quote, history, apiUser } = this.props;

    for (let quoteLineItem of quote.lineItems.items) {
      if (quoteLineItem.invoiceLineItems?.items.length > 0) {
        message.error(
          `Cannot delete a ${getSimpleLabel(
            "quote"
          )} that is linked to an invoice. Please archive it instead, or unlink it from the invoice.`,
          6
        );
        return;
      }
    }

    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: `Confirm delete ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}`,
          maskClosable: true,
          content: <>Are you sure you want to delete this {getLabel({ id: "quote", defaultValue: "quote" })}?</>,
          okText: "Delete",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQL(
      `Failed to delete ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}`,
      graphqlOperation(deleteQuote, {
        input: {
          id: quote.id,
        },
      })
    );

    await callGraphQLSimple({
      message: "Failed to record activity item",
      mutation: "createQuoteActivityItem",
      variables: {
        input: {
          quoteId: quote.id,
          total: quote.total,
          type: "DELETED",
          organisation: quote.organisation,
          author: apiUser.id,
        },
      },
    });

    history.push("/quotes");
  };

  onDataUri = async (dataUri) => {
    const { isWaitingForFirstDocumentAfterReviewApproval } = this.state;
    if (!isWaitingForFirstDocumentAfterReviewApproval && this.state.dataUri?.length === dataUri?.length) {
      return;
    }
    this.setState({ dataUri });
    this.uploadQuotePDF(dataUri);
  };

  addLineItem = async () => {
    const { quote, organisationDetails } = this.props;
    const { form } = this.state;
    let unitPrice = 0;
    let amount = 0;
    let defaultUnitPrice = parseInt(organisationDetails.settings?.quote?.defaultUnitPrice || 0);
    if (defaultUnitPrice) {
      unitPrice = defaultUnitPrice;
      amount = 1;
    }
    const lineItem = (
      await callGraphQL(
        "Failed to create line item",
        graphqlOperation(createQuoteLineItem, {
          input: {
            quoteId: quote.id,
            organisation: quote.organisation,
            title: "",
            description: "",
            quantity: 1,
            unitPrice,
            discountPercent: 0,
            taxRate: null,
            amount,
            authorityLevel: 1,
            checkPrice: 0,
            resultingTaskId: "nothing",
          },
        })
      )
    ).data.createQuoteLineItem;

    this.refreshQuote();

    if (amount > 0) {
      const updatedQuote = (
        await callGraphQL(
          `Failed to retrieve ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} details`,
          graphqlOperation(getQuote, {
            id: this.props.quote.id,
          })
        )
      ).data.getQuote;
      this.calculateAmounts({ quote: updatedQuote });
    }

    this.setState(
      {
        form: {
          ...form,
          lineItems: [...(form.lineItems || []), { id: lineItem.id }],
        },
      },
      this.debouncedSaveUserFields
    );
  };

  addPresetLineItem = async (item) => {
    const { form } = this.state;

    const lineItem = (
      await callGraphQL(
        "Failed to create line item",
        graphqlOperation(createQuoteLineItem, {
          input: {
            ...item,
            quoteId: this.props.quote.id,
            id: undefined,
            createdAt: undefined,
            updatedAt: undefined,
            resultingTaskId: "nothing",
            invoicedAmount: undefined,
            invoicingStatus: undefined,
            isRejected: undefined,
            manuallyInvoicedAmount: undefined,
            progressPercent: undefined,
            resultingPurchaseOrderId: undefined,
            isHourlyFullyInvoiced: undefined,
            invoiceLineItems: undefined,
          },
        })
      )
    ).data.createQuoteLineItem;

    this.refreshQuote();

    if (item.amount > 0) {
      const updatedQuote = (
        await callGraphQL(
          `Failed to retrieve ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} details`,
          graphqlOperation(getQuote, {
            id: this.props.quote.id,
          })
        )
      ).data.getQuote;
      this.calculateAmounts({ quote: updatedQuote });
    }

    message.success("Line item added");

    this.setState(
      {
        form: {
          ...form,
          lineItems: [...(form.lineItems || []), { id: lineItem.id }],
        },
      },
      this.debouncedSaveUserFields
    );
  };

  addLineItemToTask = async ({ taskId }) => {
    const { selectedLineItemId } = this.state;

    await this.changeLineItem({
      fieldName: "resultingTaskId",
      id: selectedLineItemId,
      value: taskId,
    });

    await this.refreshQuote();

    // update task financials
    // await calculateTaskFinancials(taskId);

    try {
      await callGraphQL(
        `Failed to update resulting ${getLabel({
          id: "task",
          defaultValue: "task",
        })}`,
        graphqlOperation(updateTask, {
          input: {
            id: taskId,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        })
      );
    } catch (e) {
      console.error(e);
    }

    this.setState({ isAddToTaskModalVisible: false });
  };

  addLineItemToPurchaseOrder = async ({ purchaseOrderId }) => {
    const { selectedLineItemId } = this.state;
    const { quote } = this.props;

    await this.changeLineItem({
      fieldName: "resultingPurchaseOrderId",
      id: selectedLineItemId,
      value: purchaseOrderId,
    });

    try {
      await callGraphQL(
        "Failed to update resulting purchase order",
        graphqlOperation(updatePurchaseOrder, {
          input: {
            id: purchaseOrderId,
            quoteId: quote.id,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        })
      );
    } catch (e) {
      console.error(e);
    }

    this.setState({ isAddQuoteLineItemToPurchaseOrderModalVisible: false });
  };

  onCreateTaskFinish = async (task) => {
    const { selectedLineItemId } = this.state;

    this.setState({
      selectedLineItemId: null,
      isCreateTaskModalVisible: false,
    });
    await callGraphQL(
      "Failed to update line item",
      graphqlOperation(updateQuoteLineItem, {
        input: {
          id: selectedLineItemId,
          resultingTaskId: task.id,
        },
      })
    );
    // update task financials

    this.refreshQuote();
  };

  changeDescription = async () => {
    const { quote } = this.props;
    let newDescription = this.state.description.toString("html");
    if (quote.description === newDescription || (quote.description === null && newDescription === "<p><br></p>")) {
      return;
    }

    this.changeAttribute({ fieldName: "description", value: newDescription });
  };

  recordActivityItemForAttributeChange({ fieldName, value, content }) {
    const { users, apiUser } = this.props;
    let quote = { ...this.props.quote, [fieldName]: value };
    let type;
    if (fieldName === "status") {
      if (value === "DRAFT") {
        type = "STATUS_CHANGED";
      } else {
        type = value;
      }

      recordActivityItem({
        quote,
        type,
        author: apiUser.id,
        users,
        content,
      });
    }
  }

  changeAttribute = async ({ fieldName, value, includeRecalculation, activityItemContent }) => {
    const { quote } = this.props;
    if (value === undefined) {
      value = null;
    }
    this.recordActivityItemForAttributeChange({
      fieldName,
      value,
      content: activityItemContent,
    });
    await callGraphQL(
      `Failed to update ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}`,
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          [fieldName]: value,
        },
      })
    );
    let updatedQuote = quote;
    if (includeRecalculation || fieldName === "status") {
      updatedQuote = (
        await callGraphQL(
          `Failed to retrieve ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} details`,
          graphqlOperation(getQuote, {
            id: this.props.quote.id,
          })
        )
      ).data.getQuote;
    }
    if (includeRecalculation) {
      this.calculateAmounts({ quote: updatedQuote });
    }
    if (fieldName === "status") {
      this.onStatusUpdate(updatedQuote);
    }
  };

  onStatusUpdate = async (updatedQuote) => {
    let updateTaskPromises;
    switch (updatedQuote.status) {
      case "ACCEPTED":
        updateTaskPromises = updatedQuote.lineItems.items
          .filter((lineItem) => lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing")
          .map((lineItem) => {
            return callGraphQL(
              `Failed to update ${getLabel({
                id: "task",
                defaultValue: "task",
              })}`,
              graphqlOperation(updateTask, {
                input: {
                  id: lineItem.resultingTaskId,
                  isConfirmed: true,
                  isArchived: false,
                },
              })
            );
          });
        await Promise.all(updateTaskPromises);
        break;

      default:
        updateTaskPromises = updatedQuote.lineItems.items
          .filter((lineItem) => lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing")
          .map((lineItem) => {
            return callGraphQL(
              `Failed to update ${getLabel({
                id: "task",
                defaultValue: "task",
              })}`,
              graphqlOperation(updateTask, {
                input: {
                  id: lineItem.resultingTaskId,
                  isConfirmed: false,
                },
              })
            );
          });
        break;
    }
  };

  changeLineItem = async ({ fieldName, id, value, includeRecalculation }) => {
    const { quote, apiUser } = this.props;
    let lineItem = quote.lineItems.items.find((lineItem) => lineItem.id === id);

    if (includeRecalculation) {
      await this.calculateAmounts({ targetLineItem: lineItem, fieldNameToChange: fieldName, fieldValue: value });
      if (lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing") {
        await callGraphQL(
          `Failed to update ${getLabel({
            id: "task",
            defaultValue: "task",
          })}`,
          graphqlOperation(updateTask, {
            input: {
              id: lineItem.resultingTaskId,
              itemSubscription: Math.floor(Math.random() * 100000),
            },
          })
        );
      }

      if (fieldName === "isRejected") {
        await callGraphQLSimple({
          message: "Failed to record activity item",
          mutation: "createQuoteActivityItem",
          variables: {
            input: {
              quoteId: quote.id,
              type: value ? "QUOTE_LINE_ITEM_REJECTED" : "QUOTE_LINE_ITEM_ACCEPTED",
              content: JSON.stringify({
                quoteLineItemId: id,
                quoteLineItemTitle: lineItem.title,
              }),
              organisation: quote.organisation,
              author: apiUser.id,
            },
          },
        });
        this.refreshQuote();
      }
    } else {
      if (lineItem[fieldName] === value) {
        // if the value hasn't actually changed, don't do anything
        // this would happen if the user clicks on a field, then clicks away without changing anything
        return;
      }

      await callGraphQL(
        "Failed to update line item",
        graphqlOperation(updateQuoteLineItem, {
          input: {
            id,
            [fieldName]: value,
          },
        })
      );

      await this.reloadQuote();
    }
  };

  changeLineItemCustomFieldsOldWay = async ({ fieldName, id, value }) => {
    const { form } = this.state;
    const updatedJsonLineItems = JSON.parse(JSON.stringify(form.lineItems));
    updatedJsonLineItems.forEach((lineItem) => {
      if (lineItem.id !== id) {
        return;
      }
      lineItem[fieldName] = value;
    });
    this.setState(
      {
        form: {
          ...form,
          lineItems: updatedJsonLineItems,
        },
      },
      this.debouncedSaveUserFields
    );
  };

  calculateAmounts = async ({ targetLineItem, quote, fieldNameToChange, fieldValue }) => {
    const { apiUser, users, clients } = this.props;

    let lineItems = [{ ...targetLineItem, [fieldNameToChange]: fieldValue }];
    if (!targetLineItem) {
      lineItems = (quote || this.props.quote).lineItems.items;
    }

    const updateLineItemPromises = [];
    for (let i = 0; i < lineItems.length; i++) {
      const lineItem = lineItems[i];

      const itemPreTaxAmount = lineItem.isHourly
        ? 0
        : lineItem.quantity * (lineItem.unitPrice + (lineItem.checkPrice || 0));
      const taxRate = this.props.quote.taxRate;
      const itemTaxAmount = itemPreTaxAmount * (taxRate / 100);

      let extraFieldsForApi = {};
      if (lineItem.id === targetLineItem?.id) {
        extraFieldsForApi[fieldNameToChange] = fieldValue;
      }

      updateLineItemPromises.push(
        callGraphQL(
          "Failed to update line item",
          graphqlOperation(updateQuoteLineItem, {
            input: {
              id: lineItem.id,
              unitPrice: lineItem.isHourly ? 0 : lineItem.unitPrice,
              amount: itemPreTaxAmount,
              taxAmount: itemTaxAmount,
              ...extraFieldsForApi,
            },
          })
        )
      );
    }

    await Promise.all(updateLineItemPromises);

    const updatedQuote = (
      await callGraphQL(
        `Failed to retrieve ${getLabel({
          id: "quote",
          defaultValue: "quote",
        })} details`,
        graphqlOperation(getQuote, {
          id: this.props.quote.id,
        })
      )
    ).data.getQuote;

    let subtotal = 0;
    let totalTax = 0;
    let total = 0;
    updatedQuote.lineItems.items.forEach((item) => {
      if (item.isRejected) {
        return;
      }
      if (!item.isHourly) {
        subtotal += item.amount;
        totalTax += item.taxAmount;
      }
    });
    total = subtotal + totalTax;

    const quoteAfterLastUpdate = (
      await callGraphQL(
        `Failed to update ${getLabel({
          id: "quote",
          defaultValue: "quote",
        })}`,
        graphqlOperation(updateQuote, {
          input: {
            id: updatedQuote.id,
            subtotal,
            totalTax,
            total,
          },
        })
      )
    ).data.updateQuote;

    await recordActivityItem({
      quote: quoteAfterLastUpdate,
      type: "TOTAL_CHANGED",
      author: apiUser.id,
      clients,
      users,
      includeRefresh: false,
    });

    await this.reloadQuote();
  };

  refreshQuote = async () => {
    const { quote } = this.props;
    await callGraphQL(
      `Failed to update ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}`,
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          itemSubscription: Math.floor(Math.random() * 100000),
        },
      })
    );
  };

  reloadQuote = async () => {
    await this.props.fetchAndSetQuote({ id: this.props.quote.id });
    await new Promise((resolve) => setTimeout(resolve, 200)); // just to give it some time to actually re-render
  };

  setEditorFocus = () => {
    let editorElement = document.querySelector(".public-DraftEditor-content");
    if (!editorElement || !editorElement.isContentEditable) {
      setTimeout(this.setEditorFocus, 100);
      return;
    }

    editorElement.focus();
  };

  displayInputLabel = ({ fieldData, fieldName }) => {
    return (
      <Typography.Paragraph className="field-label">
        <Typography.Text className="field-name">{fieldData.label ? fieldData.label : fieldName}</Typography.Text>{" "}
      </Typography.Paragraph>
    );
  };

  displayQuoteFields = () => {
    return (
      <Card
        title={`${getLabel({ id: "Quote", defaultValue: "Quote" })} details`}
        className="quote-fields-card"
        withSpace
      >
        {displayFields.call(this, { showHiddenByModal: false })}
      </Card>
    );
  };

  downloadQuote = async () => {
    const { quote } = this.props;
    this.setState({ isDownloadingQuote: true });
    try {
      if (!quote.reviewApprovedAt) {
        await this.uploadQuotePDF(this.state.dataUri, true);
      }

      if (!quote.reviewApprovedAt || !quote.exports || quote.exports.length === 0) {
        await callRest({
          route: "/annotate",
          method: "POST",
          body: {
            eventId: quote.id,
            quoteId: quote.id,
            fileType: "QUOTE",
            organisation: quote.organisation,
          },
          includeCredentials: false,
        });
      }
      const updatedQuote = (
        await window.callGraphQLSimple({
          message: `Failed to fetch ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} details`,
          queryCustom: "getQuote",
          variables: {
            id: quote.id,
          },
        })
      ).data.getQuote;
      await this.downloadPDF(
        updatedQuote.exports[0].key,
        updatedQuote.exports[0].latestS3VersionId,
        null,
        updatedQuote.currentRevisionName
      );
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to download PDF:
            <br />
            {e.response?.data?.error || e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
    this.setState({ isDownloadingQuote: false });
  };

  archiveQuote = async () => {
    const { quote } = this.props;
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: `Confirm archive ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}`,
          maskClosable: true,
          content: <>Are you sure you want to archive this {getLabel({ id: "quote", defaultValue: "quote" })}?</>,
          okText: "Archive",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQL(
      `Failed to archive ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}}`,
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          isArchived: true,
        },
      })
    );
  };

  restoreQuote = async () => {
    const { quote } = this.props;
    try {
      await new Promise((resolve, reject) => {
        Modal.confirm({
          title: `Confirm restore ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })}`,
          maskClosable: true,
          content: <>Are you sure you want to restore this {getLabel({ id: "quote", defaultValue: "quote" })}?</>,
          okText: "Restore",
          onOk: () => {
            resolve();
          },
          onCancel: () => {
            reject();
          },
        });
      });
    } catch (e) {
      // nothing, it just means the user selected "cancel"
      return;
    }

    await callGraphQL(
      `Failed to restore ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })}}`,
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          isArchived: false,
        },
      })
    );
  };

  checkQuoteCanBeSent = async () => {
    const { quote, clients } = this.props;

    if (quote.reviewStatus !== "SUCCESS") {
      Modal.info({
        title: "Approval required",
        maskClosable: true,
        content: (
          <>Before you can send this {getLabel({ id: "quote", defaultValue: "quote" })}, it must first be reviewed.</>
        ),
      });
      return;
    }
    if (!quote.assignedTo) {
      Modal.info({
        title: "Assignee required",
        maskClosable: true,
        content: (
          <>
            Before you can send this {getLabel({ id: "quote", defaultValue: "quote" })}, you must first assign it to a
            user.
          </>
        ),
      });
      return;
    }

    const client = clients.find((x) => x.id === quote.clientId);
    const clientContact = client.contacts?.find((x) => x.id === quote.clientContact);
    const clientAddress = client.addresses?.find((x) => x.id === quote.clientAddress);

    if (!clientContact) {
      Modal.info({
        title: "Contact required",
        maskClosable: true,
        content: (
          <>
            Before you can send this {getLabel({ id: "quote", defaultValue: "quote" })}, you must first assign it to a
            {getSimpleLabel("client")} contact.
          </>
        ),
      });
      return;
    }

    if (!clientAddress) {
      Modal.info({
        title: "Address required",
        maskClosable: true,
        content: (
          <>
            Before you can send this {getLabel({ id: "quote", defaultValue: "quote" })}, you must first assign it to a
            {getSimpleLabel("client")} address.
          </>
        ),
      });
      return;
    }

    if (!clientContact) {
      Modal.error({
        title: "No contact",
        maskClosable: true,
        content: (
          <>
            The {getSimpleLabel("client")} contact {quote.clientContact} does not exist anymore.
          </>
        ),
      });
      return;
    }

    if (!clientContact.email) {
      Modal.error({
        title: "Contact has no email",
        maskClosable: true,
        content: (
          <>
            {getSimpleLabel("Quote")} cannot be sent to a {getSimpleLabel("client")} contact without an email address.
          </>
        ),
      });
      return;
    }

    if (!clientContact.firstName || !clientContact.lastName) {
      try {
        await new Promise((resolve, reject) => {
          let message;
          if (!clientContact.firstName && !clientContact.lastName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{quote.clientContact}</b> does not either a first or a last
                name. Do you want to continue?
              </>
            );
          } else if (!clientContact.firstName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{quote.clientContact}</b> does not have a first name. Do you
                want to continue?
              </>
            );
          } else if (!clientContact.lastName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{quote.clientContact}</b> does not have a last name. Do you
                want to continue?
              </>
            );
          }
          Modal.confirm({
            title: "Missing contact details",
            maskClosable: true,
            content: message,
            onOk: () => resolve(),
            onCancel: () => reject(),
          });
        });
      } catch (e) {
        // user chose to cancel
        return;
      }
    }

    this.setState({ isSendQuoteModalVisible: true });
  };

  downloadPDF = async (fileKey, versionId, readableDate, currentRevisionName) => {
    try {
      const publicUrl = await getS3File(fileKey.replace("public/", ""), versionId);
      await this.downloadEntirePdfFromPublicUrl(publicUrl, fileKey, readableDate, currentRevisionName);
    } catch (e) {
      console.error("Error downloading PDF:", e);
      notification.error({
        message: "Could not download PDF",
      });
    }
  };

  downloadEntirePdfFromPublicUrl = async (publicUrl, fileKey, readableDate, currentRevisionName) => {
    const fileBlob = (
      await axios({
        url: publicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    let fileName = fileKey.split("/").slice(-1)[0];

    if (currentRevisionName) {
      fileName = fileName.replace(".pdf", ` ${currentRevisionName}.pdf`);
    }

    await downloadBlob({
      blob: fileBlob,
      fileName,
    });
  };

  getPredefinedCreateTaskModalFields = () => {
    const { organisationDetails, quote } = this.props;
    const lineItem = quote.lineItems.items.find((item) => item.id === this.state.selectedLineItemId);

    let title = "";
    let subtitle = "";

    if (organisationDetails.settings?.quote?.tasksCreatedFromQuoteDefaultToQuoteTitle) {
      title = quote.title || "";
    } else {
      title = lineItem.title || `${getSimpleLabel("Task")} title`;
      subtitle = lineItem.description;
    }

    let estimatedHours;
    if (organisationDetails.settings?.quote?.tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount) {
      estimatedHours = lineItem.amount;
    }

    return {
      title,
      initials: organisationDetails.usesTaskInitials
        ? lineItem.title
            .split(" ")
            .filter((x) => x.length > 2)
            .slice(0, 3)
            .map((x) => x[0].toUpperCase())
            .join("")
        : undefined,
      clientId: quote.clientId,
      projectId: quote.projectId,
      subtitle,
      catLevel: lineItem.authorityLevel,
      isConfirmed: quote.status === "ACCEPTED",
      checkPrice: lineItem.checkPrice,
      budget: lineItem.amount,
      estimatedHours,
    };
  };

  getPredefinedCreatePurchaseOrderModalFields = () => {
    const { quote } = this.props;
    const lineItem = quote.lineItems.items.find((item) => item.id === this.state.selectedLineItemId);
    return {
      title: lineItem.title || `${getSimpleLabel("Purchase order")} title`,
      initials: lineItem.title
        .split(" ")
        .filter((x) => x.length > 2)
        .slice(0, 3)
        .map((x) => x[0].toUpperCase())
        .join(""),
      clientId: quote.clientId,
      projectId: quote.projectId,
    };
  };

  openCommentBox = (name, newCommentLineItemIndex) => {
    this.setState({
      hasNewComment: true,
      newCommentFieldName: name,
      newCommentLineItemIndex,
      // areCommentsVisible: true,
    });
  };

  recordCommentActivityItem = async ({ content, actionType }) => {
    const { quote, apiUser, clients, users } = this.props;

    let actionTypeReadable = `${actionType.toLowerCase()}d`;
    if (actionType === "EDIT") {
      actionTypeReadable = "edited";
    }

    await recordActivityItem({
      quote,
      type: "REVIEW_ACTIVITY",
      author: apiUser.id,
      clients,
      users,
      content: `${apiUser.firstName} ${apiUser.lastName} ${actionTypeReadable} a comment: \n"${content}"`,
    });
  };

  displayApprovedVersion = () => {
    const { quote, windowWidth, windowHeight } = this.props;
    const { approvedPdfData } = this.state;

    if (!approvedPdfData) {
      return null;
    }

    return (
      <DocumentDetailsModal
        open={true}
        attachment={{
          name: quote.id,
          lastModified: quote.reviewApprovedAt,
          key: quote.exports[0].key,
          type: "PDF",
        }}
        versionId={quote.exports[0].latestS3VersionId}
        document={approvedPdfData}
        onClose={() => this.setState({ isApprovedPdfVisible: false })}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
      />
    );
  };

  displayLiveVersion = () => {
    const { apiUser, quote, users, clients, projects, organisationDetails } = this.props;
    const { form } = this.state;

    const { savedAt, updatedAt, ...quoteForPreview } = quote;

    return (
      <QuoteDocument
        apiUser={apiUser}
        quote={quoteForPreview}
        users={users}
        clients={clients}
        projects={projects}
        organisationDetails={organisationDetails}
        form={form}
        formPreview={this.state.formPreview}
        onDataUri={this.onDataUri}
        projectFolder={this.state.projectFolder}
        setIsLoading={this.props.setIsLoading}
        numberForPreviewRefresh={this.state.numberForPreviewRefresh}
      />
    );
  };

  displayQuoteActions = () => {
    const { quote, apiUser, users, clients } = this.props;
    const { areCommentsVisible, isPreviewEnabled } = this.state;

    const review = quote.reviews?.items[0];

    const clientDetails = clients.find((client) => client.id === quote.clientId);

    return (
      <QuoteActions
        quote={quote}
        client={clientDetails}
        users={users}
        apiUser={apiUser}
        updateFees={this.updateFees}
        review={review}
        hasUploadedPreview={this.state.hasUploadedPreview}
        isPreviewEnabled={isPreviewEnabled}
        isDownloadingQuote={this.state.isDownloadingQuote}
        isSendingQuote={this.state.isSendingQuote}
        hasApprovedPdf={this.state.hasApprovedPdf}
        showApprovedPdf={() => this.setState({ isApprovedPdfVisible: true })}
        reloadQuote={this.reloadQuote}
        onPreviewSwitch={(checked) => {
          this.setState({ isPreviewEnabled: checked });
          cookie.set(COOKIE_NAME_QUOTE_PREVIEW, checked ? "true" : "false", {
            expires: 99999,
          });
        }}
        onRequestReviewClick={() => {
          this.setState({ isRequestReviewModalVisible: true });
        }}
        archiveQuote={this.archiveQuote}
        restoreQuote={this.restoreQuote}
        downloadQuote={this.downloadQuote}
        sendQuote={this.checkQuoteCanBeSent}
        windowWidth={this.props.windowWidth}
        deleteQuote={this.deleteQuote}
        areCommentsVisible={areCommentsVisible}
        onCommentsSwitch={(checked) => {
          this.setState({ areCommentsVisible: checked });
          cookie.set(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE, checked ? "true" : "false", { expires: 99999 });
        }}
        history={this.props.history}
      />
    );
  };

  updateFees = async () => {
    const { organisationDetails, quote } = this.props;
    await callGraphQL(
      `Failed to update ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} fees`,
      graphqlOperation(updateQuote, {
        input: {
          id: quote.id,
          defaultFees: organisationDetails.defaultFees,
          savedAt: new Date().toISOString(),
        },
      })
    );
  };

  displayAlerts = () => {
    const { quote } = this.props;
    let alerts = [];

    if (quote.isArchived) {
      alerts.push(
        <Alert
          ley="archived"
          showIcon
          className="quote-archive-alert"
          message={`This ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} is archived`}
          type="info"
        />
      );
    }

    if (this.state.sendStatus === "SUCCESS") {
      alerts.push(
        <Alert
          key="sent-success"
          showIcon
          className="send-status-alert"
          message="Quote sent successfully"
          type="success"
        />
      );
    }

    if (this.state.sendStatus === "ERROR") {
      alerts.push(
        <Alert
          key="sent-error"
          showIcon
          className="send-status-alert"
          message={`${getLabel({
            id: "Quote",
            defaultValue: "Quote",
          })} failed to send`}
          type="error"
        />
      );
    }

    if (quote.status === "ACCEPTED") {
      alerts.push(
        <Alert
          key="accepted"
          showIcon
          className="accepted-status-alert"
          message={`This ${getLabel({
            id: "quote",
            defaultValue: "quote",
          })} has been accepted by the client. While in this state, you cannot make any changes to it.`}
          type="info"
        />
      );
    }

    if (quote.reviewStatus === "SUCCESS") {
      alerts.push(
        <Alert
          key="review-success"
          showIcon
          className="accepted-status-alert"
          message={
            <>
              The review for this{" "}
              {getLabel({
                id: "quote",
                defaultValue: "quote",
              })}{" "}
              has been approved. If you want to make changes to it, please cancel the approval.
            </>
          }
          type="info"
        />
      );
    }
    return alerts;
  };

  displayTotals = () => {
    const { organisationDetails, quote } = this.props;
    if (organisationDetails.settings?.general?.hideFinancials && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      return null;
    }

    const formattedSubtotal = window.formatCurrency("GBP", quote.subtotal);
    const formattedTotalTax = window.formatCurrency("GBP", quote.totalTax);
    const formattedTotal = window.formatCurrency("GBP", quote.total);

    return (
      <div className="total-container">
        <div className="total-inner-container">
          <div className="total-item">
            <Typography.Text className="label">Subtotal </Typography.Text>
            <Typography.Text className="value" data-cy="subtotal">
              {formattedSubtotal}{" "}
            </Typography.Text>
          </div>
          <div className="total-item">
            <Typography.Text className="label">Total VAT {quote.taxRate}% </Typography.Text>
            <Typography.Text className="value" data-cy="total-tax">
              {formattedTotalTax}{" "}
            </Typography.Text>
          </div>
          <div className="total-item grand-total-item">
            <Typography.Text className="label">Total </Typography.Text>
            <Typography.Text className="value" data-cy="total">
              {formattedTotal}{" "}
            </Typography.Text>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { quote, apiUser, users, clients, organisationDetails } = this.props;
    const {
      description,
      form,
      isCreateTaskModalVisible,
      selectedLineItemId,
      isAddToTaskModalVisible,
      isAddQuoteLineItemToPurchaseOrderModalVisible,
      areCommentsVisible,
      hasNewComment,
      isApprovedPdfVisible,
      isPreviewEnabled,
      isWaitingForFirstDocumentAfterReviewApproval,
      isCorrupted,
      corruptedReason,
      templateDetails,
      isAddItemFromListModalOpen,
    } = this.state;

    if (isCorrupted) {
      return (
        <div className={cx("quote-details-page")}>
          <div className="corrupted-message-container">
            <Typography.Text className="corrupted-title">
              It looks like this {getSimpleLabel("quote")} is corrupted.
            </Typography.Text>
            <Typography.Text className="corrupted-explanation">Reason: {corruptedReason}</Typography.Text>
            <Button type="primary" icon={<DeleteOutlined />} onClick={() => this.deleteQuote()}>
              Delete {getSimpleLabel("quote")}
            </Button>
          </div>
        </div>
      );
    }

    let activeStatusClassName = "";
    if (!description || !form) {
      return null;
    }
    const review = quote.reviews?.items[0];

    let weHaveComments = false;
    if (review) {
      weHaveComments =
        hasNewComment || review.reviewThread.filter((item) => item.type === "COMMENT" && !item.resolved).length > 0;
    }

    const clientDetails = clients.find((client) => client.id === quote.clientId);

    const sortedClientContacts = (clientDetails.contacts || []).sort((a, b) => {
      if (a?.id < b?.id) return -1;
      if (a?.id > b?.id) return 1;
      return 0;
    });

    let hasPreview = isPreviewEnabled && (isWaitingForFirstDocumentAfterReviewApproval || !quote.reviewApprovedAt);

    if (this.props.organisationDetails.settings?.quote?.disablePreview && !isAuthorised(["QUOTES.WRITE_AMOUNT"])) {
      hasPreview = false;
    }

    return (
      <div
        className={cx("quote-details-page", {
          "with-preview": hasPreview,
          "with-comments": review && areCommentsVisible && weHaveComments,
          "is-archived": quote.isArchived,
        })}
      >
        {isAddToTaskModalVisible && (
          <AddToTaskModal
            visible={true}
            onClose={() => this.setState({ isAddToTaskModalVisible: false })}
            apiUser={apiUser}
            projectId={quote.projectId}
            onSubmit={this.addLineItemToTask}
            predefinedCreateTaskFields={this.getPredefinedCreateTaskModalFields()}
            entityName={`${getSimpleLabel("quote")} line item`}
          />
        )}
        {isApprovedPdfVisible && this.displayApprovedVersion()}
        {isAddQuoteLineItemToPurchaseOrderModalVisible && (
          <AddQuoteLineItemToPurchaseOrderModal
            visible={true}
            onClose={() =>
              this.setState({
                isAddQuoteLineItemToPurchaseOrderModalVisible: false,
              })
            }
            apiUser={apiUser}
            projectId={quote.projectId}
            onSubmit={this.addLineItemToPurchaseOrder}
            predefinedCreatePurchaseOrderFields={this.getPredefinedCreatePurchaseOrderModalFields()}
          />
        )}
        {displayModalContainingFields.call(this)}
        <div className="user-input-container">
          {this.displayQuoteActions()}

          <div className="user-input-scroll-container">
            {this.displayAlerts()}

            <div className="reviewable-content">
              {review && (
                <QuoteReviewSummary
                  quote={quote}
                  apiUser={apiUser}
                  users={users}
                  clients={clients}
                  potentialReviewers={this.getPotentialReviewers()}
                  dateTimeToCheckFormAgainst={this.state.dateTimeToCheckFormAgainst}
                  onSubmitReviewStart={() => {
                    this.setState({
                      hasApprovedPdf: false,
                    });
                  }}
                />
              )}
              <div className="quote-metadata-wrapper">
                <QuoteMetadata
                  {...this}
                  {...this.props}
                  apiUser={apiUser}
                  users={users}
                  activeStatusClassName={activeStatusClassName}
                  quote={quote}
                  organisationDetails={organisationDetails}
                  clientDetails={clientDetails}
                  sortedClientContacts={sortedClientContacts}
                  changeAttribute={this.changeAttribute}
                  debouncedChangeAttribute={this.debouncedChangeAttribute}
                  addLineItemChangeToQueue={this.addLineItemChangeToQueue}
                />
                {quote.status === "REJECTED" && <QuoteRejectionDetails {...this.props} quote={quote} />}
              </div>

              <Card
                data-cy="quote-details-summary"
                withSpace
                className={cx("quote-summary", activeStatusClassName)}
                title={
                  <>
                    <div className="title-and-actions">
                      <div className="title-container">
                        <Typography.Paragraph className="quote-description-label">Title:</Typography.Paragraph>
                        <ReviewTarget name="quoteTitle" {...this} {...this.props} visible={quote.isUnderReview}>
                          <Input
                            data-cy="quote-details-title"
                            defaultValue={quote.title}
                            disabled={this.isDisabled(quote)}
                            className="quote-title"
                            fireOnChangeWithoutBlurWithDebounce
                            debounceDelay={2000}
                            onChange={(value) =>
                              this.changeAttribute({
                                fieldName: "title",
                                value,
                              })
                            }
                            fullWidth
                            showBorder
                            allowEnter={false}
                          />
                        </ReviewTarget>
                      </div>
                    </div>

                    <Typography.Paragraph className="quote-description-label">Description:</Typography.Paragraph>

                    <ReviewTarget name="quoteDescription" {...this} {...this.props} visible={quote.isUnderReview}>
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          this.setEditorFocus();
                        }}
                        onMouseDown={(e) => {
                          e.stopPropagation();
                        }}
                        onMouseUp={(e) => {
                          e.stopPropagation();
                        }}
                      >
                        {this.state.description && (
                          <RichTextEditor
                            value={this.state.description}
                            autoFocus={false}
                            disabled={this.isDisabled(quote)}
                            onChange={(value) => {
                              this.setState({ description: value });
                              this.debouncedChangeDescription();
                            }}
                            className="quote-description"
                            toolbarConfig={toolbarConfig}
                          />
                        )}
                      </div>
                    </ReviewTarget>
                  </>
                }
              />
              {this.displayQuoteFields()}
              <Card title="Line items" className="line-items-card" data-cy="line-items-card" withSpace>
                <div className="create-line-item-buttons">
                  <Button
                    icon={<PlusCircleOutlined />}
                    onClick={this.addLineItem}
                    disabled={this.isDisabled(quote)}
                    type="primary"
                    className="add-new-line-item-button"
                    data-cy="add-new-line-item-button"
                  >
                    Create new line item
                  </Button>
                  {!!templateDetails.key && (
                    <Button
                      icon={<PlusCircleOutlined />}
                      onClick={() => {
                        this.setState({ isAddItemFromListModalOpen: true });
                      }}
                      disabled={this.isDisabled(quote)}
                      type="primary"
                      className="add-new-line-item-from-presets-button"
                      data-cy="add-new-line-item-from-presets-button"
                    >
                      Add preset line item
                    </Button>
                  )}
                  {!!templateDetails.key && isAuthorised(["MANAGE_TEMPLATES"]) && (
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        this.setState({
                          isManageItemListModalOpen: true,
                        });
                      }}
                    >
                      Manage presets
                    </Button>
                  )}
                </div>
                <QuoteLineItemsTable
                  quoteDetailsPageThis={this}
                  quote={quote}
                  addLineItemChangeToQueue={this.addLineItemChangeToQueue}
                  quoteIsDisabled={this.isDisabled(quote)}
                  organisationDetails={organisationDetails}
                  quoteProps={this.props}
                  changeLineItem={this.changeLineItem}
                  isUnderReview={this.state.isUnderReview}
                  refreshQuote={this.refreshQuote}
                  form={form}
                  templateDetails={templateDetails}
                  isCreatingTaskFromLineItemId={this.state.isCreatingTaskFromLineItemId}
                  isCreatingPurchaseOrderFromLineItemId={this.state.isCreatingPurchaseOrderFromLineItemId}
                  changeLineItemCustomFieldsOldWay={this.changeLineItemCustomFieldsOldWay}
                  calculateAmounts={this.calculateAmounts}
                  numberForPreviewRefresh={this.state.numberForPreviewRefresh}
                  windowWidth={this.props.windowWidth}
                  apiUser={apiUser}
                />
                {this.displayTotals()}
              </Card>
              <QuoteActivity quote={quote} users={users} organisationDetails={organisationDetails} apiUser={apiUser} />
            </div>
          </div>
        </div>
        {areCommentsVisible && review && weHaveComments && (
          <DocumentReview
            parent={quote}
            parentType="quote"
            {...this}
            {...this.props}
            form={form}
            hasNewComment={this.state.hasNewComment}
            newCommentFieldName={this.state.newCommentFieldName}
            newCommentLineItemIndex={this.state.newCommentLineItemIndex}
            onNewCommentClose={() => {
              this.setState({
                hasNewComment: false,
                newCommentFieldName: null,
              });
            }}
            recordCommentActivityItem={this.recordCommentActivityItem}
          />
        )}
        {(this.state.isWaitingForFirstDocumentAfterReviewApproval || !quote.reviewApprovedAt) &&
          (!this.props.organisationDetails.settings?.quote?.disablePreview ||
            isAuthorised(["QUOTES.WRITE_AMOUNT"])) && (
            <div
              className={cx("pdf-preview-container", {
                enabled: this.state.isPreviewEnabled,
              })}
            >
              {this.displayLiveVersion()}
            </div>
          )}
        {displayInsertAttachmentPickerModal.call(this)}
        {displayInsertAttachmentModal.call(this)}
        {displayReportUserListModal.call(this)}
        {isCreateTaskModalVisible && selectedLineItemId && (
          <CreateTaskModal
            onClose={() =>
              this.setState({
                isCreateTaskModalVisible: false,
                selectedLineItemId: null,
              })
            }
            apiUser={apiUser}
            predefinedFields={this.getPredefinedCreateTaskModalFields()}
            onSave={this.onCreateTaskFinish}
          />
        )}
        {this.state.isRequestReviewModalVisible && (
          <RequestQuoteReviewModal
            quote={quote}
            users={users}
            clients={clients}
            apiUser={apiUser}
            onClose={() => this.setState({ isRequestReviewModalVisible: false }, this.reloadQuote)}
            potentialReviewers={this.getPotentialReviewers()}
          />
        )}
        {this.state.isSendQuoteModalVisible && (
          <SendQuoteModal
            quote={quote}
            form={form}
            onClose={() => this.setState({ isSendQuoteModalVisible: false })}
            setSendStatus={(sendStatus) => this.setState({ sendStatus })}
            changeQuoteAttribute={this.changeAttribute}
            approvedPdfData={this.state.approvedPdfData}
            organisationDetails={organisationDetails}
          />
        )}
        {isAddItemFromListModalOpen && (
          <ModalAddItemFromList
            onSubmit={this.addPresetLineItem}
            onClose={() => {
              this.setState({ isAddItemFromListModalOpen: false });
            }}
            templateDetails={templateDetails}
            fieldName="lineItems"
          />
        )}
        {this.state.isManageItemListModalOpen && (
          <ModalManageItemList
            onClose={() => {
              this.setState({ isManageItemListModalOpen: false });
            }}
            templateDetails={templateDetails}
            fieldName={"lineItems"}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: QuoteDetailsPage,
    subscriptions: ["quote", "tasks", "projects", "clients", "quotes", "users", "organisationDetails", "sprints"],
  })
);
