import { Table } from "antd";
import moment from "moment";

import Avatar from "Avatar/Avatar";

import "./HolidayConflicts.scss";

export default function HolidayConflicts({ holidays, users, targetHoliday, firstTableTitle, secondTableTitle }) {
  let conflictingHolidays = [];

  if (!targetHoliday.startsAt || !targetHoliday.endsAt || !targetHoliday.days) {
    return null;
  }

  holidays.forEach((holiday) => {
    if (holiday.id === targetHoliday.id) {
      return;
    }
    if (targetHoliday.startsAt <= holiday.endsAt && targetHoliday.endsAt >= holiday.startsAt) {
      conflictingHolidays.push(holiday);
    }
  });

  return (
    <div className="holiday-conflicts-container">
      <h4 className="holiday-conflicts-title"> {firstTableTitle}</h4>
      <Table
        pagination={{ pageSize: 100, hideOnSinglePage: true }}
        columns={[
          {
            title: "User",
            key: "user",
            align: "left",
            render: (_, holiday) => <Avatar user={users.find((x) => x.id === holiday.userId)} showLabel />,
          },
          {
            title: "Requested on",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.createdAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Starts on",
            key: "startsAt",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.startsAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Ends on",
            key: "endsAt",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.endsAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Conflicting days",
            key: "conflicting days",
            align: "center",
            render: (_, holiday) => {
              let count = 0;
              holiday.days.forEach((holidayDay) => {
                targetHoliday.days.forEach((targetHolidayDay) => {
                  if (holidayDay.day === targetHolidayDay.day) count++;
                });
              });
              return count;
            },
          },
        ]}
        dataSource={conflictingHolidays.filter((x) => x.status !== "REJECTED" && x.status !== "APPROVED")}
      ></Table>
      <br />
      <h4 className="holiday-conflicts-title">{secondTableTitle}</h4>
      <Table
        pagination={{ pageSize: 100, hideOnSinglePage: true }}
        columns={[
          {
            title: "User",
            key: "user",
            align: "left",
            render: (_, holiday) => <Avatar user={users.find((x) => x.id === holiday.userId)} showLabel />,
          },
          {
            title: "Requested on",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.createdAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Starts on",
            key: "startsAt",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.startsAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Ends on",
            key: "endsAt",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.endsAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Conflicting days",
            key: "conflicting days",
            align: "center",
            render: (_, holiday) => {
              let count = 0;
              holiday.days.forEach((holidayDay) => {
                targetHoliday.days.forEach((targetHolidayDay) => {
                  if (holidayDay.day === targetHolidayDay.day) count++;
                });
              });
              return count;
            },
          },
        ]}
        dataSource={conflictingHolidays.filter((x) => x.status === "APPROVED")}
      ></Table>
    </div>
  );
}
