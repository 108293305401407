import moment from "moment";
import { message, Button, Progress } from "antd";
import Card from "Card/Card";
import InfoItem from "InfoItem/InfoItem";
import { getReadableStatus } from "common/helpers";
import { callRest } from "common/apiHelpers";
import { callGraphQLSimple } from "common/apiHelpers";

import "./OrganisationActions.scss";

type Props = {
  organisationDetails: any;
};

export default function OrganisationActions({ organisationDetails }: Props) {
  async function startFinancialsRecalculation() {
    const messageKey = "financials-recalculation";
    message.loading({
      content: "Starting financials recalculation...",
      key: messageKey,
      duration: 0,
    });
    try {
      await callGraphQLSimple({
        mutation: "updateOrganisation",
        variables: {
          input: {
            id: organisationDetails.id,
            financialsRecalculationStatus: "IN_PROGRESS",
            financialsRecalculationProgress: 0,
          },
        },
      });
      await callRest({
        method: "post",
        route: "/recalculate-financials",
      });
      message.success({
        content: "Financials recalculation started",
        key: messageKey,
      });
    } catch (e) {
      message.error({
        content: "Failed to start financials recalculation",
        key: messageKey,
      });
      return;
    }
  }

  let progressBarStatus = "normal";
  if (organisationDetails.financialsRecalculationStatus === "IN_PROGRESS") {
    progressBarStatus = "active";
  } else if (organisationDetails.financialsRecalculationStatus === "FAILED") {
    progressBarStatus = "exception";
  } else if (organisationDetails.financialsRecalculationStatus === "FINISHED") {
    progressBarStatus = "success";
  }

  return (
    <Card title="Recalculation of financial values" withSpace className="financials-recalculation-card">
      <InfoItem
        inline
        label=""
        value={
          <Button
            type="primary"
            onClick={startFinancialsRecalculation}
            disabled={organisationDetails.financialsRecalculationStatus === "IN_PROGRESS"}
          >
            {organisationDetails.financialsRecalculationStatus === "IN_PROGRESS" ? "Running..." : "Run now"}
          </Button>
        }
      />

      <InfoItem
        inline
        label="Last finished"
        value={
          organisationDetails.financialsLastRecalculatedAt
            ? `${moment(organisationDetails.financialsLastRecalculatedAt).format("DD/MM/YYYY HH:mm:ss")} (${moment(
                organisationDetails.financialsLastRecalculatedAt
              ).fromNow()})`
            : "never"
        }
      />

      {organisationDetails.financialsRecalculationStatus && (
        <InfoItem
          inline
          label="Status of last run"
          value={getReadableStatus(organisationDetails.financialsRecalculationStatus || "")}
        />
      )}
      {![undefined, null, 0].includes(organisationDetails.financialsRecalculationProgress) && (
        <InfoItem
          inline
          label="Progress of last run"
          value={<Progress percent={organisationDetails.financialsRecalculationProgress} status={progressBarStatus} />}
        />
      )}
    </Card>
  );
}
