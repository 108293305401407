import { useState, useEffect } from "react";
import { Button, Spin, Modal } from "antd";
import { CloseCircleOutlined } from "@ant-design/icons";
import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { processIdForDisplay } from "common/helpers";
import { removeRequestFormFromTaskRevision } from "common/requestHelpers";

import TaskListItem from "TaskListItem/TaskListItem";

import "./TaskListModal.scss";

type Props = {
  onCancel: () => void;
  apiUser: any;
  requestId: string;
  formFileId: string;
  formName: string;
  taskIdsAndRevisionIds: any[] | undefined;
  organisationDetails: any;
  activityItems: any[] | undefined;
};

export default function TaskListModal({
  onCancel,
  apiUser,
  requestId,
  formFileId,
  formName,
  taskIdsAndRevisionIds,
  organisationDetails,
  activityItems,
}: Props) {
  const [tasksWithFullDetails, setTasksWithFullDetails] = useState<any[] | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setTasksWithFullDetails(undefined);
    if (!taskIdsAndRevisionIds) {
      return;
    }

    fetchAndSetTasksWithFullDetails();

    async function fetchAndSetTasksWithFullDetails() {
      setIsLoading(true);
      const getTaskPromises = (taskIdsAndRevisionIds as any[])?.map(({ taskId }) => {
        return callGraphQLSimple({
          queryCustom: "getTaskSimple",
          message: `Failed to fetch ${getSimpleLabel("task")}`,
          variables: {
            id: taskId,
          },
        });
      });
      let getTaskResponses: any[] = await Promise.all(getTaskPromises);

      const tasksWithFullDetails = getTaskResponses.map((response) => response.data.getTask);
      setTasksWithFullDetails(tasksWithFullDetails.filter((task) => task));
      setIsLoading(false);
    }
  }, [taskIdsAndRevisionIds]);

  async function confirmRemoveFromTaskRevision({ taskId, taskRevisionId }: { taskId: string; taskRevisionId: string }) {
    const taskDetails = tasksWithFullDetails?.find((task) => task.id === taskId);
    const taskRevisionDetails = taskDetails?.revisions?.items?.find(
      (taskRevision) => taskRevision.id === taskRevisionId
    );

    Modal.confirm({
      title: `Remove form from ${getSimpleLabel("task revision")}?`,
      content: (
        <>
          Are you sure you want to remove this form from {getSimpleLabel("task")}{" "}
          <b>{processIdForDisplay(taskDetails.id)}</b>, revision <b>{taskRevisionDetails?.name}</b>?
        </>
      ),
      okText: "Yes, remove",
      cancelText: "No, cancel",
      onOk: async () => {
        await removeRequestFormFromTaskRevision({
          apiUserId: apiUser.id,
          activityItems,
          taskId,
          requestId,
          taskRevisionId,
          taskTitle: taskDetails.title,
          projectTitle: taskDetails.project?.title,
          taskRevisionName: taskRevisionDetails.name,
          formName,
          formFileId,
          organisation: organisationDetails.id,
          taskRequestIds: taskDetails.requestIds,
        });
        onCancel();
      },
    });
  }

  return (
    <Modal
      title={`${getSimpleLabel("Task revisions")} linked to form`}
      open={true}
      onCancel={onCancel}
      footer={null}
      className="task-list-modal"
    >
      {isLoading ? (
        <Spin />
      ) : (
        <div className="task-list-container">
          {tasksWithFullDetails?.map((task, index) => {
            let taskRevisionId = taskIdsAndRevisionIds?.find(({ taskId }) => taskId === task?.id)?.taskRevisionId;
            return (
              <div className="task-list-item-container">
                <TaskListItem
                  windowWidth={500}
                  key={task.id}
                  {...task}
                  index={index}
                  taskRevisionId={taskRevisionId}
                  organisationDetails={organisationDetails}
                  card
                  includeActions={false}
                />
                <Button
                  icon={<CloseCircleOutlined />}
                  onClick={() => {
                    confirmRemoveFromTaskRevision({ taskId: task.id, taskRevisionId });
                  }}
                >
                  Remove from {getSimpleLabel("task revision")}
                </Button>
              </div>
            );
            // return (
            //   <div key={task.id} style={{ display: "flex", gap: "0.3rem", alignItems: "center" }}>
            //     {getSimpleLabel("Task")}
            //     <Tag className="dark-tag" style={{ marginRight: 0 }}>
            //       {processIdForDisplay(task.id)}
            //     </Tag>
            //     revision P1
            //   </div>
            // );
          })}
        </div>
      )}
    </Modal>
  );
}
