import React from "react";
import { Storage } from "aws-amplify";
import { Typography, Spin } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { getFieldDetailsById } from "common/documentEditorDataSources/aggregator";
import { getObjectStyle } from "TemplateEditorPage/renderHelpers";
import {
  replaceImageUrlsWithFreshImages,
  replaceImageUrlsWithFreshImagesInForm,
} from "TemplateEditorPage/browserOnlyRenderHelpers";
import { computeDataFields, replaceDynamicFields, copyRepeatedObjects } from "common/sharedTemplateRenderHelpers";

import PageComponent from "./PageComponent/PageComponent";
import OverallSpinner from "OverallSpinner/OverallSpinner";

import "./ApplicationPageOutput.scss";

export class ApplicationPageOutput extends React.Component {
  state = {
    isLoading: true,
    isReloading: false,
    internalNumberForRefresh: 0,
    outputTemplateData: undefined,
    attachmentImages: {},
    rawOutputTemplateData: undefined,
  };

  componentDidMount() {
    this.downloadOutputTemplateData();
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // this is only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      const previewData = JSON.parse(JSON.stringify(this.props.previewData));
      replaceImageUrlsWithFreshImagesInForm(previewData);

      try {
        this.setState(
          {
            reportJsonData: this.props.previewData,
            internalNumberForRefresh: this.state.internalNumberForRefresh + 1,
            isLoading: false,
          },
          this.refreshOutputTemplateWithNewData
        );
      } catch (e) {
        console.error(e);
        // nothing to do, we already show an error message in the function which actually failed
      }
    } else if (this.props.numberForRefresh !== prevProps.numberForRefresh) {
      this.refreshOutputTemplateWithNewData();
    }

    // if (!this.props.isDownloadButtonDisabled) {
    //   const userIsCat2Checker = this.isUserCat2Checker();
    //   if (userIsCat2Checker) {
    //     this.props.disableDownloadButton();
    //   }
    // }
  }

  getClient = () => {
    let clientId;
    if (this.props.task) {
      clientId = this.props.task.clientId;
    } else if (this.props.quote) {
      clientId = this.props.quote.clientId;
    } else if (this.props.invoice) {
      clientId = this.props.invoice.clientId;
    }
    const client = this.props.clients.find((client) => client.id === clientId);
    return client;
  };

  downloadOutputTemplateData = async () => {
    const { templateDetails, organisationDetails } = this.props;

    if (!templateDetails?.key) {
      return;
    }

    let jsonOutputTemplateFileBlob = (
      await Storage.get(`${templateDetails.key.split(".")[0]}_annotation.json`, {
        download: true,
        cacheControl: "no-cache",
      })
    ).Body;

    let jsonOutputTemplateFile = JSON.parse(await jsonOutputTemplateFileBlob.text());

    await new Promise((resolve) => {
      setTimeout(() => {
        resolve();
      }, 1000);
    });

    this.setState(
      {
        rawOutputTemplateData: jsonOutputTemplateFile,
      },
      this.refreshOutputTemplateWithNewData
    );
  };

  refreshOutputTemplateWithNewData = async () => {
    try {
      if (!this.state.rawOutputTemplateData) {
        setTimeout(() => {
          this.refreshOutputTemplateWithNewData();
        }, 200);
        return;
      }
      this.setState({ isReloading: true });
      let outputTemplateData = JSON.parse(JSON.stringify(this.state.rawOutputTemplateData));

      const form = JSON.parse(JSON.stringify(this.props.previewData));
      let keyData = {
        ...this.props,
        form,
      };

      /*
        Every time we have a data source override (so far this is only an option for the "file" data source), we need to fetch the override file from the server.
        This field is used to cache the override files, so that we don't have to fetch them again if we need them.

        Since the files may change between refreshes, we need to clear this cache every time we refresh the output template.
      */
      window.documentTemplateOverrides = {};

      await computeDataFields({
        parent: outputTemplateData,
        params: keyData,
      });

      await copyRepeatedObjects({
        parent: outputTemplateData,
        params: keyData,
      });

      await replaceDynamicFields({
        parent: outputTemplateData,
        params: keyData,
      });

      await replaceImageUrlsWithFreshImages(outputTemplateData);

      this.setState({
        outputTemplateData,
        isLoading: false,
        isReloading: false,
        internalNumberForRefresh: this.state.internalNumberForRefresh + 1,
        formFileWithFreshImages: form,
      });
    } catch (err) {
      console.log("err = ", err);
    }
  };

  displayObject = ({ index, parent, object, root, pageSize, pagePadding }) => {
    const { previewData: form } = this.props;

    let style = getObjectStyle({ index, parent, object, root, isWebPage: true });

    switch (object.custom_type) {
      case "component":
        return <PageComponent key={index} {...this.props} object={object} form={form} style={style} />;

      case "signature":
        let signatureContent = null;
        if (object.src) {
          signatureContent = (
            <img key={index} src={object.src} style={{ objectFit: "scale-down", objectPosition: "0 0" }} alt="" />
          );
        } else if (object.custom_firstName || object.custom_lastName) {
          signatureContent = (
            <Typography.Text
              style={{ fontFamily: "MrsSaintDelafield", fontSize: Math.min(object.height / 1.6, 30) }}
              key={index}
            >
              {object.custom_firstName} {object.custom_lastName}
            </Typography.Text>
          );
        }
        return (
          <div
            key={index}
            style={{
              ...style,
              width: object.width,
              height: object.height,
            }}
          >
            {signatureContent}
          </div>
        );

      case "image_container":
        return (
          <div
            key={index}
            style={{
              ...style,
              width: object.width,
              height: object.height,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {object.src ? <img src={object.src} alt="" /> : null}
          </div>
        );
      case "dynamic_file":
        return null;
      default:
        break;
    }

    switch (object.type) {
      case "rect":
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }
        return <div style={style} key={index} />;
      case "ellipse":
        style = {
          ...style,
          width: object.width,
          height: object.height,
          borderRadius: "50%",
        };
        if (object.fill) {
          style.backgroundColor = object.fill;
        }
        if (object.strokeWidth) {
          style.border = `${object.strokeWidth}px solid ${object.stroke}`;
        }

        return <div style={style} key={index} />;
      case "text":
        let fontFamily = object.fontFamily;
        style = {
          ...style,
          borderColor: object.stroke,
          fontFamily,
          fontSize: object.fontSize,
          color: object.fill,
        };

        if (!object.custom_hideBackground && (object.custom_textFill || object.custom_formula_textFill)) {
          style.backgroundColor = object.custom_textFill || object.custom_formula_textFill;
        }

        if (object.strokeWidth && parseInt(object.strokeWidth) && object.stroke) {
          style.border = `${object.strokeWidth}px solid ${object.stroke || "#000000"}`;
        }

        const paramsForGetField = {
          ...this.props,
          form,
          dataSource: object.custom_dynamicInformationDataSource,
          id: object.custom_dynamicInformation,
        };
        let fieldDetails = getFieldDetailsById(paramsForGetField);
        if (fieldDetails?.fieldType === "textarea") {
          let nodes;
          try {
            nodes = JSON.parse(object.text);
          } catch (e) {
            // nothing, it's not a JSON value
          }

          let contentToDisplay = null;
          let pageInnerHeight = pageSize.height - pagePadding.top - pagePadding.bottom;
          if (nodes && Array.isArray(nodes)) {
            let nodeContainers = [{ nodes: [], hasPageBreak: false }];
            let crtNodeContainerIndex = 0;
            for (let node of nodes) {
              if (node.type === "page-break") {
                crtNodeContainerIndex++;
                continue;
              }
              if (!nodeContainers[crtNodeContainerIndex]) {
                nodeContainers[crtNodeContainerIndex] = {
                  nodes: [],
                  hasPageBreak: true,
                };
              }
              nodeContainers[crtNodeContainerIndex].nodes.push(node);
            }

            contentToDisplay = nodeContainers.map((nodeContainer, i) => {
              let nodeContainerStyle = {};
              if (nodeContainer.hasPageBreak) {
                nodeContainerStyle.minHeight = pageInnerHeight - 1; // if we don't subtract 1, it tends to lead to extra empty pages
              }

              return (
                <div key={i} style={nodeContainerStyle} break={nodeContainer.hasPageBreak}>
                  {/* {displayDocumentOutputTextarea({
                    props: this.props,
                    numberVariables: this.props.organisationDetails.variables?.items?.filter(
                      (x) => x.type === "NUMBER"
                    ),
                    colorVariables: this.props.organisationDetails.variables?.items?.filter((x) => x.type === "COLOR"),
                    nodes: nodeContainer.nodes,
                    style: {
                      fontFamily,
                      fontSize: object.fontSize,
                      color: object.fill,
                    },
                    pageSize,
                    pagePadding,
                  })} */}
                </div>
              );
            });
          } else {
            // contentToDisplay = displayPdfTextarea({
            //   fieldName: fieldDetails.id,
            //   targetReportJsonData: form,
            //   attachmentImages: {}, //this.state.attachmentImagesCat2Check,
            //   styles: {},
            //   style: {
            //     fontFamily,
            //     fontSize: object.fontSize,
            //     color: object.fill,
            //   },
            //   projectFolder: this.props.projectFolder,
            //   displayTitle: false,
            //   pageSize,
            //   pagePadding,
            //   props: this.props,
            //   isNewTemplate: true,
            // });
          }

          return (
            <div style={style} key={index}>
              {contentToDisplay}
            </div>
          );
        }

        return (
          <div style={{ ...style, width: object.width }} key={index} wrap={object.custom_allowBreak !== false}>
            {!object.text || !object.text.split ? (
              <Typography.Text
                wrap={object.custom_allowBreak !== false}
                style={{
                  fontSize: style.fontSize,
                  fontFamily: style.fontFamily,
                  color: style.color,
                }}
              >
                {object.text}
              </Typography.Text>
            ) : (
              object.text.split("\\n").map((line, i) => {
                return (
                  <Typography.Text
                    wrap={object.custom_allowBreak !== false}
                    style={{
                      fontSize: style.fontSize,
                      fontFamily: style.fontFamily,
                      color: style.color,
                    }}
                    key={i}
                  >
                    {line}
                  </Typography.Text>
                );
              })
            )}
          </div>
        );
      case "image":
        style = {
          ...style,
          width: style.width * (object.scaleX || 1),
          height: style.height * (object.scaleY || 1),
        };
        return <img src={object.src} style={style} key={object.custom_id} alt="" />;

      default:
        return null;
    }
  };

  displayContent = () => {
    const { outputTemplateData, isLoading } = this.state;

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};
    window.lambdaPdfPageNumbersToSkipBorders = [];

    window.lambdaPdfAssets = [
      // {
      //   bucket: "draughthub-public-assets",
      //   name: "organisationLogo",
      //   key: "DC/dc-logo.png",
      // },
    ];

    if (isLoading || !outputTemplateData) {
      return null;
    }

    return (
      <>
        {outputTemplateData.objects
          .filter((object) => object.custom_type === "page" && !object.isHidden && object.visible !== false)
          .map((page, pageIndex) => {
            let pageStyle = getObjectStyle({
              object: page,
              root: outputTemplateData,
              isWebPage: true,
            });
            let pageSize = {
              width: page.custom_pageWidth,
              height: page.custom_pageHeight,
            };
            let pagePadding = {
              top: pageStyle.paddingTop,
              right: pageStyle.paddingRight,
              bottom: pageStyle.paddingBottom,
              left: pageStyle.paddingLeft,
            };

            return (
              <div size={pageSize} key={pageIndex} style={pageStyle} className="page">
                {this.displayParent({
                  parent: page,
                  root: outputTemplateData,
                  pageSize,
                  pagePadding,
                  page,
                })}
              </div>
            );
          })}
      </>
    );
  };

  displayParent = ({ parent, page, pageSize, pagePadding, root }) => {
    return parent.objects
      .filter(
        (object) =>
          !object.isHidden &&
          object.visible !== false &&
          !object.custom_isPageBorder &&
          !object.custom_isPageNumber &&
          object.custom_type !== "page_background"
      )
      .map((object, objectIndex) => {
        if (object.custom_type === "section") {
          return this.displaySection({
            object,
            objectIndex,
            page,
            pageSize,
            pagePadding,
            parent,
            root,
          });
        } else {
          return this.displayObject({
            index: objectIndex,
            object: object,
            page,
            parent,
            root,
            pageSize,
            pagePadding,
          });
        }
      });
  };

  displaySection = ({ object, objectIndex, page, pageSize, pagePadding, parent, root }) => {
    let sectionStyle = {
      ...getObjectStyle({
        object,
        index: objectIndex,
        parent,
        root,
        isWebPage: true,
      }),
    };

    return (
      <div key={objectIndex} style={sectionStyle} wrap={object.custom_allowBreak !== false}>
        {this.displayParent({
          parent: object,
          page,
          pageSize,
          pagePadding,
          root,
        })}
      </div>
    );
  };

  render() {
    const { isLoading, isReloading } = this.state;

    if (isLoading) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
          <Spin />
        </div>
      );
    }

    return (
      <div className="application-page-output">
        {this.displayContent({})}
        {isReloading && <OverallSpinner />}
      </div>
    );
  }
}

export default withSubscriptions({
  Component: ApplicationPageOutput,
  subscriptions: ["organisationDetails", "users", "tasks", "quotes", "invoices", "clients", "projects"],
});
