// import { useState } from "react";
import { Typography, Button, Modal, Select } from "antd";
import { PlusCircleOutlined, DeleteOutlined } from "@ant-design/icons";

import Condition from "./Condition/Condition";
import Input from "Input/Input";
import DraughtHubInput from "Input/Input";
import InfoItem from "InfoItem/InfoItem";

import "./Conditions.scss";

export default function Conditions({ object, updateObject, fileTypeDetails, form, organisationDetails }) {
  function displayConditions() {
    if (!object.custom_conditions) {
      return null;
    }

    return object.custom_conditions.map((condition, index) => {
      return (
        <div className="text-condition-item" key={index}>
          <div className="condition-details">
            <InfoItem
              label="Symbol"
              inline
              value={
                <Input
                  defaultValue={condition.symbol}
                  showBorder
                  onChange={(newSymbol) => {
                    updateCondition({ condition, index, object, fields: { symbol: newSymbol } });
                  }}
                />
              }
            />
            <Condition
              condition={condition}
              index={index}
              object={object}
              fileTypeDetails={fileTypeDetails}
              updateCondition={updateCondition}
              form={form}
              organisationDetails={organisationDetails}
            />
          </div>

          <Button
            className="delete-condition-button"
            onClick={() => {
              onDeleteConditioncClick({ condition, index });
            }}
            icon={<DeleteOutlined />}
          />
        </div>
      );
    });
  }

  function updateCondition({ condition, index, object, fields }) {
    let newConditions = JSON.parse(JSON.stringify(object.custom_conditions || []));

    newConditions[index] = {
      ...condition,
      ...fields,
    };

    updateObject({
      fields: {
        custom_conditions: newConditions,
      },
    });
  }

  function onDeleteConditioncClick({ condition, index }) {
    Modal.confirm({
      title: `Are you sure you want to delete ${condition.symbol}?`,
      okText: "Yes, delete",
      cancelText: "No",
      onOk: () => {
        deleteCondition(index);
      },
    });
  }

  function deleteCondition(index) {
    const newConditions = JSON.parse(JSON.stringify(object.custom_conditions || []));

    newConditions.splice(index, 1);
    updateObject({
      fields: {
        custom_conditions: newConditions,
      },
    });
  }

  function onAddConditionClick() {
    const newConditions = JSON.parse(JSON.stringify(object.custom_conditions || []));

    newConditions.push({
      symbol: `$${newConditions.length + 1}`,
    });
    updateObject({
      fields: {
        custom_conditions: newConditions,
      },
    });
  }

  return (
    <>
      <InfoItem
        label="Conditional expression"
        value={
          <DraughtHubInput
            fullWidth
            showBorder
            fireOnChangeWithoutBlur
            defaultValue={object.custom_conditionalExpression}
            onChange={(value) =>
              updateObject({
                object,
                fieldName: "custom_conditionalExpression",
                value,
              })
            }
          />
        }
      />
      <div className="conditions">
        <div className="section-header">
          <Typography.Text className="section-title">Conditions</Typography.Text>
          <Button icon={<PlusCircleOutlined />} type="primary" onClick={onAddConditionClick}>
            Add condition
          </Button>
        </div>
        {displayConditions()}
      </div>
    </>
  );
}
