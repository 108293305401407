import React from "react";
import moment from "moment";
import { getReportTaskRevisions } from "ReportPage/ReportTaskRevisions/ReportTaskRevisions";
import { LoadingOutlined } from "@ant-design/icons";
import { Typography, notification } from "antd";
import axios from "axios";
import { Text, View, Image, Document, Font } from "@react-pdf/renderer";
import { graphqlOperation } from "aws-amplify";

import { getFile } from "graphql/queries_custom";
import {
  callGraphQL,
  fetchAndSetLinkedTasks,
  getCat2Check,
  getDesignTaskForCat2Check,
  fileHasAtLeastOneExport,
} from "common/helpers";
import { getAttachmentFullKeyFromLocalKey } from "common/documentRenderHelpers";
import getS3File from "common/getS3File";

import {
  getLatestFileVersion,
  getFilenameFromKey,
  getLatestRevision,
  buildFileName,
  getAttachmentTypeFromKey,
} from "common/shared";
import { getImagesFromReport } from "ReportPage/Report/reportHelpers";
import { displayPdfTextarea, displayPdfAttachment, initialiseLambdaPdfInserts } from "common/documentRenderHelpers";
import { getSheetReferenceNumber } from "common/naming";

import Page from "ReportPage/Report/ReportPdfPage";
import ReportPreview from "ReportPage/ReportPreview";

import AvertaRegular from "ReportPage/Report/averta-regular.ttf";
import AvertaBold from "ReportPage/Report/averta-bold.ttf";
import MrsSaintDelafield from "ReportPage/Report/MrsSaintDelafield-Regular.ttf";

import { Table } from "ReportPage/PdfTable";
import { EndCover, colors, styles } from "./DC_ReportCommonComponents";

import "./ReportDefault.scss";

Font.registerHyphenationCallback((word) => [word]);

Font.register({
  family: "Averta",
  fonts: [
    {
      src: AvertaRegular,
      fontWeight: "normal",
    },
    {
      src: AvertaBold,
      fontWeight: "bold",
    },
  ],
});

Font.register({
  family: "MrsSaintDelafield",
  fonts: [
    {
      src: MrsSaintDelafield,
      fontWeight: "normal",
    },
  ],
});

export class ReportDC extends React.Component {
  state = {
    calculationsFile: null,
    isLoading: true,
    attachmentImages: null,
    attachmentImagesCat2Check: null,
    defaultRiskAssessmentReference: null,
    cat2ReportJsonData: null,
  };

  async componentDidMount() {
    await fetchAndSetLinkedTasks.call(this);
    const { taskRevision, task } = this.props;
    const { linkedTasksDetails } = this.state;
    const reportFile = this.props.file;
    let newState = {
      defaultRiskAssessmentReference: await buildFileName({
        organisation: task.organisation,
        fileId: reportFile.id,
        taskId: task.id,
        fileType: "DESIGNERS RISK ASSESSMENT",
        taskRevisionName: taskRevision.name,
        clientInitials: task.client.initials,
        projectInitials: task.project.initials,
        taskInitials: task.initials,
      }),
    };

    const cat2CheckTask = getCat2Check({ task, linkedTasksDetails });
    if (cat2CheckTask) {
      const cat2LatestRevision = getLatestRevision(cat2CheckTask);
      const cat2ReportFile = cat2LatestRevision.files.items
        .filter((x) => !x.isArchived)
        .find((x) => x.type === "REPORT");
      if (cat2ReportFile) {
        newState.cat2ReportJsonData = await this.loadForm({
          file: cat2ReportFile,
        });
      }
    }
    // if we don't receive the preview data, we load it ourselves
    // this is useful on pages that don't already have that data to pass it down to us
    if (!this.props.previewData) {
      newState.reportJsonData = await this.loadForm({
        file: reportFile,
      });
    } else {
      newState.reportJsonData = this.props.previewData;
    }
    try {
      newState.attachmentImages = await this.retrieveImages(newState.reportJsonData);
      if (newState.cat2ReportJsonData) {
        newState.attachmentImagesCat2Check = await this.retrieveImages(newState.cat2ReportJsonData);
      }

      this.setState({
        ...newState,
        calculationsFile: await this.loadCalculationsFile(),
        isLoading: false,
      });
    } catch (e) {
      // nothing, we already show an error message in the function which actually failed
    }
  }

  async componentDidUpdate(prevProps) {
    // if we don't have this block of code, the preview won't update when the user changes something
    // this is only used on the report page
    if (this.props.previewData !== prevProps.previewData) {
      try {
        const attachmentImages = await this.retrieveImages(this.props.previewData);
        this.setState({
          reportJsonData: this.props.previewData,
          attachmentImages,
          isLoading: false,
        });
      } catch (e) {
        // nothing, we already show an error message in the function which actually failed
      }
    }

    // if (!this.props.isDownloadButtonDisabled) {
    //   const userIsCat2Checker = this.isUserCat2Checker();
    //   if (userIsCat2Checker) {
    //     this.props.disableDownloadButton();
    //   }
    // }
  }

  loadForm = async ({ file }) => {
    const fileKey = getLatestFileVersion(file).key.replace("public/", "");
    const filePublicURL = await getS3File(fileKey);
    const form = (await axios.get(filePublicURL)).data;
    return form;
  };

  loadCalculationsFile = async () => {
    const { taskRevision } = this.props;
    const calculationsFileFromTaskRevision = taskRevision.files.items.find((x) => !x.isArchived && x.type === "EXCEL");
    if (!calculationsFileFromTaskRevision) {
      // not all tasks include a calculations file
      return;
    }
    const calculationsFile = (
      await callGraphQL(
        "Failed to retrieve calculations file details",
        graphqlOperation(getFile, {
          id: calculationsFileFromTaskRevision.id,
        })
      )
    ).data.getFile;
    return calculationsFile;
  };

  retrieveImages = async (reportJsonData) => {
    let imagesInReport = getImagesFromReport(reportJsonData);
    const attachmentImages = this.state.attachmentImages || {};
    let imageKeys = imagesInReport
      .map((image) => image.localKey)
      // we only want to fetch the images we don't already have
      .filter((localKey) => !attachmentImages || !attachmentImages.hasOwnProperty(localKey));

    if (!imageKeys || imageKeys.length === 0) {
      return attachmentImages;
    }

    try {
      const imagePromises = [];
      for (let i = 0; i < imageKeys.length; i++) {
        const localKey = imageKeys[i];
        const fullKey = getAttachmentFullKeyFromLocalKey({
          projectFolder: this.props.projectFolder,
          localKey,
        });
        imagePromises.push(
          getS3File(fullKey).catch(() => {
            throw new Error(fullKey);
          })
        );
      }
      let images = await Promise.all(imagePromises);
      imageKeys.forEach((key, i) => {
        attachmentImages[key] = images[i];
      });
    } catch (e) {
      console.error("error:", e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to retrieve image:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      throw e;
    }

    return attachmentImages;
  };

  shouldDisplayCat2Check = () => {
    const { reportJsonData, cat2ReportJsonData } = this.state;
    let reportType = reportJsonData.fields?.reportType?.value || [];
    const result = cat2ReportJsonData && reportType.includes("check");

    return result;
  };

  displayTaskRevisionHistory = (taskRevisionsData) => {
    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: `${95 / taskRevisionsData.columns.length}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: row === 0 ? colors.andunGreen : colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: row === 0 ? colors.andunGreen : "#fff",
        color: row === 0 ? "#fff" : "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    return <Table columns={taskRevisionsData.columns} data={taskRevisionsData.data} style_function={cell_style} />;
  };

  displayDesignCertificate = () => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);
    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : "";

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "20%" : "80%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const SIGNATURE_HEIGHT = 30;

    let signature = "";

    if (assignedTo) {
      if (assignedTo.signature) {
        signature = (
          <Image
            src={assignedTo.signature}
            style={{
              height: SIGNATURE_HEIGHT,
              width: SIGNATURE_HEIGHT * (assignedTo.signatureWidth / assignedTo.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", fontSize: 20 }}>
            {assignedTo.firstName} {assignedTo.lastName}
          </Text>
        );
      }
    }

    let bricsCADFiles = taskRevision.files.items.filter((x) => x.type === "BRICSCAD" && !x.isArchived);

    return (
      <>
        <Text style={[styles.sectionSubtitle]}>Temporary Works Design and Check Certificate</Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Project:</Text> {task.project.title}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Client:</Text> {task.client.name}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Design Brief Issued:</Text> {reportJsonData.fields.designBriefIssued.value}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Design Brief Reference:</Text> {reportJsonData.fields.designBriefReference.value}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Does the design comply with the brief:</Text>{" "}
          {reportJsonData.fields.designComplies.value}
        </Text>
        {reportJsonData.fields.designComplies.value === "Yes" ? null : (
          <>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>If no, how does it differ from the design brief?</Text>
            </Text>
            <Text style={[styles.paragraph]}>{reportJsonData.fields.designNoCompliesReason.value}</Text>
          </>
        )}

        <Table
          style={{ marginTop: 50 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : null,
            },
            { name: "Title", value: assignedTo?.position },
            {
              name: "Signature",
              value: signature,
            },
            { name: "Date", value: reviewedAt },
            {
              name: "",
              value:
                "To be signed by the Temporary Works Designer or other person authorised* to sign on behalf of the organisation responsible for the Design of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />

        {bricsCADFiles.length > 0 && (
          <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
            Drawings Produced
          </Text>
        )}

        {bricsCADFiles.length > 0 &&
          bricsCADFiles.map((file) => {
            return file.sheets.items
              .filter((sheet) => !sheet.excludeFromRegister)
              .map((sheet) => {
                return (
                  <Text key={sheet.id} style={[styles.paragraph]}>
                    {getSheetReferenceNumber({ file, sheet })}
                  </Text>
                );
              });
          })}

        <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
          Loading Constraints
        </Text>
        <Text style={[styles.paragraph]}>{reportJsonData.fields.certificateLoadingConstraints.value}</Text>

        <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
          Proposed Category of Check
        </Text>
        <Text style={[styles.paragraph]}>
          Cat{" "}
          {reportJsonData.fields.proposedCatCheck.value === "same"
            ? task.catLevel
            : reportJsonData.fields.proposedCatCheck.value}
        </Text>
      </>
    );
  };

  displayStandards = () => {
    const { reportJsonData } = this.state;
    return (
      <Page size="A4" style={styles.page}>
        <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
          Current Design Standards used
        </Text>

        {Array.isArray(reportJsonData.fields.currentDesignStandardsUsed.value)
          ? reportJsonData.fields.currentDesignStandardsUsed.value.map((item, i) => (
              <Text style={[styles.paragraph]} key={i}>
                {item}
              </Text>
            ))
          : null}

        <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
          Historical Design Standards used
        </Text>

        {Array.isArray(reportJsonData.fields.historicalDesignStandardsUsed.value)
          ? reportJsonData.fields.historicalDesignStandardsUsed.value.map((item, i) => (
              <Text style={[styles.paragraph]} key={i}>
                {item}
              </Text>
            ))
          : null}

        <Text style={[styles.paragraph, styles.bold, { marginTop: 30 }]} minPresenceAhead={30}>
          Other literature referred to
        </Text>

        {Array.isArray(reportJsonData.fields.otherLiterature.value)
          ? reportJsonData.fields.otherLiterature.value.map((item, i) => (
              <Text style={[styles.paragraph]} key={i}>
                {item}
              </Text>
            ))
          : null}
      </Page>
    );
  };

  displayCheckCertificate = (reportType) => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;
    let signedDesignCheckCertificateField = reportJsonData.fields.signedDesignCheckCertificate;

    if (signedDesignCheckCertificateField?.value && signedDesignCheckCertificateField.value.length > 0) {
      return displayPdfAttachment({
        key: signedDesignCheckCertificateField.value[0],
        label: "Signed check certificate",
        hasBorders: false,
        attachmentLabel: "signed-check-certificate",
        pagesToExclude: [],
      });
    }

    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    const { cat2ReportJsonData } = this.state;
    let cat2CheckLatestRevision;
    if (this.shouldDisplayCat2Check()) {
      cat2CheckLatestRevision = getLatestRevision(cat2CheckTask);
      if (!cat2ReportJsonData) {
        return null;
      }
    }

    let targetReportJsonData = cat2ReportJsonData || reportJsonData;

    let reviewerId;
    if (this.shouldDisplayCat2Check()) {
      reviewerId = cat2CheckTask.assignedTo;
    } else if (reportType.includes("cat3_performed")) {
      reviewerId = task.assignedTo;
    } else {
      reviewerId = taskRevision.checkedBy;
    }

    let reviewer = users.find((x) => x.id === reviewerId);

    let reviewedAt = null;

    if (this.shouldDisplayCat2Check()) {
      if (cat2CheckLatestRevision.reviewAcceptDate) {
        reviewedAt = moment(cat2CheckLatestRevision.reviewAcceptDate).format("DD/MM/YY");
      }
    } else {
      if (taskRevision.reviewAcceptDate) {
        reviewedAt = moment(taskRevision.reviewAcceptDate).format("DD/MM/YY");
      }
    }

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "20%" : "80%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const REVIEWER_SIGNATURE_HEIGHT = 30;
    let signature = null;

    if (reviewedAt && reviewer) {
      if (reviewer.signature) {
        signature = (
          <Image
            src={reviewer.signature}
            style={{
              height: REVIEWER_SIGNATURE_HEIGHT,
              width: REVIEWER_SIGNATURE_HEIGHT * (reviewer.signatureWidth / reviewer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield" }}>
            {reviewer.firstName} {reviewer.lastName}
          </Text>
        );
      }
    }
    return (
      <Page size="A4" style={styles.page}>
        <Text style={[styles.sectionSubtitle]}>Design Check</Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Checking Organisation:</Text>{" "}
          {targetReportJsonData.fields.checkingOrganisation.value}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Design Check Undertaken:</Text> Cat {task.catLevel}
        </Text>

        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Does the design comply with the brief:</Text>{" "}
          {!reportType.includes("cat3_requested") && targetReportJsonData.fields.designComplies.value}
        </Text>
        {!reportType.includes("cat3_requested") && targetReportJsonData.fields.designComplies.value === "Yes" ? null : (
          <>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>If no, how does it differ from the design brief?</Text>
            </Text>
            <Text style={[styles.paragraph]}>{targetReportJsonData.fields.designNoCompliesReason.value}</Text>
          </>
        )}

        <Table
          style={{ marginTop: 50 }}
          includeHeader={false}
          columns={[
            {
              title: "name",
              dataIndex: "name",
              key: "name",
            },
            {
              title: "value",
              dataIndex: "value",
              key: "value",
            },
          ]}
          data={[
            {
              name: "Name",
              value: reportType.includes("cat3_requested")
                ? ""
                : reviewer
                ? `${reviewer.firstName} ${reviewer.lastName}`
                : "",
            },
            {
              name: "Title",
              value: reportType.includes("cat3_requested") ? "" : reviewer?.position,
            },
            {
              name: "Signature",
              value: reportType.includes("cat3_requested") ? "" : signature,
            },
            {
              name: "Date",
              value: reportType.includes("cat3_requested") ? "" : reviewedAt,
            },
            {
              name: "",
              value:
                "To be signed by the Temporary Works checker or other person authorised* to sign on behalf of the organisation responsible for the Checking of the Temporary Works.",
            },
          ]}
          style_function={cell_style}
        />
      </Page>
    );
  };

  displayDesignPhilosophy = () => {
    const { task, users, taskRevision } = this.props;
    const { calculationsFile, reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);
    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : "";

    let calculationsFileReference = "";
    if (calculationsFile) {
      const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
      if (latestCalculationsFileVersion.customId) {
        calculationsFileReference = latestCalculationsFileVersion.customId;
      } else {
        calculationsFileReference = getFilenameFromKey(latestCalculationsFileVersion.key);
      }
    }

    const subjectOfCat2Check = getDesignTaskForCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });

    return (
      <>
        <Text style={[styles.sectionSubtitle]}>{subjectOfCat2Check ? "Check" : "Design"} Philosophy</Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Project Title:</Text> {task.project.title}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Project Title:</Text> {task.title}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Calculation Reference:</Text> {calculationsFileReference}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Design By:</Text>{" "}
          {assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : null}
        </Text>
        <Text style={[styles.paragraph]}>
          <Text style={styles.bold}>Date:</Text> {reviewedAt}
        </Text>

        <Text style={[styles.sectionSubtitle]}>Documents and Drawings utilised</Text>
        {subjectOfCat2Check &&
          subjectOfCat2Check.revisions.items
            .slice(-1)[0]
            .files.items.filter((x) => x.type === "BRICSCAD" && !x.isArchived)
            .map((file, i) => {
              const latestFileVersion = file.versions.items.slice(-1)[0];
              return (
                <Text style={[styles.paragraph]} key={i}>
                  {getFilenameFromKey(latestFileVersion.exports[0].key, true)}
                </Text>
              );
            })}

        {Array.isArray(reportJsonData.fields.attachmentsUtilised?.value)
          ? reportJsonData.fields.attachmentsUtilised.value.map((key, i) => (
              <Text style={[styles.paragraph]} key={i}>
                {getFilenameFromKey(key, true)}
              </Text>
            ))
          : null}
      </>
    );
  };

  displayCalculations = ({ userIsCat2Checker }) => {
    const { calculationsFile } = this.state;
    const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
    const firstExport = latestCalculationsFileVersion.exports[0];

    if (userIsCat2Checker) {
      return (
        <Page size="A4" style={styles.page}>
          <View>
            <Text>You are the Cat 2 checker, therefore you cannot see the calculations.</Text>
          </View>
        </Page>
      );
    }

    return displayPdfAttachment({
      key: firstExport.key,
      label: "Calculations",
      hasBorders: true,
      attachmentLabel: "calculations",
      pagesToExclude: [],
    });
  };

  displayDesignersRiskAssessment = () => {
    const { task, users, taskRevision } = this.props;
    const { reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);
    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : "";

    const cell_style1 = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const baseStyle = {
        width: col === 0 ? "40%" : "60%",
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 5pt",
        fontSize: 10,
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const cell_style2 = (row, col, _, totalRows) => {
      let borderTopWidth = row === 0 ? 0 : 1;
      let borderBottomWidth = row === totalRows ? 1 : 1;
      let borderLeftWidth = col === 0 ? 1 : 0;
      let borderRightWidth = 1;
      let padding = 5;
      let fontSize = 8;

      const COL_WIDTHS = {
        0: 25,
        1: 20,
        2: 4,
        3: 4,
        4: 4,
        5: 44,
      };

      let height = "100%";
      let marginTop = -row;

      if ([2, 3, 4].includes(col) && row > 0) {
        padding = 2;
        fontSize = 8;
      }

      if (row === 0) {
        if (col === 2 || col === 3 || col === 4) {
          borderTopWidth = 1;
          height = "50%";
          marginTop = "auto";
          padding = 3;
        }
      }

      if (col === 4) {
        borderRightWidth = 0;
      }
      if (col === 5) {
        borderLeftWidth = 1;
      }

      const baseStyle = {
        width: `${COL_WIDTHS[col]}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding,
        fontSize,
        textAlign: "center",
        whiteSpace: "wrap",
        height,
        marginTop,
        fontWeight: row === 0 ? "bold" : "normal",
        position: "relative",
      };

      let specificStyle = {
        backgroundColor: "#fff",
        color: "#333",
      };

      return { ...baseStyle, ...specificStyle };
    };

    const riskAssessment = reportJsonData.fields.riskAssessment;
    const draReference = reportJsonData.fields.draReference;
    let riskAssessmentReference = "";
    if (draReference?.value && draReference.value.length > 0) {
      riskAssessmentReference = draReference.value;
    } else {
      riskAssessmentReference = this.state.defaultRiskAssessmentReference;
    }

    return (
      <>
        <Text style={[styles.sectionSubtitle, { paddingTop: 0, marginTop: -38 }]}>Designers Risk Assessment</Text>

        <View
          style={{
            position: "relative",
            top: 85,
            left: 247,
          }}
        >
          <Text
            style={{
              color: "#333",
              fontSize: 8,
              fontWeight: "bold",
            }}
          >
            Risk Rating
          </Text>
        </View>
        <Table
          style={{ marginTop: 0 }}
          includeHeader={false}
          columns={[
            {
              title: "col1",
              dataIndex: "col1",
              key: "col1",
            },
            {
              title: "col2",
              dataIndex: "col2",
              key: "col2",
            },
          ]}
          data={[
            {
              col1: (
                <View>
                  <Text style={[styles.bold]}>Designer:</Text>
                  <Text>{assignedTo ? `${assignedTo.firstName} ${assignedTo.lastName}` : null}</Text>
                </View>
              ),
              col2: (
                <View>
                  <Text style={[styles.bold]}>Project:</Text>
                  <Text>{task.project.title}</Text>
                </View>
              ),
            },

            {
              col1: (
                <View>
                  <Text style={[styles.bold]}>Job Number:</Text>
                  <Text>{riskAssessmentReference}</Text>
                </View>
              ),
              col2: (
                <View>
                  <Text style={[styles.bold]}>Date:</Text>
                  <Text>{reviewedAt}</Text>
                </View>
              ),
            },
          ]}
          style_function={cell_style1}
        />
        <Table
          style={{ marginBottom: 20 }}
          includeHeader={true}
          columns={[
            {
              title: (
                <View>
                  <Text>Activity Element /</Text>
                  <Text>Potential Hazards</Text>
                </View>
              ),
              dataIndex: "element",
              key: "element",
            },
            {
              title: (
                <View>
                  <Text>Infrastructure /</Text>
                  <Text>People at Risk</Text>
                </View>
              ),
              dataIndex: "target",
              key: "target",
            },
            {
              title: "L",
              dataIndex: "likelihood",
              key: "likelihood",
            },
            {
              title: "S",
              dataIndex: "severity",
              key: "severity",
            },
            {
              title: "R",
              dataIndex: "risk",
              key: "risk",
            },
            {
              title: "Action at Design Stage/Residual Risk & Possible Control Options (Contractors)",
              dataIndex: "action",
              key: "action",
            },
          ]}
          data={riskAssessment.value.map((item) => {
            return {
              ...item,
              likelihood: <Text style={styles.bold}>{item.likelihood}</Text>,
              severity: <Text style={styles.bold}>{item.severity}</Text>,
              risk: <Text style={styles.bold}>{this.calculateRisk(item)}</Text>,

              action: (
                <View style={{ paddingBottom: 5 }}>
                  <Text style={{ fontWeight: "bold" }}>Action at Design Stage</Text>
                  <Text>{item.actionDesignStage}</Text>
                  <Text style={{ fontWeight: "bold", marginTop: 10 }}>Residual Risk</Text>
                  <Text>{item.residualRisk}</Text>
                </View>
              ),
            };
          })}
          style_function={cell_style2}
        />
      </>
    );
  };

  calculateRisk = ({ likelihood: l, severity: s }) => {
    if (l === "L" && s === "L") {
      return "L";
    } else if (l === "L" && s === "M") {
      return "M";
    } else if (l === "L" && s === "H") {
      return "M";
    } else if (l === "M" && s === "L") {
      return "M";
    } else if (l === "M" && s === "M") {
      return "M";
    } else if (l === "M" && s === "H") {
      return "H";
    } else if (l === "H" && s === "L") {
      return "M";
    } else if (l === "H" && s === "M") {
      return "H";
    } else if (l === "H" && s === "H") {
      return "H";
    }
    return "";
  };

  displayCat3Check = () => {
    const { reportJsonData } = this.state;

    let field = reportJsonData.fields.checkersCalculations;

    if (!field?.value || field.value.length === 0) {
      return null;
    }

    return displayPdfAttachment({
      key: field.value[0],
      label: "Cat 3 checker's calculations",
      hasBorders: false,
      attachmentLabel: "checkers-calculations",
      pagesToExclude: [],
    });
  };

  doesCat2CheckHaveCalculations = () => {
    const { task } = this.props;
    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    if (!cat2CheckTask) {
      return false;
    }
    const latestRevision = getLatestRevision(cat2CheckTask);
    const calculationsFile = latestRevision.files.items.find((x) => !x.isArchived && x.type === "EXCEL");
    if (!calculationsFile) {
      return false;
    }

    return fileHasAtLeastOneExport(calculationsFile);
  };

  displayCat2Check = (reportType) => {
    const { task, users, taskRevision } = this.props;
    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });
    const { cat2ReportJsonData } = this.state;
    if (!cat2ReportJsonData) {
      return null;
    }

    const latestRevision = getLatestRevision(cat2CheckTask);
    const calculationsFile = latestRevision.files.items.find((x) => x.type === "EXCEL" && !x.isArchived);

    const cat2Checker = users.find((x) => x.id === cat2CheckTask.assignedTo);
    const reviewedAt = latestRevision.reviewAcceptDate
      ? moment(latestRevision.reviewAcceptDate).format("DD/MM/YY")
      : "";

    let calculationsFileReference = "";
    if (calculationsFile) {
      const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
      if (latestCalculationsFileVersion.customId) {
        calculationsFileReference = latestCalculationsFileVersion.customId;
      } else {
        calculationsFileReference = getFilenameFromKey(latestCalculationsFileVersion.key);
      }
    }

    const cat2CheckCalculationsHasExport = fileHasAtLeastOneExport(calculationsFile);

    return (
      <>
        <Page size="A4" style={styles.page}>
          <View>
            <Text style={[styles.sectionSubtitle]}>Check Philosophy</Text>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>Project Title:</Text> {cat2CheckTask.project.title}
            </Text>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>Task Title:</Text> {cat2CheckTask.title}
            </Text>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>Calculation Reference:</Text> {calculationsFileReference}
            </Text>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>Check By:</Text>{" "}
              {cat2Checker ? `${cat2Checker.firstName} ${cat2Checker.lastName}` : null}
            </Text>
            <Text style={[styles.paragraph]}>
              <Text style={styles.bold}>Date:</Text> {reviewedAt}
            </Text>
            <Text style={[styles.sectionSubtitle]}>Documents and Drawings utilised</Text>
            {taskRevision.files.items
              .filter((x) => x.type === "BRICSCAD" && !x.isArchived)
              .map((file, i) => {
                const latestFileVersion = file.versions.items.slice(-1)[0];
                return (
                  <Text style={[styles.paragraph]} key={i}>
                    {getFilenameFromKey(latestFileVersion.exports[0].key, true)}
                  </Text>
                );
              })}
            {Array.isArray(cat2ReportJsonData.fields.attachmentsUtilised.value)
              ? cat2ReportJsonData.fields.attachmentsUtilised.value.map((item, i) => (
                  <Text style={[styles.paragraph]} key={i}>
                    {getFilenameFromKey(item, true)}
                  </Text>
                ))
              : null}
            <View break></View>
          </View>
          {[
            "Brief",
            "Methodology",
            "Loads",
            "Load Path",
            "Stability",
            "Geotechnical Parameters",
            "Design Life",
            "Sustainability",
            "Assumptions",
            "Exclusions",
          ].map((fieldName) =>
            displayPdfTextarea({
              fieldName,
              targetReportJsonData: cat2ReportJsonData,
              suffix: "cat2",
              attachmentImages: this.state.attachmentImagesCat2Check,
              styles,
              projectFolder: this.props.projectFolder,
            })
          )}
        </Page>
        {this.displayCat2CheckCalculations({
          reportType,
          cat2CheckCalculationsHasExport,
          calculationsFile,
        })}
      </>
    );
  };

  displayCat2CheckCalculations = ({ reportType, cat2CheckCalculationsHasExport, calculationsFile }) => {
    if (!reportType.includes("calculations") || !cat2CheckCalculationsHasExport || !calculationsFile) {
      return null;
    }

    const latestCalculationsFileVersion = getLatestFileVersion(calculationsFile);
    const firstExport = latestCalculationsFileVersion.exports[0];

    return displayPdfAttachment({
      key: firstExport.key,
      label: "Check Calculations",
      hasBorders: true,
      attachmentLabel: "check-calculations",
      pagesToExclude: [0],
    });
  };

  displaySupportingInformation = () => {
    const { reportJsonData, attachmentImages } = this.state;

    const supportingInformation = reportJsonData.fields.supportingInformation.value;
    return supportingInformation.map((key, i) => {
      const type = getAttachmentTypeFromKey(key);

      if (type === "PDF") {
        return displayPdfAttachment({
          elementKey: i,
          key,
          fieldName: "supportingInformation",
        });
      } else {
        return (
          <Page size="A4" style={styles.page}>
            <View key={i}>
              <Image style={{ width: "100%" }} src={attachmentImages[key]} />
            </View>
          </Page>
        );
      }
    });
  };

  shouldComponentUpdate = (nextProps, nextState) => {
    if (this.props.isStatic && nextState.isLoading === this.state.isLoading && this.props.zoom === nextProps.zoom) {
      return false;
    }
    return true;
  };

  displayDesignersSignature = () => {
    const { users, taskRevision } = this.props;
    const DESIGNER_SIGNATURE_HEIGHT = 26;

    const designer = users.find((x) => x.id === taskRevision.author);

    let signature = null;

    if (designer) {
      if (designer.signature) {
        signature = (
          <Image
            src={designer.signature}
            style={{
              left: 0,
              top: -15,
              height: DESIGNER_SIGNATURE_HEIGHT,
              width: DESIGNER_SIGNATURE_HEIGHT * (designer.signatureWidth / designer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", top: -20 }}>
            {designer.firstName} {designer.lastName}
          </Text>
        );
      }
    }

    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : "";

    return (
      <View>
        <View style={{ marginTop: 20 }}>
          <Text style={{ ...styles.cover.infoItem, fontWeight: "bold" }} minPresenceAhead={30}>
            {designer?.firstName} {designer?.lastName}
          </Text>
          {designer?.qualifications && designer.qualifications.length > 0 ? (
            <Text
              style={{
                ...styles.cover.infoItem,
                marginTop: -20,
                fontWeight: "bold",
              }}
              minPresenceAhead={30}
            >
              {designer?.qualifications}
            </Text>
          ) : null}

          {designer && (
            <Text
              style={{
                ...styles.cover.infoItem,
                marginTop: -20,
                fontWeight: "bold",
              }}
              minPresenceAhead={30}
            >
              Date: {reviewedAt}
            </Text>
          )}

          <View>{signature}</View>
        </View>
      </View>
    );
  };

  displayPilingPlatform = () => {
    const { task, users } = this.props;
    const { reportJsonData } = this.state;

    const assignedTo = users.find((x) => x.id === task.assignedTo);

    const cell_style = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 1 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const COL_WIDTHS = {
        0: 30,
        1: 70,
      };

      const style = {
        width: `${COL_WIDTHS[col]}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 10pt",
        fontSize: 10,
        backgroundColor: "#fff",
        color: "#333",
      };

      return style;
    };

    const cell_style2 = (row, col) => {
      const borderLeftWidth = col === 0 ? 1 : 0;
      const borderTopWidth = row === 0 ? 1 : 0;
      const borderRightWidth = 1;
      const borderBottomWidth = 1;

      const COL_WIDTHS = {
        0: 10,
        1: 20,
        2: 20,
        3: 20,
        4: 30,
      };

      const style = {
        width: `${COL_WIDTHS[col]}%`,
        borderLeftWidth,
        borderRightWidth,
        borderTopWidth,
        borderBottomWidth,
        borderStyle: "solid",
        borderColor: colors.gray,
        padding: "5pt 10pt",
        fontSize: 10,
        backgroundColor: "#fff",
        color: "#333",
        textAlign: "center",
        fontWeight: "bold",
        height: row === 0 ? undefined : 60,
      };

      return style;
    };

    return (
      <>
        <Page size="A4" style={styles.page}>
          <Text style={[styles.pageTitle]}>Design Statement</Text>
        </Page>
        <Page size="A4" style={styles.page}>
          <Text
            style={{
              ...styles.paragraph,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {task.project.title}
          </Text>
          <Text
            style={{
              ...styles.paragraph,
              textAlign: "center",
              fontWeight: "bold",
            }}
          >
            {task.title}
          </Text>
          <Text style={{ ...styles.paragraph, textAlign: "center" }}>
            <Text style={{ fontWeight: "bold" }}>PPL:</Text> {reportJsonData.fields.ppl.value}
          </Text>
          <Text style={{ ...styles.paragraph, textAlign: "center" }}>
            <Text style={{ fontWeight: "bold" }}>Pile rig:</Text> {reportJsonData.fields.pileRig.value}
          </Text>

          {displayPdfTextarea({
            fieldName: "Design Statement",
            targetReportJsonData: reportJsonData,
            suffix: "design",
            attachmentImages: this.state.attachmentImages,
            styles,
            projectFolder: this.props.projectFolder,
          })}
          {this.displayDesignersSignature()}
        </Page>
        <Page size="A4" style={styles.page}>
          <Text style={[styles.pageTitle]}>Working Platform Certificate</Text>
        </Page>
        <Page size="A4" style={[styles.page, { paddingTop: 25 }]}>
          <Text style={styles.sectionTitle}>Working Platform Certificate (FPS/WPC/4d)</Text>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={false}
            columns={[
              {
                title: "name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "value",
                dataIndex: "value",
                key: "value",
              },
            ]}
            data={[
              {
                name: "Project Name",
                value: task.project.title,
              },
              {
                name: "Work area covered by this certificate",
                value: reportJsonData.fields.certificateWorkArea.value,
              },
            ]}
            style_function={cell_style}
          />
          <Text style={[styles.paragraph, { marginTop: 5 }]}>
            (A sketch or marked up pile layout drawing may be attached to this certificate. Include haul roads and
            gridlines.)
          </Text>
          <Text style={[styles.sectionSubtitle, { fontSize: 11, marginTop: 0, marginBottom: 0 }]}>
            Part 1 – WORKING PLATFORM DESIGN (INCLUDING RAMPS AND ACCESS ROUTES)
          </Text>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={false}
            columns={[
              {
                title: "name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "value",
                dataIndex: "value",
                key: "value",
              },
            ]}
            data={[
              {
                name: "Equipment to be used on site",
                value: reportJsonData.fields.certificateEquipment.value,
              },
              {
                name: "Maximum plant loading",
                value: reportJsonData.fields.certificateMaxLoading.value,
              },
            ]}
            style_function={cell_style}
          />
          <Text style={[styles.paragraph, { marginTop: 5 }]}>
            (Note: BR470 ’Working Platforms for Tracked Plant: Good practice guide to the design, installation,
            maintenance and repair of ground-supported platforms’ is available from IHS BRE Press – Tel 01344 328 038)
          </Text>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={false}
            columns={[
              {
                title: "name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "value",
                dataIndex: "value",
                key: "value",
              },
            ]}
            data={[
              {
                name: "Designer name",
                value: `${assignedTo?.firstName} ${assignedTo?.lastName}`,
              },
              {
                name: "Tel No",
                value: reportJsonData.fields.certificateTelephoneNumber.value,
              },
              {
                name: "Designer Organisation",
                value: reportJsonData.fields.certificateDesignerOrganisation.value,
              },
              {
                name: "Specification of testing required to verify the design",
                value: reportJsonData.fields.certificateSpecificationOfTesting.value,
              },
            ]}
            style_function={cell_style}
          />
          <Text style={[styles.sectionSubtitle, { fontSize: 11, marginBottom: 10 }]}>
            Part 2 – VERIFICATION BY PRINCIPAL CONTRACTOR
          </Text>
          <Text style={[styles.paragraph]}>
            The working platform detailed above has been designed, installed to the design and, if specified, tested to
            safely support the equipment detailed in Part 1 above. The limits of the platform have been clearly
            identified on site as necessary
          </Text>
          <Text style={[styles.paragraph, { fontWeight: "bold", marginBottom: 0 }]}>
            The working platform will be REGULARLY INSPECTED, MAINTAINED, MODIFIED, REPAIRED, and REINSTATED to the
            as-designed condition after any excavation or damage, throughout the period when the equipment is on the
            site. A completed copy of this certificate signed by an authorised person from the Principal Contractor
            shall be given to each user of the working platform prior to commencement of any works on site.
          </Text>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={false}
            columns={[
              {
                title: "name",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "value",
                dataIndex: "value",
                key: "value",
              },
            ]}
            data={[
              {
                name: "Name & Position",
                value: "",
              },
              { name: "Organisation", value: "" },
              { name: "Date", value: "" },
              { name: "Signature", value: "" },
            ]}
            style_function={cell_style}
          />
          <Text style={[styles.paragraph, { textAlign: "center", marginTop: 10 }]}>
            The HSE has worked closely with the FPS to develop this initiative and supports the principle of reducing
            accidents by the certification of properly designed, prepared and maintained working platforms.
          </Text>
        </Page>
        <Page size="A4" style={[styles.page, { paddingTop: 25 }]}>
          <Text style={styles.sectionTitle}>Working Platform Certificate (FPS/WPC/4d)</Text>
          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 15,
                marginBottom: 0,
              },
            ]}
          >
            Working Platform Regular Inspection Log
          </Text>
          <Text style={[styles.paragraph, { fontWeight: "bold", textAlign: "center", marginTop: 0 }]}>
            (To be completed by an authorised representative of the Principal Contractor)
          </Text>
          <Text style={[styles.paragraph, { fontWeight: "bold", textAlign: "center", marginTop: 0 }]}>
            The working platform has been inspected{" "}
            <Text style={{ textStyle: "italic" }}>
              prior to handover and provides safe access for people and plant.
            </Text>{" "}
            All necessary maintenance, modification, repair or re-instatement of the working platform is to the
            as-designed installed condition. If necessary, a revised Working Platform Layout Drawing has been issued to
            the specialist contractor.
          </Text>
          <Table
            style={{ marginTop: 10 }}
            includeHeader={true}
            columns={[
              {
                title: "DATE",
                dataIndex: "name",
                key: "name",
              },
              {
                title: "ORGANISATION",
                dataIndex: "value",
                key: "value",
              },
              {
                title: (
                  <View>
                    <Text>Name {"&"}</Text>
                    <Text>Position</Text>
                  </View>
                ),
                dataIndex: "value",
                key: "value",
              },
              {
                title: "SIGNATURE",
                dataIndex: "value",
                key: "value",
              },
              {
                title: (
                  <View>
                    <Text style={[styles.paragraph, { marginBottom: 0 }]}>COMMENTS</Text>
                    <Text style={[styles.paragraph, { fontSize: 7, marginTop: 0 }]}>
                      (include key details of alteration, modification, maintenance, repair, date of next inspection,
                      and whether or not revised drawing issued etc. as appropriate)
                    </Text>
                  </View>
                ),
                dataIndex: "value",
                key: "value",
              },
            ]}
            data={[{}, {}, {}, {}, {}, {}]}
            style_function={cell_style2}
          />
        </Page>
        <Page size="A4" style={[styles.page, { paddingTop: 25 }]}>
          <Text style={styles.sectionTitle}>Working Platform Certificate (FPS/WPC/4d)</Text>
          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                textAlign: "center",
                marginTop: 15,
                marginBottom: 0,
              },
            ]}
          >
            Guidance on working platforms for tracked plant
          </Text>
          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 30,
              },
            ]}
          >
            1. Design
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.1 <Text> </Text>The HSWA 1974 and CDM Regulations 2015 require the Principal Contractor to appoint
            competent Designers in respect of Working Platform design. This legislation explains how competence can be
            assessed by reference to professional qualifications or professional memberships and by reference to
            practical experience of the design of working platforms. Principal Contractors must be satisfied that a
            competent Designer has been appointed by them in accordance with the relevant legislation before they
            complete and sign the WPC.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.2 <Text> </Text> The stability of tracked plant is fundamentally dependent upon the provision of a
            suitable and sufficient working platform. It must be properly designed and installed to a recognised
            standard. Whilst the same type of rig may be operated by different companies, the design bearing pressures
            may differ due to the specific operating configuration of the rig and/or any modifications. Details of the
            plant to be used and bearing pressures will be provided by the specialist contractor in advance of work
            commencing
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.3 <Text> </Text> TWorking platform design is extremely sensitive to the bearing pressure and type of fill
            used in the platform. (For example, changing the angle of friction of the fill from 35 degrees to 45 degrees
            can halve the platform thickness.) It is therefore advised that the Designer may have to adopt
            conservative/cautious estimates of platform shear strength unless higher values can be demonstrated by
            testing or with reference to appropriate published data.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.4 <Text> </Text> The working platform must be safe for pedestrian access and free draining to prevent the
            build-up of water and slurry. It must be free from harmful materials and contaminants. In the case of
            fine-grained sub-grades, a separation/filter membrane should be installed beneath the platform material to
            inhibit ‘pumping’ and infiltration of the fine-grained soils up into the platform material during wet
            weather (which can impair platform performance and increase maintenance costs).
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.5 <Text> </Text> Proof testing of the platform can be carried out with a suitably sized circular plate
            subjected to the maximum design loading. Such testing, as part of an appropriately designed testing regime,
            should highlight any gross inconsistencies in platform performance. Potentially, significant savings in
            platform thickness and cost may be realised by adopting a more detailed testing strategy.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.6 <Text> </Text> The working platform must have a design life which starts before delivery of the piling
            equipment and ends on completion of all piling works. This includes load testing, integrity testing,
            investigation of non-conformances and any remedial works.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            1.7 <Text> </Text> The specialist contractor is to advise the Principal Contractor at the earliest
            practicable opportunity should the specialist contractor become aware of any circumstances relating to the
            working platform that renders it unsafe.
          </Text>

          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 30,
              },
            ]}
          >
            2. Installation
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            2.1 <Text> </Text> The FPS Working Platform Certificate is mandatory for all sites where a rig or attendant
            plant operates. It must be signed by an authorised representative of the Principal Contractor. This
            signature confirms that the legal duties required under CDM have been carried out.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            2.2 <Text> </Text> If the working platform is to be constructed or removed in phases while piling works are
            ongoing, then the extent of the platform must be clearly defined on the certificate and, in accordance with
            good practice, physically on site. This is particularly important where the platform material is removed
            from an area previously made available to the specialist contractor.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            2.3 <Text> </Text> The working platform must provide safe access for all plant deliveries, sub-contractors
            and personnel associated with the specialist operations. Properly designed and installed, the working
            platform could also provide suitable and safe access for following trades for the whole project.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            2.4 <Text> </Text> Poor definition of the edge of the working platform is a major cause of tracked plant
            instability. It is good practice that the working platform should extend at least 2m beyond the pile
            position/edge of the building to ensure sufficient safe working area for the specialists personnel and
            attendant plant. Where having to work within this 2m zone is unavoidable the Designer is to be informed of
            the requirement to design the platform for working up to its edge.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            2.5 <Text> </Text> Where access ramps are used to move between working levels these must be of sufficient
            gradient and width to allow the plant to move safely with the stability constraints of the machine. Ramps
            must be in a straight line between working areas. Rigs and cranes cannot change direction on ramps. Where a
            change in direction is required, this must be on a flat level platform.
          </Text>

          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 30,
              },
            ]}
          >
            3. Maintenance, modification, repair and reinstatement
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            3.1 <Text> </Text> The working platform must be kept free draining. Water and slurry which is allowed to
            build up on the working platform can hide such hazards as recently constructed piles, trip hazards, uneven
            or unstable ground, services and excavations. Slurry can be transferred to work equipment which increases
            the risk of slips on steps as well as difficult handling of work tools.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            3.2 <Text> </Text> Obstructions encountered during installation of the piling works will generally require
            excavation to remove them. This can create a ‘soft spot’ which can result in the rig overturning. It is
            essential, therefore, that any excavations made in the working platform are reinstated to the designed
            standard, including any reinforcement and separation filter/membrane.
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            3.3 <Text> </Text> The working platform shall be subject to regular inspection by a competent individual
            appointed by the Principal Contractor ( e.g. the Temporary Works Co-ordinator) throughout its design life
            and after any reinstatement or any works which might have modified it. Any damaged or inadequate areas
            identified must be reinstated to the designed standard. Following the regular inspection, the Working
            Platform Regular Inspection Log shall be signed by an authorised representative of the Principal Contractor
            and issued to the specialist contractor with a layout drawing of the working platform amended as
            appropriate.
          </Text>
          <Text
            style={[
              styles.paragraph,
              {
                fontWeight: "bold",
                marginTop: 5,
                marginBottom: 5,
                paddingLeft: 30,
              },
            ]}
          >
            4. Working Platform Layout
          </Text>
          <Text style={[styles.pilingPlatformGuidanceParagraph]}>
            4.1 <Text> </Text> Items that must be included and properly located on the working platform layout drawing
            and be notified by the Principal Contractor to the specialist contractor would include: detail of platform
            edges and 2m delineation, trial pits, services or voids, areas of backfilling, known underground basements;
            areas that are covered by the certificate or permit, test locations (if specified by the Designer of the
            platform) and any other feature that may affect the safety of operations.
          </Text>
        </Page>
      </>
    );
  };

  displayDocument = ({ userIsCat2Checker }) => {
    const { task, users, taskRevision } = this.props;
    const { calculationsFile, reportJsonData } = this.state;
    const reportFile = this.props.file;

    const isPilingPlatform = reportFile.templateId === "piling_platform";

    window.pdfPageNumbers = {};
    window.pdfPageNumbersToDownload = {};
    window.lambdaPdfPageNumbersToSkipBorders = [];

    window.lambdaPdfAssets = [
      {
        bucket: "draughthub-public-assets",
        name: "organisationLogo",
        key: "DC/dc-logo.png",
      },
    ];

    const reviewer = users.find((x) => x.id === taskRevision.checkedBy);
    let taskWithCorrectRevisions = JSON.parse(JSON.stringify(task));
    taskWithCorrectRevisions.revisions.items = task.revisions.items.filter(
      (revision) => revision.createdAt <= taskRevision.createdAt
    );

    const reviewedAt = taskRevision.reviewAcceptDate ? moment(taskRevision.reviewAcceptDate).format("DD/MM/YY") : null;

    const taskRevisionsData = getReportTaskRevisions({
      task: taskWithCorrectRevisions,
      users,
    });

    const REVIEWER_SIGNATURE_HEIGHT = 26;

    let reportType = [];
    if (Array.isArray(reportJsonData.fields.reportType.value)) {
      reportType = reportJsonData.fields.reportType.value;
    }

    let signature = null;

    if (reviewedAt && reviewer) {
      if (reviewer.signature) {
        signature = (
          <Image
            src={reviewer.signature}
            style={{
              left: 60,
              top: -15,
              height: REVIEWER_SIGNATURE_HEIGHT,
              width: REVIEWER_SIGNATURE_HEIGHT * (reviewer.signatureWidth / reviewer.signatureHeight),
            }}
          />
        );
      } else {
        signature = (
          <Text style={{ fontFamily: "MrsSaintDelafield", top: -20, left: 60 }}>
            {reviewer.firstName} {reviewer.lastName}
          </Text>
        );
      }
    }

    let customTaskId = null;
    if (
      reportJsonData.fields.customTaskId &&
      reportJsonData.fields.customTaskId.value.length > 0 &&
      reportJsonData.fields.customTaskId.value !== " "
    ) {
      customTaskId = reportJsonData.fields.customTaskId.value;
    }

    let reportReference = "";

    if (reportFile) {
      if (customTaskId) {
        reportReference = customTaskId;
      } else {
        reportReference = `${reportFile.sheets.items[0].autoGeneratedReferenceNumber} ${taskRevision.name}`;
      }
    }

    const subjectOfCat2Check = getDesignTaskForCat2Check({
      task,
      linkedTasksDetails: this.state.linkedTasksDetails,
    });

    return (
      <Document>
        <Page size="A4" style={styles.page}>
          <Text
            render={() => {
              initialiseLambdaPdfInserts();
              return null;
            }}
            fixed
          />
          {/* <Image allowDangerousPaths src={CoverImage} style={styles.cover.image} /> */}
          <View style={styles.cover.infoContainer}>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Client:</Text> {task.client.name}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Task Number:</Text> {customTaskId ? customTaskId : task.id}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Project Title:</Text> {task.project.title}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Task Title :</Text> {task.title}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Design Risk Category:</Text> Cat {task.catLevel}
            </Text>
            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Report Reference:</Text> {reportReference}
            </Text>

            <Text style={styles.cover.infoItem}>
              <Text style={styles.bold}>Reviewed By:</Text> {reviewer?.firstName} {reviewer?.lastName}
            </Text>
            {reviewer?.qualifications && reviewer.qualifications.length > 0 ? (
              <Text style={{ ...styles.cover.infoItem, marginTop: -20 }}>
                {/* we duplicate the label so that the qualifications align correctly */}
                <Text style={{ ...styles.bold, opacity: 0 }}>Reviewed By:</Text> {reviewer?.qualifications}
              </Text>
            ) : null}
            <View>{signature}</View>
          </View>
        </Page>
        <Page size="A4" style={styles.page} wrap>
          <Text style={styles.pageTitle} break>
            Document Revision History
          </Text>
          {this.displayTaskRevisionHistory(taskRevisionsData)}
        </Page>
        <Page size="A4" style={styles.page} wrap>
          <Text style={[styles.pageTitle, { marginTop: 100 }]} break>
            Table of contents
          </Text>

          {isPilingPlatform && (
            <>
              <Text style={styles.pageSubtitle}>Design Statement </Text>
              <Text style={styles.pageSubtitle}>Working Platform Certificate </Text>
            </>
          )}
          {reportType.includes("design") ? (
            <Text style={styles.pageSubtitle}>Design & Check Certificate </Text>
          ) : reportType.includes("check") ? (
            <Text style={styles.pageSubtitle}>Check Certificate </Text>
          ) : null}

          <Text style={styles.pageSubtitle}>{subjectOfCat2Check ? "Check" : "Design"} Philosophy </Text>

          {reportType.includes("calculations") && calculationsFile ? (
            <Text style={styles.pageSubtitle}>Calculations </Text>
          ) : null}

          {!reportType.includes("cat3_performed") && (
            <Text style={styles.pageSubtitle}>Designers Risk Assessment </Text>
          )}
          {this.shouldDisplayCat2Check() && <Text style={styles.pageSubtitle}>Check Philosophy </Text>}
          {reportJsonData.fields?.checkersCalculations?.value?.length > 0 ||
            (this.shouldDisplayCat2Check() && this.doesCat2CheckHaveCalculations() && (
              <Text style={styles.pageSubtitle}>Checker's Calculations</Text>
            ))}

          <Text style={styles.pageSubtitle}>Supporting Information </Text>
        </Page>
        {isPilingPlatform && this.displayPilingPlatform()}
        {reportType.includes("design") ? (
          <Page size="A4" style={styles.page} wrap>
            <Text style={[styles.pageTitle]} break>
              Design & Check Certificate
            </Text>
          </Page>
        ) : null}
        {reportType.includes("design") ? (
          <Page size="A4" style={styles.page} wrap>
            {this.displayDesignCertificate()}
          </Page>
        ) : null}

        {reportType.includes("design") ? this.displayStandards() : null}
        {reportType.includes("check") ? this.displayCheckCertificate(reportType) : null}
        <Page size="A4" style={styles.page} wrap>
          {this.displayDesignPhilosophy()}
        </Page>
        <Page size="A4" style={styles.page} wrap>
          {isPilingPlatform && (
            <>
              <Text style={styles.sectionSubtitle} minPresenceAhead={30}>
                Brief
              </Text>
              <Text style={styles.paragraph}>
                Carry out a working platform design to allow the {reportJsonData.fields.pileRig.value} to operate at{" "}
                {task.project.title}.
              </Text>
            </>
          )}
          {[
            "Brief",
            "Methodology",
            "Loads",
            "Load Path",
            "Stability",
            "Geotechnical Parameters",
            "Design Life",
            "Sustainability",
            "Assumptions",
            "Exclusions",
          ].map((fieldName) =>
            displayPdfTextarea({
              fieldName,
              targetReportJsonData: reportJsonData,
              suffix: "design",
              attachmentImages: this.state.attachmentImages,
              styles,
              projectFolder: this.props.projectFolder,
            })
          )}
          {isPilingPlatform && (
            <>
              <Text style={styles.sectionSubtitle} minPresenceAhead={30}>
                Design Check Level
              </Text>
              <Text style={styles.paragraph}>{reportJsonData.fields.designCheckLevel.value}</Text>
            </>
          )}
        </Page>
        {reportType.includes("calculations") && calculationsFile ? (
          <Page size="A4" style={styles.page} wrap>
            <Text style={[styles.pageTitle]} break>
              Calculations
            </Text>
          </Page>
        ) : null}
        {reportType.includes("calculations") && calculationsFile && this.displayCalculations({ userIsCat2Checker })}

        {!reportType.includes("cat3_performed") && (
          <Page size="A4" style={styles.page} wrap>
            <Text style={[styles.pageTitle]}>Designers Risk Assessment</Text>
          </Page>
        )}
        {!reportType.includes("cat3_performed") && (
          <Page size="A4" style={[styles.page, { paddingRight: 40, paddingBottom: 30 }]} wrap>
            {this.displayDesignersRiskAssessment()}
          </Page>
        )}

        {reportType.includes("cat3_requested") && (
          <>
            <Page size="A4" style={styles.page} wrap>
              <Text style={[styles.pageTitle]}>Cat III Check</Text>
            </Page>
            {this.displayCat3Check(reportType)}
          </>
        )}
        {this.shouldDisplayCat2Check() && (
          <Page size="A4" style={styles.page} wrap>
            <Text style={[styles.pageTitle]}>Check Philosophy</Text>
          </Page>
        )}

        {this.shouldDisplayCat2Check() && this.displayCat2Check(reportType)}

        <Page size="A4" style={styles.page} wrap>
          <Text style={[styles.pageTitle]}>Supporting Information</Text>
        </Page>
        {Array.isArray(reportJsonData.fields.supportingInformation.value) &&
        reportJsonData.fields.supportingInformation.value.length > 0
          ? this.displaySupportingInformation()
          : null}

        <EndCover colors={colors} styles={styles} />
      </Document>
    );
  };

  isUserCat2Checker = () => {
    const { apiUser, task } = this.props;
    const { linkedTasksDetails } = this.state;
    if (!linkedTasksDetails) {
      return;
    }
    const cat2CheckTask = getCat2Check({
      task,
      linkedTasksDetails: linkedTasksDetails,
    });
    let userIsCat2Checker = false;
    if (cat2CheckTask && apiUser) {
      userIsCat2Checker = cat2CheckTask.assignedTo === apiUser.id;
    }
    return userIsCat2Checker;
  };

  render() {
    const { task, users, taskRevision, layout = "default", renderMode } = this.props;
    const { isLoading } = this.state;

    if (!task || !users || !taskRevision || isLoading) {
      return (
        <div className="report-preloader">
          <LoadingOutlined />
        </div>
      );
    }

    const userIsCat2Checker = this.isUserCat2Checker();

    return (
      <div className="report report-DC">
        <ReportPreview
          document={this.displayDocument({ userIsCat2Checker })}
          layout={layout}
          renderMode={renderMode}
          renderKey={JSON.stringify(this.state.reportJsonData)}
          // onDataUri={userIsCat2Checker ? undefined : this.props.onDataUri}
          onDataUri={this.props.onDataUri}
        />
      </div>
    );
  }
}

export default React.memo(ReportDC);
