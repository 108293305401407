import moment from "moment";

import { KEY_TYPES } from "../shared";
import log from "../log";
import { getFullDetailsForNaming } from "./namingHelpers";

/*
  Naming scheme:
  <Year>–<Business Unit>–<Project number>–<job number>–<design document ref>–<sheet number> <revision code>
  e.g. "23-STH-100-23-DR-01 P1"
*/

export async function getFileName({ data, type }) {
  // await log({
  //   level: "DEBUG",
  //   message: "START",
  //   method: "EIS:getFileName",
  //   details: {
  //     data,
  //     type,
  //   },
  // });

  if (data.fileType.toUpperCase() === "QUOTE") {
    return data.quoteId;
  }

  if (data.fileType.toUpperCase() === "INVOICE") {
    return data.invoiceId;
  }

  if (data.fileType.toUpperCase() === "PURCHASE_ORDER") {
    return data.purchaseOrderId;
  }

  const { fileTypeCount, taskIdWithoutOrg, task, file, organisationDetails } = await getFullDetailsForNaming(
    data,
    type
  );

  let templateDetails = organisationDetails.templates?.items?.find((x) => x.id === file.templateId);

  let sheet;

  if (type === KEY_TYPES.FILE_SHEET_EXPORT || type === KEY_TYPES.FILE_SHEET_EXPORT_RAW) {
    sheet = file.sheets.items.find((x) => x.name === data.sheetName);
  }

  let suffix = "";

  if (templateDetails?.name === "KPI report for task revision due dates") {
    return `KPI report - due dates - endOfLastMonth`;
  }

  switch (data.fileType.toUpperCase()) {
    case "BRICSCAD":
    case "REVIT":
    case "AUTOCAD":
      suffix += `DR`;
      if (sheet) {
        suffix += ` ${sheet?.name}`;
      }
      if ([KEY_TYPES.SHEET_REFERENCE].includes(type)) {
        let sheetIndex = parseInt(data.hasOwnProperty("sheetIndex") ? data.sheetIndex : file.sheets.items.length);
        suffix += `-${(100 + sheetIndex + 1).toString().substring(1)}`;
      } else if (!type.toUpperCase().includes("EXPORT")) {
        suffix += ` ${data.versionNumber}`;
      }
      break;
    case "WORD":
    case "BLUEBEAM":
    case "REPORT":
      switch (templateDetails?.name) {
        case "Calculations":
          suffix = "CA";
          break;
        case "Risk Assessment":
          suffix = "RA";
          break;
        case "Checking sheet":
          suffix = "CHK";
          break;
        case "Task document transmittal":
        case "Project document transmittal":
        case "Document transmittal":
          suffix = "TRANSMITTAL";
          break;
        case "Design certificate":
          suffix = "DESIGN_CERT";
          break;
        case "Check certificate":
          suffix = "CHECK_CERT";
          break;
        default:
          suffix = templateDetails.name?.toUpperCase().split(" ").join("_");
      }

      break;

    case "EXCEL":
      suffix = "CA";
      if (type.toUpperCase().includes("EXPORT")) {
        suffix += " XxX";
      }
      if (type?.includes("SHEET") && data.sheetName) {
        suffix += ` ${data.sheetName}`;
      }
      break;

    default:
      throw new Error(`Unknown file type:`, data.fileType.toUpperCase());
  }

  let result = `${data.taskId}-${suffix || ""}`;

  // log({
  //   level: "DEBUG",
  //   message: "END",
  //   method: "EIS:getFileName",
  //   details: {
  //     result,
  //   },
  // });
  return result;
}

async function changeFileNameAtDownloadTime(data) {
  let { fileName, sheetRevisionName, file, type, task, invoice, projects, clients } = data;
  if (type === KEY_TYPES.INVOICE) {
    let date = moment(invoice.createdAt);

    const projectDetails = projects.find((x) => x.id === invoice.projectId);
    const clientDetails = clients.find((x) => x.id === invoice.clientId);

    return `${clientDetails?.name} ${projectDetails?.title} ${date.format("MMM YYYY")} ${invoice.id}.pdf`;
  }

  let updatedFileName = fileName;

  switch (type) {
    case KEY_TYPES.FILE_MAIN_EXPORT:
    case KEY_TYPES.FILE_MAIN_EXPORT_RAW:
      if (fileName.includes("XxX")) {
        updatedFileName = fileName.split("XxX").join(sheetRevisionName || "");
      }
      updatedFileName = fileName;
      break;

    case KEY_TYPES.FILE_SHEET_EXPORT:
    case KEY_TYPES.FILE_SHEET_EXPORT_RAW:
      updatedFileName = fileName.split("XxX").join(sheetRevisionName);
      break;

    default:
      updatedFileName = fileName;
      break;
  }

  if (updatedFileName.startsWith("EIS-")) {
    updatedFileName = updatedFileName.substring(4);
  }

  if (updatedFileName.includes("endOfLastMonth")) {
    updatedFileName = updatedFileName.split("endOfLastMonth").join(moment().subtract(1, "month").format("MMMM YYYY"));
  }

  if (file && task && !task.isHidden) {
    const taskRevision = task.revisions?.items?.find((x) => x.id === file.taskRevisionId);
    if (taskRevision && !updatedFileName.endsWith(`${taskRevision.name}`)) {
      updatedFileName += ` ${taskRevision.name}`;
    }
  }

  return updatedFileName;
}

function changeSheetReference({ sheet, file, referenceNumber }) {
  referenceNumber = `${referenceNumber} ${sheet.revisions.items.slice(-1)[0].name}`;

  if (!["AUTOCAD", "REVIT", "BRICSCAD"].includes(file?.type.toUpperCase())) {
    let referenceNumberWithoutSheet = referenceNumber.split("Sheet1").join("");
    // eliminate double spaces
    while (referenceNumberWithoutSheet.includes("  ")) {
      referenceNumberWithoutSheet = referenceNumberWithoutSheet.split("  ").join(" ");
    }

    return referenceNumberWithoutSheet;
  } else {
    return referenceNumber;
  }
}

function getProjectId({ organisation, extraOffset, clientDetails }) {
  const yearNumber = parseInt(moment().format("YY"));
  const projectNumber =
    parseInt(organisation.projectIdOffset || 0) + parseInt(organisation.projectCount || 0) + 1 + parseInt(extraOffset);

  return `${organisation.id}-${yearNumber}-${
    clientDetails?.initials ? `${clientDetails.initials.trim()}-` : ""
  }${projectNumber}`;
}

async function getTaskId({ organisation, projectDetails, extraOffset, clientDetails }) {
  const taskNumber = parseInt(projectDetails.taskCount || 0) + 1 + parseInt(extraOffset);
  let taskNumberAsString = taskNumber >= 10 ? `${taskNumber}` : `0${taskNumber}`;

  return `${projectDetails.id}-${taskNumberAsString}`;
}

function getQuoteId({ organisation, projectDetails, extraOffset }) {
  const quoteNumber = parseInt(projectDetails.quoteCount || 0) + 1 + parseInt(extraOffset);
  let quoteNumberAsString = quoteNumber >= 10 ? `${quoteNumber}` : `0${quoteNumber}`;
  return `${projectDetails.id}-Q${quoteNumberAsString}`;
}

function getInvoiceId({ organisation, projectDetails, extraOffset }) {
  const invoiceNumber = parseInt(projectDetails.invoiceCount || 0) + 1 + parseInt(extraOffset);
  let invoiceNumberAsString = invoiceNumber >= 10 ? `${invoiceNumber}` : `0${invoiceNumber}`;
  return `${projectDetails.id}-INV${invoiceNumberAsString}`;
}

function getPurchaseOrderId({ organisation, projectDetails, extraOffset }) {
  const poNumber = parseInt(projectDetails.purchaseOrderCount || 0) + 1 + parseInt(extraOffset);
  return `${projectDetails.id}-PO${poNumber}`;
}

function getSheetDescription({ task, file, taskRevision, sheetCount }) {
  const fileType = file.type;
  let sheetCountForFileType = sheetCount;
  const latestTaskRevision = taskRevision || task.revisions.items[task.revisions.items.length - 1];

  if (latestTaskRevision?.files?.items) {
    latestTaskRevision.files.items.forEach((crtFile) => {
      if (crtFile.type !== fileType || crtFile.isArchived) {
        return;
      }

      sheetCountForFileType += crtFile.sheets.items.filter((x) => x.includeInPublish).length;
    });
  }

  if (file.type === "EXCEL") {
    return String(1001 + sheetCountForFileType).substring(1);
  }
  return String(101 + sheetCountForFileType);
}

function getTaskRevisionName({ task, newStatus }) {
  let newStatusIsConstruction = newStatus.toUpperCase().split(" ").join("_") === "CONSTRUCTION";
  if (!task || !task.revisions || !task.revisions.items || task.revisions.items.length === 0) {
    return newStatusIsConstruction ? "C1" : "P1";
  }

  const nonArchivedRevisions = task.revisions.items.filter((x) => !x.isArchived);
  const latestTaskRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestTaskRevision.status?.toUpperCase().split(" ").join("_") === "CONSTRUCTION";

  let newName = "";
  if (newStatusIsConstruction) {
    if (previousStatusIsConstruction) {
      let numberPart = (parseInt(latestTaskRevision.name.substring(1)) || 0) + 1;
      newName = `C${numberPart}`;
    } else {
      newName = "C1";
    }
  } else {
    let numberPart = (parseInt(latestTaskRevision.name.substring(1)) || 0) + 1;
    newName = `P${numberPart}`;
  }

  return newName;
}

function getSheetRevisionName({ sheet, newStatus }) {
  let newStatusIsConstruction = newStatus?.toUpperCase().split(" ").join("_") === "CONSTRUCTION";
  if (!sheet || !sheet.revisions || !sheet.revisions.items || sheet.revisions.items.length === 0) {
    return newStatusIsConstruction ? "C1" : "P1";
  }

  const nonArchivedRevisions = sheet.revisions.items.filter((x) => !x.isArchived);
  const latestSheetRevision = nonArchivedRevisions[nonArchivedRevisions.length - 1];

  let previousStatusIsConstruction = latestSheetRevision.status.toUpperCase().split(" ").join("_") === "CONSTRUCTION";

  let newName = "";
  if (newStatusIsConstruction) {
    if (previousStatusIsConstruction) {
      let numberPart = (parseInt(latestSheetRevision.name.substring(1)) || 0) + 1;
      newName = `C${numberPart}`;
    } else {
      newName = "C1";
    }
  } else {
    let numberPart = (parseInt(latestSheetRevision.name.substring(1)) || 0) + 1;
    newName = `P${numberPart}`;
  }

  return newName;
}

async function getFrontendFileName({ organisationDetails, task, taskRevision, templateId, fileType }) {
  if (!templateId) {
    return;
  }
  const templateDetails = organisationDetails.templates.items.find((x) => x.id === templateId);
  const filesWithSameTemplate = taskRevision.files.items.filter((file) => file.templateId === templateDetails.id);
  let filesWithSameTemplateCount = filesWithSameTemplate.length;
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (deletedFilesByType[fileType]) {
    filesWithSameTemplateCount += deletedFilesByType[fileType];
  }

  const result = `${templateDetails.name} - ${String(1000 + filesWithSameTemplateCount + 1).substring(1)}`;
  return result;
}

const functions = {
  getFileName,
  changeFileNameAtDownloadTime,
  changeSheetReference,
  getProjectId,
  getTaskId,
  getQuoteId,
  getInvoiceId,
  getPurchaseOrderId,
  getSheetDescription,
  getTaskRevisionName,
  getSheetRevisionName,
  getFrontendFileName,
};

export default functions;
