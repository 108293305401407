import { ExclamationCircleOutlined, FolderOpenOutlined, DeleteOutlined, CheckCircleOutlined } from "@ant-design/icons";
import { Modal, Button, Typography, Divider, message, notification, Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { updateTask, updateTaskRevision } from "graphql/queries_custom";
import { graphqlOperation } from "aws-amplify";
import { getLatestRevision } from "common/shared";
import { callGraphQL, deleteTaskInApi, archiveTask, getLabel } from "common/helpers";
import { isAuthorised } from "common/permissions";
import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";

import "./TaskActions.scss";

export function TaskActions({ task, history, onDelete, organisationDetails }) {
  function confirmArchive() {
    if (task.isUnderReview) {
      notification.open({
        placement: "bottomRight",
        type: "error",
        message: (
          <>
            <Typography.Title level={3}>
              {getLabel({
                id: "Task",
                defaultValue: "Task",
              })}{" "}
              cannot be archived while it is under review
            </Typography.Title>
            <Typography.Paragraph>
              Cancel the review in order to be able to archive the{" "}
              {getLabel({
                id: "task",
                defaultValue: "task",
              })}
            </Typography.Paragraph>
          </>
        ),
      });

      return;
    }
    Modal.confirm({
      title: `Archive ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      icon: <ExclamationCircleOutlined />,
      content: (
        <>
          Are you sure you want to archive <b>{task.title}</b>?
        </>
      ),
      onOk: async () => {
        await archiveTask(task);
      },
    });
  }

  function confirmNotArchive() {
    Modal.confirm({
      title: `Restore ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      icon: <CheckCircleOutlined />,
      content: (
        <>
          Are you sure you want to restore{" "}
          {getLabel({
            id: "task",
            defaultValue: "task",
          })}{" "}
          <b>{task.title}</b>?
        </>
      ),
      onOk: async () => {
        await callGraphQL(
          `Failed to restore ${getLabel({
            id: "task",
            defaultValue: "task",
          })}`,
          graphqlOperation(updateTask, {
            input: {
              id: task.id,
              isArchived: false,
              archivedAt: null,
              isReadOnly: false,
              itemSubscription: Math.floor(Math.random() * 1000000),
            },
          })
        );

        await window.callGraphQLSimple({
          mutation: "createTaskActivityItem",
          message: `Failed to record ${getSimpleLabel("task")} activity item`,
          variables: {
            input: {
              taskId: task.id,
              author: window.apiUser.id,
              organisation: organisationDetails.id,
              type: "RESTORED",
            },
          },
        });

        if (task.revisions && task.revisions.items.length > 0) {
          const latestTaskRevision = getLatestRevision(task);
          if (latestTaskRevision.reviewStatus !== "SUCCESS") {
            await callGraphQL(
              `Failed to update old ${getLabel({
                id: "task revision",
                defaultValue: "task revision",
              })}`,
              graphqlOperation(updateTaskRevision, {
                input: {
                  id: latestTaskRevision.id,
                  isReadOnly: false,
                },
              })
            );
          }
        }

        message.success(
          <Typography.Text>
            <b>{task.title}</b> has been restored
          </Typography.Text>
        );
      },
    });
  }

  function confirmDelete() {
    Modal.confirm({
      title: "Delete task",
      icon: <ExclamationCircleOutlined className="danger" />,
      cancelText: "I understand, delete",
      okText: "Cancel",
      content: (
        <>
          Are you sure you want to delete <b>{task.title}</b>?
          <br />
          <Typography.Text className="danger">
            Once deleted, a{" "}
            {getLabel({
              id: "task",
              defaultValue: "task",
            })}{" "}
            is gone forever.
          </Typography.Text>
        </>
      ),
      onCancel: async () => {
        try {
          await deleteTaskInApi(task);

          message.success(
            <Typography.Text>
              <b>{task.title}</b> has been deleted
            </Typography.Text>
          );
          if (onDelete) {
            onDelete();
          } else {
            history.push("/");
          }
        } catch (e) {
          // It means we didn't actually delete the task, nothing to be done here.
        }
      },
    });
  }

  function displayArchiveActions() {
    if (task.isFinished) {
      return null;
    }

    if (task.isArchived) {
      return (
        <>
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">
                Restore{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}
              </Typography.Paragraph>
              <Typography.Paragraph className="description">
                This will put the{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}{" "}
                back in the "active{" "}
                {getLabel({
                  id: "tasks",
                  defaultValue: "tasks",
                })}
                " dashboard and users will be able to make changes to it again.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                disabled={task.project.isArchived}
                icon={<FolderOpenOutlined />}
                onClick={confirmNotArchive}
                type="danger"
                className="mark-as-not-archived"
              >
                {task.project.isArchived
                  ? `${getLabel({
                      id: "Project",
                      defaultValue: "Project",
                    })} is archived`
                  : "Restore"}
              </Button>
            </Col>
          </Row>
        </>
      );
    } else {
      return (
        <>
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">
                Archive this{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}
              </Typography.Paragraph>
              <Typography.Paragraph className="description">
                Mark this{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}{" "}
                as archived and read-only.
                <br />
                This action can be reversed.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button
                disabled={task.project.isArchived || !isAuthorised(["TASK.ARCHIVE"])}
                icon={<FolderOpenOutlined />}
                type="danger"
                onClick={confirmArchive}
              >
                {task.project.isArchived ? "Project is archived" : "Archive"}
              </Button>
            </Col>
          </Row>
        </>
      );
    }
  }

  return (
    <Card className="task-actions">
      {displayArchiveActions()}

      {organisationDetails.usesDelete && isAuthorised(["TASK.DELETE"]) && (
        <>
          <Divider />
          <Row align="middle">
            <Col span={16}>
              <Typography.Paragraph className="main-label">
                Delete this{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}
              </Typography.Paragraph>
              <Typography.Paragraph className="description">
                Once you delete a{" "}
                {getLabel({
                  id: "task",
                  defaultValue: "task",
                })}
                , there is no going back. Please be certain.
              </Typography.Paragraph>
            </Col>
            <Col span={8} style={{ textAlign: "right" }}>
              <Button icon={<DeleteOutlined />} type="danger" onClick={confirmDelete}>
                Delete
              </Button>
            </Col>
          </Row>
        </>
      )}
    </Card>
  );
}

export default withRouter(TaskActions);
