import { getLabel } from "common/helpers";
import { Modal, Button } from "antd";
import { calculateTaskFinancials } from "common/financialHelpers";
import { CloseCircleOutlined } from "@ant-design/icons";

interface Props {
  taskId: string;
  quoteLineItemId: string;
  changeLineItem: (args: { fieldName: string; id: string; value: string | null }) => Promise<void>;
  quoteLineItemTitle: string;
  refreshQuote: () => void;
}

export default function UnlinkTaskFromQuoteLineItemButton({
  taskId,
  quoteLineItemId,
  quoteLineItemTitle,
  changeLineItem,
  refreshQuote,
}: Props) {
  function onClick() {
    Modal.confirm({
      title: `Confirm unlink ${getLabel({
        id: "task",
        defaultValue: "task",
      })}`,
      maskClosable: true,
      content: (
        <>
          Are you sure you want to unlink {getLabel({ id: "task", defaultValue: "task" })} <b>{taskId}</b> from the line
          item <b>{quoteLineItemTitle}</b>?
        </>
      ),
      okText: "Yes, unlink",
      onOk: unlinkTask,
    });
  }

  async function unlinkTask() {
    await changeLineItem({
      fieldName: "resultingTaskId",
      id: quoteLineItemId,
      value: "nothing",
    });
    refreshQuote();
    // update task financials
    // await calculateTaskFinancials(taskId);

    try {
      let failedOperationMessage = `Failed to unlink ${getLabel({
        id: "task",
        defaultValue: "task",
      })} from ${getLabel({
        id: "quote",
        defaultValue: "quote",
      })} line item`;

      window.callGraphQLSimple({
        message: failedOperationMessage,
        queryCustom: "updateTask",
        variables: {
          input: {
            id: taskId,
            itemSubscription: Math.floor(Math.random() * 100000),
          },
        },
      });
    } catch (e) {
      console.error(e);
    }
  }

  return (
    <Button
      data-cy="line-item-unlink-task-button"
      onClick={onClick}
      style={{ marginTop: "0.5rem" }}
      icon={<CloseCircleOutlined />}
    >
      Unlink task
    </Button>
  );
}
