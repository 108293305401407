import { useState, useEffect } from "react";
import { Button, Modal, message, Typography, notification } from "antd";
import { SendOutlined, DeleteOutlined, FilePdfOutlined, EyeOutlined, SyncOutlined } from "@ant-design/icons";

import withSubscriptions from "common/withSubscriptions";
import { confirmDeleteInvoice } from "common/invoiceHelpers";
import { callRest } from "common/apiHelpers";
import { THIRD_PARTY_APPS } from "common/thirdPartyApps";

import Card from "Card/Card";
import Switch from "Switch/Switch";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import AccountancyContactModal from "Modals/AccountancyContactModal/AccountancyContactModal";
import SelectExistingContactModal from "Modals/SelectExistingContactModal/SelectExistingContactModal";

import "./InvoiceActions.scss";

export function InvoiceActions({
  invoice,
  isPreviewEnabled,
  isDownloadingInvoice,
  isSendingInvoice,
  onPreviewSwitch,
  restoreInvoice,
  // archiveInvoice,
  downloadInvoice,
  sendInvoice,
  windowWidth,
  onRequestReviewClick,
  organisationDetails,
  clientDetails,
  history,
  setProps,
  context,
  apiUser,
  users,
  clients,
  showApprovedPdf,
  approvedPdfData,
  reviewFullyApprovedAt,
  recalculateInvoiceAmountsViaApi,
  refreshInvoice,
}) {
  const [isAccountancyContactModalVisible, setIsAccountancyContactModalVisible] = useState(false);
  const [isSelectExistingContactModalVisible, setIsSelectExistingContactVisible] = useState(false);
  const [accountancyInvoiceId, setAccountancyInvoiceId] = useState();
  const [isLoadingAccountancyInvoiceId, setIsLoadingAccountancyInvoiceId] = useState(true);
  const accountancyIntegration = organisationDetails.integrations?.find(
    (integration) => integration?.authorisedAt && (integration?.id === "XERO" || integration?.id === "QUICKBOOKS")
  );
  const accountancyIntegrationFullDetails = THIRD_PARTY_APPS.find(
    (integration) => integration?.id === accountancyIntegration?.id
  );
  let accountancyButton = null;

  useEffect(() => {
    getAccountancyInvoiceId();
  }, [invoice.itemSubscription]); // eslint-disable-line react-hooks/exhaustive-deps

  async function createNewAccountancyContact() {
    let clientFullAddress;

    if (invoice.clientAddress) {
      clientFullAddress = clientDetails.addresses.find((address) => address.id === invoice.clientAddress);
    }

    if (accountancyIntegration.id === "XERO") {
      try {
        const createXeroContactResponse = await callRest({
          route: "/create-xero-contact",
          method: "POST",
          body: {
            contactName: clientDetails.name,
            organisationId: invoice.organisation,
            clientFullAddress,
          },
        });

        const newXeroContact = createXeroContactResponse?.body?.contacts[0];
        await sendInvoiceToXero(newXeroContact);
      } catch (err) {
        notification.error({
          message: (
            <Typography.Text>
              Could not create Xero contact:
              <br />
              {err.response?.data}
            </Typography.Text>
          ),
        });
      }
    }

    if (accountancyIntegration.id === "QUICKBOOKS") {
      try {
        const createQuickbooksContactResponse = await callRest({
          route: "/create-quickbooks-contact",
          method: "POST",
          body: {
            contactName: clientDetails.name,
            organisationId: invoice.organisation,
            clientFullAddress,
          },
        });

        const newQuickbooksContact = createQuickbooksContactResponse;
        await sendInvoiceToQuickbooks(newQuickbooksContact);
      } catch (err) {
        notification.error({
          message: (
            <Typography.Text>
              Could not create Quickbooks contact:
              <br />
              {err.response?.data}
            </Typography.Text>
          ),
        });
      }
    }
  }

  async function getAccountancyInvoiceId() {
    if (accountancyIntegration) {
      try {
        const getAccountancyInvoiceIdResponse = await callRest({
          method: "POST",
          route: "/get-accountancy-invoice-id",
          body: {
            organisationId: organisationDetails.id,
            integration: accountancyIntegration.id,
            invoiceId: invoice.id,
          },
        });

        if (accountancyIntegration.id === "XERO" && getAccountancyInvoiceIdResponse?.body?.invoices[0]?.invoiceID) {
          const accountancyInvoiceId = getAccountancyInvoiceIdResponse?.body?.invoices[0]?.invoiceID;

          setAccountancyInvoiceId(accountancyInvoiceId);
        }

        if (
          accountancyIntegration.id === "QUICKBOOKS" &&
          getAccountancyInvoiceIdResponse?.QueryResponse?.Invoice[0]?.Id
        ) {
          const accountancyInvoiceId = getAccountancyInvoiceIdResponse?.QueryResponse?.Invoice[0]?.Id;

          setAccountancyInvoiceId(accountancyInvoiceId);
        }
      } catch (err) {
        // Nothing, it just means the invoice hasn't been sent to the accountancy software yet
      }

      setIsLoadingAccountancyInvoiceId(false);
    } else {
      setIsLoadingAccountancyInvoiceId(false);
    }
  }

  if (accountancyIntegration) {
    if (isLoadingAccountancyInvoiceId) {
      accountancyButton = null;
    } else if (accountancyInvoiceId) {
      accountancyButton = (
        <ButtonWithPermissions
          permissions={["INVOICE_DETAILS.VIEW_ACCOUNTANCY_PAGE", "FULL.READ_WRITE"]}
          type="dark"
          icon={<EyeOutlined />}
          onClick={() => {
            if (accountancyIntegration.id === "XERO") {
              window.open(
                `https://go.xero.com/AccountsReceivable/View.aspx?InvoiceID=${accountancyInvoiceId}`,
                "_blank",
                ""
              );
            }

            if (accountancyIntegration.id === "QUICKBOOKS") {
              let redirectLink;

              if (window.location.origin.includes("draughthub.com")) {
                redirectLink = `https://app.qbo.intuit.com/app/invoice?txnId=${accountancyInvoiceId}`;
              } else {
                redirectLink = `https://app.sandbox.qbo.intuit.com/app/invoice?txnId=${accountancyInvoiceId}`;
              }

              window.open(redirectLink, "_blank", "");
            }
          }}
        >
          See invoice in {accountancyIntegrationFullDetails.NAME}
        </ButtonWithPermissions>
      );
    } else {
      accountancyButton = (
        <ButtonWithPermissions
          disabled={invoice.status !== "SENT"}
          type="dark"
          icon={<SendOutlined />}
          permissions={["INVOICE_DETAILS.SEND_TO_ACCOUNTANCY", "FULL.READ_WRITE"]}
          onClick={async () => await sendInvoiceToAccountancy()}
        >
          Send to {accountancyIntegrationFullDetails.NAME}
        </ButtonWithPermissions>
      );
    }
  }

  async function findMatchingContact(contactType) {
    try {
      const clientContactsResponse = await callRest({
        route: `/get-${contactType}-contacts`,
        method: "POST",
        body: {
          organisationId: invoice.organisation,
        },
        includeCredentials: false,
      });

      let accountancyMatchingContact;

      if (contactType === "xero") {
        accountancyMatchingContact = clientContactsResponse?.body?.contacts?.find(
          (accountancyContact) => accountancyContact?.name?.trim() === clientDetails?.name?.trim()
        );
      }

      if (contactType === "quickbooks") {
        accountancyMatchingContact = clientContactsResponse?.QueryResponse?.Customer?.find(
          (accountancyContact) => accountancyContact?.DisplayName?.trim() === clientDetails?.name?.trim()
        );
      }

      return accountancyMatchingContact;
    } catch (err) {
      notification.error({
        message: (
          <Typography.Text>
            Could not retrieve contacts:
            <br />
            {err.response?.data}
          </Typography.Text>
        ),
      });
    }
  }

  async function sendInvoiceToXero(xeroContact) {
    try {
      await callRest({
        route: "/xero-create-invoice",
        method: "post",
        body: {
          organisationId: invoice.organisation,
          invoiceId: invoice.id,
          contact: xeroContact,
        },
        includeCredentials: false,
      });
      await refreshInvoice();
      message.success("Invoice sent to Xero successfully");
    } catch (err) {
      const hasValidationErrorsArray = Array.isArray(err.response?.data);
      let title;

      if (hasValidationErrorsArray && err.response?.data?.length > 0) {
        title = "Multiple validation errors occured";
      } else {
        title = "A validation error occured";
      }

      Modal.warning({
        title,
        className: "validation-errors-modal",
        content: (
          <div>
            {hasValidationErrorsArray ? (
              <ol className="quickbooks-validation-errors-list">
                {err.response?.data?.map((error) => (
                  <li className="validation-error-message">{error}</li>
                ))}
              </ol>
            ) : (
              <div>{err.response?.data}</div>
            )}
          </div>
        ),
      });
    }
  }

  async function sendInvoiceToQuickbooks(quickbooksContact) {
    if (!quickbooksContact) {
      Modal.warning({
        title: "No contact found",
        content: <></>,
      });
    }

    try {
      await callRest({
        route: "/quickbooks-create-invoice",
        method: "post",
        body: {
          organisationId: invoice.organisation,
          invoiceId: invoice.id,
          contactDetails: quickbooksContact,
        },
        includeCredentials: false,
      });
      await refreshInvoice();
      message.success("Invoice sent to Quickbooks successfully");
    } catch (err) {
      const hasValidationErrorsArray = Array.isArray(err.response?.data);
      let title;

      if (hasValidationErrorsArray && err.response?.data?.length > 0) {
        title = "Multiple validation errors occured";
      } else {
        title = "A validation error occured";
      }

      Modal.warning({
        title,
        className: "validation-errors-modal",
        content: (
          <div>
            {hasValidationErrorsArray ? (
              <ol className="quickbooks-validation-errors-list">
                {err.response?.data?.map((error) => (
                  <li className="validation-error-message">{error}</li>
                ))}
              </ol>
            ) : (
              <div>{err.response?.data}</div>
            )}
          </div>
        ),
      });
    }
  }

  async function sendInvoiceToAccountancy() {
    const hasLineItems = invoice.lineItems?.items && invoice.lineItems?.items?.length > 0;

    if (hasLineItems) {
      const lineItems = invoice.lineItems?.items;
      let everyLineItemHasADescriptionOrTitle = lineItems?.every(
        (lineItem) => lineItem?.title || lineItem?.description
      );

      if (!everyLineItemHasADescriptionOrTitle) {
        Modal.error({
          title: "Some line items are missing either a title or a description",
          content: "Please add a title or a description to each line item.",
        });

        return;
      }

      Modal.confirm({
        title: `Are you sure you want to send this invoice to ${accountancyIntegrationFullDetails.NAME}?`,
        content: (
          <>
            <Typography.Text>
              If you confirm, DraughtHub will generate a new invoice in {accountancyIntegrationFullDetails.NAME} on your
              behalf.
            </Typography.Text>
          </>
        ),
        onOk: async () => {
          if (accountancyIntegration?.id === "XERO") {
            const xeroMatchingContact = await findMatchingContact("xero");

            if (xeroMatchingContact) {
              try {
                await sendInvoiceToXero(xeroMatchingContact);
              } catch (err) {
                message.warning("Failed to send invoice to Xero. Please contact support.");
              }
            } else {
              setIsAccountancyContactModalVisible(true);
            }
          }

          if (accountancyIntegration?.id === "QUICKBOOKS") {
            const quickbooksMatchingContact = await findMatchingContact("quickbooks");

            if (quickbooksMatchingContact) {
              await sendInvoiceToQuickbooks(quickbooksMatchingContact);
            } else {
              setIsAccountancyContactModalVisible(true);
            }
          }
        },
      });
    } else {
      Modal.warning({
        title: "No line items",
        content: (
          <>
            <Typography.Text>
              This invoice has no line items. Please add line items before sending this invoice to{" "}
              {accountancyIntegrationFullDetails.NAME}.
            </Typography.Text>
          </>
        ),
      });
    }
  }

  function displayPreviewToggle() {
    if (!reviewFullyApprovedAt) {
      return (
        <Switch
          disabled={windowWidth < 1000}
          checked={isPreviewEnabled && windowWidth >= 1000}
          onChange={onPreviewSwitch}
          className="preview-switch"
          label="Display preview"
        />
      );
    }

    return (
      <Button type="primary" onClick={showApprovedPdf} disabled={!approvedPdfData}>
        Show approved version
      </Button>
    );
  }

  return (
    <div className="invoice-actions-container">
      <Card className="invoice-actions" withSpace>
        <div className="actions-container">
          {invoice.isArchived ? (
            <Button onClick={restoreInvoice} type="primary">
              Restore invoice
            </Button>
          ) : (
            <Button
              icon={<DeleteOutlined />}
              onClick={() =>
                confirmDeleteInvoice({ invoiceId: invoice.id, organisationId: organisationDetails.id, history })
              }
            >
              Delete
            </Button>
          )}

          {displayPreviewToggle()}

          {/* {review && (
            <Switch
              // disabled={invoice.isArchived}
              checkedChildren="Comments visible"
              unCheckedChildren="Comments hidden"
              checked={areCommentsVisible}
              onChange={onCommentsSwitch}
              className="comments-switch"
            />
          )} */}

          <div className="download-and-send">
            {accountancyButton}
            {/* {(apiUser.isHidden || window.location.hostname === "localhost") && (
              <Button
                type="primary"
                disabled={invoice.isArchived}
                icon={<SyncOutlined />}
                onClick={recalculateInvoiceAmountsViaApi}
              >
                Recalculate amounts
              </Button>
            )} */}
            {!invoice.reviews?.items?.length && (
              <Button
                type="dark"
                data-cy="request-review-button"
                disabled={invoice.isArchived}
                icon={<EyeOutlined />}
                onClick={onRequestReviewClick}
              >
                Request review
              </Button>
            )}

            <Button
              disabled={invoice.isArchived || (reviewFullyApprovedAt && !approvedPdfData)}
              icon={<FilePdfOutlined />}
              type="primary"
              loading={isDownloadingInvoice}
              onClick={downloadInvoice}
              className="download-button"
              data-cy="download-invoice-button"
            >
              {isDownloadingInvoice ? "Downloading..." : "Download"}
            </Button>
            <Button
              icon={<SendOutlined />}
              type="primary"
              loading={isSendingInvoice}
              onClick={sendInvoice}
              disabled={invoice.isArchived || (reviewFullyApprovedAt && !approvedPdfData)}
              className="send-button"
              data-cy="send-invoice-button"
            >
              {isSendingInvoice ? "Sending..." : "Send"}
            </Button>
          </div>
        </div>
      </Card>
      {isAccountancyContactModalVisible && (
        <AccountancyContactModal
          onClose={() => setIsAccountancyContactModalVisible(false)}
          clientDetails={clientDetails}
          setIsSelectExistingContactVisible={setIsSelectExistingContactVisible}
          createNewAccountancyContact={createNewAccountancyContact}
          accountancyIntegrationFullDetails={accountancyIntegrationFullDetails}
        />
      )}
      {isSelectExistingContactModalVisible && (
        <SelectExistingContactModal
          onClose={() => setIsSelectExistingContactVisible(false)}
          clientDetails={clientDetails}
          invoice={invoice}
          sendInvoiceToXero={sendInvoiceToXero}
          sendInvoiceToQuickbooks={sendInvoiceToQuickbooks}
          accountancyIntegration={accountancyIntegration}
        />
      )}
    </div>
  );
}

export default withSubscriptions({
  Component: InvoiceActions,
  subscriptions: ["apiUser", "organisationDetails", "clients", "projects"],
});
