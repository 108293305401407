import { useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { updateOrganisation } from "graphql/mutations";
import { Form, Modal, Button, Input, Space } from "antd";
import withSubscriptions from "common/withSubscriptions";

import { callGraphQL } from "common/helpers";

import "./EditFileStatusModal.scss";

export function EditFileStatusModal({ onClose, visible, apiUser, organisationDetails, statusDetails, index }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ name }) {
    setIsLoading(true);

    await callGraphQL(
      "Could not create file status",
      graphqlOperation(updateOrganisation, {
        input: {
          id: apiUser.organisation,
          fileStatuses: organisationDetails.fileStatuses.map((crtStatus, crtIndex) => {
            if (crtIndex === index) {
              return { name };
            } else {
              return crtStatus;
            }
          }),
        },
      })
    );

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Edit file status"
      visible={visible}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="edit-file-status-modal"
    >
      <Form form={form} initialValues={statusDetails} onFinish={onSubmit}>
        <Space direction="vertical" className="spread">
          <Form.Item
            label="Name"
            name="name"
            className="file-status-name"
            rules={[
              {
                required: true,
                message: "You must choose a name for the file status",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading}>
              Submit
            </Button>
          </div>
        </Space>
      </Form>
      <br />
    </Modal>
  );
}

export default withSubscriptions({
  Component: EditFileStatusModal,
  subscriptions: ["organisationDetails"],
});
