import { useEffect, useState } from "react";
import { Breadcrumb, Typography, Tabs, Empty } from "antd";
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import withSubscriptions from "common/withSubscriptions";
import { updateSupplier } from "graphql/mutations";

import AddressList from "AddressList/AddressList";
import ContactList from "ContactList/ContactList";
import PurchaseOrderListItem from "PurchaseOrderListItem/PurchaseOrderListItem";
import SupplierSidebar from "./SupplierSidebar/SupplierSidebar";
import SupplierSummary from "./SupplierSummary/SupplierSummary";
import SupplierActions from "./SupplierActions/SupplierActions";

import "./SupplierDetailsPage.scss";

function SupplierDetailsPage(props) {
  const { organisationDetails, supplier, purchaseOrders, windowWidth, setBoxedLayout } = props;
  const [activeTab, setActiveTab] = useState(
    organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders ? "purchase-orders" : "addresses"
  );

  window.callGraphQLSimple({
    displayError: false,
    mutation: "createAuditItem",
    variables: {
      input: {
        taskId: "nothing",
        projectId: "nothing",
        fileId: "nothing",
        clientId: "nothing",
        page: "SUPPLIER",
        type: "PAGE_VIEW",
        userId: window.apiUser.id,
        organisation: window.apiUser.organisation,
      },
    },
  });

  useEffect(() => {
    setBoxedLayout(false);

    return () => {
      setBoxedLayout(true);
    };
  }, []); // eslint-disable-line

  let layout = {};

  if (windowWidth > 850) {
    layout.mainContentWidth = "100%";
    layout.sidebarWidth = "300px";
  } else {
    layout.mainContentWidth = "100%";
    layout.sidebarWidth = 0;
  }

  return (
    <div className="supplier-details-page">
      <div className="breadcrumb-bar">
        <Breadcrumb>
          <Breadcrumb.Item>
            <Link to={`/suppliers`}>Suppliers</Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item>{supplier.name}</Breadcrumb.Item>
        </Breadcrumb>
      </div>
      <div className="page-content">
        <div style={{ width: layout.mainContentWidth }}>
          {layout.sidebarWidth === 0 && (
            <div style={{ marginTop: "-1rem" }}>
              <SupplierSidebar supplier={supplier} splitLayout={windowWidth > 500} />
            </div>
          )}
          <SupplierSummary supplier={supplier} />
          <Tabs
            className="supplier-main-tabs"
            activeKey={activeTab}
            onTabClick={(tabKey) => {
              setActiveTab(tabKey);
            }}
          >
            {organisationDetails.settings?.purchaseOrder?.usesPurchaseOrders && (
              <Tabs.TabPane
                tab={<Typography.Text data-cy="purchase-orders-tab">Purchase orders</Typography.Text>}
                key="purchase-orders"
                data-cy="purchase-orders"
              >
                {purchaseOrders.length > 0 ? (
                  purchaseOrders
                    .filter((x) => x.supplierId === supplier.id)
                    .map((purchaseOrder) => (
                      <PurchaseOrderListItem
                        purchaseOrder={purchaseOrder}
                        key={`purchase-order-${purchaseOrder.id}`}
                        organisationDetails={organisationDetails}
                      />
                    ))
                ) : (
                  <Empty description="No purchase orders found" />
                )}
              </Tabs.TabPane>
            )}

            <Tabs.TabPane
              tab={<Typography.Text data-cy="addresses-tab">Addresses</Typography.Text>}
              key="addresses"
              data-cy="addresses"
            >
              <AddressList parent={supplier} mutation={updateSupplier} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<Typography.Text data-cy="contacts-tab">Contacts</Typography.Text>}
              key="contacts"
              data-cy="contacts"
            >
              <ContactList parent={supplier} mutation={updateSupplier} />
            </Tabs.TabPane>
            <Tabs.TabPane
              tab={<Typography.Text data-cy="actions-tab">Actions</Typography.Text>}
              key="actions"
              data-cy="actions"
            >
              <SupplierActions apiUser={window.apiUser} supplier={supplier} />
            </Tabs.TabPane>
          </Tabs>
        </div>
        {layout.sidebarWidth !== 0 && (
          <div style={{ width: layout.sidebarWidth, flexShrink: 0 }}>
            <SupplierSidebar supplier={supplier} splitLayout={windowWidth > 500} />
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: SupplierDetailsPage,
    subscriptions: ["organisationDetails", "supplier", "tasks", "users", "purchaseOrders"],
  })
);
