import { useState } from "react";
import { Form, Modal, Button, Input, Radio, Alert, message, Typography } from "antd";
import randomWords from "random-words";
import { LexoRank } from "lexorank";

import { createUserInApiAndCognito, getUserReadableCatLevel } from "common/helpers";

import "./InviteUserModal.scss";

export default function InviteUserModal({ onClose, apiUser, users, organisationDetails }) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmit(formFields) {
    setIsLoading(true);
    setError();

    let newUserOrder = LexoRank.middle();
    const usersInOrganisation = users
      .filter((x) => x.organisation === organisationDetails.id)
      .sort((a, b) => (a.order < b.order ? -1 : 1));
    try {
      if (usersInOrganisation && usersInOrganisation.length > 0) {
        const lastUserInOrganisation = usersInOrganisation[usersInOrganisation.length - 1];
        newUserOrder = LexoRank.parse(lastUserInOrganisation.order).genNext();
      }
    } catch (e) {
      console.error("Failed to generate new user order", e);
    }

    try {
      await createUserInApiAndCognito({
        ...formFields,
        organisation: organisationDetails.id,
        temporaryPassword: randomWords(3).join("_"),
        order: newUserOrder.toString(),
        isHidden: false,
        accountIsActive: false,
      });
      message.success(
        <Typography.Text>
          <b>{formFields.email}</b> has been invited to <b>{organisationDetails.name}</b>
        </Typography.Text>
      );
      onClose();
    } catch (e) {
      console.error(e);
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Invite User"
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="invite-user-modal"
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form
        {...layout}
        form={form}
        initialValues={{
          role: "member",
          accountIsActive: false,
          catLevelDesign: 0,
          catLevelCheck: 0,
          catLevelIssue: 0,
          isHidden: false,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="User Email"
          name="email"
          rules={[
            {
              required: true,
              message: "You must specify a user email",
            },
          ]}
        >
          <Input autoComplete="off" className="user-email" />
        </Form.Item>
        <Form.Item
          label="First Name"
          name="firstName"
          rules={[
            {
              required: true,
              message: "You must specify a first name",
            },
          ]}
        >
          <Input autoComplete="off" className="first-name" />
        </Form.Item>
        <Form.Item
          label="Last Name"
          name="lastName"
          rules={[
            {
              required: true,
              message: "You must specify a last name",
            },
          ]}
        >
          <Input autoComplete="off" className="last-name" />
        </Form.Item>

        <Form.Item label="Job Title" name="position">
          <Input autoComplete="off" className="position" />
        </Form.Item>

        <Form.Item label="Qualifications" name="qualifications">
          <Input autoComplete="off" className="qualifications" />
        </Form.Item>

        {organisationDetails.usesDesignAuthority && (
          <Form.Item
            label="Can design"
            name="catLevelDesign"
            className="cat-level cat-level-design"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={0} className="cat-level-0">
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Radio>
              <Radio value={1} className="cat-level-1">
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Radio>
              <Radio value={2} className="cat-level-2">
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Radio>
              <Radio value={3} className="cat-level-3">
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}
        {organisationDetails.usesReviewAuthority && (
          <Form.Item
            label={<>Can review</>}
            name="catLevelIssue"
            className="cat-level cat-level-issue"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Radio.Group>
              <Radio value={0} className="cat-level-0">
                {getUserReadableCatLevel(organisationDetails, 0)}
              </Radio>
              <Radio value={1} className="cat-level-1">
                {getUserReadableCatLevel(organisationDetails, 1)}
              </Radio>
              <Radio value={2} className="cat-level-2">
                {getUserReadableCatLevel(organisationDetails, 2)}
              </Radio>
              <Radio value={3} className="cat-level-3">
                {getUserReadableCatLevel(organisationDetails, 3)}
              </Radio>
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}
