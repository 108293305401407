import cx from "classnames";
import { Spin, Result, Button, Typography } from "antd";
import { Link } from "react-router-dom";
import DraughtHubLogo from "../graphics/draughthub_logo_symbol_only.png";

import "./LoadingWrapper.scss";

type Props = {
  content: any;
  isLoading: any;
  error?: any;
  loadingClassName?: string;
  displayPreloader?: boolean;
  shouldDisplayPreloader?: boolean;
  message?: any;
  useLogo?: boolean;
};

export default function LoadingWrapper({
  content,
  isLoading,
  error,
  loadingClassName,
  displayPreloader = true,
  shouldDisplayPreloader = false,
  message,
  useLogo = false,
}: Props) {
  if (isLoading) {
    if (!displayPreloader) {
      return null;
    }
    return (
      <div className={cx("loading-screen", loadingClassName, { "with-logo": useLogo })}>
        {useLogo ? (
          <>
            <img src={DraughtHubLogo} alt="" />
          </>
        ) : (
          <Spin />
        )}
        {message && <Typography.Text className="loading-screen-message">{message}</Typography.Text>}
      </div>
    );
  }

  if (error) {
    console.log("error = ", error);
    console.log("content = ", content);
    return (
      <Result
        status={error}
        title={error === 404 ? error : "That doesn't look right"}
        subTitle="It seems like something has gone wrong. Please try again later."
        extra={
          <Link to="/">
            <Button type="primary">Back to Dashboard</Button>
          </Link>
        }
      />
    );
  }

  return (
    <>
      {shouldDisplayPreloader && (
        <div className={cx("loading-screen", loadingClassName)}>
          <Spin />
        </div>
      )}

      {content()}
    </>
  );
}
