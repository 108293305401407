import { useState } from "react";
import { PDFDocument, rgb } from "pdf-lib";
import { Form, Modal, Button, Input, Typography } from "antd";

import { downloadBase64, useForceUpdate } from "common/helpers";

import "./DownloadSpecificReportPagesModal.scss";
import { buildFileName } from "common/shared";

type Props = {
  onClose?: any;
  pdfUri: string;
  task: any;
  taskRevision: any;
  entirePdfName?: string;
};

export default function DownloadSpecificReportPagesModal({
  onClose,
  pdfUri,
  task,
  taskRevision,
  entirePdfName,
}: Props) {
  const [form] = Form.useForm();
  const forceUpdate = useForceUpdate();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit({ pages }) {
    setIsLoading(true);

    const wholeReport = await PDFDocument.load(pdfUri);
    const reportPageCount = wholeReport.getPages().length;
    pages = pages.split(" ").join("");
    let pagesToCopy: number[] = [];
    let ranges = pages.split(",");
    for (let i = 0; i < ranges.length; i++) {
      let range = ranges[i];
      if (!range.includes("-")) {
        const pageNumber = parseInt(range);
        if (!pagesToCopy.includes(pageNumber)) {
          pagesToCopy.push(pageNumber);
        }
        continue;
      }

      let rangeEnds = range.split("-").filter((x) => x !== "");

      if (range.includes("--") || range.startsWith("-") || rangeEnds.length !== 2) {
        Modal.error({
          title: "Invalid format",
          content: `Page range ${range} is invalid`,
        });
        setIsLoading(false);
        return;
      }

      let rangeStart: number = parseInt(rangeEnds[0]);
      let rangeEnd: number = parseInt(rangeEnds[1]);
      if (rangeEnd < rangeStart) {
        let bucket = rangeStart;
        rangeStart = rangeEnd;
        rangeEnd = bucket;
      }
      for (let pageNumber = rangeStart; pageNumber <= rangeEnd; pageNumber++) {
        if (!pagesToCopy.includes(pageNumber)) {
          pagesToCopy.push(pageNumber);
        }
      }
    }

    let negativePageNumbers = pagesToCopy.filter((pageNumber) => pageNumber < 1);
    let outOfBoundsPageNumbers = pagesToCopy.filter((pageNumber) => pageNumber > reportPageCount);

    if (negativePageNumbers.length > 0) {
      Modal.error({
        title: "Invalid page numbers",
        content: "You can only specify positive page numbers starting at 1.",
      });
      setIsLoading(false);
      return;
    }

    if (outOfBoundsPageNumbers.length > 0) {
      Modal.error({
        title: "Invalid page numbers",
        content: (
          <>
            There are only <b>{reportPageCount}</b> pages in the document, but you have specified page
            {outOfBoundsPageNumbers.length > 1 ? "s" : ""} <b>{outOfBoundsPageNumbers.join(", ")}</b>.
          </>
        ),
      });
      setIsLoading(false);
      return;
    }

    pagesToCopy = pagesToCopy.sort((a, b) => (a < b ? -1 : 1)).map((pageNumber) => pageNumber - 1); // pages numbers are zero-based, but users don't have to know that, so we have to compensate

    const newDocument = await PDFDocument.create();

    const copiedPages = await newDocument.copyPages(wholeReport, pagesToCopy);

    copiedPages.forEach((page, pageIndex) => {
      newDocument.addPage(page);

      const pageWidth = page.getWidth();
      const textValue = `${pageIndex + 1} / ${pagesToCopy.length}`;

      page.drawRectangle({
        width: textValue.length * 7,
        height: 14,
        x: page.getWidth() / 2 - textValue.length * 3.5,
        y: 27,
        color: rgb(1, 1, 1),
      });

      page.drawText(textValue, {
        x: pageWidth / 2 - textValue.length * 2,
        y: 30,
        size: 10,
        color: rgb(0, 0, 0),
      });
    });

    let dataUriNewDocument = await newDocument.saveAsBase64({
      dataUri: true,
    });

    let fileName =
      entirePdfName ||
      (
        await buildFileName({
          organisation: task.organisation,
          taskId: task.id,
          fileType: "REPORT",
          taskRevisionName: taskRevision.name,
          clientInitials: task.client.initials,
          projectInitials: task.project.initials,
          taskInitials: task.initials,
        })
      ).file;
    if (!fileName.includes(".pdf")) {
      fileName += ".pdf";
    }
    console.log("fileName = ", fileName);
    await downloadBase64({
      base64String: dataUriNewDocument,
      fileName,
    });

    form.resetFields();
    setIsLoading(false);
    onClose();
  }

  return (
    <Modal
      maskClosable={false}
      title="Download specific report pages"
      visible={true}
      onCancel={onClose}
      footer={null}
      className="download-specific-report-pages-modal"
    >
      <Typography.Text className="explanation">
        You can specify individual page numbers, ranges or a combination of the two. <br />
        (e.g. <b>1,2,5-10,3,1-10</b>). <br />
        Spaces are ignored.
        <br />
        Pages in the resulting document will not be repeated and will always be in ascending order.
      </Typography.Text>
      <Form form={form} initialValues={{}} onFinish={onSubmit}>
        <Form.Item
          label="Pages"
          name="pages"
          className="pages"
          rules={[
            {
              required: true,
              message: "You must specify which pages you want",
            },
            {
              pattern: /^[\d,\- ]*$/,
              message: "The only characters accepted are digits, commas, spaces and hyphens",
            },
          ]}
        >
          <Input
            onChange={(e) => {
              form.setFieldsValue({ pages: e.target.value });
              forceUpdate();
            }}
          />
        </Form.Item>

        <div className="submit-container">
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Submit
          </Button>
        </div>
      </Form>
      <br />
    </Modal>
  );
}
