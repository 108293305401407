import { graphqlOperation } from "aws-amplify";
import { updateUser } from "graphql/mutations";
import { callGraphQL } from "common/helpers";
import { Switch, Typography } from "antd";
import cx from "classnames";

import { getSimpleLabel } from "common/labels";

import Card from "Card/Card";

import "./NotificationSettings.scss";

const notificationsSettingsByType = [
  {
    title: "Web App Notifications",
    settings: [
      {
        label: "When someone requests a review from you",
        name: "webAppReviewsRequest",
      },
      {
        label: "When a review you have requested changes state",
        name: "webAppReviewsResult",
      },
      { label: `When someone assigns you a ${getSimpleLabel("task")}`, name: "webAppTaskAssigned" },
      { label: "When someone mentions you", name: "webAppMention" },
      { label: `When there is activity on a ${getSimpleLabel("request")}`, name: "webAppRequestActivity" },
      {
        label: `When there is a direct upload on a ${getSimpleLabel("task")} assigned to you`,
        name: "webAppPublicUpload",
      },
    ],
  },
  {
    title: "Email Notifications",
    settings: [
      {
        label: "When someone requests a review from you",
        name: "emailReviewsRequest",
      },
      {
        label: "When a review you have requested changes state",
        name: "emailReviewsResult",
      },
      { label: `When someone assigns you a ${getSimpleLabel("task")}`, name: "emailTaskAssigned" },
      { label: "When someone mentions you", name: "emailMention" },
      { label: `When there is activity on a ${getSimpleLabel("request")}`, name: "emailRequestActivity" },
      {
        label: `When there is a direct upload on a ${getSimpleLabel("task")} assigned to you`,
        name: "emailPublicUpload",
      },
    ],
  },
];

export default function NotificationSettings({ user, disabled }) {
  let { __typename, ...existingSettings } = user.notificationSettings || {};

  async function changeSettings({ settings, value }) {
    let newSettings = { ...existingSettings };

    settings.forEach((setting) => {
      newSettings[setting] = value;
    });

    await callGraphQL(
      "Failed to update notification settings",
      graphqlOperation(updateUser, {
        input: {
          id: user.id,
          notificationSettings: newSettings,
        },
      })
    );
  }

  function displaySetting({ label, settings, isTitle }) {
    return (
      <div key={label} className={cx("setting", { "is-title": isTitle })}>
        <Typography.Text className="label">{label}</Typography.Text>
        <Switch
          disabled={disabled}
          checked={settings.some((settingName) => existingSettings[settingName] !== false)}
          onChange={(checked) => {
            changeSettings({ settings, value: checked });
          }}
        />
      </div>
    );
  }

  return (
    <Card title="Notification settings" className="notification-settings">
      {notificationsSettingsByType.map((notificationClass, i) => {
        return (
          <div className="notification-class" key={i}>
            <div className="settings">
              {displaySetting({
                label: notificationClass.title,
                settings: notificationClass.settings?.map((setting) => setting.name),
                isTitle: true,
              })}
            </div>
            {notificationClass.settings?.map((setting) =>
              displaySetting({
                label: setting.label,
                settings: [setting.name],
              })
            )}
          </div>
        );
      })}
    </Card>
  );
}
