import { getLabel } from "common/helpers";
import { getSimpleLabel } from "./labels";

export async function recalculateTaskEstimatedHours(taskId) {
  const task = (
    await window.callGraphQLSimple({
      message: `Failed to load ${getSimpleLabel("task")}`,
      queryCustom: "getTaskSimple",
      variables: {
        id: taskId,
      },
    })
  ).data.getTask;

  const taskEstimatedHours = task.revisions.items.reduce((total, revision) => {
    return total + revision.estimatedHours;
  }, 0);

  await window.callGraphQLSimple({
    message: `Failed to update ${getSimpleLabel("task")}`,
    queryCustom: "updateTask",
    variables: {
      input: {
        id: taskId,
        estimatedHours: taskEstimatedHours,
      },
    },
  });
}

export async function updateDeletedFiles(taskRevision, fileType) {
  const deletedFilesByType = JSON.parse(taskRevision.deletedFilesByType || "{}");
  if (!deletedFilesByType.hasOwnProperty(fileType)) {
    deletedFilesByType[fileType] = 0;
  }
  deletedFilesByType[fileType]++;

  await window.callGraphQLSimple({
    message: `Failed to update ${getLabel({
      id: "task revision",
      defaultValue: "task revision",
    })}`,
    queryCustom: "updateTaskRevision",
    variables: {
      input: {
        id: taskRevision.id,
        deletedFilesByType: JSON.stringify(deletedFilesByType),
      },
    },
  });
}
