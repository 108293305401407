import { Modal, Select, message } from "antd";

import { REQUEST_STATUSES } from "common/constants";
import { callGraphQLSimple } from "common/apiHelpers";
import { isAuthorised } from "common/permissions";
import { getSimpleLabel } from "common/labels";
import { sendRequestFormRejectedNotification } from "common/notificationHelpers";
import {
  getOpenRequestFormFileIds,
  getSubmittedRequestFormFileIds,
  getActivityItemsForFormFileId,
  haveTaskFilesBeenSentForFormFileId,
} from "common/requestHelpers";

import InfoItem from "InfoItem/InfoItem";
import Input from "Input/Input";

import "./RequestStatusPicker.scss";

type Props = {
  request: any;
  apiUser: any;
  users: any;
  activityItems: any[] | undefined;
};

export default function RequestStatusPicker({ request, apiUser, users, activityItems }: Props) {
  let existingStatusDetails = REQUEST_STATUSES.find((x) => x.value === request.status);
  let existingStatusMeansInProgress = existingStatusDetails?.meansInProgress || false;

  const openFormFileIds = getOpenRequestFormFileIds({ activityItems });
  const submittedFormFileIds = getSubmittedRequestFormFileIds({ activityItems });
  const allFormIds = openFormFileIds.concat(submittedFormFileIds);

  async function onRequestRejected(params) {
    const { reason } = params || {};
    for (let formFileId of allFormIds) {
      const activityItemsForFormFileId = getActivityItemsForFormFileId({ activityItems, formFileId });
      const formCreationActivityItem = activityItemsForFormFileId.find(
        (activityItem) => activityItem.content?.type === "REQUEST_FORM_CREATED"
      );
      let taskFilesHaveBeenSent = haveTaskFilesBeenSentForFormFileId({ activityItems, formFileId });

      if (taskFilesHaveBeenSent) {
        continue;
      }

      let formName = formCreationActivityItem?.content?.formName || "Information form";

      try {
        await sendRequestFormRejectedNotification({
          users,
          request,
          apiUser,
          formName,
          formFileId,
          receiverId: formCreationActivityItem.author,
          messageContent: reason,
        });
      } catch (e) {
        message.error(`Failed to send notification for form ${formName} to ${formCreationActivityItem.author}`);
      }
    }
  }

  async function onChange(newStatus: string) {
    // ask user for a reason if the status details contains "reasonRequired"
    let reason = "";
    let statusDetails = REQUEST_STATUSES.find((x) => x.value === newStatus);
    // use a Modal.info with an input to ask the user for a reason
    if (statusDetails?.needsReason) {
      Modal.confirm({
        title: "Reason required",
        icon: null,
        content: (
          <div>
            <span>
              Please provide a reason for changing the status to <b>{statusDetails.label}</b>.
            </span>
            <br />
            <br />
            <Input
              fireOnChangeWithoutBlur
              onChange={(newValue) => {
                reason = newValue;
              }}
              showBorder
              fullWidth
              placeholder="Type the reason here"
            />
          </div>
        ),
        okText: "Submit",
        onOk: async () => {
          // create an activity item containing the recording of the reason
          if (!reason) {
            message.error("Status not updated, you must provide a reason");
            return;
          }
          await callGraphQLSimple({
            mutation: "createActivityItem",
            message: "Failed to record activity item",
            variables: {
              input: {
                parentId: request.id,
                author: apiUser.id,
                content: JSON.stringify({
                  type: newStatus,
                  reason,
                }),
                organisation: request.organisation,
              },
            },
          });

          await callGraphQLSimple({
            mutation: "updateRequest",
            message: `Failed to update ${getSimpleLabel("request")} status`,
            variables: {
              input: {
                id: request.id,
                status: newStatus,
              },
            },
          });

          if (newStatus === "REJECTED") {
            await onRequestRejected({ reason });
          }
        },
      });
    } else {
      await callGraphQLSimple({
        mutation: "createActivityItem",
        message: "Failed to record activity item",
        variables: {
          input: {
            parentId: request.id,
            author: apiUser.id,
            content: JSON.stringify({
              type: newStatus,
            }),
            organisation: request.organisation,
          },
        },
      });
      await callGraphQLSimple({
        mutation: "updateRequest",
        message: `Failed to update ${getSimpleLabel("request")} status`,
        variables: {
          input: {
            id: request.id,
            status: newStatus,
          },
        },
      });

      if (newStatus === "REJECTED") {
        await onRequestRejected();
      }
    }
  }

  let currentStatusLabel = REQUEST_STATUSES.find((x) => x.value === request.status)?.label || "Unknown";

  return (
    <InfoItem
      label="Status"
      value={
        !isAuthorised(["REQUESTS.EXECUTE"]) ? (
          currentStatusLabel
        ) : (
          <Select value={request.status} popupClassName="request-status-picker-menu" onChange={onChange}>
            {REQUEST_STATUSES.map((status) => {
              let isDisabled =
                (existingStatusMeansInProgress && status.meansInProgress) || status.value === request.status;

              return (
                <Select.Option key={status.value} value={status.value} disabled={isDisabled}>
                  {status.label}
                </Select.Option>
              );
            })}
          </Select>
        )
      }
    />
  );
}
