import { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, message, Modal, Table, Typography, Select, Dropdown, Menu } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  DownOutlined,
  RightOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { useGetSetState } from "react-use";
import moment from "moment";

import { callGraphQLSimple } from "common/apiHelpers";
import withSubscriptions from "common/withSubscriptions";
import { getUserTimeOffDataForDisplay } from "common/timeOffHelpers";
import { TIMELINE_DEFAULT_HOURS_IN_A_DAY, ALLOWANCE_TYPES } from "common/constants";
import ButtonWithPermissions from "ButtonWithPermissions/ButtonWithPermissions";
import { isAuthorised } from "common/permissions";
import {
  sendTimeOffApprovalNotification,
  sendTimeOffRejectionNotification,
  sendTimeOffDeletionNotification,
} from "common/notificationHelpers";
import { User, Holiday } from "common/types";

import Avatar from "Avatar/Avatar";
import Card from "Card/Card";
import TimeOffAllowanceModal from "Modals/TimeOffAllowanceModal/TimeOffAllowanceModal";
import HolidayModal from "Modals/HolidayModal/HolidayModal";
import UsersAllowance from "UserAllowances/UserAllowances";
import HolidayConflictsModal from "Modals/HolidayConflictsModal/HolidayConflictsModal";

import "./TimeOffForAllUsers.scss";

type Props = {
  apiUser: User;
  holidays: Holiday[];
  users: User[];
  isSick: boolean;
  defaultExpandedRow: string;
};

export function TimeOffForAllUsers({ apiUser, holidays, users, isSick, defaultExpandedRow }: Props) {
  const [getState, setState] = useGetSetState({
    selectedIntervalForEditing: undefined,
    selectedIntervalForDisplay: undefined,
    isIntervalModalOpen: false,
    isLoadingIntervals: true,
    intervals: undefined,
    // isAddHolidayModalOpen: false,
  });
  const [isAddHolidayModalOpen, setIsAddHolidayModalOpen] = useState(false);
  const [isHolidayConflictsModalOpen, setIsHolidayConflictsModalOpen] = useState(false);
  const [selectedHoliday, setSelectedHoliday] = useState<Holiday | undefined>(undefined);

  const label = isSick ? "sick day" : "holiday";

  useEffect(() => {
    fetchAndSetIntervals();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchAndSetIntervals() {
    setState({
      isLoadingIntervals: true,
    });
    const intervals = (
      await callGraphQLSimple({
        message: "Failed to fetch allowance intervals",
        query: "listIntervalsByOrganisation",
        variables: {
          organisation: apiUser.organisation,
          limit: 1000,
          filter: {
            type: {
              eq: isSick ? "SICK_ALLOWANCE" : "HOLIDAY_ALLOWANCE",
            },
          },
        },
      })
    ).data.listIntervalsByOrganisation.items;

    const { selectedIntervalForDisplay } = getState();

    let firstIntervalWeAreIn = intervals.find((interval) => {
      return moment().isBetween(interval.startDate, interval.endDate);
    });

    setState({
      intervals: intervals.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)),
      isLoadingIntervals: false,
      selectedIntervalForDisplay: selectedIntervalForDisplay || firstIntervalWeAreIn || intervals[0],
    });
  }

  function getDataForUser(userId) {
    const { selectedIntervalForDisplay } = getState();
    let holidaysForUser = holidays.filter((holiday) => holiday.userId === userId);

    if (selectedIntervalForDisplay) {
      holidaysForUser = holidaysForUser.filter((holiday) => {
        return moment(holiday.startsAt).isBetween(
          selectedIntervalForDisplay?.startDate,
          selectedIntervalForDisplay?.endDate
        );
      });
    }

    const user = users.find((user) => user.id === userId);
    const allowanceType = isSick ? "SICK" : "HOLIDAY";

    let allowance = 0;
    let countDaysApproved = 0;
    let countDaysRejected = 0;
    let countDaysPending = 0;
    let countDaysRemaining = 0;

    holidaysForUser.forEach((holiday) => {
      // let days = holiday.days!.filter((day) => moment(day?.day).isSame(selectedYear, "year"));
      let days = holiday.days!;

      let dayCount = 0;

      days.forEach((day) => {
        if (day!.endHours) {
          const numberOfHours = day!.endHours - day!.startHours!;

          dayCount += numberOfHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
        } else {
          dayCount += 1;
        }
      });

      if (holiday.status === "APPROVED") {
        countDaysApproved += dayCount;
      } else if (holiday.status === "REJECTED") {
        countDaysRejected += dayCount;
      } else {
        countDaysPending += dayCount;
      }
    });

    const timeOffAllowanceForCurrentInterval = user!.allowances?.find((allowance) => {
      // @ts-ignore
      return allowance?.intervalId === selectedIntervalForDisplay?.id && allowance?.type === allowanceType;
    });

    if (timeOffAllowanceForCurrentInterval && timeOffAllowanceForCurrentInterval.allowance) {
      allowance = timeOffAllowanceForCurrentInterval.allowance / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      countDaysRemaining =
        timeOffAllowanceForCurrentInterval.allowance / TIMELINE_DEFAULT_HOURS_IN_A_DAY - countDaysApproved;
    } else {
      allowance = 0;
      countDaysRemaining = 0;
    }

    return {
      ...user,
      key: userId,
      order: users.find((x) => x.id === userId)?.order,
      allowance,
      countDaysApproved,
      countDaysRejected,
      countDaysPending,
      countDaysRemaining,
    };
  }

  async function confirmDeleteHoliday(holidayTableRow) {
    Modal.confirm({
      title: `Are you sure you want to delete this ${label} request?`,
      content: getModalContent(holidayTableRow),
      okText: `Yes, delete ${label}`,
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to delete ${label}`,
          mutation: "deleteHoliday",
          variables: {
            input: {
              id: holidayTableRow.id,
            },
          },
        });

        await sendTimeOffDeletionNotification({
          requesterId: holidayTableRow.userId,
          deletedById: apiUser.id,
          users,
          isSick,
          startsAt: moment(holidayTableRow.startsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
          endsAt: moment(holidayTableRow.endsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
          timeOffStatus: holidayTableRow.status,
        });
      },
    });
  }

  function getModalContent(holiday) {
    let userDetails = users.find((x) => x.id === holiday.userId);

    let numberOfDays = 0;
    holiday.days.forEach((day) => {
      if (day.endHours) {
        const numberOfHours = day.endHours - day.startHours;

        numberOfDays += numberOfHours / TIMELINE_DEFAULT_HOURS_IN_A_DAY;
      } else {
        numberOfDays += 1;
      }
    });

    return (
      <>
        <Typography.Text>
          User:{" "}
          <b>
            {userDetails?.firstName} {userDetails?.lastName}{" "}
          </b>
          ({userDetails?.id})
        </Typography.Text>
        <br />
        <br />
        <Typography.Text>
          Start: <b>{moment(holiday.startsAt).format("DD-MM-YYYY")}</b>
        </Typography.Text>
        <br />
        <Typography.Text>
          End: <b>{moment(holiday.endsAt).format("DD-MM-YYYY")}</b>
        </Typography.Text>
        <br />
        {numberOfDays < 1 ? (
          <Typography.Text>
            Hours: <b>{Math.floor(numberOfDays * TIMELINE_DEFAULT_HOURS_IN_A_DAY)}</b>
          </Typography.Text>
        ) : (
          <Typography.Text>
            Days: <b>{numberOfDays}</b>
          </Typography.Text>
        )}
      </>
    );
  }

  async function approveHoliday(holiday) {
    Modal.confirm({
      title: <>Are you sure you want to approve this {label} request?</>,
      content: getModalContent(holiday),
      okText: `Yes, approve ${label}`,
      className: `approve-${label}-modal`,
      okButtonProps: {
        className: `approve-${label.split(" ").join("-")}-button`,
      },
      onOk: async () => {
        let messageKey = "approving-holiday";
        message.loading({
          content: `Approving ${label}...`,
          key: messageKey,
          duration: 0,
        });
        try {
          await callGraphQLSimple({
            message: `Failed to approve ${label}`,
            mutation: "updateHoliday",
            variables: {
              input: {
                id: holiday.id,
                approvedAt: new Date().toISOString(),
                approvedBy: apiUser.id,
                status: "APPROVED",
              },
            },
          });

          for (let day of holiday.dayList) {
            console.log("day = ", day);
            let endHours = 8;

            if (day.endHours) {
              endHours = day.endHours;
            }

            let inputForCreateTimesheetBlock = {
              invoiceId: "nothing",
              quoteId: "nothing",
              projectId: "nothing",
              taskId: label,
              userId: holiday.userId,
              organisation: holiday.organisation,
              startAt: moment(day.day).startOf("day").add(9, "hour").toISOString(),
              endAt: moment(day.day)
                .startOf("day")
                .add(9 + endHours, "hour")
                .toISOString(),
            };

            await callGraphQLSimple({
              message: "Failed to create timesheet block",
              mutation: "createTimesheetBlock",
              variables: {
                input: inputForCreateTimesheetBlock,
              },
            });
          }

          message.success({
            content: `${isSick ? "Sick day" : "Holiday"} approved and timesheet auto-filled`,
            key: messageKey,
            duration: 5,
          });
        } catch (e) {
          try {
            await callGraphQLSimple({
              message: `Failed to approve ${label}`,
              mutation: "updateHoliday",
              variables: {
                input: {
                  id: holiday.id,
                  approvedAt: null,
                  approvedBy: null,
                  status: "PENDING",
                },
              },
            });
          } catch (e) {
            // nothing we can do
          }

          message.error({
            content: `Failed to approve ${label}`,
            key: messageKey,
            duration: 5,
          });
          console.log(e);
        }
        await sendTimeOffApprovalNotification({
          requesterId: holiday.userId,
          approvedById: apiUser.id,
          users,
          isSick,
          startsAt: moment(holiday.startsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
          endsAt: moment(holiday.endsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
        });
      },
    });
  }

  async function rejectHoliday(holiday) {
    Modal.confirm({
      title: <>Are you sure you want to reject this {label} request?</>,
      content: getModalContent(holiday),
      okText: `Yes, reject ${label}`,
      okButtonProps: {
        className: `reject-${label.split(" ").join("-")}-button`,
      },
      onOk: async () => {
        await callGraphQLSimple({
          message: `Failed to reject ${label}`,
          mutation: "updateHoliday",
          variables: {
            input: {
              id: holiday.id,
              rejectedAt: new Date().toISOString(),
              rejectedBy: apiUser.id,
              status: "REJECTED",
            },
          },
        });

        await sendTimeOffRejectionNotification({
          requesterId: holiday.userId,
          users,
          isSick,
          rejectedById: apiUser.id,
          startsAt: moment(holiday.startsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
          endsAt: moment(holiday.endsAt).startOf("day").add(12, "hours").format("DD-MM-YYYY"),
        });
      },
    });
  }

  function displayDetailsPerUser(user) {
    let holidaysForUser = holidays.filter((holiday) => holiday.userId === user.id);
    const tableData = getUserTimeOffDataForDisplay({
      holidays: holidaysForUser,
      users,
      interval: getState().selectedIntervalForDisplay,
      isSick,
    });

    let timeOffTable = (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        expandRowByClick={true}
        scroll={{ x: 900 }}
        columns={[
          {
            title: "Requested on",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.createdAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Start Date",
            dataIndex: "startsAt",
            key: "startsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-start-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "End Date",
            dataIndex: "endsAt",
            key: "endsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-end-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "Days",
            key: "numberOfDays",
            dataIndex: "numberOfDays",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-number-of-days" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Approval",
            dataIndex: "approvalStatus",
            key: "approvalStatus",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-approval-status" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Status",
            key: "temporalStatus",
            dataIndex: "temporalStatus",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-temporal-status" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "",
            key: "actions",
            width: 200,
            render: (_, holidayTableRow, index) => {
              return (
                <div className="time-off-actions">
                  <>
                    <ButtonWithPermissions
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      onClick={() => rejectHoliday(holidayTableRow)}
                      icon={<CloseCircleOutlined />}
                      data-cy="reject-time-off-button"
                      data-index={index}
                      disabled={holidayTableRow.status !== "PENDING"}
                    >
                      Reject
                    </ButtonWithPermissions>
                    <ButtonWithPermissions
                      type="primary"
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      onClick={() => approveHoliday(holidayTableRow)}
                      icon={<CheckCircleOutlined />}
                      data-cy="approve-time-off-button"
                      data-index={index}
                      disabled={holidayTableRow.status !== "PENDING"}
                    >
                      Approve
                    </ButtonWithPermissions>
                  </>
                  <ButtonWithPermissions
                    permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                    type="dark"
                    onClick={() => confirmDeleteHoliday(holidayTableRow)}
                    icon={<DeleteOutlined />}
                    data-cy="delete-time-off-button"
                    data-index={index}
                  >
                    Delete
                  </ButtonWithPermissions>
                </div>
              );
            },
          },
        ]}
        dataSource={tableData}
      />
    );

    let allowanceDefinition = ALLOWANCE_TYPES.find((x) => x.isSick === !!isSick);

    return (
      <>
        <Typography.Text className="time-off-table-header">
          {allowanceDefinition?.label?.substring(0, allowanceDefinition.label?.length - 1)} requests
        </Typography.Text>
        {timeOffTable}
        <div>
          <UsersAllowance
            userData={user}
            allowanceDefinition={allowanceDefinition}
            // setSelectedUser={setSelectedUser}
            selectedUser={user}
          />
        </div>
      </>
    );
  }

  function displayPendingTimeOffTable(dataSource) {
    // extract the timesheet blocks for the current user
    // create a list of task ids based on the timesheet blocks

    return (
      <Table
        pagination={{ hideOnSinglePage: true, pageSize: 50 }}
        scroll={{ x: 900 }}
        expandRowByClick={true}
        rowKey="id"
        columns={[
          {
            title: "User",
            key: "user",
            dataIndex: "id",
            align: "left",
            sorter: (a, b) => (a.order < b.order ? -1 : 1),
            defaultSortOrder: "ascend",
            render: (_, holiday) => {
              let user = users.find((x) => x.id === holiday.userId);
              if (!user) {
                return null;
              }
              return (
                <Link to={`/users/${user.id}?tab=${isSick ? "sickDays" : "holidays"}`}>
                  <Avatar user={users.find((x) => x.id === user.id)} showLabel />
                </Link>
              );
            },
          },
          {
            title: "Requested on",
            align: "center",
            render: (_, holiday) => <p>{moment(holiday.createdAt).format("DD-MM-YYYY")}</p>,
          },
          {
            title: "Start Date",
            dataIndex: "startsAt",
            key: "startsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-start-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "End Date",
            dataIndex: "endsAt",
            key: "endsAt",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-end-date" data-index={index}>
                {moment(value).format("DD-MM-YYYY")}
              </Typography.Text>
            ),
          },
          {
            title: "Days",
            key: "numberOfDays",
            dataIndex: "numberOfDays",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-number-of-days" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Status",
            key: "temporalStatus",
            dataIndex: "temporalStatus",
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-temporal-status" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "Message",
            key: "message",
            dataIndex: "message",
            width: 200,
            render: (value, _, index) => (
              <Typography.Text data-cy="time-off-message" data-index={index}>
                {value}
              </Typography.Text>
            ),
          },
          {
            title: "",
            key: "actions",
            width: 200,
            render: (_, holiday, index) => {
              const menuItems = [
                {
                  key: "1",
                  label: (
                    <ButtonWithPermissions
                      type="primary"
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      onClick={() => approveHoliday(holiday)}
                      icon={<CheckCircleOutlined />}
                      data-cy="approve-time-off-button"
                      data-index={index}
                      disabled={holiday.status !== "PENDING"}
                    >
                      Approve
                    </ButtonWithPermissions>
                  ),
                },
                {
                  key: "2",
                  label: (
                    <Button
                      type="primary"
                      onClick={() => {
                        setSelectedHoliday(holiday.originalHoliday);
                        setIsHolidayConflictsModalOpen(true);
                      }}
                    >
                      Show conflicts
                    </Button>
                  ),
                },

                {
                  key: "3",
                  label: (
                    <ButtonWithPermissions
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      onClick={() => rejectHoliday(holiday)}
                      icon={<CloseCircleOutlined />}
                      data-cy="reject-time-off-button"
                      data-index={index}
                      disabled={holiday.status !== "PENDING"}
                    >
                      Reject
                    </ButtonWithPermissions>
                  ),
                },
                {
                  key: "4",
                  label: (
                    <ButtonWithPermissions
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      type="dark"
                      onClick={() => confirmDeleteHoliday(holiday)}
                      icon={<DeleteOutlined />}
                      data-cy="delete-time-off-button"
                      data-index={index}
                    >
                      Delete
                    </ButtonWithPermissions>
                  ),
                },
              ];
              const menu = {
                items: menuItems,
              };
              if (window.innerWidth < 1500) {
                return (
                  <Dropdown menu={menu} trigger={["click"]} overlayClassName="time-off-actions-menu">
                    <Button
                      icon={<DownOutlined />}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      Actions
                    </Button>
                  </Dropdown>
                );
              } else {
                return (
                  <div className="time-off-actions">
                    <>
                      <ButtonWithPermissions
                        permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                        onClick={() => rejectHoliday(holiday)}
                        icon={<CloseCircleOutlined />}
                        data-cy="reject-time-off-button"
                        data-index={index}
                        disabled={holiday.status !== "PENDING"}
                      >
                        Reject
                      </ButtonWithPermissions>
                      <ButtonWithPermissions
                        type="primary"
                        permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                        onClick={() => approveHoliday(holiday)}
                        icon={<CheckCircleOutlined />}
                        data-cy="approve-time-off-button"
                        data-index={index}
                        disabled={holiday.status !== "PENDING"}
                      >
                        Approve
                      </ButtonWithPermissions>
                      <Button
                        type="primary"
                        onClick={() => {
                          setSelectedHoliday(holiday.originalHoliday);
                          setIsHolidayConflictsModalOpen(true);
                        }}
                      >
                        Show conflicts
                      </Button>
                    </>
                    <ButtonWithPermissions
                      permissions={["FULL.READ_WRITE", "TIME_OFF.MANAGE"]}
                      type="dark"
                      onClick={() => confirmDeleteHoliday(holiday)}
                      icon={<DeleteOutlined />}
                      data-cy="delete-time-off-button"
                      data-index={index}
                    >
                      Delete
                    </ButtonWithPermissions>
                  </div>
                );
              }
            },
          },
        ]}
        dataSource={dataSource}
      />
    );
  }

  let timeOffPending = holidays.filter((holiday) => holiday.status === "PENDING");

  const timeOffPendingTableData = getUserTimeOffDataForDisplay({
    holidays: timeOffPending,
    users,
    interval: undefined,
    isSick,
  });

  const { isIntervalModalOpen, isLoadingIntervals, intervals, selectedIntervalForEditing, selectedIntervalForDisplay } =
    getState();

  if (intervals === undefined) {
    return null;
  }

  return (
    <div className="time-off-for-all-users">
      {timeOffPendingTableData.length > 0 && (
        <Card withSpace title={`${isSick ? "Sick days" : "Holidays"} pending approval`} flexTitle wrapHeader>
          {displayPendingTimeOffTable(timeOffPendingTableData)}
        </Card>
      )}
      <Card
        withSpace
        title={`${isSick ? "Sick days" : "Holidays"} by user`}
        flexTitle
        wrapHeader
        actions={
          !intervals?.length ? null : (
            <div style={{ display: "flex", alignItems: "center", flexWrap: "wrap", gap: "0.5rem" }}>
              <Typography.Text className="select-interval-label">Interval: </Typography.Text>
              <Select
                defaultValue={selectedIntervalForDisplay?.id}
                className="select-interval-dropdown"
                onChange={(selectedIntervalId) => {
                  const selectedInterval = intervals?.find((x) => x.id! === selectedIntervalId);
                  setState({
                    selectedIntervalForDisplay: selectedInterval,
                  });
                }}
              >
                {intervals?.map((option) => (
                  <Select.Option value={option.id} key={option.id}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select>
              {isAuthorised(["TIME_OFF.MANAGE"]) && (
                <Button
                  type="primary"
                  onClick={() => setIsAddHolidayModalOpen(true)}
                  icon={<PlusCircleOutlined />}
                  style={{ marginLeft: "0" }}
                >
                  Add {isSick ? "sick days" : "holiday"}
                </Button>
              )}
            </div>
          )
        }
      >
        {!intervals?.length ? (
          <Typography.Text>
            No allowance intervals defined, please create one in order to manage time off{" "}
          </Typography.Text>
        ) : (
          <Table
            pagination={{ hideOnSinglePage: true, pageSize: 50 }}
            sticky={{
              offsetHeader: -8,
            }}
            scroll={{ x: 900 }}
            columns={[
              {
                title: "User",
                key: "user",
                dataIndex: "id",
                align: "left",
                sorter: (a, b) => (a.order < b.order ? -1 : 1),
                defaultSortOrder: "ascend",
                render: (_, user) => {
                  return (
                    <Link to={`/users/${user.id}?tab=${isSick ? "sickDays" : "holidays"}`}>
                      <Avatar user={users.find((x) => x.id === user.id)} showLabel />
                    </Link>
                  );
                },
              },
              {
                title: "Allowance",
                dataIndex: "allowance",
                key: "allowance",
                sorter: (a, b) => (a.countTotalDays < b.countTotalDays ? -1 : 1),
              },

              {
                title: "Days approved",
                dataIndex: "countDaysApproved",
                key: "countDaysApproved",
                sorter: (a, b) => (a.countDaysApproved < b.countDaysApproved ? -1 : 1),
              },
              {
                title: "Days pending",
                dataIndex: "countDaysPending",
                key: "countDaysPending",
                sorter: (a, b) => (a.countDaysPending < b.countDaysPending ? -1 : 1),
              },
              {
                title: "Days rejected",
                dataIndex: "countDaysRejected",
                key: "countDaysRejected",
                sorter: (a, b) => (a.countDaysRejected < b.countDaysRejected ? -1 : 1),
              },
              {
                title: "Days remaining",
                dataIndex: "countDaysRemaining",
                key: "countDaysRemaining",
                sorter: (a, b) => (a.countDaysPending < b.countDaysPending ? -1 : 1),
              },
            ]}
            expandable={{
              expandedRowRender: displayDetailsPerUser,
              defaultExpandedRowKeys: [defaultExpandedRow],
              expandIcon: ({ expanded, onExpand, record }) =>
                expanded ? (
                  <Button icon={<DownOutlined />} onClick={(e) => onExpand(record, e)} data-cy="collapse-button" />
                ) : (
                  <Button
                    icon={<RightOutlined />}
                    onClick={(e) => onExpand(record, e)}
                    data-cy={`${isSick ? "sick-day" : "holiday"}-expand-button`}
                  />
                ),
            }}
            dataSource={users
              .filter((x) => !x.isHidden && !x.isDisabled)
              .map((user) => {
                return getDataForUser(user.id);
              })}
          />
        )}
      </Card>
      <Card
        withSpace
        title={`${isSick ? "Sick" : "Holiday"} allowance intervals`}
        flexTitle
        wrapHeader
        actions={
          <Button
            type="primary"
            icon={<PlusCircleOutlined />}
            onClick={() => {
              setState({
                isIntervalModalOpen: true,
              });
            }}
          >
            Add interval
          </Button>
        }
      >
        <Table
          rowKey={"id"}
          loading={isLoadingIntervals}
          scroll={{ x: 400 }}
          columns={[
            {
              title: "Name",
              key: "name",
              dataIndex: "name",
              align: "left",
            },
            {
              title: "Created at",
              dataIndex: "createdAt",
              key: "createdAt",
              render: (value) => moment(value).format("DD-MM-YYYY"),
            },

            {
              title: "Start date",
              key: "startDate",
              dataIndex: "startDate",
              render: (startDate) => moment(startDate).format("DD-MM-YYYY"),
            },
            {
              title: "End date",
              key: "endDate",
              dataIndex: "endDate",
              render: (endDate) => moment(endDate).format("DD-MM-YYYY"),
            },
            {
              title: "",
              key: "actions",
              render: (_, interval: any) => {
                return (
                  <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end" }}>
                    <Button
                      icon={<EditOutlined />}
                      onClick={() => {
                        setState({
                          selectedIntervalForEditing: interval,
                          isIntervalModalOpen: true,
                        });
                      }}
                    />

                    <Button
                      icon={<DeleteOutlined />}
                      onClick={() => {
                        Modal.confirm({
                          title: `Are you sure you want to delete allowance interval ${interval.name}?`,
                          onOk: async () => {
                            try {
                              await callGraphQLSimple({
                                message: "Failed to delete interval",
                                mutation: "deleteInterval",
                                variables: {
                                  input: {
                                    id: interval.id,
                                  },
                                },
                              });
                              message.success(`Interval deleted: ${interval.name}`);
                            } catch (e) {
                              message.error(`Failed to delete interval ${interval.name}`);
                            }
                            fetchAndSetIntervals();
                          },
                        });
                      }}
                    />
                  </div>
                );
              },
            },
          ]}
          dataSource={intervals.filter((interval) => {
            if (isSick) {
              return interval.type === "SICK_ALLOWANCE";
            } else {
              return interval.type === "HOLIDAY_ALLOWANCE";
            }
          })}
          pagination={{ pageSize: 50, hideOnSinglePage: true }}
        />
      </Card>
      {isAddHolidayModalOpen && (
        <HolidayModal
          isSick={isSick}
          onClose={() => {
            setIsAddHolidayModalOpen(false);
          }}
          multipleUsers
          targetIntervals={intervals}
        />
      )}
      {isIntervalModalOpen && (
        <TimeOffAllowanceModal
          isSick={isSick}
          apiUser={apiUser}
          interval={selectedIntervalForEditing}
          onClose={() =>
            setState({
              isIntervalModalOpen: false,
              selectedIntervalForEditing: undefined,
            })
          }
          onSubmit={() => {
            fetchAndSetIntervals();
          }}
        />
      )}

      {isHolidayConflictsModalOpen && (
        <HolidayConflictsModal
          onClose={() => setIsHolidayConflictsModalOpen(false)}
          holidays={holidays}
          users={users}
          targetHoliday={selectedHoliday}
          approveHoliday={approveHoliday}
          rejectHoliday={rejectHoliday}
          confirmDeleteHoliday={confirmDeleteHoliday}
        />
      )}
    </div>
  );
}

export default withRouter(
  withSubscriptions({
    Component: TimeOffForAllUsers,
    subscriptions: ["users", "tasks", "projects", "clients", "organisationDetails", "holidays"],
  })
);
