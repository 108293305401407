import { useEffect, useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { message, InputNumber, Form, Modal, Button, Input, Select, notification, Typography, Radio } from "antd";
import { callGraphQLSimple } from "common/apiHelpers";
import { callGraphQL, copyS3MainFile, createFileVersionInApi, pointSheetsToLatestFileVersion } from "common/helpers";
import { getTaskRevisionName } from "common/naming";
import { getLabel } from "common/helpers";
import { createReview, createFileVersion, createSheet, createSheetRevision } from "graphql/mutations";
import { recalculateTaskEstimatedHours } from "common/taskHelpers";
import {
  updateTask,
  getFile,
  updateTaskRevision,
  createTaskRevision,
  deleteTaskRevision,
  createFile,
} from "graphql/queries_custom";
import DatePicker from "DatePicker/DatePicker";
import { getLatestFileVersion } from "common/shared";
import { getSimpleLabel } from "common/labels";

import "./CreateTaskRevisionModal.scss";

export function CreateTaskRevisionModal({ onClose, apiUser, organisationDetails, task, history, predefinedFields }) {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultName, setDefaultName] = useState();
  const [status, setStatus] = useState(
    organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions && organisationDetails?.fileStatuses
      ? organisationDetails?.fileStatuses[0].name
      : undefined
  );
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ status });
  }, []);

  useEffect(() => {
    getTaskRevisionName({
      organisation: apiUser.organisation,
      task,
      newStatus: status,
    }).then((name) => {
      setDefaultName(name);
      form.setFieldsValue({ name });
    });
  }, [status]); // eslint-disable-line

  function getFileDetailsToCopy(file) {
    const { id, taskRevisionId, auditItems, sheets, versions, updatedAt, ...detailsToCopy } = file;
    return detailsToCopy;
  }

  function getSheetDetailsToCopy(sheet) {
    const { id, fileId, revisions, updatedAt, ...detailsToCopy } = sheet;
    return detailsToCopy;
  }

  function getSheetRevisionDetailsToCopy(sheetRevision) {
    const { id, sheetId, fileVersion, updatedAt, ...detailsToCopy } = sheetRevision;
    return detailsToCopy;
  }

  function getFileVersionDetailsToCopy(fileVersion) {
    const { id, fileId, updatedAt, ...detailsToCopy } = fileVersion;
    return detailsToCopy;
  }

  async function onSubmit({ base, name, description, status, dueDate, estimatedHours, priorityId, requestedDate }) {
    setIsLoading(true);
    let messageKey = "create-task-revision-progress";

    let newTaskRevisionId = null;
    const oldTaskRevision = task.revisions.items.find((x) => x.id === base);
    message.loading({ content: `Creating ${getSimpleLabel("task revision")}`, key: messageKey, duration: 0 });
    try {
      const newReview = (
        await callGraphQL(
          "Failed to create review",
          graphqlOperation(createReview, {
            input: {
              organisation: apiUser.organisation,
              reviewThread: [],
              approvedItems: [],
            },
          })
        )
      ).data.createReview;

      if (!organisationDetails?.settings?.task?.allowMultipleLiveTaskRevisions) {
        await callGraphQL(
          `Failed to update old ${getLabel({
            id: "task revision",
            defaultValue: "task revision",
          })}`,
          graphqlOperation(updateTaskRevision, {
            input: {
              id: oldTaskRevision.id,
              isReadOnly: true,
            },
          })
        );
      }
      let formattedDueDate = null;
      let formattedRequestedDate = null;

      if (dueDate) {
        formattedDueDate = dueDate.format("YYYY-MM-DD");
      }
      if (requestedDate) {
        formattedRequestedDate = requestedDate.format("YYYY-MM-DD");
      }

      const newTaskRevision = (
        await callGraphQL(
          `Failed to create ${getLabel({
            id: "task revision",
            defaultValue: "task revision",
          })}`,
          graphqlOperation(createTaskRevision, {
            input: {
              taskId: task.id,
              base,
              name,
              description,
              deletedFilesByType: oldTaskRevision.deletedFilesByType,
              organisation: apiUser.organisation,
              author: task.assignedTo,
              checkedBy: "",
              reviewId: newReview.id,
              dueDate: formattedDueDate,
              requestedDate: formattedRequestedDate,
              estimatedHours,
              priorityId,
              status: window.organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions
                ? status
                : undefined,
            },
          })
        )
      ).data.createTaskRevision;
      newTaskRevisionId = newTaskRevision.id;

      await window.callGraphQLSimple({
        mutation: "createTaskActivityItem",
        message: `Failed to record ${getSimpleLabel("task")} activity item`,
        variables: {
          input: {
            taskId: task.id,
            author: apiUser.id,
            organisation: apiUser.organisation,
            type: "REVISION_CREATED",
            content: JSON.stringify({
              revisionName: newTaskRevision.name,
              revisionDescription: newTaskRevision.description,
            }),
          },
        },
      });

      if (organisationDetails.settings?.task?.useTaskRevisionEstimates) {
        await recalculateTaskEstimatedHours(task.id);
      }

      if (organisationDetails.settings?.task?.useDueDatesOnTaskRevisions) {
        await callGraphQLSimple({
          message: `Failed to update ${getSimpleLabel("task revision")} due date`,
          queryCustom: "updateTask",
          variables: {
            input: {
              id: task.id,
              dueDate: formattedDueDate,
            },
          },
        });
      }

      for (let i = 0; i < oldTaskRevision.files.items.length; i++) {
        message.loading({
          content: `Copying files: ${i + 1} / ${oldTaskRevision.files.items.length}`,
          key: messageKey,
          duration: 0,
        });
        const oldFileInTaskRevision = oldTaskRevision.files.items[i];
        if (oldFileInTaskRevision.isArchived) {
          continue;
        }

        // we retrieve the file details directly from the API because the query for getTask
        // doesn't go deep enough to reach all the fields we need
        const oldFile = (
          await callGraphQL(
            "Failed to retrieve file",
            graphqlOperation(getFile, {
              id: oldFileInTaskRevision.id,
            })
          )
        ).data.getFile;

        const latestVersionOfOldFile = getLatestFileVersion(oldFile);
        const newVersionNumber = latestVersionOfOldFile.versionNumber + 1;

        const newFile = (
          await callGraphQL(
            "Failed to create file",
            graphqlOperation(createFile, {
              input: {
                ...getFileDetailsToCopy(oldFile),
                taskRevisionId: newTaskRevision.id,
              },
            })
          )
        ).data.createFile;

        await new Promise((resolve) => setTimeout(resolve, 1000));

        // we need this in order to match new sheet revisions to the correct copied file version
        const fileVersionEquivalencies = {};

        for (let j = 0; j < oldFile.versions.items.length; j++) {
          const oldFileVersion = oldFile.versions.items[j];
          const newFileVersion = (
            await callGraphQL(
              "Failed to create file version",
              graphqlOperation(createFileVersion, {
                input: {
                  organisation: task.organisation,
                  fileId: newFile.id,
                  ...getFileVersionDetailsToCopy(oldFileVersion),
                },
              })
            )
          ).data.createFileVersion;
          fileVersionEquivalencies[oldFileVersion.id] = newFileVersion.id;
          newFile.versions.items.push(newFileVersion);
        }

        const newFileVersion = (
          await createFileVersionInApi({
            apiUser,
            file: newFile,
            task,
            taskRevision: newTaskRevision,
            versionNumber: newVersionNumber,
          })
        ).data.createFileVersion;

        for (let m = 0; m < oldFile.sheets.items.length; m++) {
          const oldSheet = oldFile.sheets.items[m];

          const newSheet = (
            await callGraphQL(
              "Failed to create sheet",
              graphqlOperation(createSheet, {
                input: {
                  fileId: newFile.id,
                  ...getSheetDetailsToCopy(oldSheet),
                },
              })
            )
          ).data.createSheet;

          for (let k = 0; k < oldSheet.revisions.items.length; k++) {
            const oldSheetRevision = oldSheet.revisions.items[k];

            await callGraphQL(
              "Failed to copy existing sheet revision",
              graphqlOperation(createSheetRevision, {
                input: {
                  sheetId: newSheet.id,
                  fileVersionId: fileVersionEquivalencies[oldSheetRevision.fileVersionId],
                  ...getSheetRevisionDetailsToCopy(oldSheetRevision),
                },
              })
            );
          }

          let latestSheetRevisionInOldTaskRevision = oldSheet.revisions.items.slice(-1)[0];

          if (organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions) {
            await callGraphQL(
              "Failed to create sheet revision",
              graphqlOperation(createSheetRevision, {
                input: {
                  sheetId: newSheet.id,
                  fileVersionId: newFileVersion.id,
                  ...getSheetRevisionDetailsToCopy(latestSheetRevisionInOldTaskRevision),
                  reviewAcceptDate: null,
                  checkedBy: null,
                  author: task.assignedTo,
                  createdAt: new Date().toISOString(),
                  realCreatedAt: new Date().toISOString(),
                  status,
                  description,
                  name,
                },
              })
            );
          }
        }

        const newFileWithContents = (
          await callGraphQL(
            "Failed to retrieve file",
            graphqlOperation(getFile, {
              id: newFile.id,
            })
          )
        ).data.getFile;

        await pointSheetsToLatestFileVersion({
          apiUser,
          task,
          file: newFileWithContents,
          taskRevision: newTaskRevision,
        });

        await copyS3MainFile({
          history,
          task,
          apiUser,
          oldTaskRevision,
          taskRevision: newTaskRevision,
          oldFile,
          file: newFileWithContents,
          oldVersionNumber: latestVersionOfOldFile.versionNumber,
          newVersionNumber,
          extension: newFileWithContents.extension,
          doPublish: true,
          oldFileVersion: latestVersionOfOldFile,
          newFileVersion,
        });
      }

      await callGraphQL(
        "Could not update task",
        graphqlOperation(updateTask, {
          input: {
            id: task.id,
            isReadOnly: false,
            randomNumber: Math.floor(Math.random() * 1000000),
            reviewStatus: null,
            reviewSecondaryStatus: null,
            isUnderReview: false,
          },
        })
      );

      message.success({ content: `${getSimpleLabel("Task revision")} created: ${name}`, key: messageKey, duration: 5 });
      onClose();
    } catch (e) {
      setIsLoading(false);
      message.destroy(messageKey);
      if (!oldTaskRevision.isReadOnly) {
        await callGraphQL(
          `Failed to update old ${getLabel({
            id: "task revision",
            defaultValue: "task revision",
          })}`,
          graphqlOperation(updateTaskRevision, {
            input: {
              id: oldTaskRevision.id,
              isReadOnly: false,
            },
          })
        );
      }

      await callGraphQL(
        "",
        graphqlOperation(deleteTaskRevision, {
          input: {
            id: newTaskRevisionId,
          },
        })
      );

      console.error(e);
      notification.error({
        message: (
          <Typography.Text>
            Could not create{" "}
            {getLabel({
              id: "task revision",
              defaultValue: "task revision",
            })}
          </Typography.Text>
        ),
        duration: 0,
      });
      onClose();
    }
  }

  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  if (defaultName === undefined || !task) {
    return null;
  }

  return (
    <Modal
      maskClosable={false}
      title={`Create ${getLabel({
        id: "task revision",
        defaultValue: "task revision",
      })}`}
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-task-revision-modal full-screen-on-mobile"
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          base: task.revisions.items.slice(-1)[0].id,
          name: defaultName,
          ...predefinedFields,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Based on"
          name="base"
          rules={[
            {
              required: true,
              message: "You must specify a base for the new revision",
            },
          ]}
        >
          <Select>
            {task.revisions.items.map((revision) => (
              <Select.Option value={revision.id} key={revision.id}>
                {revision.name} - {revision.description}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "You must specify a name",
            },
            {
              validator: async (_, name) => {
                if (!name) {
                  return;
                }

                const taskRevisionNameIsAlreadyUsed = task.revisions.items.find((x) => x.name === name);

                if (taskRevisionNameIsAlreadyUsed) {
                  throw new Error(`This name already used`);
                }
              },
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Description"
          name="description"
          rules={[
            {
              required: true,
              message: "You must specify a description",
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        {organisationDetails.settings?.task?.usesPriority && (
          <Form.Item
            label="Priority"
            name="priorityId"
            rules={[
              {
                required: true,
                message: "You must choose a priority",
              },
            ]}
          >
            <Select style={{ width: "100%" }} placeholder="Not set" disabled={predefinedFields?.priorityId}>
              {organisationDetails.settings?.task?.priorities?.map((priority) => (
                <Select.Option key={priority.id} value={priority.id}>
                  {priority.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        )}
        {organisationDetails.settings?.task?.usesRequestedDate && (
          <Form.Item
            label="Requested date"
            name="requestedDate"
            rules={[
              {
                required: true,
                message: "You must choose a requested date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" className="date-picker" data-cy="requested-date" />
          </Form.Item>
        )}
        {organisationDetails.settings?.task?.useDueDatesOnTaskRevisions && (
          <Form.Item
            label="Due date"
            name="dueDate"
            rules={[
              {
                required: organisationDetails.settings?.task?.isTaskDueDateMandatory,
                message: "You must choose a due date",
              },
            ]}
          >
            <DatePicker format="DD-MM-YYYY" className="date-picker" data-cy="due-date" />
          </Form.Item>
        )}

        {organisationDetails.settings?.task?.useTaskRevisionEstimates && (
          <Form.Item label="Estimated hours" name="estimatedHours">
            <InputNumber />
          </Form.Item>
        )}

        {organisationDetails?.settings?.task?.taskRevisionsAreSyncedWithSheetRevisions && (
          <Form.Item
            label="Status"
            name="status"
            rules={[
              {
                required: true,
                message: "You must choose a status",
              },
            ]}
          >
            <Radio.Group
              className="sheet-revision-status-list"
              onChange={(e) => {
                form.setFieldsValue({ status: e.target.value });
                setStatus(e.target.value);
              }}
              value={status}
            >
              {organisationDetails.fileStatuses?.map((status) => {
                return (
                  <Radio value={status.name.toUpperCase().split(" ").join("_")} key={status.name}>
                    {status.name}
                  </Radio>
                );
              })}
            </Radio.Group>
          </Form.Item>
        )}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(CreateTaskRevisionModal);
