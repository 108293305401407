import { useState } from "react";
import { Form, Modal, Button, Input, Alert } from "antd";

import { updateGroup } from "graphql/mutations";

import withSubscriptions from "common/withSubscriptions";
import { callGraphQL } from "common/helpers";
import { graphqlOperation } from "aws-amplify";

import "./EditGroupNameModal.scss";

export function EditGroupNameModal({ onClose, selectedGroup, groups }) {
  const [error, setError] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [form] = Form.useForm();

  async function onSubmit(formFields) {
    setIsLoading(true);
    setError();

    try {
      await callGraphQL(
        "Could not edit group name",
        graphqlOperation(updateGroup, {
          input: {
            id: selectedGroup.id,
            name: formFields["group-name"],
          },
        })
      );
      onClose();
    } catch (e) {
      setError(e.message);
      setIsLoading(false);
    }
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  return (
    <Modal
      maskClosable={false}
      title="Edit group name"
      visible={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="edit-group-name-modal"
    >
      {error ? (
        <>
          <Alert message={error} type="error" showIcon />
          <br />
        </>
      ) : null}
      <Form {...layout} form={form} onFinish={onSubmit}>
        <Form.Item
          label="Group name"
          name="group-name"
          rules={[
            {
              required: true,
              message: "You must specify a group name",
            },
            {
              validator: !selectedGroup
                ? undefined
                : async (_, name) => {
                    let groupWithSameName = (groups || []).find((group) => group?.name === name);
                    if (groupWithSameName) {
                      throw new Error("Group name must be unique");
                    }
                  },
            },
          ]}
        >
          <Input autoComplete="off" className="group-name" />
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
        {error ? <Alert message={error} type="error" showIcon /> : null}
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: EditGroupNameModal,
  subscriptions: ["groups"],
});
