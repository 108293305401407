import { useState } from "react";
import { Storage } from "aws-amplify";
import { Form, Modal, Button, Typography, Space, message, notification } from "antd";
import cx from "classnames";

import withSubscriptions from "common/withSubscriptions";
import { HAS_SHEETS } from "common/shared";

import Upload from "Upload/Upload";

import "./UploadFilePdfModal.scss";

export function UploadFilePdfModal({ onClose, file, triggerAnnotate }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);

  async function onSubmit(params) {
    try {
      setIsLoading(true);

      let everythingIsValid = true;

      for (let sheetName in params) {
        const pdfFile = params[sheetName];
        if (!pdfFile) {
          delete params[sheetName];
          continue;
        }

        if (!pdfFile.name.toLowerCase().endsWith(".pdf")) {
          message.error("Only .pdf files are allowed");
          everythingIsValid = false;
          break;
        }

        let sheetDetails = file.sheetsInApplication.find((sheetInApplication) => sheetInApplication.name === sheetName);

        if (!sheetDetails) {
          message.error(`Sheet not found: ${sheetName}`);
          everythingIsValid = false;
          break;
        }
      }

      if (Object.keys(params).length === 0) {
        if (HAS_SHEETS[file.type]) {
          message.error("You must upload at least one PDF");
        } else {
          message.error("You must upload a PDF");
        }
        everythingIsValid = false;
      }

      if (!everythingIsValid) {
        setIsLoading(false);
        return;
      }

      if (Object.keys(params).length < file.sheets.items.length) {
        try {
          let missingSheetsCount = file.sheets.items.length - Object.keys(params).length;
          await new Promise((resolve, reject) => {
            Modal.confirm({
              title: `${missingSheetsCount} sheet${missingSheetsCount === 1 ? "" : "s"} do${
                missingSheetsCount === 1 ? "es" : ""
              } not have a new PDF`,
              content:
                "If you continue, only the PDFs you have uploaded will be updated, the rest will remain as they were before. Are you sure you want to continue?",
              okText: "Yes, continue",
              cancelText: "No, cancel",
              onCancel: () => {
                reject();
              },
              onOk: () => {
                resolve();
              },
            });
          });
        } catch (e) {
          // the user chose not to continue
          setIsLoading(false);
          return;
        }
      }

      const messageKey = "uploading-pdfs";
      message.loading({
        content: "Uploading PDFs...",
        key: messageKey,
        duration: 0,
      });
      try {
        if (HAS_SHEETS[file.type]) {
          for (let sheetName in params) {
            if (!params[sheetName]) {
              continue;
            }
            const pdfFile = params[sheetName];

            let sheetDetails = file.sheetsInApplication.find(
              (sheetInApplication) => sheetInApplication.name === sheetName
            );
            let exportKey = sheetDetails.exportKey;

            await Storage.put(exportKey.replace("public/", ""), pdfFile, {
              contentType: "application/pdf",
            });
          }
        } else {
          const pdfFile = params[Object.keys(params)[0]];
          let sheetInApplication = file.sheetsInApplication[0];
          await Storage.put(sheetInApplication.exportKey.replace("public/", ""), pdfFile, {
            contentType: "application/pdf",
          });
        }
        message.loading({
          content: "PDFs uploaded successfully, annotating...",
          key: messageKey,
          duration: 0,
        });
        await triggerAnnotate();
        await new Promise((resolve) => setTimeout(resolve, 5000));
        message.success({
          content: "Annotation finished successfully",
          key: messageKey,
          duration: 2,
        });
        form.resetFields();
        setIsLoading(false);
        onClose();
      } catch (e) {
        message.destroy();
        message.error({
          content: "Failed to upload PDFs",
          key: messageKey,
        });
        throw e;
      }
    } catch (e) {
      setIsLoading(false);
      console.log(e);
      notification.error({
        message: (
          <Typography.Text>
            Failed to upload PDF:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
    }
  }

  let modalTitle = "";

  if (HAS_SHEETS[file.type]) {
    if (file.sheets.items.length > 1) {
      modalTitle = "Upload sheet PDFs";
    } else {
      modalTitle = "Upload PDF";
    }
  } else {
    modalTitle = "Upload PDF";
  }

  let sheetsInApplicationWithCorrespondingSheetsInDatabase = [];
  if (HAS_SHEETS[file.type]) {
    sheetsInApplicationWithCorrespondingSheetsInDatabase = file.sheetsInApplication.filter((sheetInApplication) => {
      return file.sheets.items.find((sheet) => sheet.name === sheetInApplication.name);
    });
  } else {
    sheetsInApplicationWithCorrespondingSheetsInDatabase = file.sheetsInApplication;
  }

  return (
    <Modal
      maskClosable={false}
      title={modalTitle}
      open={true}
      onOk={onSubmit}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      footer={null}
      className={cx("upload-file-pdf-modal", "full-screen-on-mobile", {
        "no-sheets": !HAS_SHEETS[file.type],
      })}
    >
      {file.sheets.items.length > 1 && (
        <Typography.Text className="note">
          Note: you can choose to only upload PDFs for some of the sheets. In that case, the rest will remain unchanged.
        </Typography.Text>
      )}
      <Form layout="vertical" form={form} initialValues={{}} onFinish={onSubmit}>
        <Space direction="vertical">
          {sheetsInApplicationWithCorrespondingSheetsInDatabase.map((sheetInApplication, index) => (
            <Form.Item
              name={sheetInApplication.name}
              key={index}
              label={sheetInApplication.name}
              getValueFromEvent={(e, uploadedFile) => uploadedFile}
            >
              <Upload size="normal" />
            </Form.Item>
          ))}

          <div className="submit-container">
            <Button type="primary" htmlType="submit" loading={isLoading} className="submit-upload-file-pdf-modal">
              {isLoading ? "Uploading..." : "Upload"}
            </Button>
          </div>
        </Space>
      </Form>
    </Modal>
  );
}

export default withSubscriptions({
  Component: UploadFilePdfModal,
  subscriptions: ["organisationDetails"],
});
