import React from "react";
import moment from "moment";
import cookie from "js-cookie";
import { withRouter } from "react-router-dom";
import { message, Alert, Typography, notification, Modal, Button } from "antd";
import { ExclamationCircleOutlined, DeleteOutlined } from "@ant-design/icons";
import cx from "classnames";
import _ from "lodash";
import axios from "axios";

import withSubscriptions from "common/withSubscriptions";
import { downloadBlob } from "common/helpers";
import getS3File from "common/getS3File";
import { callRest } from "common/apiHelpers";
import { callGraphQLSimple } from "common/apiHelpers";
import {
  COOKIE_NAME_QUOTE_PREVIEW,
  COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE,
  MIN_DOCUMENT_FORM_WIDTH,
} from "common/constants";
import { KEY_TYPES, encodeKey, getTemplateFromOrganisation } from "common/shared";
import {
  getInvoiceForDisplay,
  doAmountsMatch,
  isInvoiceFullyApproved,
} from "common/invoiceHelpers/sharedInvoiceHelpers";
import { recalculateInvoiceAmounts, confirmDeleteInvoice } from "common/invoiceHelpers";
import { changeFileNameAtDownloadTime } from "common/naming";
import { isAuthorised } from "common/permissions";
import { recordActivityItem } from "common/invoiceHelpers/invoiceHelpers";
import {
  displayInsertAttachmentModal,
  displayInsertAttachmentPickerModal,
  displayReportUserListModal,
  displayFields,
  displayModalContainingFields,
  computeHiddenFormFields,
} from "ReportPage/Report/reportHelpers";
import { processLambdaPdfInserts, processReportPageMapping } from "common/documentRenderHelpers";
import { getSimpleLabel } from "common/labels";

import DocumentOutput from "DocumentOutput/DocumentOutput";
import TimesheetBlocksTable from "TimesheetBlocksTable/TimesheetBlocksTable";
import DocumentDetailsModal from "Modals/DocumentDetailsModal/DocumentDetailsModal";
import Card from "Card/Card";
import RequestInvoiceReviewModal from "Modals/RequestInvoiceReviewModal/RequestInvoiceReviewModal";
import SendInvoiceModal from "Modals/SendDocumentModal/SendInvoiceModal";
import InvoiceActivity from "./InvoiceActivity/InvoiceActivity";
import InvoiceMetadata from "./InvoiceMetadata/InvoiceMetadata";
import InvoiceActions from "./InvoiceActions/InvoiceActions";
import InvoiceFinalReviewSummary from "./InvoiceFinalReviewSummary/InvoiceFinalReviewSummary";
import InvoiceReviewSummary from "./InvoiceReviewSummary/InvoiceReviewSummary";
import InvoiceLineItemsCard from "./InvoiceLineItemsCard/InvoiceLineItemsCard";
import DocumentReview from "DocumentReview/DocumentReview";

import "./InvoiceDetailsPage.scss";

export class InvoiceDetailsPage extends React.Component {
  state = {
    isDescriptionOpen: false,
    description: "",
    dataUri: null,
    form: null,
    formPreview: null,
    numberForPreviewRefresh: 0,
    isSaving: false,
    isCalculating: false,
    savedAt: moment(),
    fieldUnderEditName: null,
    fieldUnderEditSelectionStart: null,
    subFieldUnderEditIndex: null,
    fieldUnderEditValue: "",
    isAttachmentPickerOpen: false,
    isReportUserListModalOpen: false,
    isCreatingTaskFromLineItemId: null,
    isCreateTaskModalVisible: false,
    isSendingInvoice: false,
    isDownloadingInvoice: false,
    isInsertAttachmentsModalOpen: false,
    sendStatus: null,
    selectedLineItemId: null,
    isPreviewEnabled: false,
    isAddInvoiceLineItemToTaskModalVisible: false,
    isRequestReviewModalVisible: false,
    hasNewComment: false,
    newCommentFieldName: null,
    newCommentLineItemIndex: null,
    areCommentsVisible: true,
    isUnderReview: false,
    isSendInvoiceModalVisible: false,
    approvedPdfData: null,
    isApprovedPdfVisible: false,
    isCorrupted: false,
    corruptedReason: null,
    dateTimeToCheckFormAgainst: moment().toISOString(),
    hiddenFormFields: {},
  };

  constructor(props) {
    super(props);
    this.debouncedInnerSaveUserFields = _.debounce(this.saveUserFields, 1000);

    this.debouncedSaveUserFields = () => {
      computeHiddenFormFields.call(this);
      this.debouncedInnerSaveUserFields();
    };
    this.debouncedChangeAttribute = _.debounce(this.changeAttribute, 500);
  }

  async componentDidMount() {
    this._isMounted = true;
    this.props.setBoxedLayout(false);
    this.props.showPreloader();
    this.intervalCheckFormWidth = setInterval(this.checkFormWidth, 3000);
    setTimeout(this.checkFormWidth, 1000);

    this.recalculateInvoiceAmountsViaApi({ displayMessage: false });

    // this.checkQueryParamsForAutoScroll();
    this.setState({ isUnderReview: this.props.invoice.isUnderReview });

    const isPreviewEnabled = cookie.get(COOKIE_NAME_QUOTE_PREVIEW);
    if (isPreviewEnabled === "true") {
      this.setState({ isPreviewEnabled: true });
    } else if (isPreviewEnabled === "false") {
      this.setState({ isPreviewEnabled: false });
    }

    const areCommentsVisible = cookie.get(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE);
    if (areCommentsVisible === "true") {
      this.setState({ areCommentsVisible: true });
    } else if (areCommentsVisible === "false") {
      this.setState({ areCommentsVisible: false });
    }

    const { invoice } = this.props;

    const projectFolder = await encodeKey({
      type: KEY_TYPES.PROJECT_FOLDER,
      data: {
        organisation: invoice.organisation,
        projectId: invoice.projectId,
      },
    });

    this.setState({
      projectFolder,
    });

    if (!invoice.project) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("project")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    if (!invoice.client) {
      this.setState({
        isCorrupted: true,
        corruptedReason: `No ${getSimpleLabel("client")} found.`,
      });
      this.props.hidePreloader();
      return;
    }

    await Promise.all([
      this.loadFile(),

      this.loadTimesheetBlocks(),
      callGraphQLSimple({
        displayError: false,
        mutation: "createAuditItem",
        variables: {
          input: {
            taskId: "nothing",
            projectId: invoice.projectId,
            fileId: invoice.id,
            clientId: invoice.client.id,
            page: "INVOICE_DETAILS",
            type: "PAGE_VIEW",
            userId: window.apiUser.id,
            organisation: window.apiUser.organisation,
          },
        },
      }),
      this.fetchApprovedVersion(),
    ]);

    this.isReviewed = this.isDisabled();
    setTimeout(this.checkIfAmountsMatch, 3000);
  }

  checkFormWidth = () => {
    const formElement = document.querySelector(".user-input-container");
    if (!formElement) {
      return;
    }

    // if the width of the form is below the threshold

    if (formElement.offsetWidth < MIN_DOCUMENT_FORM_WIDTH && this.state.isPreviewEnabled) {
      this.setState({ isPreviewEnabled: false });
    }
  };

  loadTimesheetBlocks = async () => {
    const { invoice, fetchAndSetTimesheetBlocks, apiUser } = this.props;

    await fetchAndSetTimesheetBlocks({
      organisation: apiUser.organisation,
      startAt: moment().subtract(100, "year").toISOString(),
      endAt: moment().add(1, "year").toISOString(),
      invoiceId: invoice.id,
    });

    this.setState({
      numberForPreviewRefresh: this.state.numberForPreviewRefresh + 1,
    });
  };

  componentDidUpdate(prevProps) {
    const { invoice } = this.props;
    const { savedAt: savedAtOld, updatedAt: updatedAtOld, ...oldInvoiceForChecking } = prevProps.invoice;
    const { savedAt: savedAtNew, updatedAt: updatedAtNew, ...newInvoiceForChecking } = invoice;

    if (JSON.stringify(oldInvoiceForChecking) !== JSON.stringify(newInvoiceForChecking)) {
      this.loadTimesheetBlocks();
    }
    if (prevProps.invoice.isUnderReview !== this.props.invoice.isUnderReview) {
      this.setState({ isUnderReview: this.props.invoice.isUnderReview });
    }
    this.isReviewed = this.isDisabled();

    if (this.state.approvedPdfData && !this.isReviewed) {
      this.setState({
        approvedPdfData: undefined,
        isApprovedPdfVisible: false,
      });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.props.setBoxedLayout(true);
    if (this.intervalCheckFormWidth) {
      clearInterval(this.intervalCheckFormWidth);
    }
  }

  getInvoiceForDisplay = (params) => {
    const invoiceFromParams = params?.invoice;
    let invoice = invoiceFromParams || this.props.invoice;
    return getInvoiceForDisplay({
      invoice,
      client: this.props.clients.find((x) => x.id === this.props.invoice.clientId),
      quotes: this.props.quotes,
      organisationDetails: this.props.organisationDetails,
      timesheetBlocks: this.props.timesheetBlocks,
      tasks: this.props.tasks,
    });
  };

  checkIfAmountsMatch = () => {
    if (!this._isMounted) {
      return;
    }
    let invoiceForDisplay = this.getInvoiceForDisplay();
    try {
      doAmountsMatch({ invoiceForDisplay });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            <b>There is a problem with this invoice.</b>
            <br /> {e.message}
            <br />
            <br />
            <u onClick={() => window.location.reload()} style={{ cursor: "pointer" }}>
              Click here to refresh the page and trigger a recalculation.
            </u>
          </Typography.Text>
        ),
        duration: 0,
      });

      return;
    }
  };

  getReviewFullyApprovedAt = () => {
    const { organisationDetails, invoice } = this.props;
    return isInvoiceFullyApproved({ invoice, organisationDetails });
  };

  fetchApprovedVersion = async () => {
    const { invoice } = this.props;

    const reviewFullyApprovedAt = this.getReviewFullyApprovedAt();

    if (reviewFullyApprovedAt) {
      const publicPdfUrl = await getS3File(invoice.fileKey.replace(".json", ".pdf").replace("public/", ""));
      const pdfDataBlob = (await axios.get(publicPdfUrl, { responseType: "blob" })).data;
      const pdfData = await new Response(pdfDataBlob).arrayBuffer();

      this.setState({ approvedPdfData: pdfData, isPreviewEnabled: false });
    }
  };

  getPotentialReviewers = () => {
    const { users, invoice, apiUser } = this.props;

    let potentialReviewers = users.filter((user) => user.invoiceReviewLimit && user.invoiceReviewLimit > invoice.total);
    if (!potentialReviewers.some((user) => user.id === apiUser.id)) {
      if (
        invoice.author === apiUser.id &&
        apiUser.invoiceCreationLimit &&
        apiUser.invoiceCreationLimit >= invoice.total
      ) {
        potentialReviewers.unshift(apiUser);
      }
    }
    return potentialReviewers;
  };

  loadFile = async () => {
    const { invoice } = this.props;
    if (!invoice.fileKey) {
      this.setState({
        isCorrupted: true,
        corruptedReason: "No invoice file found.",
      });
      this.props.hidePreloader();
      return;
    }

    const filePublicURL = await getS3File(invoice.fileKey.replace("public/", ""));
    const invoiceFileData = (await axios.get(filePublicURL)).data;

    this.setState(
      {
        form: invoiceFileData,
        formPreview: invoiceFileData,
      },
      async () => {
        await computeHiddenFormFields.call(this);
        this.props.hidePreloader();
      }
    );
  };

  saveUserFields = async () => {
    const { form } = this.state;
    const { invoice } = this.props;

    this.setState({
      isSaving: true,
      isSaveError: false,
      userHasChangedSomething: true,
    });
    try {
      await Storage.put(invoice.fileKey.replace("public/", ""), JSON.stringify(form));
      callGraphQLSimple({
        message: 'Failed to update invoice "saved at"',
        queryCustom: "updateInvoice",
        variables: {
          input: {
            id: invoice.id,
            savedAt: new Date().toISOString(),
          },
        },
      });
      this.setState({
        formPreview: form,
        savedAt: moment(),
        isSaving: false,
        dateTimeToCheckFormAgainst: moment().add(3, "seconds").toISOString(),
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to save invoice:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      this.setState({ isSaving: false, isSaveError: true });
    }
  };

  isDisabled = () => {
    const { invoice, organisationDetails } = this.props;

    let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;
    let userIsDoubleReviewer = usesDoubleInvoiceReview && isAuthorised(["INVOICES.PERFORM_SECOND_REVIEW"]);

    if (
      invoice.status !== "DRAFT" ||
      invoice.isArchived ||
      invoice.secondReviewApprovedAt ||
      (!userIsDoubleReviewer && invoice.reviewApprovedAt)
    ) {
      return true;
    }

    return false;
  };

  uploadPreviewPdf = async (dataUri) => {
    const { invoice } = this.props;
    const { approvedPdfData } = this.state;

    if (approvedPdfData || moment(invoice.reviewApprovedAt).diff(moment(), "seconds") > 10) {
      return;
    }

    const invoicePdfBlob = await (await fetch(dataUri)).blob();
    let newMetadata = {
      inserts: processLambdaPdfInserts(window.lambdaPdfInserts[window.reportRenderCycle]),
      assets: window.lambdaPdfAssets,
      pageMapping: processReportPageMapping(window.reportPageMapping[window.reportRenderCycle]),
      pageNumbersToSkipBorders: window.lambdaPdfPageNumbersToSkipBorders,
    };

    const newMetadataString = JSON.stringify(newMetadata);
    const oldMetadataString = JSON.stringify(
      JSON.parse(JSON.stringify(invoice.metadata), (key, value) => {
        if (value === null) {
          return undefined;
        }
        return value;
      })
    );
    if (newMetadataString !== oldMetadataString) {
      callGraphQLSimple({
        displayError: false,
        queryCustom: "updateInvoice",
        variables: {
          input: {
            id: invoice.id,
            metadata: newMetadata,
          },
        },
      });
    }

    const invoicePdfFile = new File([invoicePdfBlob], "");
    await Storage.put(invoice.fileKey.replace("public/", "").replace(".json", "_raw.pdf"), invoicePdfFile, {
      contentType: "application/pdf",
    });
  };

  onDataUri = async (dataUri) => {
    if (this.state.dataUri === dataUri) {
      return;
    }
    this.setState({ dataUri });
    this.uploadPreviewPdf(dataUri);
  };

  recordActivityItemForAttributeChange({ fieldName, value, content }) {
    const { users, apiUser } = this.props;
    let invoice = { ...this.props.invoice, [fieldName]: value };
    let type;
    if (fieldName === "status") {
      if (value === "DRAFT") {
        type = "STATUS_CHANGED";
      } else {
        type = value;
      }

      recordActivityItem({
        invoice,
        type,
        author: apiUser.id,
        users,
        content,
      });
    }
  }

  changeAttribute = async ({ fieldName, value, includeRecalculation, activityItemContent }) => {
    const { invoice } = this.props;
    this.recordActivityItemForAttributeChange({
      fieldName,
      value,
      content: activityItemContent,
    });

    if (fieldName === "taxRate") {
      await Promise.all(
        invoice.lineItems.items.map((lineItem) => {
          return callGraphQLSimple({
            message: "Failed to update tax rate on line item",
            queryCustom: "updateInvoiceLineItem",
            variables: {
              input: {
                id: lineItem.id,
                taxRate: value,
              },
            },
          });
        })
      );
    }

    const updatedInvoice = (
      await callGraphQLSimple({
        message: "Failed to update invoice field",
        queryCustom: "updateInvoice",
        variables: {
          input: {
            id: invoice.id,
            [fieldName]: value,
          },
        },
      })
    ).data.updateInvoice;

    if (includeRecalculation) {
      await recalculateInvoiceAmounts({ invoiceId: updatedInvoice.id });
    }
    // if (fieldName === "status") {
    //   this.onStatusUpdate(updatedInvoice);
    // }
  };

  // onStatusUpdate = async (updatedInvoice) => {
  //   let updateTaskPromises;
  //   switch (updatedInvoice.status) {
  //     case "ACCEPTED":
  //       updateTaskPromises = updatedInvoice.lineItems.items
  //         .filter((lineItem) => lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing")
  //         .map((lineItem) => {
  //           return callGraphQL(
  //             "Failed to update task",
  //             graphqlOperation(updateTask, {
  //               input: {
  //                 id: lineItem.resultingTaskId,
  //                 isConfirmed: true,
  //                 isArchived: false,
  //               },
  //             })
  //           );
  //         });
  //       await Promise.all(updateTaskPromises);
  //       break;

  //     default:
  //       updateTaskPromises = updatedInvoice.lineItems.items
  //         .filter((lineItem) => lineItem.resultingTaskId && lineItem.resultingTaskId !== "nothing")
  //         .map((lineItem) => {
  //           return callGraphQL(
  //             "Failed to update task",
  //             graphqlOperation(updateTask, {
  //               input: {
  //                 id: lineItem.resultingTaskId,
  //                 isConfirmed: false,
  //               },
  //             })
  //           );
  //         });
  //       break;
  //   }
  // };

  // changeLineItemCustomFields = async ({ fieldName, id, value }) => {
  //   const { form } = this.state;
  //   const updatedJsonLineItems = JSON.parse(JSON.stringify(form.lineItems));
  //   updatedJsonLineItems.forEach((lineItem) => {
  //     if (lineItem.id !== id) {
  //       return;
  //     }
  //     lineItem[fieldName] = value;
  //   });
  //   this.setState(
  //     {
  //       form: {
  //         ...form,
  //         lineItems: updatedJsonLineItems,
  //       },
  //     },
  //     this.debouncedSaveUserFields
  //   );
  // };

  displayInputLabel = ({ fieldData, fieldName }) => {
    return (
      <Typography.Paragraph className="field-label">
        <Typography.Text className="field-name">{fieldData.label ? fieldData.label : fieldName}</Typography.Text>{" "}
      </Typography.Paragraph>
    );
  };

  displayInvoiceFields = () => {
    return (
      <Card title="Invoice details" className="invoice-fields-card" withSpace>
        {displayFields.call(this, { showHiddenByModal: false })}
      </Card>
    );
  };

  downloadInvoice = async () => {
    const { invoice } = this.props;
    this.setState({ isDownloadingInvoice: true });
    try {
      if (!this.getReviewFullyApprovedAt()) {
        await callRest({
          route: "/build-document",
          method: "POST",
          body: {
            organisationId: invoice.organisation,
            invoiceId: invoice.id,
            projectId: invoice.projectId,
            clientId: invoice.clientId,
          },
          includeCredentials: false,
        });
      }

      await this.downloadPDF(invoice.fileKey.replace(".json", ".pdf"));
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to download PDF:
            <br />
            {e.response?.data?.error || e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      console.error(e);
    }
    this.setState({ isDownloadingInvoice: false });
  };

  // archiveInvoice = async () => {
  //   const { invoice } = this.props;
  //   try {
  //     await new Promise((resolve, reject) => {
  //       Modal.confirm({
  //         title: "Confirm archive invoice",
  //         maskClosable: true,
  //         content: <>Are you sure you want to archive this invoice?</>,
  //         okText: "Archive",
  //         onOk: () => {
  //           resolve();
  //         },
  //         onCancel: () => {
  //           reject();
  //         },
  //       });
  //     });
  //   } catch (e) {
  //     // nothing, it just means the user selected "cancel"
  //     return;
  //   }

  //   await callGraphQL(
  //     "Failed to archive invoice",
  //     graphqlOperation(updateInvoice, {
  //       input: {
  //         id: invoice.id,
  //         isArchived: true,
  //       },
  //     })
  //   );
  // };

  // restoreInvoice = async () => {
  //   const { invoice } = this.props;
  //   try {
  //     await new Promise((resolve, reject) => {
  //       Modal.confirm({
  //         title: "Confirm restore invoice",
  //         maskClosable: true,
  //         content: <>Are you sure you want to restore this invoice?</>,
  //         okText: "Restore",
  //         onOk: () => {
  //           resolve();
  //         },
  //         onCancel: () => {
  //           reject();
  //         },
  //       });
  //     });
  //   } catch (e) {
  //     // nothing, it just means the user selected "cancel"
  //     return;
  //   }

  //   await callGraphQL(
  //     "Failed to archive invoice",
  //     graphqlOperation(updateInvoice, {
  //       input: {
  //         id: invoice.id,
  //         isArchived: false,
  //       },
  //     })
  //   );
  // };

  checkInvoiceCanBeSent = async () => {
    const { invoice, clients } = this.props;

    if (invoice.reviewStatus !== "SUCCESS") {
      Modal.info({
        title: "Approval required",
        maskClosable: true,
        content: <>Before you can send this invoice, it must first be reviewed.</>,
      });
      return;
    }

    if (!invoice.assignedTo) {
      Modal.info({
        title: "Assignee required",
        maskClosable: true,
        content: <>Before you can send this invoice, you must first assign it to a user.</>,
      });
      return;
    }

    const client = clients.find((x) => x.id === invoice.clientId);
    const clientContact = client.contacts?.find((x) => x.id === invoice.clientContact);
    const clientAddress = client.addresses?.find((x) => x.id === invoice.clientAddress);

    if (!clientContact) {
      Modal.info({
        title: "Contact required",
        maskClosable: true,
        content: (
          <>Before you can send this invoice, you must first assign it to a {getSimpleLabel("client")} contact.</>
        ),
      });
      return;
    }

    if (!clientAddress) {
      Modal.info({
        title: "Address required",
        maskClosable: true,
        content: (
          <>Before you can send this invoice, you must first assign it to a {getSimpleLabel("client")} address.</>
        ),
      });
      return;
    }

    if (!clientContact) {
      Modal.error({
        title: "No contact",
        maskClosable: true,
        content: (
          <>
            The {getSimpleLabel("client")} contact {invoice.clientContact} does not exist anymore.
          </>
        ),
      });
      return;
    }

    if (!clientContact.email) {
      Modal.error({
        title: "Contact has no email",
        maskClosable: true,
        content: <>Invoice cannot be sent to a {getSimpleLabel("client")} contact without an email address.</>,
      });
      return;
    }

    if (!clientContact.firstName || !clientContact.lastName) {
      try {
        await new Promise((resolve, reject) => {
          let message;
          if (!clientContact.firstName && !clientContact.lastName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{invoice.clientContact}</b> does not have either a first or a
                last name. Do you want to continue?
              </>
            );
          } else if (!clientContact.firstName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{invoice.clientContact}</b> does not have a first name. Do you
                want to continue?
              </>
            );
          } else if (!clientContact.lastName) {
            message = (
              <>
                The {getSimpleLabel("client")} contact <b>{invoice.clientContact}</b> does not have a last name. Do you
                want to continue?
              </>
            );
          }
          Modal.confirm({
            title: "Missing contact details",
            maskClosable: true,
            content: message,
            onOk: () => resolve(),
            onCancel: () => reject(),
          });
        });
      } catch (e) {
        // user chose to cancel
        return;
      }
    }

    this.setState({ isSendInvoiceModalVisible: true });
  };

  downloadPDF = async (fileKey, versionId, readableDate) => {
    try {
      const publicUrl = await getS3File(fileKey.replace("public/", ""), versionId);
      await this.downloadEntirePdfFromPublicUrl(publicUrl, fileKey, readableDate);
    } catch (e) {
      console.error("Error downloading PDF:", e);
      notification.error({
        message: "Could not download PDF",
      });
    }
  };

  downloadEntirePdfFromPublicUrl = async (publicUrl, fileKey, readableDate) => {
    const { invoice, clients, projects } = this.props;

    const fileBlob = (
      await axios({
        url: publicUrl,
        method: "GET",
        responseType: "blob", // Important
      })
    ).data;

    const fileName = await changeFileNameAtDownloadTime({
      organisation: invoice.organisation,
      fileName: fileKey.split("/").slice(-1)[0],
      type: KEY_TYPES.INVOICE,
      invoice,
      clients,
      projects,
    });

    await downloadBlob({
      blob: fileBlob,
      fileName,
    });
  };

  openCommentBox = (name, lineItemIndex) => {
    this.setState({
      hasNewComment: true,
      newCommentFieldName: name,
      newCommentLineItemIndex: lineItemIndex,
      // areCommentsVisible: true,
    });
  };

  recordCommentActivityItem = async ({ content, actionType }) => {
    const { invoice, apiUser, clients, users } = this.props;
    let actionTypeReadable = `${actionType.toLowerCase()}d`;
    if (actionType === "EDIT") {
      actionTypeReadable = "edited";
    }
    await recordActivityItem({
      invoice,
      type: "REVIEW_ACTIVITY",
      author: apiUser.id,
      clients,
      users,
      content: `${apiUser.firstName} ${apiUser.lastName} ${actionTypeReadable} a comment: \n"${content}"`,
    });
  };

  refreshInvoice = async () => {
    const { invoice } = this.props;
    await callGraphQLSimple({
      message: "Failed to refresh invoice",
      queryCustom: "updateInvoice",
      variables: {
        input: {
          id: invoice.id,
          itemSubscription: Math.floor(Math.random() * 100000),
          // lastUpdateAuthorId: window.authorId,
        },
      },
    });

    // await this.props.fetchAndSetInvoice({ id: invoice.id });
    setTimeout(this.loadTimesheetBlocks, 1000);
  };

  displayAlertForTimesheetBlocksMissingDescription = () => {
    const { invoice, timesheetBlocks } = this.props;
    let hourlyQuoteLineItemItemIds = invoice.lineItems.items
      .filter((invoiceLineItem) => invoiceLineItem.quoteLineItem?.isHourly)
      .map((invoiceLineItem) => invoiceLineItem.quoteLineItem.id);

    let timesheetBlocksForLineItems = timesheetBlocks.filter(
      (timesheetBlock) =>
        hourlyQuoteLineItemItemIds.includes(timesheetBlock.quoteLineItemId) || timesheetBlock.variation
    );
    let timesheetBlocksWithoutDescription = timesheetBlocksForLineItems.filter(
      (timesheetBlock) => !timesheetBlock.description || timesheetBlock.description.trim().length === 0
    );

    if (timesheetBlocksWithoutDescription.length === 0) {
      return null;
    }

    return (
      <Card
        className="invoice-timesheet-blocks-card"
        title={
          <>
            <ExclamationCircleOutlined /> The following timesheet blocks do not have a description, but are displayed on
            this invoice:
          </>
        }
        withSpace
      >
        <TimesheetBlocksTable
          blocks={timesheetBlocksWithoutDescription}
          includeInvoicingStatus={false}
          triggerRefresh={() => {
            this.refreshInvoice();
          }}
        />
      </Card>
    );
  };

  displayApprovedVersion = () => {
    const { invoice, windowWidth, windowHeight } = this.props;
    const { approvedPdfData } = this.state;

    if (!approvedPdfData) {
      return null;
    }

    return (
      <DocumentDetailsModal
        open={true}
        attachment={{
          name: invoice.id,
          lastModified: this.getReviewFullyApprovedAt(),
          key: invoice.fileKey.replace(".json", ".pdf"),
          type: "PDF",
        }}
        document={approvedPdfData}
        onClose={() => this.setState({ isApprovedPdfVisible: false })}
        windowWidth={windowWidth}
        windowHeight={windowHeight}
      />
    );
  };

  displayLiveVersion = ({ invoiceForDisplay, projectDetails, clientDetails }) => {
    const { invoice, users, clients, organisationDetails, apiUser, quotes } = this.props;
    const { formPreview, numberForPreviewRefresh } = this.state;

    const templateDetails = getTemplateFromOrganisation({
      organisationDetails,
      fileType: "INVOICE",
      templateId: invoice.templateId,
    });

    return (
      <DocumentOutput
        apiUser={apiUser}
        invoice={invoiceForDisplay}
        project={projectDetails}
        users={users}
        clients={clients}
        client={clientDetails}
        quotes={quotes}
        clientContact={clientDetails.contacts?.find((x) => x.id === invoice.clientContact)}
        clientAddress={clientDetails.addresses?.find((x) => x.id === invoice.clientAddress)}
        organisationDetails={organisationDetails}
        previewData={formPreview}
        onDataUri={this.onDataUri}
        projectFolder={this.state.projectFolder}
        setIsLoading={this.props.setIsLoading}
        templateDetails={templateDetails}
        numberForRefresh={numberForPreviewRefresh}
      />
    );
  };

  recalculateInvoiceAmountsViaApi = async (params) => {
    const { displayMessage = true } = params || {};
    const { invoice, organisationDetails } = this.props;

    let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;

    if (
      (usesDoubleInvoiceReview && invoice.secondReviewApprovedAt) ||
      (!usesDoubleInvoiceReview && invoice.reviewApprovedAt)
    ) {
      return;
    }
    const messageKey = "recalculate-invoice-amounts";
    if (displayMessage) {
      message.loading({ content: "Recalculating invoice amounts...", key: messageKey, duration: 0 });
    }
    try {
      await recalculateInvoiceAmounts({ invoiceId: invoice.id });

      if (displayMessage) {
        message.success({ content: "Invoice amounts recalculated", key: messageKey });
      }
    } catch (e) {
      console.error(e);
      message.error({ content: "Failed to recalculate invoice amounts", key: messageKey });
      throw e;
    }
  };

  render() {
    const {
      invoice,
      users,
      clients,
      quotes,
      organisationDetails,
      apiUser,
      setProps,
      context,
      invoices,
      tasks,
      projects,
      history,
    } = this.props;
    const {
      form,
      areCommentsVisible,
      hasNewComment,
      isUnderReview,
      isApprovedPdfVisible,
      approvedPdfData,
      isCorrupted,
      corruptedReason,
      numberForPreviewRefresh,
    } = this.state;

    if (isCorrupted) {
      return (
        <div className={cx("invoice-details-page")}>
          <div className="corrupted-message-container">
            <Typography.Text className="corrupted-title">It looks like this invoice is corrupted.</Typography.Text>
            <Typography.Text className="corrupted-explanation">Reason: {corruptedReason}</Typography.Text>
            <Button
              type="primary"
              icon={<DeleteOutlined />}
              onClick={() =>
                confirmDeleteInvoice({ invoiceId: invoice.id, organisationId: organisationDetails.id, history })
              }
            >
              Delete invoice
            </Button>
          </div>
        </div>
      );
    }

    if (!form) {
      return null;
    }

    let usesDoubleInvoiceReview = organisationDetails.settings?.invoice?.usesDoubleInvoiceReview;
    let userIsDoubleReviewer = usesDoubleInvoiceReview && isAuthorised(["INVOICES.PERFORM_SECOND_REVIEW"]);

    const review = invoice.reviews?.items[0];

    let weHaveComments = false;
    if (review) {
      weHaveComments =
        hasNewComment || review.reviewThread.filter((item) => item.type === "COMMENT" && !item.resolved).length > 0;
    }

    const clientDetails = clients.find((client) => client.id === invoice.clientId);
    const projectDetails = projects.find((project) => project.id === invoice.projectId);

    let approvedReviewMessage = null;

    if (invoice.reviewStatus === "SUCCESS") {
      approvedReviewMessage =
        "The review for this invoice has been approved. If you want to make changes to it, please cancel the approval.";

      if (usesDoubleInvoiceReview && !invoice.secondReviewApprovedAt && userIsDoubleReviewer) {
        approvedReviewMessage =
          "The first stage of the review process for this invoice has been approved. While in this state, others cannot make changes to it, but you can, because you are authorised to perform the second review.";
      }
    }

    let invoiceForDisplay = this.getInvoiceForDisplay();

    const sortedClientContacts = (clientDetails.contacts || []).sort((a, b) => {
      if (a?.id < b?.id) return -1;
      if (a?.id > b?.id) return 1;
      return 0;
    });

    const reviewFullyApprovedAt = this.getReviewFullyApprovedAt();

    let shouldDisplayPreview = this.state.isPreviewEnabled && !reviewFullyApprovedAt;

    let bannerAdded = false;
    invoice.lineItems.items.forEach((item) => {
      if (item.quantity === undefined || item.quantity === null) {
        bannerAdded = true;
      }
    });

    return (
      <div
        className={cx("invoice-details-page", {
          "with-preview": shouldDisplayPreview,
          "with-comments": review && areCommentsVisible && weHaveComments,
          "is-archived": invoice.isArchived,
        })}
      >
        {displayModalContainingFields.call(this)}
        {isApprovedPdfVisible && this.displayApprovedVersion()}
        <div className="user-input-container">
          <InvoiceActions
            invoice={invoice}
            review={review}
            clientDetails={clientDetails}
            approvedPdfData={approvedPdfData}
            showApprovedPdf={() => this.setState({ isApprovedPdfVisible: true })}
            history={this.props.history}
            isPreviewEnabled={this.state.isPreviewEnabled}
            isDownloadingInvoice={this.state.isDownloadingInvoice}
            isSendingInvoice={this.state.isSendingInvoice}
            reviewFullyApprovedAt={this.getReviewFullyApprovedAt()}
            onPreviewSwitch={(checked) => {
              this.setState({ isPreviewEnabled: checked });
              cookie.set(COOKIE_NAME_QUOTE_PREVIEW, checked ? "true" : "false", { expires: 99999 });
            }}
            onRequestReviewClick={() => {
              this.setState({ isRequestReviewModalVisible: true });
            }}
            archiveInvoice={this.archiveInvoice}
            restoreInvoice={this.restoreInvoice}
            downloadInvoice={this.downloadInvoice}
            sendInvoice={this.checkInvoiceCanBeSent}
            windowWidth={this.props.windowWidth}
            areCommentsVisible={areCommentsVisible}
            dataUri={this.state.dataUri}
            onCommentsSwitch={(checked) => {
              this.setState({ areCommentsVisible: checked });
              cookie.set(COOKIE_NAME_QUOTE_ARE_COMMENTS_VISIBLE, checked ? "true" : "false", { expires: 99999 });
            }}
            setProps={setProps}
            context={context}
            apiUser={apiUser}
            users={users}
            clients={clients}
            recalculateInvoiceAmountsViaApi={this.recalculateInvoiceAmountsViaApi}
            refreshInvoice={this.refreshInvoice}
          />

          <div className="user-input-scroll-container">
            {invoice.amountPaid !== null &&
              invoice.amountPaid !== undefined &&
              invoice.amountPaid !== 0 &&
              invoice.amountPaid >= invoice.total && (
                <Alert
                  showIcon
                  className="invoice-paid-alert"
                  message="This invoice has been fully paid"
                  type="success"
                />
              )}
            {invoice.isArchived && (
              <Alert showIcon className="invoice-archive-alert" message="This invoice is archived" type="info" />
            )}
            {this.state.sendStatus === "SUCCESS" && (
              <Alert showIcon className="send-status-alert" message="Invoice sent successfully" type="success" />
            )}
            {this.state.sendStatus === "ERROR" && (
              <Alert showIcon className="send-status-alert" message="Invoice failed to send" type="error" />
            )}
            {invoice.status === "PAID" && (
              <Alert
                showIcon
                className="paid-status-alert"
                message="This invoice has been marked as paid. While in this state, you cannot make any changes to it."
                type="info"
              />
            )}

            {bannerAdded && (
              <Alert
                showIcon
                className="quantity-missing-alert"
                message="Quantity for at least one line item is missing. This can lead to issues."
                type="error"
              />
            )}

            {this.displayAlertForTimesheetBlocksMissingDescription()}
            {approvedReviewMessage && (
              <Alert showIcon className="paid-status-alert" message={approvedReviewMessage} type="info" />
            )}
            {userIsDoubleReviewer && window.location.pathname.includes("/final-review") && (
              <InvoiceFinalReviewSummary
                history={history}
                invoice={invoice}
                invoices={invoices}
                clients={clients}
                projects={projects}
              />
            )}
            <div className="reviewable-content">
              {review && (
                <InvoiceReviewSummary
                  invoice={invoice}
                  getInvoiceForDisplay={this.getInvoiceForDisplay}
                  apiUser={apiUser}
                  users={users}
                  clients={clients}
                  potentialReviewers={this.getPotentialReviewers()}
                  usesDoubleInvoiceReview={usesDoubleInvoiceReview}
                  userIsDoubleReviewer={userIsDoubleReviewer}
                  reviewFullyApprovedAt={this.getReviewFullyApprovedAt()}
                  dateTimeToCheckFormAgainst={this.state.dateTimeToCheckFormAgainst}
                  onSubmitReviewEnd={() => {
                    setTimeout(this.fetchApprovedVersion(), 1000);
                  }}
                  recalculateInvoiceAmountsViaApi={this.recalculateInvoiceAmountsViaApi}
                />
              )}
              <div className="invoice-metadata-wrapper">
                <InvoiceMetadata
                  {...this}
                  {...this.props}
                  isDisabled={this.isDisabled()}
                  apiUser={apiUser}
                  users={users}
                  invoice={invoice}
                  organisationDetails={organisationDetails}
                  sortedClientContacts={sortedClientContacts}
                  clientDetails={clientDetails}
                  changeAttribute={this.changeAttribute}
                  debouncedChangeAttribute={this.debouncedChangeAttribute}
                />
              </div>

              {this.displayInvoiceFields()}
              <InvoiceLineItemsCard
                invoice={invoice}
                project={projectDetails}
                invoiceForDisplay={invoiceForDisplay}
                numberForPreviewRefresh={numberForPreviewRefresh}
                refreshInvoice={this.refreshInvoice}
                isUnderReview={isUnderReview}
                organisationDetails={organisationDetails}
                isDisabled={this.isDisabled()}
                users={users}
                tasks={tasks}
                quotes={quotes}
                clients={clients}
                setProps={setProps}
                context={context}
                apiUser={apiUser}
                openCommentBox={this.openCommentBox}
              />

              <InvoiceActivity invoice={invoice} users={users} />
            </div>
          </div>
        </div>
        {areCommentsVisible && review && weHaveComments && (
          <DocumentReview
            parent={invoice}
            parentType="invoice"
            {...this}
            {...this.props}
            form={form}
            hasNewComment={this.state.hasNewComment}
            newCommentFieldName={this.state.newCommentFieldName}
            newCommentLineItemIndex={this.state.newCommentLineItemIndex}
            onNewCommentClose={() => {
              this.setState({
                hasNewComment: false,
                newCommentFieldName: null,
              });
            }}
            recordCommentActivityItem={this.recordCommentActivityItem}
          />
        )}
        {shouldDisplayPreview && (
          <div
            className={cx("pdf-preview-container", {
              enabled: this.state.isPreviewEnabled,
            })}
          >
            {this.displayLiveVersion({
              invoiceForDisplay,
              projectDetails,
              clientDetails,
            })}
          </div>
        )}
        {displayInsertAttachmentPickerModal.call(this)}
        {displayInsertAttachmentModal.call(this)}
        {displayReportUserListModal.call(this)}
        {this.state.isRequestReviewModalVisible && (
          <RequestInvoiceReviewModal
            invoice={invoice}
            users={users}
            clients={clients}
            apiUser={apiUser}
            onClose={() => this.setState({ isRequestReviewModalVisible: false }, this.refreshInvoice)}
            potentialReviewers={this.getPotentialReviewers()}
          />
        )}
        {this.state.isSendInvoiceModalVisible && (
          <SendInvoiceModal
            invoice={invoice}
            project={projectDetails}
            form={form}
            onClose={() => this.setState({ isSendInvoiceModalVisible: false })}
            setSendStatus={(sendStatus) => this.setState({ sendStatus })}
            changeInvoiceAttribute={this.changeAttribute}
            approvedPdfData={approvedPdfData}
          />
        )}
      </div>
    );
  }
}

export default withRouter(
  withSubscriptions({
    Component: InvoiceDetailsPage,
    subscriptions: [
      "invoice",
      "tasks",
      "projects",
      "clients",
      "invoices",
      "quotes",
      "timesheetBlocks",
      "users",
      "organisationDetails",
    ],
  })
);
