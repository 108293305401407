import { Checkbox } from "antd";

import { graphqlOperation } from "aws-amplify";
import { callGraphQL } from "common/helpers";
import { updateOrganisation } from "graphql/mutations";

import { MODULES } from "common/settings";
import { getGraphQLInput } from "OrganisationPage/OrganisationSettingsPage/settingsHelpers";

import FileTypes from "../FileTypes/FileTypes";
import Card from "Card/Card";
import "./Modules.scss";

export default function Modules(props) {
  const { organisationDetails } = props;

  function displayModules() {
    const checked = MODULES.filter((module) => {
      let checked = false;
      if (!module.SCHEMA_FIELD.includes(".")) {
        checked = organisationDetails[module.SCHEMA_FIELD];
        return checked;
      } else {
        const schemaFieldParts = module.SCHEMA_FIELD.split(".");
        let currentValue = organisationDetails;
        schemaFieldParts.forEach((fieldName) => {
          currentValue = currentValue && currentValue[fieldName];
        });
        return !!currentValue;
      }
    });
    const checkedSettings = checked.map((x) => x.SCHEMA_FIELD);

    return (
      <div className="modules">
        <Checkbox.Group defaultValue={checkedSettings}>
          {[...MODULES]
            .sort((a, b) => (a.NAME.toLowerCase() < b.NAME.toLowerCase() ? -1 : 1))
            .map((module) => {
              const { NAME, DESCRIPTION, SCHEMA_FIELD } = module;

              return (
                <Checkbox
                  className="setting-checkbox"
                  value={SCHEMA_FIELD}
                  key={SCHEMA_FIELD}
                  onChange={async (e) => {
                    const input = getGraphQLInput({
                      organisationDetails: { settings: organisationDetails.settings || {} },
                      checked: e.target.checked,
                      module,
                    });
                    await callGraphQL(
                      "Could not update module",
                      graphqlOperation(updateOrganisation, {
                        input: {
                          id: organisationDetails.id,
                          ...input,
                        },
                      })
                    );
                  }}
                >
                  <span className="setting-name">{NAME}</span>
                  <br />
                  <span className="setting-description">{DESCRIPTION}</span>
                </Checkbox>
              );
            })}
        </Checkbox.Group>
      </div>
    );
  }

  return (
    <>
      {organisationDetails.usesFiles && <FileTypes {...props} />}
      <Card withSpace title="Modules">
        {displayModules()}
      </Card>
    </>
  );
}
