import moment from "moment";
import { useState } from "react";
import { Wrapper } from "@googlemaps/react-wrapper";
import { EyeOutlined } from "@ant-design/icons";
import { Button, Typography, Modal } from "antd";
import { Link } from "react-router-dom";
import { Map, Marker, render } from "Map/Map";

import { FILE_TYPES_READABLE, REVIEW_SECONDARY_STATUS_READABLE, REVIEW_STATUS_READABLE } from "common/constants";

import Card from "Card/Card";
import TaskIdTag from "TaskIdTag/TaskIdTag";

import "./DailyLogItem.scss";

export default function DailyLogItem({ auditItem, tasks, projects, clients }) {
  const [apiKey, setApiKey] = useState();
  const [isLocationModalVisible, setIsLocationModalVisible] = useState(false);
  const [zoom, setZoom] = useState(18);
  const { type, content, page, taskId, projectId, fileType, fileName, fileId, clientId } = auditItem;

  if (type === "OPEN_FILE_WITH_LINK") {
    return null;
  }

  let task = taskId !== "nothing" && tasks.find((x) => x.id === taskId);
  let project = projectId !== "nothing" && projects.find((x) => x.id === projectId);

  let projectTitle = project && project.title;
  let taskTitle = task && task.title;
  if (!taskTitle || taskTitle.toLowerCase().includes("_hidden_")) {
    taskTitle = null;
  }
  let title = `${type[0]}${type.toLowerCase().substring(1).split("_").join(" ")}`;
  let subtitle = "";
  let time = moment(auditItem.createdAt).format("HH:mm");
  let taskIdTag = null;

  if (auditItem.taskId && auditItem.taskId !== "nothing" && !auditItem.taskId.includes("-ADMIN")) {
    taskIdTag = <TaskIdTag task={tasks.find((x) => x.id === auditItem.taskId)} includeTitle={false} />;
  }

  let projectIdTag = null;
  let clientDetails = null;
  if (auditItem.clientId && auditItem.clientId !== "nothing") {
    clientDetails = clients.find((x) => x.id === auditItem.clientId);
  }

  let badge = taskIdTag || projectIdTag;
  let linkPath = "";

  switch (type) {
    case "PAGE_VIEW":
      title += ` - ${page[0]}${page.toLowerCase().substring(1).split("_").join(" ")}`;
      switch (page) {
        case "USER_TIMESHEET":
          linkPath = `/user-timesheet`;
          break;
        case "TIMELINE":
          linkPath = `/timeline`;
          break;
        case "TASK_DETAILS":
          linkPath = `/tasks/${taskId}`;
          break;
        case "USERS":
          linkPath = `/users`;
          break;
        case "QUOTES":
          linkPath = `/quotes`;
          break;
        case "QUOTE_DETAILS":
          linkPath = `/quotes/${fileId}`;
          break;
        case "INVOICES":
          linkPath = `/invoices`;
          break;
        case "INVOICE_DETAILS":
          linkPath = `/invoices/${fileId}`;
          break;
        case "PURCHASE_ORDERS":
          linkPath = `/purchase-orders`;
          break;
        case "PURCHASE_ORDER_DETAILS":
          linkPath = `/purchase-orders/${fileId}`;
          break;
        case "PROJECTS":
          linkPath = `/projects`;
          break;
        case "PROJECT_DETAILS":
          linkPath = `/projects/${projectId}`;
          break;
        case "CLIENTS":
          linkPath = `/clients`;
          break;
        case "CLIENT":
          linkPath = `/clients/${clientId}`;
          break;
        case "DOCUMENT_LIBRARY":
          linkPath = `/document-library`;
          break;
        case "ASYNC_JOBS":
          linkPath = `/queue`;
          break;
        case "ACCOUNT":
          linkPath = `/account`;
          break;
      }
      break;
    case "OPEN_IN_APP":
      title = `Open file in ${FILE_TYPES_READABLE[fileType]}`;
      break;
    case "REVIEW_SUBMIT":
      title = `Submit review: ${REVIEW_STATUS_READABLE[content]?.label}`;
      break;
    case "NOTIFY_REVIEWER":
      title = `Notify reviewer: ${REVIEW_SECONDARY_STATUS_READABLE[content]}`;
      break;
    case "LOCATION":
      let locationParsedContent;
      try {
        locationParsedContent = JSON.parse(content);
      } catch (e) {
        // nothing we can do;
      }
      if (!locationParsedContent) {
        break;
      }
      title = (
        <>
          <span>
            Location: {locationParsedContent.latitude}, {locationParsedContent.longitude}
          </span>
          <Button
            type="link"
            icon={<EyeOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              const googleMapsApiKey = window.secrets?.GOOGLE_MAPS;

              if (!googleMapsApiKey) {
                return;
              }

              setApiKey(googleMapsApiKey);
              setIsLocationModalVisible(true);
            }}
          ></Button>
        </>
      );
    default:
      break;
  }

  if (fileType) {
    subtitle += `${FILE_TYPES_READABLE[fileType]}`;
    if (fileName && !fileName.toLowerCase().includes("_hidden_")) {
      subtitle += ` - ${fileName}`;
    }
  }

  let locationParsedContent;
  try {
    locationParsedContent = JSON.parse(content);
  } catch (e) {
    // nothing
  }

  const contents = (
    <Card className="daily-log-item">
      <div className="time-container">
        <Typography.Text className="time">{time}</Typography.Text>
      </div>
      <div className="info-container">
        <div className="badge-container">{badge}</div>
        <Typography.Text className="title">{title}</Typography.Text>
        {clientDetails && <Typography.Text className="client-name-container">{clientDetails.name}</Typography.Text>}
        <Typography.Text className="project-and-task">
          {projectTitle} {taskTitle ? ` - ${taskTitle}` : ""}
        </Typography.Text>
        <Typography.Text className="subtitle">{subtitle}</Typography.Text>
      </div>
      {isLocationModalVisible && (
        <Modal
          title="Location coordinates"
          open
          onCancel={() => {
            setIsLocationModalVisible(false);
          }}
        >
          <Wrapper apiKey={apiKey} render={render}>
            <Map
              center={{
                lat: parseFloat(locationParsedContent.latitude),
                lng: parseFloat(locationParsedContent.longitude),
              }}
              zoom={zoom}
              style={{ flexGrow: "1", height: "100%" }}
              onIdle={() => {}}
              onClick={() => {}}
            >
              <Marker
                position={{
                  lat: parseFloat(locationParsedContent.latitude),
                  lng: parseFloat(locationParsedContent.longitude),
                }}
              />
            </Map>
          </Wrapper>
        </Modal>
      )}
    </Card>
  );

  if (linkPath) {
    return <Link to={linkPath}>{contents}</Link>;
  }

  return contents;
}
