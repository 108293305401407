import React from "react";
import axios from "axios";
import moment from "moment";
import { Storage, Typography } from "aws-amplify";
import _ from "lodash";
import { withRouter } from "react-router-dom";
import { Drawer, Space, Form, Input, Modal, Spin, Button, message, notification } from "antd";
import { EyeOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";

import { callGraphQLSimple } from "common/apiHelpers";
import { getLatestFileVersion, getTemplateFromOrganisation } from "common/shared";
import { replaceDynamicFormFields } from "common/sharedTemplateRenderHelpers";
import getS3File from "common/getS3File";
import { displayFields, computeHiddenFormFields } from "ReportPage/Report/reportHelpers";

import FormTemplateActions from "FormTemplateActions/FormTemplateActions";
import ApplicationPageOutput from "./ApplicationPageOutput/ApplicationPageOutput";

import "./CustomApplicationPage.scss";

export class CustomApplicationPage extends React.Component {
  _isMounted = false;
  isAwaitingForReportPublish = false;
  publishReportModal = null;

  state = {
    userHasChangedSomething: false, // this is used to prevent the report saving straight after page load
    selectedField: null,
    areFieldsLoaded: false,
    dataUri: null,
    form: null,
    formPreview: null,
    fieldUnderEditName: null,
    fieldUnderEditSelectionStart: null,
    subFieldUnderEditIndex: null,
    fieldUnderEditValue: "",
    isInsertAttachmentsModalOpen: false,
    isAttachmentPickerOpen: false,
    isReportUserListModalOpen: false,
    savedAt: moment(),
    entirePdfName: null,
    isSaving: false,
    isSaveError: false,
    userIsCat2Checker: false,
    entireReportPdfVersions: null,
    isVersionsModalVisible: false,
    isSpecificPagesModalVisible: false,
    isPreviewOn: true,
    calculationsFile: null,
    reportReferenceCallback: null,
    taskRevision: null,
    isDownloading: false,
    isModalWithFieldsVisible: false,
    isDownloadButtonDisabled: false,
    isRawCloudFilesModalVisible: false,
    modalWithFieldsName: null,
    isHardcodedTemplate: false, // if the template is hardcoded, it means it wasn't created with the template editor
    templateDetails: null,
    numberForPreviewRefresh: 0,
    isLoading: true,
    isEditPageNameModalVisible: false,
    isFormVisible: false,
    hiddenFormFields: {},
  };

  constructor() {
    super();

    this.debouncedInnerSaveUserFields = _.debounce(this.saveUserFields, 1000);

    this.debouncedSaveUserFields = () => {
      computeHiddenFormFields.call(this);
      this.debouncedInnerSaveUserFields();
    };
  }

  componentDidMount() {
    this.initialise();
  }

  componentWillUnmount() {
    this._isMounted = false;
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(true);
    }
  }

  initialise = async () => {
    if (this.props.showPreloader) {
      this.props.showPreloader();
    }
    this._isMounted = true;
    if (this.props.setBoxedLayout) {
      this.props.setBoxedLayout(false);
    }

    // const { task, tasks, apiUser, file, withoutTask } = this.props;

    const entirePdfName = "";

    const projectFolder = "";

    const templateDetails = getTemplateFromOrganisation({
      organisationDetails: this.props.organisationDetails,
      templateId: this.props.file.templateId,
      fileType: "REPORT",
    });

    this.setState({
      projectFolder,
      entirePdfName,
      templateDetails,
    });

    this.loadFormFile();
  };

  loadFormFile = async (params) => {
    const { taskRevision } = params || {};
    const formFileKey = await this.getFormFileKey();
    const formFilePublicUrl = await getS3File(formFileKey);
    let form = (await axios.get(formFilePublicUrl)).data;

    const { file, task, users, organisationDetails } = this.props;

    await replaceDynamicFormFields({
      form,
      params: {
        file,
        task,
        taskRevision,
        project: task?.project,
        users,
        organisationDetails,
      },
    });

    this.setState(
      {
        form,
        formPreview: form,
      },
      async () => {
        await computeHiddenFormFields.call(this);
        this.setState({
          isLoading: false,
        });
      }
    );
  };

  getFormFileKey = () => {
    return getLatestFileVersion(this.props.file).key.replace("public/", "");
  };

  saveUserFields = async () => {
    const { form } = this.state;

    const { file } = this.props;
    const formFileKey = await this.getFormFileKey();
    const fileVersion = getLatestFileVersion(file);
    this.setState({
      isSaving: true,
      isSaveError: false,
      userHasChangedSomething: true,
    });
    try {
      await Storage.put(formFileKey, JSON.stringify(form));
      await callGraphQLSimple({
        message: "Failed to update report savedAt date",
        queryCustom: "updateFileVersion",
        variables: {
          input: {
            id: fileVersion.id,
            savedAt: moment().toISOString(),
          },
        },
      });

      this.setState({
        formPreview: form,
        savedAt: moment(),
        isSaving: false,
      });
    } catch (e) {
      notification.error({
        message: (
          <Typography.Text>
            Failed to update form:
            <br />
            {e.message}
          </Typography.Text>
        ),
        duration: 0,
      });
      this.setState({ isSaving: false, isSaveError: true });
    }
  };

  onDeleteClick = () => {
    console.log("onDeleteClick()");
    Modal.confirm({
      title: "Are you sure you want to delete this page?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: this.deletePage,
    });
  };

  deletePage = async () => {
    await callGraphQLSimple({
      queryCustom: "deleteFile",
      variables: {
        input: {
          id: this.props.file.id,
        },
      },
    });
    const { urlToRedirectToAfterDelete, history } = this.props;
    if (urlToRedirectToAfterDelete) {
      history.push(this.props.urlToRedirectToAfterDelete);
      window.location.reload();
    }
  };

  updatePageName = async ({ name }) => {
    this.setState({ isEditPageNameModalVisible: false });
    const messageKey = "updatePageName";
    try {
      message.loading({
        content: "Updating page name...",
        key: messageKey,
        duration: 0,
      });
      await callGraphQLSimple({
        queryCustom: "updateFile",
        variables: {
          input: {
            id: this.props.file.id,
            name,
          },
        },
      });
      message.success({
        content: "Page name updated",
        key: messageKey,
      });
      window.location.reload();
    } catch (e) {
      message.error({
        content: "Could not update page name",
        key: messageKey,
      });
    }
  };

  displayEditPageNameModal = () => {
    const { file } = this.props;
    const { isEditPageNameModalVisible } = this.state;

    if (!isEditPageNameModalVisible) {
      return null;
    }

    return (
      <Modal
        title="Edit dashboard name"
        open={true}
        footer={null}
        onOk={this.updatePageName}
        onCancel={() => {
          this.setState({ isEditPageNameModalVisible: false });
        }}
      >
        <Form layout="vertical" initialValues={{ name: file.name }} onFinish={this.updatePageName}>
          <Space direction="vertical">
            <Form.Item
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "You must choose a name",
                },
              ]}
            >
              <Input autoComplete="off" />
            </Form.Item>

            <div className="submit-container">
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </div>
          </Space>
        </Form>
      </Modal>
    );
  };

  render() {
    const { organisationDetails } = this.props;
    const { isLoading, templateDetails, formPreview, isFormVisible } = this.state;

    if (isLoading) {
      return (
        <div style={{ display: "flex", justifyContent: "center", marginTop: "2rem" }}>
          <Spin />
        </div>
      );
    }

    return (
      <div className="custom-application-page">
        <div className="page-actions">
          <Button icon={<DeleteOutlined />} onClick={this.onDeleteClick}>
            Delete dashboard
          </Button>

          <Button icon={<EditOutlined />} onClick={() => this.setState({ isEditPageNameModalVisible: true })}>
            Change dashboard name
          </Button>
          <FormTemplateActions
            templateDetails={templateDetails}
            form={this.state.form}
            formFileKey={this.getFormFileKey()}
            apiUser={this.props.apiUser}
          />
          <Button icon={<EyeOutlined />} onClick={() => this.setState({ isFormVisible: true })}>
            Show form
          </Button>
        </div>
        <ApplicationPageOutput
          organisationDetails={organisationDetails}
          templateDetails={templateDetails}
          previewData={formPreview}
        />
        {this.displayEditPageNameModal()}
        <Drawer
          placement="right"
          open={isFormVisible}
          onClose={() => this.setState({ isFormVisible: false })}
          title="Dashboard parameters"
        >
          {displayFields.call(this, { showHiddenByModal: false })}
        </Drawer>
      </div>
    );
  }
}

export default withRouter(CustomApplicationPage);
