const https = require("https");
const moment = require("moment");
// @ts-check

const DEFAULT_SLACK_WEBHOOK_URL = "https://hooks.slack.com/services/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";

export default async function log({ message, method, eventId, level, details, error, slackWebhookUrl }) {
  if (global.isBrowser) {
    return;
  } else {
    if (process.LOG_LEVEL === "none") {
      return;
    }
  }

  eventId = eventId || process.env.LOG_EVENT_ID;

  let logObject = {};

  if (method) {
    logObject.method = method;
  }

  logObject.message = message;

  if (details) {
    logObject.details = details;
  }

  if (eventId) {
    logObject.eventId = eventId;
  }

  if (level) {
    logObject.level = level;
  }

  if (error) {
    if (error instanceof Error || error.stack || error.message) {
      logObject.error = {
        message: error.message,
        stack: error.stack,
      };
    } else {
      logObject.error = JSON.stringify(error, null, 2);
    }
  }

  logObject.id = `${Date.now()}-${Math.floor(Math.random() * 100000)}`;

  if (level === "ERROR") {
    // if (process.env.ENV === "live") {
    try {
      const slackMessageBody = getSlackMessageBody(logObject);
      // console.log("logObject =", JSON.stringify(logObject, null, 2));
      await sendSlackMessage(slackMessageBody, slackWebhookUrl);
    } catch (e) {
      console.log("Error when trying to send to slack:", e.message, e.stack);
      // nothing we can do, it just means we cannot report it to Slack
    }
    // }
  }

  try {
    if (level === "ERROR") {
      console.error(JSON.stringify(logObject));
    } else {
      console.log(JSON.stringify(logObject, null, 2));
    }
  } catch (err) {
    // This is needed so we can avoid crashing the Lambda functions with the "Converting circular structure to JSON" error
    console.log("logObject = ", logObject);
    console.log("err = ", err);
  }
}

function getSlackMessageBody({ eventId, error, message }) {
  const startTimestamp = moment().subtract(3, "hour").valueOf();
  const endTimestamp = moment().add(1, "hour").valueOf();

  return {
    blocks: [
      {
        type: "divider",
      },
      {
        type: "header",
        text: {
          type: "plain_text",
          text: `${process.env.AWS_LAMBDA_FUNCTION_NAME}: ${message}`,
          emoji: true,
        },
      },
      {
        type: "section",
        text: {
          type: "mrkdwn",
          text: `*EventId* ${eventId}`,
        },
        accessory: {
          type: "button",
          text: {
            type: "plain_text",
            text: "Go to CloudWatch",
            emoji: true,
          },
          style: "primary",
          url: `https://eu-west-2.console.aws.amazon.com/cloudwatch/home?region=eu-west-2#logsV2:log-groups/log-group/$252Faws$252Flambda$252F${process.env.AWS_LAMBDA_FUNCTION_NAME}/log-events$3FfilterPattern$3D$257B$2524.eventId$253D$2522${eventId}$2522$257D$26start$3D${startTimestamp}$26end$3D${endTimestamp}`,
        },
      },
      {
        type: "divider",
      },
      {
        type: "context",
        elements: [
          {
            type: "mrkdwn",
            text: `*Error message:*\n${error ? error.message : ""}\n\n*Call stack:*\n${
              error ? error.stack : Error().stack
            }\n\n`,
          },
        ],
      },
    ],
  };
}

// const webHookURL = process.env.SLACK_WEBHOOK_URL;
/**
 * Handles the actual sending request.
 * We're turning the https.request into a promise here for convenience
 * @param messageBody
 * @return {Promise}
 */
async function sendSlackMessage(messageBody, slackWebhookUrl) {
  let webhookURL = slackWebhookUrl || process.env.SLACK_WEBHOOK_URL;
  if (!webhookURL) {
    webhookURL = DEFAULT_SLACK_WEBHOOK_URL;
  }
  // make sure the incoming message body can be parsed into valid JSON
  messageBody = JSON.stringify(messageBody);
  // Promisify the https.request
  return new Promise((resolve, reject) => {
    // general request options, we defined that it's a POST request and content is JSON
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    };
    // actual request
    const req = https.request(webhookURL, requestOptions, (res) => {
      let response = "";
      res.on("data", (d) => {
        response += d;
      });
      // response finished, resolve the promise with data
      res.on("end", () => {
        resolve(response);
      });
    });
    // there was an error, reject the promise
    req.on("error", (e) => {
      reject(e);
    });
    // send our message body (was parsed to JSON beforehand)
    req.write(messageBody);
    req.end();
  });
}
