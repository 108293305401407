const quoteLineItemsFields = `
lineItems {
  items {
    id
    quoteId
    organisation
    title
    description
    quantity
    unitPrice
    discountPercent
    taxRate
    amount
    taxAmount
    order
    authorityLevel
    checkPrice
    resultingTaskId
    resultingPurchaseOrderId
    isHourly
    sector
    geographicalLocation
    isHourlyFullyInvoiced
    manuallyInvoicedAmount
    isManuallyFullyInvoiced
    isRejected
    progressPercent
    customFields {
      id
      value
    }
    invoiceLineItems {
      items {
        id
        invoiceId
        amount
        taxAmount
        unitPrice
        quantity
        order
        discountPercent
        title
        description
        customFields {
          id
          value
        }
      }
    }
    createdAt
    updatedAt
  }
  nextToken
}
`;

const getFileVersionFields = `
id
versionNumber
customId
fileId
key
organisation
pageCount
processingSource
processingDestination
processingStatus
processingOperation
publishStartAt
publishEndAt
publishError
savedAt
exports {
  extension
  key
  rawKey
  fileName
}
externalReferences {
  id
  fileVersionId
  taskId
  taskRevisionId
  taskRevisionName
  fileId
  versionNumber
  key
}
createdAt
updatedAt
`;

const getFileFields = `
id
taskRevisionId
name
organisation
isArchived
isHidden
type
isCorrupted
extension
itemSubscription
autoPublishDisabled
collectionSubscription
randomNumber
skipAnnotation
hasCustomFile
createdAt
updatedAt
templateId
templateVersionNumber
constantId
isStartingFile
startingFileName
sheetsInApplication {
  name
  exportKey
}
reportMetadata {
  inserts {
    bucket
    key
    pageNumber
    hasBorders
    pagesToExclude
    pagesToInclude
    deletePage
  }
  assets {
    bucket
    name
    key
    width
    height
    scale
  }
  pageNumbersToSkipBorders
  pageMapping {
    sourcePageGroupNumber
    sourcePageGroupName
    correspondingPreviewPageNumbers
  }
}
sheets {
  items {
    id
    constantId
    taskId
    fileId
    name
    description
    fileType
    createdAt
    updatedAt
    order
    autoGeneratedReferenceNumber
    customReferenceNumber
    includeInPublish
    excludeFromRegister
    revisions {
      items {
        id
        isArchived
        sheetId
        name
        description
        checkedBy
        reviewAcceptDate
        status
        author
        fileVersionId
        realCreatedAt
        createdAt
        updatedAt
        exports {
          extension
          key
          rawKey
          fileName
        }
      }
      nextToken
    }
  }
  nextToken
}
versions {
  items {
    ${getFileVersionFields}
  }
  nextToken
}
`;

const getTaskFields = `
id
itemSubscription
collectionSubscription
randomNumber
isHidden
isConfirmed
projectId
sprintId
isReadOnly
reviewStatus
reviewSecondaryStatus
amountSpent
amountBudget
hoursBudget
hoursSpent
team
requestIds
quoteIds
quoteLineItemIds
quoteLineItems {
  items {
    id
    quoteId
    title
    quantity
    amount
    order
    isHourly
    isHourlyFullyInvoiced
    manuallyInvoicedAmount
    isManuallyFullyInvoiced
    isRejected
    progressPercent
    createdAt
    customFields {
      id
      value
    }
  }
}
budget
checkPrice
estimatedHours
subtaskProgress
checkedBy
linkedTasks {
  id
  correspondingId
  taskId
  relationship
  label
}
subtasks {
  items {
    id
    parentId
    order
    assignedTo
    title
    description
    isFinished
    author
    dueDate
    organisation
  }
}
activity {
  items {
    id
    taskId
    author
    content
    type
    organisation
    createdAt
    updatedAt
  }
  nextToken
}

initials
catLevel
organisation
organisationDetails {
  hasAutoPublish
}
author
title
titleLowerCase
assignedTo
assignedToUsers
assignedToStockItems
subtitle
dueDate
startDate
endDate
isArchived
archivedAt
isFinished
finishedAt
isUnderReview
status
isExternalReference
customFields {
  id
  value
}
order
clientContact
clientContacts
clientAddress
clientId
client {
  id
  key
  name
  organisation
  initials
  isPriority
  amountBudget
  amountSpent
  amountInvoiced
  amountReceived
  amountOutstanding
  hoursBudget
  hoursSpent
  createdAt
  updatedAt
}
project {
  id
  organisation
  author
  title
  clientId
  assignedTo
  initials
  isArchived
  archivedAt
  taskCount
  quoteCount
  invoiceCount
  purchaseOrderCount
  amountBudget
  amountSpent
  amountInvoiced
  amountReceived
  amountOutstanding
  hoursBudget
  hoursSpent
  poNumber
  createdAt
  updatedAt
}
timesheetBlocks {
  items {
    id
    startAt
    endAt
    billable
    feeRole
    taskRevisionId
  }
}
revisions {
  items {
    id
    taskId
    name
    isReadOnly
    isArchived
    organisation
    base
    description
    author
    checkedBy
    status
    reviewId
    reviewAcceptDate
    reviewStatus
    reviewSecondaryStatus
    requestedDate
    priorityId
    dueDate
    estimatedHours
    createdAt
    updatedAt
    deletedFilesByType 
    deletedFilesByTemplate 
    readAccessGroups
    writeAccessGroups
    task {
      assignedTo
      assignedToUsers
      assignedToStockItems
    }
    files {
      items {
        id
        isHidden
        taskRevisionId
        name
        organisation
        isArchived
        type
        extension
        createdAt
        constantId
        templateId
        isStartingFile
        startingFileName
        versions {
          items {
            id
            versionNumber
            customId
            fileId
            key
            organisation
            processingSource
            processingDestination
            processingStatus
            processingOperation
            publishStartAt
            publishEndAt
            publishError
            savedAt
            exports {
              extension
              key
              rawKey
              fileName
            }
            createdAt
          }
        }
        sheets {
          items {
            id
            constantId
            includeInPublish
            name
            autoGeneratedReferenceNumber
            customReferenceNumber
            description
            excludeFromRegister
            revisions {
              items {
                id
                sheetId
                name
                description
                status
                author
                checkedBy
                reviewAcceptDate
                fileVersionId
                isArchived
                realCreatedAt
                createdAt
                updatedAt
              }
              nextToken
            }
          }
        }
      }
      nextToken
    }
    review {
      id
      createdAt
      updatedAt
    }
  }
  nextToken
}
createdAt
updatedAt
`;

// items {
//   versions {
//     items {
//       id
//       customId
//       key
//       pageCount
//       savedAt
//       createdAt
//       updatedAt
//     }
//   }
// }

const getTaskWithFilesFields = `
id
initials
organisation
title
assignedTo
author
requestIds
isArchived
isFinished
finishedAt
isReadOnly
projectId
project {
  id
  title
  description
  assignedTo
  initials
}
clientId
client {
  id
  name
  key
  initials
}
customFields {
  id
  value
}
revisions {
  items {
    id
    name
    base
    createdAt
    updatedAt
    author
    status
    reviewAcceptDate
    checkedBy
    dueDate
    requestedDate
    priorityId
    estimatedHours
    isReadOnly
    isArchived
    readAccessGroups
    writeAccessGroups
    files {
      items {
        id
        name
        type
        organisation
        createdAt
        updatedAt
        extension
        constantId
        templateId
        isStartingFile
        startingFileName
        taskRevisionId
        sheetsInApplication {
          name
          exportKey
        }
        sheets {
          items {
            id
            name
            fileId
            description
            autoGeneratedReferenceNumber
            customReferenceNumber
            revisions {
              items {
                id
                sheetId
                name
                description
                status
                author
                checkedBy
                reviewAcceptDate
                fileVersionId
                isArchived
                realCreatedAt
                createdAt
                exports {
                  extension
                  key
                  rawKey
                  fileName
                }
              }
            } 
            createdAt
            order
            constantId
          }
        }
        
        versions {
          items {
            id
            customId
            key
            pageCount
            savedAt
            publishStartAt
            publishEndAt
            publishError
            createdAt
            updatedAt
            exports {
              key
              rawKey
              fileName
            }
          }
        }
      }
    }
  }
}`;

const getTaskWithFilesWithoutSheetsFields = `
id
initials
organisation
title
assignedTo
requestIds
isArchived
isFinished
finishedAt
isReadOnly
customFields {
  id
  value
}
project {
  id
  title
  description
  assignedTo
  initials
}
client {
  id
  name
  key
  initials
}

revisions {
  items {
    id
    name
    base
    createdAt
    updatedAt
    author
    status
    reviewAcceptDate
    checkedBy
    dueDate
    requestedDate
    priorityId
    estimatedHours
    isReadOnly
    isArchived
    readAccessGroups
    writeAccessGroups
    files {
      items {
        id
        name
        type
        organisation
        createdAt
        updatedAt
        extension
        constantId
        templateId
        isStartingFile
        startingFileName
        taskRevisionId
        
        versions {
          items {
            id
            customId
            key
            pageCount
            savedAt
            publishStartAt
            publishEndAt
            publishError
            createdAt
            updatedAt
            exports {
              key
              rawKey
              fileName
            }
          }
        }
      }
    }
  }
}`;

const getTaskWithFilesAndQuoteLineItemsFields = `
id
initials
organisation
title
assignedTo
requestIds
isArchived
isFinished
finishedAt
isReadOnly
projectId
clientId
customFields {
  id
  value
}
quoteLineItems {
  items {
    id
    quoteId
    title
    quantity
    amount
    order
    isHourly
    isHourlyFullyInvoiced
    manuallyInvoicedAmount
    isManuallyFullyInvoiced
    isRejected
    progressPercent
    createdAt
    customFields {
      id
      value
    }
  }
}
revisions {
  items {
    id
    name
    base
    createdAt
    updatedAt
    author
    status
    reviewAcceptDate
    checkedBy
    dueDate
    requestedDate
    priorityId
    estimatedHours
    isReadOnly
    isArchived
    readAccessGroups
    writeAccessGroups
    files {
      items {
        id
        name
        type
        organisation
        createdAt
        updatedAt
        extension
        constantId
        templateId
        isStartingFile
        startingFileName
        taskRevisionId
        sheetsInApplication {
          name
          exportKey
        }
      }
    }
  }
}`;

const getTaskWithFinancialsFields = `
id
title
quoteLineItems {
  items {
    id
    quantity
    amount
    order
    isHourlyFullyInvoiced
    isRejected
    manuallyInvoicedAmount
    isManuallyFullyInvoiced
    progressPercent
    createdAt
    customFields {
      id
      value
    }
  }
}
timesheetBlocks {
  items {
    id
    startAt
    endAt
    feeRole
    taskRevisionId
  }
}
`;

const listTaskMinimalFields = `
  id
  isHidden
  isConfirmed
  projectId
  sprintId
  isReadOnly
  initials
  catLevel
  organisation
  estimatedHours
  author
  title
  assignedTo
  dueDate
  isArchived
  archivedAt
  isFinished
  finishedAt
  isUnderReview
  status 
  isExternalReference
  order
  reviewStatus
  reviewSecondaryStatus
  subtaskProgress
  checkedBy
  amountBudget
  amountSpent
  hoursBudget
  hoursSpent
  requestIds
  linkedTasks {
    id
    correspondingId
    taskId
    relationship
    label
  }
  customFields {
    id
    value
  }
  clientId
  team
  createdAt
  updatedAt
`;

const listTaskFields = `
  id
  isHidden
  isConfirmed
  projectId
  sprintId
  isReadOnly
  initials
  randomNumber
  collectionSubscription
  catLevel
  organisation
  estimatedHours
  author
  title
  titleLowerCase
  assignedTo
  assignedToUsers
  assignedToStockItems
  subtitle
  dueDate
  startDate
  endDate
  isArchived
  archivedAt
  isFinished
  finishedAt
  isUnderReview
  status 
  isExternalReference
  order
  reviewStatus
  reviewSecondaryStatus
  subtaskProgress
  checkedBy
  amountBudget
  amountSpent
  hoursBudget
  hoursSpent
  requestIds
  linkedTasks {
    id
    correspondingId
    taskId
    relationship
    label
  }
  customFields {
    id
    value
  }
  clientContact
  clientContacts
  clientAddress
  clientId
  team
  createdAt
  updatedAt
`;

const listTaskFieldsWithRevisions = `
  ${listTaskFields}
  client {
    id
    key
    name
    initials
    isPriority
  }
  revisions {
    items {
      id
      taskId
      name
      isReadOnly
      isArchived
      organisation
      base
      description
      author
      status
      checkedBy
      reviewId
      reviewAcceptDate
      reviewStatus
      reviewSecondaryStatus
      dueDate
      requestedDate
      priorityId
      estimatedHours
      readAccessGroups
      writeAccessGroups
      createdAt
      updatedAt
    }
  }
`;

const getProjectFields = `
  id
  organisation
  randomNumber
  itemSubscription
  description
  clientContacts
  isFinished
  finishedAt
  collectionSubscription
  author
  title
  clientId
  sharedWithGroups
  sharedWithUsers
  activity {
    items {
      id
      parentId
      author
      content
      type
      organisation
      createdAt
      updatedAt
    }
    nextToken
  }
  assignedTo
  initials
  taskCount
  quoteCount
  invoiceCount
  purchaseOrderCount
  createdAt
  updatedAt
  isArchived
  archivedAt
  poNumber
  team
  client {
    id
    key
    name
    organisation
    initials
    isPriority
    createdAt
    updatedAt
  }
  adminFiles {
    id
    name
    taskId
  }
  address {
    id
    streetName
    streetNumber
    houseName
    postcode
    county
    city
    country
  }
  customFields {
    id
    value
  }
  quotes {
    items {
      id
      organisation
      isArchived
      assignedTo
      author
      clientId
      projectId
      fileKey
      validFrom
      validUntil
      reference
      title
      description
      currency
      taxInclusive
      subtotal
      totalTax
      total
      createdAt
      status
      updatedAt
      clientContact
      clientContacts
      clientAddress
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
      manuallyInvoicedAmount
      
      ${quoteLineItemsFields}
    }
  }
  invoices {
    items {
      id
      organisation
      isArchived
      dueDate
      assignedTo
      author
      clientId
      projectId
      fileKey
      reference
      title
      description
      currency
      taxInclusive
      subtotal
      totalTax
      total
      createdAt
      status
      updatedAt
      clientContact
      clientContacts
      clientAddress
      poNumber
      checkedBy
      isUnderReview
      reviewApprovedAt
      reviewStatus
      reviewSecondaryStatus
    }
  }
  addressCoordinates {
    lat
    lng
  }
  amountBudget
  amountSpent
  amountInvoiced
  amountReceived
  amountOutstanding
  hoursBudget
  hoursSpent
`;

const listProjectFields = `
  id
  organisation
  randomNumber
  itemSubscription
  taskCount
  quoteCount
  invoiceCount
  purchaseOrderCount
  description
  isFinished
  finishedAt
  collectionSubscription
  author
  title
  clientId
  assignedTo
  initials
  createdAt
  updatedAt
  isArchived
  archivedAt
  poNumber
  team
  amountBudget
  amountSpent
  amountInvoiced
  amountReceived
  amountOutstanding
  hoursBudget
  hoursSpent
  sharedWithGroups
  sharedWithUsers
  customFields {
    id
    value
  }
`;

const getReviewFields = `
id
organisation
reviewThread {
  id
  type
  createdAt
  sheetId
  sheetLabel
  content
  author
  resolved
  resolvedBy
  resolvedAt
  edited
  reviewThread {
    id
    type
    createdAt
    sheetId
    sheetLabel
    content
    author
    resolved
    resolvedBy
    resolvedAt
    edited
    reviewThread {
      id
      type
      createdAt
      sheetId
      sheetLabel
      content
      author
      resolved
      resolvedBy
      resolvedAt
      edited
      reviewThread {
        id
        type
        createdAt
        sheetId
        sheetLabel
        content
        author
        resolved
        resolvedBy
        resolvedAt
        edited
      }
    }
  }
}
approvedItems {
  id
  approvedAt
}
createdAt
updatedAt
`;

const listQuoteFields = `
id
organisation
currentRevisionName
isArchived
assignedTo
clientId
projectId
title
subtotal
total
status
isUnderReview
reviewApprovedAt
reviewStatus
reviewSecondaryStatus
manuallyInvoicedAmount
defaultFees {
  id
  label
  value
  type
  defaultPercentage
  defaultPlus
  overrideFeeId
}
team
`;

const listQuoteFieldsWithLineItems = `
id
organisation
isArchived
assignedTo
author
clientId
projectId
fileKey
validFrom
validUntil
reference
title
description
currency
taxInclusive
subtotal
totalTax
total
createdAt
termsText
termsAttachment
status
updatedAt
clientContact
clientContacts
clientAddress
poNumber
checkedBy
isUnderReview
reviewApprovedAt
reviewStatus
reviewSecondaryStatus
manuallyInvoicedAmount
team
defaultFees {
  id
  label
  value
  type
  defaultPercentage
  defaultPlus
  overrideFeeId
}

lineItems {
  items {
    id
    quoteId
    organisation
    title
    description
    quantity
    unitPrice
    discountPercent
    taxRate
    amount
    taxAmount
    order
    authorityLevel
    checkPrice
    resultingTaskId
    resultingPurchaseOrderId
    isHourly
    sector
    geographicalLocation
    isHourlyFullyInvoiced
    manuallyInvoicedAmount
    isManuallyFullyInvoiced
    isRejected
    progressPercent
    customFields {
      id
      value
    }
    invoiceLineItems {
      items {
        id
        invoiceId
        amount
        taxAmount
        unitPrice
        quantity
        order
        discountPercent
        title
        description
        customFields {
          id
          value
        }
      }
    }
    createdAt
    updatedAt
  }
  nextToken
}
`;

const getQuoteFields = `
id
organisation
currentRevisionName
isArchived
assignedTo
author
itemSubscription
collectionSubscription
clientId
projectId
fileKey
validFrom
validUntil
reference
title
description
currency
team
defaultFees {
  id
  label
  value
  type
  defaultPercentage
  defaultPlus
  overrideFeeId
}
taxInclusive
subtotal
totalTax
total
createdAt
termsText
termsAttachment
status
updatedAt
clientContact
clientContacts
clientAddress
templateId
templateVersionNumber
taxRate
poNumber
checkedBy
isUnderReview
reviewApprovedAt
reviewStatus
reviewSecondaryStatus
contactIdsToIncludeInCC
contactIdsToIncludeInBCC
extraEmailsToIncludeInCC {
  id
  email
} 
extraEmailsToIncludeInBCC {
  id
  email
}
manuallyInvoicedAmount
exports {
  extension
  key
  rawKey
  fileName
  latestS3VersionId
}
reviews {
  items {
    ${getReviewFields}
  }
}
rejectionDetails {
  type
  notes
} 
metadata {
  inserts {
    key
    pageNumber
    hasBorders
    pagesToExclude
    pagesToInclude
    deletePage
    bucket
  }
  assets {
    bucket
    name
    key
    width
    height
    scale
  }
  pageMapping {
    sourcePageGroupNumber
    sourcePageGroupName
    correspondingPreviewPageNumbers
  }
  pageNumbersToSkipBorders
}
client {
  id
  key
  name
  organisation
  initials
  isPriority
  createdAt
  updatedAt
}
project {
  id
  organisation
  author
  title
  clientId
  assignedTo
  initials
  taskCount
  quoteCount
  invoiceCount
  purchaseOrderCount
  createdAt
  isArchived
  archivedAt
  updatedAt
  poNumber
  amountBudget
  amountSpent
  hoursBudget
  hoursSpent
  quotes {
    items {
      id
    }
  }
}
activity {
  items {
    id
    quoteId
    total
    type
    organisation
    createdAt
    updatedAt
    author
    content
  }
  nextToken
}
${quoteLineItemsFields}
`;

const getMinimalInvoiceLineItemFields = `
id
invoiceId
organisation
quoteLineItemId
taskId
title
description
quantity
unitPrice
discountPercent
taxRate
createdAt
taxAmount
amount
order
customFields {
  id
  value
}
updatedAt
`;

const getInvoiceLineItemFields = `
id
invoiceId
taskId
order
quoteLineItemId
quoteLineItem {
  id
  quoteId
  title
  description
  quantity
  unitPrice
  discountPercent
  taxRate
  order
  amount
  taxAmount
  authorityLevel
  checkPrice
  resultingTaskId
  resultingPurchaseOrderId
  isHourly
  sector
  manuallyInvoicedAmount
  isManuallyFullyInvoiced
  geographicalLocation
  customFields {
    id
    value
  }
  createdAt
  updatedAt
}
organisation
title
description
quantity
unitPrice
discountPercent
taxRate
amount
taxAmount
createdAt
updatedAt
`;

const listInvoiceFields = `
id
organisation
isArchived
dueDate
assignedTo
invoiceDate
amountPaid
author
clientId
projectId
fileKey
reference
title
description
currency
taxInclusive
subtotal
totalTax
total
createdAt
termsText
termsAttachment
status
updatedAt
clientContact
clientContacts
clientAddress
poNumber
checkedBy
isUnderReview
reviewApprovedAt
secondReviewApprovedAt
reviewStatus
reviewSecondaryStatus
team
expectPaymentAfterDays
`;

const listInvoiceWithLineItemsFields = `
id
organisation
isArchived
dueDate
accountancyInvoiceId
assignedTo
invoiceDate
author
itemSubscription
collectionSubscription
clientId
projectId
fileKey
reference
title
description
currency
taxInclusive
subtotal
totalTax
total
createdAt
termsText
termsAttachment
status
updatedAt
clientContact
clientContacts
clientAddress
templateId
templateVersionNumber
taxRate
poNumber
amountPaid
checkedBy
isUnderReview
reviewApprovedAt
secondReviewApprovedAt
reviewStatus
reviewSecondaryStatus
team
contactIdsToIncludeInCC
contactIdsToIncludeInBCC
extraEmailsToIncludeInCC {
  id
  email
}
extraEmailsToIncludeInBCC {
  id
  email
}
lastUpdateAuthorId
exports {
  extension
  key
  rawKey
  fileName
  latestS3VersionId
}


lineItems {
  items {
    id
    invoiceId
    taskId
    order
    quoteLineItemId
    quoteLineItem {
      id
      quoteId
      title
      description
      quantity
      unitPrice
      discountPercent
      taxRate
      amount
      taxAmount
      order
      authorityLevel
      checkPrice
      resultingTaskId
      resultingPurchaseOrderId
      isHourly
      sector
      manuallyInvoicedAmount
      isManuallyFullyInvoiced
      geographicalLocation
      customFields {
        id
        value
      }
      createdAt
      updatedAt
    }
    organisation
    title
    description
    quantity
    unitPrice
    discountPercent
    taxRate
    amount
    taxAmount
    createdAt
    updatedAt
  }
  nextToken
}

`;

const listInvoicesForReportingFields = `
${listInvoiceFields}
client {
  id
  key
  name
  organisation
  initials
  isPriority
}
project {
  id
  organisation
  author
  title
  clientId
  assignedTo
  initials
}
lineItems {
  items {
    ${getInvoiceLineItemFields}
  }
  nextToken
}
`;

const getInvoiceFields = `
${listInvoiceFields}
accountancyInvoiceId
itemSubscription
collectionSubscription
templateId
templateVersionNumber
taxRate
contactIdsToIncludeInCC
contactIdsToIncludeInBCC
extraEmailsToIncludeInCC {
  id
  email
}
extraEmailsToIncludeInBCC {
  id
  email
}
lastUpdateAuthorId
exports {
  extension
  key
  rawKey
  fileName
  latestS3VersionId
}
reviews {
  items {
    ${getReviewFields}
  }
}
metadata {
  inserts {
    key
    pageNumber
    hasBorders
    pagesToExclude
    pagesToInclude
    deletePage
  }
  assets {
    bucket
    name
    key
    width
    height
    scale
  }
  pageMapping {
    sourcePageGroupNumber
    sourcePageGroupName
    correspondingPreviewPageNumbers
  }
  pageNumbersToSkipBorders
}
client {
  id
  key
  name
  organisation
  initials
  isPriority
  createdAt
  updatedAt
  fees {
    id
    label
    value
    type
    defaultPercentage
    defaultPlus
    overrideFeeId
  }
}
project {
  id
  organisation
  author
  title
  clientId
  assignedTo
  initials
  taskCount
  quoteCount
  invoiceCount
  purchaseOrderCount
  createdAt
  amountBudget
  amountSpent
  hoursBudget
  hoursSpent
  isArchived
  archivedAt
  updatedAt
  poNumber
}
activity {
  items {
    id
    invoiceId
    total
    type
    organisation
    createdAt
    updatedAt
    author
    content
  }
  nextToken
}
timesheetBlocks {
  items {
    id
    startAt
    endAt
    description
    userId
    quoteLineItemId
    taskRevisionId
    invoiceId
    feeRole
    taskId
    projectId
    clientId
    billable
    variation
    tags
  }
}
lineItems {
  items {
    ${getInvoiceLineItemFields}
  }
  nextToken
}
`;

export const listProjectsSimple = /* GraphQL */ `
  query ListProjectsByOrganisation(
    $organisation: ID
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrganisation(
      organisation: $organisation
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listProjectFields}
      }
      nextToken
    }
  }
`;

export const listProjects = /* GraphQL */ `
  query ListProjectsByOrganisation(
    $organisation: ID
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectsByOrganisation(
      organisation: $organisation
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listProjectFields}
        client {
          id
          key
          name 
          isPriority
        }
      }
      nextToken
    }
  }
`;

export const getProjectSimple = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      ${getProjectFields}
    }
  }
`;

export const getProjectMinimal = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      ${listProjectFields}
    }
  }
`;

export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      ${getProjectFields}
    }
  }
`;

export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      ${getProjectFields}
    }
  }
`;

export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      ${getProjectFields}
    }
  }
`;

export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject($organisation: String) {
    onUpdateProject(organisation: $organisation) {
      ${getProjectFields}
    }
  }
`;

export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject($organisation: String) {
    onDeleteProject(organisation: $organisation) {
      ${getProjectFields}
    }
  }
`;

export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject($organisation: String) {
    onCreateProject(organisation: $organisation) {
      ${getProjectFields}
    }
  }
`;

export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      ${getFileFields}
    }
  }
`;

export const createFile = /* GraphQL */ `
  mutation CreateFile(
    $input: CreateFileInput!
    $condition: ModelFileConditionInput
  ) {
    createFile(input: $input, condition: $condition) {
      ${getFileFields}
    }
  }
`;

export const updateFile = /* GraphQL */ `
  mutation UpdateFile(
    $input: UpdateFileInput!
    $condition: ModelFileConditionInput
  ) {
    updateFile(input: $input, condition: $condition) {
      ${getFileFields}
    }
  }
`;

export const deleteFile = /* GraphQL */ `
  mutation DeleteFile(
    $input: DeleteFileInput!
    $condition: ModelFileConditionInput
  ) {
    deleteFile(input: $input, condition: $condition) {
      ${getFileFields}
    }
  }
`;

export const onCreateFile = /* GraphQL */ `
  subscription OnCreateFile($organisation: String) {
    onCreateFile(organisation: $organisation) {
      ${getFileFields}
    }
  }
`;

export const onUpdateFile = /* GraphQL */ `
  subscription OnUpdateFile($organisation: String) {
    onUpdateFile(organisation: $organisation) {
      ${getFileFields}
    }
  }
`;

export const onDeleteFile = /* GraphQL */ `
  subscription OnDeleteFile($organisation: String) {
    onDeleteFile(organisation: $organisation) {
      ${getFileFields}
    }
  }
`;

export const getTaskSimple = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${getTaskFields}
    }
  }
`;

export const getTaskWithRevisions = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${listTaskFieldsWithRevisions}
    }
  }
`;

export const getTaskMinimal = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${listTaskFields}
    }
  }
`;

export const getTaskWithFiles = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${getTaskWithFilesFields}
    }
  }
`;

export const getTaskWithFilesAndQuoteLineItems = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${getTaskWithFilesAndQuoteLineItemsFields}
    }
  }
`;

export const getTaskWithFilesWithoutSheets = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${getTaskWithFilesWithoutSheetsFields}
    }
  }
`;

export const getTaskWithFinancials = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      ${getTaskWithFinancialsFields}
    }
  }
`;

export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      ${getTaskFields}
    }
  }
`;

export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      ${getTaskFields}
    }
  }
`;

export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      ${getTaskFields}
    }
  }
`;

export const onUpdateTask = /* GraphQL */ `
  subscription OnUpdateTask($organisation: String) {
    onUpdateTask(organisation: $organisation) {
      ${getTaskFields}
    }
  }
`;

export const onDeleteTask = /* GraphQL */ `
  subscription OnDeleteTask($organisation: String) {
    onDeleteTask(organisation: $organisation) {
      ${getTaskFields}
    }
  }
`;

export const onCreateTask = /* GraphQL */ `
  subscription OnCreateTask($organisation: String) {
    onCreateTask(organisation: $organisation) {
      ${getTaskFields}
    }
  }
`;

export const listTasksSimple = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listTaskFields}
      }
      nextToken
    }
  }
`;

export const listTasksMinimal = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listTaskMinimalFields}
      }
      nextToken
    }
  }
`;

export const listTasksWithRevisions = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listTaskFieldsWithRevisions}
      }
      nextToken
    }
  }
`;

export const listTasksWithFiles = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${getTaskWithFilesFields}
      }
      nextToken
    }
  }
`;

export const listTasks = /* GraphQL */ `
  query ListTasksByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listTaskFieldsWithRevisions}
      }
      nextToken
    }
  }
`;

export const listTasksByProject = /* GraphQL */ `
  query ListTasksByProject(
    $projectId: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByProject(
      projectId: $projectId
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${getTaskFields}
      }
      nextToken
    }
  }
`;

const getTaskRevisionFields = `
  id
  taskId
  name
  isReadOnly
  organisation
  isArchived
  base
  itemSubscription
  collectionSubscription
  randomNumber
  description
  author
  checkedBy
  status
  reviewId
  reviewAcceptDate
  reviewStatus
  reviewSecondaryStatus
  dueDate
  estimatedHours
  createdAt
  updatedAt
  readAccessGroups
  writeAccessGroups
  task {
    assignedTo
  }
  files {
    items {
      ${getFileFields}
    }
    nextToken
  }
  deletedFilesByType 
  deletedFilesByTemplate 
  review {
    ${getReviewFields}
  }
`;

export const getTaskRevision = /* GraphQL */ `
  query GetTaskRevision($id: ID!) {
    getTaskRevision(id: $id) {
      ${getTaskRevisionFields}
    }
  }
`;

export const createTaskRevision = /* GraphQL */ `
  mutation CreateTaskRevision(
    $input: CreateTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    createTaskRevision(input: $input, condition: $condition) {
      ${getTaskRevisionFields}
    }
  }
`;

export const updateTaskRevision = /* GraphQL */ `
  mutation UpdateTaskRevision(
    $input: UpdateTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    updateTaskRevision(input: $input, condition: $condition) {
      ${getTaskRevisionFields}
    }
  }
`;

export const deleteTaskRevision = /* GraphQL */ `
  mutation DeleteTaskRevision(
    $input: DeleteTaskRevisionInput!
    $condition: ModelTaskRevisionConditionInput
  ) {
    deleteTaskRevision(input: $input, condition: $condition) {
      ${getTaskRevisionFields}
    }
  }
`;

export const onCreateTaskRevision = /* GraphQL */ `
  subscription OnCreateTaskRevision($organisation: String) {
    onCreateTaskRevision(organisation: $organisation) {
      ${getTaskRevisionFields}
    }
  }
`;

export const onUpdateTaskRevision = /* GraphQL */ `
  subscription OnUpdateTaskRevision($organisation: String) {
    onUpdateTaskRevision(organisation: $organisation) {
      ${getTaskRevisionFields}
    }
  }
`;

export const onDeleteTaskRevision = /* GraphQL */ `
  subscription OnDeleteTaskRevision($organisation: String) {
    onDeleteTaskRevision(organisation: $organisation) {
      ${getTaskRevisionFields}
    }
  }
`;

export const getFileVersion = /* GraphQL */ `
  query GetFileVersion($id: ID!) {
    getFileVersion(id: $id) {
      ${getFileVersionFields}
    }
  }
`;

export const updateFileVersion = /* GraphQL */ `
  mutation UpdateFileVersion($input: UpdateFileVersionInput!, $condition: ModelFileVersionConditionInput) {
    updateFileVersion(input: $input, condition: $condition) {
      ${getFileVersionFields}
    }
  }
`;

export const createFileVersion = /* GraphQL */ `
  mutation CreateFileVersion(
    $input: CreateFileVersionInput!
    $condition: ModelFileVersionConditionInput
  ) {
    createFileVersion(input: $input, condition: $condition) {
      ${getFileVersionFields}
    }
  }
`;

export const deleteFileVersion = /* GraphQL */ `
  mutation DeleteFileVersion(
    $input: DeleteFileVersionInput!
    $condition: ModelFileVersionConditionInput
  ) {
    deleteFileVersion(input: $input, condition: $condition) {
      ${getFileVersionFields}
    }
  }
`;

const getSprintFields = `
  id
  organisation
  itemSubscription
  collectionSubscription
  randomNumber
  name
  startDate
  endDate
  isActive
  isFinished
  createdAt
  updatedAt
  finishedAt
  startedAt
`;

export const listSprints = /* GraphQL */ `
  query ListSprintsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSprintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSprintsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${getSprintFields}
      }
      nextToken
    }
  }
`;

export const createSprint = /* GraphQL */ `
  mutation CreateSprint(
    $input: CreateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    createSprint(input: $input, condition: $condition) {
      ${getSprintFields}
    }
  }
`;

export const updateSprint = /* GraphQL */ `
  mutation UpdateSprint(
    $input: UpdateSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    updateSprint(input: $input, condition: $condition) {
      ${getSprintFields}
    }
  }
`;

export const deleteSprint = /* GraphQL */ `
  mutation DeleteSprint(
    $input: DeleteSprintInput!
    $condition: ModelSprintConditionInput
  ) {
    deleteSprint(input: $input, condition: $condition) {
      ${getSprintFields}
    }
  }
`;

export const onCreateSprint = /* GraphQL */ `
  subscription OnCreateSprint($organisation: String) {
    onCreateSprint(organisation: $organisation) {
      ${getSprintFields}
    }
  }
`;

export const onUpdateSprint = /* GraphQL */ `
  subscription OnUpdateSprint($organisation: String) {
    onUpdateSprint(organisation: $organisation) {
      ${getSprintFields}
    }
  }
`;

export const onDeleteSprint = /* GraphQL */ `
  subscription OnDeleteSprint($organisation: String) {
    onDeleteSprint(organisation: $organisation) {
      ${getSprintFields}
    }
  }
`;

export const listReviews = /* GraphQL */ `
  query ListReviews($filter: ModelReviewFilterInput, $limit: Int, $nextToken: String) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        organisation
        createdAt
      }
      nextToken
    }
  }
`;

export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        isPublic
        organisation
        createdAt
        updatedAt
        tasks {
          items {
            ${listTaskFields}
          }
        }
      }
    }
  }
`;

export const createBoard = /* GraphQL */ `
  mutation CreateBoard(
    $input: CreateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    createBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const updateBoard = /* GraphQL */ `
  mutation UpdateBoard(
    $input: UpdateBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    updateBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const deleteBoard = /* GraphQL */ `
  mutation DeleteBoard(
    $input: DeleteBoardInput!
    $condition: ModelBoardConditionInput
  ) {
    deleteBoard(input: $input, condition: $condition) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const onCreateBoard = /* GraphQL */ `
  subscription OnCreateBoard($organisation: String) {
    onCreateBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const onUpdateBoard = /* GraphQL */ `
  subscription OnUpdateBoard($organisation: String) {
    onUpdateBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const onDeleteBoard = /* GraphQL */ `
  subscription OnDeleteBoard($organisation: String) {
    onDeleteBoard(organisation: $organisation) {
      id
      isPublic
      organisation
      createdAt
      updatedAt
      tasks {
        items {
          ${listTaskFields}
        }
      }
    }
  }
`;

export const listQuotesByOrganisation = /* GraphQL */ `
  query ListQuotesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listQuoteFields}
      }
      nextToken
    }
  }
`;

export const listQuotesWithLineItemsByOrganisation = /* GraphQL */ `
  query ListQuotesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelQuoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listQuotesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listQuoteFieldsWithLineItems}
      }
      nextToken
    }
  }
`;

export const getQuote = /* GraphQL */ `
  query GetQuote($id: ID!) {
    getQuote(id: $id) {
      ${getQuoteFields}
    }
  }
`;

export const createQuote = /* GraphQL */ `
  mutation CreateQuote(
    $input: CreateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    createQuote(input: $input, condition: $condition) {
      ${getQuoteFields}
    }
  }
`;

export const deleteQuote = /* GraphQL */ `
  mutation DeleteQuote(
    $input: DeleteQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    deleteQuote(input: $input, condition: $condition) {
      ${getQuoteFields}
    }
  }
`;

export const updateQuote = /* GraphQL */ `
  mutation UpdateQuote(
    $input: UpdateQuoteInput!
    $condition: ModelQuoteConditionInput
  ) {
    updateQuote(input: $input, condition: $condition) {
      ${getQuoteFields}
    }
  }
`;

export const onCreateQuote = /* GraphQL */ `
  subscription OnCreateQuote($organisation: String) {
    onCreateQuote(organisation: $organisation) {
      ${getQuoteFields}
    }
  }
`;

export const onUpdateQuote = /* GraphQL */ `
  subscription OnUpdateQuote($organisation: String) {
    onUpdateQuote(organisation: $organisation) {
      ${getQuoteFields}
    }
  }
`;

export const onDeleteQuote = /* GraphQL */ `
  subscription OnDeleteQuote($organisation: String) {
    onDeleteQuote(organisation: $organisation) { ${getQuoteFields}
    }
  }
`;

export const listInvoicesByOrganisation = /* GraphQL */ `
  query ListInvoicesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listInvoiceFields}
      }
      nextToken
    }
  }
`;

export const listInvoicesByOrganisationForReporting = /* GraphQL */ `
  query ListInvoicesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listInvoicesForReportingFields}
      }
      nextToken
    }
  }
`;

export const listInvoicesByOrganisationWithLineItems = /* GraphQL */ `
  query ListInvoicesByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInvoiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvoicesByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listInvoiceWithLineItemsFields}
      }
      nextToken
    }
  }
`;

export const getInvoice = /* GraphQL */ `
  query GetInvoice($id: ID!) {
    getInvoice(id: $id) {
      ${getInvoiceFields}
    }
  }
`;

export const createInvoice = /* GraphQL */ `
  mutation CreateInvoice(
    $input: CreateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    createInvoice(input: $input, condition: $condition) {
      ${getInvoiceFields}
    }
  }
`;

export const deleteInvoice = /* GraphQL */ `
  mutation DeleteInvoice(
    $input: DeleteInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    deleteInvoice(input: $input, condition: $condition) {
      ${getInvoiceFields}
    }
  }
`;

export const updateInvoice = /* GraphQL */ `
  mutation UpdateInvoice(
    $input: UpdateInvoiceInput!
    $condition: ModelInvoiceConditionInput
  ) {
    updateInvoice(input: $input, condition: $condition) {
      ${getInvoiceFields}
    }
  }
`;

export const onCreateInvoice = /* GraphQL */ `
  subscription OnCreateInvoice($organisation: String) {
    onCreateInvoice(organisation: $organisation) {
      ${getInvoiceFields}
    }
  }
`;

export const onUpdateInvoice = /* GraphQL */ `
  subscription OnUpdateInvoice($organisation: String) {
    onUpdateInvoice(organisation: $organisation) {
      ${getInvoiceFields}
    }
  }
`;

export const onDeleteInvoice = /* GraphQL */ `
  subscription OnDeleteInvoice($organisation: String) {
    onDeleteInvoice(organisation: $organisation) { ${getInvoiceFields}
    }
  }
`;

export const getMinimalInvoiceLineItem = /* GraphQL */ `
  query GetInvoiceLineItem($id: ID!) {
    getInvoiceLineItem(id: $id) {
      ${getMinimalInvoiceLineItemFields}
    }
  }
`;

export const updateInvoiceLineItem = /* GraphQL */ `
  mutation UpdateInvoiceLineItem(
    $input: UpdateInvoiceLineItemInput!
    $condition: ModelInvoiceLineItemConditionInput
  ) {
    updateInvoiceLineItem(input: $input, condition: $condition) {
      ${getMinimalInvoiceLineItemFields}
    }
  }
`;

export const simpleAsyncJobFields = `
id
retriedTimes
retriedFromAsyncJobId
retryReason
fileType
taskId
fileId
type
fileVersionId
organisation
userId
status
createdAt
progressPercent
cancelRequested
error
restUrl
bucket
region
graphQLUrl
updatedAt
startedAt
finishedAt
durationSeconds
auditItemId
consumerId
logStreamName
outputS3Key
executionId
`;

export const getAsyncJobFields = `
${simpleAsyncJobFields}
task {
  ${getTaskFields}
}
file {
  ${getFileFields}
}
fileVersion {
  ${getFileVersionFields}
}
operations {
  type
  sheetName
  copySheetName
  oldSheetName
  newSheetName
  sheetNames
  newSheetNames
  externalReferences {
    id
    fileVersionId
    taskId
    taskRevisionId
    taskRevisionName
    fileId
    versionNumber
    key
  }
}
`;

export const getAsyncJob = /* GraphQL */ `
  query GetAsyncJob($id: ID!) {
    getAsyncJob(id: $id) {
      ${getAsyncJobFields}
    }
  }
`;

export const updateAsyncJob = /* GraphQL */ `
  mutation UpdateAsyncJob(
    $input: UpdateAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    updateAsyncJob(input: $input, condition: $condition) {
      ${getAsyncJobFields}
    }
  }
`;

export const createAsyncJob = /* GraphQL */ `
  mutation CreateAsyncJob(
    $input: CreateAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    createAsyncJob(input: $input, condition: $condition) {      
      ${getAsyncJobFields}
    }
  }
`;

export const deleteAsyncJob = /* GraphQL */ `
  mutation DeleteAsyncJob(
    $input: DeleteAsyncJobInput!
    $condition: ModelAsyncJobConditionInput
  ) {
    deleteAsyncJob(input: $input, condition: $condition) {
      ${getAsyncJobFields}
    }
  }
`;

export const onCreateAsyncJob = /* GraphQL */ `
  subscription OnCreateAsyncJob($organisation: String) {
    onCreateAsyncJob(organisation: $organisation) {
      ${getAsyncJobFields}
    }
  }
`;

export const onUpdateAsyncJob = /* GraphQL */ `
  subscription OnUpdateAsyncJob($organisation: String) {
    onUpdateAsyncJob(organisation: $organisation) {
      ${getAsyncJobFields}
    }
  }
`;

export const onDeleteAsyncJob = /* GraphQL */ `
  subscription OnDeleteAsyncJob($organisation: String) {
    onDeleteAsyncJob(organisation: $organisation) {
      ${getAsyncJobFields}
    }
  }
`;

export const listAsyncJobs = /* GraphQL */ `
  query ListAsyncJobs(
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${simpleAsyncJobFields}
      }
      nextToken
    }
  }
`;
export const listPublishJobsByFile = /* GraphQL */ `
  query ListPublishJobsByFile(
    $fileVersionId: ID
    $statusCreatedAt: ModelAsyncJobByFileVersionCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsByFile(
      fileVersionId: $fileVersionId
      statusCreatedAt: $statusCreatedAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${simpleAsyncJobFields}
      }
      nextToken
    }
  }
`;

export const listAsyncJobsByStatus = /* GraphQL */ `
  query ListAsyncJobsByStatus(
    $status: PublishJobStatus
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsyncJobsByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${simpleAsyncJobFields}
      }
      nextToken
    }
  }
`;

export const listPublishJobsByOrganisation = /* GraphQL */ `
  query ListPublishJobsByOrganisation(
    $organisation: ID
    $createdAt: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAsyncJobFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishJobsByOrganisation(
      organisation: $organisation
      createdAt: $createdAt
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items { 
        ${simpleAsyncJobFields}
      }
      nextToken
    }
  }
`;

const listClientFields = `
id
key
name
organisation
initials
isPriority
randomNumber
itemSubscription
collectionSubscription
createdAt
customFields {
  id
  value
}
fees {
  id
  label
  value
  type
  defaultPercentage
  defaultPlus
  overrideFeeId
}
addresses {
  id
  streetName
  streetNumber
  houseName
  postcode
  county
  city
  country
}
contacts {
  id
  firstName
  lastName
  email
  phone
  phone2
  position
  notes
}
vatNumber
companyNumber
defaultAddressId
defaultContactId
updatedAt
amountBudget
amountSpent
amountInvoiced
amountReceived
amountOutstanding
hoursBudget
hoursSpent
defaultExpectPaymentAfterDays
sharedWithGroups
sharedWithUsers
`;

const getClientFields = `
${listClientFields}
notes
`;

export const getClient = /* GraphQL */ `
  query GetClient($id: ID!) {
    getClient(id: $id) {
      ${getClientFields}
    }
  }
`;

export const updateClient = /* GraphQL */ `
  mutation UpdateClient(
    $input: UpdateClientInput!
    $condition: ModelClientConditionInput
  ) {
    updateClient(input: $input, condition: $condition) {
      ${getClientFields}
    }
  }
`;

export const deleteClient = /* GraphQL */ `
  mutation DeleteClient(
    $input: DeleteClientInput!
    $condition: ModelClientConditionInput
  ) {
    deleteClient(input: $input, condition: $condition) {
      ${getClientFields}
    }
  }
`;

export const listClients = /* GraphQL */ `
  query ListClientsByOrganisation(
    $organisation: ID
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelClientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientsByOrganisation(
      organisation: $organisation
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        ${listClientFields}
      }
  }
}`;

export const createClient = /* GraphQL */ `
  mutation CreateClient(
    $input: CreateClientInput!
    $condition: ModelClientConditionInput
  ) {
    createClient(input: $input, condition: $condition) {
      ${getClientFields}
    }
  }
`;

export const onCreateClient = /* GraphQL */ `
  subscription OnCreateClient($organisation: String) {
    onCreateClient(organisation: $organisation) {
      ${getClientFields}
    }
  }
`;

export const onUpdateClient = /* GraphQL */ `
  subscription OnUpdateClient($organisation: String) {
    onUpdateClient(organisation: $organisation) {
      ${getClientFields}
    }
  }
`;

export const onDeleteClient = /* GraphQL */ `
  subscription OnDeleteClient($organisation: String) {
    onDeleteClient(organisation: $organisation) {
      ${getClientFields}
    }
  }
`;

export const listTaskRevisions = /* GraphQL */ `
  query ListTaskRevisions($filter: ModelTaskRevisionFilterInput, $limit: Int, $nextToken: String) {
    listTaskRevisions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        name
        isReadOnly
        organisation
        isArchived
        base
        randomNumber
        itemSubscription
        collectionSubscription
        description
        author
        checkedBy
        reviewId
        reviewAcceptDate
        reviewStatus
        reviewSecondaryStatus
        dueDate
        estimatedHours
        createdAt
        updatedAt
        task {
          id
          title
        }
      }
      nextToken
    }
  }
`;

const getOrganisationFields = `
id
logoKey
name
description
projectCount
requestCount
projectIdOffset
hasAutoPublish
slackWebhookURL
usesFiles
usesAuthority
usesDesignAuthority
usesCheckAuthority
usesReviewAuthority
usesInitials
usesClientInitials
usesProjectInitials
usesTaskInitials
usesRevisions
usesReviews
usesSprints
usesDelete
billingDay
pricePerSeat
taxRate
accountBalance
monthlyCost
discountPerSeat
lastBillCreatedAt
seats
deploymentAlertNumber
itemSubscription
settings {
  review {
    excludedFileTypes
    defaultCommentFontSize
  }
  quote {
    isValidFromHidden
    isValidUntilHidden
    hiddenLineItemColumns
    hiddenLineItemColumnsByTemplate {
      templateId
      hiddenLineItemColumns
    }
    shouldArchiveTasksWhenClosingQuote
    shouldOpenCreateTaskModal
    defaultQuoteBccEmails
    usesQuotes
    usesDelete
    tasksCreatedFromQuoteDefaultToQuoteTitle
    tasksCreatedFromQuoteDefaultEstimatedHoursToLineItemAmount
    defaultUnitPrice
    disablePreview
    emailAddressToSendQuotesFrom
    quotesInHours
    fieldsToCopyFromFirstQuoteLineItemByTemplate {
      templateId
      fieldsToCopyFromFirstQuoteLineItem
    }
  }
  task {
    createsCat2Check
    defaultSprint
    attachmentTemplates {
      id
      name
      path
    }
    taskRevisionsAreSyncedWithSheetRevisions
    allowUseOfCatZero
    usesTaskEstimates
    useTaskRevisionEstimates
    useDueDatesOnTaskRevisions
    usesPriority
    usesRequestedDate
    displayTaskEstimatesAsTag
    createAttachmentFolderWithTask
    dontCreateTaskFolders
    defaultTaskCatLevel
    isTaskAssigneeMandatory
    isTaskDueDateMandatory
    priorities {
      id
      name
      color
    }
    maxTaskRevisionNameLength
    maxTaskRevisionDescriptionLength
    needsRequestForTaskRevisionCreation
    needsRequestForTaskRevisionReview
    automaticallyCreateProject
    hideCustomTaskNumberAtCreationTime
    hideCopyTaskAtCreationTime
    hideTaskDueDates
    taskIdToCopyByDefault
    allowMultipleLiveTaskRevisions
    cannotCreateNewTaskRevisions
    copyTaskAlsoCopiesAllTaskRevisions
    usesStartAndEndDates
    allowMultipleUsersToBeAssignedToATask
    allowMultipleStockItemsToBeAssignedToATask
    hideRawCloudStorageTab
  }
  file {
    hideIncludeInPublishSwitch
    hideSheetTitleInput
    hideCustomReference
    hideIncludeInDocumentRegister
    hideAnnotateSwitch
    hideXref
    maxSheetRevisionDescriptionLength
    maxSheetRevisionNameLength
    sheetDescriptionsMustBeUnique
  }
  general {
    usesDocumentRegister
    usesTaskConfirmation
    usesTeams
    usesTimeOff
    usesUploadFromGoogleDrive
    usesUploadFromOneDrive
    usesUploadFromDropbox
    requirePermissionToDisplayUsers
    sectors {
      id
      label
    }
    geographicalLocations {
      id
      label
    }
    hideFinancials
    hideOrganisationIdInTags
    defaultAddressToSendEmailsFrom
    scheduledNotifications {
      id
      name
      title
      body
      targetUserGroups
      schedule
    }
  }
  timesheet {
    usesTimesheets
    areTagsMandatory
    isDescriptionMandatory
    requireQuoteOrVariationForBillableTimesheetBlocks
    canAssignTimesheetBlocksToTaskRevisions
    usesReview
    recordLatLongWhenClockingInOut
    recordLatLongEveryXMinutes
    usesPartDayTimeOff
  }
  timeline {
    usesTimeline
    usesPhysicalBlockInteraction
    usesGapFilling
    usesColoredBlocks
    usesCustomColorLogic
    defaultRoundToHours
    defaultTaskLengthHours
    shouldAssignTimelineTaskToUser
    shouldDisplayOwnUserAtTheTop
    planProjectsInsteadOfTasks
    defaultTaskLengthToEstimatedHours
    planTaskRevisionsInsteadOfTasks
    pseudoTaskOptions {
      id
      defaultColor
    }
  }
  calendar {
    usesCalendar
  }
  documentLibrary {
    usesDocumentLibrary
  }
  queue {
    usesQueue
  }
  dashboard {
    displayViewTypePicker
  }
  invoice {
    usesInvoices
    usesDoubleInvoiceReview
    usesPercentagesInsteadOfQuantity
    invoiceDate
    useCancelledStatus
    displayPaidInvoicesInSeparateColumns
    emailAddressToSendInvoicesFrom
    defaultExpectPaymentAfterDays
  }
  purchaseOrder {
    usesPurchaseOrders
    emailAddressToSendPurchaseOrdersFrom
  }
  supplier {
    usesSuppliers
  }
  project {
    hideCustomProjectNumberAtCreationTime
    hideAssignedToAtCreationTime
    hideClientAtCreationTime
    customNumberCheckboxOnByDefault
    attachmentTemplates {
      id
      name
      path
    }
    customFields {
      id
      label
      target
      type
      options {
        value
        label
        color
        showAsTag
        tagLabel
      }
      defaultValue
      showAsTag
      displayTagIfChecked
      displayTagIfEmpty
      onlyVisibleForCertainUserGroups
      groupsThatCanSee
      tagLabel
      color
      formula
      isStatic
      mandatory
      nonAdminsCanRead
      nonAdminsCanWrite
    }
    hideCustomProjectNumberAtCreationTime
  }
  client {
    customFields {
      id
      label
      target
      type
      options {
        value
        label
        color
        showAsTag
        tagLabel
      }
      defaultValue
      showAsTag
      displayTagIfChecked
      displayTagIfEmpty
      onlyVisibleForCertainUserGroups
      groupsThatCanSee
      tagLabel
      color
      formula
      isStatic
      mandatory
      nonAdminsCanRead
      nonAdminsCanWrite
    }
  }
  request {
    usesRequests
    emailsToNotifyOnNewRequest
    usesClientReview
    displayRequestFormInTaskReview
    allowRequestFormPdfDownload
    allowExternalReviews
    formsCanOnlyBeAddedToOneTask
  }
  stock {
    usesStock
  }
}
address {
  id
  streetName
  streetNumber
  houseName
  postcode
  county
  city
  country
}
vatNumber
fileTypesUsed
financialControllers
addons {
  pricePerSeat
  name
  discountPercent
}
labels {
  id
  value
}
defaultFees {
  id
  label
  value
  type
  defaultPercentage
  defaultPlus
  overrideFeeId
}
teams {
  id
  label
}
fileStatuses {
  name
}
taskStatuses {
  name
  type
}
customFields {
  id
  label
  target
  type
  options {
    value
    label
    color
    showAsTag
    tagLabel
  }
  defaultValue
  showAsTag
  displayTagIfChecked
  displayTagIfEmpty
  onlyVisibleForCertainUserGroups
      groupsThatCanSee
  tagLabel
  color
  formula
  isStatic
  mandatory
  nonAdminsCanRead
  nonAdminsCanWrite
}
integrations {
  id
  authorisedAt
}
accountancyOrganisationId
includeInBilling
financialsLastRecalculatedAt
financialsLastStartedRecalculatingAt
financialsRecalculationProgress
financialsRecalculationStatus
createdAt
updatedAt
variables {
  items {
    id
    organisation
    name
    type
    value
    createdAt
    updatedAt
  }
  nextToken
}
templates {
  items {
    id
    organisation
    name
    type
    createdAt
    currentVersionNumber
    previewTaskId
    previewFileId
    previewTaskRevisionId
    isLive
    isDeprecated
    isDefault
    key
    reviewCommentFontSize
    excludeFromRegisterByDefault
    isCat2Restricted
    outputType
    systemVersion
    parentType
    updatedAt
  }
  nextToken
}
bills {
  items {
    id
    customId
    organisation
    createdAt
    isPaid
    subtotal
    totalWithTax
    taxAmount
    lineItems {
      id
      name
      quantity
      pricePerUnit
      amount
      amountWithTax
      discountPercent
      discountAmount
    }
    s3Key
    itemSubscription
    accountancyInvoiceId
    updatedAt
  }
  nextToken
}
timesheetTags {
  items {
    id
    organisation
    label
    createdAt
    updatedAt
  }
  nextToken
}
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        ${getOrganisationFields}
      }
      nextToken
    }
  }
`;

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      ${getOrganisationFields}
    }
  }
`;
