import { useEffect, useState } from "react";
import { graphqlOperation } from "aws-amplify";
import { withRouter } from "react-router-dom";
import { Form, Modal, Button, Radio, Typography, Input } from "antd";

import withSubscriptions from "common/withSubscriptions";
import { getFile, updateFile } from "graphql/queries_custom";

import {
  getUppercaseStatus,
  callGraphQL,
  pointSheetsToLatestFileVersion,
  createFileVersionInApi,
  createSheetRevisionInApi,
  copyS3MainFile,
  isFileOpen,
  showFileIsOpenModal,
} from "common/helpers";
import { getSheetRevisionName } from "common/naming";
import { getLatestFileVersion, getLatestRevision } from "common/shared";
import { HAS_SHEETS } from "common/shared";

import "./CreateSheetRevisionModal.scss";

export function CreateSheetRevisionModal({ onClose, apiUser, task, sheet, history, file, organisationDetails }) {
  const [isLoading, setIsLoading] = useState(false);
  const [defaultName, setDefaultName] = useState();
  const [form] = Form.useForm();
  const [status, setStatus] = useState(
    sheet ? sheet.revisions.items.slice(-1)[0].status : file.sheets.items[0].revisions.items.slice(-1)[0].status
  );

  useEffect(() => {
    form.setFieldsValue({ status });
  }, []);

  useEffect(() => {
    getSheetRevisionName({
      organisation: task.organisation,
      sheet: sheet || file.sheets.items[0],
      newStatus: status,
      task,
    }).then((name) => {
      setDefaultName(name);
      form.setFieldsValue({ name });
    });
  }, [status]); // eslint-disable-line

  async function onSubmit({ name, description, status }) {
    const fileIsOpen = await isFileOpen({ task, file });
    if (fileIsOpen) {
      showFileIsOpenModal(file);
      return;
    }

    setIsLoading(true);

    const oldFileVersion = getLatestFileVersion(file);
    const latestTaskRevision = getLatestRevision(task);

    const newFileVersionNumber = oldFileVersion.versionNumber + 1;

    const newFileVersion = (
      await createFileVersionInApi({
        file,
        task,
        taskRevision: latestTaskRevision,
        apiUser,
        versionNumber: newFileVersionNumber,
      })
    ).data.createFileVersion;

    if (sheet) {
      await createSheetRevisionInApi({
        apiUser,
        task,
        sheet,
        file,
        fileVersion: newFileVersion,
        taskRevision: latestTaskRevision,
        status: getUppercaseStatus(status),
        name,
        description,
      });
    } else if (file.sheets.items.length === 1) {
      await createSheetRevisionInApi({
        apiUser,
        task,
        sheet: file.sheets.items[0],
        file,
        fileVersion: newFileVersion,
        taskRevision: latestTaskRevision,
        status: getUppercaseStatus(status),
        name,
        description,
      });
    } else {
      for (let i = 0; i < file.sheets.items.length; i++) {
        let generatedName = await getSheetRevisionName({
          organisation: task.organisation,
          sheet: file.sheets.items[i],
          task,
          newStatus: status,
        });

        await createSheetRevisionInApi({
          apiUser,
          task,
          sheet: file.sheets.items[i],
          file,
          fileVersion: newFileVersion,
          taskRevision: latestTaskRevision,
          status: getUppercaseStatus(status),
          name: generatedName || name,
          description,
        });
      }
    }

    const updatedFile = (
      await callGraphQL(
        "Could not retrieve file",
        graphqlOperation(getFile, {
          id: file.id,
        })
      )
    ).data.getFile;

    await pointSheetsToLatestFileVersion({
      apiUser,
      task,
      file: updatedFile,
      taskRevision: latestTaskRevision,
    });

    await callGraphQL(
      "Could not update file",
      graphqlOperation(updateFile, {
        input: {
          id: file.id,
          randomNumber: Math.floor(Math.random() * 100000),
        },
      })
    );

    // each time we create a sheet revision, we create a copy of the actual file
    copyS3MainFile({
      history,
      task,
      apiUser,
      taskRevision: latestTaskRevision,
      file: updatedFile,
      oldVersionNumber: oldFileVersion.versionNumber,
      newVersionNumber: newFileVersion.versionNumber,
      extension: file.extension,
      doPublish: true,
      oldFileVersion,
      newFileVersion,
    });

    onClose();
  }

  const layout = {
    labelCol: {
      span: 8,
    },
    wrapperCol: {
      span: 16,
    },
  };
  const tailLayout = {
    wrapperCol: {
      offset: 8,
      span: 16,
    },
  };

  // if (defaultName === undefined) {
  //   return null;
  // }

  return (
    <Modal
      maskClosable={false}
      title={
        <Typography.Text>
          Create revision{" "}
          {HAS_SHEETS[file.type] ? (
            <>
              for <b>{sheet ? sheet.name : "all sheets"}</b>
            </>
          ) : null}
        </Typography.Text>
      }
      open={true}
      onOk={onSubmit}
      onCancel={onClose}
      footer={null}
      className="create-sheet-revision-modal"
    >
      <Form
        {...layout}
        form={form}
        initialValues={{
          name: defaultName,
        }}
        onFinish={onSubmit}
      >
        <Form.Item
          label="Name"
          name="name"
          rules={[
            {
              required: true,
              message: "You must specify a revision name",
            },
            {
              validator: async (_, name) => {
                if (!name) {
                  return;
                }

                let nameIsAlreadyUsed = false;
                if (sheet) {
                  sheet.revisions.items.forEach((revision) => {
                    if (revision.name === name) {
                      nameIsAlreadyUsed = true;
                    }
                  });
                } else {
                  file.sheets.items.forEach((sheet) => {
                    sheet.revisions.items.forEach((revision) => {
                      if (revision.name === name) {
                        nameIsAlreadyUsed = true;
                      }
                    });
                  });
                }

                if (nameIsAlreadyUsed) {
                  throw new Error(`This name already used`);
                }
              },
            },
          ]}
        >
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item label="Description" name="description">
          <Input autoComplete="off" />
        </Form.Item>
        <Form.Item
          label="Status"
          name="status"
          rules={[
            {
              required: true,
              message: "You must choose a status",
            },
          ]}
        >
          <Radio.Group
            className="sheet-revision-status-list"
            onChange={(e) => {
              form.setFieldsValue({ status: e.target.value });
              setStatus(e.target.value);
            }}
            value={status}
          >
            {organisationDetails.fileStatuses.map((status) => {
              return (
                <Radio value={status.name.toUpperCase().split(" ").join("_")} key={status.name}>
                  {status.name}
                </Radio>
              );
            })}
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {isLoading ? "Creating" : "Submit"}
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default withRouter(
  withSubscriptions({
    Component: CreateSheetRevisionModal,
    subscriptions: ["organisationDetails"],
  })
);
