import { useState } from "react";
import { Button, Modal, Typography, Tag, Select } from "antd";
import { withRouter } from "react-router-dom";
import { DeleteOutlined } from "@ant-design/icons";
import cx from "classnames";
import { LexoRank } from "lexorank";
import { Droppable } from "react-beautiful-dnd";
import { PlusCircleOutlined, LoadingOutlined, DownOutlined, RightOutlined } from "@ant-design/icons";

import { graphqlOperation } from "aws-amplify";
import { callGraphQL, getLabel } from "common/helpers";
import { updateSprint, updateTask, deleteSprint } from "graphql/queries_custom";
import withSubscriptions from "common/withSubscriptions";
import query from "query-string";

import Card from "Card/Card";
import TaskListItem from "TaskListItem/TaskListItem";
import Input from "Input/Input";
import LazyLoadList from "LazyLoadList/LazyLoadList";

import "./SprintItem.scss";
import { getSimpleLabel } from "common/labels";

export function SprintItem({
  sprints,
  tasks,
  clients,
  projects,
  filteredTasks,
  name,
  id,
  isActive,
  isFinished,
  oneSprintIsActive,
  organisationDetails,
  openCreateTaskModal,
  dragStartedOnThis,
  location,
  history,
  windowWidth,
}) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [sprintToMoveTo, setSprintToMoveTo] = useState(); // eslint-disable-line
  const [isReorderingTasks, setIsReorderingTasks] = useState(false); // eslint-disable-line

  const backlog = sprints[sprints.length - 1];

  const tasksInSprint = filteredTasks.filter((x) => x.sprintId === id && !x.isHidden && !x.isExternalReference);
  const sortedTasks = tasksInSprint.sort((a, b) => (a.order < b.order ? -1 : 1));

  let isBacklog = id === backlog.id;

  async function moveAllTasksToNewSprint(sprintToMoveTo, includeFinishedAndArchived) {
    const tasksToMove = tasks.filter((x) => {
      if (x.sprintId !== id) {
        return false;
      }
      if (!includeFinishedAndArchived && (x.isFinished || x.isArchived)) {
        return false;
      }
      return true;
    });
    const lastTaskInNewSprint = tasks
      .filter((x) => x.sprintId === sprintToMoveTo)
      .sort((a, b) => (a.order < b.order ? -1 : 1))
      .slice(-1)[0];
    let newTaskOrder = lastTaskInNewSprint ? LexoRank.parse(lastTaskInNewSprint.order).genNext() : LexoRank.middle();

    for (let i = 0; i < tasksToMove.length; i++) {
      const task = tasksToMove[i];
      await callGraphQL(
        `Could not move ${getSimpleLabel("task")} ${task.title}`,
        graphqlOperation(updateTask, {
          input: {
            id: task.id,
            sprintId: sprintToMoveTo,
            order: newTaskOrder.value,
          },
        })
      );
      newTaskOrder = newTaskOrder.genNext();
    }
  }

  function displayTasks() {
    if (!filteredTasks || filteredTasks.length === 0) {
      return null;
    }

    return (
      <div className="tasks-in-sprint">
        <LazyLoadList
          initialChunkSize={50}
          subsequentChunkSize={50}
          overshootSize={20}
          list={sortedTasks}
          refreshOnChange={windowWidth}
          item={(task, index) => {
            return (
              <TaskListItem
                key={task.id}
                {...task}
                tasks={tasks}
                tasksInSprint={sortedTasks}
                index={index}
                organisationDetails={organisationDetails}
                draggable
                windowWidth={windowWidth}
              />
            );
          }}
        />
      </div>
    );
  }

  function confirmDeleteSprint() {
    window.sprintToMoveTo = backlog.id;
    Modal.confirm({
      title: "Confirm delete sprint",
      className: "delete-sprint-modal",
      content: (
        <>
          <Typography.Text>Which sprint to move tasks to:</Typography.Text>
          <br />
          <Select
            style={{ marginTop: 10 }}
            allowClear={true}
            defaultValue={window.sprintToMoveTo}
            placeholder="Choose a sprint"
            className="move-to-sprint-select"
            onChange={(sprintId) => {
              setSprintToMoveTo(sprintId);
              window.sprintToMoveTo = sprintId;
            }}
          >
            {sprints
              .filter((x) => x.id !== id && !x.isFinished)
              .map((sprint) => (
                <Select.Option key={sprint.id} value={sprint.id}>
                  {sprint.name}
                </Select.Option>
              ))}
          </Select>
        </>
      ),
      onOk: async () => {
        if (window.sprintToMoveTo) {
          await moveAllTasksToNewSprint(window.sprintToMoveTo, true);
        }

        await callGraphQL(
          `Could not delete sprint ${name}`,
          graphqlOperation(deleteSprint, {
            input: {
              id,
            },
          })
        );
        window.location.reload();
      },
    });
  }

  function confirmStartSprint() {
    Modal.confirm({
      title: (
        <Typography.Text>
          {getLabel({
            organisationDetails,
            id: "Start sprint",
            defaultValue: "Start sprint",
          })}
          : <b>{name}</b>?
        </Typography.Text>
      ),
      className: "start-sprint-modal",
      onOk: async () => {
        await callGraphQL(
          `Could not start sprint ${name}`,
          graphqlOperation(updateSprint, {
            input: {
              id,
              isActive: true,
              startedAt: new Date().toISOString(),
            },
          })
        );
      },
    });
  }
  function confirmEndSprint() {
    window.sprintToMoveTo = backlog.id;
    Modal.confirm({
      title: (
        <>
          {getLabel({
            organisationDetails,
            id: "End sprint",
            defaultValue: "End sprint",
          })}
          : <b>{name}</b>?
        </>
      ),
      maskClosable: true,
      className: "end-sprint-modal",
      content: (
        <>
          <Typography.Text>Move unfinished items to:</Typography.Text>
          <br />
          <Select
            style={{ marginTop: 10 }}
            allowClear={true}
            defaultValue={window.sprintToMoveTo}
            placeholder="Choose a sprint"
            className="move-to-sprint-select"
            onChange={(sprintId) => {
              setSprintToMoveTo(sprintId);
              window.sprintToMoveTo = sprintId;
            }}
          >
            {sprints
              .filter((x) => x.id !== id && !x.isFinished)
              .map((sprint) => (
                <Select.Option key={sprint.id} value={sprint.id}>
                  {sprint.name}
                </Select.Option>
              ))}
          </Select>
        </>
      ),
      onOk: async () => {
        if (window.sprintToMoveTo) {
          await moveAllTasksToNewSprint(window.sprintToMoveTo, false);
        }

        await callGraphQL(
          `Could not end sprint ${name}`,
          graphqlOperation(updateSprint, {
            input: {
              id,
              isActive: false,
              isFinished: true,
              finishedAt: new Date().toISOString(),
            },
          })
        );

        const queryParams = query.parse(location.search);
        const { sprintId, ...data } = queryParams;
        const filteredQuery = query.stringify(data);

        history.replace(`${location.pathname}?${filteredQuery}`);
        window.location.reload();
      },
    });
  }

  function confirmReorderTasks() {
    Modal.confirm({
      title: (
        <>
          Reorder{" "}
          {getLabel({
            id: "tasks",
            defaultValue: "tasks",
          })}
        </>
      ),
      maskClosable: true,
      className: "end-sprint-modal",
      content: (
        <>
          This action will order all the{" "}
          {getLabel({
            id: "tasks",
            defaultValue: "tasks",
          })}{" "}
          in this sprint by client and project, with priority clients first.
        </>
      ),
      onOk: () => reorderTasks(),
    });
  }

  async function reorderTasks() {
    const allTasksInSprint = tasks
      .filter((task) => task.sprintId === id)
      .map((task) => {
        return {
          ...task,
          client: clients.find((x) => x.id === task.clientId),
          project: projects.find((x) => x.id === task.projectId),
        };
      });
    let reorderedTasksInSprint = allTasksInSprint.sort((a, b) => {
      if (a.client.isPriority && !b.client.isPriority) {
        return -1;
      }

      if (!a.client.isPriority && b.client.isPriority) {
        return 1;
      }

      if (a.client.name.toLowerCase() < b.client.name.toLowerCase()) {
        return -1;
      }

      if (a.client.name.toLowerCase() > b.client.name.toLowerCase()) {
        return 1;
      }

      if (a.project.title.toLowerCase() < b.project.title.toLowerCase()) {
        return -1;
      }

      if (a.project.title.toLowerCase() > b.project.title.toLowerCase()) {
        return 1;
      }

      if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1;
      } else {
        return 1;
      }
    });

    let currentOrder = LexoRank.middle();
    reorderedTasksInSprint = reorderedTasksInSprint.map((task) => {
      const newTask = {
        ...task,
        order: currentOrder.value,
      };

      currentOrder = currentOrder.genNext();
      return newTask;
    });

    let updatePromises = [];

    for (let i = 0; i < reorderedTasksInSprint.length; i++) {
      updatePromises.push(
        callGraphQL(
          "Failed to reorder task",
          graphqlOperation(updateTask, {
            input: {
              id: reorderedTasksInSprint[i].id,
              order: reorderedTasksInSprint[i].order,
            },
          })
        )
      );
      if (reorderedTasksInSprint.length < 30) {
        await new Promise((resolve) => setTimeout(resolve, 10));
      } else {
        await new Promise((resolve) => setTimeout(resolve, 50));
      }
    }
    await Promise.all(updatePromises);
  }

  async function changeSprintName(name) {
    await callGraphQL(
      "Failed to update sprint name",
      graphqlOperation(updateSprint, {
        input: {
          id,
          name,
        },
      })
    );
  }

  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={cx("sprint-item-container")}
          data-testid={`sprint-${id}`}
          data-cy="sprint-item"
          data-id={id}
          data-name={name}
        >
          <Card
            className={cx("sprint-item", {
              "is-collapsed": !isExpanded,
              "drag-started-on-this": dragStartedOnThis,
              "list-being-dragged-over": snapshot.isDraggingOver,
            })}
            attributes={{ "data-title": name }}
            title={
              <>
                {isExpanded ? (
                  <Button type="clear" className="collapse-button" onClick={() => setIsExpanded(false)}>
                    <DownOutlined />
                  </Button>
                ) : (
                  <Button type="clear" className="expand-button" onClick={() => setIsExpanded(true)}>
                    <RightOutlined />
                  </Button>
                )}
                {isActive && (
                  <Tag color="green">
                    {getLabel({
                      organisationDetails,
                      id: "Sprint-Active",
                      defaultValue: "Active",
                    })}
                  </Tag>
                )}
                {isFinished && <Tag>Finished</Tag>}
                <Typography.Text className="task-count-in-sprint">
                  {tasksInSprint.length}{" "}
                  {tasksInSprint.length === 1
                    ? getLabel({
                        id: "task",
                        defaultValue: "task",
                      })
                    : getLabel({
                        id: "tasks",
                        defaultValue: "tasks",
                      })}
                </Typography.Text>
                <Input
                  className="sprint-name"
                  data-cy="sprint-name-input"
                  data-value={name}
                  onChange={changeSprintName}
                  defaultValue={name}
                  fullWidth
                />
              </>
            }
            actions={
              <>
                {isActive && (
                  <Button className="end-sprint" onClick={confirmEndSprint}>
                    {getLabel({
                      organisationDetails,
                      id: "End sprint",
                      defaultValue: "End sprint",
                    })}
                  </Button>
                )}
                {!isFinished && !oneSprintIsActive && !isBacklog && !isActive && (
                  <Button className="start-sprint" type="primary" onClick={confirmStartSprint}>
                    {getLabel({
                      organisationDetails,
                      id: "Start sprint",
                      defaultValue: "Start sprint",
                    })}
                  </Button>
                )}
                <Button onClick={confirmReorderTasks}>
                  Reorder{" "}
                  {getLabel({
                    id: "tasks",
                    defaultValue: "tasks",
                  })}
                </Button>

                <Button
                  type="primary"
                  className="create-task-in-sprint"
                  icon={<PlusCircleOutlined />}
                  onClick={() => openCreateTaskModal(id)}
                >
                  Create{" "}
                  {getLabel({
                    id: "task",
                    defaultValue: "task",
                  })}{" "}
                  in{" "}
                  {getLabel({
                    organisationDetails,
                    id: "sprint",
                    defaultValue: "sprint",
                  })}
                </Button>

                <Button
                  disabled={isBacklog}
                  className="delete-sprint"
                  data-cy="delete-sprint-button"
                  onClick={confirmDeleteSprint}
                  icon={<DeleteOutlined />}
                />
              </>
            }
          >
            {isExpanded && displayTasks()}
          </Card>
          {isReorderingTasks && (
            <Modal maskClosable={false} title="Please wait" visible={true} footer={null}>
              <LoadingOutlined />{" "}
              <Typography.Text>
                We are currently reordering{" "}
                {getLabel({
                  id: "tasks",
                  defaultValue: "tasks",
                })}
                .
              </Typography.Text>
            </Modal>
          )}
        </div>
      )}
    </Droppable>
  );
}

export default withRouter(
  withSubscriptions({
    Component: SprintItem,
    subscriptions: ["users", "clients", "projects", "sprints", "tasks"],
  })
);
