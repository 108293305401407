import { callGraphQLSimple } from "common/apiHelpers";
import { getSimpleLabel } from "common/labels";
import { getUppercaseStatus } from "common/helpers";

export async function changeTaskStatus({ status, taskId, organisationDetails, order, recordActivity = true }) {
  const input = getUpdatedTaskDetailsAfterChangingStatus({ status, taskId, organisationDetails });

  if (order) {
    input.order = order;
  }

  await callGraphQLSimple({
    queryCustom: "updateTask",
    message: `Failed to update ${getSimpleLabel("task")} status`,
    variables: {
      input,
    },
  });

  if (recordActivity) {
    await window.callGraphQLSimple({
      mutation: "createTaskActivityItem",
      message: `Failed to record ${getSimpleLabel("task")} activity item`,
      variables: {
        input: {
          taskId,
          author: window.apiUser.id,
          organisation: organisationDetails.id,
          type: "STATUS_CHANGED",
          content: JSON.stringify({
            newStatus: input.status,
          }),
        },
      },
    });
  }
}

export function getUpdatedTaskDetailsAfterChangingStatus({ taskId, status, organisationDetails }) {
  let input = {
    id: taskId,
  };

  let newStatusDetails = (organisationDetails.taskStatuses || []).find(
    (statusDetails) => getUppercaseStatus(statusDetails.name) === getUppercaseStatus(status)
  );
  let newStatusMeansDone = false;
  if (newStatusDetails && newStatusDetails.type === "DONE") {
    newStatusMeansDone = true;
  }

  if (newStatusMeansDone) {
    input.isFinished = true;
    input.finishedAt = new Date().toISOString();
    input.archivedAt = null;
  } else {
    input.isFinished = false;
    input.finishedAt = null;
  }

  input.status = newStatusDetails?.name || status;

  return input;
}
