import { displayErrorMessage, callGraphQL, getReadableStatus } from "common/helpers";
import { callRest } from "common/apiHelpers";

import { getPurchaseOrderId } from "common/naming";
import { roundToDecimals } from "common/mathHelpers";
import { KEY_TYPES, encodeKey } from "common/shared";
import { PURCHASE_ORDER_STATUSES, CREATE_RETRY_LIMIT, CURRENCIES } from "common/constants";
import { DEFAULT_TAX_RATE } from "common/constants";

import { graphqlOperation } from "aws-amplify";

import { createDocumentActivityItem, updatePurchaseOrder } from "graphql/mutations";

import { REVIEW_STATUS_READABLE } from "common/constants";

export async function recordActivityItem({ purchaseOrder, type, author, users, content }) {
  const reviewerDetails = users.find((user) => user.id === purchaseOrder.checkedBy);

  if (!content) {
    switch (type) {
      case "CREATED":
        content = `Purchase order created`;
        break;
      case "STATUS_CHANGED":
        content = `Purchase order status changed to ${getReadableStatus(purchaseOrder.status)}`;
        break;
      case "TOTAL_CHANGED":
        content = `Purchase order total changed`;
        break;
      case "SENT":
        // content = `Purchase order sent to ${supplierContactDetails?.firstName} ${supplierContactDetails?.lastName} at ${supplierContactDetails?.email}`;
        content = `Purchase order status manually changed to "Sent"`;
        break;
      case "PAID":
        content = `Purchase order paid`;
        break;
      case "VIEWED":
        content = `Purchase order viewed`;
        break;
      case "REVIEW_ACCEPTED":
        content = `Purchase order review approved`;
        break;
      case "REVIEW_REJECTED":
        content = `Purchase order review rejected - ${REVIEW_STATUS_READABLE[purchaseOrder.reviewStatus].label}`;
        break;
      case "REVIEW_CLOSED":
        content = `Purchase order review closed`;
        break;
      case "REVIEW_OPENED":
        content = `Purchase order review opened. Reviewer is ${reviewerDetails.firstName} ${reviewerDetails.lastName}`;
        break;
      default:
        break;
    }
    content = content || type;
  }

  await callGraphQL(
    "Failed to record activity item",
    graphqlOperation(createDocumentActivityItem, {
      input: {
        documentId: purchaseOrder.id,
        total: purchaseOrder.total,
        type,
        organisation: purchaseOrder.organisation,
        author,
        content,
      },
    })
  );
  await callGraphQL(
    "Failed to refresh purchase order",
    graphqlOperation(updatePurchaseOrder, {
      input: {
        id: purchaseOrder.id,
        itemSubscription: Math.floor(Math.random() * 100000),
      },
    })
  );
}

export async function createPurchaseOrder({
  organisationDetails,
  suppliers,
  supplier,
  project,
  templateId,
  users,
  apiUser,
  title,
}) {
  let fileType = "PURCHASE_ORDER";
  const templatesForFileType = organisationDetails.templates.items.filter(
    // (template) => (template.isLive || !template.key) && !template.isDeprecated
    // TODO: re-add the restriction for template to be live
    (template) => template.type === fileType && !template.isDeprecated
  );

  if (!templateId) {
    templateId = templatesForFileType[0].id;
  }

  const newPurchaseOrder = await createPurchaseOrderInApi({
    supplier,
    project,
    title,
  });

  const templateDetails = templatesForFileType && templatesForFileType.find((template) => template.id === templateId);

  const extension = "json";

  try {
    await recordActivityItem({
      purchaseOrder: newPurchaseOrder,
      type: "CREATED",
      author: apiUser.id,
      suppliers,
      users,
    });
  } catch (e) {
    console.error(e);
  }

  const fileKey = await encodeKey({
    type: KEY_TYPES.PURCHASE_ORDER,
    data: {
      organisation: organisationDetails.id,
      projectId: project.id,
      purchaseOrderId: newPurchaseOrder.id,
      extension,
      fileType,
    },
  });

  await callRest({
    route: "/copyTemplate",
    method: "post",
    body: {
      fileType,
      templateId,
      templateVersionNumber: templateDetails?.currentVersionNumber || 0,
      extension,
      organisation: organisationDetails.id,
      destinationKey: fileKey,
    },
  });

  await window.callGraphQLSimple({
    mutation: "updatePurchaseOrder",
    variables: {
      input: {
        id: newPurchaseOrder.id,
        fileKey,
        templateId: templateDetails?.id,
        templateVersionNumber: templateDetails?.currentVersionNumber || 0,
      },
    },
  });

  let purchaseOrderLineItems = [];

  let purchaseOrderSubtotal = 0;
  let purchaseOrderTaxTotal = 0;

  purchaseOrderLineItems.forEach((purchaseOrderLineItem) => {
    purchaseOrderSubtotal = roundToDecimals(purchaseOrderSubtotal + purchaseOrderLineItem.amount);
    purchaseOrderTaxTotal = roundToDecimals(purchaseOrderTaxTotal + purchaseOrderLineItem.taxAmount);
  });

  let purchaseOrderTotal = purchaseOrderSubtotal + purchaseOrderTaxTotal;
  const newPurchaseOrderUpdated = (
    await window.callGraphQLSimple({
      message: "Failed to update purchase order total",
      mutation: "updatePurchaseOrder",
      variables: {
        input: {
          id: newPurchaseOrder.id,
          subtotal: purchaseOrderSubtotal,
          totalTax: purchaseOrderTaxTotal,
          total: purchaseOrderTotal,
        },
      },
    })
  ).data.updatePurchaseOrder;
  return newPurchaseOrderUpdated;
}

export async function createPurchaseOrderInApi(params) {
  const { supplier, project, title, extraOffset = 0 } = params;
  const { apiUser } = window;

  try {
    let purchaseOrderId = await getPurchaseOrderId({
      organisation: apiUser.organisation,
      projectDetails: project,
      extraOffset,
    });

    const newPurchaseOrder = (
      await window.callGraphQLSimple({
        message: "Failed to create purchase order",
        mutation: "createPurchaseOrder",
        variables: {
          input: {
            id: purchaseOrderId,
            author: apiUser.id,
            assignedTo: apiUser.id,
            organisation: window.apiUser.organisation,
            supplierId: supplier.id,
            projectId: project.id,
            clientId: project.clientId,
            status: PURCHASE_ORDER_STATUSES[0].value,
            currency: CURRENCIES[0].value,
            taxInclusive: false,
            total: 0,
            subtotal: 0,
            totalTax: 0,
            taxRate: DEFAULT_TAX_RATE,
            title,
            team: project.team,
          },
        },
      })
    ).data.createPurchaseOrder;

    await window.callGraphQLSimple({
      message: "Failed to create purchase order",
      queryCustom: "updateProject",
      variables: {
        input: {
          id: project.id,
          purchaseOrderCount: (project.purchaseOrderCount || 0) + extraOffset + 1,
        },
      },
    });

    return newPurchaseOrder;
  } catch (e) {
    console.log("purchase order error: e = ", e);
    const errorType = e?.errors && e.errors[0]?.errorType;
    console.log("extraOffset = ", extraOffset);
    console.log("CREATE_RETRY_LIMIT = ", CREATE_RETRY_LIMIT);
    // debugger;
    if (errorType === "DynamoDB:ConditionalCheckFailedException" && extraOffset <= CREATE_RETRY_LIMIT) {
      console.log("not showing error");
      return await createPurchaseOrderInApi({
        ...params,
        extraOffset: extraOffset + 1,
      });
    } else {
      console.log("error creating purchase order:", e);
      displayErrorMessage(e, "Failed to create purchase order");
      throw e;
    }
  }
}

window.createPurchaseOrderInApi = createPurchaseOrderInApi;
